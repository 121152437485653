import React, {useEffect, useMemo, useState} from "react";
import useRouter from "../../../hooks/use-router";
import {partApis} from "../../../../core/constants/endpoints/endpoints";
import {apiMethods, TechnicalFileTypes as TechnicalFIleTypes} from "../../../../core/constants/enums";
import HTML from "../../../../assets/images/file-types/html.svg";
import PDF from "../../../../assets/images/file-types/pdf.svg";
import IMAGE from "../../../../assets/images/file-types/image.svg";
import UNKNOWN from "../../../../assets/images/file-types/unknown.svg";
import {Col, Container, Row} from "reactstrap";
import {Document, Page, pdfjs} from "react-pdf";
import Iframe from "react-iframe";
import api from "../../../../core/services/api/api";
import {ReactComponent as BackButton} from "../../../../assets/images/blog-back-icon.svg";
import {ReactComponent as ExpiredIcon} from "../../../../assets/images/art-likes/public-link-expired.svg";
import {routeFunctions} from "../../../routes";
import FloatingContainer from "../../../components/app-specific/floating-container";
import useIsMounted from "../../../hooks/use-is-mounted";
import {downloadFile} from "../../../../core/services/utils/utils";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";


const TechnicalFileView = () => {
    const {params, history} = useRouter();
    const [filePreview, setFilePreview] = useState();
    const [loading, setLoading] = useState(true);
    const [downloading, setDownloading] = useState(false);
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const isMounted = useIsMounted();

    /**
     * Sets the global worker for the pdfjs and then Fetches the technical file related to provided share link as soon
     * as the component mounts.
     */
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
        getTechnicalFile().then();
    }, [])

    /**
     * Fetches the technical file related to the provided token and if the result of the api is successful, sets the
     * preview.
     */
    const getTechnicalFile = async () => {
        setLoading(true);
        const response = await api({
            url: partApis.getFile(params.token),
            method: apiMethods.get,
            loginRequired: false,
        })
        if (!isMounted()) return
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setLoading(false);
            return;
        }
        if (!response?.resultFlag) {
            setFilePreview(null)
        } else {
            setFilePreview(response?.data ?? {})
        }
        setLoading(false);
    }


    /**
     * Downloads the file of the technical file for the user by fetching the file then downloading it using an <a> tag.
     */
    const downloadTechnicalFile = async () => {
        setDownloading(true);
        await fetch(filePreview.fileAddress)
            .then((resp) => {
                if (resp.status !== 200) return undefined;
                return resp.blob();
            })
            .then((blob) => {
                if (!blob) return;
                downloadFile(blob, filePreview.name.concat(`.${filePreview.extention}`));
            })
            .catch((e) => {
            });
        setDownloading(false);
    }

    /**
     * Sets the number of pages and start th epdf from the first page after the document has been loaded successfully.
     * @param {number} numPages
     */
    const onPdfDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    const fileIcon = useMemo(() => {
        switch (filePreview?.type?.id) {
            case TechnicalFIleTypes.HTML:
                return HTML;
            case TechnicalFIleTypes.PDF:
                return PDF;
            case TechnicalFIleTypes.IMAGE:
                return IMAGE;
            default:
                return UNKNOWN;
        }
    }, [filePreview?.type?.id])


    const content = useMemo(() => {
        switch (filePreview?.type?.id) {
            case TechnicalFIleTypes.HTML:
                return (
                    <Iframe
                        url={filePreview?.fileAddress}
                        width={'100%'}
                        height={'100%'}
                        frameBorder={0}
                        className={'preview-iframe'}
                    />
                );
            case TechnicalFIleTypes.PDF:
                return <>
                    <Document
                        file={filePreview?.fileAddress}
                        onLoadSuccess={onPdfDocumentLoadSuccess}
                        error={
                            <div
                                className={'mb-3 mt-4 py-5 d-flex justify-content-center' +
                                    ' align-items-center' +
                                    ' text-center'}>
                                Failed to load this PDF
                            </div>
                        }>
                        <Page pageNumber={pageNumber}/>
                        {
                            numPages > 1 &&
                            <div className={'page-controls'}>
                                <button
                                    className={'button primary text'}
                                    onClick={() => setPageNumber(prevState => Math.max(1, prevState - 1))}
                                >
                                    Prev
                                </button>
                                <span>{pageNumber ?? 0} of {numPages ?? 0}</span>
                                <button
                                    className={'button primary text'}
                                    onClick={() => setPageNumber(prevState => Math.min(numPages, prevState + 1))}
                                >
                                    Next
                                </button>
                            </div>
                        }
                    </Document>

                </>;
            case TechnicalFIleTypes.IMAGE:
                return (
                    <div
                        className={'d-flex justify-content-center preview-image'}>
                        <img src={filePreview?.fileAddress}
                             alt="preview"/>
                    </div>
                )
            default:
                return (
                    <div
                        className={'d-flex text-center justify-content-center my-3'}>
                        <p className={'primary-text-color text-xl' +
                            ' font-weight-600'}>
                            Unfortunately, preview is unavailable for the
                            specified file.
                        </p>
                    </div>
                );
        }
    }, [filePreview, numPages, pageNumber])

    return (
        <>
            <Container>
                <Row>
                    <Col xs={12}>
                        <FloatingContainer setHeight>
                            {
                                loading
                                    ? <>
                                        <div className={'my-5 py-5 d-flex justify-content-center'}>
                                            <LoadingIndicator/>
                                        </div>
                                    </>
                                    : !filePreview ?
                                        <Container className={'public-link-expired'}>
                                            <Row className='justify-content-center w-100'>
                                                <ExpiredIcon/>
                                            </Row>
                                        </Container>
                                        : <Row>
                                            <Col xs={12}>
                                                <div className="technical-file-box no-hover">
                                                    <div className={'d-flex justify-content-between mb-5'}>
                                                        <div className={'d-flex justify-content-start align-items-center'}>

                                                            <div
                                                                onClick={() => history.replace(routeFunctions.main.single(params.id))}>
                                                                <BackButton className={'back-button'}/>
                                                            </div>
                                                            <h2 className={'title m-0'}>
                                                                {params.id ?? ''}
                                                            </h2>
                                                        </div>
                                                        <button className={'button primary'}
                                                                disabled={downloading}
                                                                onClick={downloadTechnicalFile}>
                                                            {
                                                                downloading
                                                                    ? 'downloading...'
                                                                    : 'Download File'
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className={'d-flex w-100'}>
                                                        <div className={'image'}>
                                                            <img
                                                                src={fileIcon}
                                                                alt="extension"
                                                            />
                                                        </div>
                                                        <div className={'mt-4 d-flex flex-column'}>
                                                            <h6>{filePreview?.name ?? ''}</h6>
                                                            <p className={'text-wrap'}
                                                               style={{overflowWrap: 'break-word'}}>
                                                                {
                                                                    filePreview?.description
                                                                        ?.substring(0, 99)
                                                                        ?.concat(filePreview?.description?.length > 99 ? '...' : '')}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xs={12} className={'mt-4'}>
                                                {content}
                                            </Col>
                                        </Row>
                            }
                        </FloatingContainer>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default TechnicalFileView;
