import React, {useEffect, useMemo, useState} from "react";
import {Col, Row} from "reactstrap";
import ReactCreditCards from "react-credit-cards";
import {dateComparator, formatMoney, getCardMonth, getCardNumber} from "../../../../../core/services/utils/utils";
import Form from "../../../base/form";
import * as Yup from "yup";
import ValidateMessages from "../../../../../core/constants/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import MuiInput from "../../../base/input/mui-input";
import PaymentBox from "../../payment-box";
import {apiMethods, CreditCardTypeNames, UserPaymentsProcessSteps} from "../../../../../core/constants/enums";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {ReactComponent as InformationIcon} from "../../../../../assets/images/information-icon.svg";
import {paymentConfigurationApis} from "../../../../../core/constants/endpoints/endpoints";
import api from "../../../../../core/services/api/api";

const schema = Yup.object().shape({
    amount: Yup.number().positive().nullable().required(ValidateMessages.required)
        .min(0, ValidateMessages.min('0'))
        .typeError(ValidateMessages.incorrectType('valid amount')),
});

const UserPaymentsPaymentSection = ({
                                        card,
                                        setCard,
                                        setStep,
                                        amount: amountProp,
                                        pay: payProps,
                                        disabled,
                                        payments,
                                        getCallToAction,
                                        allowWindowScroll = true,
                                        showPreAuthPercentage = false,
                                    }) => {
    const amount = useMemo(() => amountProp ?? 0, [amountProp]);
    const [paying, setPaying] = useState(false);
    const [initialValues, setInitialValues] = useState({amount: amount});
    const [marginPayment, setMarginPayment] = useState(0);
    const [preAuthPercentage, setPreAuthPercentage] = useState(0);
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const isMounted = useIsMounted();

    /**
     * With each change in the showPreAuthPercentage prop:
     * - fetches the margin payment percentages if showPreAuthPercentage.
     */
    useEffect(() => {
        if (!showPreAuthPercentage)
            return;
        getMarginPaymentPercentage().then();
    }, [showPreAuthPercentage])

    /**
     * Listens for the changes in amount and showPreAuthPercentage and with each change:
     * - resets the initial values of the form.
     * - resets the margin payment amount.
     */
    useEffect(() => {
        if (showPreAuthPercentage && preAuthPercentage) {
            setMarginPayment(amount + (amount * preAuthPercentage));
        }
        setInitialValues({
            amount: Number(Math.abs(amount).toFixed(2)),
        });
    }, [amount, showPreAuthPercentage])

    /**
     * Pays for the operation with the selected user card and specified amount.
     * @param {any} values form values
     * @param {formApi} form
     * @return {Promise<void>}
     */
    const pay = async (values, form) => {
        setPaying(true);
        const data = {
            amount: parseFloat(values.amount),
            card: card,
        }
        await payProps(data);
        if (!isMounted()) return
        setPaying(false);
    }

    /**
     * Calls the api for getting the percentage amount of margin payment needed for calculations
     */
    const getMarginPaymentPercentage = async () => {
        const response = await api({
            url: paymentConfigurationApis.getPaymentPreAuthPercentage,
            method: apiMethods.get,
            showError: false
        });
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            const parsed = parseFloat(response?.configuration?.MarginPreAuthPaymentsPercentage)
            const preAuthPercentage = (isNaN(parsed) ? 0 : parsed) / 100;
            setPreAuthPercentage(preAuthPercentage);
            setMarginPayment(amount + (amount * preAuthPercentage));
        }
    }

    /**
     * Prefills the values used for the payment given a previous payment.
     *
     * this method sets the selected card and the amount of the form to make it ready for user to click on pay.
     * @param {any} payment the payment to be used for prefilling.
     */
    const prefillPayment = (payment) => {
        if (allowWindowScroll) {
            window.scrollTo(0, 0);
        }
        setCard(payment.extra.card);
        setInitialValues({amount: payment?.amount ?? 0});
    }

    /**
     * With every change in the text field, sets the [marginPayment] in the state
     * @param e
     */
    const onInputChanged = (e) => {
        setMarginPayment(+e.target.value + (+e.target.value * preAuthPercentage))
    }


    return (
        <>
            <Col xs={12}>
                <div className={'payment-card'}>
                    <Col xs={12} xl={6} className={'px-0'}>
                        <ReactCreditCards
                            preview
                            cvc={""}
                            focused={"name"}
                            expiry={getCardMonth(card?.month)}
                            number={getCardNumber(card?.lastFourDigits)}
                            issuer={CreditCardTypeNames[card?.type] ?? 'gradient'}
                            name={' '}
                        />
                        <button className={'button primary outlined mt-2'}
                                disabled={disabled}
                                type={"button"}
                                onClick={() => setStep(UserPaymentsProcessSteps.cardSelection)}>
                            Choose Another Card
                        </button>
                    </Col>
                    <Col xs={12} xl={6}>
                        <Form
                            onSubmit={pay}
                            validate={validate}
                            initialValues={initialValues}
                            render={({values, initialValues}) => {
                                const _amount = parseFloat(values?.amount);
                                const _disabled = disabled || paying || !card || isNaN(_amount) || _amount <= 0;
                                return (
                                    <Row>
                                        <Col xs={12} className={'mt-5 mt-xl-3'}>
                                            <p className={'title'}>
                                                Payable
                                                <span>:</span>
                                                <span>{formatMoney(amount)}</span>
                                            </p>
                                        </Col>
                                        <Col xs={12} className={'mt-4 mb-3'}>
                                            <p className={'mb-1 font-weight-600'}>Enter your amount</p>
                                            <MuiInput
                                                onInput={onInputChanged}
                                                form
                                                placeholder={'payment amount'}
                                                name={'amount'}
                                                required={required.amount}
                                                type={'number'}
                                                inputProps={{min: 0}}
                                            />
                                        </Col>
                                        <Col xs={12} className={'pt-lg-1 mt-xl-4'}>
                                            <button className={'button primary w-100'}
                                                    disabled={_disabled}
                                                    type={'submit'}>
                                                {
                                                    paying
                                                        ? "Paying..."
                                                        : getCallToAction(_amount, amount)
                                                }
                                            </button>
                                        </Col>
                                    </Row>
                                )
                            }}
                        />
                    </Col>
                    {
                        showPreAuthPercentage && preAuthPercentage !== 0 &&
                        <Col xs={12} className={'mt-4'}>
                            <div className={'pre-auth-informative'}>
                                <InformationIcon/>
                                <p className={'mb-1'}>
                                    The amount of <span
                                    className={'font-weight-600'}>{formatMoney(marginPayment)} dollars</span> will
                                    be verified and locked on your credit card and it will be updated with final
                                    transaction
                                </p>
                            </div>
                        </Col>
                    }
                </div>
            </Col>
            <Col xs={12} className={'mt-4'}>
                <div>
                    {
                        payments
                            ?.sort((a, b) => dateComparator(b.submittedDate, a.submittedDate))
                            ?.map(payment => (
                                <PaymentBox
                                    key={payment.id}
                                    payment={payment}
                                    onSelect={prefillPayment}
                                />
                            ))
                    }
                </div>

            </Col>
        </>
    )
}


export default UserPaymentsPaymentSection;
