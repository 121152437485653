import React, {forwardRef, useContext, useMemo} from "react";
import {DynamicContentEditorEntryRecordTypes, DynamicContentEditorEntryRecordValueTypes} from "../../../index.d";
import {DynamicContentEditorEntryWrapperContext} from "../../contexts";
import moment, {defaultFormat} from "moment";

/**
 * @type {ForwardRefExoticComponent<PropsWithoutRef<DynamicContentEditorEntryRecordProps> & RefAttributes<unknown>>}
 */
const DynamicContentEditorEntryRecord = forwardRef(({
                                                        recordKey,
                                                        valueType = DynamicContentEditorEntryRecordValueTypes.text,
                                                        type = DynamicContentEditorEntryRecordTypes.default,
                                                        tag = 'div',
                                                        placeholder,
                                                        _title,
                                                        children,
                                                        dateFormat,
                                                        ...props
                                                    }, ref) => {
    const data = useContext(DynamicContentEditorEntryWrapperContext);

    const dataValue = useMemo(() =>
            data?.find(e => e.key.toString() === recordKey?.toString())?.value,
        [data, recordKey])
    const value = useMemo(() => dataValue ?? placeholder, [dataValue, placeholder])

    const valueProperty = useMemo(() => {
        switch (valueType) {
            case DynamicContentEditorEntryRecordValueTypes.multiline:
                return {'dangerouslySetInnerHTML': {__html: value}};
            case DynamicContentEditorEntryRecordValueTypes.url:
                return {'href': value};
            case DynamicContentEditorEntryRecordValueTypes.phone:
                return {'href': `tel:${value}`};
            case DynamicContentEditorEntryRecordValueTypes.image:
            case DynamicContentEditorEntryRecordValueTypes.video:
            case DynamicContentEditorEntryRecordValueTypes.pdf:
                return {'src': value};
            case DynamicContentEditorEntryRecordValueTypes.date:
                if (!value || (value && !moment(value).isValid()))
                    return {}
                return {
                    'children': moment(value).format(dateFormat?.length ? dateFormat : defaultFormat)
                }
            case DynamicContentEditorEntryRecordValueTypes.text:
            case DynamicContentEditorEntryRecordValueTypes.currency:
            case DynamicContentEditorEntryRecordValueTypes.number:
            default:
                return {'children': value};
        }
    }, [valueType, value])

    const content = useMemo(() => {
        switch (valueType) {
            case DynamicContentEditorEntryRecordValueTypes.element:
                if (typeof children !== 'function') {
                    throw new Error("The children of an Element typed record must be of type function.");
                }
                const dataObject = Object.fromEntries(data?.map(e => ([e.key, e.value])) ?? []);
                return children(dataObject, ref);
            default:
                const Tag = tag;
                return (
                    <Tag
                        ref={ref}
                        data-dce-record-key={recordKey}
                        data-dce-record-value-type={valueType}
                        data-dce-record-type={type}
                        data-dce-record-value={dataValue}
                        data-dce-record-title={_title}
                        children={children}
                        {...(props ?? {})}
                        {...valueProperty}
                    />
                );
        }

    }, [valueProperty, props, recordKey, valueType, dataValue, _title, ref, tag, children, data, type])

    return (
        <>
            {content}
        </>
    );
})

export default DynamicContentEditorEntryRecord;
