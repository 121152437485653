import CookiesService from "../../../core/services/cache/cookies-service";
import {createSlice} from "@reduxjs/toolkit";

/**
 * The initial state for the profile information
 */
export const profileInfoInitialState = {
    firstName: null,
    lastName: null,
    email: null,
    phone: null,
    profileFileName: null,
    isImagePlaceholder: true,
}

/**
 * Reducer responsible for handling the profile information sync in the application.
 */
const profileReduxSlice = createSlice({
    name: "profile",
    initialState: {
        ...profileInfoInitialState,
        ...(CookiesService.get(CookiesService.keys.userInfo.name) ?? {}),
    },
    reducers: {
        /**
         * Updates the profile information of the user in the designated store slice.
         *
         * * also updates the information of the user set in the cookies.
         * @param state
         * @param action
         */
        updateProfile: (state, action) => {
            const payload = action.payload ?? {};
            for (const key in payload) {
                state[key] = payload[key]
            }
            CookiesService.set(CookiesService.keys.userInfo.name, state);
        }
    }
})

export default profileReduxSlice;
