import React, {useEffect, useMemo, useRef, useState} from "react";
import ProductSearchUtils from "../../../../../../core/services/utils/product-search-utils";
import useRouter from "../../../../../hooks/use-router";
import {deepEqual} from "../../../../../../core/services/utils/utils";
import {apiMethods, ProductsSearchTypes} from "../../../../../../core/constants/enums";
import routes from "../../../../../routes";
import {Col, Row} from "reactstrap";
import {ReactComponent as CategoryIcon} from "../../../../../../assets/images/input-icons/category.svg";
import ValidateMessages from "../../../../../../core/constants/validate-messages";
import * as Yup from "yup";
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../../../base/form";
import Select from "../../../../base/select";
import AutoComplete from "../../../../base/auto-complete";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import {ReactComponent as SearchIcon} from "../../../../../../assets/images/search-icon.svg";
import api from "../../../../../../core/services/api/api";
import {newLookupApis} from "../../../../../../core/constants/endpoints/endpoints";

const formKeys = ProductSearchUtils.elasticTechnicalSearchFormKeys;
const queryKeys = ProductSearchUtils.elasticTechnicalSearchQueryKeys;

const schema = Yup.object().shape({
    [formKeys.keyword]: Yup.string().nullable().trim(),
    [formKeys.categoryId]: Yup.number().nullable()
        .required(ValidateMessages.required).typeError(ValidateMessages.incorrectType("category"))
        .notOneOf([-1], ValidateMessages.required),
});

const validate = makeValidate(schema);
const required = makeRequired(schema);

const initialFirstCategoryOption = {
    id: -1,
    value: null,
    title: "All categories",
    disabled: true,
}

const ProductsSearchElasticTechnicalForm = ({categoriesFlat, data: dataProp, inSearchResults = false, parsedQuery}) => {
    const {history, stringifyUrl} = useRouter();
    const [initialFormValues, setInitialFormValues] = useState({});
    const prevDataProp = useRef({});

    const firstCategories = useMemo(() => [
        initialFirstCategoryOption,
        ...(categoriesFlat
            ?.filter((item) => item.ParentId === null)
            ?.map(e => ({
                id: e.Id,
                value: e.Id,
                title: e.Title,
            })) ?? [])
    ], [categoriesFlat]);

    /**
     * Listens for the changes in dataProp and with each change:
     * - syncs dataProp with the initialFormValues in the state.
     */
    useEffect(() => {
        if (!categoriesFlat?.length) {
            return;
        }
        if (!Object.keys(dataProp).length) {
            setInitialFormValues({});
            prevDataProp.current = {}
            return
        }
        if (deepEqual(dataProp, prevDataProp.current)) {
            return;
        }
        setInitialFormValues(dataProp);
        prevDataProp.current = dataProp
    }, [dataProp, categoriesFlat])

    /**
     * Changes the query of the url with the given search values.
     * @param {any} values the form values.
     */
    const search = (values) => {
        const url = stringifyUrl({
            url: routes.main.products,
            query: {
                type: ProductsSearchTypes.elasticTechnical,
                data: JSON.stringify({
                    [ProductsSearchTypes.elasticTechnical]: {
                        [queryKeys.orderBy]: values[queryKeys.orderBy],
                        [queryKeys.categoryId]: values[formKeys.categoryId],
                        [queryKeys.keyword]: values[formKeys.keyword],
                    }
                })
            },
        });
        history.push(url);
    };


    return (
        <>
            <Form
                initialValues={initialFormValues}
                validate={validate}
                onSubmit={search}
                render={({form, values}) => {
                    return (
                        <>
                            <Row form>
                                <Col xs={12} className={'mb-3'}>
                                    <Select
                                        form
                                        name={formKeys.categoryId}
                                        required={required[formKeys.categoryId]}
                                        value={values[formKeys.categoryId] ?? firstCategories[0].id}
                                        renderValue={e => firstCategories?.find(c => c.id === e)?.title}
                                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                                        data={firstCategories?.map(e => ({
                                            value: e.value,
                                            label: e.title,
                                            id: e.id,
                                            disabled: e.disabled,
                                        }))}
                                    />
                                </Col>
                                {
                                    inSearchResults &&
                                    <Col xs={12} className={'mb-3'}>
                                        <KeywordsAutocomplete
                                            name={formKeys.keyword}
                                            required={required[formKeys.keyword]}
                                            form={form}
                                        />
                                    </Col>
                                }
                                <Col
                                    xs={12}
                                    className={'d-flex flex-column align-items-center justify-content-start mt-2'}
                                >
                                    <Col md={5} lg={3} className={'p-0'}>
                                        <button className={'button primary w-100 px-5'} type={'submit'}>
                                            {
                                                inSearchResults
                                                    ? 'Search'
                                                    : 'Browse'
                                            }
                                        </button>
                                    </Col>
                                </Col>
                            </Row>
                        </>
                    );
                }}
            />
        </>
    )
}

const KeywordsAutocomplete = ({name, required, form}) => {
    const [fetching, setFetching] = useState(false);
    const [keywords, setKeywords] = useState('');
    const [data, setData] = useState([]);
    const isMounted = useIsMounted();

    /**
     * With each change in the keywords:
     * - fetches the autocomplete values for the given keywords.
     */
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!keywords || fetching || !isMounted() || (keywords?.trim()?.length ?? 0) < 3)
                return;
            getAutocompleteValues(keywords).then();
        }, 400)
        return () => clearTimeout(timer);
    }, [keywords])

    /**
     * Fetches the autocomplete values for the given keywords.
     *
     * @param {string} keywords
     * @return {Promise<void>}
     */
    const getAutocompleteValues = async (keywords) => {
        setFetching(true);
        const response = await api({
            url: newLookupApis.getElasticKeywordSuggestions(keywords),
            method: apiMethods.get,
        });
        if (!isMounted())
            return;
        setFetching(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        setData(response?.data?.suggestions ?? []);
    }

    return (
        <AutoComplete
            freeSolo
            fullWidth
            loading={fetching}
            form={form}
            name={name}
            required={required}
            placeholder={'Keyword'}
            className={'search-input'}
            onInputChange={(_, v) => setKeywords(v)}
            options={data?.map(e => e)}
            textFieldProps={{
                InputProps: {
                    startAdornment: (
                        <button className={'button text'} type={'submit'}>
                            <SearchIcon className={'icon'}/>
                        </button>
                    ),
                },
            }}
            fieldProps={{
                beforeSubmit: () => form.change(name, keywords),
            }}
            renderOption={(option, {inputValue}) => {
                const matches = match(option, inputValue);
                const parts = parse(option, matches);
                return (
                    <div>
                        {parts.map((part, index) => (
                            <span key={index} style={{fontWeight: part.highlight ? 600 : 400}}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                );
            }}
        />
    );
}


export default ProductsSearchElasticTechnicalForm;
