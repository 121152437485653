import React, {useEffect, useMemo, useState} from "react";
import {Col, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane} from "reactstrap";
import {TechnicalFileTypes as TechnicalFIleTypes, TechnicalPropertyTypes} from "../../../../../../core/constants/enums";
import HTML from "../../../../../../assets/images/file-types/html.svg";
import PDF from "../../../../../../assets/images/file-types/pdf.svg";
import IMAGE from "../../../../../../assets/images/file-types/image.svg";
import UNKNOWN from "../../../../../../assets/images/file-types/unknown.svg";
import classnames from "classnames";
import CacheService from "../../../../../../core/services/cache/cache-service";
import TechnicalFilePreviewDialog from "../../../../../components/dialogs/technical-file-preview-modal";
import {ReactComponent as PassedIcon} from "../../../../../../assets/images/pass-fail-icons/pass_icon.svg";
import {ReactComponent as FailedIcon} from "../../../../../../assets/images/pass-fail-icons/fail_icon.svg";

const Tabs = {
    description: {
        index: '1',
        title: "Description"
    },
    technicalInfo: {
        index: '2',
        title: "Technical Information"
    },
    application: {
        index: '3',
        title: "Application"
    },
    notes: {
        index: '4',
        title: "Notes"
    },
    technicalFiles: {
        index: '5',
        title: "Technical Files"
    },
}

const PartInformationViewPropertiesSection = ({data}) => {
    const [isLoggedIn] = useState(CacheService.isLoggedIn());
    const [previewDialog, setPreviewDialog] = useState({open: false, previewFile: null});
    const [activeTab, setActiveTab] = useState(Tabs.description.index);

    /**@type {{index: string, title: string}[]}*/
    const tabs = useMemo(() => {
        const allTabs = Object.fromEntries(Object.keys(Tabs).map(e => [e, e]));

        if ((data?.description?.length ?? 0) <= 0) {
            delete allTabs.description
        }
        if ((data?.properties?.length ?? 0) <= 0) {
            delete allTabs.technicalInfo
        }
        if ((data?.applications?.length ?? 0) <= 0) {
            delete allTabs.application
        }
        if ((data?.notes?.length ?? 0) <= 0) {
            delete allTabs.notes
        }
        if ((data?.technicalFiles?.length ?? 0) <= 0 || !isLoggedIn) {
            delete allTabs.technicalFiles;
        }
        return Object.keys(allTabs).map(e => Tabs[e]);
    }, [isLoggedIn, data])

    /**
     * Listens for the changes in isLoggedIn and with each change:
     * - if the active tab is technicalFiles then changes it to description.
     */
    useEffect(() => {
        if (!tabs?.map(e => e.index).includes(activeTab)) {
            setActiveTab(tabs?.[0]?.index)
        }
    }, [tabs])

    /**
     * Opens the technical file preview dialog.
     * @param {any} file the technical file
     */
    const openFilePreviewDialog = (file) => {
        setPreviewDialog({
            open: true,
            previewFile: file
        })
    }

    /**
     * Renders the value of the technical info properties of this part.
     * @param {any} property
     * @return {JSX.Element}
     */
    const renderProperty = (property) => {
        if (property.type === TechnicalPropertyTypes.switch) {
            if (property.value === 1) {
                return <PassedIcon/>
            }
            return <FailedIcon/>
        }
        return (
            <span>
                {property.value}
            </span>
        )
    }

    /**
     * Fetches the appropriate technical file extension based on the type of the technical file.
     * @param technicalFile
     * @returns {*}
     */
    const getTechnicalFileExtensionImage = (technicalFile) => {
        switch (technicalFile?.type?.id ?? technicalFile?.typeId) {
            case TechnicalFIleTypes.HTML:
                return HTML;
            case TechnicalFIleTypes.PDF:
                return PDF;
            case TechnicalFIleTypes.IMAGE:
                return IMAGE;
            case TechnicalFIleTypes.unknown:
                return UNKNOWN;
            default:
                return UNKNOWN;
        }

    }


    return (
        <>
            <div className={'nav-tabs-container'}>
                <Nav tabs className={'not-fixed-width align-items-center'}>
                    {
                        tabs?.map(tab => (
                            <NavItem key={tab.index}>
                                <NavLink
                                    className={classnames({'active': activeTab === tab.index})}
                                    onClick={() => setActiveTab(tab.index)}>
                                    {tab.title}
                                </NavLink>
                            </NavItem>
                        )) ?? []
                    }
                </Nav>
            </div>
            <TabContent
                id={'loader'}
                activeTab={activeTab}
                className={'mt-0 part-information-view-properties'}>
                <TabPane tabId={Tabs.technicalInfo.index}>
                    <div className="product-technical-info-table">
                        <Row>
                            {
                                data?.properties?.map((item, index) => (
                                    <Col lg={6} xs={12} key={`${item.title}-${item.type}`}>
                                        <div className={classnames(
                                            "item",
                                            {
                                                'top-first-row': index === 0,
                                                'last': index + 1 === data.properties?.length,
                                                'bottom-first-row': data.properties?.length % 2 === 0
                                                    ? index + 2 === data.properties?.length
                                                    : index + 1 === data.properties?.length,
                                                'top-second-row': index === 1,
                                                'bottom-second-row': data.properties?.length % 2 === 0
                                                    ? index + 1 === data.properties?.length
                                                    : index + 2 === data.properties?.length,
                                            }
                                        )}>
                                            <strong>{item.title}</strong>
                                            {renderProperty(item)}
                                        </div>
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                </TabPane>
                <TabPane tabId={Tabs.description.index}>
                    <div
                        className={'part-information-view-description'}
                        dangerouslySetInnerHTML={{__html: data?.description}}
                    />
                </TabPane>
                <TabPane tabId={Tabs.application.index}>
                    {
                        data?.applications?.length > 0 &&
                        <div className={'part-information-view-application-table-container'}>
                            <Table responsive className={'application-table'}>
                                <thead>
                                <tr>
                                    <th>
                                        <div className={'cell center'}>
                                            Make
                                        </div>
                                    </th>
                                    <th>
                                        <div className={'cell center'}>
                                            Model
                                        </div>
                                    </th>
                                    <th>
                                        <div className={'cell center'}>
                                            Year From
                                        </div>
                                    </th>
                                    <th>
                                        <div className={'cell center'}>
                                            Year To
                                        </div>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    data.applications?.map((item, index) => (
                                        <tr key={index} className={classnames({'alternate-color': index % 2 === 0})}>
                                            <td>
                                                <div className={'cell center'}>
                                                    {item.make?.name ?? item?.makeTitle ?? ''}
                                                </div>
                                            </td>
                                            <td>
                                                <div className={'cell center'}>
                                                    {item.model?.name ?? item?.modelTitle ?? ''}
                                                </div>
                                            </td>
                                            <td>
                                                <div className={'cell center'}>
                                                    {item.yearFrom ?? ''}
                                                </div>
                                            </td>
                                            <td>
                                                <div className={'cell center'}>
                                                    {item.yearTo ?? ''}
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </Table>
                        </div>
                    }
                </TabPane>
                <TabPane tabId={Tabs.notes.index}>
                    <div className={'d-flex flex-wrap'}>
                        {
                            data?.notes?.map((e) => (
                                <div key={e.id} className="part-information-view-note-box">
                                    <h6>{e.title}</h6>
                                    <div dangerouslySetInnerHTML={{__html: e.msg}}/>
                                </div>
                            ))
                        }
                    </div>
                </TabPane>
                <TabPane tabId={Tabs.technicalFiles.index}>
                    <Row>
                        {
                            data?.technicalFiles?.map((technicalFile) => (
                                <Col key={technicalFile.id} xl={4} lg={6} xs={12} className="my-3">
                                    <div className="technical-file-box"
                                         onClick={() => openFilePreviewDialog(technicalFile)}>
                                        <div className={'d-flex w-100'}>
                                            <div className={'image'}>
                                                <img
                                                    src={getTechnicalFileExtensionImage(technicalFile)}
                                                    alt="extension"
                                                />
                                            </div>
                                            <div className={'w-100 d-flex flex-column'}>
                                                <h6>
                                                    {technicalFile?.name ?? ''}
                                                </h6>
                                                <p className={'text-wrap'}
                                                   style={{overflowWrap: 'break-word'}}>
                                                    {
                                                        technicalFile?.description
                                                            ?.substring(0, 99)
                                                            ?.concat(technicalFile?.description?.length > 99 ? '...' : '')
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>
                </TabPane>
            </TabContent>
            <TechnicalFilePreviewDialog
                preview={previewDialog.previewFile}
                open={previewDialog.open}
                toggle={() => setPreviewDialog({open: false, previewFile: null})}
            />
        </>
    )
}

export default PartInformationViewPropertiesSection;
