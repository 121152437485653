import React, {useEffect, useMemo, useState} from "react";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {apiMethods} from "../../../../../core/constants/enums";
import api from "../../../../../core/services/api/api";
import {shoppingCartApis, userAddressApis} from "../../../../../core/constants/endpoints/endpoints";
import {getAddressString, stringComparator} from "../../../../../core/services/utils/utils";
import {
    DataGridColumnAlignments,
    DataGridColumnPinnedTypes,
    DataGridColumnTypes,
    DataGridColumnWidthTypes
} from "../../../../../packages/data-grid/models";
import classnames from "classnames";
import AppDataGrid from "../../../../containers/data-grid";


// the keys used for the table
const dataGridColumnNames = {
    title: 'title',
    address: 'address',
    select: 'select',
};

const initialPaginationInfo = {
    pageSize: 5,
    currentPage: 1,
    length: 0,
    sizes: [5, 10, 20],
};

const EditShoppingCartAddressDialogSelectAddress = ({type, onSuccess}) => {
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [visibleAddresses, setVisibleAddresses] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState(initialPaginationInfo);
    const [orderByMethod, setOrderByMethod] = useState(() => (a, b) => 0);
    const [updating, setUpdating] = useState(false);
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts:
     *- fetches the addresses from the server and only saves the ones that are compatible with the given type.
     */
    useEffect(() => {
        getAddresses()
    }, [])

    /**
     * With each change in the [addresses] and [paginationInfo] values of the state:
     * - selects a slice of the credits to be set as the visible credits.
     */
    useEffect(() => {
        if (!addresses?.length) {
            return setVisibleAddresses([]);
        }
        const start = Math.min(Math.max((paginationInfo.currentPage - 1) * paginationInfo.pageSize, 0), addresses.length);
        const end = Math.min((paginationInfo.currentPage) * paginationInfo.pageSize, addresses.length);
        const chunk = Array.from(addresses).sort(orderByMethod).slice(start, end)
        setVisibleAddresses(chunk);
    }, [addresses, paginationInfo.currentPage, paginationInfo.pageSize, orderByMethod])
    /**
     * Fetches the addresses from the server and only saves the ones that are compatible with the given type.
     */
    const getAddresses = () => {
        setLoading(true);
        api({
            url: userAddressApis.getAll,
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setLoading(false);
                return;
            }
            if (response?.resultFlag) {
                const addresses = response?.data?.data
                    ?.filter(e => e.type.id === type?.id)
                    ?.map(e => ({...e, __fullAddress: getAddressString(e)})) ?? [];
                setAddresses(addresses)
                setPaginationInfo(prevState => ({...prevState, length: addresses.length}))
            }
            setLoading(false);
        })
    }

    /**
     * Updates this cart's address with the provided address.
     * @param {any} address
     */
    const updateShoppingCartAddress = async (address) => {
        setUpdating(true);
        const response = await api({
            url: shoppingCartApis.updateAddress,
            method: apiMethods.put,
            showError: true,
            data: {
                ...address,
                fullAddress: address.streetAddress,
            },
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setUpdating(false);
            return;
        }
        if (!response?.resultFlag) {
            setUpdating(false);
            return;
        }
        onSuccess(response.data);
        setUpdating(false);
    }

    /**
     * Sorts the entries of the addresses in place.
     * @param {DataGridSortBy} tableOrderBy
     */
    const onSort = (tableOrderBy) => {
        setOrderBy(tableOrderBy);
        setPaginationInfo(prevState => ({...prevState, currentPage: 1}));
        switch (tableOrderBy?.field) {
            case dataGridColumnNames.title:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? stringComparator(a?.title ?? '', b?.title ?? '')
                    : stringComparator(b?.title ?? '', a?.title ?? '')
                )
            case dataGridColumnNames.address:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? stringComparator(a?.__fullAddress ?? '', b?.__fullAddress ?? '')
                    : stringComparator(b?.__fullAddress ?? '', a?.__fullAddress ?? '')
                )
            default:
                return setOrderByMethod(() => (a, b) => 0)
        }
    }

    const dataGridColumns = useMemo(() =>
            /**@type {DataGridColumn[]}*/
            [
                {
                    title: 'Title',
                    name: dataGridColumnNames.title,
                    alignment: DataGridColumnAlignments.left,
                    type: DataGridColumnTypes.string,
                },
                {
                    title: 'Address',
                    name: dataGridColumnNames.address,
                    alignment: DataGridColumnAlignments.left,
                    type: DataGridColumnTypes.string,
                    width: {
                        size: 1,
                        type: DataGridColumnWidthTypes.flex,
                    },
                },
                {
                    name: dataGridColumnNames.select,
                    alignment: DataGridColumnAlignments.center,
                    type: DataGridColumnTypes.element,
                    sortable: false,
                    pinned: true,
                    pinnedToggleable: false,
                    width: 60,
                    pinnedType: DataGridColumnPinnedTypes.right,
                },
            ]
        , [])

    /**@type {DataGridRow[]}*/
    const dataGridRows = useMemo(() => {
        return Array.from(visibleAddresses)
                ?.map((address) => ({
                    key: address.id,
                    data: address,
                    cells: {
                        [dataGridColumnNames.title]: address?.title,
                        [dataGridColumnNames.address]: address?.__fullAddress,
                        [dataGridColumnNames.select]: () => (
                            <button
                                disabled={updating}
                                className='button primary outlined px-3'
                                onClick={() => updateShoppingCartAddress(address)}>
                                {
                                    updating
                                        ? 'processing...'
                                        : "Select"
                                }
                            </button>
                        ),
                    }
                }))
            ?? []
    }, [visibleAddresses, updating])


    return (
        <>
            <div className={'select-address w-100'}>
                <div className={'d-flex w-100 mx-3'}>
                    <p className={'message mt-4'}>
                        please select a <span>{type.title} address</span> from the blow list.
                    </p>
                </div>
                <AppDataGrid
                    hideFooterActions
                    hideExporting
                    classNames={{
                        container: classnames('address-selection-table mb-3'),
                    }}
                    rows={dataGridRows}
                    columns={dataGridColumns}
                    state={{
                        loading: {state: loading},
                        sortBy: orderBy,
                        pagination: paginationInfo,
                    }}
                    onSortByChanged={(_, current) => onSort(current)}
                    onCurrentPageChanged={(_, current) => setPaginationInfo({...current, sizes: [5, 10, 20],})}
                    onPageSizeChanged={(_, current) => setPaginationInfo({...current, sizes: [5, 10, 20],})}
                />
            </div>
        </>
    );
}

export default EditShoppingCartAddressDialogSelectAddress;
