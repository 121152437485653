import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import useRouter from "../../../../hooks/use-router";
import {cmsVideoApis} from "../../../../../core/constants/endpoints/cms_endpoints";
import api from "../../../../../core/services/api/api";
import {ReactComponent as BackButton} from '../../../../../assets/images/blog-back-icon.svg';
import BlogDateIcon from "../../../../../assets/images/blogs/date-icon.svg";
import BlogViewsIcon from "../../../../../assets/images/blogs/views-icon.svg";
import moment from "moment";
import classnames from "classnames";
import VideoCard from "../../../../components/app-specific/content-boxes/video-card";
import {apiMethods} from "../../../../../core/constants/enums";
import useIsMounted from "../../../../hooks/use-is-mounted";
import FloatingContainer from "../../../../components/app-specific/floating-container";
import YoutubeVideoPlayer, {VideoTypes} from "../../../../components/base/video-player/youtube";
import NativeVideoPlayer from "../../../../components/base/video-player/native";
import {correctUrl} from "../../../../../core/services/utils/utils";
import ContentShareButtons from "../../../../components/app-specific/content-share-buttons";


const ContentVideoInformationView = () => {
    const {location, params, history} = useRouter();
    const [loading, setLoading] = useState(true);
    const [loadingRelatedVideos, setLoadingRelatedVideos] = useState(true);
    const [video, setVideo] = useState({});
    const [relatedVideos, setRelatedVideos] = useState([]);
    const isMounted = useIsMounted();

    const url = correctUrl(video?.url)
    const isVideoFromYoutube = url?.startsWith('https://www.youtube') ?? false;
    const baseUrl = window.location.origin;

    /**
     * Loads the full information of the specified video based on its slug and searches for the list of related videos
     * (by tags) as soon as this component mounts.
     */
    useEffect(() => {
        getFullInformation();
    }, [params?.slug])


    /**
     * Loads the full information of the specified video based on its slug and if the result of the api is
     * successful, sets the data
     */
    const getFullInformation = () => {
        setLoading(true);
        api({
            url: cmsVideoApis.getBySlug(params.slug),
            method: apiMethods.get,
            loginRequired: false,
        }).then((response) => {
            if (!isMounted())
                return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setLoading(false);
                return;
            }
            setVideo(response?.data ?? []);
            setLoading(false);
            if (response?.resultFlag) {
                getRelatedVideos(response.data);
            } else {
                setLoadingRelatedVideos(false)
            }
        });
    }

    /**
     * Loads the related videos of the current video by searching with videos' tags and if the result of the api is
     * successful, sets the data
     */
    const getRelatedVideos = (video) => {
        api({
            url: cmsVideoApis.search,
            method: apiMethods.post,
            loginRequired: false,
            data: {
                // here we are adding one more than the usual since the same blog is related to itself,
                paginationInfo: {currentPage: 1, pageSize: 4},
                filters: {tagsId: video?.tags?.map(e => e.id)}
            }
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setLoadingRelatedVideos(false);
                return;
            }
            setRelatedVideos(response.data?.resultList
                    ?.filter(e => e.id !== video?.id)
                    ?.slice(0, 3)
                ?? []);
            setLoadingRelatedVideos(false);
        });
    }

    return (
        <>
            <main className={'blogs-view'}>
                <Container>
                    <FloatingContainer setHeight>
                        <Row
                            className={classnames('justify-content-around align-items-start mx-4')}>
                            {
                                loading
                                    ? (
                                        <>
                                            <div className={'d-flex w-100 justify-content-between align-items-start'}>
                                                <div className={'d-flex flex-column w-50 w-lg-25'}>
                                                    <div className={'loading extra-small mb-2'}>
                                                        <div/>
                                                    </div>
                                                    <div className={'loading extra-extra-small'}>
                                                        <div/>
                                                    </div>
                                                </div>
                                                <div className={'w-10'}>
                                                    <div className={'loading extra-extra-small'}>
                                                        <div/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'w-100 my-4 mt-5'}/>
                                            <div className={'w-100 loading video-main-image mb-4'}>
                                                <div/>
                                            </div>
                                            <div className={'d-flex w-100'}>
                                                <div className={'w-50 loading extra-small mb-5'}>
                                                    <div/>
                                                </div>
                                            </div>
                                            <div className={'w-100 loading extra-extra-small'}>
                                                {
                                                    Array(20).fill(null).map((e, index) => (
                                                        <div key={index}/>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    )
                                    : (
                                        <>
                                            <div className={'d-flex w-95 justify-content-between align-items-start'}>
                                                <div className={'d-flex flex-column w-75'}>
                                                    <div className={'d-flex w-100 justify-content-start' +
                                                        ' align-items-center'}>
                                                        <div
                                                            onClick={history.goBack}
                                                        >
                                                            <BackButton className={'back-button'}/>
                                                        </div>
                                                        <h2 className={'header-title m-0'}>
                                                            {video.title ?? ''}
                                                        </h2>
                                                    </div>
                                                    <div className={'d-flex w-100 justify-content-start' +
                                                        ' align-items-center mt-2 ml-4'}>
                                                        <p className={'date mr-4 ml-3'}>
                                                            <img className={'mr-2'} src={BlogDateIcon} alt="date icon"/>
                                                            {video?.lastModifyDateTime ? moment(video?.lastModifyDateTime).format('MMM DD, YYYY') : ''}
                                                        </p>
                                                        <p className={'date'}>
                                                            <img className={'mr-2 ml-2'} src={BlogViewsIcon}
                                                                 alt="views icon"/>
                                                            {`${video.viewCount ?? 0} viewed`}
                                                        </p>
                                                    </div>

                                                </div>
                                                <ContentShareButtons
                                                    tag={'div'}
                                                    className={'mt-2 d-flex align-items-center'}
                                                    title={video?.title}
                                                    slug={video?.slug}
                                                    link={baseUrl?.concat(location.pathname)}
                                                />
                                            </div>
                                            <div className={'w-100 my-4 mt-5'}/>
                                            <div className={'blog-video mb-4 mx-5'}>
                                                {
                                                    isVideoFromYoutube
                                                        ? <YoutubeVideoPlayer
                                                            key={video.id}
                                                            id={`video-${video.id}`}
                                                            videoId={url?.split('/')[url?.split('/').length - 1]}
                                                            type={VideoTypes.large}
                                                            title={video.title}
                                                        />
                                                        : <NativeVideoPlayer
                                                            key={video.id}
                                                            id={`video-${video.id}`}
                                                            src={url}
                                                            type={VideoTypes.large}
                                                            title={video.title}
                                                        />
                                                }

                                            </div>
                                            <div className={'w-100 my-4'}/>
                                            {
                                                video?.otherPropValues?.length > 0 &&
                                                <>
                                                    <div className={'w-100 mt-3'}/>
                                                    <p className={'w-100 content-info-features'}>
                                                        Video's features:
                                                    </p>
                                                    <div className={'w-100 mb-5'}/>
                                                </>
                                            }
                                            <div className={'d-flex flex-wrap w-100 mx-3'}>
                                                {
                                                    video?.otherPropValues?.map((e, index) => (
                                                        <div
                                                            key={e.property?.name ?? index}
                                                            className={classnames('blog-property-box', {'no-border': index === video?.otherPropValues?.length - 1})}>
                                                            <div className={classnames('ball')}/>

                                                            <div className={'d-flex flex-column flex-wrap'}>
                                                                <h4 className={'title mb-1'}>
                                                                    {e?.property?.name ?? ''}
                                                                </h4>
                                                                <p className={'value'}>
                                                                    {e?.value ?? ''}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className={'w-100 my-4'}/>
                                            <div className={'w-100 blog-body mx-3'}
                                                 dangerouslySetInnerHTML={{__html: video?.description ?? ''}}/>
                                        </>
                                    )
                            }
                            <div className={'w-100 my-5'}/>
                            {
                                loadingRelatedVideos
                                    ? (
                                        <Col xs={12} className={'mt-5 d-flex flex-wrap p-0'}>
                                            {
                                                <div className={'w-50 mb-3'}>
                                                    <div className={'loading extra-small'}>
                                                        <div/>
                                                    </div>
                                                </div>
                                            }
                                            <div className={'w-100'}/>
                                            {
                                                Array(3).fill(null).map((e, index) => (
                                                    <Col key={index} xs={12} sm={6} md={4} lg={3} className={'mb-3'}>
                                                        <div className={'loading video'}>
                                                            <div/>
                                                        </div>
                                                    </Col>

                                                ))
                                            }
                                        </Col>
                                    )
                                    : (
                                        <Col xs={12} className={'mt-5 d-flex flex-wrap p-0'}>
                                            {
                                                <div className={'w-100 mx-3 mb-3'}>
                                                    <h2 className={'header-title'}>
                                                        Related Topics
                                                    </h2>
                                                </div>
                                            }
                                            <div className={'w-100'}/>
                                            {
                                                relatedVideos.map((video) => (
                                                    <VideoCard
                                                        key={video.id}
                                                        className={'mb-3 mx-3 mx-sm-3'}
                                                        data={video}
                                                    />
                                                ))
                                            }
                                        </Col>
                                    )
                            }
                        </Row>
                    </FloatingContainer>
                </Container>
            </main>
        </>
    );
}


export default ContentVideoInformationView;
