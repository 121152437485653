// #####################     GLOBALS      #####################

import DynamicContentEditorCommunicator from "./core/models/communicator";
import {ElementType, MutableRefObject, ReactNode} from "react";
import {AxiosRequestConfig, AxiosResponse} from "axios";

declare global {
    interface Window {
        DCECommunicator: DynamicContentEditorCommunicator,
    }
}


// #####################     ENUMS      #####################


export enum DynamicContentEditorMode {
    view = 'view',
    edit = 'edit',
}

export enum DynamicContentEditorStateActions {
    none,
    setMode,
    setCurrentPage,
    setPageInfo,
    setPageEntryInfo,
    setPageEntryDataRecordInfo,
    setLoading,
    setApiConfiguration,
    setRouteToPageMap,
}

export enum DynamicContentEditorEntryDataTypes {
    fixed = 'fixed',
    pageBased = 'pageBased',
}

export enum DynamicContentEditorEntryIconPositions {
    inward = 'inward',
    outward = 'outward',
}

export enum DynamicContentEditorEntryRecordValueTypes {
    text = 'text',
    multiline = 'multiline',
    url = 'url',
    phone = 'phone',
    image = 'image',
    video = 'video',
    currency = 'currency',
    number = 'number',
    element = 'element',
    date = 'date',
    pdf = 'pdf',
}

export enum DynamicContentEditorEntryRecordTypes {
    default = 'default',
    unEditable = 'unEditable',
}

export enum DynamicContentEditorInputFileAcceptTypes {
    png = '.png',
    jpg = '.jpg',
    jpeg = '.jpeg',
    mp4 = '.mp4',
    mov = '.mov',
    webm = '.webm',
    flv = '.flv',
    wmv = '.wmv',
    pdf = '.pdf',
    csv = '.csv',
    spreadsheet = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    excel = 'application/vnd.ms-excel',
    numbers = '.numbers',
    zip = '.zip',
    rar = '.rar',
    html = '.html',
    word = '.doc',
    svg = '.svg',
}

export enum DynamicContentEditorInputFileAcceptMimes {
    images = 'image/*',
    videos = 'video/mp4,video/mov,video/x-m4v,video/*',
    audios = 'audio/*',
}

export enum DynamicContentEditorReadingFileAs {
    binaryString = 'binaryString',
    dataUrl = 'dataUrl',
    text = 'text',
    arrayBuffer = 'arrayBuffer',
}

export const DynamicContentEditorListEntryItemIdentifierKey = '__DCEListItemId'

// #####################     TYPES / INTERFACES      #####################


type RequestInterceptor = [onFulfilled?: (value: AxiosRequestConfig) => AxiosRequestConfig | Promise<AxiosRequestConfig>, onRejected?: (error: any) => any];
type ResponseInterceptor = [onFulfilled?: (value: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>, onRejected?: (error: any) => any];

export interface DynamicContentEditorApiInterceptors {
    request: Array<RequestInterceptor>,
    response: Array<ResponseInterceptor>,
}

export type DynamicContentEditorPageEntryDataRecordValue =
    string
    | Array<string>
    | Array<DynamicContentEditorPageEntryDataRecord<string>>;

export interface DynamicContentEditorPageEntryDataRecord<T = DynamicContentEditorPageEntryDataRecordValue> {
    key: string,
    value: T,
}

export type DynamicContentEditorPageEntryData<T = DynamicContentEditorPageEntryDataRecordValue> = Array<DynamicContentEditorPageEntryDataRecord<T>>;

export interface DynamicContentEditorPageEntry<T = DynamicContentEditorPageEntryDataRecordValue> {
    name: string,
    isFixed: boolean,
    data: DynamicContentEditorPageEntryData<T>,
}

export type DynamicContentEditorPage = Array<DynamicContentEditorPageEntry>;


export interface DynamicContentEditorApiConfiguration {
    token?: string,
    validateTokenUrl?: string,
    getPageDataUrl?: string,
    savePageEntryUrl?: string,
    uploadFileUrl?: string,


    [key: string]: any,
}

export type DynamicContentEditorLoading = Record<string, boolean>;

export interface DynamicContentEditorStateData {
    fixed: DynamicContentEditorPage,
    pageBased: Record<string, DynamicContentEditorPage>,
}

export interface DynamicContentEditorState {
    /**
     * The data that are used for all the api calls related to this package.
     * Includes the url endpoints and the request validation token.
     */
    apiConfiguration: DynamicContentEditorApiConfiguration,
    /**
     * The record that maps the paths of the application to the pages of the editor.
     */
    routeToPageMap: Record<string, string>,
    /**
     * The mode of the editor. The value of this property determines whether the ui elements get into the edit mode
     * or not.
     */
    mode: DynamicContentEditorMode,
    currentPage?: string,
    data: DynamicContentEditorStateData,
    loading: DynamicContentEditorLoading,
}

export interface DynamicContentEditorDispatcherAction {
    type: DynamicContentEditorStateActions,
    payload: any,
}

export interface ADynamicContentEditorApiResponseConfiguration {
    FileBaseURL: string,
}

export interface DynamicContentEditorApiResponseDS<T = any> {
    errors?: Record<string, Array<string>>;
    code?: number;
    header?: string;
    message?: string;
    resultFlag?: boolean;
    referenceNumber?: string;
    configuration?: Partial<ADynamicContentEditorApiResponseConfiguration>;
    data?: T;
    aborted?: boolean,
}


// #####################     REACT PROPS      #####################

type GetDynamicContentEditorEntryChildren = (inEditMode: boolean) => ReactNode;

type DynamicContentEditorEntryDataObject = Record<string, DynamicContentEditorPageEntryDataRecordValue>;

type ExposeEntryData = (data: Array<DynamicContentEditorEntryDataObject>) => void;

type GetListEntryChildProps = (
    data?: DynamicContentEditorEntryDataObject,
    index?: number,
    array?: Array<DynamicContentEditorEntryDataObject>
) => Record<string, any>;

export interface DynamicContentEditorRouterProps {
    children?: ReactNode,
    routeToPageMap: Record<string, string>,
    apiConfiguration?: Partial<DynamicContentEditorApiConfiguration>,
    apiInterceptors?: Partial<DynamicContentEditorApiInterceptors>,
    route: string,
}

interface DynamicContentEditorEntryRoot {
    dataType?: DynamicContentEditorEntryDataTypes,
    tag?: ElementType,
    isAbsolutePositioned?: boolean,
    entryName: string,
    _title: string,
    getData?: ExposeEntryData,
    iconPosition?: DynamicContentEditorEntryIconPositions,

    [key: string]: any,
}

export interface DynamicContentEditorEntryProps extends DynamicContentEditorEntryRoot {
    children?: ReactNode | GetDynamicContentEditorEntryChildren,
    id?: string | number,
    isRelativePositioned?: boolean,
}

export interface DynamicContentEditorListEntryProps extends DynamicContentEditorEntryRoot {
    children?: ReactNode,
    childTag?: ElementType,
    childProps?: Record<string, any> | GetListEntryChildProps,
    isChildRelativePositioned?: boolean,
    includeIdentifier?: boolean,
}

export interface DynamicContentEditorListEntryItemProps {
    children?: ReactNode,
    tag?: ElementType,
    inEditMode?: boolean,
    template?: boolean,
    data: DynamicContentEditorPageEntryDataRecord<string | Array>,
    upsert?: Function,
    remove?: Function,
    page: string,
    id?: string | number,
    isRelativePositioned?: boolean,
    parentTitle: string,
    iconPosition?: DynamicContentEditorEntryIconPositions,

    [key: string]: any,
}

export interface DynamicContentEditorEntryRecordProps {
    recordKey?: any,
    valueType?: DynamicContentEditorEntryRecordValueTypes,
    type?: DynamicContentEditorEntryRecordTypes,
    tag?: ElementType,
    placeholder?: string,
    _title?: string,
    children?: (data: DynamicContentEditorEntryDataObject, ref: MutableRefObject<any>) => ReactNode,
    dateFormat?: string,

    [key: string]: any,
}

// #####################     DEFAULT VALUES      #####################


export const DefaultDynamicContentEditorState: DynamicContentEditorState = {
    apiConfiguration: {},
    routeToPageMap: {},
    currentPage: undefined,
    mode: DynamicContentEditorMode.view,
    data: {
        fixed: [],
        pageBased: {},
    },
    loading: {},
}
