import React from "react";
import {Col, Row} from "reactstrap";
import QuoteBox from "../../../../../components/app-specific/order-boxes/quote-box";

const QuotesListView = ({data}) => {


    return (
        <Row className={'pt-3'}>
            {
                data?.map((quote) => (
                    <Col xs={12} key={quote.id} className={'pt-3'}>
                        <QuoteBox data={quote}/>
                    </Col>
                ))
            }
        </Row>
    );
}


export default QuotesListView;
