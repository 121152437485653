import {createSlice} from "@reduxjs/toolkit";

const initialValues = {
    values: {},
};

/**
 * Reducer for storing DCE values in the application.
 */
const DCEReduxSlice = createSlice({
    name: "dynamicContentEditor",
    initialState: initialValues,
    reducers: {
        /**
         * Sets the DCE values of the slice with the given action payload.
         *
         * @param state
         * @param action
         * @return {any}
         */
        setValues: (state, action) => {
            state.values = {
                ...state.values,
                ...(action.payload ?? {})
            };
            return state;
        }
    }
})

export default DCEReduxSlice;
