import React, {useCallback, useEffect, useState} from "react";
import {Alert, Col, Container, Row} from "reactstrap";
import classnames from "classnames";
import LoginForm from "../../../components/app-specific/forms/login-form";
import useAlert from "../../../hooks/use-alert";
import useIsMounted from "../../../hooks/use-is-mounted";
import {useDispatch, useSelector} from "react-redux";
import {loginUser} from "../../../../redux/entities/auth/actions";
import {AlertTypes, apiMethods} from "../../../../core/constants/enums";
import {authApis} from "../../../../core/constants/endpoints/endpoints";
import api from "../../../../core/services/api/api";

const DeleteAccountView = () => {
    const [deleting, setDeleting] = useState(false);
    const [deleted, setDeleted] = useState(false);
    const [verified, setVerified] = useState(false);
    const {message, loading: verifying} = useSelector(state => state?.auth ?? {message: null, loading: false})
    const [alert, setAlert, resetAlert] = useAlert(8000);
    const isMounted = useIsMounted();
    const dispatch = useDispatch();

    /**
     * Handles the login of the user
     *
     * 1. Gets the values from the form
     * 2. Dispatches the loginUser action
     * 3. If the login is successful, then:
     *     - sets the verified state to true
     *     - resets the alert
     *   - else sets the verified state to false
     * @type {(function(Record<string, any>): void)}
     */
    const onUserLogin = useCallback((values) => {
        const _values = {
            account: values['email'],
            password: values['password'],
            remember: values['remember']
        }
        dispatch(loginUser(_values,
            () => {
                if (!isMounted())
                    return;
                setVerified(true);
                resetAlert();
            },
            () => {
                if (!isMounted())
                    return;
                setVerified(false);
            }
        ))
    }, [dispatch, isMounted, resetAlert])

    /**
     * Listens for the changes in the message state of the auth redux slice, and with each change:
     * - if there is no message, then closes the alert,
     * - else opens the alert since there is an error from the server that needs to be displayed.
     */
    useEffect(() => {
        // if message is gone, so should the alert
        if (!message?.length) {
            return resetAlert();
        }
        // message exists, so there is an error from the server.
        setAlert({isOpen: true, message: message, color: AlertTypes.error})
    }, [message])


    /**
     * Handles the deletion of the account.
     *
     * 1. Sets the deleting state to true
     * @type {(function(): Promise<void>)}
     */
    const deleteAccount = useCallback(async () => {
        setDeleting(true);
        const response = await api({
            url: authApis.removeAccount,
            method: apiMethods.delete,
        })
        if (!isMounted())
            return;
        setDeleting(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            setDeleted(true);
        }
    }, [isMounted])

    return (
        <>
            <Container className={'delete-account-view'}>
                <Row>
                    <Col lg={7} xs={12}>
                        <div className="d-flex flex-column">
                            <h1>
                                {
                                    verifying
                                        ? <>
                                            Verifying your <span>Account</span>
                                        </>
                                        : deleting
                                            ? <>
                                                Deleting your <span>account</span>
                                            </>
                                            : deleted
                                                ? <>
                                                    Your <span>account</span> has been deleted
                                                </>
                                                : <>
                                                    Delete your <span>account</span>
                                                </>
                                }
                            </h1>
                            <p>
                                {
                                    verifying
                                        ? <>
                                            We are verifying your account, please wait...
                                        </>
                                        : deleting
                                            ? <>
                                                We are deleting your account, please wait...
                                            </>
                                            : deleted
                                                ? <>
                                                    Your account has been deleted, you will be redirected to the home page in 5 seconds.
                                                </>
                                                : <>
                                                    To delete your account, please login first. Please note that this action is irreversible.
                                                </>
                                }
                            </p>
                        </div>
                    </Col>
                    <Col lg={5} xs={12}>
                        <div className={classnames("auth-form-box", {'move-back': deleted})}>
                            <div className="box-title">
                                {
                                    !verified && !deleted &&
                                    "Sign In"
                                }
                                {
                                    verified && !deleted &&
                                    "Remove Account"
                                }
                            </div>
                            <Alert color={alert.color} isOpen={alert.isOpen}>
                                {alert.message}
                            </Alert>
                            {
                                !verified && !deleted &&
                                <LoginForm
                                    onSubmit={onUserLogin}
                                    loading={verifying}
                                />
                            }
                            {
                                verified && !deleted &&
                                <button
                                    className={"button primary px-4 m-auto d-flex mb-3"}
                                    onClick={deleteAccount}
                                    disabled={deleting}
                                >
                                    {
                                        deleting
                                            ? 'Processing...'
                                            : 'Delete your Account'
                                    }
                                </button>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default DeleteAccountView;
