import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {confirmationDialog} from "../../../../redux/entities/dialogs/actions";
import {IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";

export const ConfirmationDialog = () => {
    const state = useSelector(state => state?.dialogs?.confirmation)
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    /**
     * Listens for changes in the open state of the dialog and with each change:
     * if the dialog is closed, then sets the loading to false too
     */
    useEffect(() => {
        if (!state?.open) {
            setLoading(false);
        }
    }, [state?.open])

    /**
     * Closes the dialog.
     *
     * calls the callback if the user has confirmed the operation otherwise does nothing.
     * if the callback is called, it is awaited for its response and only closes the dialog if the resultFlag is true.
     * @param {boolean} accepted
     * @param {boolean} auto
     */
    const closeDialog = async (accepted = false, auto = false) => {
        if (!accepted && auto) {
            return dispatch(confirmationDialog({
                open: false
            }))
        }
        if (typeof state?.callback === 'function') {
            setLoading(true);
            const resultFlag = await state?.callback(accepted);
            if (resultFlag) {
                dispatch(confirmationDialog({
                    open: false
                }))
            }
            setLoading(false);
        }
    }

    return (
        <Modal size={'md'}
               isOpen={state?.open}
               backdrop={(!!state?.mandatory || loading) ? 'static' : true}
               centered={true}
               toggle={() => closeDialog(false, true)}>
            <ModalHeader>
                <div className={'d-flex flex-row justify-content-between align-items-center'}>
                    {
                        state?.title ?? ''
                    }
                    <div>
                        <IconButton onClick={() => closeDialog(false)}>
                            <Close/>
                        </IconButton>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className={'mx-3 mt-3 font-weight-600'}>
                {
                    state?.description ?? ""
                }
            </ModalBody>
            <ModalFooter className={'d-flex align-items-center justify-content-end'}>
                <button
                    className={'button text primary'}
                    onClick={() => closeDialog(false)}
                    disabled={loading}>
                    {
                        loading
                            ? "waiting...."
                            : state?.cancelText ?? ''
                    }
                </button>
                <button
                    className={'button primary px-4'}
                    onClick={() => closeDialog(true)}
                    disabled={loading}>
                    {
                        loading
                            ? "Processing...."
                            : state?.proceedText ?? ''
                    }
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default ConfirmationDialog;
