import React, {useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {HeaderTypes} from "../../../core/constants/enums";
import classnames from "classnames";
import {Col, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, Row} from "reactstrap";
import {Link} from "react-router-dom";
import routes from "../../routes";
import LogoWhite from "../../../assets/images/logo/logo-white.svg";
import Logo from "../../../assets/images/logo/logo-white.svg";
import HeaderNavigationButtons from "../../components/app-specific/header-navigation-buttons";
import LoggedInHeaderContent from "./sections/logged-in";
import LoggedOutHeaderContent from "./sections/logged-out";
import useWindowViewportWidth from "../../hooks/use-window/viewport-width";

const Header = () => {
    const [navbarOpen, setNavbarOpen] = useState(false);
    const _headerHeight = useSelector(state => state?.header?.height ?? 0);
    const headerType = useSelector(state => state?.header?.type);
    const viewportWidth = useWindowViewportWidth();

    const headerHeight = useMemo(() => {
        const headerHeight = !isNaN(Number(_headerHeight)) ? Number(_headerHeight) : 0;
        if (['xs', 'sm', 'md'].includes(viewportWidth)) {
            return Math.max(headerHeight, 25);
        }
        return headerHeight;
    }, [viewportWidth, _headerHeight])

    const isAuth = headerType === HeaderTypes.auth
    const loggedIn = headerType === HeaderTypes.loggedIn
    const brandLink = loggedIn
        ? routes.landing
        : routes.auth.base
    const brandLogo = isAuth
        ? LogoWhite
        : Logo

    /**
     * Renders the content of the header based on the specified header type.
     * @return {JSX.Element}
     */
    const renderContent = () => {
        switch (headerType) {
            case HeaderTypes.loggedOut:
                return <LoggedOutHeaderContent/>
            case HeaderTypes.auth:
            default:
                return <></>
            case HeaderTypes.loggedIn:
                return <LoggedInHeaderContent/>
        }
    }

    return (
        <>
            <header
                style={{paddingBottom: headerHeight}}
                className={classnames('app-header', {
                    'auth': isAuth,
                })}>
                <Container>
                    <Row className={'align-items-start header-row mt-3'}>
                        <Col lg xs={12}>
                            <Navbar expand="lg">
                                <NavbarBrand tag={() =>
                                    <Link className={'navbar-brand mt-0'} to={brandLink}>
                                        <img src={brandLogo} alt="Chtp" width={250}/>
                                    </Link>
                                }/>
                                <NavbarToggler onClick={() => setNavbarOpen(prevState => !prevState)}/>
                                <Col xs={12} lg={!loggedIn ? 1 : 12} className={'py-2 header-log-nav-spacer'}
                                     style={{display: !loggedIn ? 'none' : ''}}/>
                                <Collapse isOpen={navbarOpen} navbar>
                                    <HeaderNavigationButtons
                                        loggedIn={loggedIn}
                                        className={classnames('app-header-navbar', {'ml-lg-4': !loggedIn})}
                                    />
                                </Collapse>
                            </Navbar>
                        </Col>
                        {renderContent()}
                    </Row>
                </Container>
            </header>
        </>
    );
}

export default Header;
