import React, {useEffect, useMemo, useRef, useState} from "react";
import useRouter from "../../../../../hooks/use-router";
import routes from "../../../../../routes";
import {ProductsSearchTypes} from "../../../../../../core/constants/enums";
import {Col, Row} from "reactstrap";
import ValidateMessages from "../../../../../../core/constants/validate-messages";
import classnames from "classnames";
import ProductSearchUtils from "../../../../../../core/services/utils/product-search-utils";
import {deepEqual} from "../../../../../../core/services/utils/utils";
import * as Yup from "yup";
import {makeRequired, makeValidate} from "mui-rff";
import Select from "../../../../base/select";
import {ReactComponent as CategoryIcon} from "../../../../../../assets/images/input-icons/category.svg";
import Form from "../../../../base/form";

const formKeys = ProductSearchUtils.applicationSearchFormKeys;
const queryKeys = ProductSearchUtils.applicationSearchQueryKeys;

const schema = Yup.object().shape({
    [formKeys.make]: Yup.number().required().typeError(ValidateMessages.incorrectType("make")),
    [formKeys.model]: Yup.number().nullable().typeError(ValidateMessages.incorrectType("model")),
    [formKeys.year]: Yup.number().nullable().typeError(ValidateMessages.incorrectType("year")),
    [formKeys.orderBy]: Yup.string().nullable(),
});

const validate = makeValidate(schema);
const required = makeRequired(schema);

const initialMakeOption = {
    id: -1,
    value: null,
    title: "All Makes",
    disabled: true,
}

const initialModelOption = {
    id: -1,
    value: null,
    title: "All Models",
    disabled: true,
}

const initialYearOption = {
    id: -1,
    value: null,
    title: "All Years",
    disabled: true,
}

const initialOrderBy = {
    name: '-1',
    title: "order by",
    disabled: true,
}

const ProductsSearchApplicationForm = ({formData, data: dataProp}) => {
    const {history, stringifyUrl} = useRouter();
    const [initialFormValues, setInitialFormValues] = useState({});
    const prevDataProp = useRef({});

    const makes = useMemo(() => [
        initialMakeOption,
        ...(formData?.makeAndModels?.map(e => ({
            id: e.id,
            value: e.id,
            title: e.name,
        })) ?? []),
    ], [formData?.makeAndModels])

    const orderBys = useMemo(() => [
        initialOrderBy,
        ...(formData.orderByList?.map(e => ({
            name: e.name,
            title: e.title,
        })) ?? [])
    ], [formData?.orderByList])

    /**
     * Listens for the changes in dataProp and with each change:
     * - syncs dataProp with the data in the state.
     */
    useEffect(() => {
        if (!formData.makeAndModels?.length) {
            return;
        }
        if (!Object.keys(dataProp).length) {
            setInitialFormValues({});
            prevDataProp.current = {}
            return
        }
        if (deepEqual(dataProp, prevDataProp.current)) {
            return;
        }
        const [newData, removed] = ProductSearchUtils.parseApplicationSearchQuery(dataProp, {
            makeAndModels: formData.makeAndModels,
            orderByList: formData.orderByList,
        });
        setInitialFormValues(newData)
        prevDataProp.current = dataProp
        if (removed?.length) {
            search(newData)
        }
    }, [dataProp, formData])

    /**
     * Resets the values of model and year dropdowns with each change in the make dropdown value.
     * @param {Event} e
     * @param {formApi} form
     */
    const onMakeChanged = (e, form) => {
        const {name, value} = e.target;
        form.change(name, value);
        form.change(formKeys.model, undefined);
        form.change(formKeys.year, undefined);
    }

    /**
     * Resets the value of year dropdown with each change in the model dropdown value.
     * @param {Event} e
     * @param {formApi} form
     */
    const onModelChanged = (e, form) => {
        const {name, value} = e.target;
        form.change(name, value);
        form.change(formKeys.year, undefined);
    }

    /**
     * Changes the query of the url with the given search values.
     * @param {any} _values the form values.
     * @param {boolean} form
     */
    const search = (_values, form = false) => {
        const url = stringifyUrl({
            url: routes.main.products,
            query: {
                type: ProductsSearchTypes.application,
                data: JSON.stringify({
                    [ProductsSearchTypes.application]: ProductSearchUtils.transformFormValuesToSearchQuery(formKeys, _values, {
                        [queryKeys.make]: formKeys.make,
                        [queryKeys.model]: formKeys.model,
                        [queryKeys.year]: formKeys.year,
                        [queryKeys.orderBy]: formKeys.orderBy,
                    }),
                })
            },
        })

        if (form)
            history.push(url);
        else
            history.replace(url);
    };

    return (
        <>
            <Form
                onSubmit={(v) => search(v, true)}
                validate={validate}
                initialValues={initialFormValues}
                render={({form, values}) => {
                    const models = [
                        initialModelOption,
                        ...(formData?.makeAndModels
                                ?.find(e => e.id === Number(values[formKeys.make]))
                                ?.models
                                ?.map(e => ({
                                    id: e.id,
                                    value: e.id,
                                    title: e.name,
                                }))
                            ?? [])
                    ];
                    const years = [
                        initialYearOption,
                        ...(formData?.makeAndModels
                                ?.find(e => e.id === Number(values[formKeys.make]))
                                ?.models
                                ?.find(e => e.id === Number(values[formKeys.model]))
                                ?.years
                                ?.map(e => ({
                                    id: e,
                                    value: e,
                                    title: e,
                                }))
                            ?? [])
                    ]
                    const showModels = !!values[formKeys.make] && models?.length > 1
                    const showYears = showModels && !!values[formKeys.model] && years?.length > 1
                    return (
                        <>
                            <Row form>
                                <Col md={showModels ? 6 : 12} xs={12}
                                     className={'mb-3'}>
                                    <Select
                                        form
                                        name={formKeys.make}
                                        required={required[formKeys.make]}
                                        value={values[formKeys.make] ?? makes[0].id}
                                        renderValue={e => makes?.find(c => c.id === e)?.title}
                                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                                        onChange={(e) => onMakeChanged(e, form)}
                                        data={makes?.map(e => ({
                                            value: e.value,
                                            label: e.title,
                                            id: e.id,
                                            disabled: e.disabled,
                                        }))}
                                    />
                                </Col>
                                <Col md={6} xs={12}
                                     className={classnames('mb-3', {'w-0': !showModels})}>
                                    <Select
                                        form
                                        name={formKeys.model}
                                        required={required[formKeys.model]}
                                        value={values[formKeys.model] ?? models[0].id}
                                        renderValue={e => models?.find(c => c.id === e)?.title}
                                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                                        onChange={(e) => onModelChanged(e, form)}
                                        data={models?.map(e => ({
                                            value: e.value,
                                            label: e.title,
                                            id: e.id,
                                            disabled: e.disabled,
                                        }))}
                                    />
                                </Col>
                                <Col md={4} xs={12}
                                     className={classnames('mb-3', {'w-0': !showYears})}>
                                    <Select
                                        form
                                        name={formKeys.year}
                                        required={required[formKeys.year]}
                                        value={values[formKeys.year] ?? years[0].id}
                                        renderValue={e => years?.find(c => c.id === e)?.title}
                                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                                        data={years?.map(e => ({
                                            value: e.value,
                                            label: e.title,
                                            id: e.id,
                                            disabled: e.disabled,
                                        }))}
                                    />
                                </Col>
                                <Col xs={12} md={showYears ? 8 : 12} className={'mb-3'}>
                                    <Select
                                        form
                                        name={formKeys.orderBy}
                                        required={required[formKeys.orderBy]}
                                        value={values[formKeys.orderBy] ?? orderBys[0].name}
                                        renderValue={e => orderBys?.find(c => c.name === e)?.title}
                                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                                        data={orderBys?.map(e => ({
                                            value: e.name,
                                            label: e.title,
                                            id: e.name,
                                            disabled: e.disabled,
                                        }))}
                                    />
                                </Col>
                                <Col
                                    xs={12}
                                    className={'d-flex flex-column align-items-center justify-content-start mt-2'}
                                >
                                    <Col md={5} lg={3} className={'p-0'}>
                                        <button className={'button primary w-100 px-5'} type={'submit'}>
                                            Search
                                        </button>
                                    </Col>
                                </Col>
                            </Row>
                        </>
                    );
                }}
            />
        </>
    );
}

export default ProductsSearchApplicationForm;
