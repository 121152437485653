import React, {useLayoutEffect, useRef} from "react";
import {PrintInvoiceViewTableTypes} from "../../../../../core/constants/enums";
import {defaultPrintInvoiceViewPadding, getElements} from "../index";
import {createUUId} from "../../../../../core/services/utils/utils";

const PrintInvoiceViewPage = ({page}) => {
    /**@type {React.MutableRefObject<HTMLTableElement | null>}*/
    const pageRef = useRef(null);
    const productsFillerId = useRef(createUUId(true));

    /**
     * With any changes in page or PageRef:
     * - clears the table entries (content of the page)
     * - populates the page with the proper entries based on the values of the page.
     */
    useLayoutEffect(() => {
        if (!pageRef.current) return;
        clearTable();
        const elements = getElements();
        setPageElements(fetchTemplateElements(page, elements));
        requestAnimationFrame(() => {
            fillRemainingSpots(page, elements);
        })
    }, [page, pageRef])


    /**
     *  Clears the table entries (content of the page) except for the col-span-group tag
     */
    const clearTable = () => {
        for (const index of pageRef.current.childNodes.keys()) {
            if (index !== 0) {
                pageRef.current.removeChild(pageRef.current.childNodes.item(index));
            }
        }
    }

    /**
     * Fetches the page elements from the template to be shown in the page.
     * @param {PrintInvoicePage} page
     * @param {any} elements
     */
    const fetchTemplateElements = (page, elements) => {
        const result = [
            elements.pageHeader.cloneNode(true),
        ];
        for (const entity of page.entities) {
            switch (entity.name) {
                case PrintInvoiceViewTableTypes.products: {
                    const body = createTableBody(entity.name, entity.range, elements);
                    result.push(elements.productsTable.header.cloneNode(true));
                    if (body) {
                        result.push(body);
                    }
                    if (entity.useContinueFooter) {
                        result.push(elements.productsTable.footerContinue.cloneNode(true));
                    } else {
                        result.push(elements.productsTable.footer.cloneNode(true));
                    }
                    if (page.entities.length > 1) {
                        // notes also exist in this page so add the divider
                        result.push(elements.tablesDistance.cloneNode(true));
                    }
                    break;
                }
                case PrintInvoiceViewTableTypes.notes: {
                    const body = createTableBody(entity.name, entity.range, elements);
                    result.push(elements.notesTable.header.cloneNode(true));
                    if (body) {
                        result.push(body);
                    }
                    if (entity.useContinueFooter) {
                        result.push(elements.notesTable.footerContinue.cloneNode(true))
                    }
                    break;
                }
                default:
                    break;
            }
        }
        result.forEach((e) => {
            e?.removeAttribute('id')
        })
        return result;
    }

    /**
     * Creates the body entries of the page based on given range and type.
     * @param {string} type
     * @param {[number, number]} range
     * @param {any} elements
     * @return {Node}
     */
    const createTableBody = (type, range, elements) => {
        if (!range.length) {
            return null;
        }
        let element;
        switch (type) {
            case PrintInvoiceViewTableTypes.products:
                element = elements.productsTable.body.cloneNode(true);
                break;
            case PrintInvoiceViewTableTypes.notes:
                element = elements.notesTable.body.cloneNode(true);
                break;
            default:
                break;
        }
        const children = [...element.childNodes.values()];
        for (let i = 0; i < children.length; i++) {
            if (i > range[1] || i < range[0]) {
                element.removeChild(children[i]);
            }
        }

        const spacerElements = [
            elements.productsTable.bodyFiller.id,
        ]
        for (const elementElement of element.children) {
            if (spacerElements.includes(elementElement.id)) {
                elementElement.setAttribute('id', productsFillerId.current);
            }
        }
        return element;
    }

    /**
     * Injects the given elements into the page content.
     * - this method also sets the page number of the current page.
     * @param elements
     */
    const setPageElements = (elements) => {
        pageRef.current.append(...elements);
        const pageIdentifier = pageRef.current.getElementsByClassName('page-identifier')?.item(0);
        if (!pageIdentifier) {
            return;
        }
        pageIdentifier.innerText = `Page ${page.pageNumber} of ${page.totalPages}`;
    }

    /**
     * Fills the reining spot of the products' table in the current page.
     * @param page
     * @param elements
     */
    const fillRemainingSpots = (page, elements) => {
        const remainingHeight = Math.max(
            elements.page.clientHeight
            - (pageRef.current?.clientHeight ?? 0)
            - defaultPrintInvoiceViewPadding * 2,
            0
        );
        if (remainingHeight === 0)
            return;
        if (!page.entities.find(e => e.name === PrintInvoiceViewTableTypes.products)) {
            return;
        }
        const filler = document.getElementById(productsFillerId.current);
        if (filler) {
            filler.setAttribute('height', `${remainingHeight}px`);
        }
    }


    return (
        <div className={'print-invoice-page'}>
            <table ref={pageRef} width={'100%'} className={'table'}>
                <colgroup>
                    {
                        Array(60).fill(1).map((e, index) => (
                            <col key={index} width="1.6666666666667%"/>
                        ))
                    }
                </colgroup>
            </table>
        </div>
    );
}


export default PrintInvoiceViewPage;
