import React, {useContext, useMemo, useRef, useState} from "react";
import {DataGridMiscContext, DataGridStateContext} from "../../../../index";
import classnames from "classnames";
import {DataGridColumnAlignments, DataGridColumnTypes, DataGridExoticColumnFields} from "../../../../models";
import {ReactComponent as ChildTogglerIcon} from "../../../../assets/images/body/child-toggler.svg";
import {ReactComponent as GroupTogglerIcon} from "../../../../assets/images/body/group-toggler.svg";
import DataGridCheckbox from "../../../components/checkbox";
import DataGridIconButton from "../../../components/icon-button/inedx";
import DataGridUtils from "../../../../core/services/utils";
import {Popover, Tooltip} from "@mui/material";
import DataGridCellEditor from "../cell-editor";
import * as ReactIs from "react-is";


/**
 * @param {DataGridInternalCell} cell
 * @param {DataGridDensities} density
 * @param {string} rowKey
 * @param {Function} setDetailedPanelOpen
 * @param {Function} toggleSelection
 * @param {boolean} detailedPanelOpen
 * @param {boolean} hasDetailedPanel
 * @param {boolean} rowSelected
 * @param {boolean} detailedPanelVisible
 * @param {boolean} groupedRowsOpen
 * @param {boolean} groupedRowsVisible
 * @param {Function} setGroupedRowsOpen
 * @param {Function} startScrolling
 * @return {JSX.Element}
 * @constructor
 */
const DataGridBodyCell = ({
                              cell,
                              rowKey,
                              density,
                              setDetailedPanelOpen,
                              toggleSelection,
                              detailedPanelOpen,
                              rowSelected,
                              hasDetailedPanel,
                              detailedPanelVisible,
                              groupedRowsOpen,
                              groupedRowsVisible,
                              setGroupedRowsOpen,
                              startScrolling: startScrollingProp,
                          }) => {
    const {group} = useContext(DataGridStateContext)
    const {classNames, singleSelectionOnly} = useContext(DataGridMiscContext);
    const [popover, setPopover] = useState(null);
    const id = useRef(DataGridUtils.createUUId(true));
    const cellRef = useRef();
    const innerCellRef = useRef();

    /**@type {DataGridInternalColumn}*/
    const column = useMemo(() => cell?.column, [cell?.column])

    const showRowToggler = useMemo(() =>
            column?.name && column?.name === group?.showOnColumn,
        [group?.showOnColumn, column?.name])

    /**
     * Memo version of the boolean indicating if secondary font family is needed
     * @type {boolean}
     */
    const needsDifferentFont = useMemo(() => [DataGridColumnTypes.money, DataGridColumnTypes.number].includes(column?.type), [column?.type])

    const width = useMemo(() =>
            `${Math.max(column?.width?.size ?? 0, column?.width?.minWidth ?? 0)}px`,
        [column.width])

    const pinOffset = useMemo(() =>
            !column?.pinned
                ? undefined
                : `${column?.leftPinOffset ?? column?.rightPinOffset}px`,
        [column?.pinned, column?.leftPinOffset, column?.rightPinOffset])

    /**@type {'right' | 'left' | 'center'}*/
    const popoverHorizontalPosition = useMemo(() => {
        switch (column?.alignment) {
            case DataGridColumnAlignments.center:
                return 'center';
            case DataGridColumnAlignments.left:
            default:
                return 'right';
            case DataGridColumnAlignments.right:
                return 'left';
        }
    }, [column?.alignment])

    const editable = useMemo(() =>
            cell?.content?.editable &&
            React.isValidElement(cell?.content?.EditableContentComponent),
        [cell?.content?.editable, cell?.content?.EditableContentComponent])

    /**
     * Initiates the scrolling of the data-grid content if the column is not pinned.
     * @param {MouseEvent | TouchEvent} e
     */
    const startScrolling = (e) => {
        if (!column || column.pinned) {
            return;
        }
        startScrollingProp(e);
    }

    /**
     * Closes the popover
     */
    const closePopover = () => setPopover(null);

    /**
     * Opens the popover
     * @param {Event} e
     */
    const openPopover = (e) => {
        if (!editable)
            return;
        setPopover(e.currentTarget)
    };

    const groupedRowToggler = useMemo(() => (
        cell?.showGroupToggler && cell?.groupedRowsLength > 0
            ? <Tooltip
                arrow
                title={`${groupedRowsVisible ? "Collapse" : "Expand"} ${cell?.groupedRowsLength} ${cell?.groupedRowsLength !== 1 ? 'rows' : 'row'}`}
            >
                <DataGridIconButton
                    className={classnames(
                        'data-grid-group-toggler-button',
                        {'open': groupedRowsVisible},
                    )}
                    onClick={() => setGroupedRowsOpen(prevState => !prevState)}
                >
                    <GroupTogglerIcon/>
                </DataGridIconButton>
            </Tooltip>
            : <DataGridIconButton
                className={classnames(
                    'data-grid-group-toggler-button invisible',
                    {'open': groupedRowsVisible},
                    {'half-margin': cell?.groupedRowsLength <= 0},
                )}
            >
                <GroupTogglerIcon/>
            </DataGridIconButton>
    ), [groupedRowsVisible, cell?.showGroupToggler, cell?.groupedRowsLength])

    /**
     * Generates the element of the row cell content based on the column.
     *
     * @param {DataGridCellContent} content
     * @return {JSX.Element}
     */
    const cellContent = useMemo(() => {
        const content = cell?.content?.value;
        let cellContent;

        if (typeof content === 'undefined' || content === null)
            cellContent = '--';
        else if (typeof content === 'function') {
            cellContent = content({
                format: column?.format,
                detailedPanelOpen: detailedPanelOpen,
                toggleDetailedPanelOpen: () => setDetailedPanelOpen(prevState => !prevState),
                groupedRowsOpen: groupedRowsOpen,
                toggleGroupedRowsOpen: () => setGroupedRowsOpen(prevState => !prevState),
                rowSelected: rowSelected,
                toggleRowSelection: toggleSelection,
                openEditPopover: () => openPopover({currentTarget: cellRef.current}),
            });
        } else {
            if (typeof column?.format === 'function')
                cellContent = column?.format(content);
            else if (React.isValidElement(content))
                cellContent = content;
            else if (typeof content === 'number' && column?.type === DataGridColumnTypes.number) {
                cellContent = content.toFixed(2);
            } else {
                cellContent = content;
            }
        }
        return cellContent;
    }, [
        cell?.content?.value,
        column?.format,
        column?.type,
        detailedPanelOpen,
        rowSelected,
        groupedRowsOpen,
    ])

    const defaultCellContent = useMemo(() => {
        const childrenProps = {
            onMouseDown: e => e.stopPropagation(),
            onMouseMove: e => e.stopPropagation(),
            onTouchStart: e => e.stopPropagation(),
            onTouchMove: e => e.stopPropagation(),
        }

        let children = cellContent;
        if (React.isValidElement(children)) {
            if (React.Children.count(children) === 1) {
                const firstChild = React.Children.only(children);
                if (ReactIs.typeOf(firstChild) === React.Fragment) {
                    children = firstChild.props.children;
                }

            }

            return (
                <>
                    {showRowToggler && groupedRowToggler}
                    {React.Children.map(
                        children,
                        (child) => child && React.cloneElement(child, childrenProps)
                    )}
                </>
            );
        }

        return (
            <>
                {showRowToggler && groupedRowToggler}
                <p {...childrenProps} className={'data-grid-row-body-inner-cell-text'}>
                    {children ?? '--'}
                </p>
            </>
        );
    }, [
        cellContent,
        groupedRowToggler,
        showRowToggler
    ])

    const content = useMemo(() => {
        switch (column?.name) {
            case DataGridExoticColumnFields.selection:
                return <>
                    <DataGridCheckbox
                        checked={rowSelected}
                        onClick={toggleSelection}
                        radio={singleSelectionOnly}
                        className={classnames({
                            'show-on-hover': singleSelectionOnly,
                            'force-show': rowSelected,
                        })}
                    />
                </>
            case DataGridExoticColumnFields.detailedPanelToggler:
                if (!hasDetailedPanel)
                    return <></>;
                return <>
                    <DataGridIconButton
                        className={classnames(
                            'data-grid-child-toggler-button',
                            {'open': detailedPanelVisible},
                        )}
                        onClick={() => setDetailedPanelOpen(prevState => !prevState)}
                    >
                        <ChildTogglerIcon/>
                    </DataGridIconButton>
                </>
            case DataGridExoticColumnFields.detailedPanel:
            case DataGridExoticColumnFields.spacer:
            case undefined:
            case null:
                // child column should never be rendered in the first place. this is just a
                // placeholder in case of any bugs.
                return <></>
            default:
                return defaultCellContent
        }
    }, [
        hasDetailedPanel,
        defaultCellContent,
        column?.name,
        rowSelected,
        detailedPanelVisible,
        singleSelectionOnly,
    ]);

    return (
        <>
            <td
                onMouseDown={startScrolling}
                onTouchStart={startScrolling}
                onDoubleClick={openPopover}
                ref={cellRef}
                className={classnames(
                    'data-grid-row-body-cell',
                    column?.alignment,
                    classNames.cell,
                    {
                        'pinned': column?.pinned,
                        [column?.pinnedType]: column?.pinned,
                        'first-right-pinned': column?.pinned && cell?.firstRightPinned,
                        'last-left-pinned': column?.pinned && cell?.lastLeftPinned,
                        'detailed-panel-visible': detailedPanelVisible,
                        'editable': editable,
                        'secondary': needsDifferentFont,
                    },
                    {
                        [classNames.cellEditable]: editable,
                    }
                )}
                style={{
                    '--width': width,
                    '--offset': pinOffset,
                }}>
                <div
                    className={classnames(
                        'data-grid-row-body-inner-cell',
                        classNames.innerCell,
                        {[density]: true},
                        {'data-grid-row-body-inner-cell-custom-element': React.isValidElement(cellContent)}
                    )}
                    ref={innerCellRef}
                >
                    {content}
                </div>
            </td>
            {
                editable &&
                <Popover
                    id={!!popover ? id.current : undefined}
                    elevation={2}
                    open={!!popover}
                    onClose={closePopover}
                    anchorReference={'anchorEl'}
                    className={classnames(
                        "data-grid-popover",
                        "data-grid-cell-editor-popover",
                        classNames.cellEditorPopover,
                    )}
                    classes={{
                        paper: classnames(
                            'data-grid-popover-paper',
                            'data-grid-cell-editor-popover-paper',
                            classNames.cellEditorPopoverPaper,
                        ),
                    }}
                    anchorEl={popover}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: popoverHorizontalPosition,
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: popoverHorizontalPosition,
                    }}>
                    <DataGridCellEditor
                        initialValue={cell?.content?.editableValue}
                        ContentComponent={cell?.content?.EditableContentComponent}
                        colName={column?.name}
                        rowKey={rowKey}
                        close={closePopover}
                    />
                </Popover>
            }
        </>
    )
}

export default DataGridBodyCell;
