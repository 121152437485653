import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as SearchIcon} from "../../../../assets/images/search-icon.svg";
import AutoComplete from "./index";
import api from "../../../../core/services/api/api";
import {partApis} from "../../../../core/constants/endpoints/endpoints";
import {apiMethods} from "../../../../core/constants/enums";
import LazyImage from "../../app-specific/lazy-lmage";
import {formatMoney} from "../../../../core/services/utils/utils";
import useIsMounted from "../../../hooks/use-is-mounted";
import axios from "axios";
import classnames from "classnames";
import {CircularProgress} from "@material-ui/core";

const searchPaginationInfo = {
    currentPage: 1,
    pageSize: 5,
    length: 0,
}

const ProductsAutoComplete = ({onSelect, paginationInfo = searchPaginationInfo}) => {
    const [autoCompleteLoading, setAutoCompleteLoading] = useState(false);
    const [selectingPart, setSelectingPart] = useState(false);
    const [items, setItems] = useState([]);
    const [autoCompleteText, setAutoCompleteText] = useState('');
    const [open, setOpen] = useState(false);
    const isMounted = useIsMounted();
    const triedToOpen = useRef(false);

    /**
     * Listens for the changes in autoCompleteText and with each change:
     * - sets a timer that at the end of which, calls the search api.
     */
    useEffect(() => {
        if (!(autoCompleteText?.length > 2))
            return;
        let axiosCancelToken;
        const timer = setTimeout(() => {
            if (!isMounted() || !(autoCompleteText?.length > 2)) return;
            axiosCancelToken = axios.CancelToken.source()
            search(autoCompleteText, axiosCancelToken).then();
        }, 500)
        return () => {
            timer && clearTimeout(timer);
            axiosCancelToken && axiosCancelToken.cancel('cancelled');
        };
    }, [autoCompleteText])


    /**
     * Searches for the products in the system with the provided keywords as the filters.
     * @param {string} keyword
     * @param {import("axios").CancelTokenSource} cancelToken
     * @return {Promise<void>}
     */
    const search = async (keyword, cancelToken) => {
        setAutoCompleteLoading(true);
        if (triedToOpen.current) {
            setOpen(true);
        }
        const response = await api({
            url: partApis.basicSearch,
            method: apiMethods.post,
            data: {paginationInfo: paginationInfo, filters: {keyword: keyword}},
            extra: {cancelToken: cancelToken.token},
            showError: false,
        });
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setAutoCompleteLoading(false);
            return;
        }
        setItems(response?.data?.resultList ?? []);
        if (triedToOpen.current) {
            setOpen(true);
            triedToOpen.current = false;
        }
        setAutoCompleteLoading(false);
    }

    /**
     * Sets the autoComplete text state from the changes in auto complete component.
     *
     * if the reason is clear or reset, then the text is set to an empty string and the items are reset
     * @param {Event} e
     * @param {string} value
     * @param {import("@material-ui/lab").AutocompleteInputChangeReason} reason
     */
    const onInputChanged = (e, value, reason) => {
        if (['clear', 'reset'].includes(reason)) {
            setItems([]);
            return setAutoCompleteText('');
        }
        setAutoCompleteText(value)
    }

    /**
     * Invokes the onSelect callback with the selected product only if the product is not null.
     * @param {Event} event
     * @param {any} product the selected product
     * @return {Promise<void>}
     */
    const onProductSelected = async (event, product) => {
        if (!product) return;
        setSelectingPart(true);
        await onSelect(product);
        if (!isMounted())
            return;
        setSelectingPart(false);
    }

    /**
     * Opens the popover only if loading or we have options to show otherwise sets the flag to open later
     */
    const openPopover = () => {
        if (items?.length || autoCompleteLoading) {
            setOpen(true);
        } else {
            triedToOpen.current = true;
        }
    }

    /**
     * Closes the popover and sets the open for later to false
     */
    const closePopover = () => {
        setOpen(false);
        triedToOpen.current = false;
    }

    /**
     * Renders the auto complete options which are the products of the system.
     * @param {any} option the product.
     * @return {JSX.Element}
     */
    const renderOption = (option) => {
        const price = option.changedPrice ?? option.originalPrice ?? 0;
        return (
            <div className={'product-auto-complete-popover-option'}>
                <div className={'img'}>
                    <LazyImage src={option.cover?.url} alt={option.partNumber}/>
                </div>
                <p className={'part-no'}>
                    {option?.partNumber}
                </p>
                <p className={classnames('price', {'not-available': price <= 0})}>
                    {
                        price <= 0
                            ? "Currently Unavailable"
                            : formatMoney(price)
                    }
                </p>
            </div>
        )
    }

    return (
        <>
            <AutoComplete
                clearOnBlur
                clearOnEscape
                clearOnSelect
                openOnFocus={false}
                open={open}
                onOpen={openPopover}
                onClose={closePopover}
                loading={autoCompleteLoading}
                onInputChange={onInputChanged}
                onChange={onProductSelected}
                options={items}
                value={null}
                disabled={selectingPart}
                placeholder={'search Part No, OE, or AM...'}
                autoCapitalize={'characters'}
                renderOption={renderOption}
                getOptionLabel={e => e?.partNumber ?? ''}
                filterOptions={options => options}
                popupIcon={
                    selectingPart
                        ? <CircularProgress size={25} color={'primary'}/>
                        : <SearchIcon
                            type={'button'}
                            tabIndex={-1}
                            aria-label={'open'}
                            className={'search-icon'}
                        />
                }
                loadingText={
                    <div className={'w-100'}>
                        {Array(5).fill(null).map((e, index) => (
                            <div key={index} className={'loading normal mb-2'}>
                                <div/>
                            </div>
                        ))}
                    </div>
                }
            />
        </>
    )
}

export default ProductsAutoComplete;
