import {
    DefaultDynamicContentEditorState,
    DynamicContentEditorApiConfiguration,
    DynamicContentEditorApiInterceptors,
    DynamicContentEditorApiResponseDS,
    DynamicContentEditorPageEntry,
} from "../../../index.d";
import EnvService from "../env-service";
import DynamicContentEditorApiExecutor from "./executor";
import DynamicContentEditorUtils from "../utils";
import DynamicContentEditorTexts from "../../static/text";
import {toast} from "react-toastify";
import axios from "axios";


/**
 * The interface that houses all the available api calls related to the dynamic-content-editor server.
 */
class DynamicContentEditorApi {

    /**
     * The configuration of the api interface.
     *
     * * NOTE: the configuration can be set anywhere. It is useful for when an application / package would want to
     * set these constant values.
     */
    private static _configuration: DynamicContentEditorApiConfiguration = DefaultDynamicContentEditorState.apiConfiguration;

    /**
     * Fetches the configuration of this api interface.
     */
    static get configuration(): DynamicContentEditorApiConfiguration {
        return this._configuration;
    }

    /**
     * Sets the configuration value of the Api.
     *
     * * instead of replacing the configuration, it merges the current config with the given value.
     * @param {Partial<DynamicContentEditorApiConfiguration>} value
     */
    static set configuration(value: Partial<DynamicContentEditorApiConfiguration>) {
        this._configuration = {
            ...this._configuration,
            ...value,
        };
    }

    /**
     * The executor of this api responsible for handling the api call themselves.
     * @private
     */
    private static readonly executor = new DynamicContentEditorApiExecutor();

    /**
     * The instance with which all the api calls are handled.
     * @private
     */
    private static readonly instance = axios.create();
    /**
     * The list of interceptor ids that the current instance uses.
     * @private
     */
    private static readonly instanceInterceptorIds: { request: Array<number>, response: Array<number> } = {
        request: [],
        response: []
    };

    /**
     * Injects api interceptors to the axios instance used in this interface.
     * @param {{
     *   request: Array<RequestInterceptor>,
     *   response: Array<ResponseInterceptor>
     *}} interceptors
     */
    static injectInterceptors(interceptors: Partial<DynamicContentEditorApiInterceptors>) {
        for (const id of this.instanceInterceptorIds.request)
            this.instance.interceptors.request.eject(id);
        for (const id of this.instanceInterceptorIds.response)
            this.instance.interceptors.response.eject(id);
        for (const [onFulfilled, onRejected] of interceptors.request ?? [])
            this.instanceInterceptorIds.request.push(this.instance.interceptors.request.use(onFulfilled, onRejected));
        for (const [onFulfilled, onRejected] of interceptors.response ?? [])
            this.instanceInterceptorIds.response.push(this.instance.interceptors.response.use(onFulfilled, onRejected));
    }


    /**
     * Validates the token specific for enabling the edit mode of this package.
     *
     * * the baseUrl is used to construct the url-endpoint for this api.
     * @param {string} token
     */
    static async validateToken(
        token: string,
    ): Promise<undefined | DynamicContentEditorApiResponseDS> {
        if (!this._configuration.validateTokenUrl) {
            if (EnvService.isDevelopment) {
                console.error(
                    'The validate-token api was called without setting the url for validateToken in the api' +
                    ' configuration.'
                )
            }
            return undefined;
        }
        const data = {
            token: token,
        }
        const headers = {};
        const response = await this.executor.execute(this.instance.post, DynamicContentEditorApi.configuration.validateTokenUrl, data, {headers})
        if (!response?.resultFlag) {
            const message = response?.message?.length ? response?.message : DynamicContentEditorTexts.validationErrorMessage;
            DynamicContentEditorUtils.showToast(
                message,
                {type: toast.TYPE.ERROR, autoClose: 4000}
            )
        }
        return response;
    }

    /**
     * Fetches the information of a specific page.
     *
     * * if the [getDataUrl] is not set in the [configuration] property of this interface prior to calling the
     * api, then nothing happen.
     * @param {string} page
     */
    static async getPageData(
        page?: string,
    ): Promise<undefined | DynamicContentEditorApiResponseDS> {
        if (!this._configuration.getPageDataUrl) {
            if (EnvService.isDevelopment) {
                console.error(
                    'The get-page-data api was called without setting the url for getDataUrl' +
                    ' in the api configuration.'
                )
            }
            return undefined;
        }
        const params = {route: page}
        const response = await this.executor.execute(this.instance.get, DynamicContentEditorApi.configuration.getPageDataUrl, {params: params})
        if (!response?.resultFlag) {
            const message = response?.message?.length ? response?.message : DynamicContentEditorTexts.getPageDataErrorMessage;
            DynamicContentEditorUtils.showToast(
                message,
                {type: toast.TYPE.ERROR}
            )
        }
        const data = [
            ...DynamicContentEditorUtils.parsePageData(response.data?.sections, false),
            ...DynamicContentEditorUtils.parsePageData(response.data?.fixedSection, true),
        ];
        return {...response, data: data};
    }

    /**
     * Saves the information of a specific entry in a specific page.
     *
     * * if the [savePageEntryUrl] is not set in the [configuration] property of this interface prior to calling the
     * api, then nothing happen.
     * * if the [token] is not set in the [configuration] property of this interface prior to calling the
     * api, then nothing happen.
     * @param {{ page: string, entry: DynamicContentEditorPageEntry }} _data
     */
    static async savePageEntry(
        _data: { page: string, entry: DynamicContentEditorPageEntry },
    ): Promise<DynamicContentEditorApiResponseDS | undefined> {
        if (!this._configuration.savePageEntryUrl) {
            if (EnvService.isDevelopment) {
                console.error(
                    'The save-page-entry api was called without setting the url for saveDataUrl in the api configuration.'
                )
            }
            return undefined;
        }
        if (!this._configuration.token) {
            if (EnvService.isDevelopment) {
                console.error(
                    'The save-page-entry api was called without setting the token in the api configuration'
                )
            }
            return undefined;
        }
        const data = {
            pageRoute: _data.page,
            editToken: this._configuration.token,
            name: _data.entry.name,
            values: _data.entry.data?.map(e => ({
                ...e,
                value: typeof e.value === 'string' ? e.value : JSON.stringify(e.value),
            })) ?? [],
        }
        const response = await this.executor.execute(this.instance.post, DynamicContentEditorApi.configuration.savePageEntryUrl, data)
        if (!response?.resultFlag) {
            const message = response?.message?.length ? response?.message : DynamicContentEditorTexts.savePageEntryErrorMessage;
            DynamicContentEditorUtils.showToast(
                message,
                {type: toast.TYPE.ERROR}
            )
        }
        return response;
    }

    /**
     * Uploads a specific file in the server.
     *
     * * if the [uploadFileUrl] is not set in the [configuration] property of this interface prior to calling the
     * api, then nothing happen.
     * * if the [token] is not set in the [configuration] property of this interface prior to calling the
     * api, then nothing happen.
     * @param {File} file
     * @param {((progressEvent: any) => void)?} onUploadProgress
     */
    static async uploadFile(
        file: File,
        onUploadProgress?: (progressEvent: any) => void,
    ): Promise<DynamicContentEditorApiResponseDS | undefined> {
        if (!this._configuration.uploadFileUrl) {
            if (EnvService.isDevelopment) {
                console.error(
                    'The upload-file api was called without setting the url for uploadFile in the api configuration.'
                )
            }
            return undefined;
        }
        if (!this._configuration.token) {
            if (EnvService.isDevelopment) {
                console.error(
                    'The upload-file api was called without setting the token in the api configuration'
                )
            }
            return undefined;
        }
        const data = new FormData();
        data.append('file', file, file.name);
        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        const response = await this.executor.execute(this.instance.post, this._configuration.uploadFileUrl, data, {
            headers: headers,
            onUploadProgress: onUploadProgress,
        })
        if (!response?.resultFlag) {
            const message = response?.message?.length ? response?.message : DynamicContentEditorTexts.uploadFileErrorMessage;
            DynamicContentEditorUtils.showToast(
                message,
                {type: toast.TYPE.ERROR}
            )
        }
        return {
            ...response,
            configuration: {
                FileBaseURL: EnvService.fileBaseUrl,
            },
        };

    }

}


export default DynamicContentEditorApi;
