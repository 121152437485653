import React from "react";
import {Col, Row} from "reactstrap";
import {
    DynamicContentEditorEntryRecord,
    DynamicContentEditorListEntry,
    DynamicContentEditorModels
} from "../../../../../packages/dynamic-content-editor/index";
import NoImage from "../../../../../assets/images/no-image.png";
import {DCEEntryNames} from "../../../../routes";

const OurTeam = () => {

    return (
        <>
            <Row>
                <Col xs={12} className={'mb-5'}>
                    <p className={'heading small mb-3 ml-5'}>
                        Our Team
                    </p>
                </Col>
                <DynamicContentEditorListEntry
                    isChildRelativePositioned
                    _title={'Team Members'}
                    entryName={DCEEntryNames.aboutUsTeam}
                    dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.pageBased}
                    iconPosition={DynamicContentEditorModels.DynamicContentEditorEntryIconPositions.outward}
                    tag={Col}
                    childTag={'div'}
                    childProps={{
                        lg: 4,
                        sm: 6,
                        xs: 12,
                        className: "team-box",
                    }}
                    className={'d-flex flex-wrap justify-content-center align-items-center'}
                >
                    <div className="img">
                        <DynamicContentEditorEntryRecord
                            recordKey={'image'}
                            _title={'Avatar'}
                            tag={'img'}
                            valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.image}
                            placeholder={NoImage}
                        />
                    </div>
                    <div className={'content'}>
                        <DynamicContentEditorEntryRecord
                            recordKey={'name'}
                            _title={'Name'}
                            tag={'div'}
                            className={'name'}
                            valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                            placeholder={'Member name'}
                        />
                        <DynamicContentEditorEntryRecord
                            recordKey={'role'}
                            _title={'Role'}
                            tag={'div'}
                            className={'jobs'}
                            valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                            placeholder={'Member Role'}
                        />
                    </div>
                </DynamicContentEditorListEntry>
            </Row>
        </>
    );
}

export default OurTeam;
