import React, {useCallback, useMemo, useRef, useState} from "react";
import AutoComplete from "../../../../../components/base/auto-complete";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import {newLookupApis, partCompareApis} from "../../../../../../core/constants/endpoints/endpoints";
import {apiMethods} from "../../../../../../core/constants/enums";
import api from "../../../../../../core/services/api/api";
import {debounce} from "lodash";
import {createUUId} from "../../../../../../core/services/utils/utils";


const CompareViewPartsAutoComplete = ({
                                          onPartAdded,
                                          currentlySelectedParts
                                      }) => {
    const [threadIds, setThreadIds] = useState([]);
    const [adding, setAdding] = useState(false);
    const [data, setData] = useState([]);
    const isMounted = useIsMounted();
    /**@type {React.MutableRefObject<AbortController>}*/
    const abortControllerRef = useRef();

    const selectedPartIds = useMemo(() => currentlySelectedParts?.map(e => e.id) ?? [], [currentlySelectedParts])

    const loading = useMemo(() => threadIds.length > 0, [threadIds]);

    /**
     * Fetches the autocomplete values for the given keywords.
     *
     * @param {string} keywords
     * @return {Promise<void>}
     */
    const getAutocompleteValues = async (keywords) => {
        if (abortControllerRef.current) {
            abortControllerRef.current?.abort();
        }
        abortControllerRef.current = new AbortController();
        let _threadId = createUUId();
        setThreadIds(prevState => [...prevState, _threadId]);
        const response = await api({
            url: newLookupApis.partAutocomplete(keywords),
            method: apiMethods.get,
            extra: {
                signal: abortControllerRef.current.signal,
            }
        });
        if (!isMounted())
            return;
        setThreadIds(prevState => prevState.filter(e => e !== _threadId));
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        setData(response?.data ?? []);
        abortControllerRef.current = undefined;
    }

    /**
     * Checks for validation of input and if so, triggers the debounce function that handles getting autoComplete values
     * @param {string} input
     */
    const onAutoCompleteInputChanged = (input) => {
        if (!input || !isMounted() || (input?.trim().length ?? 0) < 3)
            return;
        debounceFunction(input);
    }


    /*** @type {DebouncedFunc<(function(string): Promise<void>)|*>}*/
    const debounceFunction = useCallback(debounce(getAutocompleteValues, 500), [])

    /**
     * Adds the given part to the current comparison list of the user.
     *
     * @param part
     * @return {Promise<void>}
     */
    const addToCompareList = async (part) => {
        setAdding(true);
        const response = await api({
            url: partCompareApis.addPart,
            method: apiMethods.post,
            data: {
                partId: part.id,
            },
        })
        if (!isMounted())
            return;
        setAdding(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            onPartAdded(part);
        }
    }

    /**
     * Adds the selected value to the compare list of the user
     * @param {Event} e
     * @param {any} value
     * @param {import('@material-ui/lab').AutocompleteChangeReason} reason
     */
    const onValueChanged = (e, value, reason) => {
        if (reason === 'select-option') {
            addToCompareList(value).then()
        }
    }

    return (
        <AutoComplete
            freeSolo
            fullWidth
            clearOnBlur
            clearOnEscape
            clearOnSelect
            loading={loading}
            disabled={adding}
            value={null}
            placeholder={'Auto complete with category'}
            onInputChange={(_, input) => onAutoCompleteInputChanged(input)}
            options={data?.map(e => e)}
            onChange={onValueChanged}
            getOptionLabel={e => e?.partNo ?? ''}
            filterOptions={(options) => options.filter(e => !selectedPartIds.includes(e.id))}
            renderOption={(option, {inputValue}) => {
                const matches = match(option?.partNo, inputValue);
                const parts = parse(option?.partNo, matches);
                return (
                    <div>
                        {parts.map((part, index) => (
                            <span key={index} style={{fontWeight: part.highlight ? 600 : 400}}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                );
            }}
        />
    );
}

export default CompareViewPartsAutoComplete;
