import {configureStore as CStore} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga'
import reducers from './reducers';
import sagas from "./sagas";
import authReduxSlice from "./entities/auth/reducer";
import headerReduxSlice from "./entities/header/reducer";
import profileReduxSlice from "./entities/profile/reducer";
import dialogsReduxSlice from "./entities/dialogs/reducer";

/**
 * Configures the redux store and makes it ready for injection in react.
 */
const configureStore = (initialState) => {
    const sagaMiddleware = createSagaMiddleware();
    const store = CStore({
        reducer: reducers,
        preloadedState: initialState,
        devTools: true,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: {
                    ignoredActions: [
                        authReduxSlice.actions.loginUser.type,
                        authReduxSlice.actions.logoutUser.type,
                        headerReduxSlice.actions.headerHeight.type,
                        headerReduxSlice.actions.headerType.type,
                        profileReduxSlice.actions.updateProfile.type,
                        dialogsReduxSlice.actions.policyDialog.type,
                        dialogsReduxSlice.actions.exportingDialog.type,
                        dialogsReduxSlice.actions.unAuthorizedDialog.type,
                        dialogsReduxSlice.actions.accessDeniedDialog.type,
                        dialogsReduxSlice.actions.confirmationDialog.type,
                    ],
                    ignoredPaths: ['dialogs'],
                }
            }).concat([
                sagaMiddleware,
            ]),
    })

    sagaMiddleware.run(sagas);
    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./reducers', () => {
            const nextRootReducer = require('./reducers');
            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}

/**
 * The Singleton State of the redux store
 */
const store = configureStore();

export default store;
