import React, {useEffect, useMemo, useState} from "react";
import {useDispatch} from "react-redux";
import {setReduxHeaderHeight as headerHeightRedux, setReduxHeaderType} from "../../../redux/entities/header/actions";
import {HeaderTypes} from "../../../core/constants/enums";
import {Col, Container, Row} from "reactstrap";
import {Route, Switch} from "react-router-dom";
import EntryView from "./entry";
import routes, {DCEEntryNames} from "../../routes";
import ResetPasswordView from "./reset-password";
import {defaultHeaderHeight} from "../../../assets/js/sizes";
import EnvService from "../../../core/services/env-service";
import ObserversService from "../../../core/services/observers-service";
import {
    DynamicContentEditorEntry,
    DynamicContentEditorEntryRecord,
    DynamicContentEditorModels
} from "../../../packages/dynamic-content-editor/index";
import {ReactComponent as SphereArtLike} from "../../../assets/images/art-likes/auth-sphere.svg";
import DeleteAccountView from "./delete-account";

const AuthViews = () => {
    const [headerHeight, setHeaderHeight] = useState(0);
    const dispatch = useDispatch();

    /**
     * As soon as the component mounts:
     * - sets the header type and height to be specific for the authentication views.
     * - fetches the sign page data from the server to load the information of the brands, and the video url at the
     *  end of the view.
     */
    useEffect(() => {
        dispatch(setReduxHeaderType(HeaderTypes.auth));
        dispatch(headerHeightRedux(defaultHeaderHeight));

        const observer = ObserversService.newResizeObserver(determineHeaderHeight);
        for (let element of document.getElementsByClassName("app-header")) {
            ObserversService.observeResizeObserver(observer, element);
        }
        determineHeaderHeight();
        return () => {
            ObserversService.disconnectResizeObserver(observer);
        }
    }, [])

    /**
     * set the padding of the header according to the height of the element.
     */
    const determineHeaderHeight = () => {
        const elements = document.getElementsByClassName('app-header');
        if (elements?.length) {
            setHeaderHeight(elements.item(0).clientHeight ?? 0);
        }
    }

    const videoSection = useMemo(() => (
        <section
            className={'auth-view-video'}
            style={{
                marginTop: EnvService.ShowCustomerBrands ? '190px' : '6rem',
            }}
        >
            <Container>
                <Row>
                    <DynamicContentEditorEntry
                        dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.pageBased}
                        iconPosition={DynamicContentEditorModels.DynamicContentEditorEntryIconPositions.outward}
                        entryName={DCEEntryNames.entryVideo}
                        _title={'Landing Page Video'}
                        tag={Col}
                        md={10} xs={12}
                        className={'mx-auto'}
                    >
                        <DynamicContentEditorEntryRecord
                            recordKey={'video-src'}
                            valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.video}
                            _title={'Video source'}
                            title={'Video'}
                            tag={'iframe'}
                            width="100%"
                            height="550"
                            allow={"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"}
                        />
                    </DynamicContentEditorEntry>
                </Row>
            </Container>
        </section>
    ), [])

    return (
        <>
            <main
                className={'auth-view'}
                style={{
                    paddingTop: headerHeight,
                }}
            >
                <Container className={'auth-view-pattern-container-outer'}>
                    <Row>
                        <Col lg={7} xs={12}/>
                        <Col lg={5} xs={12}>
                            <div className={'auth-view-pattern-container'}>
                                <SphereArtLike className={'pattern'}/>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Switch>
                    <Route path={routes.auth.resetPassword} exact>
                        <ResetPasswordView/>
                    </Route>
                    <Route path={routes.auth.deleteAccount} exact>
                        <DeleteAccountView/>
                    </Route>
                    <Route path={routes.auth.base}>
                        <EntryView/>
                    </Route>
                </Switch>
            </main>
            {videoSection}
        </>
    )
}

export default AuthViews;
