import React, {useContext, useEffect, useMemo, useState} from "react";
import {apiMethods, ProductBoxTypes, ProductsSearchTypes} from "../../../../core/constants/enums";
import useRouter from "../../../hooks/use-router";
import useIsMounted from "../../../hooks/use-is-mounted";
import {controller} from "../../../../core/services/api/controller";
import {newLookupApis, partApis, partCompareApis} from "../../../../core/constants/endpoints/endpoints";
import routes, {routeFunctions} from "../../../routes";
import ProductSearchUtils from "../../../../core/services/utils/product-search-utils";
import {Col} from "reactstrap";
import MediumProductBox from "./types/medium";
import LargeProductBox from "./types/large";
import {toast} from "react-toastify";
import SmallProductBox from "./types/small";
import ProductContext from "../../../contexts/product";
import ListProductBox from "./types/list";
import api from "../../../../core/services/api/api";
import ToastService from "../../../../core/services/toast-service";

const ProductBox = ({
                        data: dataProps,
                        type = ProductBoxTypes.medium,
                        SearchToken = null,
                        elasticSearchRequestId = null,
                        elasticSearchQuery = null,
                        isChildOfCarousel = false,
                        fullWidth = false,
                        preventActionButton = false,
                        navigateToPublicProductInfo = false
                    }) => {
    const {enforcedHeight = null} = useContext(ProductContext);
    const {history, stringifyUrl} = useRouter();
    const [data, setData] = useState({});
    const isMounted = useIsMounted();

    const navigationLinkPath = useMemo(() => (
        navigateToPublicProductInfo
            ? {
                pathname: routeFunctions.public.partInformation(data?.partNumber ?? ' ')
            }
            : {
                pathname: routeFunctions.main.single(data?.partNumber ?? ' '),
                ...(SearchToken ? {
                    state: {searchToken: SearchToken}
                } : {})
            }
    ), [navigateToPublicProductInfo, data?.partNumber])

    /**
     * Listens to the changes in data and with each change:
     * - fills in the state information based on the data and whether its oldVersion or not.
     */
    useEffect(() => {
        setData({
            id: dataProps?.id ?? dataProps.Id ?? dataProps.PartId ?? 0,
            imageUrl: dataProps.cover?.url ?? dataProps.coverImage?.url ?? dataProps?.ImageUrl ?? dataProps?.coverImageURL ?? dataProps?.coverImageFileName ?? '',
            partNumber: dataProps?.partNumber ?? dataProps?.PartNumber ?? dataProps?.partNo ?? '',
            codeList: dataProps?.codeList ?? dataProps?.CodeList ?? dataProps?.codes ?? [],
            originalPrice: dataProps?.originalPrice ?? dataProps?.OriginalPrice ?? 0,
            changedPrice: dataProps?.price ?? dataProps?.changedPrice ?? dataProps?.originalPrice ?? dataProps?.ChangedPrice ?? dataProps?.OriginalPrice ?? 0,
            saved: dataProps?.IsSaved ?? dataProps?.isSaved ?? false,
            isCompared: dataProps?.IsInCompareList ?? dataProps?.isInCompareList ?? dataProps?.isInCompare ?? false,
            category: dataProps?.category ?? dataProps?.Category,
            categoryTitle: dataProps?.CategoryTitle ?? '',
            saving: false,
            comparing: false,
            tags: dataProps?.tags?.map(e => ({
                id: e.id,
                name: e.name,
                iconFileName: e.iconFileName ?? e.iconInfo?.url,
            })) ?? [],
        });
    }, [dataProps]);

    /**
     * Disables the animation of actions buttons for a limited time
     * * Prevents animation being flickered when window's view port has changed (mainly used for carousel)
     * @param element
     */
    const preventAnimation = (element) => {
        const animatedButtons = element.getElementsByClassName('animating-icon-button');
        for (const animatedButton of animatedButtons) {
            animatedButton.style.setProperty('animation-duration', '0s');
            setTimeout(() => {
                if (!isMounted()) return;
                animatedButton.style.removeProperty('animation-duration');
            }, 301)
        }
    }

    /**
     * Changes the isInCompareList value of the part in the server. Parts that are in compare list can then be
     * fetched separately in comparison view.
     *
     * @param {MouseEvent} e
     * @return {Promise<void>}
     */
    const toggleInCompareList = async (e) => {
        e.stopPropagation()
        e.preventDefault()
        setData(prevState => ({
            ...prevState,
            comparing: true,
        }));
        if (data?.isCompared) {
            await removeFromCompareList();
        } else {
            await addToCompareList();
        }
        if (!isMounted())
            return;
        setData(prevState => ({
            ...prevState,
            comparing: false,
        }));
    }

    /**
     * Removes the curren product from the comparison list of the user.
     * @return {Promise<void>}
     */
    const removeFromCompareList = async () => {
        const response = await api({
            url: partCompareApis.removePart(data.id),
            method: apiMethods.delete,
        })
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            setData(prevState => ({
                ...prevState,
                isCompared: false,
            }));
        }
    }

    /**
     * Navigates user to compare list screen using the history
     *
     * @return void
     */
    const onViewCompareListClicked = () => {
        history.push(routes.dashboard.compare);
    }

    /**
     * Adds the current part to the comparison list of the user.
     * @return {Promise<void>}
     */
    const addToCompareList = async () => {
        const response = await api({
            url: partCompareApis.addPart,
            method: apiMethods.post,
            data: {
                partId: data?.id,
            },
            showError: false,
            showSuccess: false
        })
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            setData(prevState => ({
                ...prevState,
                isCompared: true,
            }));
            ToastService.success({
                title: "Part Added to Compare List",
                message: `${data.partNumber} was added to your compare list `,
                buttons: [
                    {
                        onClick: () => onViewCompareListClicked(),
                        children: "View Compare List"
                    }
                ]
            });
        }
    }

    /**
     * Changes the isSaved value of the part in the server. Parts that are saved can then be fetched separately in
     * saved items view.
     *
     * @param {MouseEvent} e
     * @return {Promise<void>}
     */
    const toggleBookmarked = async (e) => {
        e.stopPropagation()
        e.preventDefault()
        setData(prevState => ({
            ...prevState,
            saving: true,
        }));
        const {response} = await controller({
            response: {
                url: partApis.toggleBookmark,
                params: {
                    Data: {
                        PartID: data.partNumber,
                    }
                }
            }
        });
        if (!isMounted())
            return;
        setData(prevState => ({
            ...prevState,
            saving: false,
        }));
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            return;
        }
        if (response?.Code === 100) {
            setData(prevState => ({
                ...prevState,
                saved: !prevState.saved,
            }));
        } else {
            toast.error(response?.Message ?? '')
        }
    }

    /**
     * Navigates the user to the products' page with the given tag as its search filter.
     *
     * @param tag
     */
    const onTagClicked = (tag) => {
        history.push(stringifyUrl({
                url: routes.main.products,
                query: {
                    type: ProductsSearchTypes.basic,
                    data: JSON.stringify({
                        [ProductsSearchTypes.basic]: {
                            [ProductSearchUtils.basicSearchQueryKeys.tags]: [{id: tag.id, name: tag.name}]
                        }
                    })
                },
            })
        )
    }

    /**
     * If the product is shown as a result of the elastic search of the system, calls an api to record the clicking
     * activity for reporting purposes.
     */
    const onProductClicked = () => {
        if (!elasticSearchRequestId || !dataProps.searchItemId)
            return;
        api({
            url: newLookupApis.reportElasticSearchResultClick,
            method: apiMethods.post,
            data: {
                searchItemId: dataProps.searchItemId,
                query: elasticSearchQuery ?? '',
                requestId: elasticSearchRequestId,
                partId: data.id,
            }
        }).then();
    }

    /**
     * Renders the container that wraps the given products based on type and whether the product is child of carousel.
     *
     * * if not child of a carousel, the product is not wrapped.
     * @param {JSX.Element} product
     * @return {JSX.Element}
     */
    const renderProductContainer = (product) => {
        if (isChildOfCarousel || fullWidth) {
            return <div
                className={'product-container'}
                onClick={onProductClicked}
                style={isChildOfCarousel && !!enforcedHeight
                    ? {height: `${enforcedHeight}px`}
                    : {}
                }
            >
                {product}
            </div>;
        }
        switch (type) {
            case ProductBoxTypes.large:
                return <Col xs={12} md={10} lg={6}
                            onClick={onProductClicked}
                            className={'d-flex justify-content-center align-items-center pb-3'}
                >
                    {product}
                </Col>
            case ProductBoxTypes.medium:
                return <Col xs={10} sm={9} md={6} lg={4}
                            onClick={onProductClicked}
                            className={'d-flex align-items-center align-items-md-start pb-3'}>
                    {product}
                </Col>
            case ProductBoxTypes.small:
                return <Col xs={12} md={6} lg={4} xl={4}
                            onClick={onProductClicked}>
                    {product}
                </Col>
            default:
                return <></>
        }
    }

    /**
     * Renders the appropriate product based on the given type.
     * @return {JSX.Element}
     */
    const renderProduct = () => {
        switch (type) {
            case ProductBoxTypes.large:
                return <LargeProductBox
                    data={data}
                    preventAnimation={preventAnimation}
                    linkPath={navigationLinkPath}
                    toggleBookmarked={toggleBookmarked}
                    toggleInCompareList={toggleInCompareList}
                    onTagClicked={onTagClicked}
                />
            case ProductBoxTypes.medium:
                return <MediumProductBox
                    data={data}
                    preventAnimation={preventAnimation}
                    linkPath={navigationLinkPath}
                    toggleBookmarked={toggleBookmarked}
                    toggleInCompareList={toggleInCompareList}
                    onTagClicked={onTagClicked}
                />
            case ProductBoxTypes.small:
                return <SmallProductBox
                    data={data}
                    preventAnimation={preventAnimation}
                    linkPath={navigationLinkPath}
                    toggleBookmarked={toggleBookmarked}
                    toggleInCompareList={toggleInCompareList}
                    onTagClicked={onTagClicked}
                />
            case ProductBoxTypes.list:
                return <ListProductBox
                    data={data}
                    preventAnimation={preventAnimation}
                    linkPath={navigationLinkPath}
                    toggleBookmarked={toggleBookmarked}
                    toggleInCompareList={toggleInCompareList}
                    onTagClicked={onTagClicked}
                    preventActionButtons={preventActionButton}
                />
            default:
                return <></>
        }
    }

    return (
        <>
            {renderProductContainer(renderProduct())}
        </>
    );
}


export default ProductBox;
