import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {Col, Container, Row,} from "reactstrap";
import {useDispatch} from "react-redux";
import {setReduxHeaderHeight} from "../../../../redux/actions";
import useRouter from "../../../hooks/use-router";
import api from "../../../../core/services/api/api";
import {newLookupApis, partApis} from "../../../../core/constants/endpoints/endpoints";
import InfiniteScroll from 'react-infinite-scroller';
import ProductsSearchSection from "../../../components/search-sections/products";
import {
    apiMethods,
    elasticSearchOrderByTypes,
    ProductBoxTypes,
    ProductsSearchTypes,
    TechnicalPropertyPrefix,
    ValidSortTypes
} from "../../../../core/constants/enums";
import useIsMounted from "../../../hooks/use-is-mounted";
import routes from "../../../routes";
import {deepEqual} from "../../../../core/services/utils/utils";
import StaticImage from '../../../../assets/images/static/products.png';
import {toast} from "react-toastify";
import ProductSearchUtils from "../../../../core/services/utils/product-search-utils";
import Regexes from "../../../../core/constants/regexes";
import ProductBox from "../../../components/app-specific/product-box";
import HomeViewBanner from "../../../components/app-specific/home-components/banner";
import classnames from "classnames";
import ElasticTechnicalSearchFiltersSection from "../../../components/search-sections/products/types/elastic-technical/filters-section";
import {controller} from "../../../../core/services/api/controller";
import {ReactComponent as ArrowUp} from "../../../../assets/images/elastic-orderby/arrow-up.svg";
import {ReactComponent as ArrowDown} from "../../../../assets/images/elastic-orderby/arrow-down.svg";
import EnvService from "../../../../core/services/env-service";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";
import LostSearchContainer from "../../../components/app-specific/lost-search-container";


const initialPaginationInfo = {
    currentPage: 1,
    pageSize: 24,
    length: 0,
}

const ProductsView = () => {
    const {history, query} = useRouter()
    const [paginationInfo, setPaginationInfo] = useState(initialPaginationInfo);
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchToken, setSearchToken] = useState(null);
    const [elasticSearchRequestId, setElasticSearchRequestId] = useState(undefined);
    const [filters, setFilters] = useState({});
    const [rootCategories, setRootCategories] = useState([]);
    const [elasticSearchFormData, setElasticSearchFormData] = useState({});
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    const hasBanner = useRef(null);
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const headerRef = useRef();
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const pageScrollViewRef = useRef();

    const searchType = useMemo(() => query?.type ?? ProductsSearchTypes.basic, [query?.type]);
    const elasticSearchCategory = useMemo(() => {
        if (searchType !== ProductsSearchTypes.elasticTechnical)
            return {};
        return rootCategories?.find(e =>
                (e.id ?? e.Id) === ((filters ?? {})
                    [ProductsSearchTypes.elasticTechnical] ?? {})
                    [ProductSearchUtils.elasticTechnicalSearchQueryKeys.categoryId]
            )
            ?? {};
    }, [rootCategories, filters, searchType])
    const elasticKeywords = useMemo(() => {
        return ((filters ?? {})[ProductsSearchTypes.elasticTechnical] ?? {})[ProductSearchUtils.elasticTechnicalSearchQueryKeys.keyword] ?? ''
    }, [filters, searchType])

    /**
     * With each change in the search type:
     * - resets the products list to prevent the bug of keys not being unique while rendering the previous products
     * list (that may have been of a different type like application products vs other products)
     */
    useLayoutEffect(() => {
        setLoading(true);
        setProducts([]);
    }, [searchType])

    /**
     * With each change in the selected search type:
     * - if search type i elastic technical and categories is not already fetched, then fetches the list of root
     * categories from the server.
     */
    useEffect(() => {
        if (searchType !== ProductsSearchTypes.elasticTechnical)
            return
        if (!!rootCategories?.length)
            return;
        getCategories().then();
    }, [searchType])

    /**
     * With each change in the banner of the url query params:
     * - sets the header height of this view with the appropriate image
     * - sets the flag for having the banner or not.
     */
    useEffect(() => {
        if (hasBanner.current === null) {
            hasBanner.current = !!query?.banner;
            setHeaderHeight();
            return;
        }
        if (hasBanner.current && !query?.banner) {
            // banner has been removed on this render
            hasBanner.current = false;
            setHeaderHeight();
            return;
        }
        if (!hasBanner.current && !!query?.banner) {
            // banner has been added on this
            hasBanner.current = true;
            setHeaderHeight();
        }
    }, [query?.banner])

    /**
     * As soon as the component mounts:
     * - attaches an event listener to listen for the changes in the resizes of the window.
     */
    useEffect(() => {
        window.addEventListener('resize', setHeaderHeight);
        setHeaderHeight();
        return () => {
            window.removeEventListener('resize', setHeaderHeight);
        }
    }, [])

    /**
     * Listens for the changes in the query of the url and with each change:
     *
     * - determines if there is any valid filters available. if nothing available, redirects the user to the main view
     * - determines if the given filters in query have changed and if so, searches for the results with a reset of
     * paginationInfo to trigger the searching functionality.
     */
    useEffect(() => {
        const parsedData = JSON.parse(query?.data ?? '{}');
        if (!query ||
            !query?.data?.length ||
            !Object.keys(parsedData).length ||
            !Object.keys(parsedData[query?.type] ?? {})?.length) {
            toast.warn("No valid searching filter was given. Returning to your main page.")
            return history.replace(routes.landing);
        }
        const newFilters = hasFiltersChanged(parsedData);
        if (!newFilters) {
            return;
        }
        setPaginationInfo(initialPaginationInfo);
        setProducts([]);
    }, [query])

    /**
     * Listens for the changes in filters and currentPage of paginationInfo and with each change:
     * - if filters is an empty object does nothing.
     * - else searches the products.
     */
    useEffect(() => {
        if (!Object.keys(filters ?? {})?.length)
            return;
        const abortController = new AbortController();
        search(abortController);
        return () => {
            if (paginationInfo?.currentPage === initialPaginationInfo.currentPage) {
                abortController.abort();
            }
        }
    }, [paginationInfo?.currentPage, filters])

    /**
     * With each change in the loading and pagination info current page in the search section:
     * - scrolls the results' section into the view if first page is just loaded, and we are not loading any more pages.
     */
    useEffect(() => {
        if (!pageScrollViewRef?.current)
            return;
        if (loading || paginationInfo.currentPage !== 1)
            return;

        pageScrollViewRef.current?.scrollIntoView(true)
    }, [pageScrollViewRef, loading])

    /**
     * Fetches the categories of the system from the server.
     * @return {Promise<void>}
     */
    const getCategories = async () => {
        const response = await controller({
            "Form": {
                url: 'Form/BasicSearch',
            }
        }).then(({Form}) => {
            if (Form?.isPreemptedDueToNotBeingLoggedIn)
                return;
            let response;
            if (Form?.Code === 100) {
                response = Array.isArray(Form.List) ? Form.List[0] : {};
            } else {
                response = {}
            }
            return response;
        });
        if (!isMounted())
            return;
        setRootCategories(response?.CategoryList?.filter(e => e.ParentId === null) ?? [])
    }

    /**
     * Searches using the specified search engine and the provided filters and paginationInfo. The result of the api
     * is then added to the existing products list in the state.
     *
     * this method will set the loading state to true before the api call.
     * @param {AbortController} abortController
     */
    const search = (abortController) => {
        const searchData = prepareSearchData();
        setLoading(true);
        let apiCall;
        switch (searchType) {
            case ProductsSearchTypes.application:
                applicationSearch(abortController, searchData).then();
                return;
            case ProductsSearchTypes.technical:
                apiCall = api({
                    url: partApis.technicalSearch,
                    method: apiMethods.post,
                    data: searchData,
                    extra: {
                        signal: abortController.signal,
                    }
                })
                break;
            case ProductsSearchTypes.elasticTechnical:
                elasticTechnicalSearch(abortController, searchData).then();
                return;
            case ProductsSearchTypes.basic:
            case ProductsSearchTypes.categories:
            case ProductsSearchTypes.tags:
            default:
                if (EnvService.useNewBasicSearch) {
                    newBasicSearch(abortController, searchData).then();
                    return;
                }
                apiCall = api({
                    url: partApis.basicSearch,
                    method: apiMethods.post,
                    data: searchData,
                    extra: {
                        signal: abortController.signal,
                    }
                })
                break;
        }
        apiCall.then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setLoading(false);
                return;
            }
            if (response?.resultFlag) {
                setProducts(prevState => [...prevState, ...(response.data?.resultList ?? [])])
                setPaginationInfo(prevState => ({...prevState, length: response.data?.paginationInfo?.length ?? 0}))
                setSearchToken(response.data.filters.token);
            }
            setLoading(false);
        })
    }

    /**
     * Searches for the products using the application search engine with the given search data.
     * @param {AbortController} abortController
     * @param {any} searchData
     * @returns {Promise<void>}
     */
    const applicationSearch = async (abortController, searchData) => {
        const response = await api({
            url: partApis.applicationSearch,
            method: apiMethods.post,
            data: searchData,
            extra: {
                signal: abortController.signal,
            }
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setLoading(false);
            return;
        }
        if (response?.resultFlag) {
            setProducts(prevState => [...prevState, ...(response.data?.resultList?.map(e => ({
                applicationCategory: e.category,
                parts: e.partsList ?? []
            })) ?? [])])
            setPaginationInfo(prevState => ({...prevState, length: response.data?.paginationInfo?.length ?? 0}))
            setSearchToken(response.data.filters.token);
        }
        setLoading(false);
    }

    /**
     * Uses the new basic search engine to search for the products given the search data.
     *
     * @param {AbortController} abortController
     * @param {any} searchData
     * @return {Promise<void>}
     */
    const newBasicSearch = async (abortController, searchData) => {
        const response = await api({
            url: newLookupApis.newBasicSearch,
            method: apiMethods.post,
            data: searchData,
            extra: {
                signal: abortController.signal,
            }
        })
        if (!isMounted())
            return;
        setLoading(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            const data = response?.data?.dataList ?? {};
            getBasicSearchResultCompleteInformation(response).then();
            setPaginationInfo(prevState => ({
                ...prevState,
                length: data?.totalCount ?? 0,
            }))
            setElasticSearchRequestId(response?.data?.requestId);
            setSearchToken(null);
        }
    }

    /**
     * Sets the partial product information obtained from the basic search, and then calls an api that would
     * complete the given products' information list.
     *
     * @param {CrudDs} searchResponse
     * @return {Promise<void>}
     */
    const getBasicSearchResultCompleteInformation = async (searchResponse) => {
        setProducts(prevState => [
            ...prevState,
            ...(
                searchResponse?.data?.dataList?.items?.map(e => ({
                    ...e,
                    coverImageURL: searchResponse?.configuration?.PartImageURL?.concat(e.coverImageURL) ?? '',
                })) ?? []
            )
        ]);
        const partNos = searchResponse?.data?.dataList?.items?.map(e => e.partNo) ?? [];
        if (!partNos?.length)
            return;
        const response = await api({
            url: newLookupApis.getSearchCompleteInformation,
            method: apiMethods.post,
            data: {
                partNumbers: partNos
            },
        })
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            setProducts(prevState => prevState?.map(product => {
                const found = response?.data?.find(e => e.partNo === product.partNo);
                if (!found)
                    return product;
                return {
                    ...product,
                    ...found,
                    changedPrice: found?.price,
                    coverImageURL: response?.configuration?.PartImageURL?.concat(found.coverImageURL) ?? '',
                    tags: found?.tags?.map(e => ({
                        ...e,
                        iconFileName: response?.configuration?.TagIconUrlBaseAddress?.concat(e.iconFileName) ?? '',
                    })) ?? []
                };
            }))
        }
    }

    /**
     * Uses the elastic search engine to search for the products given the search data.
     *
     * @param {AbortController} abortController
     * @param {any} searchData
     * @return {Promise<void>}
     */
    const elasticTechnicalSearch = async (abortController, searchData) => {
        const response = await api({
            url: newLookupApis.elasticTechnicalSearch,
            method: apiMethods.post,
            data: searchData,
            extra: {
                signal: abortController.signal,
            }
        })
        if (!isMounted())
            return;
        setLoading(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            getElasticTechnicalSearchResultCompleteInformation(response).then();
            setPaginationInfo(prevState => ({
                ...prevState,
                length: response.data?.parts?.totalCount ?? 0,
            }))
            setElasticSearchRequestId(response?.data?.requestId);
            setSearchToken(null);
            setElasticSearchFormData({
                properties: response?.data?.properties ?? [],
                facets: response?.data?.facets ?? {},
                orderByList: response?.data?.validSorts?.map((e, i) => ({
                    name:
                        <>
                            <div className={'d-flex align-items-center '}>
                                {
                                    (e?.type === elasticSearchOrderByTypes.ascending || e?.type === "Asc")
                                        ? <ArrowUp/>
                                        : e?.type === elasticSearchOrderByTypes.descending
                                            ? <ArrowDown/>
                                            : <div className={'ml-4 mr-2'}/>
                                }
                                <p className={'mb-0'}>{e?.name}</p>
                            </div>
                        </>,
                    id: i + 1,
                    value: {
                        property: e.name,
                        isDesc: e.type === ValidSortTypes.desc ? true : e.type === ValidSortTypes.asc ? false : null
                    }
                })) ?? [],
            });
        }
    }

    /**
     * Sets the partial product information obtained from the elastic search, and then calls an api that would
     * complete the given products' information list.
     *
     * @param {CrudDs} searchResponse
     * @return {Promise<void>}
     */
    const getElasticTechnicalSearchResultCompleteInformation = async (searchResponse) => {
        setProducts(prevState => [
            ...prevState,
            ...(
                searchResponse?.data?.parts?.items?.map(e => ({
                    ...e,
                    coverImageURL: searchResponse?.configuration?.PartImageURL?.concat(e.coverImageURL) ?? '',
                })) ?? []
            )
        ]);
        const partNos = searchResponse?.data?.parts?.items?.map(e => e.partNo) ?? [];
        if (!partNos?.length)
            return;
        const response = await api({
            url: newLookupApis.getSearchCompleteInformation,
            method: apiMethods.post,
            data: {
                partNumbers: partNos
            },
        })
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            setProducts(prevState => prevState?.map(product => {
                const found = response?.data?.find(e => e.partNo === product.partNo);
                if (!found)
                    return product;
                return {
                    ...product,
                    ...found,
                    changedPrice: found?.price,
                    coverImageURL: response?.configuration?.PartImageURL?.concat(found.coverImageURL) ?? '',
                    tags: found?.tags?.map(e => ({
                        ...e,
                        iconFileName: response?.configuration?.TagIconUrlBaseAddress?.concat(e.iconFileName) ?? '',
                    })) ?? []
                };
            }))
        }
    }

    /**
     * Sets the height of the application header based on the current height of the headerRef.
     */
    const setHeaderHeight = () => {
        if (!!query?.banner) {
            dispatch(setReduxHeaderHeight(0));
            if (headerRef) {
                headerRef.current.style.marginTop = '50px';
            }
            return;
        }
        const height = headerRef.current?.clientHeight ?? 0;
        dispatch(setReduxHeaderHeight(height));
        const negativeTop = (height - 60) * -1;
        if (headerRef) {
            headerRef.current.style.marginTop = negativeTop + 'px';
        }
    };

    /**
     * Determines if the filters of the query dta has been changed.
     *
     * @param {Record<string, any>} parsedData the filters.
     * @return {null|any} null if no change is detected, the data, otherwise.
     */
    const hasFiltersChanged = (parsedData) => {
        if (deepEqual(parsedData, filters))
            return null;
        setFilters(parsedData);
        return parsedData;
    }

    /**
     * Prepares the search data specific for the searching engine that the user has chosen.
     *
     * NOTE: DO not change the returned filters object. By doing so, you have to also change the LOST SEARCH REPORT FEATURE of Partner
     * Application.
     */
    const prepareSearchData = () => {
        let queryKeys;
        let _filters = filters[searchType]
        switch (searchType) {
            case ProductsSearchTypes.application:
                queryKeys = ProductSearchUtils.applicationSearchQueryKeys;
                _filters = {
                    makeId: _filters[queryKeys.make] ? parseInt(_filters[queryKeys.make]) : undefined,
                    modelId: _filters[queryKeys.model] ? parseInt(_filters[queryKeys.model]) : undefined,
                    year: _filters[queryKeys.year] ? parseInt(_filters[queryKeys.year]) : undefined,
                    orderBy: _filters[queryKeys.orderBy],
                }
                break;
            case ProductsSearchTypes.technical:
                queryKeys = ProductSearchUtils.technicalSearchQueryKeys;
                const properties = Object.entries(_filters)
                    ?.filter(([key]) => key.match(Regexes.technicalPropertyFinder))
                    ?.map(([key, value]) => ({
                        ID: parseInt(key.replace(TechnicalPropertyPrefix, '')),
                        Value: value,
                    }))
                _filters = {
                    categoryId: _filters[queryKeys.categoryId] ? parseInt(_filters[queryKeys.categoryId]) : undefined,
                    orderBy: _filters[queryKeys.orderBy],
                    properties,
                }
                break;
            case ProductsSearchTypes.elasticTechnical: {
                queryKeys = ProductSearchUtils.elasticTechnicalSearchQueryKeys;
                return {
                    pagination: paginationInfo,
                    mainCategoryId: _filters[queryKeys.categoryId],
                    keyword: _filters[queryKeys.keyword] ?? '',
                    filters: _filters[queryKeys.facets],
                    sort: _filters[queryKeys.orderBy],
                };
            }
            case ProductsSearchTypes.basic:
            case ProductsSearchTypes.categories:
            case ProductsSearchTypes.tags:
            default:
                queryKeys = ProductSearchUtils.basicSearchQueryKeys;
                if (EnvService.useNewBasicSearch) {
                    return {
                        keyword: _filters[queryKeys.keyword] ? _filters[queryKeys.keyword] : "",
                        categoryId: _filters[queryKeys.categoryId] ? parseInt(_filters[queryKeys.categoryId]) : undefined,
                        tagIds: _filters[queryKeys.tags]?.map(e => e.id) ?? [],
                        orderBy: _filters[queryKeys.orderBy],
                        pagination: paginationInfo
                    }
                }
                _filters = {
                    categoryId: _filters[queryKeys.categoryId] ? parseInt(_filters[queryKeys.categoryId]) : undefined,
                    keyword: _filters[queryKeys.keyword] ? _filters[queryKeys.keyword] : undefined,
                    orderBy: _filters[queryKeys.orderBy],
                    tagIds: _filters[queryKeys.tags]?.map(e => e.id) ?? [],
                }
                break;
        }
        return {paginationInfo, filters: _filters};
    }

    /**
     * Loads more parts by increasing the currentPage of pagination info
     */
    const loadMore = () => {
        if (!products.length || loading) return;
        setLoading(true);
        setPaginationInfo(prevState => ({...prevState, currentPage: prevState.currentPage + 1}))
    }

    const searchFilters = useMemo(() => {
        if (!filters[searchType])
            return {};
        const searchData = prepareSearchData();
        if (searchType === ProductsSearchTypes.basic) {
            if (EnvService.useNewBasicSearch) {
                delete searchData.pagination;
                return searchData;
            }
        } else if (searchType === ProductsSearchTypes.elasticTechnical) {
            delete searchData.pagination;
            return searchData;
        } else {
            delete searchData.paginationInfo;
            return searchData.filters;
        }
    }, [searchType, filters])

    return (
        <>
            <section className={classnames(
                'view-image-header in-products-view',
                {'elastic-results': searchType === ProductsSearchTypes.elasticTechnical}
            )}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            {
                                searchType === ProductsSearchTypes.categories
                                    ? <div
                                        className="box"
                                        ref={headerRef}
                                        style={{backgroundImage: `url(${StaticImage})`}}>
                                        <h1>
                                            Products
                                        </h1>
                                        {
                                            loading && !products?.length
                                                ? <p>loading</p>
                                                : <p>
                                                    {paginationInfo?.length} {paginationInfo?.length === 1 ? "Result" : "Results"}
                                                </p>
                                        }
                                    </div>
                                    : <div ref={headerRef}>
                                        {
                                            !!query?.banner &&
                                            <div
                                                ref={pageScrollViewRef}
                                                className={'part-search-section-banner'}>
                                                <HomeViewBanner
                                                    url={decodeURIComponent(query?.banner)}
                                                    isUnClickable
                                                />
                                            </div>
                                        }
                                        {
                                            !query?.banner &&
                                            <ProductsSearchSection
                                                ref={pageScrollViewRef}
                                                inElasticTechnicalSearchResults={searchType === ProductsSearchTypes.elasticTechnical}
                                                className={'products-view-products-search-section'}
                                            />
                                        }
                                    </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
            <main className={classnames(
                'product-search-result-view',
                {'elastic-results': searchType === ProductsSearchTypes.elasticTechnical}
            )}>
                <Container>
                    <div
                        className={classnames('d-flex flex-wrap w-100 product-search-result-view-products-section', {'no-result': !products?.length && !loading})}>
                        {
                            searchType === ProductsSearchTypes.elasticTechnical &&
                            (products?.length || (loading && !products?.length)) &&
                            <div className={'elastic-search-result-filters-section-container'}>
                                <ElasticTechnicalSearchFiltersSection
                                    formData={elasticSearchFormData}
                                    data={filters[ProductsSearchTypes.elasticTechnical] ?? {}}
                                    fetchingData={loading}
                                />
                            </div>
                        }
                        <div className={'w-100'}>
                            <div className='d-flex justify-content-between mb-4'>
                                {
                                    !products?.length && !loading &&
                                    <span/>
                                }
                                {
                                    !products?.length && !loading &&
                                    <Col className={'d-flex flex-column justify-content-start p-0'}>
                                        <LostSearchContainer
                                            searchToken={elasticSearchRequestId || searchToken}
                                            searchFilters={searchFilters}
                                        />
                                    </Col>
                                }
                                {
                                    searchType !== ProductsSearchTypes.elasticTechnical &&
                                    ((loading && paginationInfo?.currentPage !== 1) || (products?.length > 0)) &&
                                    <>
                                        <div className={'product-search-result-indicator'}>
                                            <p>
                                                {paginationInfo?.length ?? 0}
                                                <span>
                                                            {
                                                                (paginationInfo?.length ?? 0) === 1
                                                                    ? "Result"
                                                                    : "Results"
                                                            }
                                                    </span>
                                            </p>
                                        </div>
                                    </>
                                }
                                {
                                    searchType === ProductsSearchTypes.elasticTechnical &&
                                    ((loading && paginationInfo?.currentPage !== 1) || (products?.length > 0)) &&
                                    <>
                                        <div className={'product-search-elastic-result-indicator'}>
                                            <p>
                                                <span>{paginationInfo?.length ?? 0}</span>
                                                {
                                                    (paginationInfo?.length ?? 0) === 1
                                                        ? "result"
                                                        : "results"
                                                } for: {
                                                elasticKeywords?.length > 0 &&
                                                <>
                                                    <span>{elasticKeywords}</span>
                                                    in
                                                </>
                                            } <span>{elasticSearchCategory?.Title ?? ''}</span>
                                            </p>
                                        </div>
                                    </>
                                }
                            </div>
                            {
                                searchType === ProductsSearchTypes.application &&
                                <div>
                                    {
                                        !!loading &&
                                        <div
                                            className='w-100 d-flex justify-content-center align-items-center p-5 my-5'>
                                            <LoadingIndicator/>
                                        </div>
                                    }
                                    {
                                        products?.map((group) => (
                                            <div
                                                key={
                                                    group.id
                                                    ?? group.searchItemId
                                                    ?? (group?.applicationCategory?.id
                                                        ? `group-category-${group?.applicationCategory?.id}`
                                                        : `no-category`)
                                                }
                                            >
                                                <div className='heading sticky'>
                                                    {group.applicationCategory?.title ?? ''}
                                                </div>
                                                <Row className={'medium-container-row'}>
                                                    {
                                                        group.parts?.map((item) => (
                                                            <Col key={item.id} xs={12} md={4} lg={3}
                                                                 className={'medium-container col-12'}>
                                                                <ProductBox
                                                                    type={ProductBoxTypes.list}
                                                                    SearchToken={searchToken}
                                                                    fullWidth
                                                                    data={item}
                                                                />
                                                            </Col>
                                                        ))
                                                    }
                                                </Row>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                            {
                                searchType !== ProductsSearchTypes.application && (
                                    <>
                                        {
                                            products?.length > 0 &&
                                            <InfiniteScroll
                                                className={classnames('products-wrapper', {[searchType]: true})}
                                                pageStart={0}
                                                useWindow
                                                threshhold={400}
                                                loadMore={loadMore}
                                                hasMore={
                                                    !loading &&
                                                    paginationInfo.length > paginationInfo.pageSize * paginationInfo.currentPage
                                                }>
                                                {
                                                    products?.map((item) => (
                                                        <Col
                                                            key={
                                                                item.id
                                                                ?? item.searchItemId
                                                                ?? (item?.applicationCategory?.id
                                                                    ? `group-category-${item?.applicationCategory?.id}`
                                                                    : `no-category`)
                                                            }
                                                            xs={12} sm={6} md={4} lg={3}
                                                            {...(searchType === ProductsSearchTypes.elasticTechnical
                                                                    ? {xs: 12, sm: 6, md: 6, lg: 6, xl: 4}
                                                                    : {xs: 12, sm: 6, md: 4, lg: 3}
                                                            )}
                                                            className={classnames('medium-container')}
                                                        >
                                                            <ProductBox
                                                                type={ProductBoxTypes.list}
                                                                fullWidth
                                                                SearchToken={searchToken}
                                                                elasticSearchRequestId={elasticSearchRequestId}
                                                                elasticSearchQuery={((filters ?? {})[ProductsSearchTypes.elasticTechnical] ?? {})[ProductSearchUtils.elasticTechnicalSearchQueryKeys.keyword]}
                                                                data={item}
                                                            />
                                                        </Col>
                                                    ))
                                                }
                                            </InfiniteScroll>
                                        }
                                        {
                                            !!loading &&
                                            <div
                                                className='w-100 d-flex justify-content-center align-items-center p-5 my-5'>
                                                <LoadingIndicator/>
                                            </div>
                                        }
                                    </>
                                )
                            }
                        </div>
                    </div>
                </Container>
            </main>
        </>
    );
}


export default ProductsView;
