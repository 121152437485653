import React, {useEffect} from 'react';
import {Col, Container, Row} from "reactstrap";
import {useDispatch} from "react-redux";
import {setReduxHeaderHeight} from "../../../redux/entities/header/actions";
import useRouter from "../../hooks/use-router";
import {ReactComponent as NotFound} from "../../../assets/images/art-likes/not-found.svg";
import {errorHeaderHeight} from "../../../assets/js/sizes";

const ErrorView = () => {
    const {location, history} = useRouter();
    const dispatch = useDispatch();

    /**
     * Listens for the changes in the pathname of the location and with each change:
     * - sets the header height to be 40
     */
    useEffect(() => {
        dispatch(setReduxHeaderHeight(errorHeaderHeight));
    }, [location.pathname])

    return (
        <>
            <Container className={'error-view'}>
                <Row>
                    <Col xs={12} className={'d-flex justify-content-center'}>
                        <NotFound className={'svg'}/>
                    </Col>
                    <Col xs={12} className={'d-flex flex-column align-items-center'}>
                        <p>
                            Sorry, but we couldn't find the page you're looking for, please accept our apology.
                        </p>
                        <button className={'button primary mt-3'} onClick={history.goBack}>
                            Go back
                        </button>
                    </Col>
                </Row>
            </Container>
        </>
    );

}
export default ErrorView;

