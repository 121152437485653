import React, {useEffect, useRef, useState} from "react";
import classnames from "classnames";
import {ReactComponent as RemoveIcon} from "../../../../../../assets/images/comparison/remove-property.svg";
import {confirmationDialog} from "../../../../../../redux/entities/dialogs/actions";
import {useDispatch} from "react-redux";
import {ReactComponent as PrevButton} from "../../../../../../assets/images/comparison/prev.svg";
import {ReactComponent as NextButton} from "../../../../../../assets/images/comparison/next.svg";
import ObserversService from "../../../../../../core/services/observers-service";

const CompareViewProductChips = ({
                                     products,
                                     selectedProductId,
                                     selectProduct,
                                     removeProduct,
                                 }) => {
    const dispatch = useDispatch();
    const [showButtons, setShowButtons] = useState(false);
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const layoutRef = useRef();
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const innerLayoutRef = useRef();

    /**
     * As soon as the component mounts:
     * - attaches an observer that would determine whether the scroll buttons should be shown or not based on the width of the chips and
     * their container.
     */
    useEffect(() => {
        const parent = layoutRef.current?.parentElement;
        if (!parent)
            return;
        const observer = ObserversService.newResizeObserver((entries) => {
            // 39 is the padding of the parent element.
            setShowButtons(((entries?.[0]?.contentRect?.width ?? 0) - 39) < (innerLayoutRef.current?.scrollWidth ?? 0));
        })
        setShowButtons((parent?.getBoundingClientRect()?.width ?? 0) < (innerLayoutRef.current?.scrollWidth ?? 0));
        ObserversService.observeResizeObserver(observer, parent);
        return () => {
            ObserversService.disconnectResizeObserver(observer);
        }
    }, [products?.length])

    /**
     * Handles removing a product from the list of items in compare screen:
     * - Shows a confirmation dialog from for user
     * - after confirmation, calls the api regarding this action
     *
     * @param {MouseEvent<HTMLButtonElement>} e
     * @param {Object}  product
     */
    const getRemoveProductConfirmation = (e, product) => {
        e.stopPropagation()
        e.preventDefault()
        dispatch(confirmationDialog({
            open: true,
            title: "Remove Product",
            description: "Do you want to remove the following product?",
            callback: (confirmed) => {
                if (confirmed) removeProduct(product).then();
                return true;
            },
            mandatory: false,
        }))
    }

    /**
     * Scrolls the content of the product chips.
     * @param forward
     */
    const scroll = (forward = false) => {
        innerLayoutRef.current.scrollBy({left: (layoutRef.current.getBoundingClientRect().width ?? 0) * (forward ? 1 : -1), top: 0});
    }

    return (
        <>
            <div className={'product-chips-list-container'} ref={layoutRef}>
                {
                    showButtons &&
                    <PrevButton
                        className={'back-button prev'}
                        onClick={() => scroll(false)}
                    />
                }
                <div className={'product-chips-list'} ref={innerLayoutRef}>
                    {
                        products.map((product) => (
                            <div
                                key={product?.id}
                                className={classnames(
                                    'product-chips',
                                    {'selected': product.id === selectedProductId}
                                )}
                                onClick={() => selectProduct(product)}
                            >
                                <p className={'mb-0'}>{product?.partNo ?? ""}</p>
                                <button
                                    className={'remove-icon'}
                                    onClick={(e) => getRemoveProductConfirmation(e, product)}
                                >
                                    <RemoveIcon/>
                                </button>
                            </div>
                        ))
                    }
                </div>
                {
                    showButtons &&
                    <NextButton
                        className={'back-button next'}
                        onClick={() => scroll(true)}
                    />
                }
            </div>
        </>
    )
}

export default CompareViewProductChips;
