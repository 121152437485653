import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {Col, Container, Row} from "reactstrap";
import ProductsSearchSection from "../../../components/search-sections/products";
import useIsMounted from "../../../hooks/use-is-mounted";
import {deepCopy, numComparator} from "../../../../core/services/utils/utils";
import {useDispatch} from "react-redux";
import {apiMethods, HomepagePlatforms, HomeViewComponentTypes} from "../../../../core/constants/enums";
import InfiniteScroll from "react-infinite-scroller";
import {mainPageApis} from "../../../../core/constants/endpoints/endpoints";
import api from "../../../../core/services/api/api";
import HomeViewSlideShow from "../../../components/app-specific/home-components/slide-show";
import {setReduxHeaderHeight as changeHeaderHeightRedux} from "../../../../redux/entities/header/actions"
import HomeViewBizkeyTechPattern from "./pattern/bizkey-tech";
import HomePageComponent from "../../../components/app-specific/home-components";
import EnvService from "../../../../core/services/env-service";
import HomeViewChtpPattern from "./pattern/chtp";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";

const initialPaginationInfo = {
    pageSize: 4,
    currentPage: 1,
    length: 0,
}

const HomeView = () => {
    const [loading, setLoading] = useState(true);
    const [firstSlideshow, setFirstSlideshow] = useState(null)
    const [components, setComponents] = useState([])
    const [paginationInfo, setPaginationInfo] = useState(initialPaginationInfo);
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    /**@type{React.MutableRefObject<HTMLDivElement>}*/
    const contentRef = useRef();

    const initialLoading = loading && !components?.length
    const hasMoreToLoad = !loading && paginationInfo.length > paginationInfo.pageSize * paginationInfo.currentPag

    const bizkeyTechPatternHeight = 1000;

    /**
     * As soon as the component mounts:
     * - Changes the height of the header back to 0.
     */
    useLayoutEffect(() => {
        dispatch(changeHeaderHeightRedux(0));
    }, [])

    /**
     * with each change in currenPage property of paginationInfo:
     * - fetches the remaining home view components from the server.
     */
    useEffect(() => {
        setLoading(true);
        getHomeViewComponents(paginationInfo).then();
    }, [paginationInfo.currentPage])

    /**
     * Fetches the main page data from the server and populates the inner state.
     */
    const getHomeViewComponents = async (paginationInfo) => {
        const response = await api({
            url: mainPageApis.getComponents,
            method: apiMethods.post,
            data: {
                TabId: null,
                PlatformId: HomepagePlatforms.web,
            },
        });
        if (!isMounted()) return;
        setLoading(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        setPaginationInfo(prevState => ({...prevState, length: 0}));
        if (response?.resultFlag) {
            const data = response?.data?.length ? response?.data[0] : null;
            const config = response?.configuration ?? {};
            prepareComponents(data, config);
        }
    };

    /**
     * Prepares the given data so that they can be used in home-view components.
     *
     * * filters out components that have valid entries for their content
     * * for each component, inject the given configs in their properties.
     * @param _data
     * @param config
     */
    const prepareComponents = (_data, config) => {
        const data = deepCopy(_data);
        let _components = data?.components?.sort((a, b) => numComparator(a?.orderIndex ?? 0, b?.orderIndex ?? 0)) ?? [];
        _components = _components
            ?.filter(e => {
                // only return components that have valid entries for their content
                if (!e?.content) {
                    return false;
                }
                if (!Object.keys(e?.content ?? {})?.length) {
                    return false;
                }
                for (const value of Object.values(e?.content)) {
                    if (!value || (Array.isArray(value) && !value?.length)) {
                        return false;
                    }
                }
                return true;
            })
            ?.map(e => {
                // for each component, inject the given configs in their properties.
                switch (e?.type?.id) {
                    case HomeViewComponentTypes.slideShow:
                    case HomeViewComponentTypes.banner:
                        return {
                            ...(e ?? {}), content: {
                                banners: e?.content?.banners?.map(e => ({
                                    ...e,
                                    imageName: !!e?.imageName ? config?.BannerUrlBaseAddress?.concat(e?.imageName) : null,
                                    tags: e?.tags?.map(e => ({
                                        ...e,
                                        iconFileName: !!e?.iconFileName ? config?.TagIconUrlBaseAddress?.concat(e?.iconFileName) : null
                                    })) ?? []
                                })) ?? [],
                            }
                        }
                    case HomeViewComponentTypes.category:
                        return {
                            ...(e ?? {}),
                            content: {
                                categories: e?.content?.categories?.map(e => ({
                                    ...e,
                                    coverFileName: !!e?.coverFileName ? config?.CategoryImageURL?.concat(e?.coverFileName) : null,
                                })) ?? [],
                            }
                        }
                    case HomeViewComponentTypes.largeProduct:
                    case HomeViewComponentTypes.mediumProduct:
                    case HomeViewComponentTypes.smallProduct:
                    case HomeViewComponentTypes.timeBased:
                        return {
                            ...(e ?? {}),
                            content: {
                                finishDateTime: e?.content?.finishDateTime,
                                parts: e?.content?.parts?.map(e => ({
                                    ...e,
                                    category: {
                                        ...(e?.category ?? {}),
                                        coverFileName: !!e?.category?.coverFileName ? config?.CategoryImageURL?.concat(e?.category?.coverFileName) : null,
                                    },
                                    tags: e?.tags?.map(e => ({
                                        ...e,
                                        iconFileName: !!e?.iconFileName ? config?.TagIconUrlBaseAddress?.concat(e?.iconFileName) : null
                                    })) ?? [],
                                    coverImageURL: !!e?.coverImageURL ? config?.PartImageURL?.concat(e?.coverImageURL) : null,
                                })) ?? [],
                            }
                        }
                    default:
                        return e;
                }
            })
            ?.map(e => ({
                ...e,
                searchQuery: parseSearchQueryEntity(!e.searchQuery ? null : parseSearchQueryEntity(JSON.parse(e.searchQuery)))
            }));
        if (!components.length && !_components.length) {
            // if fetching for the first time, and nothing is returned, fill it with null
            _components = [null]
        }
        if (!isMounted())
            return;
        if (!components.length) {
            // if fetching for the first time, set the firstSlideShow as well
            const firstComponent = _components[0]
            if (firstComponent?.type?.id === HomeViewComponentTypes.slideShow) {
                setFirstSlideshow(firstComponent);
                return setComponents(_components.slice(1))
            }
        }
        setComponents(_components?.filter(e => !!e) ?? [])
    }

    /**
     * Parses the search query objects keys in format of camel case for the application
     * @param entity
     * @return {{[p: string]: *|number|string|{[p: string]: *|number|string|undefined}}|string|number|*}
     */
    const parseSearchQueryEntity = (entity) => {
        if (typeof entity === "undefined" || entity === null)
            return entity;
        if (typeof entity === 'number')
            return entity;
        if (typeof entity === 'string')
            return entity;
        if (Array.isArray(entity))
            return entity.map(e => parseSearchQueryEntity(e));
        if (typeof entity === 'object')
            return Object.fromEntries(Object.entries(entity).map(([key, value]) => [
                key.substring(0, 1).toLowerCase().concat(key.slice(1).replace('ID', 'Id')),
                parseSearchQueryEntity(value),
            ]))
    }

    /**
     * Loads more components by increasing the currentPage of pagination info
     */
    const loadMore = () => {
        if (!components.length || loading) return;
        setPaginationInfo(prevState => ({...prevState, currentPage: prevState.currentPage + 1}))
    }

    const content = useMemo(() => {
        return components?.map(component => (
            <HomePageComponent
                key={component.id}
                component={component}
            />
        ))
    }, [components])

    return (
        <>
            <div
                className={'home-view'}
                style={{
                    '--container-height': EnvService.useBizkeyTechHomepagePattern ? `${bizkeyTechPatternHeight}px` : 0,
                }}
            >
                {
                    EnvService.useBizkeyTechHomepagePattern &&
                    <HomeViewBizkeyTechPattern
                        height={bizkeyTechPatternHeight}
                    />
                }
                {
                    EnvService.useChtpHomepagePattern &&
                    <HomeViewChtpPattern
                        contentRef={contentRef.current}
                    />
                }
                <div
                    className={'home-view-content'}
                    ref={contentRef}
                >
                    <Container>
                        <Row>
                            <Col xs={12}>
                                {
                                    (initialLoading || !!firstSlideshow) &&
                                    <HomeViewSlideShow
                                        data={firstSlideshow?.content?.banners ?? []}
                                        loading={initialLoading}
                                        first
                                    />
                                }
                            </Col>
                            <Col xs={12} className={'mt-3'}>
                                <ProductsSearchSection/>
                            </Col>
                        </Row>
                    </Container>
                    {
                        !!components?.length &&
                        <InfiniteScroll
                            className='d-flex flex-column w-100  mt-4'
                            pageStart={1}
                            useWindow
                            threshhold={400}
                            loadMore={loadMore}
                            hasMore={hasMoreToLoad}>
                            {content}
                        </InfiniteScroll>
                    }
                    {
                        loading &&
                        <Container>
                            <Row>
                                <Col xs={12} className={'d-flex align-items-center justify-content-center my-4'}>
                                    <LoadingIndicator/>
                                </Col>
                            </Row>
                        </Container>
                    }
                </div>
            </div>
        </>
    );
}


export default HomeView;

