import React, {useContext, useLayoutEffect, useRef, useState} from "react";
import rafSchedule from "raf-schd";
import classnames from "classnames";
import {DataGridMiscContext} from "../../../../index";
import * as $ from 'jquery';
import useIsMounted from "../../../hooks/use-is-mounted";

const DataGridColumnResizer = ({
                                   reverse,
                                   onResize,
                                   onIsResizingChanged,
                               }) => {
    const {classNames} = useContext(DataGridMiscContext);
    const prevMouseState = useRef(null);
    const [isResizing, setIsResizing] = useState(false);
    const isMounted = useIsMounted();

    /**
     * with each change in the [isResizing] state value:
     * - invokes the [onIsResizingChanged] callback with the new value of the [isResizing]
     * - sets the cursor of the application.
     */
    useLayoutEffect(() => {
        if (onIsResizingChanged)
            onIsResizingChanged(isResizing)

        if (isResizing)
            $(`#root`).css('cursor', 'col-resize');
        else
            $(`#root`).css('cursor', 'auto');
    }, [isResizing])

    /**
     * Resizes the column width.
     *
     * @param {MouseEvent | TouchEvent} e
     * @param {MutableRefObject<MouseEvent | TouchEvent>} prevMouseState
     */
    const resizeColumn = (e, prevMouseState) => {
        if (!onResize || !isMounted())
            return;

        let offsetX;
        if (reverse)
            offsetX = (prevMouseState.current?.pageX ?? 0) - e.pageX;
        else
            offsetX = e.pageX - (prevMouseState.current?.pageX ?? 0)

        onResize(offsetX);
        prevMouseState.current = e;
    }

    /**
     * schedules the resize callback based on animationFrames of the window
     * @type {Function}
     */
    const scheduleResize = rafSchedule(resizeColumn);

    /**
     * Starts the resizing process and attaches appropriate event listeners in the window for resizing.
     * @param {MouseEvent} e
     */
    const onMouseDown = (e) => {
        setIsResizing(true);
        prevMouseState.current = ({
            pageX: e.pageX,
        });
        e.preventDefault();
        window.addEventListener("mousemove", onMouseMove);
        window.addEventListener("touchmove", onMouseMove);
        window.addEventListener("mouseup", onMouseUp);
        window.addEventListener("touchend", onMouseUp);
    };


    /**
     * Calls the scheduler for resizing when the mouse moves.
     * @param {MouseEvent} e
     */
    const onMouseMove = (e) => {
        scheduleResize(e, prevMouseState);
    }

    /**
     * Removes the event listeners of the window and perform one final resize before the end of dragging.
     * @param {MouseEvent} e
     */
    const onMouseUp = (e) => {
        window.removeEventListener("mousemove", onMouseMove);
        window.removeEventListener("touchmove", onMouseMove);
        window.removeEventListener("mouseup", onMouseUp);
        window.removeEventListener("touchend", onMouseUp);
        resizeColumn(e, prevMouseState);
        setIsResizing(false);
    };

    return (
        <>
            <div
                onMouseDown={onMouseDown}
                onTouchStart={onMouseDown}
                className={classnames(
                    'data-grid-column-resizer',
                    classNames.columnResizer,
                    {'reverse': reverse},
                    {'resizing': isResizing},
                    {[classNames.columnResizerDragged]: isResizing},
                )}
            />
        </>
    );
}

export default DataGridColumnResizer;
