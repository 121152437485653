import {all} from 'redux-saga/effects';
import authSaga from './entities/auth/saga';

/**
 * The root saga that is the gateway for all the saga listeners in redux.
 */
function* sagas() {
    yield all([
        authSaga()
    ]);
}

export default sagas;
