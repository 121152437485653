import React, {useEffect, useMemo, useState} from "react";
import useRouter from "../../../hooks/use-router";
import {createUUId, stringComparator} from "../../../../core/services/utils/utils";
import PrintInvoiceTemplate from "./template";
import {apiMethods, PrintInvoiceViewTableTypes} from "../../../../core/constants/enums";
import useIsMounted from "../../../hooks/use-is-mounted";
import api from "../../../../core/services/api/api";
import ApplicationIds from "../../../../core/constants/application-ids";
import {creditsApis, invoicesApis, partnerApis} from "../../../../core/constants/endpoints/endpoints";
import PrintInvoiceViewPage from "./page";
import classnames from "classnames";


export const defaultPrintInvoiceViewPadding = 20;

/**
 * Fetches the elements that are needed for the computation of the print pages.
 * @return {{productsTable: {footer: HTMLElement, footerContinue: HTMLElement, header: HTMLElement, body: HTMLElement}, page: HTMLElement, pageHeader: HTMLElement, notesTable: {footerContinue: HTMLElement, header: HTMLElement, body: HTMLElement}}}
 */
export const getElements = () => ({
    page: document.getElementById(ApplicationIds.printInvoiceViewIds.page),
    table: document.getElementById(ApplicationIds.printInvoiceViewIds.table),
    pageHeader: document.getElementById(ApplicationIds.printInvoiceViewIds.pageHeader),
    tablesDistance: document.getElementById(ApplicationIds.printInvoiceViewIds.tablesDistance),
    productsTable: {
        header: document.getElementById(ApplicationIds.printInvoiceViewIds.productsTable.header),
        body: document.getElementById(ApplicationIds.printInvoiceViewIds.productsTable.body),
        bodyFiller: document.getElementById(ApplicationIds.printInvoiceViewIds.productsTable.bodyFiller),
        footerContinue: document.getElementById(ApplicationIds.printInvoiceViewIds.productsTable.footerContinue),
        footer: document.getElementById(ApplicationIds.printInvoiceViewIds.productsTable.footer),
    },
    notesTable: {
        header: document.getElementById(ApplicationIds.printInvoiceViewIds.notesTable.header),
        body: document.getElementById(ApplicationIds.printInvoiceViewIds.notesTable.body),
        footerContinue: document.getElementById(ApplicationIds.printInvoiceViewIds.notesTable.footerContinue),
    }

})

/**
 * Fetches the static heights of the template elements. These aid in the process of determining the print pages.
 * @param {any} elements
 * @return {{productsTableStaticHeightWithContinue: number, notesTableStaticHeight: number, notesTableStaticHeightWithContinue: number, productsTableStaticHeight: number}}
 */
export const getHeights = (elements) => ({
    productsTableStaticHeight: elements.productsTable.header.scrollHeight + elements.productsTable.footer.scrollHeight,
    productsTableStaticHeightWithContinue: elements.productsTable.header.scrollHeight + elements.productsTable.footerContinue.scrollHeight,
    productsTableFooterStaticHeight: elements.productsTable.footer.scrollHeight,
    productsTableFooterStaticHeightWithContinue: elements.productsTable.footerContinue.scrollHeight,
    notesTableStaticHeight: elements.notesTable.header.scrollHeight,
    notesTableStaticHeightWithContinue: elements.notesTable.header.scrollHeight + elements.notesTable.footerContinue.scrollHeight,
    tablesDistance: elements.tablesDistance.scrollHeight,
})

/**
 * @typedef {{
 *     pageNumber: int,
 *     totalPages: int,
 *     entities: {name: string, range: [number, number], useContinueFooter: boolean, spacerHeight: number}[],
 *     key: string,
 * }} PrintInvoicePage
 */


// TODO: If the footer rows length exceed a number like 20 or so, the static height of the page becomes more than
//  the available height for the entries of the page (so we get infinite pages)
// we can either limit the user to only show a specific number of transactions (so that in total we get 20 rows for
// footer or change the strategy of our printing)
const PrintInvoiceView = ({credit = false}) => {
    const {params} = useRouter();
    const [printInfoReady, setPrintInfoReadyReady] = useState(false);
    const [partnerInfoReady, setPartnerInfoReady] = useState(false);
    const [invoice, setInvoice] = useState(null);
    const [partner, setPartner] = useState(null);
    const [pages, setPages] = useState([]);
    const isMounted = useIsMounted();

    const showTransactions = true;
    const ready = useMemo(() => printInfoReady && partnerInfoReady, [printInfoReady, partnerInfoReady]);

    /**
     * Loads the selected invoice as soon as the component loads
     * As soon as the component mounts, the print dialog is also opened
     */
    useEffect(() => {
        getOrderInformation(credit).then()
        getPartnerInformation().then()
    }, [params.id, credit])

    /**
     * With each change in Invoice:
     * - sets the ready state based on the existence of the invoice.
     * - if the ready state is set to true, waits for 2 seconds and then calculates the necessary info from the DOM.
     */
    useEffect(() => {
        if (!invoice || !partner) {
            return setPrintInfoReadyReady(false);
        }
        // give browser to render its elements and load the logo.
        setTimeout(() => {
            setPrintPages();
            setPrintInfoReadyReady(true);
        }, 2000)
    }, [invoice, partner])

    /**
     * Fetches the full information of the invoice from the server and if the result of the api is successful then
     * sets the data.
     */
    const getOrderInformation = async (credit = false) => {
        setPrintInfoReadyReady(false);
        const response = await api({
            url: credit
                ? creditsApis.getFullDetails(params.id)
                : invoicesApis.getFullDetails(params.id),
            method: apiMethods.get,
        });
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            // sort the appliedTransactions by their type of the id so that the gift card can be at the beginning
            // or at the end.
            response.data.appliedTransactions = response.data?.appliedTransactions?.sort((a, b) => stringComparator(a.transactionTypeName, b.transactionTypeName));
            setInvoice(response.data);
        }
    }

    /**
     * Fetches the full information of the current partner that the invoice belongs to from the server and if the result
     * of the api is successful then sets the data.
     */
    const getPartnerInformation = async () => {
        setPartnerInfoReady(false);
        const response = await api({
            url: partnerApis.getPartnerInformation,
            method: apiMethods.get,
        })
        if (!isMounted()) return;
        if (response?.resultFlag) {
            setPartner({
                ...response.data,
                logo: (response?.configuration?.PartnerLogoUrlBaseAddress ?? '') + (response?.data?.logo ?? ''),
            });
            setPartnerInfoReady(true);
        }
    }


    /**
     * Sets the pages that are to be rendered for printing.
     */
    const setPrintPages = () => {
        const elements = getElements();
        const heights = getHeights(elements);
        setPages(createPages(elements, heights));
    }

    /**
     * Creates the pages that are to be rendered for printing.
     * @return {PrintInvoicePage[]}
     */
    const createPages = (elements, heights) => {
        const MAX_PAGE_HEIGHT = elements.page.clientHeight - (defaultPrintInvoiceViewPadding * 2) - elements.pageHeader.scrollHeight;
        /**@type {HTMLCollection}*/
        const products = elements.productsTable.body.children;
        /**@type {HTMLCollection}*/
        const notes = elements.notesTable.body.children;

        // Indices to keep track of which product and note are being processed
        let productsIndex = 0;
        let notesIndex = 0;

        // Array to store the pages
        let pages = [];
        let productsNormalFooterAdded = false;

        while (productsIndex < products.length || notesIndex < notes.length) {
            let currentPageProducts = [];
            let currentPageNotes = [];
            let remainingHeight = MAX_PAGE_HEIGHT;
            let useProductsContinueFooter = false;

            // Add products
            while (productsIndex < products.length) {
                let nextProductHeight = products[productsIndex].scrollHeight;

                // Check if we can add more products without the "continue" footer
                if ((remainingHeight - nextProductHeight) < heights.productsTableStaticHeight) {
                    useProductsContinueFooter = true;
                    break;
                }

                currentPageProducts.push(productsIndex);
                remainingHeight -= nextProductHeight;
                productsIndex++;
            }

            // Try to add more products with the "continue" footer
            if (useProductsContinueFooter) {
                while (productsIndex < products.length) {
                    let nextProductHeight = products[productsIndex].scrollHeight;

                    if ((remainingHeight - nextProductHeight) < heights.productsTableStaticHeightWithContinue) {
                        break;
                    }

                    currentPageProducts.push(productsIndex);
                    remainingHeight -= nextProductHeight;
                    productsIndex++;
                }
            }

            if (!productsNormalFooterAdded && !useProductsContinueFooter) {
                productsNormalFooterAdded = true;
            }

            // Reduce the remaining height by the distance between the products and notes tables or the products table footer
            if (currentPageProducts.length > 0) {
                remainingHeight -= heights.tablesDistance;
            }
            if (useProductsContinueFooter) {
                remainingHeight -= heights.productsTableFooterStaticHeightWithContinue;
            }
            if (!useProductsContinueFooter && productsNormalFooterAdded) {
                remainingHeight -= heights.productsTableFooterStaticHeight;
            }

            // Add notes
            if (notes.length > 0) {
                if (remainingHeight > 0) {
                    // Add notes
                    while (notesIndex < notes.length) {
                        let nextNoteHeight = notes[notesIndex].scrollHeight;

                        if ((remainingHeight - nextNoteHeight) < heights.notesTableStaticHeight) {
                            break;
                        }

                        currentPageNotes.push(notesIndex);
                        remainingHeight -= nextNoteHeight;
                        notesIndex++;
                    }


                    // Try to add more notes with the "continue" footer
                    while (notesIndex < notes.length) {
                        let nextNoteHeight = notes[notesIndex].scrollHeight;

                        if ((remainingHeight - nextNoteHeight) < heights.notesTableStaticHeightWithContinue) {
                            break;
                        }

                        currentPageNotes.push(notesIndex);
                        remainingHeight -= nextNoteHeight;
                        notesIndex++;
                    }
                }
            }

            // Add the current page to the pages array
            pages.push({
                products: currentPageProducts,
                notes: currentPageNotes,
                remainingHeight: remainingHeight,
                useProductsContinueFooter: useProductsContinueFooter,
            });
        }

        // if the footer was not added, add it now
        if (!productsNormalFooterAdded) {
            pages.push({
                products: [],
                notes: [],
                remainingHeight: MAX_PAGE_HEIGHT - heights.productsTableFooterStaticHeight,
                useProductsContinueFooter: false,
            });
        }

        // add the product filled to any page, so it can fill up the space.
        for (const page of pages) {
            if (page.products.length === 0) {
                page.products.push(products.length - 1)
            }
        }


        return pages.map((page, index, array) => {
            const productsEntity = {
                name: PrintInvoiceViewTableTypes.products,
                range: [
                    page.products.reduce((min, p) => p < min ? p : min, Number.MAX_SAFE_INTEGER),
                    page.products.reduce((max, p) => p > max ? p : max, Number.MIN_SAFE_INTEGER),
                ].filter(e => e !== Number.MIN_SAFE_INTEGER && e !== Number.MAX_SAFE_INTEGER),
                useContinueFooter: page.useProductsContinueFooter,
                spacerHeight: page.remainingHeight,
            }
            const notesEntity = {
                name: PrintInvoiceViewTableTypes.notes,
                range: [
                    page.notes.reduce((min, p) => p < min ? p : min, Number.MAX_SAFE_INTEGER),
                    page.notes.reduce((max, p) => p > max ? p : max, Number.MIN_SAFE_INTEGER),
                ].filter(e => e !== Number.MIN_SAFE_INTEGER && e !== Number.MAX_SAFE_INTEGER),
                useContinueFooter: index !== array.length - 1,
                spacerHeight: 0,
            }

            const entities = [];
            if (productsEntity.range.length === 2 || !productsEntity.useContinueFooter) {
                entities.push(productsEntity);
            }
            if (notesEntity.range.length === 2) {
                entities.push(notesEntity);
            }
            return ({
                entities: entities,
                key: createUUId(),
                pageNumber: index + 1,
                totalPages: array.length,
            })
        });
    }

    return (
        <>
            <div className={classnames('print-invoice-view', {'ready': ready})}>
                {
                    !ready &&
                    <div className={'loading print-invoice-page'}>
                        <div/>
                    </div>
                }
                {
                    ready && !!invoice &&
                    <div className={'pages'}>
                        {
                            pages.map(page => (
                                <PrintInvoiceViewPage
                                    key={page.key}
                                    page={page}
                                />
                            ))
                        }
                    </div>
                }
                {
                    !!invoice && !!partner &&
                    <PrintInvoiceTemplate
                        credit={credit}
                        invoice={invoice}
                        partner={partner}
                        showTransactions={showTransactions}
                    />
                }
            </div>
        </>
    )
}

export default PrintInvoiceView;
