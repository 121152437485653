/**
 * The Service that is used to keep the variables used in the .env file.
 */
export default class EnvService {
    /**
     * Determines if the current environment is development.
     */
    static isDevelopment: boolean = !!process.env.NODE_ENV && process.env.NODE_ENV === 'development'

    /**
     * The base url of fetching the files of this package.
     */
    static fileBaseUrl: string = 'https://admin.chtp.com/files/';

    /**
     * The url for uploading images for this package.
     */
    static uploadFileUrl: string = 'https://admin.chtp.com/api/FileUploader/Upload';
}
