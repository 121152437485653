import React, {useEffect} from 'react';
import {Route, Switch} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setReduxHeaderType} from "../../../redux/actions";
import routes from "../../routes";
import useRouter from "../../hooks/use-router";
import {HeaderTypes} from "../../../core/constants/enums";
import CacheService from "../../../core/services/cache/cache-service";
import TechnicalFileView from "./technical-file";
import PartInformationView from "../main/part-information";
import PartSupportExtraInfoView from "./part-support-extra-info";


const PublicLinkViews = () => {
    const dispatch = useDispatch();
    const {location} = useRouter();

    /**
     * Listens for the changes in location and with each change
     * - depending on the authorization of the current user sets the appropriate header.
     */
    useEffect(() => {
        dispatch(setReduxHeaderType(CacheService.isLoggedIn()
            ? HeaderTypes.loggedIn
            : HeaderTypes.loggedOut
        ))
    }, [dispatch, location]);

    return (
        <>
            <Switch>
                <Route path={[routes.publicLinks.technicalFile]} exact>
                    <TechnicalFileView/>
                </Route>
                <Route path={[routes.publicLinks.partSupportExtraInfo]} exact>
                    <PartSupportExtraInfoView/>
                </Route>
                <Route path={[routes.publicLinks.partInformation]} exact>
                    <PartInformationView/>
                </Route>
            </Switch>
        </>

    );
}

export default PublicLinkViews;
