import React, {useEffect, useRef, useState} from "react";
import useRouter from "../../../../../hooks/use-router";
import useAlert from "../../../../../hooks/use-alert";
import {AlertTypes, ProductsSearchTypes, TechnicalPropertyPrefix, TechnicalPropertyTypes} from "../../../../../../core/constants/enums";
import Form from "../../../../base/form";
import routes from "../../../../../routes";
import {Alert, Col, Row} from "reactstrap";
import classnames from "classnames";
import {ReactComponent as CategoryIcon} from "../../../../../../assets/images/input-icons/category.svg";
import {ReactComponent as OrderByIcon} from "../../../../../../assets/images/input-icons/orderby.svg";
import ProductSearchUtils from "../../../../../../core/services/utils/product-search-utils";
import {deepEqual} from "../../../../../../core/services/utils/utils";
import Regexes from "../../../../../../core/constants/regexes";
import ValidateMessages from "../../../../../../core/constants/validate-messages";
import {Close} from "@material-ui/icons";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import * as Yup from "yup";
import {makeRequired, makeValidate} from "mui-rff";
import MuiSelect from "../../../../base/select";
import MuiInput from "../../../../base/input/mui-input";
import Switch from "../../../../base/switch";


const formKeys = ProductSearchUtils.technicalSearchFormKeys;
const queryKeys = ProductSearchUtils.technicalSearchQueryKeys;

const initialSchema = Yup.object().shape({
    [formKeys.category1]: Yup.number().required(ValidateMessages.required).nullable(),
    [formKeys.category2]: Yup.number().nullable(),
    [formKeys.category3]: Yup.number().nullable(),
    [formKeys.orderBy]: Yup.string().nullable(),
    [formKeys.properties]: Yup.object().nullable(),
});

const propertyPrefix = (id) => `${TechnicalPropertyPrefix}${id}`

const ProductsSearchTechnicalForm = ({formData, data, categoriesFlat}) => {
    const {history, stringifyUrl} = useRouter();
    const [schema, setSchema] = useState(initialSchema);
    const [initialValues, setInitialValues] = useState(data ?? {});
    const prevDataProp = useRef({});
    const [alert, setAlert] = useAlert(10000);

    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * Listens for the changes in dataProp and with each change:
     * - syncs dataProp with the data in the state.
     */
    useEffect(() => {
        if (!formData.categories?.length || !categoriesFlat?.length) {
            return;
        }
        if (!Object.keys(data).length) {
            setInitialValues({});
            prevDataProp.current = {}
            return
        }
        if (deepEqual(data, prevDataProp.current)) {
            return;
        }
        shouldSetInitialValues().then()
    }, [data, categoriesFlat, formData])

    /**
     * Determines if search should occur again.
     * @return {Promise<void>}
     */
    const shouldSetInitialValues = async () => {
        const [newData, removed] = ProductSearchUtils.parseTechnicalSearchQuery(data, {
            categories: formData.categories,
            orderByList: formData.orderByList,
            categoriesFlat: categoriesFlat,
        })
        setInitialValues(newData);
        prevDataProp.current = data
        if (removed?.length) {
            search(newData).then()
        }
    }

    /**
     * Changes the query of the url with the given search values.
     * @param {Event | null} values
     * @param {boolean} form
     * @param {any} values the form values.
     */
    const search = async (values, form = false) => {
        const properties = Object.entries(values)?.filter(([key, value]) => key.match(Regexes.technicalPropertyFinder) && !!value) ?? [];

        if (!properties?.length) {
            document
                .getElementsByClassName('products-search-section')
                .item(0)
                .scrollIntoView(true)
            setAlert({
                isOpen: true,
                message: "At least 1 property must contain a value",
                color: AlertTypes.error
            })
            return;
        }

        const formToQueryKeyMapping = {
            [queryKeys.categoryId]: (values) => values[formKeys.category3] ?? values[formKeys.category2] ?? values[formKeys.category1],
            [queryKeys.orderBy]: formKeys.orderBy,
            ...Object.fromEntries(properties.map(([k]) => [k, k]))
        }
        const _formKeys = {
            ...formKeys,
            ...Object.fromEntries(properties.map(([k]) => [k, k]))
        }
        const a = ProductSearchUtils.transformFormValuesToSearchQuery(_formKeys, values, formToQueryKeyMapping, false, false, (key) => key.match(Regexes.technicalPropertyFinder));
        debugger;
        const url = stringifyUrl({
            url: routes.main.products,
            query: {
                type: ProductsSearchTypes.technical,
                data: JSON.stringify({
                    [ProductsSearchTypes.technical]: ProductSearchUtils.transformFormValuesToSearchQuery(_formKeys, values, formToQueryKeyMapping,
                        false, false, (key) => key.match(Regexes.technicalPropertyFinder)),
                })
            },
        });

        if (form)
            history.push(url);
        else
            history.replace(url);
    };

    /**
     * Empties the sub-category values of a particular category that has been changed.
     * for each first-order category a new property objects will get instructed, and a new schema will get constructed
     * @param e {Object}
     * @param form
     * @param categoryNumber {number}
     */
    const onCategoryChange = (e, form, categoryNumber) => {
        if (categoryNumber === 1) {
            changeFormProperties(e.target.value);
            form.change(formKeys.properties, undefined);
        }
        for (let index = categoryNumber + 1; index < 4; index++) {
            form.change(formKeys[`category${index}`], undefined);
        }
    }

    /**
     * For each first-order category a new property objects will get instructed, and a new schema will get constructed.
     * @param value
     */
    const changeFormProperties = (value) => {
        const properties = formData.categories?.find(c => c.id === value)?.properties ?? [];
        const newProperties = {};
        properties.forEach(property => {
            const id = propertyPrefix(property.id);
            switch (property.type) {
                case TechnicalPropertyTypes.dropdown:
                    newProperties[id] = Yup.object().nullable();
                    break;
                case TechnicalPropertyTypes.input:
                    newProperties[id] = Yup.string().trim().nullable();
                    break;
                case TechnicalPropertyTypes.switch:
                    newProperties[id] = Yup.object().nullable();
                    break;
                default:
                    break;
            }
        });
        const schema = Yup.object().shape({
            [formKeys.category1]: Yup.number().required(ValidateMessages.required).nullable(),
            [formKeys.category2]: Yup.number().nullable(),
            [formKeys.category3]: Yup.number().nullable(),
            [formKeys.orderBy]: Yup.string().nullable(),
            [formKeys.properties]: Yup.object().nullable(),
            ...newProperties,
        });
        setSchema(schema);
    }

    /**
     * Removes the property and sets its value for the form to null.
     * @param property {any}
     * @param values {any} values of the form
     * @param form
     */
    const onRemoveProperty = (property, values, form) => {
        form.change(formKeys.properties, [...values[formKeys.properties].filter(e => e !== property.id)])
        form.change(propertyPrefix(property.id), null)
    }

    /**
     * Creates the properties of the technical search.
     * @param allProperties {any}
     * @param values {any}
     * @param form {any}
     * @return {JSXElement[]}
     */
    const renderProperties = (allProperties, values, form) => {
        const selectedPropIds = values[formKeys.properties] ?? [];
        const selectedProps = allProperties?.filter(e => selectedPropIds.includes(e.id))
        return selectedProps
            ?.map((property, index) => (
                <CSSTransition
                    key={property.id}
                    classNames={'fade'}
                    timeout={300}>
                    <Col xs={12} md={6} className={classnames('transition px-0 mb-3',
                        {
                            'pr-0 pr-md-2': !(index % 2),
                            'pl-0 pl-md-2': index % 2,
                        }
                    )}>
                        <div className={'remove-container'}>
                            <Close className={'remove'} onClick={() => onRemoveProperty(property, values, form)}/>
                            {renderProperty(values, form, property)}
                        </div>
                    </Col>
                </CSSTransition>
            ));
    }

    /**
     * Renders a single property based on its type.
     *
     * @param values
     * @param property
     * @param form
     * @return {JSX.Element|null}
     */
    const renderProperty = (values, form, property) => {
        const name = propertyPrefix(property.id)
        switch (property.type) {
            case TechnicalPropertyTypes.dropdown:
                return (
                    <MuiSelect
                        key={name}
                        form
                        label={property.title}
                        name={name}
                        required={false}
                        data={property.defaultValue?.map(e => ({
                            value: e.id,
                            label: e.title,
                            id: e.id
                        })) ?? []}
                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                    />
                )
            case TechnicalPropertyTypes.input:
                return (
                    <MuiInput
                        key={name}
                        form
                        value={values?.[name] ?? ''}
                        type={'text'}
                        name={name}
                        placeholder={property.title}
                    />
                )
            case TechnicalPropertyTypes.switch:
                return (
                    <Switch
                        key={name}
                        size={4.5}
                        checked={values[name] ?? false}
                        onChange={(checked) => form.change(name, checked)}
                    />
                )
            default:
                return null;
        }
    }

    return (
        <>
            <Form
                onSubmit={(v) => search(v, true)}
                validate={validate}
                initialValues={initialValues}
                render={({values, form}) => {
                    const firstCategories = formData?.categories ?? [];
                    const secondCategories = firstCategories?.find(e => e.id === Number(values[formKeys.category1]))?.children ?? [];
                    const thirdCategories = secondCategories?.find(e => e.id === Number(values[formKeys.category2]))?.children ?? [];
                    const properties = firstCategories?.find(e => e.id === Number(values[formKeys.category1]))?.properties ?? [];
                    const showSecondCategory = !!values[formKeys.category1] && secondCategories?.length > 0
                    const showThirdCategory = showSecondCategory && !!values[formKeys.category2] && thirdCategories?.length > 0

                    debugger;
                    return (
                        <>
                            <Alert color={alert.color} isOpen={alert.isOpen}>
                                {alert.message}
                            </Alert>
                            <Row form>
                                <Col
                                    xs={12}
                                    md={showThirdCategory ? 4 : showSecondCategory ? 6 : 12}
                                    className={'mb-3'}
                                >
                                    <MuiSelect
                                        form
                                        label={"Root Category"}
                                        name={formKeys.category1}
                                        required={!!required[formKeys.category1]}
                                        data={firstCategories?.map(e => ({
                                            value: e.id,
                                            label: e.title,
                                            id: e.id
                                        })) ?? []}
                                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                                        onChange={(e) => onCategoryChange(e, form, 1)}
                                    />
                                </Col>
                                <Col
                                    xs={12}
                                    md={showThirdCategory ? 4 : 6}
                                    className={classnames('mb-3', {'w-0': !showSecondCategory})}
                                >
                                    <MuiSelect
                                        form
                                        label={"Sub Category"}
                                        name={formKeys.category2}
                                        required={!!required[formKeys.category2]}
                                        data={secondCategories?.map(e => ({
                                            value: e.id,
                                            label: e.title,
                                            id: e.id
                                        })) ?? []}
                                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                                        onChange={(e) => onCategoryChange(e, form, 2)}
                                    />
                                </Col>
                                <Col
                                    xs={12}
                                    md={4}
                                    className={classnames('mb-3', {'w-0': !showThirdCategory})}
                                >
                                    <MuiSelect
                                        form
                                        label={"Sub Category"}
                                        name={formKeys.category3}
                                        required={!!required[formKeys.category3]}
                                        data={thirdCategories?.map(e => ({
                                            value: e.id,
                                            label: e.title,
                                            id: e.id
                                        })) ?? []}
                                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                                        onChange={(e) => onCategoryChange(e, form, 3)}
                                    />
                                </Col>
                                <Col xs={12} className={'mb-3'}>
                                    <MuiSelect
                                        form
                                        label={"Order By"}
                                        name={formKeys.orderBy}
                                        required={!!required[formKeys.orderBy]}
                                        data={formData.orderByList?.map(e => ({
                                            value: e.name,
                                            label: e.title,
                                            id: e.name
                                        })) ?? []}
                                        startAdornment={<OrderByIcon className={'select-icon'}/>}
                                    />
                                </Col>
                                <Col xs={12} className={classnames('mb-3', {'w-0': !showSecondCategory})}>
                                    <MuiSelect
                                        form
                                        startAdornment={<CategoryIcon className={'start-adornment'}/>}
                                        multiple
                                        fullWidth
                                        name={formKeys.properties}
                                        label={'Properties'}
                                        value={values?.[formKeys.properties] ?? []}
                                        renderValue={(value) => {
                                            if (value?.length === 1 && typeof value[0] === 'string') {
                                                return <p className={'mb-0 ml-4 '}>{value}</p>;
                                            }
                                            const valueIds = value ?? [];
                                            const selectedProps = properties?.filter(e => valueIds.includes(e.id))
                                            return <div className='chips-container'>
                                                {
                                                    selectedProps.map(e =>
                                                        <div key={e?.id} className='chips'>
                                                            {e?.title ?? ''}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        }}
                                        data={properties?.map(e => ({
                                            value: e.id,
                                            label: e.title,
                                            id: e.id
                                        })) ?? []}
                                    />
                                </Col>
                                <Col s={12} className={classnames({'w-0': !showSecondCategory})}>
                                    <TransitionGroup className={'d-flex flex-wrap w-100'}>
                                        {renderProperties(properties, values, form)}
                                    </TransitionGroup>
                                </Col>
                                <Col xs={12} className={'d-flex flex-column align-items-center justify-content-start'}>
                                    <Col md={5} lg={3} className={'p-0'}>
                                        <button className={'button primary w-100 px-5'} type={'submit'}>
                                            Search
                                        </button>
                                    </Col>
                                </Col>
                            </Row>
                        </>
                    );
                }}
            />
        </>
    )
}


export default ProductsSearchTechnicalForm;
