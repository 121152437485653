import axios from 'axios';
import LocalStorageService from '../cache/local-storage-service'
import store from "../../../redux/store";
import {apiMethods, HeaderTypes} from "../../constants/enums";
import {accessDeniedDialog, setReduxHeaderType, unAuthorizedDialog} from "../../../redux/actions";
import ApiResponseMessages from "../../constants/api-response-messages";
import {toast} from "react-toastify";
import {getOS} from "../utils/utils";
import EnvService from "../env-service";
import {bizliveApis} from "../../constants/endpoints/endpoints";
import CacheService from "../cache/cache-service";
import ApiResponseCodes from "../../constants/api-response-codes";
import BroadcastService from "../broadcast-service";
import {matchPath} from "react-router-dom";
import routes, {routeLists} from "../../../ui/routes";

/**
 * Represents an interface for all apis calls in the system.
 */
export class Api {
    /**
     * @type {import('history').History}
     * @private
     */
    static _history;
    /**
     * @type {import('history').Location}
     * @private
     */
    static _location;

    /**
     * Fetches the location tact is cached in this service.
     * @return {import('history').Location}
     */
    static get location() {
        return this._location;
    }

    /**
     * Fetches the location tact is cached in this service.
     * @return {import('history').history}
     */
    static get history() {
        return this._history;
    }

    /**
     * Sets the current routing history of the application to be used in the logic of our api calls.
     * @param {import('history').History} value
     */
    static set history(value) {
        this._history = value;
    }

    /**
     * Sets the current routing location of the application to be used in the logic of our api calls.
     * @param {import('history').Location} value
     */
    static set location(value) {
        this._location = value;
    }

    /**
     * Converts the string of apiMethod to the enum like property that can be used in axios request.
     * @param {string} method
     * @return {string}
     * @private
     */
    static _determineApiMethod(method) {
        switch (method) {
            case "get":
            case apiMethods.get:
                return apiMethods.get;
            case "put":
            case apiMethods.put:
                return apiMethods.put;
            case "delete":
            case apiMethods.delete:
                return apiMethods.delete;
            case 'post':
            case apiMethods.post:
            default:
                return apiMethods.post;
        }
    }

    /**
     *
     * @param url
     * @param method
     * @param data
     * @param headers
     * @param extra
     * @return {Promise<import("axios").AxiosRequestConfig<any>>}
     * @private
     */
    static async _getApiRequest(url, method, data, headers, extra) {
        return {
            url,
            data,
            method: this._determineApiMethod(method),
            headers: await this.getApiHeaders(headers),
            ...(extra ?? {}),
        }
    }

    /**
     * Checks if the url of the api call is related to BizLive service,
     * in this case, returns true if api call url is for BizLive, otherwise returns false
     * @param url
     * @return {boolean}
     */
    static _shouldNotShowDialogs(url) {
        switch (url) {
            case bizliveApis.deactivate:
            case bizliveApis.activate:
                return true;
            default:
                return false;
        }
    }

    /**
     * Fetches the headers of the api call.
     * @param {any} headers the extra headers that are sent to override the default ones.
     * @return {*&{Authorization: (any|string), Os: (string), "Access-Control-Allow-Origin": string, "Access-Control-Allow-Methods": string, PartnerId: (any|string), "Content-Type": string}}
     * @private
     */
    static getApiHeaders(headers) {
        const authToken = LocalStorageService.get(LocalStorageService.keys.token);
        const partnerId = LocalStorageService.get(LocalStorageService.keys.partnerId);
        return {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
            Os: getOS(),
            Authorization: authToken ?? '',
            PartnerId: partnerId ?? EnvService.DemoPartnerId,
            ...(headers ?? {}),
        };
    }


    /**
     * Navigates the user to the authentication view and caches the url if pathname arg is provided.
     * @param history
     * @param pathname
     * @return {*|string}
     */
    static navigateToAuthenticationView(history, pathname = undefined) {
        if (pathname) {
            CacheService.cacheUrl(pathname)
        }
        if (history) {
            return history.replace(routes.auth.login);
        }
        return window.location.pathname = routes.auth.login;
    }

    /**
     * Handles the preempted requests due to authentication for browsers that do not support BroadcastChannel feature.
     *
     * * if the api request's location is changed, does nothing
     * * if the user is in any of the public views and not in the home page: only the header of the application is changed
     * * if the user is in the private views, and they are logged out due to a token expiration: opens the unauthorized dialog
     * * if the user is in the private views, and they do not have any token set in the cache: navigates them to the login view.
     * @param {import('history').History} history
     * @param {import('history').Location} location
     * @param {boolean} fromBroadcastManager
     */
    static onRequestPreemptedDueToAuthentication(history, location, fromBroadcastManager = false) {
        const pathname = location?.pathname;
        if (EnvService.isDevelopment && !fromBroadcastManager) {
            console.log("Request Preempting happened inside api instead of broadcasting service")
        }

        if (!fromBroadcastManager && this.location?.pathname && this.location?.pathname !== pathname)
            return;

        if (!pathname) // since we do not know where we are, navigate to log in view
            return this.navigateToAuthenticationView(history);

        const inPublicViews = matchPath(pathname, {
            path: [
                ...routeLists.auth,
                ...routeLists.public,
                ...routeLists.publicLinks,
                routes.error,
                routes.redirect,
            ],
            exact: false,
        })
        const inAuthViews = !!matchPath(pathname, {
            path: routes.auth.base,
        })
        const inLandingView = !!matchPath(pathname, routes.landing);
        if (inPublicViews && !inLandingView) {
            store.dispatch(setReduxHeaderType(inAuthViews ? HeaderTypes.auth : HeaderTypes.loggedOut));
        }

        if (!inAuthViews) {
            if (CacheService.hasTokenAndExpiration) {
                // users who were already logged in, so we show dialog.
                return store.dispatch(unAuthorizedDialog({open: true}));
            }
            return this.navigateToAuthenticationView(history, location);
        }
    }


    /**
     * Invokes an api call for the given api request.
     *
     * * injects the current history and location of the application to the request data.
     * @param {Partial<ApiRequestDs>} request
     * @return {CrudResponse}
     */
    static async _api(request) {
        const {
            url = '',
            method = apiMethods.get,
            data = null,
            headers = {},
            extra = {},
            showSuccess = false,
            showError = true,
            loginRequired = true,
            history,
            location,
        } = request;

        try {
            if (loginRequired && !CacheService.isLoggedIn()) {
                const broadcastResultFlag = BroadcastService.postMessage(BroadcastService.channelNames.userTokenExpired, location ?? {});
                if (!broadcastResultFlag) {
                    this.onRequestPreemptedDueToAuthentication(history, location);
                }
                // TODO: refactor this. if extra time
                return {
                    resultFlag: false,
                    data: null,
                    message: '',
                    headerTitle: '',
                    errorCode: ApiResponseCodes.apiAbortedDueToAuth,
                    status: ApiResponseCodes.apiAbortedDueToAuth,
                    isPreemptedDueToNotBeingLoggedIn: true
                };
            }
            const response = await axios.request(await this._getApiRequest(url, method, data, headers, extra));
            const resData = response.data;

            if (!resData.resultFlag) {
                const errorData = {
                    message: resData?.message ?? ApiResponseMessages.serverErrorMessage,
                    headerTitle: resData?.headerTitle ?? ApiResponseMessages.serverErrorTitle,
                    errorCode: resData?.errorCode ?? null,
                };
                let fatalError = false;
                if ((errorData.errorCode === 401 || response.status === 401 || resData.errorCode === -50 || resData?.Code === -8) && !this._shouldNotShowDialogs(url)) {
                    fatalError = true;
                    // TODO: fix this
                    store.dispatch(unAuthorizedDialog({open: true, message: response.data.message}));
                    LocalStorageService.remove(LocalStorageService.keys.Ip4);
                }
                if ((errorData.errorCode === 403 || response.status === 403 || errorData.errorCode === -40) && !this._shouldNotShowDialogs(url)) {
                    fatalError = true;
                    // TODO: fix this
                    store.dispatch(accessDeniedDialog({open: true, message: response.data.message}));
                }
                if (showError) {
                    if (fatalError) {
                        toast.dismiss('fatal-error')
                        toast.error(`${errorData.headerTitle} : ${errorData.message}`, {toastId: 'fatal-error'});
                    } else {
                        toast.error(`${errorData.headerTitle} : ${errorData.message}`);
                    }
                }
            } else {
                if (showSuccess) toast.success(`${resData.headerTitle ?? ApiResponseMessages.serverSuccessTitle} : ${resData.message ?? ApiResponseMessages.serverSuccessMessage}`);
            }
            if (!resData)
                return resData
            return {...resData, isPreemptedDueToNotBeingLoggedIn: false}

        } catch (error) {
            console.error(error);
            const aborted = error?.message === 'canceled';
            if (showError && !aborted) {
                toast.error(`${ApiResponseMessages.serverErrorTitle} : ${ApiResponseMessages.serverErrorMessage}`);
            }
            return {
                resultFlag: false,
                data: null,
                message: ApiResponseMessages.serverErrorMessage,
                headerTitle: ApiResponseMessages.serverErrorTitle,
                errorCode: 500,
                status: 500,
                isPreemptedDueToNotBeingLoggedIn: false
            }
        }
    };

    /**
     * Invokes an api call for the given api request.
     *
     * * injects the current history and location of the application to the request data.
     * @param {Partial<ApiRequestDs>} request
     * @return {CrudResponse}
     */
    static async api(request) {
        return this._api({
            ...request,
            history: Api._history,
            location: Api._location,
        });
    };
}

/**
 * Invokes an api call for the given api request.
 *
 * @type {function(Partial<ApiRequestDs>): Promise<CrudDs<T | null>>}
 */
const api = Api.api.bind(Api);

export default api;
