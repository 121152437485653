import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Col, Container, Row,} from "reactstrap";
import {useDispatch} from "react-redux";
import Iframe from "react-iframe";
import useRouter from "../../../hooks/use-router";
import classnames from "classnames";
import useWindowViewportWidth from "../../../hooks/use-window/viewport-width";
import {DCEEntryNames} from "../../../routes";
import {
    DynamicContentEditorEntryRecord,
    DynamicContentEditorListEntry,
    DynamicContentEditorModeContext,
    DynamicContentEditorModels
} from "../../../../packages/dynamic-content-editor/index";
import {setReduxHeaderHeight as setReduxHeaderHeight} from "../../../../redux/entities/header/actions";

const CatalogueView = () => {
    const {location, history, query, stringifyUrl} = useRouter();
    const [catalogues, setCatalogues] = useState([]);
    const [activeCatalogueQuery, setActiveCatalogueQuery] = useState();
    const dispatch = useDispatch();
    const headerRef = useRef();
    const windowViewPortWidth = useWindowViewportWidth();
    const dceMode = useContext(DynamicContentEditorModeContext);
    const isDceInEditMode = useMemo(() => dceMode === DynamicContentEditorModels.DynamicContentEditorMode.edit, [dceMode]);

    const showSeparator = useMemo(() => !['xs', 'sm', 'md'].includes(windowViewPortWidth), [windowViewPortWidth])
    const selectedCatalogue = useMemo(() => {
        if (!catalogues.length)
            return undefined;
        if (!activeCatalogueQuery) {
            return catalogues[0];
        }
        return catalogues.find(e => e.query === activeCatalogueQuery);
    }, [activeCatalogueQuery, catalogues])


    /**
     * As soon as the component mounts:
     * - attaches an event listener to listen for the changes in the resizes of the window.
     */
    useEffect(() => {
        if (!headerRef.current)
            return;
        window.addEventListener('resize', setHeaderHeight);
        setHeaderHeight();
        return () => {
            window.removeEventListener('resize', setHeaderHeight);
        }
    }, [headerRef])

    /**
     * With each change in the value of the selected catalogue:
     * - sets the header height.
     */
    useEffect(() => {
        if (!selectedCatalogue)
            return;
        setHeaderHeight();
    }, [selectedCatalogue])

    /**
     * Listens for the changes in the query of the url params and with each change:
     * - changes the selected catalogue based on the value of the query param.
     */
    useEffect(() => {
        if (!catalogues?.length)
            return setActiveCatalogueQuery(undefined);
        if (query.selected?.length) {
            const found = catalogues?.find(e => e.query === query.selected);
            if (found) {
                return setActiveCatalogueQuery(found.query);
            }
        }
        return setActiveCatalogueQuery(catalogues[0].query);
    }, [query, catalogues])

    /**
     * Sets the height of the application header based on the current height of the headerRef.
     */
    const setHeaderHeight = () => {
        const height = headerRef.current?.clientHeight ?? 0;
        const headerHeight = (height / 2.5 - 150);
        const topMargin = headerHeight * -1;
        dispatch(setReduxHeaderHeight(Math.max(headerHeight + 30, 0)))
        headerRef.current.style.marginTop = topMargin + 'px';
    };

    /**
     * Changes the query param of this path to invoke the re-selection of the catalogue.
     * @param {string} value
     */
    const onCatalogueSelected = (value) => {
        history.replace(stringifyUrl({
            url: location.pathname,
            query: {selected: value ?? undefined}
        }))
    }

    /**
     * Sets the catalogues list in the state.
     * @param catalogues
     */
    const getCatalogues = (catalogues = []) => {
        setCatalogues(catalogues?.map(e => ({
            ...e,
            query: e[DynamicContentEditorModels.DynamicContentEditorListEntryItemIdentifierKey],
        })) ?? []);
    }

    return (
        <>
            <section ref={headerRef} className={'catalogues-view'}>
                <Container>
                    <div className={'catalogue-wrapper'}>
                        <DynamicContentEditorListEntry
                            includeIdentifier
                            isChildRelativePositioned
                            _title={'Catalogues'}
                            entryName={DCEEntryNames.catalogues}
                            dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.pageBased}
                            iconPosition={showSeparator
                                ? DynamicContentEditorModels.DynamicContentEditorEntryIconPositions.outward
                                : DynamicContentEditorModels.DynamicContentEditorEntryIconPositions.inward
                            }
                            getData={getCatalogues}
                            tag={Row}
                            className={classnames(
                                'w-100 d-flex flex-md-row justify-content-center align-items-center',
                                'tab-buttons-section my-5 position-relative'
                            )}
                            childTag={Col}
                            childProps={(_, index, array) => ({
                                lg: 3,
                                xs: 12,
                                className: classnames({
                                    'pr-lg-0': (array?.length ?? 0) - 1 !== (index ?? 0)
                                }),
                            })}
                        >
                            <DynamicContentEditorEntryRecord
                                recordKey={'title'}
                                _title={'Catalogue Title'}
                                tag={'p'}
                                className={'d-none'}
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                            />
                            <DynamicContentEditorEntryRecord
                                recordKey={'url'}
                                _title={'Catalogue Url'}
                                tag={'p'}
                                className={'d-none'}
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.url}
                            />
                            <DynamicContentEditorEntryRecord
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.element}
                            >
                                {(item) => {
                                    const isTemplate = !Object.keys(item).length;
                                    const catalogue = catalogues.find(e => e.title === item?.title);
                                    const index = catalogues.indexOf(catalogue);
                                    return (
                                        <div className={classnames(
                                            'd-flex align-items-center w-100 mb-3',
                                            {'mb-lg-5': isDceInEditMode}
                                        )}>
                                            <button
                                                disabled={isTemplate}
                                                onClick={() => onCatalogueSelected(catalogue?.query)}
                                                className={classnames(
                                                    {'active': activeCatalogueQuery === catalogue?.query},
                                                )}
                                            >
                                                {
                                                    isTemplate
                                                        ? "Click on the + icon"
                                                        : catalogue?.title ?? ''
                                                }
                                            </button>
                                            {
                                                showSeparator &&
                                                (![catalogues.length - 1].includes(index) || isTemplate) &&
                                                <div className={'tab-buttons-section-separator mr-0'}/>
                                            }
                                        </div>
                                    )
                                }}
                            </DynamicContentEditorEntryRecord>
                        </DynamicContentEditorListEntry>
                        {
                            selectedCatalogue &&
                            <Iframe
                                url={selectedCatalogue.url}
                                title={selectedCatalogue.title}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                width='93%'
                                frameBorder='none'
                                className={'iframe catalogue mb-5'}
                            />
                        }

                    </div>
                </Container>
            </section>
        </>
    );

}

export default CatalogueView;
