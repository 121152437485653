import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import $ from 'jquery';
import useIsMounted from "../../hooks/use-is-mounted";
import DynamicContentEditorApi from "../../../core/services/api";
import DynamicContentEditorUtils from "../../../core/services/utils";
import {DynamicContentEditorInputFileAcceptMimes} from "../../../index.d";


const DynamicContentEditorCkEditor = ({
                                          onChange,
                                          onBlur,
                                          value,
                                          disabled,
                                          className,
                                          onKeyPress: onKeyPressProps,
                                          error,
                                          ...props
                                      }) => {
    const [editor, setEditor] = useState();
    const id = useRef(DynamicContentEditorUtils.createUUId(true));
    const _id = useMemo(() => `#${id.current}`, [id]);
    const isMounted = useIsMounted();

    /**
     * WIth each change in the value prop:
     * - if editor exists, sets the value of the editor as the given prop value.
     */
    useLayoutEffect(() => {
        if (!editor)
            return;
        editor?.data.set(value);
        editor?.model?.change(writer => {
            writer.setSelection(writer.createPositionAt(editor.model.document.getRoot(), 'end'));
        });
    }, [editor])

    /**
     * As soon as the [editor] of this ck-editor becomes available:
     * - attaches the file uploader functionality in this editor.
     */
    useEffect(() => {
        if (!editor)
            return;
        attachFileUploaderToCKEditor();
    }, [editor])

    /**
     * Attaches the correct file uploader logic into the ck editor.
     * * removes the onChange callback of the input to prevent caching the selected files in the input.
     */
    const attachFileUploaderToCKEditor = () => {
        const buttonRef = $(`${_id} .ck-file-dialog-button button`);
        const inputRef = $(`${_id} .ck-file-dialog-button input`)
        buttonRef.off('click');
        buttonRef.on('click', () => {
            const input = document.createElement('input');
            input.type = 'file';
            input.accept = DynamicContentEditorInputFileAcceptMimes.images;
            input.onchange = onFileSelect;
            input.click();
        });
        inputRef.on('click', () => false);
        inputRef.off('change');
    }

    /**
     * Includes the given image in the ui of the CK editor.
     *
     * @param {string} src the src of the image to be included
     * @param {string} name the name of the image to be included
     */
    const includeUploadedImageInEditor = (src, name) => {
        const content = '<img src="' + src + '" alt="' + name + '"/>';
        const viewFragment = editor?.data.processor.toView(content);
        const modelFragment = editor?.data.toModel(viewFragment);
        if (editor) editor?.model.insertContent(modelFragment);
    };

    /**
     * Uploads the selected file into the server of the image.
     *
     * @param {InputEvent} event
     * @return {Promise<void>}
     */
    const onFileSelect = async (event) => {
        for (const file of event.target.files) {
            const response = await DynamicContentEditorApi.uploadFile(file);
            if (!isMounted())
                return;
            if (response?.resultFlag) {
                includeUploadedImageInEditor(response.configuration?.FileBaseURL?.concat(response.data?.fileName) ?? '', response.data?.fileName)
            }
        }
    };

    /**
     * Handles keyboard key presses on this component.
     * @param {KeyboardEvent} e
     */
    const onKeyPress = (e) => {
        if (!onKeyPressProps || !editor)
            return;
        onKeyPressProps(e, editor?.getData());
    }

    /**
     * Sets the editor state value if the component is still mounted
     * @param {any} editor
     */
    const onEditorIsReady = (editor) => {
        if (!isMounted())
            return;
        setEditor(editor);
    }

    /**
     * Calls the given callback if the component is still mounted and the callback exists.
     * @param {any} editor
     * @param {Function} callback
     */
    const setValue = (editor, callback) => {
        if (!isMounted() || !callback)
            return;
        callback(editor.getData())
    }

    return (
        <div id={id.current} className={'w-100'} onKeyPress={onKeyPress} {...props}>
            <CKEditor
                error={error}
                className={className}
                data={value}
                disabled={disabled}
                editor={ClassicEditor}
                config={{language: {ui: 'en', content: 'en'}}}
                onReady={onEditorIsReady}
                onChange={(event, editor) => setValue(editor, onChange)}
                onBlur={(event, editor) => setValue(editor, onBlur)}
            />
        </div>
    );
}

export default DynamicContentEditorCkEditor;
