import moment from "moment";
import React from "react";
import useRouter from "../../../../hooks/use-router";
import routes, {routeFunctions} from "../../../../routes";
import YoutubeVideoPlayer, {VideoTypes} from "../../../base/video-player/youtube";
import NativeVideoPlayer from "../../../base/video-player/native";
import {Link} from 'react-router-dom';
import classnames from "classnames";
import {correctUrl} from "../../../../../core/services/utils/utils";

const VideoCard = ({data, className}) => {
    const {history} = useRouter();

    const url = correctUrl(data?.url)
    const isVideoFromYoutube = url?.startsWith('https://www.youtube') ?? false;
    const internalLink = !!data
        ? routeFunctions.public.blogs.video(data?.slug)
        : routes.public.content.main

    /**
     * Navigates the user to the page of that specified video.
     */
    const navigateToSingle = () => {
        history.push(routeFunctions.public.blogs.video(data?.slug))
    }


    return (
        <>
            <div className={classnames('content-video-card', className)}>
                {
                    isVideoFromYoutube
                        ? <YoutubeVideoPlayer
                            key={data.id}
                            id={`video-${data.id}`}
                            videoId={url?.split('/')[url?.split('/').length - 1]}
                            type={VideoTypes.small}
                            title={data.title}
                        />
                        : <NativeVideoPlayer
                            key={data.id}
                            id={`video-${data.id}`}
                            src={url}
                            type={VideoTypes.small}
                            title={data.title}
                        />
                }

                <Link to={internalLink} className={'d-flex justify-content-between align-items-start'}
                      onClick={navigateToSingle}>
                    <h3 className={'title cursor-pointer-hover'}>
                        {data?.title}
                    </h3>
                    <p className={'date'}>
                        {data?.lastModifyDateTime
                            ? moment(data.lastModifyDateTime).format('MMM DD, YYYY')
                            : ''
                        }
                    </p>
                </Link>
            </div>
        </>
    )
}

export default VideoCard;
