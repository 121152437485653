import React, {forwardRef} from "react";
import classnames from "classnames";

/**
 * @param {string} className
 * @param {JSX.Element} children
 * @param {React.HTMLProps<HTMLButtonElement>} props
 * @return {JSX.Element}
 * @constructor
 */
const DataGridButton = forwardRef(({
                                       className,
                                       children,
                                       ...props
                                   }, ref) => {
    return (
        <>
            <button type={'button'} ref={ref} {...props} className={classnames('data-grid-button', className)}>
                {children}
            </button>
        </>
    )
})

export default DataGridButton;
