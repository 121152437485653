import EnvService from "../../services/env-service";

export const cmsApiUrl = EnvService.ApiUrl + "/api/" + 'Cms/';

const cmsVideoBaseUrl = cmsApiUrl + 'Videos/';
export const cmsVideoApis = {
    baseUrl: cmsVideoBaseUrl,
    getAll: cmsVideoBaseUrl,
    search: cmsVideoBaseUrl + `search`,
    getAllMostViewed: cmsVideoBaseUrl + `MostViewedItems`,
    getAllLatest: cmsVideoBaseUrl + `LatestItems`,
    getById: (videoId: number) => cmsVideoBaseUrl + `GeyById/${videoId}`,
    getBySlug: (slug: string) => cmsVideoBaseUrl + `${slug}`,
}

const cmsBlogBaseUrl = cmsApiUrl + 'Posts/';
export const cmsBlogApis = {
    baseUrl: cmsBlogBaseUrl,
    getAll: cmsBlogBaseUrl,
    search: cmsBlogBaseUrl + `search`,
    getAllMostViewed: cmsBlogBaseUrl + `MostViewedItems`,
    getAllLatest: cmsBlogBaseUrl + `LatestItems`,
    getById: (videoId: number) => cmsBlogBaseUrl + `GetById/${videoId}`,
    getBySlug: (slug: string) => cmsBlogBaseUrl + `${slug}`,
    getARandom: cmsBlogBaseUrl + 'Random',
}

const cmsTagsBaseUrl = cmsApiUrl + 'Tags/';
export const cmsTagApis = {
    baseUrl: cmsTagsBaseUrl,
    search: (text: string) => cmsTagsBaseUrl + `${text}`,
}

const cmsCategoryBaseUrl = cmsApiUrl + 'Categories/';
export const cmsCategoryApis = {
    baseUrl: cmsCategoryBaseUrl,
    getAllVideoCategories: cmsCategoryBaseUrl + 'Video',
    getAllBlogCategories: cmsCategoryBaseUrl + 'Blog',
}
