import EnvService from "../../services/env-service";

const apiUrl = EnvService.ApiUrl + '/api/';
const v2ApiUrl = EnvService.ApiUrl + '/v2/api/';

/**
 * Part APis
 */
const partBaseUrl = apiUrl + 'Part/';
export const partApis = {
    baseUrl: partBaseUrl,
    basicSearch: partBaseUrl + 'BasicSearch',
    applicationSearch: partBaseUrl + 'ApplicationSearch',
    technicalSearch: partBaseUrl + 'TechnicalSearch',
    getPartInfo: (partNumber: string, searchToken: string | null) => partBaseUrl + `${partNumber}${!!searchToken?.length ? `/${encodeURIComponent(searchToken)}` : ''}`,
    getPartSecondPart: (partNumber: string) => partBaseUrl + `GetExtraDetails/${partNumber}`,
    getPartAvailability: (partNumber: string) => partBaseUrl + `GetPartAvailabilityForUser/${partNumber}`,
    validateShareLink: (partNumber: string, token: string) => partBaseUrl + `ValidateSharedLink/${partNumber}/${encodeURIComponent(token)}`,
    getPartInfoUsingLink: (partNumber: string, token: string) => partBaseUrl + `GetFullInfoBySharedLink/${(partNumber)}/${encodeURIComponent(token)}`,
    getFile: (token: string) => partBaseUrl + `PartDocument/${encodeURIComponent(token)}`,
    getCompareList: partBaseUrl + 'GetCompareList',
    toggleBookmark: partBaseUrl + 'SaveAndUnsave',
    toggleInCompareList: partBaseUrl + 'AddOrRemoveFromCompare',
    addComment: partBaseUrl + 'AddComment',
};

/**
 * Auth APis
 */
const authBaseUrl = apiUrl + 'Auth/';
export const authApis = {
    baseUrl: authBaseUrl,
    login: authBaseUrl + 'Login',
    register: authBaseUrl + 'Register',
    removeAccount: authBaseUrl + 'deleteAccount',
};

/**
 * User Address APis
 */
const userAddressBaseUrl = apiUrl + 'UserAddresses/';
export const userAddressApis = {
    baseUrl: userAddressBaseUrl,
    getAll: userAddressBaseUrl + 'GetAddresses',
    create: userAddressBaseUrl + 'CreateNewAddress',
    update: userAddressBaseUrl + 'UpdateAddress',
    setAsDefault: (addressId: number) => userAddressBaseUrl + `SetAddressAsDefault/${encodeURIComponent(addressId)}`,
    remove: (addressId: number) => userAddressBaseUrl + `RemoveAddress/${encodeURIComponent(addressId)}`,
};

/**
 * Quote Apis.
 */
const quoteBaseUrl = apiUrl + 'Quotes/';
export const quoteApis = {
    baseUrl: quoteBaseUrl,
    search: quoteBaseUrl + 'Search',
    getFullDetails: (quoteId: number) => quoteBaseUrl + `GetFullDetails/${encodeURIComponent(quoteId)}`,
    sendOrderAsEmail: quoteBaseUrl + `SendOrderAsEmail`,
};

/**
 * Invoices Apis.
 */
const invoicesBaseUrl = apiUrl + 'Invoice/';
export const invoicesApis = {
    baseUrl: invoicesBaseUrl,
    search: invoicesBaseUrl + 'Search',
    getFullDetails: (invoiceId: number) => invoicesBaseUrl + `GetFullDetails/${encodeURIComponent(invoiceId)}`,
    getInvoicesForPart: (partId: number) => invoicesBaseUrl + `GetInvoicesBasedOnPart/${encodeURIComponent(partId)}`,
    addCreditToMultipleInvoices: invoicesBaseUrl + `AddCreditToMultipleInvoices`,
    addCreditToInvoice: invoicesBaseUrl + 'AddCreditToInvoice',
    payMultiInvoices: invoicesBaseUrl + `PayUserInvoices`,
    payInvoice: invoicesBaseUrl + 'PayUserInvoice',
    removeTransactionItem: invoicesBaseUrl + 'RemoveTransactionItem',
    removeBulkTransactionItem: invoicesBaseUrl + 'RemoveBulkTransactionItems',
    sendMultipleOrdersDetailsAsEmail: invoicesBaseUrl + 'SendMultipleOrdersDetailsAsEmail',
}

/**
 * Credits Apis
 */
const creditsBaseUrl = apiUrl + 'Credit/';
export const creditsApis = {
    baseUrl: creditsBaseUrl,
    search: creditsBaseUrl + 'Search',
    getFullDetails: (creditId: number) => creditsBaseUrl + `GetFullDetails/${encodeURIComponent(creditId)}`,
}

/**
 * User Apis.
 */
const userBaseUrl = apiUrl + 'User/';
export const userApis = {
    baseUrl: userBaseUrl,
    validateEmail: (email: string) => userBaseUrl + `ValidateEmail?email=${encodeURIComponent(email)}`,
    registerNewUser: userBaseUrl + 'RegisterNewUser',
    removeUserFromCustomer: (userId: number) => userBaseUrl + `RemoveCustomerFromUser/${encodeURIComponent(userId)}`,
    forgetPassword: (userEmail: string) => userBaseUrl + `ForgetPassword?userName=${encodeURIComponent(userEmail)}`,
    validateResetPasswordToken: (token: string) => userBaseUrl + `ResetPasswordTokenValidate/${encodeURIComponent(token)}`,
    resetPassword: userBaseUrl + `ResetPassword`,
    updateProfile: userBaseUrl + 'UpdateProfile',
    updateProfileImage: userBaseUrl + "UpdateProfilePic",
    logout: userBaseUrl + 'Logout',
    getBookmarkedParts: userBaseUrl + 'GetSavedPart',
    getProfile: userBaseUrl + 'GetProfile',
    getFinancialInfo: userBaseUrl + 'GetCurrentUserFinancialInfo',
}

/**
 * User Cards Apis.
 */
const userCardsBaseUrl = apiUrl + 'UserCard/';
export const userCardsApis = {
    baseUrl: userCardsBaseUrl,
    addUserCreditCard: userCardsBaseUrl + 'Add',
    getUserCreditCards: userCardsBaseUrl + 'List',
    removeUserCreditCard: (cardId: number) => userCardsBaseUrl + `Remove/${cardId}`,
    searchUserCardHistory: userCardsBaseUrl + 'SearchUserCardHistory',
    setUserCardAsDefault: (cardId: number) => userCardsBaseUrl + `SetDefault/${cardId}`,
    redeemGiftCard: userCardsBaseUrl + `SubmitGiftCard`,
    getListOfGiftCards: (loadAll: boolean = false) => userCardsBaseUrl + `GetUserGiftCards/${encodeURIComponent(loadAll)}`,
}

/**
 * USer Shopping cart apis.
 */
const shoppingCartBaseUrl = apiUrl + 'Cart/';
export const shoppingCartApis = {
    baseUrl: shoppingCartBaseUrl,
    getShoppingCart: shoppingCartBaseUrl + 'GetCurrentActiveCart',
    addToCart: shoppingCartBaseUrl + 'AddToCart',
    openQuote: shoppingCartBaseUrl + 'OpenQuote',
    combineQuote: (quoteId: number) => shoppingCartBaseUrl + `CombineQuoteWithCurrentCart/${encodeURIComponent(quoteId)}`,
    saveForLater: shoppingCartBaseUrl + 'SaveForLater',
    addGiftCard: shoppingCartBaseUrl + 'AddGiftCard',
    addCredit: shoppingCartBaseUrl + 'AddCredit',
    pay: shoppingCartBaseUrl + 'PayTheCurrentQuote',
    removeTransaction: shoppingCartBaseUrl + 'RemoveTransactionItem',
    checkout: shoppingCartBaseUrl + 'ProcessCurrentCartCheckout',
    validateCheckout: shoppingCartBaseUrl + 'ValidateCurrentCartCheckout',
    updateItems: shoppingCartBaseUrl + 'UpdateItems',
    getAvailableCredits: shoppingCartBaseUrl + 'GetAllAvailableCredit',
    updateAddress: shoppingCartBaseUrl + 'UpdateAddress',
    getPartsAvailabilities: shoppingCartBaseUrl + 'GetTotalAvailablityOfCurrentCartProducts',
    removeGiftCard: shoppingCartBaseUrl + 'RemoveGiftCard',
}

/**
 * User Access Level Apis
 */
const userAccessLevelBaseUrl = apiUrl + 'UserAccessLevels/';
export const userAccessLevelApis = {
    baseUrl: userAccessLevelBaseUrl,
    updateUserAccessLevelsInCustomer: userAccessLevelBaseUrl + `UpdateUserAccessLevelsInCustomer`,
    getAllAccessLevels: userAccessLevelBaseUrl + `GetAllAccessLevelsForUsers`,
    getUsersAccessLevelsInCustomer: userAccessLevelBaseUrl + `GetAllUsersAccessLevelOfCurrentCustomer`,
}

/**
 * Addresses Apis
 */
const addressesBaseUrl = apiUrl + 'Addresses/';
export const addressesApis = {
    baseUrl: addressesBaseUrl,
    getAllCountries: addressesBaseUrl + `GetAllCountries`,
    getAllProvincesForCountry: (countryId: number) => addressesBaseUrl + `GetAllProvinces/${encodeURIComponent(countryId)}`,
}

/**
 * Bizlive Apis
 */
const bizliveBaseUrl = apiUrl + 'Live/';
export const bizliveApis = {
    baseUrl: bizliveBaseUrl,
    deactivate: bizliveBaseUrl + `DeactivateLink`,
    activate: bizliveBaseUrl + `SubmitFirebaseDevice`,
}

/**
 * Category APis
 */
const partCategoryBaseUrl = apiUrl + 'PartCategory/';
export const partCategoryApis = {
    baseUrl: partCategoryBaseUrl,
    getAll: partCategoryBaseUrl + 'List',
    getByParentId: partCategoryBaseUrl + `GetByParentID`,
};

/**
 * Lookup APis
 */
const lookupBaseUrl = apiUrl + 'Lookup/';
export const lookupApis = {
    baseUrl: lookupBaseUrl,
    applicationSearchForm: lookupBaseUrl + 'GetApplicationSearchRawData',
    technicalSearchForm: lookupBaseUrl + `GetTechnicalSearchRawData`,
};

/**
 * Main Page Endpoints.
 */
const mainPageBaseUrl = apiUrl + 'MainPage';
export const mainPageApis = {
    baseUrl: mainPageBaseUrl,
    getComponents: mainPageBaseUrl,
};

const paymentConfiguration = apiUrl + 'config';
export const paymentConfigurationApis = {
    baseUrl: paymentConfiguration,
    getPaymentPreAuthPercentage: paymentConfiguration,
}

/**
 * Part compare Endpoints.
 */
const partCompareBaseUrl = apiUrl + 'PartCompare';
export const partCompareApis = {
    baseUrl: partCompareBaseUrl,
    get: partCompareBaseUrl,
    addPart: partCompareBaseUrl,
    removePart: (partId: number) => partCompareBaseUrl + `?partId=${encodeURIComponent(partId)}`,
    removeCategory: (categoryId: number) => partCompareBaseUrl + `/deleteCategory?CategoryId=${encodeURIComponent(categoryId)}`,
}

/**
 * New lookup Endpoints
 */
const newLookupBaseUrl = apiUrl + 'NewLookup/';
export const newLookupApis = {
    baseUrl: newLookupBaseUrl,
    elasticTechnicalSearch: newLookupBaseUrl + 'TechnicalSearch',
    getElasticKeywordSuggestions: (keywords: string) => newLookupBaseUrl + `Suggestion?keyword=${encodeURIComponent(keywords)}`,
    reportElasticSearchResultClick: newLookupBaseUrl + 'Click',
    getSearchCompleteInformation: newLookupBaseUrl + 'GetCompleteInformation',
    partAutocomplete: (keywords: string) => newLookupBaseUrl + `AutoComplete?keyword=${encodeURIComponent(keywords)}`,
    getPublicPartsByTag: (tag: string) => newLookupBaseUrl + `GetPublicTagView?tag=${encodeURIComponent(tag)}`,
    basicAutoComplete: (keywords: string) => newLookupBaseUrl + `BasicAutoComplete?keyword=${encodeURIComponent(keywords)}`,
    newBasicSearch: newLookupBaseUrl + `BasicSearch`,
    submitLostSearch: newLookupBaseUrl + 'submitLostSearch',
}

/**
 * Survey end points
 */
const surveyBaseUrl = apiUrl + 'Survey/';
export const surveyApis = {
    baseUrl: surveyBaseUrl,
    getListOfSurveys: surveyBaseUrl + 'List'
}

/**
 * Public products end points
 */
const productsBaseUrl = apiUrl + "Product/";
export const productsApis = {
    baseUrl: productsBaseUrl,
    getAll: productsBaseUrl + 'All',
    getPublicInformation: (partNo: string) => productsBaseUrl + `PublicView?partNumber=${partNo}`,
}

/**
 * Public products end points
 */
const partnerBaseUrl = apiUrl + "Partner";
export const partnerApis = {
    baseUrl: partnerBaseUrl,
    getPartnerInformation: partnerBaseUrl,
}

/**
 * Customer Card end points
 */
const customerCardsBaseUrl = apiUrl + 'CustomerCard';
export const customerCardsApis = {
    baseUrl: customerCardsBaseUrl,
    getCustomerCards: customerCardsBaseUrl,
    addCustomerCard: customerCardsBaseUrl,
    deleteCustomerCards: (cardId: number) => customerCardsBaseUrl + `?cardId=${cardId}`,
    setCustomerCardAsDefault: (cardId: number) => customerCardsBaseUrl + `/SetDefault?cardId=${cardId}`,
    getCustomerCardTransactionHistory: customerCardsBaseUrl + '/GetTransactionHistory',
    getCustomerCardTransactionTypes: customerCardsBaseUrl + '/GetTransactionTypes'
}


const newPartSupportRequestApis = v2ApiUrl + 'NewPartSupportReq/';
export const newPartSupportRequestApi = {
    baseUrl: newPartSupportRequestApis,
    getInfo: (token: string) => newPartSupportRequestApis + `GetBySharedLink?shareLinkToken=${encodeURIComponent(token)}`,
    updateExtraInfo: newPartSupportRequestApis + 'UpdateExtraInfoByLink',
}

