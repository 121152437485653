import {createSlice} from "@reduxjs/toolkit";

/**
 * Reducer for handling shopping card of the redux.
 * it is used to determine the current shopping cart count and update the ui based on its information.
 */
const shoppingCartReduxSlice = createSlice({
    name: 'shoppingCart',
    initialState: {
        count: 0
    },
    reducers: {
        /**
         * Updates the shopping cart count.
         * @param state the previous state of the reducer.
         * @param action the action
         */
        updateCount: (state, action) => {
            state.count += action.payload
            state.count = Math.max(state.count, 0);
        },
        /**
         * Sets the shopping cart count.
         * @param state the previous state of the reducer.
         * @param action the action
         */
        setCount: (state, action) => {
            state.count = action.payload
            state.count = Math.max(state.count, 0);
        }
    }
})

export default shoppingCartReduxSlice;
