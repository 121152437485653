import React, {useEffect, useMemo, useRef, useState} from "react";
import DynamicContentEditorUtils from "../../../core/services/utils";
import {DynamicContentEditorEntryWrapperContext} from "../../contexts";
import {ReactComponent as EditIcon} from "../../../assets/images/edit.svg";
import {ReactComponent as RemoveIcon} from "../../../assets/images/remove.svg";
import {ReactComponent as AddIcon} from "../../../assets/images/add.svg";
import useIsMounted from "../../hooks/use-is-mounted";
import DynamicContentEditorUpsertEntryDialog from "../../components/dialogs/upsert-entry-dialog";
import DynamicContentEditorConfirmationDialog from "../../components/dialogs/confirmation-dialog";
import classnames from "classnames";
import {DynamicContentEditorEntryRecordTypes} from "../../../index.d";

const initUpsertEntryDialog = {open: false, records: []};
const initRemoveConfirmationDialog = {open: false};

/**
 * @type {FC<DynamicContentEditorListEntryItemProps>}
 */
const DynamicContentEditorListEntryItem = ({
                                               children,
                                               tag,
                                               inEditMode,
                                               template = false,
                                               data: _data,
                                               page,
                                               upsert,
                                               remove,
                                               isRelativePositioned = false,
                                               id,
                                               parentTitle,
                                               iconPosition,
                                               ...props
                                           }) => {
    const idRef = useRef(id ?? DynamicContentEditorUtils.createUUId(true))
    const currentPage = useRef(page);
    const [upsertEntryDialog, setUpsertEntryDialog] = useState(initUpsertEntryDialog);
    const [removeConfirmationDialog, setRemoveConfirmationDialog] = useState(initRemoveConfirmationDialog);
    const [removing, setRemoving] = useState(false);
    const isMounted = useIsMounted();

    const data = useMemo(() => _data?.value ?? [], [_data])

    const upsertDialogTitle = useMemo(() => {
        let title;
        if (!parentTitle)
            title = ''
        else
            title = parentTitle.endsWith('s') ? `${parentTitle}'` : `${parentTitle}'s`

        if (template)
            return `Add a New Item to ${title} Section`;
        return `Update Item in ${title} Section`
    }, [parentTitle, template])

    /**
     * With each change in the [inEditMode] flag and [page] value:
     * - if not in edit mode and the upsert entry dialog is open, closes the dialog.
     * - if page is different from the current page, then closes the dialog.
     */
    useEffect(() => {
        if (!inEditMode && upsertEntryDialog.open) {
            setUpsertEntryDialog(initUpsertEntryDialog);
        }
        if (currentPage.current !== page && upsertEntryDialog.open) {
            setUpsertEntryDialog(initUpsertEntryDialog);
        }
    }, [inEditMode, page])


    /**
     * Opens the upsert Entry dialog of the DCE.
     *
     * * first fetches all the records o this entry then opens the dialog with these records so the dialog content
     * can be created based on these records.
     */
    const openUpsertEntryDialog = () => {
        const recordElements = document.getElementById(idRef.current)?.querySelectorAll('[data-dce-record-key]');
        const records = [];
        for (const recordElement of recordElements) {
            if (recordElement.dataset.dceRecordType === DynamicContentEditorEntryRecordTypes.unEditable)
                continue;
            records.push({
                key: recordElement.dataset.dceRecordKey,
                value: recordElement.dataset.dceRecordValue,
                title: recordElement.dataset.dceRecordTitle,
                type: recordElement.dataset.dceRecordValueType,
            })
        }
        setUpsertEntryDialog({open: true, records: records})
    }

    /**
     * Removes the item from the entry list by invoking the [remove] callback.
     * @return {Promise<boolean | undefined>}
     */
    const removeItem = async () => {
        setRemoving(true);
        const resultFlag = await remove();
        if (!isMounted())
            return;
        setRemoving(false);
        return resultFlag;
    }

    const contentChildren = useMemo(() => {
        let content = (
            <>
                {
                    template && inEditMode &&
                    <button
                        className={classnames('dynamic-content-editor-icon-button add', iconPosition)}
                        onClick={openUpsertEntryDialog}
                    >
                        <AddIcon/>
                    </button>
                }
                {
                    !template && inEditMode &&
                    <button
                        disabled={removing}
                        className={classnames('dynamic-content-editor-icon-button remove', iconPosition)}
                        onClick={() => setRemoveConfirmationDialog({open: true})}
                    >
                        <RemoveIcon/>
                    </button>
                }
                {
                    !template && inEditMode &&
                    <button
                        disabled={removing}
                        className={classnames('dynamic-content-editor-icon-button edit', iconPosition)}
                        onClick={openUpsertEntryDialog}
                    >
                        <EditIcon/>
                    </button>
                }
                {children}
            </>
        )
        if (!isRelativePositioned) {
            content = (
                <>
                    <div className={classnames('dynamic-content-editor-list-entry-item-container',)}>
                        {content}
                    </div>
                </>
            );
        }
        return content;
    }, [isRelativePositioned, template, inEditMode, children, removing, iconPosition])

    /**@type {JSX.Element}*/
    const content = useMemo(() => {
        const Tag = tag;
        return (
            <Tag
                {...(props ?? {})}
                id={idRef.current}
            >
                {contentChildren}
            </Tag>
        );
    }, [idRef, props, tag, contentChildren])

    if (!inEditMode && template) {
        // Technically, this shall never happen since the listEntry component will never render the template when we
        // are in view mode. This is just precautionary.
        return null;
    }

    return (
        <>
            <DynamicContentEditorEntryWrapperContext.Provider value={data}>
                {content}
            </DynamicContentEditorEntryWrapperContext.Provider>
            <DynamicContentEditorUpsertEntryDialog
                open={upsertEntryDialog.open}
                records={upsertEntryDialog.records}
                close={() => setUpsertEntryDialog(initUpsertEntryDialog)}
                onSuccess={upsert}
                title={upsertDialogTitle}
            />
            <DynamicContentEditorConfirmationDialog
                open={removeConfirmationDialog.open}
                close={() => setRemoveConfirmationDialog(initRemoveConfirmationDialog)}
                onConfirmed={removeItem}
                title={"Remove Confirmation"}
                description={"Do you want to remove this item? This action is not reversible."}
            />
        </>
    );
}

export default DynamicContentEditorListEntryItem;
