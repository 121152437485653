import React, {useEffect, useMemo, useRef} from "react";
import Form from "../../../base/form";
import {Col, Row} from "reactstrap";
import EmailInput from "../../../base/input/email-input";
import MuiInput from "../../../base/input/mui-input";
import MuiCheckbox from "../../../base/check-box";
import * as Yup from "yup";
import ValidateMessages from "../../../../../core/constants/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {LandingPageFormTypes} from "../../../../../core/constants/enums";
import CookiesService from "../../../../../core/services/cache/cookies-service";
import EnvService from "../../../../../core/services/env-service";

const loginFormKeys = {
    username: "email",
    password: "password",
    remember: "remember",
}

const loginSchema = Yup.object().shape({
    [loginFormKeys.username]: Yup.string().nullable().required(ValidateMessages.required),
    [loginFormKeys.password]: Yup.string().nullable().required(ValidateMessages.required),
    [loginFormKeys.remember]: Yup.boolean().nullable().default(false),
});

const validate = makeValidate(loginSchema);
const required = makeRequired(loginSchema);

const LoginForm = ({onSubmit, changeLoginRegister, loading}) => {
    const containerRef = useRef();
    /**
     * Gets the user information from cookies service
     * @type {unknown}
     */
    const preloadedUserInfo = useMemo(() => (
        CookiesService.get(CookiesService.keys.login.name)
        ?? {
            account: '',
            password: '',
            remember: false
        }
    ), []);

    /**
     * Sets the initial form values of the registration form
     * @type {{"[loginFormKeys.remember]": *, "[loginFormKeys.password]": *, "[loginFormKeys.username]": *}}
     */
    const initialFormValues = useMemo(() => ({
        [loginFormKeys.username]: preloadedUserInfo.account,
        [loginFormKeys.password]: preloadedUserInfo.password,
        [loginFormKeys.remember]: preloadedUserInfo.remember,
    }), [preloadedUserInfo]);

    /**
     * As soon as the component mounts:
     * - changes the color of the autocomplete inputs.
     */
    useEffect(() => {
        setTimeout(changeAutofillColors, 100);
    }, [])

    /**
     * Changes the colors of autofilled inputs based on the parent component of mui inputs that are autofilled
     * @param color
     * @return {Promise<void>}
     */
    const changeAutofillColors = async (color = 'bg-autofill-input-color') => {
        if (!EnvService.EnableAutofillDistinction)
            return;
        if (!containerRef.current)
            return;
        await new Promise(r => setTimeout(r, 1));
        const inputs = document.querySelectorAll("input");
        const autofilled = Array.from(containerRef.current.querySelectorAll('input:-webkit-autofill'));
        for (const input of inputs) {
            const parent = input.parentElement;
            input.classList.remove(color);
            const isAutofilled = !!autofilled.find(e => e === input);
            if (isAutofilled) {
                input.classList.add(color);
            }
            if (parent.classList.contains('MuiInputBase-root')) {
                parent.classList.remove(color);
                if (isAutofilled) {
                    parent.classList.add(color);
                }
            }
        }
    }

    return (
        <div ref={containerRef}>
            <Form
                className='register-form'
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialFormValues}
                render={({values, form}) => {

                    return (
                        <>
                            <Row>
                                <Col xs={12} className={'mb-3'}>
                                    <EmailInput
                                        setValidating={() => false}
                                        form
                                        type={'email'}
                                        label={"Email"}
                                        shouldValidateWithApiCall={false}
                                        name={loginFormKeys.username}
                                        placeholder={'Email or username '}
                                        required={required.email}
                                        onKeyUp={(e) => changeAutofillColors()}
                                        onFocus={() => changeAutofillColors()}
                                        onBlur={() => changeAutofillColors()}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <MuiInput
                                        form
                                        label={"Password"}
                                        type={'password'}
                                        placeholder={'Password'}
                                        name={loginFormKeys.password}
                                        required={required.password}
                                        onKeyUp={(e) => changeAutofillColors()}
                                        onFocus={() => changeAutofillColors()}
                                        onBlur={() => changeAutofillColors()}
                                    />
                                </Col>
                                <Col xs={12} className={'form-check-label'}>
                                    <MuiCheckbox
                                        form
                                        name={loginFormKeys.remember}
                                        required={required[loginFormKeys.remember]}
                                        onChange={(_, c) =>
                                            form.change(loginFormKeys.remember, c)}
                                        data={{
                                            value: values[loginFormKeys.remember] ?? false,
                                            label: (
                                                <p className={'label'}>
                                                    Remember Me
                                                </p>
                                            )
                                        }}
                                    />
                                </Col>
                                <Col xs={12}>
                                    <div className="d-flex justify-content-center ">
                                        <button
                                            className={'button primary px-5 mt-3 primary-action-button'}
                                            disabled={loading}
                                            type={'submit'}>
                                            {!loading ? "Login" : "Loading..."}
                                        </button>
                                    </div>
                                </Col>
                                {
                                    changeLoginRegister &&
                                    <>
                                        <Col xs={12}>
                                            <div className="d-flex justify-content-center mb-3">
                                                <button className={'button text primary px-5 m-auto primary-action-button'}
                                                        onClick={() => changeLoginRegister(LandingPageFormTypes.forgetPassword)}
                                                >
                                                    Reset Password
                                                </button>
                                            </div>
                                        </Col>
                                        <Col xs={12}>
                                            <div
                                                className={'d-flex justify-content-center flex-column align-items-center action-spacer'}>
                                                <p className={'alternate-action'}>
                                                    Don't have an account?
                                                    <span
                                                        onClick={() => changeLoginRegister(LandingPageFormTypes.registration)}>
                                                Sign Up
                                            </span>
                                                </p>
                                            </div>
                                        </Col>
                                    </>
                                }
                            </Row>
                        </>
                    );
                }}
            />
        </div>
    );
}

export default LoginForm;
