import React, {useEffect, useRef} from "react";
import {setReduxHeaderHeight} from "../../../../redux/entities/header/actions";
import {useDispatch} from "react-redux";
import {floatingContainerHeaderHeight, floatingContainerMarginTop} from "../../../../assets/js/sizes";
import {stripUnits} from "../../../../core/services/utils/utils";
import ObserversService from "../../../../core/services/observers-service";

const FloatingContainer = ({children, setHeight = false, marginTop = floatingContainerMarginTop}) => {
    const dispatch = useDispatch();
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const containerRef = useRef();

    /**
     * As soon as the component mounts:
     * - sets the header height to 360px
     * - attaches the observer for width change syncs between containers.
     */
    useEffect(() => {
        if (!containerRef)
            return;
        if (setHeight) dispatch(setReduxHeaderHeight(floatingContainerHeaderHeight));
        const disconnect = setObserver();
        return () => {
            if (disconnect) disconnect();
        }
    }, [containerRef])

    /**
     * Sets the observer on the floating-card element so that the shadowy-background element can change its width
     * according to it.
     * @return {() => void}
     */
    const setObserver = () => {
        const observer = ObserversService.newResizeObserver(watchChildEntryResizes);
        for (const element of containerRef.current.getElementsByClassName("floating-card")) {
            ObserversService.observeResizeObserver(observer, element);
        }
        return () => ObserversService.disconnectResizeObserver(observer);
    }

    /**
     * Watches the changes in the resizes of the children of this floating container and provides the proper styling
     * for them
     * @param {ResizeObserverEntry[]} entries
     */
    const watchChildEntryResizes = (entries) => {
        const entry = entries.length ? entries[0] : null;
        if (!entry) return;
        const elements = document.getElementsByClassName('shadowy-background');
        if (!elements?.length) return;
        const element = elements.item(0);
        const styles = window.getComputedStyle(entry.target)
        const top = stripUnits(styles.getPropertyValue('padding-top'));
        const bottom = stripUnits(styles.getPropertyValue('padding-bottom'));
        element.style.width = `${entry.target.clientWidth ?? 0}px`;
        element.style.height = `${Math.max((entry.target.clientHeight ?? 0) - (top + bottom), 0)}px`;

    }

    return (
        <>
            <div ref={containerRef} className={'floating-container'}>
                <div className={'shadowy-background'}/>
                <div className={`floating-card`} style={{'--mt': `${marginTop}px`}}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default FloatingContainer;
