import React, {useMemo} from "react";
import classnames from "classnames";
import moment from "moment";
import {TransactionTypes} from "../../../../../core/constants/enums";
import Utils, {createUUId, dateComparator, formatMoney} from "../../../../../core/services/utils/utils";
import ApplicationIds from "../../../../../core/constants/application-ids";
import {normalize} from "phone-formatter";

const rules = [
    'Customer is responsible to inspect all parts upon receiving and any loss or damage must be noted by' +
    ' the customer on the invoice/Waybill copy. Otherwise customer\'s signature will constitute a clear receipt.',
    'Any Warranties on Products sold hereby are those made by the manufacturer, unless you buy extended warranty.' +
    ' All Parts comes with ONE year manufacture warranty and NO LABOR. Purchaser responsible to pay for labor. It will take between 2 to 3 weeks to process warranty claims. - Notification of any pricing or quantity discrepancies must be made within 15 days of receipt of product or the invoice will be considered uncontested and payable in full within our stated terms. - No Merchandise may be returned without the authorization of Cool Heat Truck Parts, RGA# required of all returns and warranties.(Refer to RGA Form.)',
    'No returns on electrical products, 15% restocking fee and/or $35 re-boxing fee may apply.',
    'All Credits must be used against future purchases within one year of issue or they will be voided',
]

/**
 * Creates a product row given the item and its index.
 * @param item
 * @param index
 * @param length
 * @return {JSX.Element}
 */
const createProductRow = (item, index, length) => {
    const serials = item.serials;
    return (
        <tr key={item.partNo}
            className={classnames('table-body side-borders',
                {' no-bottom-border': serials?.length && index !== length - 1})
            }>
            <td colSpan={12}
                className={classnames('part-no no-right-border',
                    {'no-padding-bottom': serials?.length})}
                style={{paddingBottom: serials?.length ? '0px !important' : '3px !important'}}
            >
                {item?.partNo ?? ''}
            </td>
            <td colSpan={24}
                className={classnames('description no-left-border', {'no-padding-bottom': serials?.length})}
                style={{paddingBottom: serials?.length ? '0px !important' : '3px !important'}}
            >
                {item?.description ?? ''}
            </td>
            <td colSpan={4} className={'order-quantity no-right-border'}>
                {item?.orderQty === '0' ? '' : item?.orderQty ?? ''}
            </td>
            <td colSpan={5} className={'committed-quantity no-right-border' +
                ' no-left-border'}>
                {item?.committedQty === '0' ? '' : item?.committedQty ?? ''}
            </td>
            <td colSpan={3} className={'backorder-quantity no-left-border'}>
                {item?.backorderQty === '0' ? '' : item?.backorderQty ?? ''}
            </td>
            <td colSpan={4} className={'unit-price no-right-border'}>
                {formatMoney(item?.unitPriceBeforeDiscount)?.replace("$", '') ?? ''}
            </td>
            <th colSpan={3} className={'discount-pct no-left-border no-right-border'}>
                {`${item?.discountPct ?? '0'}%`}
            </th>
            <td colSpan={5} className={'extended-price no-left-border'}>
                {formatMoney(parseFloat(item?.unitPrice) * parseFloat(item.committedQty))?.replace("$", '') ?? ''}
            </td>
        </tr>
    );
}

/**
 * Creates a serial row
 * @param first
 * @param second
 * @param props
 * @return {JSX.Element}
 */
const createSerialNoRow = ({data: {first = "", second = ""} = {}, ...props}) => {
    return (
        <tr {...props} className={'p-0 no-top-border no-bottom-border serial-number side-borders'}>
            <td colSpan={12}
                className={'serial-no no-right-border'}>
                {first ? `S/N: ${first}` : ''}
            </td>
            <td colSpan={24}
                className={'serial-no no-left-border'}>
                {second ? `S/N: ${second}` : ''}
            </td>
            <td colSpan={4} className={'no-right-border'}/>
            <td colSpan={5} className={'no-right-border no-left-border'}/>
            <td colSpan={3} className={'no-left-border'}/>
            <td colSpan={4} className={'no-right-border'}/>
            <td colSpan={3} className={'no-left-border no-right-border'}/>
            <td colSpan={5} className={'no-left-border'}/>
        </tr>
    );
}

/**
 * Creates a Table Rooter Row.
 * @param {boolean} fullWidth whether full width
 * @param className
 * @param leftData
 * @param leftProps
 * @param centerData
 * @param centerProps
 * @param rightData
 * @param rightProps
 * @param props
 * @return {JSX.Element}
 */
const createTableFooterRow = ({
                                  fullWidth = false,
                                  className = '',
                                  data: {
                                      left: {leftData = '', leftProps = {}} = {},
                                      center: {centerData = '', centerProps = {}} = {},
                                      right: {rightData = '', rightProps = {}} = {},
                                  } = {},
                                  ...props
                              }) => {
    if (fullWidth) {
        return (
            <tr  {...props} className={classnames('side-borders', className)}>
                <td colSpan={36}
                    {...leftProps}
                    className={classnames('full-width-footer-left-data', leftProps.className)}>
                    {leftData ?? ''}
                </td>
                <td colSpan={24}
                    {...rightProps}
                    className={classnames('full-width-footer-right-data', rightProps.className)}>
                    {rightData ?? ''}
                </td>
            </tr>
        );
    }
    return (
        <tr {...props} className={classnames('side-borders', className)}>
            <td colSpan={8}
                {...leftProps}
                className={classnames('footer-left-data no-right-border', leftProps.className)}>
                {leftData ?? ''}
            </td>
            <td colSpan={9}
                {...centerProps}
                className={classnames('footer-center-data no-right-border no-left-border', centerProps.className)}>
                {centerData ?? ''}
            </td>
            <td colSpan={7}
                {...rightProps}
                className={classnames('footer-right-data no-right-border no-left-border', rightProps.className)}>
                {rightData ?? ''}
            </td>
        </tr>
    );
}

/**
 * Creates an order note row.
 * @param data
 * @param index
 * @param length
 * @param props
 * @return {JSX.Element}
 */
const createNoteRow = (data = '', index, length, props = {}) => {
    const {innerProps, ...restProps} = props ?? {};
    return (
        <tr
            {...restProps}
            key={`note-${index}`}
            className={classnames(
                'table-body side-borders',
                {' no-bottom-border': index !== length - 1}
            )}
        >
            <td colSpan={60}
                {...innerProps ?? {}}
                className={classnames('note-table-data', innerProps?.className)}>
                {data ?? ''}
            </td>
        </tr>
    );
}

const PrintInvoiceTemplate = ({invoice, partner, showTransactions, credit = false}) => {
    const notes = useMemo(() => invoice?.printableNotes?.sort((a, b) => dateComparator(a.createdDate, b.createdDate)) ?? [], [invoice]);

    const transactions = useMemo(() => {
        const transactions = invoice?.appliedTransactions?.filter(e => Number(e.reference) !== invoice?.id) ?? [];
        const taxesLength = invoice?.taxes?.length;
        // 22 for max - 5 for sub/dis/shipping/tot/bal - taxLengths
        return transactions.slice(0, Math.max(22 - 5 - taxesLength, 0))
    }, [invoice?.appliedTransactions, invoice?.id, invoice?.taxes?.length]);

    // 5 for sub/dis/shipping/tot/bal + 1 extra = 6
    const rowSpanOfRules = useMemo(() => 6 + (invoice?.taxes?.length ?? 0) + (showTransactions ? transactions.length ?? 0 : 0) + (!showTransactions ? -1 : 0), [invoice, transactions, showTransactions]);
    const items = useMemo(() => [...(invoice?.items ?? [])].map((item) => {
        const pairs = [];
        item?.serials?.forEach(e => {
            if (!pairs.length) {
                return pairs.push([e]);
            }
            if (pairs[pairs.length - 1]?.length === 1) {
                return pairs[pairs.length - 1].push(e);
            }
            pairs.push([e]);
        });
        const serials = pairs?.map(([first, second]) => createSerialNoRow({
            data: {first: first?.serialNumber ?? "", second: second?.serialNumber ?? ""},
            key: createUUId(true),
        })) ?? [];
        return {...item, serials};
    }), [invoice]);

    const {billingAddress, shippingAddress} = useMemo(() => ({
        billingAddress: invoice?.billingAddress,
        shippingAddress: invoice?.shippingAddress
    }), [invoice?.shippingAddress, invoice?.billingAddress]);

    const subTotal = useMemo(() => invoice?.subTotal ?? 0, [invoice?.subTotal]);

    const discount = useMemo(() => {
        const discount = (invoice?.totalItemsDiscountAmount ?? 0);
        if (discount > 0) {
            return discount;
        }
        return 0;
    }, [invoice?.totalItemsDiscountAmount]);

    const shippingFee = useMemo(() => (invoice?.freightFee ?? 0), [invoice?.freightFee]);
    const total = useMemo(() => invoice?.total ?? 0, [invoice?.total]);
    const balance = useMemo(() => invoice?.balance ?? 0, [invoice?.balance]);

    /**
     * Creates the parts with their serial numbers for the invoices table
     */
    const productTableBody = useMemo(() => items.map((item, index) => (
        [createProductRow(item, index, items.length), ...item.serials])
    ), [items])

    /**
     * Creates the taxes with their applied rates and exempt numbers
     */
    const productTableTaxes = useMemo(() => invoice?.taxes?.map((tax, index) => (
        createTableFooterRow({
            className: 'no-bottom-border no-top-border',
            key: `Tax-${index}`,
            data: {
                left: {leftData: tax.exemptNo ? `${tax.name} # ${tax.exemptNo}` : ''},
                center: {centerData: tax.name ?? ''},
                right: {rightData: formatMoney(tax.total).replace("$", '')},
            },
        })
    )), [invoice?.taxes])

    /**
     * Creates the credits with their refund values for the table
     */
    const productTableCredits = useMemo(() => transactions?.map((transaction, index) => (
        createTableFooterRow({
            key: `CREDIT-${index}`,
            className: 'no-bottom-border no-top-border',
            data: {
                left: {
                    leftData: transaction?.transactionTypeName === TransactionTypes.payment
                        ? `Auth code: ${transaction?.authorizationCode ?? ''}`
                        : ''
                },
                center: {centerData: `${transaction?.transactionTypeDescription ?? '--'}:`},
                right: {rightData: `-${formatMoney(transaction?.amount).replace("$", '')}`}
            },
        })
    )), [transactions])

    /**
     * Creates the notes' table body.
     */
    const noteTableBody = useMemo(() => notes?.map((note, index) => (
        createNoteRow(note.body, index, notes.length)
    )), [notes])


    return (
        <>
            <div id={ApplicationIds.printInvoiceViewIds.page} className={'print-invoice-template'}>
                <table id={ApplicationIds.printInvoiceViewIds.table} width={'100%'} className={'table'}>
                    <colgroup>
                        {
                            Array(60).fill(1).map((e, index) => (
                                <col key={index} width="1.6666666666667%"/>
                            ))
                        }
                    </colgroup>
                    <tbody id={ApplicationIds.printInvoiceViewIds.pageHeader}>
                    <tr className={''}>
                        <td colSpan={20} className={'no-border pr-4'}>
                            <img src={partner.logo} height={'75px'} className={'w-100'} alt="logo"/>
                        </td>
                        <td colSpan={20} className={'no-border'}>
                            {/*CHTP ADDRESS AND PHONE */}
                            <p className={'invoice-information-title line-height-2'}>
                                {partner.fullAddress}
                            </p>
                            <p className={'invoice-information-title mr-1 line-height-2'}>
                                {partner.city} {partner.province} {partner.postalCode}
                            </p>
                            <p className={'invoice-information-title mr-1 line-height-2'}>
                                Ph. <span>{partner.phone}</span>
                            </p>
                            <p className={'invoice-information-title mr-1 line-height-2'}>
                                Fx. <span>{partner.fax}</span>
                            </p>
                            <p className={'invoice-information-title mr-1 line-height-2'}>
                                W: <span> {partner.website ?? ''}</span>
                            </p>
                        </td>
                        <td colSpan={20} className={'no-border'}>
                            {/* Invoice Details */}
                            <p className={'invoice-text mb-2'}>
                                {credit ? "Credit" : "Invoice"}
                            </p>
                            <p className={'invoice-no mb-1'}>
                                {invoice?.invoiceNo ?? ''}
                            </p>
                            <p>
                                <span className={'invoice-information-title'} style={{paddingRight: 100}}>
                                    Date
                                </span>
                                <span
                                    className={'invoice-information-data'}>
                                    {moment(invoice?.invoiceDate).format('MMMM DD, YYYY')}
                                </span>
                            </p>
                            <p>
                                <span className={'invoice-information-title'} style={{paddingRight: 63}}>
                                    Customer
                                </span>
                                <span
                                    className={'invoice-information-data'}>
                                    {invoice?.customer?.customerNo ?? ''}
                                </span>
                            </p>
                        </td>
                    </tr>
                    <tr className={''}>
                        <td colSpan={45} className={'no-border'} height={'50px'}/>
                        <td colSpan={15} className={'no-border'} height={'50px'}>
                            <p className={'page-identifier'}>
                                Page 0 of 0
                            </p>
                        </td>
                    </tr>
                    <tr className={''}>
                        <td colSpan={30} className={'no-border pt-4'}>
                            {/*BILL-TO ADDRESS*/}
                            <p className={'address-entity address-title mr-2'}>
                                <span style={{paddingRight: 30}}>Bill To:</span>
                                <span>{invoice?.customer?.name ?? ''}</span>
                            </p>
                            <p className={'address-entity'}>
                                <span style={{paddingLeft: 82}}>{billingAddress?.fullAddress ?? ''}</span>
                            </p>
                            <p className={'address-entity pb-4'}>
                                            <span style={{paddingLeft: 82}}>
                                                {billingAddress?.city ?? ''}
                                            </span>
                                <span className={'ml-2'}>
                                                {billingAddress?.province?.code ?? ''}
                                            </span>
                                <span className={'ml-2'}>
                                                 {billingAddress?.postalCode ?? ''}
                                            </span>
                            </p>
                            <p className={'address-entity'}>
                                            <span style={{paddingLeft: 82}}>
                                                {/*Phone number added to the DS of address, changed on March 28th, 2022*/}
                                                {
                                                    billingAddress?.phone &&
                                                    `Ph. ${Utils.formatPhoneNumber(normalize(billingAddress?.phone))}`
                                                }
                                                {/*Ph. {billingAddress?.phone ?? 'N/A'}*/}
                                            </span>
                            </p>
                        </td>
                        <td colSpan={30} className={'no-border  pt-4'}>
                            <p className={'address-entity address-title mr-2'}>
                                <span style={{paddingRight: 30}}>Ship To:</span>
                                <span>{invoice?.customer?.name ?? ''}</span>
                            </p>
                            <p className={'address-entity'}>
                                <span style={{paddingLeft: 92}}>{shippingAddress?.fullAddress ?? ''}</span>
                            </p>
                            <p className={'address-entity pb-4'}>
                                            <span style={{paddingLeft: 92}}>
                                                {shippingAddress?.city ?? ''}
                                            </span>
                                <span className={'ml-2'}>
                                                {shippingAddress?.province?.code ?? ''}
                                            </span>
                                <span className={'ml-2'}>
                                                 {shippingAddress?.postalCode ?? ''}
                                            </span>
                            </p>
                            <p className={'address-entity'}>
                                            <span style={{paddingLeft: 92}}>
                                                {/*Phone number added to the DS of address, changed on March 28th, 2022*/}
                                                {
                                                    shippingAddress?.phone &&
                                                    `Ph. ${Utils.formatPhoneNumber(normalize(shippingAddress?.phone))}`
                                                }
                                                {/*Ph. {shippingAddress?.phone ?? 'N/A'}*/}
                                            </span>
                            </p>
                        </td>
                    </tr>
                    <tr className={''}>
                        <td className={'no-border'} colSpan={60} height={'100px'}/>
                    </tr>
                    </tbody>
                    <tbody id={ApplicationIds.printInvoiceViewIds.productsTable.header}>
                    {/*FIRST ROW OF TABLE*/}
                    <tr className={'side-top-borders'}>
                        <th colSpan={12} className={'text-center products-header-cell-title'}>
                            PO Number
                        </th>
                        <th colSpan={12} className={'text-center products-header-cell-title'}>
                            F.O.B.
                        </th>
                        <th colSpan={18} className={'text-center products-header-cell-title'}>
                            Salesperson
                        </th>
                        <th colSpan={9} className={'text-center products-header-cell-title'}>
                            Order Date
                        </th>
                        <th colSpan={9} className={'text-center products-header-cell-title'}>
                            Order Number
                        </th>
                    </tr>
                    <tr className={'side-borders'}>
                        <td colSpan={12} className={'text-center products-header-cell-body'}>
                            {invoice?.customerPO ?? '--'}
                        </td>
                        <td colSpan={12} className={'text-center products-header-cell-body'}>
                            {invoice?.fob ?? '--'}
                        </td>
                        <td colSpan={18} className={'text-center products-header-cell-body'}>
                            {invoice?.salespersonNo ?? '--'}
                        </td>
                        <td colSpan={9} className={'text-center products-header-cell-body'}>
                            {invoice?.orderDate ? moment(invoice?.orderDate).format('MMMM DD, YYYY') : '--'}
                        </td>
                        <td colSpan={9} className={'text-center products-header-cell-body'}>
                            {invoice?.orderNo ?? '--'}
                        </td>
                    </tr>
                    {/*SECOND ROW OF TABLE*/}
                    <tr className={'side-borders'}>
                        <th colSpan={12} className={'text-center products-header-cell-title'}>
                            Ship Via
                        </th>
                        <th colSpan={12} className={'text-center products-header-cell-title'}>
                            Tracking No
                        </th>
                        <th colSpan={18} className={'text-center products-header-cell-title'}>
                            Payment Terms
                        </th>
                        <th colSpan={18} className={'text-center products-header-cell-title'}>
                            Reference
                        </th>
                    </tr>
                    <tr className={'side-borders'}>
                        <td colSpan={12} className={'text-center products-header-cell-body'}>
                            {invoice?.shippingCarrier ?? '--'}
                        </td>
                        <td colSpan={12} className={'text-center products-header-cell-body'}>
                            {invoice?.trackingNo ?? '--'}
                        </td>
                        <td colSpan={18} className={'text-center products-header-cell-body'}>
                            {invoice?.paymentTermsCode ?? '--'}

                        </td>
                        <td colSpan={18} className={'text-center products-header-cell-body'}>
                            {invoice?.invoiceNo ?? '--'}
                        </td>
                    </tr>
                    <tr className={'side-borders'}>
                        <th colSpan={60}/>
                    </tr>
                    {/*THIRD ROW OF TABLE*/}
                    <tr className={'side-borders'}>
                        <th colSpan={12}
                            className={'text-left products-header-cell-title align-bottom no-right-border'}>
                            Part Number
                        </th>
                        <th colSpan={24}
                            className={'text-left products-header-cell-title align-bottom no-left-border'}>
                            Description
                        </th>
                        <th colSpan={4}
                            className={'text-left products-header-cell-title align-bottom no-right-border'}>
                            Ordered
                        </th>
                        <th colSpan={5}
                            className={'text-center products-header-cell-title align-bottom no-right-border no-left-border'}>
                            Shipped
                        </th>
                        <th colSpan={3}
                            className={'text-right products-header-cell-title align-bottom no-left-border'}>
                            B/O
                        </th>
                        <th colSpan={4}
                            className={'text-left products-header-cell-title align-bottom no-right-border'}>
                            Unit Price
                        </th>
                        <th colSpan={3}
                            className={'text-left products-header-cell-title align-bottom no-left-border no-right-border'}>
                            Discount
                        </th>
                        <th colSpan={5}
                            className={'text-right products-header-cell-title align-bottom no-left-border'}>
                            Extended Price
                        </th>
                    </tr>
                    </tbody>
                    <tbody id={ApplicationIds.printInvoiceViewIds.productsTable.body} className={'table-border'}>
                    {
                        productTableBody
                    }
                    {/*BOTTOM ROW OF TABLE BODY*/}
                    <tr id={ApplicationIds.printInvoiceViewIds.productsTable.bodyFiller}
                        className={classnames('no-bottom-border no-top-border side-borders')}>
                        <td colSpan={36} className={classnames('py-2-5 products-bottom-row')}/>
                        <td colSpan={12} className={classnames('py-2-5 products-bottom-row')}/>
                        <td colSpan={12} className={classnames('py-2-5 products-bottom-row')}/>
                    </tr>
                    </tbody>
                    <tbody id={ApplicationIds.printInvoiceViewIds.productsTable.footerContinue}
                           className={'table-footer-border'}>
                    {createTableFooterRow({
                        className: 'no-bottom-border no-top-border',
                        fullWidth: true,
                        height: '120px',
                        data: {
                            right: {
                                rightData: "Continued...",
                                rightProps: {className: 'py-2-5 products-continued'}
                            },
                        },
                    })}
                    </tbody>
                    <tbody id={ApplicationIds.printInvoiceViewIds.productsTable.footer}
                           className={'table-footer-border'}>
                    {/*RULES*/}
                    {createTableFooterRow({
                        className: 'no-bottom-border no-top-border',
                        fullWidth: true,
                        data: {
                            left: {
                                leftData: rules.map(e => (<p key={e}>{e.trim()}</p>)),
                                leftProps: {rowSpan: rowSpanOfRules, className: 'rules'}
                            },
                        },
                    })}
                    {/*SUB_TOTAL*/}
                    {createTableFooterRow({
                        className: 'no-bottom-border no-top-border',
                        data: {
                            left: {leftProps: {className: 'pt-3'}},
                            center: {
                                centerData: "Sub Total",
                                centerProps: {className: 'pt-3'}
                            },
                            right: {
                                rightData: formatMoney(subTotal).replace("$", ''),
                                rightProps: {className: 'pt-3'}
                            },
                        },
                    })}
                    {/*DISCOUNT*/}
                    {createTableFooterRow({
                        className: 'no-bottom-border no-top-border',
                        data: {
                            center: {centerData: "Discount"},
                            right: {rightData: `-${formatMoney(discount).replace("$", '')}`},
                        },
                    })}
                    {/*SHIPPING FEE*/}
                    {createTableFooterRow({
                        className: 'no-bottom-border no-top-border',
                        data: {
                            center: {centerData: "Shipping Fee"},
                            right: {rightData: `${formatMoney(shippingFee).replace("$", '')}`},
                        },
                    })}
                    {/*TAXES*/}
                    {
                        productTableTaxes
                    }
                    {/*TOTAL*/}
                    {createTableFooterRow({
                        className: 'no-bottom-border no-top-border',
                        data: {
                            center: {
                                centerData: "Total",
                                centerProps: {className: 'total-amount'}
                            },
                            right: {
                                rightData: formatMoney(total).replace("$", ''),
                                rightProps: {className: 'total-amount'}
                            },
                        },
                    })}
                    {/*CREDITS*/}
                    {
                        showTransactions &&
                        productTableCredits
                    }
                    {/*BALANCE*/}
                    {
                        showTransactions &&
                        createTableFooterRow({
                            className: 'no-bottom-border no-top-border',
                            data: {
                                center: {
                                    centerData: "Total Due",
                                    centerProps: {className: 'total-due'}
                                },
                                right: {
                                    rightData: formatMoney(balance).replace("$", ''),
                                    rightProps: {className: 'total-due'}
                                },
                            },
                        })
                    }

                    {/*BOTTOM ROW OF TABLE FOOTER*/}
                    {createTableFooterRow({
                        className: 'no-bottom-border no-top-border',
                        fullWidth: true,
                        data: {
                            left: {leftProps: {className: 'py-1'}},
                            right: {rightProps: {className: 'py-1'}},
                        }
                    })}
                    {/*SIGNATURE*/}
                    {createTableFooterRow({
                        className: 'no-bottom-border no-top-border',
                        fullWidth: true,
                        data: {
                            left: {leftData: "Signature:", leftProps: {className: 'py-1 pl-2 signature'}},
                            right: {rightProps: {className: 'py-1'}},
                        }
                    })}
                    </tbody>
                    <tbody id={ApplicationIds.printInvoiceViewIds.tablesDistance}>
                    <tr>
                        <td className={'no-border'} colSpan={60} height={'100px'}/>
                    </tr>
                    </tbody>

                    {/*NOTES TABLE*/}
                    <tbody id={ApplicationIds.printInvoiceViewIds.notesTable.header}>
                    <tr className={'side-top-borders'}>
                        <th colSpan={60} className={'notes-description'}>
                            Note Description
                        </th>
                    </tr>
                    </tbody>
                    <tbody id={ApplicationIds.printInvoiceViewIds.notesTable.body} className={'table-border'}>
                    {
                        noteTableBody
                    }
                    </tbody>
                    <tbody id={ApplicationIds.printInvoiceViewIds.notesTable.footerContinue}
                           className={'table-footer-border'}>
                    {createNoteRow(
                        'Continued...',
                        createUUId(),
                        0,
                        {
                            className: "no-border",
                            innerProps: {
                                className: 'note-continued pr-3'
                            }
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default PrintInvoiceTemplate;


