// TODO: make this into a service that inserts these values to css variables and then we read these and store as
//  scss variables.

/**
 * The Colors that are used in the application.
 */
const Colors = {
    primaryColor: '#583F99',
    secondaryColor: '#FF9234',
    warning: 'rgba(248, 163, 13, 1)',

    blackColor: 'black',

    red: '#BD3F1C',
    red2: '#DF2838',
    lightRed: 'rgba(235, 87, 87, 0.7)',

    green: '#2C8161',
    green2: "#EEF5F2",

    grey1: 'rgba(242, 242, 242, 1)',

    borderColor: "#f0f5fd",
    inputBorderColor: "rgba(0, 0, 0, 0.42)",

    chartGreen: 'rgba(69, 130, 104, 0.7)',
    chartBlack: '#4F4F4F',
    chartRed: 'rgba(189, 63, 28, 0.8)',
    datePickerToolBar: '#583F99',
}

export default Colors;
