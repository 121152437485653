import React, {useEffect, useRef, useState} from "react";
import useIsMounted from "../../hooks/use-is-mounted";
import accepts from "attr-accept";
import classnames from "classnames";
import DynamicContentEditorUploadButton from "../upload-button";
import {
    DynamicContentEditorInputFileAcceptMimes,
    DynamicContentEditorInputFileAcceptTypes,
    DynamicContentEditorReadingFileAs
} from "../../../index.d";
import DynamicContentEditorUtils from "../../../core/services/utils";


const DynamicContentEditorDropzone = ({
                                          error = false,
                                          disabled,
                                          accept = Object.values(DynamicContentEditorInputFileAcceptTypes),
                                          id,
                                          setFiles,
                                          multiple = false,
                                          children,
                                          setParsedImages,
                                          loading,
                                          setLoading,
                                          className,
                                          openOnClick = false,
                                          uploading,
                                          dropZoneClassName,
                                          labelClassName,
                                          noParse = false,
                                          dragging = false,
                                          endDragging,
                                          notAcceptedDurationInMs = 1000,
                                      }) => {
    const [highlight, setHighlight] = useState(false);
    const [notAccepted, setNotAccepted] = useState(false);
    const inputRef = useRef();
    const isMounted = useIsMounted();

    /**
     * Listens for the changes in notAccepted and with each change:
     * if the component was in the not-accepted format, then clears the format after a second.
     */
    useEffect(() => {
        let timer;
        if (!notAccepted) {
            if (timer) return () => clearTimeout(timer);
            return;
        }
        timer = setTimeout(() => {
            if (!isMounted()) return;
            setNotAccepted(false)
        }, notAcceptedDurationInMs)
        return () => clearTimeout(timer)
    }, [notAccepted])

    /**
     * Opens the file dialog from the system.
     */
    const openFileDialog = () => {
        if (disabled || loading) return;
        inputRef?.current?.click()
    }

    /**
     * Highlights the dropzone if dragged over it.
     * @param e {DragEvent}
     */
    const onDragEnter = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (disabled || loading) return;
        if (!highlight) setHighlight(true);
    }

    /**
     * Un-Highlights the dropzone if leaves the draggable area it.
     * @param e {DragEvent}
     */
    const onDragLeave = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (highlight) setHighlight(false);
    }

    /**
     * Gets the dropped files and then un-highlights the drop-zone and calls onFilesSelected with the returned array
     * from fileListToArray function.
     * @param e {DragEvent}
     */
    const onDrop = (e) => {
        e.preventDefault();
        if (disabled || loading || !e.dataTransfer?.files?.length) return;
        const files = fileListToArray(e.dataTransfer.files);
        setNotAccepted(!files?.length);
        if (endDragging) endDragging();
        onDragLeave(e);
        onFilesSelected(files).then();
    }


    /**
     * For every selected file, if the file has the accepted types, returns the files array.
     * if no multiple, then only returns the first selected file.
     *
     * @param files {FileList}
     * @return {FileList}
     */
    const fileListToArray = (files) => {
        const dataTransfer = new DataTransfer();
        for (let index = 0; index < files.length; index++) {
            if (!accepts(files.item(index), accept)) continue;
            if (multiple) {
                dataTransfer.items.add(files.item(index));
            } else {
                dataTransfer.items.add(files.item(index));
                break;
            }
        }
        return dataTransfer.files;
    }

    /**
     * For each of the files, converts them to their base 64 string and then sends the converted array of
     * potentially filled as the argument of setFiles callback.
     * @param files {FileList}
     */
    const onFilesSelected = async (files) => {
        if (disabled || loading || files?.length <= 0) return;
        if (setLoading) setLoading(true);
        const {parsedImages, parsedFiles} = await readFiles(files);
        if (setLoading) setLoading(false);
        if (setParsedImages && parsedImages?.length) setParsedImages(parsedImages);
        if (!setFiles) return;
        if (noParse) {
            setFiles(files);
        } else {
            setFiles(parsedFiles?.filter(e => e.length));
        }
    }

    /**
     * Reads the given list of files and parses them into parsed data url files or texts if their type is not of
     * type image.
     * @param {FileList} files
     * @return {Promise<{parsedImages: *[], parsedFiles: *[]}|{}>}
     */
    const readFiles = async (files) => {
        const parsedImages = [];
        const parsedFiles = [];
        for (let index = 0; index < files.length; index++) {
            if (!accepts(files.item(index), DynamicContentEditorInputFileAcceptMimes.images)) {
                const text = await DynamicContentEditorUtils.readFile(files.item(index), DynamicContentEditorReadingFileAs.text);
                parsedFiles.push(text);
            } else {
                const dataUrl = await DynamicContentEditorUtils.readFile(files.item(index), DynamicContentEditorReadingFileAs.dataUrl);
                parsedImages.push(dataUrl);
                parsedFiles.push(dataUrl.split(',')[1]);
            }
            if (!multiple) break;
        }
        if (!isMounted()) return {};
        return {parsedImages, parsedFiles};
    }


    return (
        <div
            className={classnames(
                'dynamic-content-editor-drop-zone',
                {
                    'high-light': highlight,
                    'error': error,
                    'dragging': dragging,
                    'uploading': uploading,
                    'disabled': disabled || loading,
                    'not-accepted': notAccepted,
                    [dropZoneClassName]: dropZoneClassName?.length,
                }
            )}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={e => e.preventDefault()}
            onDrop={onDrop}
            onClick={() => openOnClick && openFileDialog()}
        >
            {
                openOnClick
                    ? (
                        <DynamicContentEditorUploadButton
                            labelClassName={labelClassName}
                            disabled={disabled || loading}
                            accept={accept}
                            ref={inputRef}
                            multiple={multiple}
                            id={`upload-button-${id}`}
                            onFileSelect={onFilesSelected}
                            className={className}
                        >
                            {
                                typeof children === 'function'
                                    ? children(openFileDialog, highlight, notAccepted)
                                    : children
                            }
                        </DynamicContentEditorUploadButton>
                    )
                    : typeof children === 'function'
                        ? children(openFileDialog, highlight, notAccepted)
                        : children
            }

        </div>
    )
}


export default DynamicContentEditorDropzone;
