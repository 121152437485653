import React, {useEffect, useMemo, useRef, useState} from "react";
import useRouter from "../../../../../hooks/use-router";
import routes from "../../../../../routes";
import ValidateMessages from "../../../../../../core/constants/validate-messages";
import ProductSearchUtils from "../../../../../../core/services/utils/product-search-utils";
import {apiMethods, BasicSearchAutocompleteTypes, ProductsSearchTypes} from "../../../../../../core/constants/enums";
import {deepCopy, deepEqual} from "../../../../../../core/services/utils/utils";
import * as Yup from "yup";
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../../../base/form";
import {Col, Row} from "reactstrap";
import Select from "../../../../base/select";
import {ReactComponent as CategoryIcon} from "../../../../../../assets/images/input-icons/category.svg";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import api from "../../../../../../core/services/api/api";
import {newLookupApis} from "../../../../../../core/constants/endpoints/endpoints";
import AutoComplete from "../../../../base/auto-complete";
import {ReactComponent as SearchIcon} from "../../../../../../assets/images/search-icon.svg";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import Regexes from "../../../../../../core/constants/regexes";
import classnames from "classnames";

const formKeys = ProductSearchUtils.basicSearchFormKeys;
const queryKeys = ProductSearchUtils.basicSearchQueryKeys;

const schema = Yup.object().shape({
    [formKeys.keyword]: Yup.mixed().nullable(),
    [formKeys.category1]: Yup.number().nullable().typeError(ValidateMessages.incorrectType("category")),
    [formKeys.category2]: Yup.number().nullable().typeError(ValidateMessages.incorrectType("category")),
    [formKeys.category3]: Yup.number().nullable().typeError(ValidateMessages.incorrectType("category")),
    [formKeys.orderBy]: Yup.string().nullable(),
});

const validate = makeValidate(schema);
const required = makeRequired(schema);

const initialFirstCategoryOption = {
    id: -1,
    value: null,
    title: "All categories",
    disabled: true,
}

const initialCategoryOption = {
    id: -1,
    value: null,
    title: "All Sub categories",
    disabled: true,
}

const initialOrderBy = {
    name: '-1',
    title: "order by",
    disabled: true,
}

/**
 * Creates keywords option.
 * @param keywords
 * @returns {{id: undefined, label, type: string, value, key: string}}
 */
const createKeywordOption = (keywords) => ({
    id: undefined,
    key: 'free-solo__',
    value: keywords,
    label: keywords,
    type: '',
})

/**
 * Creates a valid keyword option from the given tag.
 * @param {any} tag
 * @returns {any}
 */
const createKeywordOptionFromTag = (tag) => {
    tag['type'] = BasicSearchAutocompleteTypes.tag;
    const typeValue = tag.type
        .substring(0, 1)
        .toLowerCase()
        .concat(tag.type
            .substring(1)
            .replaceAll(Regexes.CamelCaseFinder, (char) => ` ${char.toLowerCase()}`)
        );
    return ({
        id: tag.id,
        key: `${tag.name}-${tag.id}`,
        value: tag.name,
        label: `${tag.name} (${typeValue})`,
        type: tag.type,
        typeValue: typeValue,
    })
}

const ProductsSearchBasicForm = ({data: dataProp, formData}) => {
    const {history, stringifyUrl} = useRouter();
    const [initialFormValues, setInitialFormValues] = useState({});
    const prevDataProp = useRef({});

    const firstCategories = useMemo(() => [
        initialFirstCategoryOption,
        ...(formData?.categories
            ?.filter((item) => item.ParentId === null)
            ?.map(e => ({
                id: e.Id,
                value: e.Id,
                title: e.Title,
            })) ?? [])
    ], [formData?.categories]);

    const orderBys = useMemo(() => [
        initialOrderBy,
        ...(formData.orderByList?.map(e => ({
            name: e.Name,
            title: e.Title,
        })) ?? [])
    ], [formData?.orderByList])

    /**
     * Listens for the changes in dataProp and with each change:
     * - syncs dataProp with the data in the state.
     */
    useEffect(() => {
        if (!formData.categories?.length) {
            return;
        }
        if (!Object.keys(dataProp).length) {
            setInitialFormValues({});
            prevDataProp.current = {}
            return
        }
        if (deepEqual(dataProp, prevDataProp.current)) {
            return;
        }
        shouldSetInitialValues();
    }, [dataProp, formData?.categories, formData?.orderByList])

    /**
     * Determines if search should occur again.
     *
     * * injects the proper values for the initial form values such as the keyword option either from keyword value
     * or the first render-able tag from tags
     */
    const shouldSetInitialValues = () => {
        let [newData, removed] = ProductSearchUtils.parseBasicSearchQuery(dataProp, {
            categories: formData.categories,
            orderByList: formData.orderByList,
        });

        if (newData[formKeys.tags]) {
            // get tags that can be rendered
            const withNames = newData[formKeys.tags]?.filter(e => !!e.id && !!e.name) ?? [];
            if (newData[formKeys.keyword]) {
                // keywords do get priority over tags for the autocomplete
                newData[formKeys.keyword] = createKeywordOption(newData[formKeys.keyword]);
            } else if (!newData[formKeys.keyword] && withNames?.length) {
                // pick the first tag and then create an option for it as the keyword value
                const firstTag = withNames[0];
                newData[formKeys.keyword] = createKeywordOptionFromTag(firstTag);
            }
        } else if (!!newData[formKeys.keyword]?.trim()) {
            newData[formKeys.keyword] = createKeywordOption(newData[formKeys.keyword?.trim()]);
        }

        setInitialFormValues(newData);
        prevDataProp.current = dataProp
        if (removed?.length) {
            search(newData)
        }
    }

    /**
     * Changes the query of the url with the given search values.
     * @param {any} _values the form values.
     * @param {boolean} form
     */
    const search = (_values, form = false) => {
        const [keywords, keywordsError] = validateKeywords(_values);
        const category1Error = validateCategory1(_values);
        if (!!keywordsError || !!category1Error) {
            return {
                [formKeys.keyword]: keywordsError,
                [formKeys.category1]: category1Error,
            };
        }
        const values = deepCopy(_values);
        if (_values[formKeys.keyword]?.type === BasicSearchAutocompleteTypes.tag) {
            values[formKeys.keyword] = undefined;
            values[formKeys.tags] = [{id: _values[formKeys.keyword]?.id, name: _values[formKeys.keyword]?.value}];
        } else {
            values[formKeys.keyword] = keywords;
            values[formKeys.tags] = undefined;
        }
        const url = stringifyUrl({
            url: routes.main.products,
            query: {
                type: ProductsSearchTypes.basic,
                data: JSON.stringify({
                    [ProductsSearchTypes.basic]: {
                        ...ProductSearchUtils.transformFormValuesToSearchQuery(formKeys, values, {
                            [queryKeys.keyword]: formKeys.keyword,
                            [queryKeys.categoryId]: (values) => values[formKeys.category3] ?? values[formKeys.category2] ?? values[formKeys.category1],
                            [queryKeys.orderBy]: formKeys.orderBy,
                            [queryKeys.tags]: formKeys.tags,
                        }),
                    }
                }),
            },
        });

        if (form)
            history.push(url);
        else
            history.replace(url);
    };

    /**
     * Validates the keywords of the form based on the given values:
     *
     * This is basically the same thing as:
     * ```javascript
     * Yup.string().when(formKeys.category1, {
     *         is: (val) => !!val,
     *         then: Yup.string().nullable().trim().min(3, ValidateMessages.min('Keywords', '3')),
     *         otherwise: Yup.string().nullable().trim().required(ValidateMessages.required).min(3, ValidateMessages.min('Keywords', '3')),
     *     }),
     * ```
     * @param values
     * @returns {[undefined,string]|[*]}
     */
    const validateKeywords = (values) => {
        const keywords = values[formKeys.keyword]?.value?.trim();
        if (!!values[formKeys.category1]) {
            if (typeof keywords === 'string' && keywords?.length < 3 && keywords.length > 0) {
                return [undefined, ValidateMessages.minLength('3')];
            }
            return [keywords]
        }
        if (!keywords)
            return [undefined, ValidateMessages.required];
        if (typeof keywords === 'string' && keywords?.length < 3) {
            return [undefined, ValidateMessages.minLength('3')];
        }
        return [keywords]
    }

    /**
     * Validates the first category's value before submission of the form.
     * @param values
     * @returns {string | undefined}
     */
    const validateCategory1 = (values) => {
        const keywords = values[formKeys.keyword]?.value?.trim();
        if (!values[formKeys.category1] && !keywords) {
            return ValidateMessages.required;
        }
    }

    /**
     * Changes the value of the category in data.
     *
     * based on which one of the categories has changed, removes the child-categories.
     * @param {Event} e the event for changing the data
     * @param {formApi} form
     */
    const onCategoryChanged = (e, form) => {
        const {name, value} = e.target;
        form.change(name, value);
        if (name === formKeys.category1) {
            // resets the keywords' field state if validation error occurs.
            const keywords = form.getFieldState(formKeys.keyword).value;
            if (!keywords)
                form.resetFieldState(formKeys.keyword);

            form.change(formKeys.category2, undefined);
            form.change(formKeys.category3, undefined);
        }
        if (name === formKeys.category2) {
            form.change(formKeys.category3, undefined);
        }
    }

    return (
        <>
            <Form
                onSubmit={(v) => search(v, true)}
                validate={validate}
                initialValues={initialFormValues}
                render={({form, values, submitting}) => {
                    const secondCategories = [
                        initialCategoryOption,
                        ...(formData?.categories
                                ?.filter((item) => item.ParentId === values[formKeys.category1])
                                ?.map(e => ({
                                    id: e.Id,
                                    value: e.Id,
                                    title: e.Title,
                                }))
                            ?? [])
                    ];
                    const thirdCategories = [
                        initialCategoryOption,
                        ...(formData?.categories
                                ?.filter((item) => item.ParentId === values[formKeys.category2])
                                ?.map(e => ({
                                    id: e.Id,
                                    value: e.Id,
                                    title: e.Title,
                                }))
                            ?? [])
                    ];

                    const showSecondCategory = !!values[formKeys.category1] && secondCategories?.length > 1
                    const showThirdCategory = showSecondCategory && !!values[formKeys.category2] && thirdCategories?.length > 1

                    return (
                        <>
                            <Row form>
                                <Col xs={12} className={'mb-3'}>
                                    <KeywordsAutocomplete
                                        name={formKeys.keyword}
                                        required={!!required[formKeys.keyword]}
                                        form={form}
                                        submitting={submitting}
                                    />
                                </Col>
                                <Col md={showThirdCategory ? 4 : showSecondCategory ? 6 : 12} xs={12}
                                     className={'mb-3'}>
                                    <Select
                                        form
                                        name={formKeys.category1}
                                        required={required[formKeys.category1]}
                                        value={values[formKeys.category1] ?? firstCategories[0].id}
                                        renderValue={e => firstCategories?.find(c => c.id === e)?.title}
                                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                                        onChange={(e) => onCategoryChanged(e, form)}
                                        data={firstCategories?.map(e => ({
                                            value: e.value,
                                            label: e.title,
                                            id: e.id,
                                            disabled: e.disabled,
                                        }))}
                                    />
                                </Col>
                                <Col md={showThirdCategory ? 4 : 6} xs={12}
                                     className={classnames('mb-3', {'w-0': !showSecondCategory})}>
                                    <Select
                                        form
                                        name={formKeys.category2}
                                        required={required[formKeys.category2]}
                                        value={values[formKeys.category2] ?? secondCategories[0].id}
                                        renderValue={e => secondCategories?.find(c => c.id === e)?.title}
                                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                                        onChange={(e) => onCategoryChanged(e, form)}
                                        data={secondCategories?.map(e => ({
                                            value: e.value,
                                            label: e.title,
                                            id: e.id,
                                            disabled: e.disabled,
                                        }))}
                                    />
                                </Col>
                                <Col md={4} xs={12}
                                     className={classnames('mb-3', {'w-0': !showThirdCategory})}>
                                    <Select
                                        form
                                        name={formKeys.category3}
                                        required={required[formKeys.category3]}
                                        value={values[formKeys.category3] ?? thirdCategories[0].id}
                                        renderValue={e => thirdCategories?.find(c => c.id === e)?.title}
                                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                                        onChange={(e) => onCategoryChanged(e, form)}
                                        data={thirdCategories?.map(e => ({
                                            value: e.value,
                                            label: e.title,
                                            id: e.id,
                                            disabled: e.disabled,
                                        }))}
                                    />
                                </Col>
                                <Col xs={12} className={'mb-3'}>
                                    <Select
                                        form
                                        name={formKeys.orderBy}
                                        required={required[formKeys.orderBy]}
                                        value={values[formKeys.orderBy] ?? orderBys[0].name}
                                        renderValue={e => orderBys?.find(c => c.name === e)?.title}
                                        startAdornment={<CategoryIcon className={'select-icon'}/>}
                                        onChange={(e) => onCategoryChanged(e, form)}
                                        data={orderBys?.map(e => ({
                                            value: e.name,
                                            label: e.title,
                                            id: e.name,
                                            disabled: e.disabled,
                                        }))}
                                    />
                                </Col>
                                <Col
                                    xs={12}
                                    className={'d-flex flex-column align-items-center justify-content-start mt-2'}
                                >
                                    <Col md={5} lg={3} className={'p-0'}>
                                        <button className={'button primary w-100 px-5'} type={'submit'}>
                                            Search
                                        </button>
                                    </Col>
                                </Col>
                            </Row>
                        </>
                    );
                }}
            />
        </>
    )
}

const KeywordsAutocomplete = ({name, required, form, submitting}) => {
    const [fetching, setFetching] = useState(false);
    const [keywords, setKeywords] = useState('');
    const [data, setData] = useState([]);
    const isMounted = useIsMounted();

    /**
     * With each change in the keywords:
     * - fetches the autocomplete values for the given keywords.
     */
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!keywords || fetching || !isMounted() || (keywords?.trim()?.length ?? 0) < 3)
                return;
            if (!!keywords?.trim()) {
                // resets the validation error of the category1 and keywords fields.
                setKeywordsAsValue();
            }
            getAutocompleteValues(keywords).then();
        }, 400)
        return () => clearTimeout(timer);
    }, [keywords])

    /**
     * Fetches the autocomplete values for the given keywords.
     *
     * @param {string} keywords
     * @return {Promise<void>}
     */
    const getAutocompleteValues = async (keywords) => {
        setFetching(true);
        const response = await api({
            url: newLookupApis.basicAutoComplete(keywords),
            method: apiMethods.get,
        });
        if (!isMounted())
            return;
        setFetching(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        setData(response?.data?.map(e => {
            const typeValue = e.type
                .substring(0, 1)
                .toLowerCase()
                .concat(e.type
                    .substring(1)
                    .replaceAll(Regexes.CamelCaseFinder, (char) => ` ${char.toLowerCase()}`)
                );
            return ({
                id: e.id,
                key: `${e.type}-${e.id}`,
                value: e.value,
                label: `${e.value} (${typeValue})`,
                type: e.type,
                typeValue: typeValue,
            })
        }) ?? []);
    }

    /**
     * Sets the value of the autocomplete and then based on the reason of the change, modifies the form value and
     * calls appropriate callbacks.
     * @param {Event} e
     * @param {any} _value
     * @param {import('@material-ui/lab').AutocompleteChangeReason} reason
     */
    const onChanged = (e, _value, reason) => {
        if (deepEqual(_value, form.getFieldState(name).value) && !!_value)
            return;
        switch (reason) {
            case "create-option":
                setFormWithDelay(createKeywordOption(_value));
                break;
            default:
                break;
        }
    }

    /**
     * Sets the form value associated with the name with the given value.
     * @param {any} value
     */
    const setFormWithDelay = (value) => {
        setTimeout(() => {
            form.change(name, value);
        }, 1)
    }

    /**
     * Sets the keywords of this autocomplete as the value of the form entity only if the entity does not have a value.
     */
    const setKeywordsAsValue = () => {
        // category
        const category1 = form.getFieldState(formKeys.category1).value;
        if (!category1)
            form.resetFieldState(formKeys.category1);

        const value = form.getFieldState(name)?.value;
        const sameKeywords = keywords?.trim()?.toLowerCase() === (typeof value === 'string' ? value : value?.value)?.trim()?.toLowerCase()
        if (!!sameKeywords)
            return;
        if (typeof value === 'string')
            return form.change(name, createKeywordOption(value?.trim()));
        if (keywords?.trim())
            return form.change(name, createKeywordOption(keywords?.trim()))
    }

    return (
        <AutoComplete
            freeSolo
            fullWidth
            disabled={submitting}
            loading={fetching}
            form={form}
            name={name}
            required={required}
            classes={{
                paper: 'basic-search-keywords-popper'
            }}
            placeholder={'Keyword'}
            className={'search-input'}
            onInputChange={(_, v) => setKeywords(v)}
            onChange={onChanged}
            getOptionLabel={e => e.value ?? ''}
            options={data ?? []}
            textFieldProps={{
                InputProps: {
                    startAdornment: (
                        <button className={'button text'} type={'submit'}>
                            <SearchIcon className={'icon'}/>
                        </button>
                    ),
                },
            }}
            fieldProps={{
                beforeSubmit: setKeywordsAsValue,
            }}
            renderOption={(option, {inputValue}) => {
                const matches = match(option.value, inputValue);
                const options = parse(option.value, matches);
                return (
                    <div>
                        {
                            options.map((part, index) => (
                                <span key={index} style={{fontWeight: part.highlight ? 600 : 400}}>
                                {part.text}
                            </span>
                            ))
                        }
                        <span className={'option-type'}>
                            ({option.typeValue ?? ''})
                        </span>
                    </div>
                );
            }}
        />
    );
}

export default ProductsSearchBasicForm;
