import React, {useMemo} from "react";
import {EmailShareButton, FacebookShareButton, TelegramShareButton, TwitterShareButton} from "react-share";
import {ReactComponent as TelegramIcon} from "../../../../assets/images/share-buttons/telegram.svg";
import {ReactComponent as FacebookIcon} from "../../../../assets/images/share-buttons/face-book.svg";
import {ReactComponent as TwitterIcon} from "../../../../assets/images/share-buttons/twitter.svg";
import {ReactComponent as EmailIcon} from "../../../../assets/images/share-buttons/email.svg";
import classnames from "classnames";

const ContentShareButtons = ({tag: Tag, title, slug, link, ...props}) => {

    const info = useMemo(() => ({
        title: `Bizkey Tech's blog: ${title ?? ''} \n\n I was exploring in Bizkey Tech blogs and here is one that I found interesting:`,
        link: link,
        slug: slug,
    }), [title, link, slug])

    const telegramInfo = useMemo(() => ({
        title: info.title,
        url: info.link,
    }), [info])

    const facebookInfo = useMemo(() => ({
        quote: info.title,
        url: info.link,
    }), [info])

    const twitterInfo = useMemo(() => ({
        title: info.title,
        url: info.link,
    }), [info])

    const emailInfo = useMemo(() => ({
        body: info.title,
        url: info.link,
        separator: "\n\n",
        subject: info.slug ?? '',
    }), [info])

    /**
     * Stops the propagation of the on-click event of the tag and then calls the [onClick] callback of the props if
     * it exists.
     * @param {MouseEvent} e
     */
    const onTagClicked = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (props?.onClick) {
            props?.onClick(e);
        }
    }

    return (
        <>
            <Tag
                {...props}
                onClick={onTagClicked}
                className={classnames("blog-share-buttons", props?.className ?? '')}
            >
                <TelegramShareButton
                    className={'a'}
                    title={telegramInfo.title}
                    url={telegramInfo.url}
                >
                    <TelegramIcon/>
                </TelegramShareButton>
                <FacebookShareButton
                    className={'a'}
                    quote={facebookInfo.quote}
                    url={facebookInfo.url}
                >
                    <FacebookIcon/>
                </FacebookShareButton>
                <TwitterShareButton
                    className={'a'}
                    title={twitterInfo.title}
                    url={twitterInfo.url}
                >
                    <TwitterIcon/>
                </TwitterShareButton>
                <EmailShareButton
                    className={'a'}
                    url={emailInfo.url}
                    subject={emailInfo.subject}
                    body={emailInfo.body}
                    separator={emailInfo.separator}
                >
                    <EmailIcon/>
                </EmailShareButton>
            </Tag>
        </>
    )
}

export default ContentShareButtons;
