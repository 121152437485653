import {createTheme} from "@material-ui/core";
import {lg, md, sm, xl, xs, xxl} from "./sizes";
import Colors from "./colors";


const theme = createTheme({
    breakpoints: {
        values: {
            xs: xs,
            sm: sm,
            md: md,
            lg: lg,
            xl: xl,
            xxl: xxl,
        },
    },
    palette: {
        primary: {
            main: Colors.secondaryColor,
        },
        secondary: {
            main: Colors.primaryColor,
        },
        cancelBlack: {
            main: Colors.blackColor,
        },
        error: {
            main: Colors.red2,
        },
        warning: {
            main: Colors.warning,
        },
        success: {
            main: Colors.green,
        },
    },
    typography: {
        fontFamily: 'Poppins',
        fontSize: 15,
    },
    shape: {
        borderRadius: 4,
    },
    overrides: {
        MuiInputLabel: {
            root: {
                color: 'black',
            },
            outlined: {
                transform: 'translate(14px, 16px) scale(1)',
                color: 'black !important',
                opacity: '0.7 !important',
                '&.Mui-error': {
                    color: 'red !important'
                }
            },
        },
        MuiSelect: {
            root: {
                backgroundColor: 'white',
            },
            icon: {
                color: Colors.primaryColor,
            },
            outlined: {
                borderColor: Colors.inputBorderColor,
            },
            select: {
                '&:focus': {
                    backgroundColor: 'transparent',
                }
            }
        },
        MuiOutlinedInput: {
            root: {
                backgroundColor: 'white',
                borderColor: Colors.inputBorderColor,
                '&.Mui-error': {
                    '&$focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: `${Colors.lightRed} !important`,
                    },
                },
                '&$focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: `${Colors.inputBorderColor} !important`,
                },
            },
            input: {
                padding: "14px 10px",
                '&::placeholder': {
                    color: 'black',
                    opacity: 0.5,
                },

            },
            notchedOutline: {
                borderColor: Colors.inputBorderColor,
            },
            adornedEnd: {
                paddingRight: 5
            }
        },
        MuiAutocomplete: {
            root: {
                backgroundColor: 'white',
            },
            inputRoot: {
                padding: '4px',
            }
        },
        MuiFormHelperText: {
            root: {
                fontSize: 12,
            },
            contained: {
                marginLeft: '0px',
                marginRight: "0px",
            }
        },
        MuiFormLabel: {
            root: {
                opacity: 0.6,
                '&$focused': {
                    opacity: 1,
                },

            }
        },
        MuiTooltip: {
            tooltip: {
                fontSize: '13px',
            }
        },
        MuiIconButton: {
            root: {
                padding: 7
            },
        },
        MuiPickersDatePickerRoot: {
            toolbar: {
                backgroundColor: `${Colors.datePickerToolBar} !important`,
            },

        },
        MuiPickersToolbarText: {
            toolbarTxt: {
                color: 'white',
            },
            toolbarBtnSelected: {
                color: 'white',
            }
        },
        MuiPickersDay: {
            daySelected: {
                color: 'white',
                backgroundColor: Colors.secondaryColor,
            },
            current: {
                color: Colors.primaryColor,
            },
        },
        MuiPickersYear: {
            yearSelected: {
                color: Colors.primaryColor
            }
        },
        MuiPickersMonth: {
            monthSelected: {
                color: Colors.primaryColor
            }
        }

    },
});


export default theme;
