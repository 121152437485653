import * as Yup from "yup";

/**
 * Houses all the customized test functions added to different types of the Yup package.
 *
 * - NOTE: be sure to add each of the necessary extensions in the files that you intend to use them.
 */
class YupExtensions {

    /**
     * Adds the phone number method to Yup String types.
     * @return {Yup.Schema}
     */
    static phoneNo() {
        Yup.addMethod(Yup.string, 'phoneNo', function (...args) {
            const [message, required = false, validLength = 10] = args;
            return this.test('test-phone-no', message, function (val) {
                let value = val?.trim();
                if (!val) {
                    return true;
                }
                value = value
                    .replace(/[()_-]/g, '')
                    .replace(/\+1/g, '')
                    .replaceAll(' ', '');
                if (!value) {
                    return true;
                }
                return value?.length === validLength;
            });
        });
    }

    /**
     * Injects all the extensions of this interface to yup.
     */
    static injectExtensions() {
        this.phoneNo();
    }
}

export default YupExtensions;
