import React, {useEffect, useState} from "react";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import {Document, Page, pdfjs} from 'react-pdf';
import {TechnicalFileTypes} from "../../../../core/constants/enums";
import Iframe from "react-iframe";
import {downloadFile} from "../../../../core/services/utils/utils";

const TechnicalFilePreviewDialog = ({preview, open, toggle}) => {
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [downloading, setDownloading] = useState(false);

    /**
     * Sets the global worker for the pdfjs as soon as the component mounts.
     */
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    }, [])

    /**
     * Sets the page numbers and the selected page number as soon as the pdf document loads successfully.
     * @param numPages
     */
    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages);
        setPageNumber(1);
    }

    /**
     * Downloads the file of the technical file for the user by fetching the file then downloading it using an <a> tag.
     */
    const downloadTechnicalFile = async () => {
        setDownloading(true);
        await fetch(preview.fileAddress)
            .then((resp) => {
                if (resp.status !== 200) return undefined;
                return resp.blob();
            })
            .then((blob) => {
                if (!blob) return;
                downloadFile(blob, preview.name.concat(`.${preview.extention}`));
            })
            .catch((e) => {
            });
        setDownloading(false);
    }


    const content = () => {
        switch (preview?.type?.id) {
            case TechnicalFileTypes.PDF:
                return (
                    <>
                        <Document
                            file={preview?.fileAddress}
                            onLoadSuccess={onDocumentLoadSuccess}
                            error={<div className={'mb-3 mt-4 py-5 d-flex justify-content-center' +
                                ' align-items-center' +
                                ' text-center'}>
                                Failed to load this PDF
                            </div>}
                        >
                            <Page pageNumber={pageNumber}/>
                        </Document>

                    </>
                )
            case TechnicalFileTypes.HTML:
                return (
                    <Iframe
                        url={preview?.fileAddress}
                        width={'100%'}
                        height={'100%'}
                        frameBorder={0}
                        className={'preview-iframe'}
                    />
                )
            case TechnicalFileTypes.IMAGE:
                return (
                    <div className={'d-flex justify-content-center preview-image'}>
                        <img src={preview?.fileAddress} alt="preview image"/>
                    </div>
                )
            default:
                return (
                    <div className={'d-flex text-center justify-content-center my-3'}>
                        <p>Unfortunately, preview is unavailable for the specified file. You can still
                            download it though.</p>
                    </div>
                )
        }
    }


    return (
        <Modal unmountOnClose centered
               isOpen={open}
               toggle={toggle}
               size={'lg'}
               className={'technical-file-preview-dialog'}>
            <ModalHeader className={'w-100'}>
                {(preview?.name ?? "Technical File").concat(' Preview')}
            </ModalHeader>
            <ModalBody className={'px-3'}>
                {content()}
            </ModalBody>
            <ModalFooter className={'d-flex align-items-center justify-content-between'}>
                <Row className={'no-gutters w-100'}>
                    <Col xs={4} className={'d-flex justify-content-start'}>
                        <div className={'flex-grow-1'}/>
                    </Col>
                    <Col xs={4} className={'d-flex justify-content-center'}>
                        {
                            numPages > 1 &&
                            <div className={'page-controls'}>
                                <button className={'button primary outlined py-2'}
                                        onClick={() => setPageNumber(prevState => Math.max(1, prevState - 1))}>
                                    Prev
                                </button>
                                <span>{pageNumber ?? 0} of {numPages ?? 0}</span>
                                <button className={'button primary outlined py-2'}
                                        onClick={() => setPageNumber(prevState => Math.min(numPages, prevState + 1))}>
                                    Next
                                </button>
                            </div>
                        }
                    </Col>
                    <Col xs={4} className={'d-flex justify-content-end'}>
                        <button
                            className={'button primary px-5'}
                            disabled={downloading}
                            onClick={downloadTechnicalFile}>
                            {
                                downloading
                                    ? 'downloading...'
                                    : 'Download File'
                            }
                        </button>
                    </Col>
                </Row>
            </ModalFooter>
        </Modal>
    );
}

export default TechnicalFilePreviewDialog;
