import DynamicContentEditorController from "../controller";
import {DynamicContentEditorApiConfiguration} from "../../../index.d";

/**
 * This interface is responsible for communications between the edit-mode-iframe and the view-mode-window of the iframe.
 */
class DynamicContentEditorCommunicator {
    private _historyStackLength: number;
    private _apiConfigurationSetter?: (config: Partial<DynamicContentEditorApiConfiguration>) => void;

    /**
     * Constructs a new communicator with the provided values.
     * @param {number} historyStackLength
     */
    constructor(historyStackLength: number = 0) {
        this._historyStackLength = historyStackLength;
    }

    /**
     * Gets the stack length of the DCE iframe history.
     *
     * * this getter is used to fetch the depth of the history stack when the iframe of DCE is to be removed, so we
     * can navigate back to the initial page before the DCE route is used.
     */
    get historyStackLength(): number {
        return this._historyStackLength;
    }

    /**
     * Sets the stack length of the DCE iframe history.
     * @param value
     */
    set historyStackLength(value: number) {
        this._historyStackLength = value;
    }

    /**
     * Fetches the api configuration setter of this communicator.
     *
     * * this setter is used when the top window (view-mode) wants to inject the properties obtained from the
     * validateToken api into the iframe's state (edit-mode) so the iframe can call its edit-mode-specific apis.
     */
    get apiConfigurationSetter(): ((config: Partial<DynamicContentEditorApiConfiguration>) => void) | undefined {
        return this._apiConfigurationSetter;
    }

    /**
     * Prepares the api configuration syncer of this communicator by entrapping the controller passed from the
     * iframe and provide it to the top window.
     *
     * @param {DynamicContentEditorController} controller
     * @return {void}
     */
    prepareApiConfigurationSyncer(controller: DynamicContentEditorController): void {
        this._apiConfigurationSetter = (configuration) => {
            controller.setApiConfiguration(configuration);
        }
    }

}

export default DynamicContentEditorCommunicator;
