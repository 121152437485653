/**
 * The Codes used in this application's api errorCode / successCode that would flag a specific case.
 */
export default class ApiResponseCodes {
    /**Specifies that the user already has an existing shopping cart when they want to open a quote.*/
    static existingCart: number = -2;

    /**Specifies that the cart we are trying to check out has items with delayed shipping.*/
    static cartContainsDelayedItems: number = -2;

    /**
     * Specifies that the api call is aborted due to user not being logged in and the api requires a logged-in user.
     */
    static apiAbortedDueToAuth: number = 1234;
}
