import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import Draggable from "react-draggable";
import * as $ from 'jquery';
import classnames from "classnames";
import {createUUId} from "../../../../core/services/utils/utils";

// the default size of the switch
const DEFAULT_SIZE = 6;

const Switch = ({
                    onChange,
                    size = DEFAULT_SIZE,
                    className = '',
                    disabled = false,
                    color = 'primary',
                    checked: checkedProp = false,
                    id: idProp,
                    // animationDelay = DefaultTableRowAnimationProps.timeout,
                }) => {
    const id = useRef(idProp || createUUId(true));
    const containerId = useRef(createUUId(true));
    const [checked, setChecked] = useState(checkedProp ?? false);
    const [switchSize, setSwitchSize] = useState(size);
    const [timer, setTimer] = useState(0);
    const draggableRef = useRef();

    /**
     * As soon as the component mounts:
     * sets the switch's size if it is not in a valid range
     */
    useLayoutEffect(() => {
        if (!(size >= 1 && size <= 10) || size === undefined)
            setSwitchSize(DEFAULT_SIZE);
    }, []);

    /**
     * Listens to the checked state
     * Call the onChange method provided from props
     */
    useEffect(() => {
        if (!id.current)
            return;
        moveSwitch(checked);
        if (checked === checkedProp)
            return;
        const prev = !checked;
        if (onChange)
            onChange(checked, () => {
                setChecked(prev);
            });
    }, [checked, id.current]);

    /**
     * Listens to the checked state
     * Call the onChange method provided from props
     */
    useEffect(() => {
        if (checked === checkedProp) return
        setChecked(checkedProp ?? false);
    }, [checkedProp]);

    /**
     * Handles the onClick event for the switch
     * @param {Event} e
     * @param {boolean} force
     */
    const onClick = (e, force = false) => {
        if (disabled && !force) return;
        setChecked(prevState => !prevState);
        e.stopPropagation();
        e.preventDefault();
    }

    /**
     * Sets the timer when the drag functionality starts
     */
    const onDragStart = () => {
        setTimer(new Date().getTime());
    }

    /**
     * Determines whether the user has dragged to switch or clicked on it.
     * @return {boolean}
     */
    const determineIfUserDraggedTheSwitch = () => {
        const startTime = timer;
        const endTime = new Date().getTime();
        // Checking if the user clicked on the switch handle or dragged it
        return endTime - startTime <= 300;
    }

    /**
     * Moves the switch thumb component in the container.
     * @param {boolean} checked         whether to move to switch to on or off state.
     */
    const moveSwitch = (checked) => {
        const switchElm = $(`#${id.current}`);
        if (!checked) {
            switchElm.css({
                transform: `translateX(-2px)`
            });
        } else {
            const containerWidth = $(`#${containerId.current}`).outerWidth();
            switchElm.css({
                transform: `translateX(${containerWidth / 2}px)`
            })
        }
    }

    /**
     * Handles and moves the switch handle using the current position
     * to the proper position
     * @param {DraggableEvent}  e
     * @param {DraggableData}   data
     */
    const onDragStop = (e, data) => {
        if (determineIfUserDraggedTheSwitch()) {
            draggableRef.current?.classList?.remove('no-transition');
            return requestAnimationFrame(() => onClick(e))
        }

        const switchEle = $(`#${id.current}`);
        const {left: switchLeft} = switchEle.position();
        const switchWidth = switchEle.outerWidth();
        const containerWidth = $(`#${containerId.current}`).outerWidth();
        const switchInOffArea = (switchLeft + (switchWidth / 2)) < (containerWidth / 2);
        const switchInOnArea = (switchLeft + (switchWidth / 2)) > (containerWidth / 2);
        let _checked = checked;
        if (switchInOffArea && checked) {
            _checked = false;
        }
        if (switchInOnArea && !checked) {
            _checked = true;
        }
        if (checked !== _checked) {
            setChecked(_checked);
        } else {
            moveSwitch(_checked);
        }
    }

    return (
        <div
            className={classnames(`switch-wrapper`, className, color, {disabled, checked})}
            id={containerId.current}
            style={{
                '--switch_size': switchSize,
            }}
            onClick={(e) => e.preventDefault() || e.stopPropagation()}
            onMouseDown={e => e.preventDefault() || e.stopPropagation()}
            onMouseUp={e => e.preventDefault() || e.stopPropagation()}
            onTouchStart={e => e.preventDefault() || e.stopPropagation()}
            onTouchEnd={e => e.preventDefault() || e.stopPropagation()}
        >
            <div
                className={classnames(`w-100 switch-text px-1 pr-2 text-left`, {'show': checked,})}
                onClick={onClick}>
                ON
            </div>
            <div
                className={classnames(`w-100 switch-text px-1 pr-2 text-right`, {'show': !checked,})}
                onClick={onClick}>
                OFF
            </div>
            <Draggable
                axis='x'
                bounds='parent'
                scale={1}
                disabled={disabled}
                allowAnyClick={false}
                onStart={onDragStart}
                onStop={onDragStop}
                ref={draggableRef}
                defaultPosition={{x: -2, y: 0}}
                position={{
                    x: checked
                        ? ($(`#${containerId.current}`).outerWidth() / 2)
                        : -2,
                    y: 0,
                }}
                defaultClassNameDragging={'no-transition'}
            >
                <div
                    className={classnames(`switch`)}
                    id={id.current}
                />
            </Draggable>
        </div>
    )
};

export default Switch;
