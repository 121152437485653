import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {controller} from "../../../../core/services/api/controller";
import {useDispatch, useSelector} from "react-redux";
import {policyDialog} from "../../../../redux/entities/dialogs/actions";
import {apiMethods} from "../../../../core/constants/enums";
import useIsMounted from "../../../hooks/use-is-mounted";
import EnvService from "../../../../core/services/env-service";

export const PolicyDialog = () => {
    const state = useSelector(state => state?.dialogs?.policy ?? {})
    const dispatch = useDispatch();
    const [data, setData] = useState(false);
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts:
     * fetches the data of the policy dialog from the server.
     */
    useEffect(() => {
        controller({
            "page": {
                action: apiMethods.post,
                url: `Page/Get`,
                params: {
                    "Data": EnvService.policyPageName,
                },
                loginRequired: false,
            }
        }).then(({page}) => {
            if (!isMounted())
                return;
            if (page?.isPreemptedDueToNotBeingLoggedIn)
                return;
            if (page.Code === 100) {
                setData(page.List[0])
            }
        })
    }, [])

    /**
     * Accepts the new policies of the application and closes the modal if the api result is successful.
     */
    const acceptPolicy = () => {
        controller({
            "Policy": {
                action: apiMethods.post,
                url: `User/AcceptPolicy`,
                loginRequired: false,
            }
        }).then(({Policy}) => {
            if (!isMounted())
                return;
            if (Policy?.isPreemptedDueToNotBeingLoggedIn)
                return;
            if (Policy.Code === 100) {
                dispatch(policyDialog({
                    open: false
                }))
            }
        })
    };

    /**
     * Closes the dialog.
     *
     * if mandatory, then accepts the policy and closes the modal
     * if callback exists, the callback is invoked with the value of accepted.
     * @param {boolean} accepted
     */
    const closeDialog = (accepted = false) => {
        if (state.mandatory) {
            if (!accepted) return
            return acceptPolicy();
        }
        if (typeof state?.callback === 'function') {
            state?.callback(accepted)
        }
        dispatch(policyDialog({
            open: false
        }))
    }

    return (
        <Modal size={'lg'}
               isOpen={state.open}
               backdrop={!!state.mandatory ? 'static' : true}
               toggle={() => closeDialog(false)}>
            <ModalHeader>
                {data.Title}
            </ModalHeader>
            <ModalBody className={'mx-3'}>
                <div dangerouslySetInnerHTML={{__html: data.PageContent}}/>
            </ModalBody>

            <ModalFooter className={'d-flex align-items-center justify-content-end'}>
                {
                    !state.mandatory &&
                    <button className={'button primary outlined'} onClick={() => closeDialog(false)}>
                        Cancel
                    </button>
                }
                <button className={'button primary px-5'} onClick={() => closeDialog(true)}>
                    Accept
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default PolicyDialog;
