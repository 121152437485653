import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import ValidateMessages from "../../../../../core/constants/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import useIsMounted from "../../../../hooks/use-is-mounted";
import api from "../../../../../core/services/api/api";
import {addressesApis, shoppingCartApis, userAddressApis} from "../../../../../core/constants/endpoints/endpoints";
import Form from "../../../base/form";
import {deepEqual} from "../../../../../core/services/utils/utils";
import {Col, Row} from "reactstrap";
import MuiSelect from "../../../base/select";
import MuiInput from "../../../base/input/mui-input";
import classnames from "classnames";
import {apiMethods} from "../../../../../core/constants/enums";

const schema = Yup.object().shape({
    country: Yup.object().nullable().required(ValidateMessages.required),
    province: Yup.number().nullable().required(ValidateMessages.required).typeError(ValidateMessages.required),
    fullAddress: Yup.string().nullable().required(ValidateMessages.required),
    postalCode: Yup.string().nullable().required(ValidateMessages.required),
    city: Yup.string().nullable().required(ValidateMessages.required),
    title: Yup.string().nullable(),
})

/**
 * Creates the forms initial values from the provided address.
 * @param address {any}
 * @param provinces {any}
 * @param countries {any[]}
 * @return {{}|{country: (*|{name, id: number}), city, province: (*|{name: (string|*), id: number}), postalCode, streetAddress: *}}
 */
const makeInitialValues = (address, provinces, countries) => {
    if (!address) return {};
    return {
        fullAddress: address.fullAddress,
        city: address.city,
        province: address.province?.id,
        country: countries?.find(e => e.id === address.province?.countryId),
        postalCode: address.postalCode,
    };
}

const EditShoppingCartAddressDialogEditAddressSection = ({
                                                             address,
                                                             countries,
                                                             provinces,
                                                             setProvinces,
                                                             type,
                                                             onSuccess
                                                         }) => {
    const [updating, setUpdating] = useState(false);
    const [initialValues, setInitialValues] = useState(address ?? {});
    const [expandSaveForLater, setExpandSaveForLater] = useState(false);
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const isMounted = useIsMounted();

    /**
     * Fetches the list of provinces from the server if the countryId of the given address was given and the
     * provinces already didnt have the list
     */
    useEffect(() => {
        if (!address?.province?.countryId) return;
        if (provinces[`${address.province.countryId}`]?.length) return;
        getProvincesForCountry(address.province.countryId);
    }, [type])

    /**
     * Listens for the changes in the address and with each change:
     * sets the initialValues of the form to the new address.
     */
    useEffect(() => {
        const _address = makeInitialValues(address, provinces, countries);
        setInitialValues(_address);
    }, [address])

    /**
     * Loads the list of provinces for the given countryId and then saves it in the provinces object for future
     * references
     * @param countryId
     */
    const getProvincesForCountry = (countryId) => {
        api({
            url: addressesApis.getAllProvincesForCountry(countryId),
            method: apiMethods.get,
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn)
                return;
            if (response?.resultFlag) {
                setProvinces(prevState => {
                    return ({...prevState, [countryId.toString()]: response.data});
                });
            }
        });
    }

    /**
     * Updates this cart's address with the provided values.
     * @param {any} values
     */
    const updateShoppingCartAddress = async (values) => {
        setUpdating(true);
        const province = provinces[values?.country?.id?.toString()]?.find(e => e.id === values.province)
        if (!province) {
            setUpdating(false);
            return;
        }
        const response = await api({
            url: shoppingCartApis.updateAddress,
            method: apiMethods.put,
            showError: true,
            data: {
                ...values,
                province: {
                    ...province,
                    country: {
                        ...values?.country,
                    },
                },
                type: {id: type.id},
            },
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setUpdating(false);
            return;
        }
        if (!response?.resultFlag) {
            setUpdating(false);
            return;
        }
        onSuccess(response.data);
        setUpdating(false);
    }

    /**
     * Creates a new address with the provided values of the address and then updates this cart's address with the
     * created address.
     *
     * @param {any} values
     * @param {formApi} form
     */
    const saveAddressForLater = async (values, form) => {
        setUpdating(true);
        const province = provinces[values?.country?.id?.toString()]?.find(e => e.id === values.province)
        if (!province) {
            setUpdating(false);
            return;
        }
        const response = await api({
            url: userAddressApis.create,
            method: apiMethods.post,
            showError: true,
            showSuccess: true,
            data: {
                ...values,
                province: province,
                streetAddress: values.fullAddress,
                isDefault: false,
                type: {id: type.id},
            }
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setUpdating(false);
            return;
        }
        if (!response?.resultFlag) {
            setUpdating(false);
            return;
        }
        form.resetFieldState('title')
        setExpandSaveForLater(false);
        const _response = await api({
            url: shoppingCartApis.updateAddress,
            method: apiMethods.put,
            showError: true,
            data: {
                ...response.data,
                fullAddress: response.data?.streetAddress,
            },
        })
        if (!isMounted()) return;
        if (_response?.isPreemptedDueToNotBeingLoggedIn) {
            setUpdating(false);
            return;
        }
        if (!_response?.resultFlag) {
            setUpdating(false);
            return;
        }
        onSuccess(_response.data);
        setUpdating(false);
    }

    /**
     * Changes the value of province and country and if the provinces of that country is not yet loaded, gets them
     * from the server.
     * @param {Event} e
     * @param {formApi} form
     */
    const onCountryChanged = (e, form) => {
        form.change(e.target.name, e.target.value);
        form.change('province', null);
        form.resetFieldState('province');
        if (provinces[`${e.target.value?.id}`]?.length) return;
        getProvincesForCountry(e.target.value?.id);
    }

    /**
     * Opens the save for later modal and saves the selected values to be used when user confirms the operation
     * @param {formApi} form
     */
    const onSaveForLaterClicked = (form) => {
        form.resetFieldState('title');
        setExpandSaveForLater(true);
    }

    return (
        <>
            <Form
                className={'edit-address'}
                onSubmit={() => false}
                validate={validate}
                initialValues={initialValues}
                render={({form, initialValues, values}) => {
                    const canProceed = !deepEqual(values, initialValues);
                    return (
                        <>
                            <Row className={'mx-5'}>
                                <Col xs={12} className={'mt-4'}>
                                    <MuiSelect
                                        form
                                        label={"Country"}
                                        name={'country'}
                                        onChange={(e) => onCountryChanged(e, form)}
                                        required={!!required.country}
                                        data={countries?.map(e => ({
                                            value: e,
                                            label: e.name,
                                            id: e.id,
                                        })) ?? []}
                                    />
                                </Col>
                                <Col xs={6}>
                                    <MuiSelect
                                        form
                                        label={"Province"}
                                        name={'province'}
                                        required={!!required.province}
                                        value={values?.province}
                                        data={
                                            values?.country?.id ? provinces[values?.country?.id?.toString()]?.map(e => ({
                                                value: e.id,
                                                label: e.name ?? e.code,
                                                id: e.id,
                                            })) ?? [] : []
                                        }
                                    />
                                </Col>
                                <Col xs={6}>
                                    <MuiInput
                                        form
                                        label={"City"}
                                        placeholder={'city'}
                                        name={'city'}
                                        required={required.city}
                                    />
                                </Col>
                                <Col xs={8}>
                                    <MuiInput
                                        form
                                        label={"Full Address"}
                                        placeholder={'full address'}
                                        name={'fullAddress'}
                                        required={required.fullAddress}
                                    />
                                </Col>
                                <Col xs={4}>
                                    <MuiInput
                                        form
                                        label={"Postal Code"}
                                        placeholder={'postal code'}
                                        name={'postalCode'}
                                        required={required.postalCode}
                                    />
                                </Col>
                                <Col xs={12} className={'mt-3 d-flex justify-content-center'}>
                                    <button className={'button primary px-5'}
                                            disabled={!canProceed || updating}
                                            onClick={() => updateShoppingCartAddress(values)}>
                                        {
                                            updating
                                                ? "processing..."
                                                : " Save changes"
                                        }

                                    </button>
                                </Col>
                            </Row>
                            <div className={classnames('save-for-later-container', {'expanded': expandSaveForLater})}>
                                <div className={'d-flex justify-content-center'}>
                                    <button
                                        className={classnames('button primary text', {"hidden": expandSaveForLater || updating})}
                                        disabled={!canProceed || updating}
                                        onClick={() => onSaveForLaterClicked(form)}>
                                        Save changes and save for later
                                    </button>
                                </div>
                                <div className={'expanded-section'}>
                                    <div className={'dots'}/>
                                    <Row className={'mx-5'}>
                                        <div className={'d-flex w-100'}>
                                            <p className={'title'}>
                                                Add a Title for future use
                                            </p>
                                        </div>
                                        <Col className={'flex-grow-1'}>
                                            <MuiInput
                                                form
                                                placeholder={'Address Title'}
                                                name={'title'}
                                                required={required.title}
                                            />
                                        </Col>
                                        <div className={'d-flex align-items-center'}>
                                            <button className={'button primary px-4'}
                                                    disabled={!canProceed || updating}
                                                    onClick={() => saveAddressForLater(values, form)}>
                                                {
                                                    updating
                                                        ? "Saving..."
                                                        : "Save and Use"
                                                }
                                            </button>
                                        </div>
                                    </Row>
                                </div>
                            </div>
                        </>
                    );
                }}
            />
        </>
    );
}


export default EditShoppingCartAddressDialogEditAddressSection;
