import React, {useMemo, useState} from "react";
import MuiInput from "../../base/input/mui-input";
import {apiMethods, ReadingFileAs} from "../../../../core/constants/enums";
import {readFile} from "../../../../core/services/utils/utils";
import {Fade} from "@material-ui/core";
import useIsMounted from "../../../hooks/use-is-mounted";
import routes from "../../../routes";
import useRouter from "../../../hooks/use-router";
import {ReactComponent as LostSearchSubmitted} from "../../../../assets/images/art-likes/lost-search-submitted.svg";
import api from "../../../../core/services/api/api";
import {newLookupApis} from "../../../../core/constants/endpoints/endpoints";

const initialState = {imageFile: null, imageDataUrl: null, message: ''};

const LostSearchContainer = ({searchToken, searchFilters}) => {
    const {history} = useRouter();
    const [submitting, setSubmitting] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [state, setState] = useState(initialState);
    const isMounted = useIsMounted();

    /**@type {boolean}*/
    const canSubmit = useMemo(() => !!state.imageFile || !!state.message?.trim()?.length, [state.imageFile, state.message])

    /**
     * Sets the message of the state.
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    const onMessageChanged = (e) => {
        e.persist();
        setState(prevState => ({
            ...prevState,
            message: e.target?.value ?? '',
        }))
    }

    /**
     * Sets the first dropped file as the selected imageFile of the state.
     * @param files
     */
    const onFilesAdded = async (files) => {
        if (!files.length) {
            return setState(prevState => ({
                ...prevState,
                imageFile: null,
                imageDataUrl: null,
            }));
        }
        const file = files[files.length - 1];
        const dataUrl = await readFile(file, ReadingFileAs.dataUrl);
        setState(prevState => ({
            ...prevState,
            imageFile: file,
            imageDataUrl: dataUrl,
        }));
    };

    /**
     * Submits a lost search report.
     * @return {Promise<void>}
     */
    const submit = async () => {
        if (!state.imageFile && !state.message?.trim()?.length)
            return;
        setSubmitting(true);
        const response = await api({
            url: newLookupApis.submitLostSearch,
            method: apiMethods.post,
            data: {
                searchToken: searchToken,
                description: state.message,
                searchQuery: JSON.stringify(searchFilters ?? ""),
                searchUrl: "__empty__",
            },
        })
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setSubmitted(true);
        }
        setSubmitting(false);
    }

    /**
     * Navigates the user back to the homepage view.
     */
    const backToHomepage = () => {
        history.replace(routes.landing);
    }

    return (
        <div className={'lost-search-container'}>
            {
                submitted &&
                <div className={'lost-search-inner-container'}>
                    <p className={'title'}>
                        Your note has been sent successfully
                    </p>
                    <button
                        className={'button primary mt-3'}
                        onClick={backToHomepage}
                    >
                        Back to home page
                    </button>
                    <LostSearchSubmitted className={'svg-art-like-constrained'}/>
                </div>
            }
            {
                !submitted &&
                <div className={'lost-search-inner-container'}>
                    <p className={'title'}>
                        Sorry, we did not find any results for your search
                    </p>
                    <p className={'description'}>
                        Please send us a note about what you are looking for, <br/>
                        and one of our salespeople will contact you
                    </p>
                    <MuiInput
                        type={'text'}
                        multiline
                        value={state.message}
                        onChange={onMessageChanged}
                        minRows={5}
                        placeholder={'Description'}
                    />
                    {/*<DropZone*/}
                    {/*    id={'lost-search'}*/}
                    {/*    dropZoneClassName={'lost-search-dropzone'}*/}
                    {/*    uploading={submitting}*/}
                    {/*    openOnClick={true}*/}
                    {/*    multiple={false}*/}
                    {/*    setFiles={onFilesAdded}*/}
                    {/*    accept={[InputFileAcceptMimes.images]}*/}
                    {/*    notAcceptedDurationInMs={2000}*/}
                    {/*>*/}
                    {/*    {(_, highlight, notAccepted) =>*/}
                    {/*        <div className="d-flex flex-wrap justify-content-center">*/}
                    {/*            {*/}
                    {/*                !!state.imageDataUrl?.length*/}
                    {/*                    ? <div className="img">*/}
                    {/*                        <LazyImage src={state.imageDataUrl} alt="image"/>*/}
                    {/*                    </div>*/}
                    {/*                    : <div className="img placeholder-image">*/}
                    {/*                        <Placeholder/>*/}
                    {/*                    </div>*/}
                    {/*            }*/}
                    {/*            <div className={'w-100 d-flex flex-column text-center'}>*/}
                    {/*                <p>*/}
                    {/*                    <strong>*/}
                    {/*                        {*/}
                    {/*                            notAccepted*/}
                    {/*                                ? "Invalid format"*/}
                    {/*                                : "Choose Photo"*/}
                    {/*                        }*/}
                    {/*                    </strong>*/}
                    {/*                </p>*/}
                    {/*                <p>*/}
                    {/*                    {*/}
                    {/*                        notAccepted*/}
                    {/*                            ? "The selected file's format is invalid. you can only use images."*/}
                    {/*                            : "Click here or drag and drop an image of the product."*/}
                    {/*                    }*/}
                    {/*                </p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    }*/}
                    {/*</DropZone>*/}
                    <Fade in={canSubmit}>
                        <button
                            disabled={submitting || !canSubmit}
                            className={'button primary px-5'}
                            onClick={submit}
                        >
                            {
                                submitting
                                    ? "Sending..."
                                    : "Send"
                            }
                        </button>
                    </Fade>
                </div>
            }
        </div>
    )
}

export default LostSearchContainer;
