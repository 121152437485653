import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane,} from "reactstrap";
import {useDispatch} from "react-redux";
import {setReduxHeaderHeight} from "../../../../redux/actions";
import StoryBoard from "./sections/story-board";
import ProductsCatalogues from "./sections/catalogues";
import ContactUs from "./sections/contact-us";
import OurTeam from "./sections/our-team";
import ResellerLocator from "./sections/reseller-locator";
import useRouter from "../../../hooks/use-router";
import classnames from "classnames";
import {ReactComponent as ContactUsIcon} from "../../../../assets/images/contact-us-icon.svg";
import LazyImage from "../../../components/app-specific/lazy-lmage";
import {
    DynamicContentEditorEntry,
    DynamicContentEditorEntryRecord,
    DynamicContentEditorListEntry,
    DynamicContentEditorModels,
} from "../../../../packages/dynamic-content-editor/index";
import {DCEEntryNames} from "../../../routes";

/**
 * Tabs of the about us view.
 */
export const AboutUsTabs = {
    ourStory: {hash: 'OurStory', index: '1'},
    catalogues: {hash: "ProductsCatalogues", index: '2'},
    contactUs: {hash: 'SendMessage', index: '3'},
    resellerLocator: {hash: 'ResellerLocator', index: '4'},
    ourTeam: {hash: 'OurTeam', index: '5'},
}

const getHashIndex = (hash) => {
    return Object.values(AboutUsTabs).find(e => e.hash === hash)?.index;
}

const AboutUsView = () => {
    const {location, history, stringifyUrl} = useRouter();
    const [state, setState] = useState({activeTab: AboutUsTabs.ourStory.index})
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const headerRef = useRef();
    const dispatch = useDispatch();
    /**
     * With each change in the location hash:
     * - sets the state's active tab.
     */
    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            activeTab: getHashIndex(location.hash.substring(1)) ?? AboutUsTabs.ourStory.index,
        }))
    }, [location.hash])

    /**
     * As soon as the component mounts:
     * - attaches an event listener to listen for the changes in the resizes of the window.
     * - fetches the about us data from the server.
     */
    useEffect(() => {
        // getAboutUsData();
        window.addEventListener('resize', setHeaderHeight);
        setHeaderHeight();
        return () => {
            window.removeEventListener('resize', setHeaderHeight);
        }
    }, [])

    /**
     * Sets the height of the application header based on the current height of the headerRef.
     */
    const setHeaderHeight = () => {
        const height = headerRef?.current?.clientHeight ?? 0;
        dispatch(setReduxHeaderHeight(height / 1.6))
        const negativeTop = (height / 1.6 + -30) * -1;
        if (headerRef?.current) {
            headerRef.current.style.marginTop = negativeTop + 'px';
        }
    };

    /**
     * Scrolls the tab content into the view unless the tba is ourStory tab with no hash.
     * @param {string} hash
     */
    const scrollToTabSection = (hash) => {
        const tabIndex = getHashIndex(hash);
        setState(prevState => ({...prevState, activeTab: tabIndex}))

        const content = document.getElementById("TabContent");
        if (typeof tabIndex === 'undefined' || !content)
            return;
        content.scrollIntoView(true);
    }

    /**
     * Changes the selected tab of the view in the url to invoke the scrolling mechanism again.
     * @param {string} tabIndex the selected tab
     */
    const toggleTab = (tabIndex) => {
        if (state.activeTab === tabIndex)
            return
        const hash = Object.values(AboutUsTabs).find(e => e.index === tabIndex)?.hash ?? null;
        history.replace(stringifyUrl({
            url: location.pathname,
            fragmentIdentifier: hash,
        }));
        scrollToTabSection(hash);
    };

    return (
        <>
            <section className="about-us-header" ref={headerRef}>
                <Container>
                    <DynamicContentEditorEntry
                        isRelativePositioned
                        dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.fixed}
                        entryName={DCEEntryNames.aboutUsGeneralInfo}
                        _title={'About us General Information'}
                        tag={Row}
                        className={'about-us-general-info'}
                    >
                        <Col lg={7} xs={12}>
                            <h1>General Information</h1>
                            <DynamicContentEditorEntryRecord
                                recordKey={'description'}
                                _title={'Description'}
                                tag={'p'}
                                className={'ml-3'}
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.multiline}
                            />
                        </Col>
                        <Col lg={5} xs={12}>
                            <div className="img">
                                <DynamicContentEditorEntryRecord
                                    recordKey={'image'}
                                    _title={'Image'}
                                    valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.image}
                                    tag={LazyImage}
                                    alt={"about chtp"}
                                    height={'100%'}
                                    discardNoImage
                                />
                            </div>
                        </Col>
                    </DynamicContentEditorEntry>
                </Container>
            </section>
            <section className="about-us-our-visions">
                <Container>
                    <DynamicContentEditorListEntry
                        _title={'Company Visions'}
                        entryName={DCEEntryNames.aboutUsPoints}
                        dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.pageBased}
                        tag={Row}
                        childTag={Col}
                        childProps={{
                            lg: 4,
                            sm: 6,
                            xs: 12,
                            className: "my-3",
                        }}
                    >
                        <div className={'text'}>
                            <DynamicContentEditorEntryRecord
                                recordKey={'title'}
                                _title={'Title'}
                                tag={'div'}
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                className={'about-us-points-title'}
                                placeholder={'Placeholder'}
                            />
                            <div className={'about-us-points-desc-wrapper'}>
                                <DynamicContentEditorEntryRecord
                                    recordKey={'description'}
                                    _title={'Text'}
                                    tag={'p'}
                                    valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.multiline}
                                    className={'about-us-points-desc'}
                                    placeholder={"please click on the + icon to create this item"}
                                />
                            </div>
                        </div>
                    </DynamicContentEditorListEntry>
                </Container>
            </section>
            <main className={'about-us-view-content'}>
                <Container>
                    <Row>
                        <Col xs={12} id="TabContent">
                            <Nav tabs>
                                <NavItem>
                                    <NavLink
                                        className={classnames({'active': state.activeTab === AboutUsTabs.ourStory.index})}
                                        onClick={() => toggleTab(AboutUsTabs.ourStory.index)}>
                                        <i className={'icon-calendar'}/>
                                        <p>
                                            Our Story
                                        </p>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({'active': state.activeTab === AboutUsTabs.catalogues.index})}
                                        onClick={() => toggleTab(AboutUsTabs.catalogues.index)}>
                                        <i className={'icon-book'}/>
                                        <p>
                                            Catalogues
                                        </p>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({'active': state.activeTab === AboutUsTabs.contactUs.index})}
                                        onClick={() => toggleTab(AboutUsTabs.contactUs.index)}>
                                        <ContactUsIcon/>
                                        <p>
                                            Contact us
                                        </p>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({'active': state.activeTab === AboutUsTabs.resellerLocator.index})}
                                        onClick={() => toggleTab(AboutUsTabs.resellerLocator.index)}>
                                        <i className={'icon-pin_rounded_circle'}/>
                                        <p>
                                            Our Branches
                                        </p>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({'active': state.activeTab === AboutUsTabs.ourTeam.index})}
                                        onClick={() => toggleTab(AboutUsTabs.ourTeam.index)}>
                                        <i className={'icon-team_group'}/>
                                        <p>
                                            Our Team
                                        </p>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={state.activeTab}>
                                <TabPane tabId={AboutUsTabs.ourStory.index}>
                                    {
                                        state.activeTab === AboutUsTabs.ourStory.index &&
                                        <StoryBoard/>
                                    }
                                </TabPane>
                                <TabPane tabId={AboutUsTabs.catalogues.index}>
                                    {
                                        state.activeTab === AboutUsTabs.catalogues.index &&
                                        <ProductsCatalogues/>
                                    }
                                </TabPane>
                                <TabPane tabId={AboutUsTabs.contactUs.index}>
                                    {
                                        state.activeTab === AboutUsTabs.contactUs.index &&
                                        <ContactUs/>
                                    }

                                </TabPane>
                                <TabPane tabId={AboutUsTabs.resellerLocator.index}>
                                    {
                                        state.activeTab === AboutUsTabs.resellerLocator.index &&
                                        <ResellerLocator/>
                                    }

                                </TabPane>
                                <TabPane tabId={AboutUsTabs.ourTeam.index}>
                                    {
                                        state.activeTab === AboutUsTabs.ourTeam.index &&
                                        <OurTeam/>
                                    }
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </Container>
            </main>
        </>
    );


}

export default AboutUsView;
