import React from "react";
import NotificationManager from "./notification-manager";
import BroadcastManager from "./brodcast-manager";
import AppInfoManager from "./app-info-manager";
import DCEManager from "./dce-manager";

/**
 * The aggregator of all the application level middlewares.
 *
 * The middlewares responsibility is to inject behaviour
 * into the views or the entire application without any visible ui components.
 */
const ApplicationMiddlewares = () => {

    return (
        <>
            <NotificationManager/>
            <BroadcastManager/>
            <DCEManager/>
            <AppInfoManager/>
        </>
    )
}

export default ApplicationMiddlewares;
