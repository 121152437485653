import React, {useContext, useMemo, useState} from "react";
import DataGridBodyCell from "../cell";
import {DataGridControllerContext, DataGridMiscContext} from "../../../../index";
import classnames from "classnames";
import DataGridBodyRowDetailedPanel from "../detailed-panel";
import DataGridBodyGroupedRows from "../grouped-rows";

/**
 * @param {DataGridInternalRow} row
 * @param {DataGridDensities} density
 * @param {string} className
 * @param {Function} startScrolling
 * @return {JSX.Element}
 * @constructor
 */
const DataGridBodyRow = ({
                             row,
                             density,
                             className,
                             startScrolling,
                         }) => {
    const {classNames, singleSelectionOnly} = useContext(DataGridMiscContext);
    const dataGridApi = useContext(DataGridControllerContext);
    const [detailedPanelOpen, setDetailedPanelOpen] = useState(false);
    const [groupedRowsOpen, setGroupedRowsOpen] = useState(false);

    const hasGroupedRows = useMemo(() => row?.groupedRows?.length > 0, [row?.groupedRows])

    /**@type {boolean}*/
    const groupedRowsVisible = useMemo(() => hasGroupedRows && groupedRowsOpen, [hasGroupedRows, groupedRowsOpen])

    const hasDetailedPanel = useMemo(() =>
            (typeof row.detailedPanel !== 'undefined' && row.detailedPanel !== null),
        [row.detailedPanel])

    /**@type {boolean}*/
    const detailedPanelVisible = useMemo(() =>
            hasDetailedPanel && detailedPanelOpen,
        [hasDetailedPanel, detailedPanelOpen])


    /**
     * Toggles the selection of this row.
     *
     * * if [singleSelectionOnly] value of the data-grid is true, then resets the selection prior to the toggling of
     * the selection.
     */
    const toggleSelection = () => {
        if (singleSelectionOnly) {
            dataGridApi.resetSelection();
        }
        dataGridApi.toggleSelectedRows({
            [row.key]: !row.selected,
        }, true);
    }


    const cells = useMemo(() => row?.cells?.map((cell) => (
        <DataGridBodyCell
            key={cell.column.name}
            density={density}
            cell={cell}
            rowKey={row?.key}
            rowSelected={row?.selected}
            hasDetailedPanel={hasDetailedPanel}
            detailedPanelVisible={detailedPanelVisible}
            detailedPanelOpen={detailedPanelOpen}
            toggleSelection={toggleSelection}
            setDetailedPanelOpen={setDetailedPanelOpen}
            groupedRowsVisible={groupedRowsVisible}
            groupedRowsOpen={groupedRowsOpen}
            setGroupedRowsOpen={setGroupedRowsOpen}
            startScrolling={startScrolling}
        />
    )) ?? [], [
        row?.cells,
        row?.key,
        row?.selected,
        density,
        hasGroupedRows,
        hasDetailedPanel,
        detailedPanelVisible,
        detailedPanelOpen,
        groupedRowsVisible,
        groupedRowsOpen,
    ])

    return (
        <>
            <tr
                className={classnames(
                    'data-grid-body-row',
                    classNames.row,
                    className,
                    {
                        [classNames.selectedRow]: row.selected,
                        'selected': row.selected,
                        [row.className]: !!row.className,
                    },
                )}
                {...row.onClick && {onClick: row.onClick}}
            >
                {cells}
            </tr>
            {
                hasDetailedPanel &&
                <DataGridBodyRowDetailedPanel
                    row={row}
                    visible={detailedPanelVisible}
                />
            }
            {
                hasGroupedRows &&
                <DataGridBodyGroupedRows
                    row={row}
                    visible={groupedRowsVisible}
                    startScrolling={startScrolling}
                />
            }
        </>
    )
}

export default DataGridBodyRow;
