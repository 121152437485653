import dialogsReduxSlice from "./reducer";

const actions = dialogsReduxSlice.actions;

export const policyDialog = (data) => (dispatch) => dispatch(actions.policyDialog(data));

export const unAuthorizedDialog = (state) => (dispatch) => dispatch(actions.unAuthorizedDialog(state));

export const accessDeniedDialog = (status) => (dispatch) => dispatch(actions.accessDeniedDialog(status));

export const confirmationDialog = (data) => (dispatch) => dispatch(actions.confirmationDialog(data));

export const exportingDialog = (data) => (dispatch) => dispatch(actions.exportingDialog(data));

