import {combineReducers} from "redux";
import authReduxSlice from "./entities/auth/reducer";
import dialogsReduxSlice from "./entities/dialogs/reducer";
import headerReduxSlice from "./entities/header/reducer";
import profileReduxSlice from "./entities/profile/reducer";
import DCEReduxSlice from "./entities/dynamic-content-editor/reducer";
import shoppingCartReduxSlice from "./entities/shopping-cart/reducer";

/**
 * The combined reducers of the entire redux state.
 */
const reducers = combineReducers({
    [authReduxSlice.name]: authReduxSlice.reducer,
    [dialogsReduxSlice.name]: dialogsReduxSlice.reducer,
    [headerReduxSlice.name]: headerReduxSlice.reducer,
    [profileReduxSlice.name]: profileReduxSlice.reducer,
    [DCEReduxSlice.name]: DCEReduxSlice.reducer,
    [shoppingCartReduxSlice.name]: shoppingCartReduxSlice.reducer,
});

export default reducers;
