import React, {useEffect} from "react";
import {Alert, Col} from "reactstrap";
import classnames from "classnames";
import useRouter from "../../../../hooks/use-router";
import {useDispatch, useSelector} from "react-redux";
import useAlert from "../../../../hooks/use-alert";
import {AlertTypes} from "../../../../../core/constants/enums";
import {loginUser} from "../../../../../redux/entities/auth/actions";
import {matchPath} from "react-router-dom";
import routes, {routeLists} from "../../../../routes";
import HeaderLoginForm from "./form/login";
import useIsMounted from "../../../../hooks/use-is-mounted";

const LoggedOutHeaderContent = () => {
    const {history, location, query} = useRouter();
    const {message, loading} = useSelector(state => state?.auth ?? {message: null, loading: false})
    const [alert, setAlert, resetAlert] = useAlert(6000);
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    /**
     * Listens for the changes in the message state of the auth redux slice, and with each change:
     * - if there is no message, then closes the alert,
     * - else opens the alert since there is an error from the server that needs to be displayed.
     */
    useEffect(() => {
        // if message is gone, so should the alert
        if (!message?.length) {
            return resetAlert();
        }
        // message exists, so there is an error from the server.
        setAlert({isOpen: true, message: message, color: AlertTypes.error})
    }, [message])


    /**
     * Handles submitting the login form in the header of the application
     * @param values
     */
    const onLoginSubmitted = (values) => {
        const _values = {
            account: values['email'],
            password: values['password'],
            remember: values['remember']
        }
        dispatch(loginUser(_values, () => {
            if (!isMounted())
                return;
            if (!!matchPath(location.pathname, {path: routeLists.auth, exact: true})) {
                return history.replace(routes.landing);
            }
            history.replace(routes.redirect, {
                location: location,
                query: query,
                hash: location.hash,
            })
        }))
    }

    return (
        <>
            <Col lg={'auto'} xs={12} className={classnames('mt-lg-0 mt-5')}>
                <Alert color={alert.color} className={'mb-3 app-header-alert'} isOpen={alert.isOpen}>
                    {alert.message}
                </Alert>
                <HeaderLoginForm
                    onSubmit={onLoginSubmitted}
                    loading={loading}
                />
            </Col>
        </>
    );
}


export default LoggedOutHeaderContent;
