import React, {useState} from "react";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import MuiInput from "../../base/input/mui-input";
import Form from "../../base/form";
import {makeRequired, makeValidate} from "mui-rff";
import * as Yup from "yup";
import ValidateMessages from "../../../../core/constants/validate-messages";
import useIsMounted from "../../../hooks/use-is-mounted";

const schema = Yup.object().shape({
    cardNumber: Yup.string().nullable().required(ValidateMessages.required),
})

const RedeemGiftCardDialog = ({open, toggle, onSubmit: onSubmitProps}) => {
    const [redeeming, setRedeeming] = useState(false);
    const isMounted = useIsMounted();
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * Calls the onSuccess callback with the final value of cardNumber.
     */
    const onSubmit = async (values, form) => {
        if (!onSubmitProps) return false;
        setRedeeming(true);
        const resultFlag = await onSubmitProps(values.cardNumber ?? '');
        if (!isMounted()) return;
        if (resultFlag) {
            toggle(false);
            form.resetFieldState('cardNumber');
        }
        setRedeeming(false);
    }

    return (
        <>
            <Modal isOpen={open} toggle={toggle} centered>
                <ModalHeader>
                    Redeem your Gift Card
                </ModalHeader>
                <ModalBody>
                    <Form
                        className='w-100'
                        onSubmit={onSubmit}
                        validate={validate}
                        initialValues={{}}
                        id={'redeem-gift-card-form'}
                        render={() => {
                            return (
                                <Row className={'mt-3'}>
                                    <Col xs={12} className={'mt-3'}>
                                        <MuiInput
                                            form
                                            placeholder={'Gift card number'}
                                            className={'mt-3'}
                                            name={'cardNumber'}
                                            required={required.cardNumber}
                                            inputProps={{maxLength: 19}}
                                        />
                                    </Col>
                                </Row>
                            );
                        }}
                    />
                </ModalBody>
                <ModalFooter className={'d-flex align-items-center justify-content-end'}>
                    <button
                        className={'button text primary'}
                        onClick={toggle}
                        disabled={redeeming}>
                        Cancel
                    </button>
                    <button
                        disabled={!open || redeeming}
                        className={'button primary px-5'}
                        form={'redeem-gift-card-form'}
                        type={'submit'}>
                        {
                            redeeming
                                ? "Redeeming..."
                                : "Redeem"
                        }
                    </button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default RedeemGiftCardDialog;
