import React, {useMemo} from "react";
import {useHistory, useLocation, useParams, useRouteMatch} from 'react-router-dom';
import queryString from 'query-string';
import {QueryStringDefaultParseOptions} from "../../../core/constants/enums";

/**
 * /**
 * Hook for React-router-dom Hooks
 *
 * The return object is :
 *
 * ```javascript
 * {
 *      parseUrl: (url: string, options?: ParseOptions) => ParsedUrl,
 *      query: {[p: string]: string[] | string | null},
 *      match: match<{}>,
 *      location: Location<LocationState>,
 *      history: History<LocationState>,
 *      params: {},
 *      stringify: (object: Record<string, any>, options?: StringifyOptions) => string,
 *      stringifyUrl: (object: UrlObject, options?: StringifyOptions) => string,
 *      pathname: string
 * }
 * ```
 * @param {string | string[] | {path: string | string[]}} path
 * @param {{arrayFormatSeparator: string; arrayFormat: string}} parseOptions
 */
const useRouter = (path = null, parseOptions = QueryStringDefaultParseOptions) => {
    const params = useParams();
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch(path);
    const stringifyUrl = queryString.stringifyUrl;
    const stringify = queryString.stringify;
    const parseUrl = queryString.parseUrl;

    return useMemo(() => {
        return {
            pathname: location.pathname,
            query: {
                ...queryString.parse(location.search, parseOptions),
            },
            params: {
                ...params,
            },
            match,
            location,
            history,
            stringifyUrl,
            stringify,
            parseUrl,
        };
    }, [params, match, location, history, stringifyUrl, parseUrl, stringify]);
}

export default useRouter;
