import React, {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {quoteApis, shoppingCartApis} from "../../../../core/constants/endpoints/endpoints";
import api from "../../../../core/services/api/api";
import useIsMounted from "../../../hooks/use-is-mounted";
import {Col, Row, Container} from "reactstrap";
import {ReactComponent as EmptyCartIcon} from "../../../../assets/images/art-likes/empty-shopping-cart.svg";
import useRouter from "../../../hooks/use-router";
import routes from "../../../routes";
import {setReduxHeaderHeight} from "../../../../redux/entities/header/actions";
import {
    apiMethods,
    PaymentTerms,
    ShoppingCartInjectedPropertyNames,
    ShoppingCartProcessSteps,
    TransactionTypes,
    UserPaymentsProcessSteps
} from "../../../../core/constants/enums";
import {setShoppingCartCount} from "../../../../redux/entities/shopping-cart/actions";
import ShoppingCartSummary from "./summary";
import {ReactComponent as BackButton} from '../../../../assets/images/blog-back-icon.svg';
import {defaultHeaderHeight} from "../../../../assets/js/sizes";
import UserPayments from "../../../components/app-specific/user-payments";
import ApiResponseCodes from "../../../../core/constants/api-response-codes";
import {createUUId} from "../../../../core/services/utils/utils";
import moment from "moment";
import ShoppingCartViewItems from "./items";
import ShoppingCartViewAccountSummary from "./account-summary";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";


const ShoppingCartView = () => {
    const {history} = useRouter();
    const [cart, _setCart] = useState(null);
    const [loading, setLoading] = useState(true);
    const [checkingOut, setCheckingOut] = useState(false);
    const [step, setStep] = useState(ShoppingCartProcessSteps.items)
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    const editable = [
        ShoppingCartProcessSteps.items,
        ShoppingCartProcessSteps.delayedItems
    ].includes(step)
    const sessionPayments = cart?.appliedTransactions?.filter(e => !!(e ?? {})[ShoppingCartInjectedPropertyNames.transactions.extra])
    const order = cart?.appliedTransactions?.find(e => e.transactionTypeName === TransactionTypes.userOrder);

    /**
     * As soon as the component mounts:
     *  fetches the shopping cart and also changes the type and height of the header.
     */
    useEffect(() => {
        getShoppingCart().then();
        dispatch(setReduxHeaderHeight(defaultHeaderHeight));
    }, [])

    /**
     * Listens for the changes in steps and before the render of the component:
     * - scrolls the window to the top
     */
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [step])

    /**
     * Listens for the changes in cart and load state and with each change:
     * if not loading, then syncs the count of the shopping cart with the cart icon in the header.
     */
    useEffect(() => {
        if (loading) return
        const count = [...(cart?.parts?.map(e => e.quantity) ?? []), 0, 0].reduce((p, c) => p + c)
        dispatch(setShoppingCartCount(count))
    }, [cart, loading])

    /**
     * Listens for the changes in step and shopping cart data and with each change:
     * - determines if the component can automatically navigate the uer to the success state by checking the step.
     * - if possible, then does so only if the quote's minimumRequiredPayment is zero or less.
     */
    useEffect(() => {
        const canGoToSuccess = [
            ShoppingCartProcessSteps.payment,
            ShoppingCartProcessSteps.cardSelection,
        ].includes(step)
        if (!canGoToSuccess) return;
        if (cart?.payInfo?.isNeededToPay === false) {
            checkoutOrder().then();
        }
    }, [step, cart])

    /**
     * Sets the state of the cart data in this component.
     *
     * this method exists so that any new CartDS that comes from the backend is merged with the current DS since we
     * have already injected a lot of properties into this object.
     *
     * NOTE: if setCart's getState's argument is of type function, then it should ALWAYS be a local change
     * * if the change is from the server, updates the new quantities of the parts as well since the server changes
     * take precedence.
     * @param {Record<string, any> | function(Record<string, any>): Record<string, any>} getState
     */
    const setCart = (getState) => {
        _setCart(prevState => {
            let currentState;
            const changeFromServer = typeof getState !== 'function';
            if (typeof getState === 'function') currentState = getState(prevState);
            else currentState = getState;

            if (!currentState) return currentState;

            const newAppliedTransactions = currentState?.appliedTransactions?.map(curr => {
                const prev = prevState?.appliedTransactions?.find(e => curr.id === e.id)
                if (prev) return {...prev, ...curr}
                return curr;
            }) ?? [];
            const newAppliedTransactionIds = newAppliedTransactions?.map(e => e.id) ?? [];
            const sessionAppliedTransactions = prevState?.appliedTransactions?.filter(e =>
                !newAppliedTransactionIds.includes((e.id)) &&
                !!e[ShoppingCartInjectedPropertyNames.transactions.extra]
            ) ?? []

            return {
                ...prevState,
                ...currentState,
                parts: currentState.parts?.map(curr => {
                    const prev = prevState?.parts?.find(e => curr.partNo === e.partNo)
                    if (prev) {
                        return {
                            ...prev,
                            ...curr,
                            ...(changeFromServer
                                ? {[ShoppingCartInjectedPropertyNames.item.newQuantity]: curr.quantity ?? 0}
                                : {})
                        }
                    }
                    return curr;
                }) ?? [],
                appliedTransactions: [
                    ...newAppliedTransactions,
                    ...sessionAppliedTransactions
                ],
                appliedGiftCardInfo: currentState?.appliedGiftCardInfo
                    ? {
                        ...(prevState?.appliedGiftCardInfo ?? {}),
                        ...currentState?.appliedGiftCardInfo,
                    }
                    : null,

            }
        })
    }

    /**
     * Fetches the shopping cart from the server.
     * @return {Promise<void>}
     */
    const getShoppingCart = async () => {
        setLoading(true);
        const response = await api({
            url: shoppingCartApis.getShoppingCart,
            method: apiMethods.get,
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setLoading(false);
            return;
        }
        if (response?.resultFlag) {
            setCart(response?.data)
        }
        setLoading(false)
    }

    /**
     * Sends an email containing the details of the shopping cart to the provided email.
     *
     * @param {string} email the email address to send the cart's details
     * @return {Promise<boolean>}
     */
    const sendEmail = async (email) => {
        const response = await api({
            url: quoteApis.sendOrderAsEmail,
            method: apiMethods.post,
            data: {
                orderId: cart.quoteId, // seems SUS that this one only takes one orderId and not the list
                email: email,
            }
        })
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return false;
        return response?.resultFlag ?? false;
    }

    /**
     * Finds the new payment that the user has submitted from the newCart that is returned from the api response of
     * payment submitting.
     * @param newCart
     * @return {*}
     * @private
     */
    const _findTheNewPayment = (newCart) => {

        const prevTransactionIds = cart?.appliedTransactions?.map(e => e.id);
        const newPayments = newCart?.appliedTransactions
                ?.filter(e =>
                    !prevTransactionIds.includes(e.id) &&
                    e.transactionTypeName === TransactionTypes.payment &&
                    moment(e.submittedDate).isSame(moment(), 'hour')
                )
            ?? [];
        return newPayments[0];
    }

    /**
     * Pays for the current shopping card  of the user using the provided payData.
     *
     * this method will create a payment regardless of the result of the api. in both cases, some additional
     * information regarding the success state of the api and the credit card used are injected into the payment object.
     * @param {any} payData the payment data.
     * @return {Promise<boolean>} boolean to determine the success state of the api.
     */
    const payForOrder = async (payData) => {
        const response = await api({
            url: shoppingCartApis.pay,
            method: apiMethods.post,
            data: {
                amount: payData.amount,
                cardPaymentInfo: {
                    cardId: payData.card?.id,
                }
            }
        })
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return false;
        let payment;
        if (response?.resultFlag) {
            payment = _findTheNewPayment(response?.data);
        } else {
            payment = {
                id: createUUId(),
                authorizationCode: undefined,
                submittedDate: moment().toDate(),
                amount: payData.amount,
            }
        }
        if (payment) {
            setCart(prevState => ({
                ...prevState,
                ...response.data,
                appliedTransactions: [
                    ...(prevState?.appliedTransactions ?? []),
                    {
                        ...payment,
                        extra: {
                            card: payData.card,
                            success: response?.resultFlag ?? false,
                        }
                    },
                ],
            }))
        }
        return response?.resultFlag ?? false;
    }

    /**
     * Checks whether it is possible to check out the current shopping cart of the user in the server.
     * @return {Promise<boolean>}
     */
    const validateCheckingOutConstraints = async () => {
        setCheckingOut(true);
        const note = cart[ShoppingCartInjectedPropertyNames.note];
        const response = await api({
            url: shoppingCartApis.validateCheckout,
            method: apiMethods.put,
            showError: false,
            data: {
                note: note?.length ? note : null,
                userToBackOrderPermission: step !== ShoppingCartProcessSteps.items,
            }
        })
        if (!isMounted()) return false;
        setCheckingOut(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return false;
        if (response?.resultFlag || !response.data?.orderValidations?.length) {
            return goToNextStep(ShoppingCartProcessSteps.delayedItems)
        }
        switch (response?.errorCode) {
            case ApiResponseCodes.cartContainsDelayedItems:
                setCart(prevState => ({
                    ...prevState,
                    [ShoppingCartInjectedPropertyNames.delayedItems]: response.data?.orderValidations?.map(e => ({
                        ...e,
                        parts: e?.parts?.map(e => e.toUpperCase())
                    })) ?? [],
                }))
                setTimeout(() => goToNextStep(), 300);
                return true;
            default:
                return false;
        }
    }

    /**
     * Checks out the current shopping cart of the user.
     *
     * - if the user is in the delayed items step, then simply navigates them to the next step, and they have decided
     * to accept the late shipment.
     * - if the result of the api is successful, shows the account summary information view otherwise, if the
     * errorCode is [ApiResponseCodes.cartContainsDelayedItems] then shows the delayedItems view
     * - in other cases, simply check out the cart and if the result is successful, navigates the user to the
     * success view.
     * @return {Promise<*|boolean>}
     */
    const checkoutOrder = async () => {
        if (step === ShoppingCartProcessSteps.delayedItems) {
            return goToNextStep();
        }
        if (step === ShoppingCartProcessSteps.items) {
            return validateCheckingOutConstraints();
        }
        if (step === ShoppingCartProcessSteps.success)
            return;
        setCheckingOut(true);
        const note = cart[ShoppingCartInjectedPropertyNames.note];
        const response = await api({
            url: shoppingCartApis.checkout,
            method: apiMethods.put,
            data: {
                note: note?.length ? note : null,
                userToBackOrderPermission: step !== ShoppingCartProcessSteps.items,
            }
        })
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setCheckingOut(false);
            return;
        }
        if (response?.resultFlag) {
            setCheckingOut(false);
            setStep(ShoppingCartProcessSteps.success);
        }
        setCheckingOut(false);
    }

    /**
     * Navigates the user to the next logic step based on their current step.
     *
     * @param {string} stepOverride the step that is to override the step of the user.
     */
    const goToNextStep = (stepOverride = undefined) => {
        switch (stepOverride ?? step) {
            case ShoppingCartProcessSteps.items:
                setStep(ShoppingCartProcessSteps.delayedItems);
                break;
            case ShoppingCartProcessSteps.delayedItems:
                if (cart.paymentTerm === PaymentTerms.COD) {
                    setStep(ShoppingCartProcessSteps.payment);
                } else {
                    setStep(ShoppingCartProcessSteps.accountSummary);
                }
                break;
            case ShoppingCartProcessSteps.accountSummary:
                setStep(ShoppingCartProcessSteps.payment);
                break;
            default:
                break;
        }
        return true;
    }

    /**
     * Synchronizes the step of this view with the changes in the step in user payments component.
     *
     * this method is solely used so that the steps are syncs in both directions
     * (child -> parent direction).
     * @param {string} _step the new step belonging to UserPayments component
     */
    const onUserPaymentsStepChanged = (_step) => {
        if (_step === step) return;
        switch (_step) {
            case UserPaymentsProcessSteps.payment:
                return setStep(ShoppingCartProcessSteps.payment);
            default:
            case UserPaymentsProcessSteps.success:
                return setStep(ShoppingCartProcessSteps.success);
            case UserPaymentsProcessSteps.cardSelection:
                return setStep(ShoppingCartProcessSteps.cardSelection);
        }
    }

    /**
     * Fetches the current user payments step based on the current step of this component.
     *
     * this method is solely used so that the steps are syncs in both directions
     * (parent -> child direction).
     * * @return {string}
     */
    const getUserPaymentStep = () => {
        switch (step) {
            case ShoppingCartProcessSteps.cardSelection:
                return UserPaymentsProcessSteps.cardSelection;
            case ShoppingCartProcessSteps.success:
                return UserPaymentsProcessSteps.success;
            default:
                return UserPaymentsProcessSteps.payment;
        }
    }

    /**
     * Renders the header title of this view based on the step state.
     * @return {string}
     */
    const renderHeaderTitle = () => {
        switch (step) {
            case ShoppingCartProcessSteps.items:
            default:
                return "Your Cart";
            case ShoppingCartProcessSteps.delayedItems:
                return "Delay on Shipping";
            case ShoppingCartProcessSteps.accountSummary:
            case ShoppingCartProcessSteps.payment:
            case ShoppingCartProcessSteps.cardSelection:
                return "Pay for the Order";
            case ShoppingCartProcessSteps.success:
                return "";
        }
    }

    /**
     * Renders the text for the payment button based on the selected amount and the payable amount.
     * @param {number} amount
     * @param {number} payableAmount
     * @return {string} the button text
     */
    const renderPaymentButtonText = (amount, payableAmount) => {
        if (amount < payableAmount) return 'Pay';
        return "Pay and Submit Order"
    }

    /**
     * Renders the section that the user must see based on the process step.
     * @return {JSX.Element}
     */
    const renderShoppingCardSection = () => {
        switch (step) {
            case ShoppingCartProcessSteps.items:
            case ShoppingCartProcessSteps.delayedItems:
            default:
                return <ShoppingCartViewItems
                    step={step}
                    disabled={checkingOut}
                    goToNextStep={goToNextStep}
                    cart={cart}
                    setCart={setCart}
                />;
            case ShoppingCartProcessSteps.payment:
            case ShoppingCartProcessSteps.cardSelection:
            case ShoppingCartProcessSteps.success:
                return <>
                    <Col xs={12}>
                        <div className={'subtitle mb-3'}>
                            Quote Number: <span
                            className={'font-weight-600 ml-2'}>{order?.referenceDisplay ?? '--'}</span>
                        </div>
                    </Col>
                    <UserPayments
                        payments={sessionPayments}
                        payableAmount={cart?.payInfo?.minimumRequiredPayment}
                        pay={payForOrder}
                        onStepChanged={onUserPaymentsStepChanged}
                        disabled={checkingOut}
                        step={getUserPaymentStep()}
                        getCallToAction={renderPaymentButtonText}
                        getSuccessTitle={() => `Your order has been submitted successfully`}
                        sendEmail={sendEmail}
                        navigateAway={() => history.push(routes.landing)}
                        showPreAuthPercentageOnPayments
                    />
                </>
            case ShoppingCartProcessSteps.accountSummary:
                return <ShoppingCartViewAccountSummary
                    payInfo={cart?.payInfo ?? {}}
                    order={order}
                    checkingOut={checkingOut}
                    checkoutOrder={checkoutOrder}
                    goToNextStep={goToNextStep}
                />;
        }
    }

    return (
        <>
            <Container className={'shopping-cart-view'}>
                <Row>
                    <Col xs={12} className={'d-flex flex-row'}>
                        <h3 className={'title d-flex flex-row mb-0'}>
                            {
                                step === ShoppingCartProcessSteps.delayedItems &&
                                // <SvgIcon
                                //     className={'icon mr-3 cursor-pointer-hover'}
                                //     component={BackButton}
                                //     onClick={() => setStep(ShoppingCartProcessSteps.items)}
                                // />
                                <div
                                    onClick={() => setStep(ShoppingCartProcessSteps.items)}
                                >
                                    <BackButton className={'back-button mb-2'}/>
                                </div>
                            }
                            {renderHeaderTitle()}
                        </h3>
                    </Col>
                </Row>
                {
                    loading
                        ? <Row>
                            <Col xs={12} className={'text-center py-100'}>
                                <LoadingIndicator size={100}/>
                            </Col>
                        </Row>
                        : !cart
                            ? <Row className={'empty-cart-container'}>
                                <Col xs={12} className={'text-center'}>
                                    <div className={'navigation'}>
                                        <p>
                                            Your shopping cart is Empty
                                        </p>
                                        <button className={'button primary px-5'}
                                                onClick={() => history.replace(routes.dashboard.orders)}>
                                            continue shopping
                                        </button>
                                    </div>
                                    <EmptyCartIcon className={'empty-icon'}/>
                                </Col>
                            </Row>
                            : <>
                                <Row>
                                    <Col xs={12} md={7}>
                                        <div className={'main'}>
                                            {renderShoppingCardSection()}
                                        </div>
                                    </Col>
                                    <Col xs={12} md={5}>
                                        <ShoppingCartSummary
                                            cart={cart}
                                            setCart={setCart}
                                            editable={editable}
                                            step={step}
                                            checkoutOrder={checkoutOrder}
                                            sendEmail={sendEmail}
                                            checkingOut={checkingOut}
                                        />
                                    </Col>
                                </Row>
                            </>
                }
            </Container>
        </>
    )
}

export default ShoppingCartView;
