import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import useRouter from "../../../hooks/use-router";
import routes from "../../../routes";
import {
    apiMethods,
    MultiInvoicePaymentProcessSteps,
    OrderViewTabs,
    TransactionTypes,
    UserPaymentsProcessSteps
} from "../../../../core/constants/enums";
import {Col, Row} from "reactstrap";
import {ReactComponent as BackButton} from "../../../../../src/assets/images/blog-back-icon.svg";
import Container from "reactstrap/es/Container";
import MultiInvoicePaymentSummary from "./summary";
import api from "../../../../core/services/api/api";
import {invoicesApis} from "../../../../core/constants/endpoints/endpoints";
import useIsMounted from "../../../hooks/use-is-mounted";
import RouteLeavingGuard from "../../../components/base/route-leaving-guard";
import classnames from "classnames";
import UserPayments from "../../../components/app-specific/user-payments";
import moment from "moment";
import {setReduxHeaderHeight} from "../../../../redux/entities/header/actions";
import {defaultHeaderHeight} from "../../../../assets/js/sizes";
import {useDispatch} from "react-redux";
import {createUUId} from "../../../../core/services/utils/utils";
import MultiInvoicePaymentViewCredits from "./credits";

const initRouteLeavingGuard = {block: false, message: null}

const MultiInvoicePaymentView = () => {
    const {history, location, stringifyUrl} = useRouter();
    const [invoices] = useState(location?.state?.invoices ?? []);
    const [credits, setCredits] = useState(location?.state?.credits ?? []);
    const [payments, setPayments] = useState(location?.state?.payments ?? []);
    const [routeLeavingGuard, setRouteLeavingGuard] = useState(initRouteLeavingGuard);
    const [step, setStep] = useState(MultiInvoicePaymentProcessSteps.credits)
    const isMounted = useIsMounted();
    const dispatch = useDispatch();

    const sessionPayments = useMemo(() => payments?.filter(e => e.extra) ?? [], [payments]);
    const orderIds = useMemo(() => invoices?.map(e => e.id) ?? [], [invoices]);
    const editable = useMemo(() => [
        MultiInvoicePaymentProcessSteps.credits,
        MultiInvoicePaymentProcessSteps.payment,
        MultiInvoicePaymentProcessSteps.cardSelection,
    ].includes(step), [step])

    const invoicesAmount = useMemo(() =>
            Number(invoices
                ?.map(e => Number(parseFloat(e.payInfo?.minimumRequiredPayment ?? '0').toFixed(2) ?? 0))
                ?.reduce((a, b) => a + b, 0) ?? 0),
        [invoices]);
    const creditsAmount = useMemo(() =>
            Number(credits
                ?.map(e => Number(parseFloat(e.balance ?? '0').toFixed(2) ?? 0))
                ?.reduce((a, b) => a + b, 0) ?? 0),
        [credits]);
    const paymentsAmount = useMemo(() =>
            Number(sessionPayments
                ?.filter(e => e.extra?.success)
                ?.map(e => Number(parseFloat(e.amount ?? '0').toFixed(2) ?? 0))
                ?.reduce((a, b) => a + b, 0) ?? 0),
        [sessionPayments]);
    const total = useMemo(() =>
            Number((invoicesAmount - creditsAmount - paymentsAmount).toFixed(2)),
        [invoicesAmount, creditsAmount, paymentsAmount]);

    /**
     * As soon as this component is painted but before being rendered:
     *
     * - if the given list of invoices is empty, then navigates the user back to the invoices view
     * - if the user has opened this specific link, then navigates them to the invoices tab of orders view.
     */
    useLayoutEffect(() => {
        const invoices = location?.state?.invoices;
        if (!invoices) {
            return history.replace(stringifyUrl({
                url: routes.dashboard.orders,
                query: {
                    tab: OrderViewTabs.invoices.id
                },
            }))
        }
        if (!invoices.length) {
            return history.replace(stringifyUrl({
                url: routes.dashboard.orders,
                query: location?.state?.query,
            }))
        }
    }, [])

    /**
     * Listens for the changes in steps and before the render of the component:
     * - scrolls the window to the top
     */
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [step])

    /**
     * Listens for the changes in total and with each change and after the layout is painted:
     * - checks if the total amount is a positive amount, and if so, sets the step to success.
     * - else, if the step is success, sets it to payment sets the step to payments
     */
    useLayoutEffect(() => {
        if (total <= 0 && step !== MultiInvoicePaymentProcessSteps.success) {
            if (paymentsAmount === 0 && creditsAmount === 0)
                return;
            return setStep(MultiInvoicePaymentProcessSteps.success)
        }
        // this is unlikely to happen
        if (total > 0 && step === MultiInvoicePaymentProcessSteps.success) {
            return setStep(MultiInvoicePaymentProcessSteps.payment)
        }
    }, [total])

    /**
     * As soon as the component mounts:
     *  - changes the type and height of the header.
     */
    useEffect(() => {
        dispatch(setReduxHeaderHeight(defaultHeaderHeight));
    }, [])

    /**
     * Listens to the changes in step and the list states and with each change:
     * - determines if It's needed to block or un-block the navigation of the user.
     */
    useEffect(() => {
        guardNavigation();
    }, [step, sessionPayments, credits])

    /**
     * Determines whether to block or un-block the navigation or the user from this page.
     * - if the step is success, then the navigation should not be blocked
     * - otherwise it should be blocked.
     */
    const guardNavigation = () => {
        // we are done
        if (step === MultiInvoicePaymentProcessSteps.success) {
            return setRouteLeavingGuard(initRouteLeavingGuard);
        }
        // we have not started yet, or haven't had a successful payment
        if (step === MultiInvoicePaymentProcessSteps.credits) {
            if (!credits.length && !sessionPayments?.filter(e => e.extra?.success)?.length) {
                return setRouteLeavingGuard(initRouteLeavingGuard);
            }
        }
        if (!routeLeavingGuard.block) {
            setRouteLeavingGuard({
                block: true,
                message: 'By leaving this page, you may not be able to view the details of your payments or credit' +
                    ' selections. Are you sure?'
            });
        }
    }

    /**
     * Sets the loading state of the selected transaction based on their type.
     * @param {any} transaction the transaction to set the loading state of
     * @param {string} type the type of the transaction
     * @param {boolean} value the loading state value.
     * @return {boolean} True if the loading could be set, False otherwise
     */
    const setLoadingForTransaction = (transaction, type, value) => {
        switch (type) {
            case TransactionTypes.payment:
                setPayments(prevState => prevState?.map(e => e.id === transaction.id ? {...e, loading: value} : e))
                break;
            case TransactionTypes.creditMemo:
                setCredits(prevState => prevState?.map(e => e.id === transaction.id ? {...e, loading: value} : e))
                break;
            default:
                return false;
        }
        return true;
    }

    /**
     * Removes the transaction from their respective list based on the given type.
     * @param {any} transaction the transaction to set the loading state of
     * @param {string} type the type of the transaction
     */
    const _removeTransaction = (transaction, type) => {
        switch (type) {
            case TransactionTypes.payment:
                setPayments(prevState => prevState?.filter(e => e.id !== transaction.id))
                break;
            case TransactionTypes.creditMemo:
                setCredits(prevState => prevState?.filter(e => e.transactionNo !== transaction.transactionNo))
                break;
            default:
                break;
        }
    }

    /**
     * Adds a credit to be applied to the selected invoices.
     * @param {any} credit the credit to be applied.
     * @return {Promise<boolean>}
     */
    const addCredit = async (credit) => {
        const response = await api({
            url: invoicesApis.addCreditToMultipleInvoices,
            method: apiMethods.post,
            data: {
                transactionNo: credit.transactionNo,
                invoiceIds: orderIds
            }
        })
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return false;
        if (response?.resultFlag) {
            setCredits(prevState => [
                ...prevState,
                {...response?.data ?? {}, loading: false}
            ])
        }
        return response?.resultFlag;
    }

    /**
     * Removes a transaction from this shopping cart, a transaction in this case can be a payment, credit memo, or a
     * gift card (a refillable gift card only)
     * @param {any} transaction
     * @param {string} type
     * @return {Promise<boolean>}
     */
    const removeTransaction = async (transaction, type) => {
        if (!setLoadingForTransaction(transaction, type, true)) return false;
        setLoadingForTransaction(transaction, type, true);
        const response = await api({
            url: invoicesApis.removeBulkTransactionItem,
            method: apiMethods.post,
            data: {
                transactionNo: transaction.transactionNo,
                invoiceIds: orderIds
            }
        })
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setLoadingForTransaction(transaction, type, false);
            return false;
        }
        if (response?.resultFlag) {
            _removeTransaction(transaction, type);
        } else {
            setLoadingForTransaction(transaction, type, false);
        }
        return response?.resultFlag ?? false;
    }

    /**
     * Sends an email containing the details of these orders to the provided email.
     *
     * @param {string} email the email address to send the orders' details
     * @return {Promise<boolean>}
     */
    const sendEmail = async (email) => {
        const response = await api({
            url: invoicesApis.sendMultipleOrdersDetailsAsEmail,
            method: apiMethods.post,
            data: {
                orderId: invoices[0].id, // seems SUS that this one only takes one orderId and not the list
                email: email,
            }
        })
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return false;
        return response?.resultFlag ?? false;
    }

    /**
     * Pays for the selected invoices of the user using the provided payData.
     *
     * this method will create a payment regardless of the result of the api. in both cases, some additional
     * information regarding the success state of the api and the credit card used are injected into the payment object.
     * @param {any} payData the payment data.
     * @return {Promise<boolean>} boolean to determine the success state of the api.
     */
    const payForInvoices = async (payData) => {
        const response = await api({
            url: invoicesApis.payMultiInvoices,
            method: apiMethods.post,
            data: {
                amount: payData.amount,
                orderIds: orderIds,
                cardPaymentInfo: {
                    cardId: payData.card?.id,
                }
            }
        });
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return false;
        setPayments(prevState => [
            ...prevState,
            {
                id: createUUId(),
                authorizationCode: undefined,
                submittedDate: moment().toDate(),
                amount: payData.amount,
                ...(response?.data ?? {}),
                extra: {
                    card: payData.card,
                    success: response?.resultFlag ?? false,
                }
            }
        ])
        return response?.resultFlag ?? false;
    }

    /**
     * Navigates the user to the previous step based on the current step.
     * - if the user is in success or credits step, then navigates the user back to the invoices view.
     */
    const goToPreviousStep = () => {
        switch (step) {
            case MultiInvoicePaymentProcessSteps.payment:
                return setStep(MultiInvoicePaymentProcessSteps.credits);
            case MultiInvoicePaymentProcessSteps.cardSelection:
                return setStep(MultiInvoicePaymentProcessSteps.payment);
            case MultiInvoicePaymentProcessSteps.credits:
            case MultiInvoicePaymentProcessSteps.success:
            default:
                return history.replace(stringifyUrl({
                    url: routes.dashboard.orders,
                    query: location.state?.query,
                }))
        }
    }

    /**
     * Synchronizes the step of this view with the changes in the step in user payments component.
     *
     * this method is solely used so that the steps are syncs in both directions
     * (child -> parent direction).
     * @param {string} _step the new step belonging to UserPayments component
     */
    const onUserPaymentsStepChanged = (_step) => {
        if (_step === step) return;
        switch (_step) {
            case UserPaymentsProcessSteps.payment:
                return setStep(MultiInvoicePaymentProcessSteps.payment);
            default:
            case UserPaymentsProcessSteps.success:
                return setStep(MultiInvoicePaymentProcessSteps.success);
            case UserPaymentsProcessSteps.cardSelection:
                return setStep(MultiInvoicePaymentProcessSteps.cardSelection);
        }
    }

    /**
     * Fetches the current user payments step based on the current step of this component.
     *
     * this method is solely used so that the steps are syncs in both directions
     * (parent -> child direction).
     * * @return {string}
     */
    const paymentStep = useMemo(() => {
        switch (step) {
            case MultiInvoicePaymentProcessSteps.cardSelection:
                return UserPaymentsProcessSteps.cardSelection;
            case MultiInvoicePaymentProcessSteps.success:
                return UserPaymentsProcessSteps.success;
            default:
                return UserPaymentsProcessSteps.payment;
        }
    }, [step])

    /**
     * Renders the header title of this view based on the step state.
     * @return {string}
     */
    const headerTitle = useMemo(() => {
        switch (step) {
            case MultiInvoicePaymentProcessSteps.payment:
                return "Pay";
            case MultiInvoicePaymentProcessSteps.cardSelection:
                return "Add or Select a payment";
            case MultiInvoicePaymentProcessSteps.success:
                return "";
            case MultiInvoicePaymentProcessSteps.credits:
            default:
                return "Available Credits";
        }
    }, [step])

    /**
     * Renders the text for the payment button based on the selected amount and the payable amount.
     * @param {number} amount
     * @param {number} payableAmount
     * @return {string} the button text
     */
    const renderPaymentButtonText = (amount, payableAmount) => {
        if (amount < payableAmount) return 'Pay';
        return "Pay and Submit"
    }

    /**
     * Renders the section that the user must see based on the process step.
     * @return {JSX.Element}
     */
    const content = useMemo(() => {
        switch (step) {
            case MultiInvoicePaymentProcessSteps.payment:
            case MultiInvoicePaymentProcessSteps.cardSelection:
            case MultiInvoicePaymentProcessSteps.success:
                return <UserPayments
                    payments={sessionPayments}
                    payableAmount={total}
                    pay={payForInvoices}
                    onStepChanged={onUserPaymentsStepChanged}
                    step={paymentStep}
                    getCallToAction={renderPaymentButtonText}
                    getSuccessTitle={() => `You have paid ${invoices?.length ?? 0} ${(invoices?.length ?? 0) === 1 ? 'invoice' : "invoices"} successfully`}
                    sendEmail={sendEmail}
                    navigateAway={goToPreviousStep}
                />
            case MultiInvoicePaymentProcessSteps.credits:
            default:
                return <MultiInvoicePaymentViewCredits
                    addCredit={addCredit}
                    removeCredit={(credit) => removeTransaction(credit, TransactionTypes.creditMemo)}
                    selectedCredits={credits}
                />;
        }
    }, [step, sessionPayments, paymentStep, total, invoices, credits])


    return (
        <>
            <Container className={'multi-invoice-payment-view'}>
                {
                    step !== MultiInvoicePaymentProcessSteps.success &&
                    <Row>
                        <Col xs={12} className={'d-flex'}>
                            <h3 className={'title d-flex flex-row'}>
                                <div onClick={goToPreviousStep}>
                                    <BackButton className={'back-button'}/>
                                </div>
                                {headerTitle}
                            </h3>
                        </Col>
                    </Row>
                }

                <Row>
                    <Col xs={12} md={7} className={classnames({
                        'px-0': step === MultiInvoicePaymentProcessSteps.credits
                    })}>
                        <div className={'main'}>
                            {content}
                        </div>
                    </Col>
                    <Col xs={12} md={5}>
                        <MultiInvoicePaymentSummary
                            invoices={invoices}
                            credits={credits}
                            payments={payments}
                            step={step}
                            nextStep={() => setStep(MultiInvoicePaymentProcessSteps.payment)}
                            editable={editable}
                            removeTransaction={removeTransaction}
                            sendEmail={sendEmail}
                            total={total}
                        />
                    </Col>
                </Row>
            </Container>
            <RouteLeavingGuard
                when={routeLeavingGuard.block}
                shouldBlockNavigation={() => routeLeavingGuard.block}
                confirmationMessage={routeLeavingGuard.message}
                // we only replace it since the user is not allowed to go back to this view after leaving
                navigate={(location) => history.replace(location)}
            />
        </>
    )
}


export default MultiInvoicePaymentView;
