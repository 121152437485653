import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Popover} from "@mui/material";
import DataGridUtils from "../../../../../core/services/utils";
import {DataGridControllerContext, DataGridMiscContext, DataGridStateContext} from "../../../../../index";
import DataGridButton from "../../../../components/button";
import classnames from "classnames";
import {ReactComponent as Icon} from "../../../../../assets/images/toolbar/columns.svg";
import DataGridInput from "../../../../components/input";
import DataGridSwitch from "../../../../components/switch";
import useWindowViewportWidth from "../../../../../../../ui/hooks/use-window/viewport-width";
import DataGridIconButton from "../../../../components/icon-button/inedx";
import {InternalDataGridEvents} from "../../../../../models";
import {debounce} from "lodash";

const DataGridToolbarFilterColumnsAction = () => {
    const {columns} = useContext(DataGridStateContext);
    const {classNames} = useContext(DataGridMiscContext);
    const dataGridApi = useContext(DataGridControllerContext);
    const [popover, setPopover] = useState(null);
    const [keywords, setKeywords] = useState('');
    const id = useRef(DataGridUtils.createUUId(true));
    const viewportWidth = useWindowViewportWidth();
    const showIconButton = useMemo(() => ['xs', 'sm', 'md'].includes(viewportWidth), [viewportWidth])
    const ref = useRef();
    const togglingFromHere = useRef(false);

    const filteredColumns = useMemo(() => {
        const _keywords = keywords?.trim()?.toLowerCase() ?? '';
        const _columns = columns
            ?.filter(e => !!e.visibilityToggleable)
            ?.map(e => {
                const title = (typeof (e.title) === "function" ? e.title() : e.title);
                return ({
                    ...e,
                    title: typeof title === "string" ? (title?.toLowerCase()?.trim() ?? '') : title,
                })
            });
        return _columns.filter(e => typeof e.title === "string" ? (e.title?.indexOf(_keywords) !== -1 || _keywords?.indexOf(e.title) !== -1) : true);
    }, [keywords, columns])

    /**
     * As soon as the component mounts:
     *
     */
    useEffect(() => {
        dataGridApi.on(InternalDataGridEvents.onColumnsToolbarOpened, () => {
            setPopover(ref.current);
        })
    }, [ref, dataGridApi])


    /**
     * With each change in popover:
     * - resets the entered keywords if the popover closes.
     */
    useEffect(() => {
        if (!popover) {
            setKeywords('');
        }
    }, [popover])

    /**
     * Closes the popover
     */
    const closePopover = () => setPopover(null);

    /**
     * Opens the popover
     * @param {Event} e
     */
    const openPopover = (e) => setPopover(e.currentTarget);

    /**
     * Toggles the specified column's visibility.
     * @param {DataGridInternalColumn} column
     * @param {boolean} visibility
     */
    const toggleColumnVisibility = debounce(
        /**
         * @param {DataGridInternalColumn} column
         * @param {boolean} visibility
         */
        (column, visibility) => {
            dataGridApi.toggleColumnsVisibility({
                [column.name]: visibility
            }, true)
        },
        100);

    /**
     * Ensures that the item is clicked outside the switch boundary and only then toggles the visibility of the
     * column assocaited with the item.
     * @param {DataGridInternalColumn} column
     */
    const onItemClicked = (column) => {
        if (!togglingFromHere.current)
            return;
        if (!column.visibilityToggleable)
            return;
        toggleColumnVisibility(column, !column.visible);
        togglingFromHere.current = false;
    }

    return (
        <>
            {
                showIconButton
                    ? <DataGridIconButton
                        ref={ref}
                        className={classnames('data-grid-toolbar-action', classNames.toolbarAction, classNames.toolbarColumnsFilterActionPopover)}
                        onClick={openPopover}
                    >
                        <Icon/>
                    </DataGridIconButton>
                    : <DataGridButton
                        ref={ref}
                        className={classnames('data-grid-toolbar-action', classNames.toolbarAction, classNames.toolbarColumnsFilterActionPopover)}
                        onClick={openPopover}
                    >
                        <Icon/>
                        <p>Columns</p>
                    </DataGridButton>
            }
            <Popover
                id={!!popover ? id.current : undefined}
                elevation={2}
                open={!!popover}
                onClose={closePopover}
                anchorReference={'anchorEl'}
                className={classnames(
                    "data-grid-toolbar-columns-filter-action-popover",
                    "data-grid-popover data-grid-toolbar-action-popover",
                    classNames.toolbarActionPopover,
                    classNames.toolbarColumnsFilterActionPopover,
                )}
                classes={{
                    paper: classnames(
                        'data-grid-popover-paper data-grid-toolbar-action-popover-paper',
                        classNames.toolbarActionPopoverPaper,
                        classNames.toolbarColumnsFilterActionPopoverPaper,
                        {'bounded-width': !filteredColumns?.length}
                    )
                }}
                anchorEl={popover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <div className={'data-grid-toolbar-popover-header'}>
                    <p className={'title'}>
                        Find Column
                    </p>
                    <DataGridInput
                        fullWidth
                        value={keywords}
                        variant={'outlined'}
                        placeholder={'Column Title'}
                        size={'small'}
                        onChange={(e) => setKeywords(e.target.value)}
                    />
                </div>
                <div className={'data-grid-toolbar-popover-body'}>
                    {
                        !filteredColumns?.length
                            ? <p className={'no-items'}>
                                There is no column in this table {
                                keywords?.length
                                    ? "that matches the provided keywords"
                                    : ""
                            }
                            </p>
                            : filteredColumns?.map(column => (
                                <div
                                    key={column.name}
                                    className={'popover-item default'}
                                    onClick={() => onItemClicked(column)}
                                    onMouseDown={() => togglingFromHere.current = true}
                                    onTouchStart={() => togglingFromHere.current = true}
                                >
                                    <DataGridSwitch
                                        size={4}
                                        disabled={!column.visibilityToggleable}
                                        checked={column.visible}
                                        onChange={(checked) => toggleColumnVisibility(column, checked)}
                                    />
                                    {
                                        typeof (column.title) === "function"
                                            ? column.title() ?? ""
                                            : <p>
                                                {column.title ?? ''}
                                            </p>
                                    }
                                </div>
                            ))
                    }
                </div>
            </Popover>
        </>
    )
}

export default DataGridToolbarFilterColumnsAction;
