import {ReactComponent as NoResultFoundSvg} from "../../../../assets/images/art-likes/no-results-found.svg";
import {ReactComponent as NoSurveyFound} from "../../../../assets/images/art-likes/no-surveys-found.svg";
import {
    ReactComponent as NoPublicResultFoundSvg
} from "../../../../assets/images/art-likes/no-public-products-found.svg";
import React from "react";


const NoFoundSvg = ({usePublic = false, useSurvey = false}) => {

    return (
        <div className={'no-search-found-svg-container'}>
            {
                useSurvey
                    ? <NoSurveyFound className={'survey'}/>
                    : usePublic
                        ? <NoPublicResultFoundSvg className={'public-results'}/>
                        : <NoResultFoundSvg className={'search-results'}/>
            }
        </div>
    );
}

export default NoFoundSvg;
