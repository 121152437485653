import React, {useState} from "react";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {apiMethods} from "../../../../../core/constants/enums";
import api from "../../../../../core/services/api/api";
import {shoppingCartApis} from "../../../../../core/constants/endpoints/endpoints";
import AvailableCreditsTable from "../../../app-specific/available-credits-table";


const AddGiftCardOrCreditToShoppingCartDialogAvailableCreditsSection = ({onSuccess}) => {
    const [updating, setUpdating] = useState(false);
    const isMounted = useIsMounted();

    /**
     * Adds a credit to the shopping cart. In this case, a credit memo is considered to be a transaction.
     * if the response is successful, invokes the response callback with the provided server response as its argument
     * @param {any} transaction
     * @return {Promise<void>}
     */
    const addCreditToShoppingCart = async (transaction) => {
        setUpdating(true);
        const response = await api({
            url: shoppingCartApis.addCredit,
            method: apiMethods.put,
            data: {
                transactionNo: transaction.transactionNo,
            }
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setUpdating(false);
            return;
        }
        if (response?.resultFlag) {
            onSuccess(response.data)
        }
        setUpdating(false);
    }


    return (
        <>
            <div className={'credits'}>
                <AvailableCreditsTable
                    disabled={() => updating}
                    updating={() => updating}
                    selected={() => false}
                    className={'my-3'}
                    onSelect={addCreditToShoppingCart}
                />
            </div>
        </>
    )
}

export default AddGiftCardOrCreditToShoppingCartDialogAvailableCreditsSection;
