import React from "react";
import {Add, Remove} from "@material-ui/icons";

const ShoppingCartInput = ({disabled, quantity, onChange: onChangeProps, type = 'int', min = 0, max}) => {

    /**
     * Invokes the onChange callback with the sieved quantity value.
     * @param {Event} e
     */
    const onChange = (e) => {
        if (!e.target.value?.length) {
            if (onChangeProps) onChangeProps(null)
            return
        }
        if (e.target?.value?.includes('-')) {
            e.target.value = e.target?.value?.replace('-', '1')
        }
        const value = type === 'int'
            ? parseInt(e.target.value ?? min.toString())
            : parseFloat(e.target.value ?? min.toString())
        if (onChangeProps) onChangeProps(value)
    }

    /**
     * Invokes the onChange callback with the applied modifier as the result of using either of the buttons.
     * @param {1 | -1 | 0} modifier
     */
    const changeQuantity = (modifier) => {
        if (onChangeProps) onChangeProps(Math.max((quantity ?? min) + modifier, min))
    }

    return (
        <>
            <div className={'shopping-cart-input'}>
                <button className={'cart-square-icon button mr-2'}
                        disabled={disabled}
                        onClick={() => changeQuantity(-1)}>
                    <Remove/>
                </button>
                <input
                    type="number"
                    min={min}
                    max={max}
                    disabled={disabled}
                    value={quantity == null || isNaN(quantity) ? "" : quantity ?? ''}
                    onChange={onChange}
                />
                <button className={'cart-square-icon button ml-2'}
                        disabled={disabled}
                        onClick={() => changeQuantity(1)}>
                    <Add/>
                </button>
            </div>
        </>
    )
}

export default ShoppingCartInput;
