import React from "react";
import {Col, Row} from "reactstrap";
import useRouter from "../../../hooks/use-router";
import * as Yup from "yup";
import ValidateMessages from "../../../../core/constants/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../base/form";
import MuiInput from "../../base/input/mui-input";
import MuiSelect from "../../base/select";
import {OrderViewOrderBys, OrderViewTabs} from "../../../../core/constants/enums";
import classnames from "classnames";
import {ReactComponent as SortIcon} from "../../../../assets/images/sort-icon.svg";
import MuiDatePicker from "../../base/date-picker";
import moment from "moment";
import {ReactComponent as SearchIcon} from "../../../../assets/images/search-icon.svg";

const schema = Yup.object().shape({
    keyword: Yup.string().when('keyword', {
        is: (v) => !v?.length,
        then: Yup.string().nullable(),
        otherwise: Yup.string().nullable().required(ValidateMessages.required).min(3, ValidateMessages.minLength('3'))
    }),
    fromDate: Yup.date().nullable().typeError(ValidateMessages.incorrectType('date')),
    toDate: Yup.date().nullable().typeError(ValidateMessages.incorrectType('date')),
    orderBy: Yup.string().nullable(),
    isDescending: Yup.boolean().nullable().default(true),
}, ['keyword', 'keyword']);


const OrdersSearchSection = ({activeTab, filters}) => {
    const {history, location, query, stringifyUrl} = useRouter();
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    const orderByList = activeTab === OrderViewTabs.quotes.id
        ? OrderViewOrderBys.orders
        : OrderViewOrderBys.invoices

    /**
     * Changes the data field of query parameters to invoke the search method.
     * @param values
     */
    const search = (values) => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                tab: query?.tab,
                data: JSON.stringify(values),
            }
        }));
    }

    /**
     * With any change in the orderBy, if the selected value is none, then isDescending will have to be set to false.
     * @param {Event} e
     * @param {formApi} form
     */
    const onOrderByChanged = (e, form) => {
        if (e.target.value === undefined) {
            form.change('isDescending', false);
        }
    }


    return (
        <>
            <Form
                className={'mb-5 orders-search-section'}
                onSubmit={search}
                validate={validate}
                initialValues={filters ?? {}}
                render={({values, form}) => {
                    return (
                        <>
                            <Row>
                                <Col xs={12} md={8}>
                                    <MuiInput
                                        form
                                        className={'search-input'}
                                        placeholder={'search for order numbers and parts'}
                                        name={'keyword'}
                                        required={required.keyword}
                                        InputProps={{
                                            startAdornment: (
                                                <button className={'button text'} type={'submit'}>
                                                    <SearchIcon className={'icon'}/>
                                                </button>
                                            )
                                        }}
                                    />
                                </Col>
                                <Col xs={12} md={4}>
                                    <div className={'d-flex w-100'}>
                                        <div className={'flex-grow-1 transition'}>
                                            <MuiSelect
                                                form
                                                label={'Order by'}
                                                name={'orderBy'}
                                                required={!!required.orderBy}
                                                onChange={(e) => onOrderByChanged(e, form)}
                                                data={[
                                                    ...(!!values?.orderBy
                                                        ? [{value: undefined, label: 'None', id: -1,}]
                                                        : []),
                                                    ...(orderByList?.map(e => ({
                                                        value: e.id,
                                                        label: e.label,
                                                        id: e.id,
                                                    })) ?? []),
                                                ]}
                                            />
                                        </div>
                                        <div
                                            className={classnames('transition',
                                                {
                                                    'hidden flex-grow-0 ml-0': !values.orderBy,
                                                    'ml-2': values.orderBy,
                                                }
                                            )}>
                                            <button className={'button order-by'}
                                                    type={'button'}
                                                    disabled={!values.orderBy}
                                                    onClick={() => form.change('isDescending', !values?.isDescending)}>
                                                <SortIcon
                                                    className={classnames({'descending': !!values?.isDescending})}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                                <Col xs={6} md={4}>
                                    <MuiDatePicker
                                        form
                                        placeholder={'Start Date'}
                                        name={'fromDate'}
                                        format={"dd-MM-yyyy"}
                                        required={required.fromDate}
                                        maxDate={
                                            values.toDate && values.toDate !== 'Invalid Date'
                                                ? values.toDate
                                                : moment()
                                        }
                                    />
                                </Col>
                                <Col xs={6} md={4}>
                                    <MuiDatePicker
                                        form
                                        placeholder={'End Date'}
                                        name={'toDate'}
                                        format={"dd-MM-yyyy"}
                                        required={required.toDate}
                                        disableFuture
                                        minDate={
                                            values.fromDate && values.fromDate !== 'Invalid Date'
                                                ? values.fromDate
                                                : undefined
                                        }
                                    />
                                </Col>
                                <Col xs={12} md={4} className={'d-flex align-items-center justify-content-center'}>
                                    <button className={'button primary w-100'}
                                            type={'submit'}>
                                        Search
                                    </button>
                                </Col>
                            </Row>
                        </>
                    );
                }}
            />
        </>
    )
}

export default OrdersSearchSection;
