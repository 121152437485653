import React, {useLayoutEffect} from "react";
import useRouter from "../../hooks/use-router";
import EnvService from "../../../core/services/env-service";
import Utils, {htmlToString} from "../../../core/services/utils/utils";
import routes from "../../routes";
import {matchPath} from "react-router-dom";
import queryString from "query-string";
import {OrderViewTabs, ProductsSearchTypes} from "../../../core/constants/enums";
import {useSelector} from "react-redux";

const AppInfoManager = () => {
    const {history} = useRouter();
    const aboutUsDescription = useSelector(state => state.dynamicContentEditor?.values?.description)

    /**
     * With each change in the history:
     * - attaches a listener on the history changes that would change the application's information based on the
     * location changes.
     */
    useLayoutEffect(() => {
        const unlisten = history.listen(setApplicationTitle);
        setApplicationTitle(history.location);
        return () => unlisten();
    }, [history])

    /**
     * As soon as the about us information is fetched and populated from the redux state and with each change in the
     * history:
     * - sets the applications' description based on the provided route and the app description fetched from the
     * aboutUsInformation.
     */
    useLayoutEffect(() => {
        if (!aboutUsDescription)
            return;
        const description = htmlToString(aboutUsDescription);
        const unlisten = history.listen((location) => setApplicationDescription(location, description));
        setApplicationDescription(history.location, description);
        return () => unlisten();
    }, [aboutUsDescription, history])

    /**
     * Sets the applications' title based on the given location.
     * @param {Location} location
     */
    const setApplicationTitle = (location) => {
        const appName = EnvService.websiteName + ' - ';
        switch (location.pathname) {
            case routes.redirect:
            case routes.auth.base:
                return Utils.setAppInfo();
            case routes.landing:
                return Utils.setAppInfo({
                    title: appName + 'Home'
                });
            case routes.dynamicContentEditor:
                return Utils.setAppInfo({
                    title: appName + 'Content Editor'
                });
            case routes.error:
                return Utils.setAppInfo({
                    title: appName + 'Not Found'
                });
            case routes.auth.login:
                return Utils.setAppInfo({
                    title: appName + 'Log in'
                });
            case routes.auth.signUp:
                return Utils.setAppInfo({
                    title: appName + 'Sign up'
                });
            case routes.auth.forgetPassword:
                return Utils.setAppInfo({
                    title: appName + 'Forgot your password?'
                });
            case routes.auth.deleteAccount:
                return Utils.setAppInfo({
                    title: appName + 'Delete Account'
                });
            case routes.main.products: {
                const query = queryString.parse(location.search);
                switch (query?.type) {
                    case ProductsSearchTypes.basic:
                        return Utils.setAppInfo({
                            title: appName + 'Basic search results'
                        });
                    case ProductsSearchTypes.application:
                        return Utils.setAppInfo({
                            title: appName + 'Application search results'
                        });
                    case ProductsSearchTypes.technical:
                        return Utils.setAppInfo({
                            title: appName + 'Technical search results'
                        });
                    case ProductsSearchTypes.elasticTechnical:
                        return Utils.setAppInfo({
                            title: appName + 'Elastic technical search results'
                        });
                    default:
                        return Utils.setAppInfo({
                            title: appName + 'Search results'
                        });
                }
            }
            case routes.main.shoppingCart:
                return Utils.setAppInfo({
                    title: appName + 'Shopping cart'
                });
            case routes.main.multiInvoicePayment:
                return Utils.setAppInfo({
                    title: appName + 'Pay invoice(s)'
                });
            case routes.main.survey:
                return Utils.setAppInfo({
                    title: appName + 'Survey'
                });
            case routes.public.about:
                return Utils.setAppInfo({
                    title: appName + 'About us'
                });
            case routes.public.catalogue:
                return Utils.setAppInfo({
                    title: appName + 'Catalogues'
                });
            case routes.public.content.base:
            case routes.public.content.main:
                return Utils.setAppInfo({
                    title: appName + 'Content'
                });
            case routes.public.content.allBlogs:
                return Utils.setAppInfo({
                    title: appName + 'Blogs'
                });
            case routes.public.content.allVideos:
                return Utils.setAppInfo({
                    title: appName + 'Videos'
                });
            case routes.public.content.searchResults:
                return Utils.setAppInfo({
                    title: appName + 'Content search results'
                });
            case routes.public.products:
                return Utils.setAppInfo({
                    title: appName + 'Public Products'
                });
            case routes.dashboard.base:
                return Utils.setAppInfo({
                    title: appName + 'Dashboard'
                });
            case routes.dashboard.savedItems:
                return Utils.setAppInfo({
                    title: appName + 'Your saved items'
                });
            case routes.dashboard.paymentMethods.base:
                return Utils.setAppInfo({
                    title: appName + 'Your payment methods'
                });
            case routes.dashboard.userManagement:
                return Utils.setAppInfo({
                    title: appName + 'User management'
                });
            case routes.dashboard.profile:
                return Utils.setAppInfo({
                    title: appName + 'Edit your profile'
                });
            case routes.dashboard.address:
                return Utils.setAppInfo({
                    title: appName + 'Your addresses'
                });
            case routes.dashboard.orders: {
                const query = queryString.parse(location.search);
                switch (query?.tab) {
                    case OrderViewTabs.quotes.id:
                        return Utils.setAppInfo({
                            title: appName + 'Your quotes'
                        });
                    case OrderViewTabs.invoices.id:
                        return Utils.setAppInfo({
                            title: appName + 'Your invoices'
                        });
                    case OrderViewTabs.credits.id:
                        return Utils.setAppInfo({
                            title: appName + 'Your credits'
                        });
                    default:
                        return Utils.setAppInfo({
                            title: appName + 'Your orders'
                        });
                }
            }
            case routes.dashboard.compare:
                return Utils.setAppInfo({
                    title: appName + 'Compare items'
                });

            default: {
                let params;

                params = getParams(location.pathname, [
                    routes.publicLinks.partInformation,
                    routes.public.partInformation,
                    routes.main.partInformation,
                ]);
                if (!!params) {
                    return Utils.setAppInfo({
                        title: appName + `${params.id}`
                    });
                }

                params = getParams(location.pathname, [
                    routes.publicLinks.technicalFile,
                    routes.main.technicalFile,
                ]);
                if (!!params) {
                    return Utils.setAppInfo({
                        title: appName + `Technical file of ${params.id}`
                    });
                }

                params = getParams(location.pathname, [
                    routes.publicLinks.partSupportExtraInfo,
                ]);
                if (!!params) {
                    return Utils.setAppInfo({
                        title: appName + `Part Support Extra Information`
                    });
                }

                // For categories route, we do nothing since the title is set in the view itself.
                params = getParams(location.pathname, routes.main.categories);
                if (!!params) {
                    return;
                }

                params = getParams(location.pathname, routes.auth.resetPassword);
                if (!!params) {
                    return Utils.setAppInfo({
                        title: appName + 'Reset your password'
                    });
                }

                params = getParams(location.pathname, routes.print.invoice);
                if (!!params) {
                    return Utils.setAppInfo({
                        title: appName + 'Print your invoice'
                    });
                }

                params = getParams(location.pathname, routes.print.credit);
                if (!!params) {
                    return Utils.setAppInfo({
                        title: appName + 'Print your credit'
                    });
                }

                params = getParams(location.pathname, routes.public.page);
                if (!!params) {
                    return Utils.setAppInfo({
                        title: appName + `${params.slug}`
                    });
                }

                params = getParams(location.pathname, routes.public.content.blog);
                if (!!params) {
                    return Utils.setAppInfo({
                        title: appName + `Blog - ${params.slug}`
                    });
                }

                params = getParams(location.pathname, routes.public.content.video);
                if (!!params) {
                    return Utils.setAppInfo({
                        title: appName + `Video - ${params.slug}`
                    });
                }

                // params = getParams(location.pathname, routes.dashboard.paymentMethods.transactionsSummary);
                // if (!!params) {
                //     return Utils.setAppInfo({
                //         title: appName + 'Card Transactions'
                //     });
                // }


                return Utils.setAppInfo();
            }
        }
    }

    /**
     * Sets the applications' description based on the given location and default description.
     * @param {Location} location
     * @param {string} description
     */
    const setApplicationDescription = (location, description) => {
        switch (location.pathname) {
            default:
                Utils.setAppInfo({
                    description: description,
                });
        }

    }

    /**
     * Fetches the params associated with the current pathname and the given route.
     * @param {string} pathname
     * @param {string | string[]} route
     * @return {{[p: string]: any} | undefined}
     */
    const getParams = (pathname, route) => {
        return matchPath(pathname, {
            path: route,
            exact: true,
        })?.params;
    }

    return (<></>);
}

export default AppInfoManager;
