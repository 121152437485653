import React, {useEffect, useRef, useState} from "react";
import Slider from "react-slick";
import {ReactComponent as AlertIcon} from "../../../../../../assets/images/alert.svg";
import {ReactComponent as PrevIcon} from "../../../../../../assets/images/product-images/prev.svg";
import {ReactComponent as NextIcon} from "../../../../../../assets/images/product-images/next.svg";
import useWindowViewportWidth from "../../../../../hooks/use-window/viewport-width";
import LazyImage from "../../../../../components/app-specific/lazy-lmage";
import classnames from "classnames";
import {SvgIcon} from "@material-ui/core";
import {ReactComponent as CompareOutLinedIcon} from "../../../../../../assets/images/parts/compare-outlined.svg";
import {BookmarkBorderOutlined} from "@material-ui/icons";
import CacheService from "../../../../../../core/services/cache/cache-service";
import {controller} from "../../../../../../core/services/api/controller";
import {partApis, partCompareApis} from "../../../../../../core/constants/endpoints/endpoints";
import {toast} from "react-toastify";
import {apiMethods, ToastTypes} from "../../../../../../core/constants/enums";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import api from "../../../../../../core/services/api/api";
import ReactImageMagnify from "react-image-magnify";
import NoImage from "../../../../../../assets/images/no-image.png";
import ToastService from "../../../../../../core/services/toast-service";
import routes from "../../../../../routes";
import useRouter from "../../../../../hooks/use-router";


const PartInformationViewImagesSection = ({data, activeIndex, setActiveIndex, setData}) => {
    const {history} = useRouter();
    const [isLoggedIn] = useState(CacheService.isLoggedIn())
    const [comparing, setComparing] = useState(false);
    const [bookmarking, setBookmarking] = useState(false);
    const viewportWidth = useWindowViewportWidth();
    const [imageRect, setImageRect] = useState({height: 0, width: 0});
    const isMounted = useIsMounted();
    const [activeIndexImageErrored, setActiveIndexImageErrored] = useState(false);


    /**@type {React.MutableRefObject<import('react-slick').default>}*/
    const sliderRef = useRef();

    /**
     * With each change in the activeIndex:
     * - the image errored flag is set to false.
     */
    useEffect(() => {
        syncImageDimensions();
        setActiveIndexImageErrored(false);
    }, [activeIndex])


    /**
     * Syncs the dimensions of the rendered image so that the zoomed version has an appropriate size.
     */
    const syncImageDimensions = () => {
        if (activeIndex === -1 || !data?.images?.length)
            return;
        const image = new Image();
        image.src = data?.images[activeIndex];
        image.onload = (e) => {
            if (!isMounted())
                return;
            const heightZoomScale = image.naturalHeight > 2000 ? 1 : image.naturalHeight > 1000 ? 2 : 3;
            const widthZoomScale = image.naturalWidth > 2000 ? 1 : image.naturalWidth > 1000 ? 2 : 3;
            const zoomScale = Math.min(heightZoomScale, widthZoomScale);
            setImageRect({
                height: image.naturalHeight * zoomScale,
                width: image.naturalWidth * zoomScale,
            });
        }
        image.onerror = () => {
            if (!isMounted())
                return;
            setActiveIndexImageErrored(true);
        }
    }

    /**
     * Changes the isInCompareList value of the part in the server. Parts that are in compare list can then be
     * fetched separately in comparison view.
     *
     * @param {MouseEvent} e
     * @return {Promise<void>}
     */
    const toggleInCompareList = async (e) => {
        setComparing(true);
        if (data?.isInCompareList) {
            await removeFromCompareList();
        } else {
            await addToCompareList();
        }
        if (!isMounted())
            return;
        setComparing(false);
    }

    /**
     * Removes the curren product from the comparison list of the user.
     * @return {Promise<void>}
     */
    const removeFromCompareList = async () => {
        const response = await api({
            url: partCompareApis.removePart(data.id),
            method: apiMethods.delete,
        })
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            setData(prevState => ({
                ...prevState,
                isInCompareList: false,
            }));
        }
    }

    /**
     * Navigates user to compare list using the history
     */
    const onViewCompareListClicked = () => {
        history.push(routes.dashboard.compare);
    }

    /**
     * Adds the current part to the comparison list of the user.
     * @return {Promise<void>}
     */
    const addToCompareList = async () => {
        const response = await api({
            url: partCompareApis.addPart,
            method: apiMethods.post,
            data: {
                partId: data?.id,
            },
        })
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            setData(prevState => ({
                ...prevState,
                isInCompareList: true,
            }));
            ToastService.success({
                title: "Part Added to Compare List",
                message: `${data.partNo} was added to your compare list `,
                buttons: [
                    {
                        onClick: () => onViewCompareListClicked(),
                        children: "View Compare List"
                    }
                ]
            });
        }
    }

    /**
     * Changes the isSaved value of the part in the server. Parts that are saved can then be fetched separately in
     * saved items view.
     * @return {Promise<void>}
     */
    const toggleBookmarked = async () => {
        setBookmarking(true);
        const {response} = await controller({
            response: {
                url: partApis.toggleBookmark,
                params: {
                    Data: {
                        PartID: data?.partNo,
                    }
                }
            }
        });
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        setBookmarking(false);
        if (response?.Code === 100) {
            setData(prevState => ({
                ...prevState,
                isSaved: !prevState.isSaved,
            }))
        } else {
            toast.error(response?.Message ?? '', {type: ToastTypes.error});
        }
    }

    /**
     * Changes the active index of the image based on the given offset.
     * @param {number} offset
     */
    const changeImage = (offset) => {
        const length = data?.images?.length ?? 0;
        let newActiveIndex = activeIndex + offset;
        if (newActiveIndex >= length) {
            newActiveIndex %= length;
        }
        if (newActiveIndex < 0) {
            newActiveIndex = length + newActiveIndex;
        }
        sliderRef.current.slickGoTo(newActiveIndex);
        setActiveIndex(newActiveIndex)
    }

    return (
        <>
            <div className="part-information-image-section">
                {
                    activeIndex !== -1 && data?.images?.length > 0 &&
                    <>
                        {
                            activeIndexImageErrored
                                ? <LazyImage height={350} className={'no-image'}/>
                                : imageRect.width > 0 && imageRect.height > 0 &&
                                <ReactImageMagnify
                                    className={'zoom-image'}
                                    smallImage={{
                                        isFluidWidth: true,
                                        src: activeIndexImageErrored
                                            ? NoImage
                                            : data?.images[activeIndex],
                                    }}
                                    enlargedImagePosition={['xs', 'sm', 'md'].includes(viewportWidth) ? 'over' : 'beside'}
                                    largeImage={{
                                        src: activeIndexImageErrored
                                            ? NoImage
                                            : data?.images[activeIndex],
                                        width: imageRect.width,
                                        height: imageRect.height,
                                    }}
                                    lensStyle={{
                                        borderRadius: '10px',
                                    }}
                                    isEnlargedImagePortalEnabledForTouch
                                    shouldUsePositiveSpaceLens
                                    enlargedImageContainerClassName={'part-zoom-image-container'}
                                    enlargedImageClassName={'part-zoom-image'}
                                />
                        }
                    </>
                }
                {
                    isLoggedIn &&
                    <div className={'part-information-compare-bookmark'}>
                        <button
                            className={classnames('button icon part-info-action-button mr-2', {'selected': data?.isInCompareList})}
                            onClick={toggleInCompareList}>
                            {
                                comparing
                                    ? <i className={'icon-loader'}/>
                                    : <SvgIcon component={CompareOutLinedIcon}/>
                            }
                        </button>
                        <button
                            className={classnames('button icon part-info-action-button', {'selected': data?.isSaved})}
                            onClick={toggleBookmarked}>
                            {
                                bookmarking
                                    ? <i className={'icon-loader'}/> :
                                    <BookmarkBorderOutlined className={'color'}/>
                            }
                        </button>
                    </div>
                }
            </div>
            {
                ((data?.images?.length ?? 0) > 1) &&
                <div className={'w-100 d-flex flex-row justify-content-center align-items-center part-image-slider'}>
                    {
                        ((data?.images?.length ?? 0) > 4) &&
                        <div
                            className={'part-images-icon'}
                            onClick={() => changeImage(-1)}
                        >
                            <PrevIcon/>
                        </div>
                    }
                    <div className={'w-90'}>
                        <Slider
                            swipeToSlide={false}
                            slidesToScroll={1}
                            focusOnSelect={true}
                            infinite={true}
                            arrows={false}
                            draggable={false}
                            slidesToShow={data?.images?.length > 4 ? 4 : data?.images?.length ?? 0}
                            ref={sliderRef}
                            className={'part-image-slider-item'}
                        >
                            {
                                data?.images?.map((item, index) => (
                                    <div key={item} className={'image-wrapper'}
                                         onClick={() => changeImage(index - activeIndex)}
                                    >
                                        <div
                                            className={classnames('img', {'active': index === activeIndex})}
                                        >
                                            <LazyImage src={item} alt=""/>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                    {
                        data.images?.length > 4 &&
                        <div
                            className={'part-images-icon'}
                            onClick={() => changeImage(1)}
                        >
                            <NextIcon/>
                        </div>
                    }
                </div>
            }
            {
                data?.images?.length >= 1 &&
                <div className="part-information-images-warning mt-4">
                    <AlertIcon/>
                    All images are for illustration purposes only, final
                    products may differ in appearance and specification and
                    subject to change. please contact us for confirmation.
                </div>
            }
        </>
    )
}

export default PartInformationViewImagesSection;
