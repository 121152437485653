import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "reactstrap";
import {partApis, productsApis} from "../../../../core/constants/endpoints/endpoints";
import api from "../../../../core/services/api/api";
import Utils from "../../../../core/services/utils/utils";
import routes from "../../../routes";
import PartInformationViewContentSection from "./sections/content";
import PartInformationViewImagesSection from "./sections/images";
import PartInformationViewPropertiesSection from "./sections/properties";
import PartInformationViewExtrasSection from "./sections/extras";
import {ReactComponent as ExpiredIcon} from "../../../../assets/images/art-likes/public-link-expired.svg";
import classnames from "classnames";
import CacheService from "../../../../core/services/cache/cache-service";
import {apiMethods} from "../../../../core/constants/enums";
import FloatingContainer from "../../../components/app-specific/floating-container";
import useRouter from "../../../hooks/use-router";
import useIsMounted from "../../../hooks/use-is-mounted";
import {useRouteMatch} from "react-router-dom";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";

const PartInformationView = () => {
    const {params, history} = useRouter();
    const [activeImageIndex, setActiveImageIndex] = useState(-1);
    const [isLoggedIn] = useState(CacheService.isLoggedIn());
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState({});
    const [extraPartInfo, setExtraPartInfo] = useState({});
    const [fetchedSecondPart, setFetchedSecondPart] = useState(false);
    const isMounted = useIsMounted();
    const scrollerRef = useRef(fetchedSecondPart);

    const fromPublicLink = !!useRouteMatch({
        path: routes.publicLinks.partInformation,
        exact: true
    })

    const fromPublicProducts = !!useRouteMatch({
        path: routes.public.partInformation,
        exact: true,
    })

    /**
     * With each change in the fetchedSecondPart flag:
     * - changes the scrollerRef as well.
     */
    useLayoutEffect(() => {
        scrollerRef.current = fetchedSecondPart;
    }, [fetchedSecondPart])

    /**
     * As soon as the component mounts:
     * - attaches an event listener to window's scrolling effect that will determine if the extra information of the
     * aprt needs to be fetched or not.
     */
    useEffect(() => {
        window.addEventListener('scroll', onWindowScrolled)
        return () => {
            window.removeEventListener('scroll', onWindowScrolled)
        }
    }, [])

    /**
     * Listens to the changes in id of the url parameters and with each change:
     * - if id does not exist, replaces the route with the homeView
     * - else, fetches the part information again.
     */
    useEffect(() => {
        if (!params?.id) {
            return history.replace(routes.landing);
        }
        window.scroll(0, 0)
        setFetchedSecondPart(false);
        scrollerRef.current = false;
        getPartInformation().then();
    }, [params?.id])

    /**
     * Fetches the part information of the provided part number. This method will call different apis depending on
     * the auth state of the user and the url of the website.
     *
     * - if the user has come using the public products view, then the public part information is fetched from the
     * server to be shown.
     *
     * - if the user has come using a public link, then if they are logged in, we will validate the share link and
     * will fetch the part info using the getPartInfo url (which is the normal way), but if they are not logged in,
     * then we will fetch the part information using getPartInfoUsingLink api
     *
     * - if the user has come to this view from using the website and they are logged in, then just fetches the part
     * information without any extra work.
     *
     * @return {Promise<void>}
     */
    const getPartInformation = async () => {
        setLoading(true);
        if (!!fromPublicProducts) {
            return await getPublicPartInformation();
        }
        if (!!fromPublicLink && isLoggedIn) {
            // make sure to validate the share link since we are logged in
            await validateShareLinkForLoggedInUser()
            if (!isMounted())
                return;
        }
        if (!fromPublicLink) {
            // we are already logged in and got here by using the website
            return await getLoggedInPartInformation();
        }
        // we are using the public link to see the part info.
        return await getPublicLinkPartInformation();
    }

    /**
     * Fetches the part information for a public user without any credentials and sets the data state.
     * @return {Promise<void>}
     */
    const getPublicPartInformation = async () => {
        const response = await api({
            url: productsApis.getPublicInformation(params.id),
            method: apiMethods.get,
            loginRequired: false,
        })
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            const images = [response?.configuration?.PartImageURL?.concat(response?.data?.coverImage ?? '')]
            if (images?.length > 0) {
                setActiveImageIndex(0)
            }
            setData({
                ...(response?.data ?? {}),
                partNo: response?.data?.partNumber ?? '',
                properties: response?.data?.technicalInformation?.map(e => ({
                    ...e,
                    title: e.property,
                })) ?? [],
                technicalFiles: response?.data?.technicalFiles?.map((e, i) => ({
                    ...e,
                    id: e.id ?? i + 1,
                    fileAddress: response?.configuration?.ParTechnicalFileBaseAddress?.concat('/', e.fileName ?? '') ?? '',
                    type: {
                        id: e.typeId,
                        name: e.typeName,
                    },
                })),
                notes: response?.data?.notes?.map((e, i) => ({
                    ...e,
                    id: e.id ?? i + 1,
                })),
                mainCategory: response?.data?.category,
                firstChildCategory: response?.data?.category?.parent,
                secondChildCategory: response?.data?.category?.parent?.parent,
                images: images,
            })
        } else {
            setData({});
        }
        return setLoading(false);
    }

    /**
     * Validates the given public share link for a logged-in user to submit a reporting point that the public link
     * is used.
     * @return {Promise<void>}
     */
    const validateShareLinkForLoggedInUser = async () => {
        await api({
            url: partApis.validateShareLink(params.id, params.publicToken),
            method: apiMethods.get,
        });
    }

    /**
     * Fetches the part information with the public link token of a public user and sets the data stsate.
     * @return {Promise<void>}
     */
    const getPublicLinkPartInformation = async () => {
        const response = await api({
            url: partApis.getPartInfoUsingLink(params.id, params.publicToken),
            method: apiMethods.get,
            loginRequired: false,
        })
        if (!isMounted()) return;
        if (response?.resultFlag) {
            const images = response?.data?.gallery?.sort((a, b) => a.orderNumber - b.orderNumber)?.map(e => e.url) ?? [];
            if (images?.length > 0) {
                setActiveImageIndex(0)
            }
            setData({
                ...(response?.data ?? {}),
                properties: response?.data?.technicalInfo ?? [],
                mainCategory: response?.data?.category,
                firstChildCategory: response?.data?.category?.parent,
                secondChildCategory: response?.data?.category?.parent?.parent,
                images: images,
            })
        } else {
            setData(null);
        }
        setLoading(false);
    }

    /**
     * Fetches the part information of a logged-in user from the server and sets the data state.
     * @return {Promise<void>}
     */
    const getLoggedInPartInformation = async () => {
        const response = await api({
            url: partApis.getPartInfo(params.id, history.location?.state?.searchToken),
            method: apiMethods.get,
        })
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setLoading(false);
            return;
        }
        if (response?.resultFlag) {
            const images = response?.data?.gallery?.sort((a, b) => a.orderNumber - b.orderNumber)?.map(e => e.url) ?? [];
            if (images?.length > 0) {
                setActiveImageIndex(0)
            }
            setData({
                ...(response?.data ?? {}),
                properties: response?.data?.technicalInfo ?? [],
                technicalFiles: response?.data?.technicalFiles ?? [],
                mainCategory: response?.data?.category,
                firstChildCategory: response?.data?.category?.parent,
                secondChildCategory: response?.data?.category?.parent?.parent,
                images: images,
            })
        } else {
            setData({});
        }
        return setLoading(false);
    }


    /**
     * Listens for the changes in the window viewport as it is being scrolled and with each change:
     * if the user is logged in and the end of the first section has come to view, then loads the part extra
     * information.
     */
    const onWindowScrolled = async () => {
        if (!isLoggedIn || fetchedSecondPart || scrollerRef.current) return;
        const element = document.getElementById('loader');
        if (!element) return;
        if (!Utils.isScrolledIntoView(element)) return;
        setFetchedSecondPart(true);
        await getPartExtraInformation();
    }

    /**
     * Fetches the extra information of the part from the server.
     * @return {Promise<void>}
     */
    const getPartExtraInformation = async () => {
        const response = await api({
            url: partApis.getPartSecondPart(params.id),
            method: apiMethods.get,
        });
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        setExtraPartInfo(response?.data ?? {});
    }


    return (
        <div className={'product-information-view'}>
            <main className="mb-5">
                <Container className={classnames('w-100')}>
                    <Row>
                        <Col xs={12}>
                            <FloatingContainer setHeight>
                                {
                                    !data && !!fromPublicLink
                                        ? <Container className={'public-link-expired'}>
                                            <Row className='w-100 justify-content-center'>
                                                <ExpiredIcon/>
                                            </Row>
                                        </Container>
                                        : loading
                                            ? <Row>
                                                <Col xs={12} className={'text-center'}>
                                                    <LoadingIndicator/>
                                                </Col>
                                            </Row>
                                            : <Row className={'mx-5'}>
                                                <Col lg={6} xs={12}>
                                                    <PartInformationViewImagesSection
                                                        data={data}
                                                        setData={setData}
                                                        activeIndex={activeImageIndex}
                                                        setActiveIndex={setActiveImageIndex}
                                                    />
                                                </Col>
                                                <Col lg={6} xs={12} className="mt-5 mt-lg-0">
                                                    <div className="content-section">
                                                        <PartInformationViewContentSection
                                                            data={data}
                                                            setData={setData}
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                }
                            </FloatingContainer>
                        </Col>
                        {
                            !!data && !loading &&
                            <Col xs={12}>
                                <PartInformationViewPropertiesSection data={data}/>
                            </Col>
                        }
                    </Row>
                </Container>
            </main>
            {
                !!data && isLoggedIn &&
                <PartInformationViewExtrasSection data={extraPartInfo} getComments={getPartExtraInformation}/>
            }
            {!isLoggedIn && <div className={'py-5'}/>}
        </div>
    );
}

export default PartInformationView;
