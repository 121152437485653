import React, {useContext, useMemo, useState} from "react";
import {Col, Dropdown, DropdownItem, DropdownMenu, Row} from "reactstrap";
import {DCEEntryNames} from "../../../../routes";
import {
    DynamicContentEditorEntryRecord,
    DynamicContentEditorListEntry,
    DynamicContentEditorModeContext,
    DynamicContentEditorModels
} from "../../../../../packages/dynamic-content-editor/index";
import classnames from "classnames";

const ProductCatalogues = () => {
    const [catalogues, setCatalogues] = useState([]);
    const [activeCatalogueId, setActiveCatalogueId] = useState();
    const dceMode = useContext(DynamicContentEditorModeContext);
    const isDceInEditMode = useMemo(() => dceMode === DynamicContentEditorModels.DynamicContentEditorMode.edit, [dceMode]);


    const selectedCatalogue = useMemo(() => {
        if (!catalogues.length)
            return undefined;
        if (!activeCatalogueId) {
            return catalogues[0];
        }
        return catalogues.find(e => e.id === activeCatalogueId) ?? catalogues[0];
    }, [activeCatalogueId, catalogues])

    /**
     * Sets the elected catalogue as the active catalogue in the view so that its preview can also be shown.
     * @param {string} id
     * @return {boolean}
     */
    const selectCatalogue = (id) => {
        setActiveCatalogueId(id);
        return false;
    };

    /**
     * Sets the catalogues list in the state.
     * @param catalogues
     */
    const getCatalogues = (catalogues = []) => {
        setCatalogues(catalogues?.map(e => ({
            ...e,
            id: e[DynamicContentEditorModels.DynamicContentEditorListEntryItemIdentifierKey],
        })) ?? []);
    }

    return (
        <>
            <div className="products-catalogues" id="ProductsCatalogues">
                <Row>
                    <Col xs={12} lg={3}>
                        <Dropdown isOpen={true} toggle={() => ''}>
                            <div className={'title'}>
                                Catalogues
                            </div>
                            <DynamicContentEditorListEntry
                                includeIdentifier
                                _title={'About us catalogues'}
                                entryName={DCEEntryNames.aboutUsCatalogues}
                                dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.pageBased}
                                iconPosition={DynamicContentEditorModels.DynamicContentEditorEntryIconPositions.outward}
                                getData={getCatalogues}
                                tag={DropdownMenu}
                                childTag={'div'}
                            >
                                <DynamicContentEditorEntryRecord
                                    recordKey={'title'}
                                    _title={'Catalogue Title'}
                                    tag={'p'}
                                    className={'d-none'}
                                    valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                />
                                <DynamicContentEditorEntryRecord
                                    recordKey={'url'}
                                    _title={'Catalogue Url'}
                                    tag={'p'}
                                    className={'d-none'}
                                    valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.pdf}
                                />
                                <DynamicContentEditorEntryRecord
                                    valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.element}
                                >
                                    {(item) => (
                                        <DropdownItem
                                            active={item[DynamicContentEditorModels.DynamicContentEditorListEntryItemIdentifierKey] === selectedCatalogue?.id && !!selectedCatalogue?.id}
                                            disabled={!Object.keys(item).length}
                                            onClick={() => selectCatalogue(item[DynamicContentEditorModels.DynamicContentEditorListEntryItemIdentifierKey])}
                                            className={classnames({
                                                'mb-5': isDceInEditMode,
                                            })}
                                        >
                                            {
                                                !Object.keys(item).length
                                                    ? "Click on + icon"
                                                    : item.title ?? ''
                                            }
                                        </DropdownItem>
                                    )}
                                </DynamicContentEditorEntryRecord>
                            </DynamicContentEditorListEntry>
                        </Dropdown>
                    </Col>
                    <Col xs={12} lg={9}>
                        {
                            selectedCatalogue &&
                            <div>
                                <div className="d-flex flex-wrap justify-content-between align-items-center mb-4">
                                    <h6>
                                        {selectedCatalogue?.title ?? ''}
                                    </h6>
                                    {
                                        selectedCatalogue?.url &&
                                        <a
                                            href={selectedCatalogue?.url}
                                            target="_blank"
                                            className="button primary px-5"
                                        >
                                            Download
                                        </a>
                                    }
                                </div>
                                {
                                    selectedCatalogue?.url &&
                                    <iframe
                                        title={'preview'}
                                        style={{borderRadius: '15px'}}
                                        src={selectedCatalogue?.url}
                                        width="100%"
                                        height="1190"
                                        frameBorder="0"
                                    />
                                }
                            </div>
                        }
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ProductCatalogues;
