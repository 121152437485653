/** api methods */
export const apiMethods = {
    get: 'GET' as const,
    post: 'POST' as const,
    put: 'PUT' as const,
    delete: 'DELETE' as const,
    patch: 'PATCH' as const,
};

/**
 * Types that are supported in the technical files section of the application.
 */
export const TechnicalFileTypes = {
    HTML: 4 as const,
    PDF: 2 as const,
    IMAGE: 5 as const,
    unknown: 1 as const,
}

/**
 * The access level tokens of the users that would determine in which parts of the system the user can access.
 */
export const UserAccessLevels = {
    purchase: '12255197-479E-4F92-B1B8-980167E416F3' as const,
    payment: 'D59004BD-33F9-4804-B080-F90478206A36' as const,
    lookup: '95A2F1EF-559D-4695-8491-F64BA5B5652B' as const,
    warrantyAndReturn: '060AD386-A7C3-48F9-8F6A-7D5978A84594' as const,
}

/**
 * The Card types that the application supports and send with the credit card apis.
 */
export const CreditCardTypes = {
    masterCard: 'M' as const,
    visa: 'V' as const,
    americanExpress: 'AX' as const,
    novusDiscover: 'NO' as const,
    debit: 'D' as const,
    jcb: 'C1' as const,
    sears: 'SE' as const,
    giftCard: 'GIFT_CARD' as const,
    gradient: 'GRADIENT' as const,
    unknown: 'UNKNOWN' as const,
}

/**
 * The name of the cards that are supported in react-credit-card library by default.
 */
export const CreditCardTypeNames = {
    M: 'masterCard' as const,
    V: 'visa' as const,
    AX: 'amex' as const,
    NO: 'discover' as const,
    D: 'debit' as const,
    C1: 'jcb' as const,
    SE: 'sears' as const,
    GRADIENT: 'gradient' as const,
    GIFT_CARD: 'gift-card' as const,
    UNKNOWN: 'unknown' as const,
}

/**
 * Servers transaction types that are used in user orders.
 */
export const TransactionTypes = {
    giftCard: 'GiftCard' as const,
    userOrder: 'UserOrder' as const,
    creditMemo: 'CreditMemo' as const,
    payment: 'OnlinePayment' as const,
    cashOrChequePayment: 'OtherPayments' as const,
}

/**
 * The address types in the system.
 */
export const AddressTypes = {
    shipping: {id: 1, title: 'Shipping'} as const,
    billing: {id: 2, title: 'Billing'} as const,
}

/**
 * The three available header types in the system.
 */
export const HeaderTypes = {
    loggedIn: 'logged-in' as const,
    loggedOut: 'logged-out' as const,
    auth: 'authentication' as const,
}

/**
 * Provides constants that would determine the type of the file we are about to read.
 */
export const ReadingFileAs = {
    binaryString: 'binaryString' as const,
    dataUrl: 'dataUrl' as const,
    text: 'text' as const,
    arrayBuffer: 'arrayBuffer' as const,
}

/**
 * The different types that exist for the gift cards in the system.
 */
export const GiftCardTypes = {
    refillable: {id: 2, name: 'Refillable Gift Card'} as const,
    giftCard: {id: 1, name: 'Gift Card'} as const,
}


/**
 * The Alignments of the table cells
 */
export const TableCellAlignments = {
    left: 'left' as const,
    right: 'right' as const,
    center: 'center' as const,
}

/**
 * The different Types of the table cells
 */
export const TableCellTypes = {
    date: 'date' as const,
    dateTime: 'dateTime' as const,
    string: 'string' as const,
    money: 'money' as const,
    element: 'element' as const,
}

/**
 * The default page sizes of the table pagination
 */
export const DefaultPageSizes = [
    10 as const,
    20 as const,
    50 as const,
    100 as const,
    500 as const,
];

/**
 * The default animation properties of the table row items
 */
export const DefaultTableRowAnimationProps = {
    timeout: 500 as const,
    classNames: 'shrink' as const,
}

/**
 * Different alert types used in the application (the alerts are bootstraps)
 */
export const AlertTypes = {
    success: "success" as const,
    warning: "warning" as const,
    error: "danger" as const,
}

/**
 * Different toast types used in the application
 */
export const ToastTypes = {
    success: "success" as const,
    warning: "warning" as const,
    info: "info" as const,
    error: "error" as const,
    default: "default" as const,
}

/**
 * The default options to parse or stringify query strings.
 */
export const QueryStringDefaultParseOptions = {
    arrayFormat: 'bracket-separator' as const,
    arrayFormatSeparator: '|' as const,
}

/**
 * The different types that the user can search for products in the application.
 */
export const ProductsSearchTypes = {
    basic: 'Normal' as const,
    application: 'Application' as const,
    technical: 'TechnicalInfo' as const,
    elasticTechnical: 'ElasticTechnical' as const,
    categories: 'Categories' as const,
    tags: 'Tags' as const,
}

/**
 * The available property types in technical search.
 */
export const TechnicalPropertyTypes = {
    input: 'Float' as const,
    dropdown: 'List' as const,
    switch: 'Bit' as const,
}

/**
 * The two types of the ContentMainEitherView that is used to search for that specific type.
 */
export const ContentMainEitherViewTypes = {
    video: 'videos' as const,
    blog: 'blogs' as const,
}

/**
 * The upload state to indicate the progress of uploading a document
 */
export const UploadStates = {
    error: 'error' as const,
    pending: 'pending' as const,
    done: 'done' as const,
}

/**
 * The available Tabs in order views.
 */
export const OrderViewTabs = {
    quotes: {id: 'quotes', title: "Quotes"} as const,
    invoices: {id: 'invoices', title: "Invoices"} as const,
    credits: {id: 'credits', title: "Credits"} as const,
}

/**
 * The available order by options specific to orders view.
 */
export const OrderViewOrderBys = {
    orders: [
        {id: 'orderNo', label: 'Order no'} as const,
        {id: 'orderDDate', label: 'Order date'} as const,
        {id: 'customerPO', label: 'PO number'} as const,
        {id: 'totalOrdered', label: 'Total'} as const,
    ],
    invoices: [
        {id: 'invoiceNo', label: 'Invoice no'} as const,
        {id: 'invoiceDate', label: 'Invoice date'} as const,
        {id: 'customerPO', label: 'PO number'} as const,
        {id: 'total', label: 'Total'} as const,
    ],
}

/**
 * Different types of file types the file-fetcher of browser accepts
 */
export const InputFileAcceptTypes = {
    png: '.png' as const,
    jpg: '.jpg' as const,
    jpeg: '.jpeg' as const,
    mp4: '.mp4' as const,
    mov: '.mov' as const,
    webm: '.webm' as const,
    flv: '.flv' as const,
    wmv: '.wmv' as const,
    pdf: '.pdf' as const,
    csv: '.csv' as const,
    spreadsheet: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' as const,
    excel: 'application/vnd.ms-excel' as const,
    numbers: '.numbers' as const,
    zip: '.zip' as const,
    rar: '.rar' as const,
}

/**
 * Different File Mimes used while choosing the documents in user system
 */
export const InputFileAcceptMimes = {
    images: 'image/*' as const,
    videos: 'video/mp4,video/x-m4v,video/*' as const,
    audios: 'audio/*' as const,
}


/**
 * The process steps that exist in the shopping cart of the user,
 */
export const ShoppingCartProcessSteps = {
    items: "items" as const,
    delayedItems: "delayed-items" as const,
    payment: "payment" as const,
    accountSummary: 'account-summary' as const,
    cardSelection: "card-selection" as const,
    success: "success" as const,
}


/**
 * The process steps that exist in the multi invoice payment of the user,
 */
export const MultiInvoicePaymentProcessSteps = {
    credits: "credits" as const,
    payment: "payment" as const,
    cardSelection: "card-selection" as const,
    success: "success" as const,
}

/**
 * The process steps that exist in the user payments section
 */
export const UserPaymentsProcessSteps = {
    payment: 'payment' as const,
    cardSelection: 'card-selection' as const,
    success: 'success' as const,
}

/**
 * The different types of the cart item box. These different types directly change the actions shown in the box.
 */
export const ShoppingCartItemBoxTypes = {
    normal: 'normal' as const,
    delayed: 'delayed' as const,
}

/**
 * The injected property names of the shopping cart DS.
 */
export const ShoppingCartInjectedPropertyNames = {
    item: {
        updating: 'updating' as const,
        removing: 'removing' as const,
        newQuantity: 'newQuantity' as const,
    },
    transactions: {
        loading: 'loading' as const,
        extra: 'extra',
    },
    delayedItems: 'delayedItems' as const,
    note: 'note' as const,
}

/**
 * The payments terms of the users in the application.
 */
export const PaymentTerms = {
    COD: "COD" as const,
}

/**
 * The types of the form that can be shown by this component.
 */
export const AuthenticationFormTypes = {
    login: 'login' as const,
    password: 'resetPassword' as const,
}

/**
 * Different type of registration content in the landing page
 */
export const LandingPageFormTypes = {
    login: "login" as const,
    registration: "registration" as const,
    forgetPassword: "forgettpassword" as const,
}

/**
 * The different components in the home page view.
 */
export const HomeViewComponentTypes = {
    slideShow: 1 as const,
    category: 2 as const,
    largeProduct: 3 as const,
    mediumProduct: 4 as const,
    smallProduct: 5 as const,
    timeBased: 6 as const,
    banner: 7 as const,
}

/**
 * The predix used for all the technical properties of the application.
 */
export const TechnicalPropertyPrefix = "property-";

/**
 * The available types of product cards in the application.
 */
export const ProductBoxTypes = {
    small: 'small' as const,
    medium: 'medium' as const,
    large: 'large' as const,
    list: 'list' as const,
}

/**
 * Home view components' search query types. This enum is used to determine which search engine to be used when
 * searching for products.
 */
export const HomeViewComponentSearchQueryTypes = {
    application: "Application" as const,
    basic: "Basic" as const,
    technical: "Technical" as const,
    elasticTechnical: 'ElasticTechnical' as const,
}

/**
 * The different platforms of the homepage.
 */
export const HomepagePlatforms = {
    web: 1 as const,
    phone: 2 as const,
}

/**
 * The valid types of the sorting features.
 */
export const ValidSortTypes = {
    desc: 'desc' as const,
    asc: 'asc' as const,
    none: null,
}

/**
 * Different tables used in the print invoice table.
 */
export const PrintInvoiceViewTableTypes = {
    products: 'products' as const,
    notes: 'notes' as const,
}

/**
 * Different types of options that are available in the autocomplete input of the basic search.
 */
export const BasicSearchAutocompleteTypes = {
    tag: 'Tag',
    code: 'Code',
    partNo: 'PartNumber',
}

/**
 * Different types of orders by in elastic search
 */
export const elasticSearchOrderByTypes = {
    ascending: 'asc',
    descending: 'desc'
}

/**
 * The default pagination info of the application
 */
export const DefaultPaginationInfo = {
    pageSize: DefaultPageSizes[0],
    length: 0 as const,
    currentPage: 1 as const,
}

/**
 * The default pagination info of the application
 */
export const DefaultInitialPaginationInfo = {
    ...DefaultPaginationInfo,
    currentPage: -1 as const,
}

/**
 * Different types for handling the pagination
 */
export const HandlePaginationType = {
    tablePagination: 'tablePagination',
    scrollPagination: 'scrollPagination',
}
