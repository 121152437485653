import useRouter from "../../../../hooks/use-router";
import routes, {routeFunctions} from "../../../../routes";
import LazyImage from "../../lazy-lmage";
import {Col} from "reactstrap";
import moment from "moment";
import {htmlToString} from "../../../../../core/services/utils/utils";
import React from "react";
import {Link} from 'react-router-dom';
import ContentShareButtons from "../../content-share-buttons";


const BlogCard = ({blog, secondVersion}) => {
    const {history} = useRouter();

    const link = !!blog
        ? window.location.origin + routeFunctions.public.blogs.blog(blog?.slug)
        : window.location.origin + routes.public.content.main;
    const internalLink = !!blog
        ? routeFunctions.public.blogs.blog(blog?.slug)
        : routes.public.content.main;

    /**
     * Navigates the user to the page of that specified video.
     */
    const navigateToSingle = () => {
        history.push(routeFunctions.public.blogs.blog(blog?.slug))
    }

    if (secondVersion) {
        return (
            <Link to={internalLink}
                  className={' d-flex flex-column w-100 blog-card-2 mb-2 p-2 h-100'}>
                <div className={'d-flex w-100 justify-content-between'}>
                    <Col className={'d-flex flex-column p-0'}>
                        <div className={'title p-2 text-wrap cursor-pointer-hover'} onClick={navigateToSingle}>
                            {blog.title}
                        </div>
                        <Col className={'text-wrap d-flex flex-wrap pl-2'}>
                            <div
                                className={'description'}
                                dangerouslySetInnerHTML={{__html: htmlToString(blog?.bodyContent ?? '', 50)}}
                            />
                        </Col>
                    </Col>
                    <div className={'image'}>
                        <LazyImage src={blog.coverImage?.srcUrl} alt={blog.slug}/>
                    </div>
                </div>
                <div className={'w-100 my-1 flex-fill'}/>
                <div className={' d-flex justify-content-between align-items-center'}>
                    <ContentShareButtons
                        tag={Col}
                        className={'small mt-2 d-flex align-items-center flex-grow-1'}
                        title={blog?.title}
                        slug={blog?.slug}
                        link={link}
                    />
                </div>
            </Link>
        )
    }

    return (
        <div
            className={'d-flex flex-column w-100 blog-card mb-2 h-100 cursor-pointer-hover'}
            onClick={navigateToSingle}
        >
            <div className={'image'}>
                <LazyImage src={blog.coverImage?.srcUrl} alt={blog.slug}/>
            </div>
            <div className={'w-100 my-1'}/>
            <Col className={'title text-wrap cursor-pointer-hover'} onClick={navigateToSingle}>
                {blog.title}
            </Col>
            <div className={'w-100 mt-1'}/>
            <Col className={'sub-title cursor-pointer-hover'}>
                {blog?.lastModifyDateTime ? moment(blog?.lastModifyDateTime).format('MMM DD, YYYY') : ''}
                <span className={'mx-2'}>/</span>
                {blog.category?.title ?? ''}
            </Col>
            <Col className={'text-wrap d-flex flex-wrap mt-1'}>
                <div
                    className={'description'}
                    dangerouslySetInnerHTML={{__html: htmlToString(blog?.bodyContent ?? '', 100)}}
                />
            </Col>
            <div className={' d-flex justify-content-between align-items-center mt-2 '}>
                <ContentShareButtons
                    tag={Col}
                    className={'small d-flex align-items-center flex-grow-1'}
                    title={blog?.title}
                    slug={blog?.slug}
                    link={link}
                />
            </div>
            <div className={'w-100 mt-2 mb-1'}/>
        </div>
    );
}

export default BlogCard;
