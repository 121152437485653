import React, {useContext, useEffect, useMemo, useRef} from "react";
import {ReactComponent as BackIcon} from "../../../assets/images/back.svg";
import useIsMounted from "../../hooks/use-is-mounted";
import {useHistory} from "react-router-dom";
import {DynamicContentEditorStateContext} from "../../contexts";

const DynamicContentEditorIframe = ({setLoading}) => {
    /**@type {React.MutableRefObject<HTMLIFrameElement>}*/
    const iframeRef = useRef();
    const history = useHistory();
    const isMounted = useIsMounted();
    const state = useContext(DynamicContentEditorStateContext);

    /**
     * With each change in the apiConfig of this component (view mode):
     * - sets the api config of the iframe through the DCE communicator to sync the url values for the iframe to call.
     */
    useEffect(() => {
        setTimeout(() => {
            try {
                iframeRef.current.contentWindow.DCECommunicator?.apiConfigurationSetter?.(state.apiConfiguration);
            } catch {
            }
        }, 1000)
    }, [state.apiConfiguration])


    /**
     * As soon as the iframe content is loaded:
     * - Sets the loading of the DCE to false
     * - attaches an event listener for when the window of the iframe content is unloaded, so the loading can then
     * be set to true.
     */
    const onIframeLoaded = () => {
        if (!isMounted())
            return;
        setLoading(false);
        try {
            iframeRef.current.contentWindow.onunload = () => {
                if (!isMounted())
                    return;
                setLoading(true);
            }
        } catch {
        }
    }

    /**
     * Exists the iframe by navigating back all the way to a location prior to the route of the DCE. (the referrer
     * location).
     */
    const exitIframe = () => {
        history.go(-(window.DCECommunicator.historyStackLength + 1));
    }

    const content = useMemo(() => (
        <>
            <iframe
                key={'dce-iframe-stable'}
                onLoad={onIframeLoaded}
                ref={iframeRef}
                src={window.location.origin}
                title={`dynamic-content-editor-of-${window.location.origin}`}
            />
            <button
                className={'dynamic-content-editor-icon-button back'}
                onClick={exitIframe}
            >
                <BackIcon/>
            </button>
        </>
    ), [iframeRef])

    return (
        <>
            {content}
        </>
    )
}

export default DynamicContentEditorIframe;
