/**
 * Houses all the unique ids used in the applications.
 */
class ApplicationIds {

    static readonly headerLoginFormEmailInput = 'header-login-form-email-input';

    static readonly printInvoiceViewIds = {
        page: 'page' as const,
        table: 'table' as const,
        pageHeader: 'page-header' as const,
        tablesDistance: 'tables-distance' as const,
        productsTable: {
            header: 'products-table-header' as const,
            body: 'products-table-body' as const,
            bodyFiller: 'products-table-body-filler' as const,
            footerContinue: 'products-table-footer-continue' as const,
            footer: 'products-table-footer' as const,
        },
        notesTable: {
            header: 'notes-table-header' as const,
            body: 'notes-table-body' as const,
            footerContinue: 'notes-table-footer-continue' as const,
        },
    }
}

export default ApplicationIds;
