import React, {useEffect, useMemo, useRef, useState} from "react";
import ObserversService from "../../../../../../core/services/observers-service";
import {debounce} from "lodash";


/**
 * Calculates the position of the lines and points on each line of the home-view pattern
 *
 * @param {number} width
 * @param {number} height
 * @param {number} lineWidth
 * @param {number} lineHeight
 * @param {number} widthSpacing
 * @param {number} heightSpacing
 * @return {{x: number, y: number}[][]}
 */
const getLinesAndTheirPoints = (width, height, lineWidth, lineHeight, widthSpacing, heightSpacing) => {
    const res = [];
    const numberOfLines = Math.floor(height / (lineHeight + heightSpacing))
    const [startingXMin, startingXMax] = [0, width / 5];

    for (let i = 0; i < numberOfLines; i++) {
        const y = i * (lineHeight + heightSpacing);
        const initialX = Math.floor(Math.random() * (startingXMax - startingXMin + 1) + startingXMin);

        let consumedWidth = initialX + lineWidth
        const remainingWidth = width - consumedWidth;

        const numberOfPoints = Math.floor((remainingWidth / (lineWidth + widthSpacing)) + 1)
        let newX = initialX + lineWidth + widthSpacing;
        res.push(Array(numberOfPoints)
            .fill(null)
            .map((_, i) => {
                if (i === 0)
                    return {x: initialX, y: y};
                const x = newX;
                consumedWidth += lineWidth + widthSpacing;
                newX = x + lineWidth + widthSpacing;
                // make the points to not appear 30% of the time
                if (i > 3 && i < numberOfPoints - 4 && Math.random() > 0.1)
                    return null
                else if (Math.random() > 0.7)
                    return null;
                return {x: x, y: y}
            })
            .filter(e => !!e)
        )
    }
    return res;
}


const HomeViewBizkeyTechPattern = ({
                                       height = 1000,
                                       lineHeight = 5,
                                       lineWidth = 18,
                                       widthSpacing = 100,
                                       heightSpacing = 80,
                                   }) => {
    const [width, setWidth] = useState(window.innerWidth ?? 0);
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const containerRef = useRef();

    const containerStyles = useMemo(() => ({
        '--height': `${height ?? 0}px`,
        '--line-height': `${lineHeight ?? 0}px`,
        '--line-width': `${lineWidth ?? 0}px`,
    }), [height, lineHeight, lineWidth])

    /**
     * With each change in the value of the [containerRef]:
     * - attaches a resize observer that observes the changes in the width of the container and sets the [width]
     * value of the state.
     */
    useEffect(() => {
        if (!containerRef.current)
            return;
        const observer = ObserversService.newResizeObserver(observeWidthChanges);
        ObserversService.observeResizeObserver(observer, containerRef.current);
        observeWidthChanges();
        return () => ObserversService.disconnectResizeObserver(observer);
    }, [containerRef])


    /**
     * Observes the changes in the width of the container and sets the [width] value of the state.
     * @type {ResizeObserverCallback}
     */
    const observeWidthChanges = debounce((entries) => {
        if (!containerRef.current)
            return;
        if (!entries) {
            // initial render
            return setWidth(containerRef.current.getBoundingClientRect().width ?? 0)
        }
        const entry = entries[0];
        return setWidth(entry.contentRect?.width ?? 0)
    }, 300)

    const lines = useMemo(() => {
        const res = [];
        const lines = getLinesAndTheirPoints(width, height, lineWidth, lineHeight, widthSpacing, heightSpacing);
        let i = 0;
        for (const line of lines) {
            let j = 0;
            for (const point of line) {
                res.push(
                    <div
                        key={`line-${i}-point-${j++}`}
                        style={{'--x': `${point.x}px`, '--y': `${point.y}px`}}
                        className={'home-view-pattern-point'}
                    />
                )
            }
            i++;
        }
        return res;
    }, [width, lineWidth, lineHeight, height, widthSpacing, heightSpacing])


    return (
        <>
            <div
                style={containerStyles}
                ref={containerRef}
                className={'home-view-bizkey-tech-pattern-container'}
            >
                <div className={`home-view-pattern-inner-container`}>
                    {lines}
                </div>
            </div>
        </>
    )
}

export default HomeViewBizkeyTechPattern;
