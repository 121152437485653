import React, {useState} from "react";
import useRouter from "../../../../hooks/use-router";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {useDispatch} from "react-redux";
import api from "../../../../../core/services/api/api";
import {quoteApis, shoppingCartApis} from "../../../../../core/constants/endpoints/endpoints";
import {apiMethods} from "../../../../../core/constants/enums";
import ApiResponseCodes from "../../../../../core/constants/api-response-codes";
import {toast} from "react-toastify";
import routes from "../../../../routes";
import {confirmationDialog} from "../../../../../redux/entities/dialogs/actions";
import classnames from "classnames";
import {Col, Collapse} from "reactstrap";
import moment from "moment";
import {formatMoney} from "../../../../../core/services/utils/utils";
import {ReactComponent as ExpandIcon} from "../../../../../assets/images/blogs/expand.svg";
import {Fade} from "@material-ui/core";
import EnvService from "../../../../../core/services/env-service";
import LoadingIndicator from "../../loading-indicator";

const QuoteBox = ({data}) => {
    const {history} = useRouter();
    const [expanded, setExpanded] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [openingQuote, setOpeningQuote] = useState(false);
    const [fullInfo, setFullInfo] = useState({});
    const isMounted = useIsMounted();
    const dispatch = useDispatch();

    /**
     * Fetches the full information of this quote from the server.
     *
     * if the resul of the api is successful, then sets the state information.
     * @return {Promise<void>}
     */
    const getFullInformation = async () => {
        setLoadingInfo(true);
        const response = await api({
            url: quoteApis.getFullDetails(data.id),
            method: apiMethods.get,
        })
        if (!isMounted()) return;
        setLoadingInfo(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        setFullInfo(response?.data ?? {});
    }

    /**
     * Opens this quote as the current shopping cart of the user.
     *
     * navigates to shopping cart if successful, otherwise, if there is an existing cart and permission is not
     * given, a dialog is shown to direct users' decision on what they want to do.
     * @param {boolean} replacePermission to indicate if the user wants to set this quote as the cart even if there
     * is already an existing cart.
     * @return {Promise<boolean>} a boolean to indicate the success of the api call
     */
    const openQuote = async (replacePermission = false) => {
        setOpeningQuote(true);
        const response = await api({
            url: shoppingCartApis.openQuote,
            method: apiMethods.post,
            data: {
                quoteID: data.id,
                replacePermission: replacePermission,
            },
            showError: false,
        })
        if (!isMounted()) return false;
        setOpeningQuote(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return false;
        if (response?.resultFlag) {
            navigateToShoppingCart();
        } else {
            if (response?.errorCode === ApiResponseCodes.existingCart) showDecisionDialog(response);
            else toast.error(`${response?.headerTitle ?? ''} : ${response?.message ?? ''}`);
        }
        return response?.resultFlag ?? false;
    }

    /**
     * Combines the selected quote of the user with their current shopping cart.
     *
     * if the result of the api is successful, navigates to the shopping cart.
     * @return {Promise<boolean>} a boolean to indicate the success of the api call
     * */
    const combineQuote = async () => {
        setOpeningQuote(true);
        const response = await api({
            url: shoppingCartApis.combineQuote(data.id),
            method: apiMethods.post,
            showSuccess: true,
        })
        if (!isMounted())
            return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setOpeningQuote(false);
            return false;
        }
        if (response?.resultFlag) navigateToShoppingCart();
        setOpeningQuote(false);
        return response?.resultFlag ?? false;
    }

    /**
     * Toggles the expanded state of this quote.
     *
     * if the quote gets expanded, then the api for fetching its full information is called.
     */
    const toggleExpanded = () => {
        setExpanded(prevState => {
            const val = !prevState;
            if (val) getFullInformation().then();
            return val;
        });
    }

    /**
     * Navigates the user to shopping cart view.
     */
    const navigateToShoppingCart = () => {
        history.push(routes.main.shoppingCart);
    }

    /**
     * Shows the appropriate decision dialog regarding the existing shopping cart after the user wanted to set
     * another quote as their current cart.
     * @param {any} response a CrudDS
     */
    const showDecisionDialog = (response) => {
        dispatch(confirmationDialog({
            open: true,
            title: "Existing Shopping Cart",
            description: response?.message ??
                "You already have an existing shopping cart. Would you like to set this" +
                " quote as the  current shopping cart or merge the items in the selected quote and your existing" +
                " cart?",
            callback: (accepted) => {
                if (!accepted) return openQuote(true);
                else return combineQuote();
            },
            cancelText: "Replace",
            proceedText: "Merge",
            mandatory: false,
        }))
    }

    return (
        <>
            <div className={classnames('order-box', {expanded})}>
                <div className={'d-flex justify-content-end pt-3'}>
                    <Fade in={EnvService.EnablePayments}>
                        <div>
                            <button
                                className={classnames('button text quote-shopping-cart-status-button',
                                    {'open': data?.isCurrentUserActiveQuote}
                                )}
                                onClick={() => data?.isCurrentUserActiveQuote ? navigateToShoppingCart() : openQuote()}
                                disabled={openingQuote}>
                                {
                                    openingQuote
                                        ? "processing..."
                                        : data?.isCurrentUserActiveQuote
                                            ? "Currently in shopping cart"
                                            : "Open in shopping cart"
                                }
                            </button>
                        </div>
                    </Fade>

                </div>
                <div className={'body'}>
                    <Col xs={2} lg={2} xl={2}>
                        Number
                    </Col>
                    <Col xs={2} lg={2} xl={2} className={'d-flex justify-content-end'}>
                        PO-Number
                    </Col>
                    <Col xs={2} lg={2} xl={2} className={'d-flex justify-content-end'}>
                        Date
                    </Col>
                    <Col xs={2} lg={2} xl={2} className={'d-flex justify-content-end'}>
                        Subtotal
                    </Col>
                    <Col xs={2} lg={2} xl={2} className={'d-flex justify-content-end'}>
                        Total
                    </Col>
                </div>
                <div className={'expanded-body'}>
                    <Col xs={2} md={2} lg={2} xl={2} className={'d-flex align-items-center pr-0'}>
                        {data?.orderNo ?? "--"}
                    </Col>
                    <Col xs={2} lg={2} xl={2} className={'d-flex justify-content-end align-items-center'}>
                        {data?.customerPO ?? "--"}
                    </Col>
                    <Col xs={2} lg={2} xl={2} className={'d-flex justify-content-end align-items-center'}>
                        {data?.orderDate ? moment(data?.orderDate).format('DD/MM/yyyy') : "--"}
                    </Col>
                    <Col xs={2} lg={2} xl={2} className={'d-flex justify-content-end align-items-center'}>
                        {data?.subTotal ? formatMoney(data?.subTotal) : '--'}
                    </Col>
                    <Col xs={2} lg={2} xl={2} className={'d-flex justify-content-end align-items-center'}>
                        {data?.total ? formatMoney(data?.total) : '--'}
                    </Col>
                    <div className={'d-flex justify-content-end flex-grow-1 align-items-center'}>
                        <div className={'mr-3'}>
                            <button className={'button text icon primary'}
                                    onClick={toggleExpanded}>
                                <ExpandIcon className={classnames('expand')}/>
                            </button>
                        </div>
                    </div>
                </div>
                <Collapse isOpen={expanded}>
                    <div className={'footer'}>
                        {
                            loadingInfo
                                ? <Col xs={12} className={'text-center'}>
                                    <LoadingIndicator size={100}/>
                                </Col>
                                : <>
                                    <div className={'d-flex flex-wrap'}>
                                        <Col xs={3} md={2} lg={3} xl={2}
                                             className={'d-flex align-items-center pr-0'}>
                                            Product
                                        </Col>
                                        <Col xs={3} lg={2} xl={3}
                                             className={'d-flex justify-content-end align-items-center'}>
                                            Quantity
                                        </Col>
                                        <Col xs={3} lg={2} xl={3}
                                             className={'d-flex justify-content-end align-items-center'}>
                                            Unit Price
                                        </Col>
                                    </div>
                                    {
                                        fullInfo?.items?.map(data => (
                                            <div key={data?.partNo ?? ''}
                                                 className={'d-flex flex-wrap black font-weight-500 mt-2 pt-1'}>
                                                <Col xs={3} md={2} lg={3} xl={2}
                                                     className={'d-flex align-items-center pr-0'}>
                                                    {data?.partNo ?? "--"}
                                                </Col>
                                                <Col xs={3} lg={2} xl={3}
                                                     className={'d-flex justify-content-end align-items-center'}>
                                                    {data?.orderQty ?? "--"}
                                                </Col>
                                                <Col xs={3} lg={2} xl={3}
                                                     className={'d-flex justify-content-end align-items-center'}>
                                                    {data?.unitPrice ? formatMoney(data.unitPrice) : '--'}
                                                </Col>
                                            </div>
                                        ))
                                    }
                                </>
                        }
                    </div>
                </Collapse>
            </div>
        </>
    );
}


export default QuoteBox;
