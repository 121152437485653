import React, {useEffect, useState} from 'react';
import {Col, Row} from "reactstrap";
import AddressBox from "../../../components/app-specific/address-box";
import {userAddressApis} from "../../../../core/constants/endpoints/endpoints";
import api from "../../../../core/services/api/api";
import {AddressTypes, apiMethods} from "../../../../core/constants/enums";
import useIsMounted from "../../../hooks/use-is-mounted";
import UpsertAddressDialog from "../../../components/dialogs/upsert-address-dialog";
import TryAgain from "../../../components/app-specific/try-again";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";


const initDialog = {address: null, open: false, type: null, duplicate: false}

const AddressManagementView = () => {
    const [addresses, setAddresses] = useState([]);
    const [dialog, setDialog] = useState(initDialog)
    const [loading, setLoading] = useState(true);
    const [countryAndState, setCountryAndState] = useState({countries: [], provinces: []})
    const isMounted = useIsMounted();

    const shippingAddresses = addresses?.filter(e => e.type.id === AddressTypes.shipping.id);
    const billingAddresses = addresses?.filter(e => e.type.id === AddressTypes.billing.id);

    /**
     * As soon as the component mounts:
     * - fetches the list of addresses from the server.
     */
    useEffect(() => {
        getAddresses();
    }, [])

    /**
     * Fetches the list of addresses and countries and provinces from the server.
     *
     * if the result of the api is successful, sets the list.
     */
    const getAddresses = (_setLoading) => {
        if (_setLoading) setLoading(true);
        api({
            url: userAddressApis.getAll,
            method: apiMethods.get,
        }).then((response) => {
            if (!isMounted()) return;
            setLoading(false);
            if (response?.isPreemptedDueToNotBeingLoggedIn)
                return;
            if (response?.resultFlag) {
                setAddresses(response?.data?.data?.map(e => ({
                    ...e,
                    removing: false,
                    updating: false,
                    settingDefault: false
                })) ?? [])
                setCountryAndState({
                    provinces: response?.data?.provinces ?? [],
                    countries: response?.data?.countries ?? [],
                })
            }
        });
    }

    /**
     * Sets address as default by calling the api form the server.
     *
     * if the result was successful, finds the previous default, un-assigns it, then assigns the isDefault to the
     * new Default address.
     * @param address {any}
     */
    const setAddressAsDefault = (address) => {
        setAddresses(prevState => prevState?.map(e => e.id === address.id ? {...e, settingDefault: true} : e))
        api({
            url: userAddressApis.setAsDefault(address.id),
            method: apiMethods.put,
            showSuccess: true,
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setAddresses(prevState => prevState?.map(e => e.id === address.id ? {...e, settingDefault: false} : e))
                return;
            }
            if (response?.resultFlag) {
                const previousDefault = addresses.find(e => e.type.id === address.type.id && e.isDefault);
                setAddresses(prevState => prevState?.map(e => e.id === previousDefault?.id ? {
                    ...e,
                    isDefault: false
                } : e.id === address.id ? {
                    ...address,
                    isDefault: true,
                } : e))
            }
            getAddresses();
            setAddresses(prevState => prevState?.map(e => e.id === address.id ? {...e, settingDefault: false} : e))
        })
    }

    /**
     * Adds an address to users' addresses in the server.
     *
     * if the result of the api is successful, adds the address in the list
     * @param {any} address
     * @return {Promise<boolean>}
     */
    const addAddress = async (address) => {
        const response = await api({
            url: userAddressApis.create,
            method: apiMethods.post,
            data: address,
        })
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return false;
        if (response?.resultFlag) {
            if (!address.isDefault) {
                getAddresses();
            } else {
                const previousDefault = addresses.find(e => e.type.id === address.type.id && e.isDefault);
                setAddresses(prevState => prevState?.map(e => e.id === previousDefault?.id ? {
                    ...e,
                    isDefault: false
                } : e))
                getAddresses();
            }
        }
        return response?.resultFlag ?? false;
    }

    /**
     * Edits the address information in the server.
     *
     * if the result of the api is successful, updates the address in the list
     * @param {any}     address
     * @param {boolean} forceGet
     * @return {Promise<boolean>}
     */
    const editAddress = async (address, forceGet = false) => {
        setAddresses(prevState => prevState?.map(e => e.id === address.id ? {...e, updating: true} : e))
        const response = await api({
            url: userAddressApis.update,
            method: apiMethods.put,
            data: address,
            showSuccess: true,
        })
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setAddresses(prevState => prevState?.map(e => e.id === address.id ? {...e, updating: false} : e))
            return false;
        }
        if (response?.resultFlag) {
            setAddresses(prevState => prevState?.map(e => e.id === address.id ? {
                ...e, ...response.data,
                updating: false
            } : e))
            if (address.isDefault || forceGet) getAddresses();
        }
        return response?.resultFlag ?? false;
    }

    /**
     * Removes an address from the list of addresses in the server.
     *
     * removes the address in the local list after the successful removal of an address from server.
     * @param address
     * @return {Promise<boolean>}
     */
    const removeAddress = async (address) => {
        if (address.isDefault) {
            return true;
        }
        setAddresses(prevState => prevState?.map(e => e.id === address.id ? {...e, removing: true} : e))
        const response = await api({
            url: userAddressApis.remove(address.id),
            method: apiMethods.delete,
            showSuccess: true,
        })
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setAddresses(prevState => prevState?.map(e => e.id === address.id ? {...e, removing: false} : e))
            return false;
        }
        if (response?.resultFlag) {
            setAddresses(prevState => prevState?.filter(e => e.id !== address?.id))
        } else {
            setAddresses(prevState => prevState?.map(e => e.id === address.id ? {...e, removing: false} : e))
        }
        return response?.resultFlag ?? false;
    }

    /**
     * Sets the selected address as the given address but with another address type and then shows the dialog
     * @param address {any}
     */
    const onDuplicateAddress = (address) => {
        const type = address.type.id === AddressTypes.shipping.id ? AddressTypes.billing : AddressTypes.shipping;
        setDialog({
            open: true,
            address: {
                ...address,
                country: address.province.country,
            },
            type: type,
            duplicate: true,
        })
    }

    /**
     * Sets the country of the address to be taken from its province data, then sets the selected address as the
     * address and then opens the dialog.
     * @param address
     */
    const onEditAddress = (address) => {
        const type = address.type.id === AddressTypes.shipping.id ? AddressTypes.shipping : AddressTypes.billing;
        setDialog({
            open: true,
            address: {
                ...address,
                country: countryAndState.countries?.find(e => e.id === address.province?.countryId),
                province: countryAndState.provinces?.find(e => e.id === address.province?.id),
            },
            type: type,
            duplicate: false,
        })
    }

    /**
     * Sets the type of the address to the proper type depending on the type and then opens the dialog.
     * @param {any} type
     */
    const onAddAddress = (type) => {
        setDialog({
            open: true,
            address: null,
            type: type,
            duplicate: false,
        })
    }

    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className='sticky-header mt-5 mb-5'>
                        <h3 className="heading mb-3">
                            Shipping Addresses
                        </h3>
                        <button className={'px-4 button primary mb-3'}
                                onClick={() => onAddAddress(AddressTypes.shipping)}>
                            Add A New Address
                        </button>
                    </div>
                    {
                        loading
                            ? <Row className='text-center justify-content-center py-100'>
                                <LoadingIndicator/>
                            </Row>
                            : shippingAddresses?.length >= 1
                                ? <div className={'d-flex flex-wrap'}>
                                    {
                                        shippingAddresses?.map((address) => (
                                            <Col xs={12} md={6} className={'px-0 pr-4 pb-4'} key={address.id}>
                                                <AddressBox
                                                    settingDefault={shippingAddresses?.some(e => e.settingDefault)}
                                                    address={address}
                                                    remove={removeAddress}
                                                    edit={editAddress}
                                                    setDefault={setAddressAsDefault}
                                                    onEdit={onEditAddress}
                                                    onDuplicate={onDuplicateAddress}
                                                />
                                            </Col>
                                        ))
                                    }
                                </div>
                                : <TryAgain
                                    text={'You do not have any shipping addresses in your account.'}
                                    buttonText={"Retry"}
                                    onClick={() => getAddresses(true)}
                                />
                    }
                </Col>
                <div className='my-4 w-100'/>
                <Col xs={12}>
                    <div className={'sticky-header mt-5 mb-5'}>
                        <h3 className="heading mb-3">
                            Billing Addresses
                        </h3>
                        <button className={'px-4 button primary mb-3'}
                                onClick={() => onAddAddress(AddressTypes.billing)}>
                            Add A New Address
                        </button>
                    </div>
                    {
                        loading
                            ? <Row className='text-center justify-content-center py-100'>
                                <LoadingIndicator/>
                            </Row>
                            : billingAddresses?.length >= 1
                                ? <div className={'d-flex flex-wrap'}>
                                    {
                                        billingAddresses?.map((address) => (
                                            <Col xs={12} md={6} className={'px-0 pr-4 pb-4'} key={address.id}>
                                                <AddressBox
                                                    address={address}
                                                    remove={removeAddress}
                                                    edit={editAddress}
                                                    setDefault={setAddressAsDefault}
                                                    onEdit={onEditAddress}
                                                    onDuplicate={onDuplicateAddress}
                                                />
                                            </Col>
                                        ))
                                    }
                                </div>
                                : <TryAgain
                                    text={'You do not have any billing addresses in your account.'}
                                    buttonText={"Retry"}
                                    onClick={() => getAddresses(true)}
                                />
                    }
                </Col>
            </Row>
            <UpsertAddressDialog
                open={dialog.open}
                duplicate={dialog.duplicate}
                setOpen={() => setDialog(initDialog)}
                addressType={dialog.type}
                address={dialog.address}
                countryAndState={countryAndState}
                createAddress={addAddress}
                updateAddress={editAddress}
            />
        </>
    );
}


export default AddressManagementView
