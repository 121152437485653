import React, {useEffect, useState} from 'react'
import OrdersSearchSection from "../../../components/search-sections/orders";
import {stringifyUrl} from "query-string";
import {deepCopy, deepEqual, stripUnits} from "../../../../core/services/utils/utils";
import {Col, Container, Nav, NavItem, NavLink, Row} from "reactstrap";
import useRouter from "../../../hooks/use-router";
import {apiMethods, OrderViewOrderBys, OrderViewTabs} from "../../../../core/constants/enums";
import {creditsApis, invoicesApis, quoteApis} from "../../../../core/constants/endpoints/endpoints";
import api from "../../../../core/services/api/api";
import useIsMounted from "../../../hooks/use-is-mounted";
import useWindowViewportWidth from "../../../hooks/use-window/viewport-width";
import classnames from "classnames";
import TryAgain from "../../../components/app-specific/try-again";
import QuotesListView from "./quotes/list";
import CreditsListView from "./credits/list";
import InvoicesListView from "./invoices/list";
import moment from "moment/moment";
import {Fade} from "@material-ui/core";
import Pagination from "../../../components/base/pagination";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";


const initialPaginationInfo = {
    currentPage: 1,
    pageSize: 5,
    length: 0,
}


const OrdersView = () => {
    const {query, location, history} = useRouter();
    const [activeTab, setActiveTab] = useState(undefined);
    const [filters, setFilters] = useState({});
    const [data, setData] = useState([]);
    const [containerMargin, setContainerMargin] = useState(0);
    const [loading, setLoading] = useState(true);
    const [paginationInfo, setPaginationInfo] = useState(initialPaginationInfo);
    const [selectedInvoicesToPay, setSelectedInvoicesToPay] = useState([]);
    const viewportWidth = useWindowViewportWidth();
    const isMounted = useIsMounted();

    const showPaginationInfo = Math.floor(paginationInfo.length / paginationInfo.pageSize) > 1;

    /**
     * Listens for the changes in the query parameters of the url and with each change:
     * - checks to see if any of the tab, filters, or paginationInfo have changed and if so, invokes search method.
     * - if tab or filters have changed, resets the entry list as well.
     */
    useEffect(() => {
        const newTab = hasTabChanged();
        const newFilters = hasFiltersChanged();
        const newPaginationInfo = hasPaginationChanged();
        if (newTab || newFilters || newPaginationInfo) {
            if (newTab || newFilters) setData([])
            search(createSearchData(newFilters ?? filters, newPaginationInfo ?? paginationInfo, newTab ?? activeTab), newTab ?? activeTab).then();
        }
    }, [query])

    /**
     * Listens for the changes in the windows' viewport width and with each change:
     * - sets the container top margin based on the current viewport. if it's less than lg, sets it to 0 otherwise -250
     */
    useEffect(() => {
        if (['xs', 'sm', 'md'].includes(viewportWidth)) {
            return setContainerMargin(0);
        }
        const element = document.getElementsByClassName('dashboard-sidebar')?.item(0);
        if (element) {
            const marginTop = stripUnits(window.getComputedStyle(element).getPropertyValue('margin-top'));
            return setContainerMargin(marginTop);
        }
        setContainerMargin(-230);
    }, [viewportWidth])

    /**
     * Determines if the value of the tab in url query params has changes.
     *
     * if the value is changed, sets the new value in the state and returns it, otherwise returns null.
     * @return {string|null}
     */
    const hasTabChanged = () => {
        const newTab = query?.tab ?? OrderViewTabs.quotes.id;
        if (deepEqual(activeTab, newTab)) return null;
        setActiveTab(newTab);
        return newTab;
    }

    /**
     * Determines if the value of the data in url query params has changes.
     *
     * if the value is changed, sets the new value in the state and returns it, otherwise returns null.
     * @return {{}|null}
     */
    const hasFiltersChanged = () => {
        const newFilters = query?.data ? JSON.parse(query?.data ?? '{}') : {};
        if (deepEqual(newFilters, filters)) return null;
        setFilters(newFilters)
        return newFilters;
    }

    /**
     * Determines if the value of the paginationInfo in url query params has changes.
     *
     * if the value is changed, sets the new value in the state and returns it, otherwise returns null.
     * @return {{}|null}
     */
    const hasPaginationChanged = () => {
        const newPaginationInfo = query?.page
            ? (JSON.parse(query.page ?? '{}') ?? initialPaginationInfo)
            : initialPaginationInfo;
        if (deepEqual(newPaginationInfo, paginationInfo)) return null;
        setPaginationInfo(newPaginationInfo)
        return newPaginationInfo;
    }

    /**
     * Checks the validity of the orderBy before searching.
     *
     * if the tabs have changed but the orderBy have not, automatically replaces the correct orderBy property name
     * @param {string} orderBy property name
     * @param {string} tab the current tab
     * @return {undefined|string}
     */
    const ensureCorrectOrderBy = (orderBy, tab) => {
        if (![...OrderViewOrderBys.invoices.map(e => e.id), ...OrderViewOrderBys.orders.map(e => e.id),].includes(orderBy))
            return undefined;
        switch (tab) {
            case OrderViewTabs.quotes.id:
                if (!(Object.values(OrderViewOrderBys.orders).map(e => e.id)).includes(orderBy)) {
                    if (orderBy === OrderViewOrderBys.invoices[1].id) orderBy = OrderViewOrderBys.orders[1].id;
                    if (orderBy === OrderViewOrderBys.invoices[0].id) orderBy = OrderViewOrderBys.orders[0].id;

                }
                break;
            case OrderViewTabs.invoices.id:
            case OrderViewTabs.credits.id:
                if (!(Object.values(OrderViewOrderBys.invoices).map(e => e.id)).includes(orderBy)) {
                    if (orderBy === OrderViewOrderBys.orders[1].id) orderBy = OrderViewOrderBys.invoices[1].id;
                    if (orderBy === OrderViewOrderBys.orders[0].id) orderBy = OrderViewOrderBys.invoices[0].id;
                }
                break;
            default:
                break;
        }
        return orderBy;
    }

    /**
     * Creates the api data needed for the search using the
     * @param filters
     * @param paginationInfo
     * @param tab
     * @return {{paginationInfo: {length: number, pageSize: number, currentPage: number}, filters: {}}}
     */
    const createSearchData = (filters, paginationInfo, tab) => {
        const _filters = deepCopy(filters ?? {});
        let orderBy = null;
        if (_filters.orderBy?.length && _filters?.isDescending !== null) {
            const propertyName = ensureCorrectOrderBy(_filters.orderBy, tab);
            if (propertyName) {
                orderBy = {
                    propertyName: propertyName,
                    isDescending: _filters.isDescending
                }
                // filters are here set, so the search section does not have to change anything
                if (_filters.orderBy !== propertyName) setFilters({..._filters, orderBy: propertyName});
            }
            delete _filters.orderBy;
            delete _filters.isDescending;
        }
        const data = {
            filters: {...(_filters ?? {})},
            paginationInfo: paginationInfo ?? initialPaginationInfo,
        }
        // inject now as toDate so that the results come.
        if (!data.filters.toDate) {
            data.filters['toDate'] = moment(Date.now()).toDate().toDateString()
        }
        if (orderBy) {
            data["orderByProperties"] = [orderBy]
        }
        return data
    }

    /**
     * Searches for the order data based on the provided search data and the selected tab.
     *
     * tab indicates which of the searches (quotes, invoices, credits) should be used
     * searchData indicates the search filters that has been prepared by createSearchData method.
     * @param {any} searchData
     * @param {string} tab
     * @return {Promise<void>}
     */
    const search = async (searchData, tab) => {
        setLoading(true);
        const _api = {
            url: '',
            method: apiMethods.post,
            data: searchData,
        };
        switch (tab) {
            case OrderViewTabs.quotes.id:
            default:
                _api.url = quoteApis.search;
                break;
            case OrderViewTabs.invoices.id:
                _api.url = invoicesApis.search;
                break;
            case OrderViewTabs.credits.id:
                _api.url = creditsApis.search;
                break;
        }
        const response = await api(_api);
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setLoading(false);
            return;
        }
        setData(response?.data?.resultList ?? []);
        if (response?.resultFlag) {
            setPaginationInfo(prevState => ({...prevState, length: response?.data?.paginationInfo?.length ?? 0}))
        }
        setLoading(false);
    }

    /**
     * Searches the for entries again.
     */
    const searchAgain = () => {
        search(createSearchData(filters, paginationInfo, activeTab), activeTab).then();
    }

    /**
     * Removes all the filters of query url params and only keeps the tab so the search method can be invoked.
     */
    const removeFilters = () => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {tab: activeTab},
        }))
    }

    /**
     * Changes the tab of the query parameters so the search method can be invoked again.
     *
     * - if the change of the tab, requires the change of the orderBy property of the search filters, it injects the
     * correct value in the url
     * - empties the selected list of invoices as well.
     * @param {string} tab the new tab.
     */
    const onTabChanged = (tab) => {
        if (tab === activeTab) return
        setLoading(true);
        const propertyName = ensureCorrectOrderBy(filters.orderBy, tab);
        setSelectedInvoicesToPay([]);
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                data: JSON.stringify({
                    ...filters,
                    orderBy: propertyName
                }),
                tab: tab,
                page: undefined,
            },
        }))
    }

    /**
     * Changes the page of the query parameters so the search method can be invoked again.
     * @param {number} newPage currentPage
     */
    const onCurrentPageChange = (newPage) => {
        history.push(stringifyUrl({
            url: location.pathname,
            query: {
                ...(query ?? {}),
                page: JSON.stringify({...paginationInfo, currentPage: newPage + 1})
            },
        }))
    }

    /**
     * Renders the appropriate list view depending on the active tab.
     * @return {JSX.Element}
     */
    const renderOrders = () => {
        if (loading) {
            return (
                <></>
            )
        }
        switch (activeTab) {
            case OrderViewTabs.quotes.id:
            default:
                return <QuotesListView data={data}/>
            case OrderViewTabs.invoices.id:
                return <InvoicesListView
                    data={data}
                    selectedInvoices={selectedInvoicesToPay}
                    setSelectedInvoices={setSelectedInvoicesToPay}
                />
            case OrderViewTabs.credits.id:
                return <CreditsListView data={data}/>
        }
    }


    return (
        <>
            <div className={'orders-view'}>
                <Container>
                    <div className="heading">
                        Orders
                    </div>
                    <OrdersSearchSection
                        filters={filters}
                        activeTab={activeTab}
                    />
                </Container>
                <Container>
                    <div className="nav-tabs-container">
                        <Nav tabs>
                            {
                                Object.entries(OrderViewTabs).map(([key, tab]) => (
                                    <NavItem key={key}>
                                        <NavLink
                                            className={classnames({'active': activeTab === tab.id})}
                                            onClick={() => onTabChanged(tab.id)}>
                                            {tab.title}
                                        </NavLink>
                                    </NavItem>
                                ))
                            }
                        </Nav>
                    </div>
                    {
                        loading
                            ? <Row>
                                <Col xs={12} className={'text-center py-100'}>
                                    <LoadingIndicator/>
                                </Col>
                            </Row>
                            : data?.length < 1
                                ? <Row>
                                    <Col xs={12} className={'text-center py-100'}>
                                        {
                                            Object.entries(filters ?? {}).length < 1
                                                ? <TryAgain
                                                    text={'There is currently no orders available. You may try to fetch the list' +
                                                        ' again.'}
                                                    onClick={searchAgain}
                                                />
                                                : <TryAgain
                                                    text={'No search results found with the provided search filters, you can' +
                                                        ' remove them if you desire'}
                                                    buttonText={'Remove Filters'}
                                                    onClick={removeFilters}
                                                />
                                        }
                                    </Col>
                                </Row>
                                : <>
                                    {renderOrders()}
                                </>
                    }
                    <div className={'d-flex justify-content-center mt-4 w-100'}>
                        <Fade in={showPaginationInfo}>
                            <div>
                                <Pagination
                                    paginationInfo={paginationInfo}
                                    onPageChange={(s) => showPaginationInfo && onCurrentPageChange(s)}
                                />
                            </div>
                        </Fade>
                    </div>
                </Container>

            </div>
        </>
    );

}


export default OrdersView;
