import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {loginUserFailed, loginUserSuccess, policyDialog, unAuthorizedDialog, updateReduxProfileInformation,} from '../../actions';
import {controller} from "../../../core/services/api/controller";
import {authApis, userApis} from "../../../core/constants/endpoints/endpoints";
import api from "../../../core/services/api/api";
import {apiMethods} from "../../../core/constants/enums";
import CacheService from "../../../core/services/cache/cache-service";
import ApiResponseMessages from "../../../core/constants/api-response-messages";
import FirebaseNotificationService from "../../../core/services/firebase-notification-service";
import routes from "../../../ui/routes";
import {getOS} from "../../../core/services/utils/utils";
import CookiesService from "../../../core/services/cache/cookies-service";
import authReduxSlice from "./reducer";
import EnvService from "../../../core/services/env-service";

const actions = authReduxSlice.actions;


/**
 * Logs the user into the system given their credentials
 *
 * if login is successful, then saves user info on local Storage and subscribes the user to the notifications service.
 * @param {string} account
 * @param {string} password
 * @param {boolean} remember
 * @return {Promise<*|undefined>}
 */
const login = async (account, password, remember) => {
    const response = await api({
        url: authApis.login,
        method: apiMethods.post,
        showError: false,
        loginRequired: false,
        data: {
            userName: account,
            password: password,
            OS: getOS(),
        },
        headers: {
            PartnerId: EnvService.DemoPartnerId
        },
    });
    if (response?.resultFlag) {
        const {token, expiryDateTimeMinute, accessLevels, hasFullAccess, partnerId} = response.data;
        if (remember) {
            // only the path is used since we would like to refill this data anywhere we can.
            CookiesService.set(CookiesService.keys.login.name, {account, password, remember})
        } else {
            CookiesService.remove(CookiesService.keys.login.name);
        }
        CacheService.setLoginInfo(token, expiryDateTimeMinute, partnerId, accessLevels, hasFullAccess)
        FirebaseNotificationService.deactivateDevice().then(async (_) => {
            await FirebaseNotificationService.activateDevice().then((__) => {
            })
        })
    }
    return response;
};

/**
 * Removes the user authentication information from the server and if successful, then removes cached information of
 * user and its notification service.
 *
 * @param {boolean} clearCache
 */
const logout = (clearCache) => {
    FirebaseNotificationService.deactivateDevice().then(() => {
        controller({
            "_logout": {
                url: userApis.logout,
                params: {Data: null},

            }
        }).then(({_logout}) => {
            CacheService.removeUserInformation(clearCache);
        })
    });
};

/**
 * Logs the user into the system using user credentials
 *
 * if login is successful, then saves user info on local Storage and subscribes the user to the notifications service.
 * @param payload
 */
function* loginSaga({payload}) {
    const {account, password} = payload.user;
    const remember = payload.user?.remember ?? false;
    const {onLoginSuccess, onLoginFailed} = payload;
    try {
        const response = yield call(login, account, password, remember);
        if (response?.resultFlag) {
            const {firstname, lastname, email, phone, profilePicInfo, token, policyPage} = response.data;
            yield put(updateReduxProfileInformation({
                firstName: firstname,
                lastName: lastname,
                email: email,
                phone: phone,
                profileFileName: profilePicInfo?.url,
                isImagePlaceholder: profilePicInfo?.isPlaceholder,
            }));
            yield put(policyDialog({
                open: !!policyPage,
                mandatory: true,
            }));
            yield put(loginUserSuccess(token));
            yield put(unAuthorizedDialog({open: false}));
            if (onLoginSuccess) yield call(onLoginSuccess)
        } else {
            yield put(loginUserFailed(response.message));
            if (onLoginFailed) yield call(onLoginFailed)
        }
    } catch (error) {
        yield put(loginUserFailed(ApiResponseMessages.serverErrorMessage));
        if (onLoginFailed) yield call(onLoginFailed)
    }
}


/**
 * Logs the user out of the application.
 *
 * if payload contained history, then also navigates the user to the signing view
 * else shows a login popup to ask for user credentials.
 * finally, calls the logout api in the server.
 * @param payload
 */
function* logoutSaga({payload}) {
    const {history, clearCache} = payload;
    try {
        yield call(logout, clearCache);
        if (history) history.push(routes.auth.base)
    } catch (error) {
        console.log(error)
    }
}

/**
 * The Saga that is responsible for authentication of the user.
 */
export default function* authSaga() {
    yield all([
        // listens for login user actions
        fork(function* () {
            yield takeEvery(actions.loginUser.type, loginSaga);
        }),
        // listens for logout user actions
        fork(function* () {
            yield takeEvery(actions.logoutUser.type, logoutSaga);
        }),
    ]);
}
