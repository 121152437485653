import React, {useEffect, useRef} from "react";
import classnames from "classnames";
import {
    DynamicContentEditorEntryRecord,
    DynamicContentEditorListEntry,
    DynamicContentEditorModels,
} from "../../../../../packages/dynamic-content-editor/index";
import {DCEEntryNames} from "../../../../routes";

const StoryBoard = () => {
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const containerRef = useRef();

    /**
     * As soon as the component mounts:
     * - attaches a scroll listener to window's scrolling effect that will change the time-line's active item.
     */
    useEffect(() => {
        window.addEventListener('scroll', onViewScrolled)
        return () => {
            window.removeEventListener('scroll', onViewScrolled)
        }
    }, [])

    /**
     * Based on the offset top of the timeline-time elements WRT the viewport, they have be selected as the active
     * timeline or a done timeline.
     * Gets the scrollY from the window object
     */
    const onViewScrolled = () => {
        const _items = containerRef.current.getElementsByClassName('item');
        const items = Array.from(_items ?? [])
        const offsets = items?.map(e => getOffsetTop(e)) ?? [];

        const hasScroll = items.filter((i, index) =>
            offsets[index] + 200 <= window.scrollY &&
            (offsets[index] + i.clientHeight) + 200 >= window.scrollY);

        const readScroll = items.filter((i, index) => offsets[index] + i.clientHeight + 200 <= window.scrollY);

        hasScroll?.forEach(e => {
            e.classList.add('active');
            e.classList.remove('done')
        })

        readScroll?.forEach(e => {
            e.classList.add('done');
            e.classList.remove('active')
        });

        items
            .filter(e => !(hasScroll.includes(e) || readScroll.includes(e)))
            .forEach(e => {
                e.classList.remove('active', 'done')
            });
    }

    /**
     * Fetches the offset top of an element with respect to the window itself.
     * @param {HTMLElement} elem
     * @return {number}
     */
    const getOffsetTop = (elem) => {
        let offsetTop = 0;
        do {
            if (!isNaN(elem.offsetTop)) {
                offsetTop += elem.offsetTop;
            }
        } while (elem === elem.offsetParent);
        return offsetTop
    }

    return (
        <>
            <DynamicContentEditorListEntry
                ref={containerRef}
                isChildRelativePositioned
                _title={'Our Story'}
                entryName={DCEEntryNames.aboutUsUurStory}
                dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.pageBased}
                tag={'div'}
                className={'story-board'}
                childTag={'div'}
                childProps={(_, index, array) => ({
                    className: classnames("item", {"last": index + 1 === array?.length}),
                })}
            >
                <div className="img">
                    <DynamicContentEditorEntryRecord
                        recordKey={'image'}
                        _title={'Image'}
                        tag={'img'}
                        valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.image}
                    />
                </div>
                <div className="time">
                    <i/>
                    <DynamicContentEditorEntryRecord
                        recordKey={'date'}
                        _title={'Date'}
                        tag={'time'}
                        valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.date}
                        dateFormat={'MMMM YYYY'}
                    />
                </div>
                <div className="content">
                    <DynamicContentEditorEntryRecord
                        recordKey={'title'}
                        _title={'Title'}
                        tag={'h2'}
                        valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                    />
                    <DynamicContentEditorEntryRecord
                        recordKey={'description'}
                        _title={'Description'}
                        tag={'p'}
                        valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.multiline}
                        className={'key-desc'}
                    />
                </div>
            </DynamicContentEditorListEntry>
        </>
    )
}

export default StoryBoard;
