import React, {useEffect, useMemo, useState} from "react";
import useRouter from "../../../../hooks/use-router";
import useWindowViewportWidth from "../../../../hooks/use-window/viewport-width";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {apiMethods, OrderViewTabs, PaymentTerms} from "../../../../../core/constants/enums";
import api from "../../../../../core/services/api/api";
import {userApis} from "../../../../../core/constants/endpoints/endpoints";
import Colors from "../../../../../assets/js/colors";
import routes from "../../../../routes";
import {Col, Row} from "reactstrap";
import DonutChart from "../../../../components/base/chart/donut";
import {Fade} from "@material-ui/core";
import {formatMoney} from "../../../../../core/services/utils/utils";
import classnames from "classnames";

const ShoppingCartViewAccountSummary = ({payInfo, checkoutOrder, checkingOut, goToNextStep, order}) => {
    const {history, stringifyUrl} = useRouter();
    const [loading, setLoading] = useState(true);
    const [financialInfo, setFinancialInfo] = useState({});
    const [chartData, setChartData] = useState({numbers: [], colors: [], legend: []})
    const viewportWidth = useWindowViewportWidth();
    const isMounted = useIsMounted();

    const donutChartSize = useMemo(() =>
            ['xl'].includes(viewportWidth)
                ? 270
                : ['lg'].includes(viewportWidth)
                    ? 220
                    : ['md', 'sm'].includes(viewportWidth)
                        ? 350
                        : 300,
        [viewportWidth])

    const nonCODNeedsToPay = financialInfo?.paymentTermsCode !== PaymentTerms.COD && payInfo?.isNeededToPay

    /**
     * As soon as this component mounts:
     * - fetches user's financial information from the server.
     */
    useEffect(() => {
        getUserFinancialInfo().then();
    }, [])

    /**
     * Fetches user's financial information from the server.
     *
     * if the result of the api is successful, sets the chart data so that they can be shown to the user
     * @return {Promise<void>}
     */
    const getUserFinancialInfo = async () => {
        setLoading(true);
        const response = await api({
            url: userApis.getFinancialInfo,
            method: apiMethods.get,
        });
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setLoading(false);
            return;
        }
        setFinancialInfo(response?.data ?? {});
        setChartData({
            numbers: [
                Math.max(response?.data?.accountReceivable ?? 0, 0),
                Math.max(response?.data?.availableCredit ?? 0, 0),
                Math.max(response?.data?.otherOrders ?? 0, 0),
            ],
            colors: [Colors.chartRed, Colors.chartGreen, Colors.chartBlack],
            legend: ['AR', "Available", "Other"],
        })
        setLoading(false);
    }

    /**
     * Navigates the user to their invoices view so that they may pay their outstanding fees.
     */
    const navigateToInvoiceViews = () => {
        history.push(stringifyUrl({
            url: routes.dashboard.orders,
            query: {
                tab: OrderViewTabs.invoices.id
            },
        }))
    }

    /**
     * Based on the current PaymentTerms of the user, Either checks out the order or just simply navigates them to
     * the payment view.
     * @return {*}
     */
    const onButtonClick = () => {
        if (financialInfo?.paymentTermsCode === PaymentTerms.COD) {
            return goToNextStep();
        }
        if (payInfo?.isNeededToPay) {
            return goToNextStep();
        }
        checkoutOrder();
    }

    return (
        <>
            <Row>
                <Col xs={12}>
                    <div className={'subtitle'}>
                        Quote Number: <span
                        className={'font-weight-600 ml-2'}>{order?.referenceDisplay ?? '--'}</span>
                    </div>
                </Col>
                <Col xs={12} lg={6} className={'mb-4 account-summary-card-box'}>
                    <div className={'account-summary-card'}>
                        <DonutChart data={chartData} size={donutChartSize}>
                            <Fade in={!loading} className={'credit-limit'}>
                                <div>
                                    <p className={'name'}>
                                        Credit Limit
                                    </p>
                                    <p className={'value'}>
                                        {formatMoney(financialInfo.creditLimit ?? 0)}
                                    </p>
                                </div>
                            </Fade>
                        </DonutChart>
                        <div className={'legend'}>
                            {
                                chartData.legend.map((e, index) => (
                                    <div key={e}
                                         className={'d-flex align-items-center'}
                                         style={{'--color': chartData.colors[index]}}>
                                        <div className={'ball'}/>
                                        <p>
                                            {chartData.legend[index] ?? '--'}:
                                            <span>{formatMoney(chartData.numbers[index] ?? 0)}</span>
                                        </p>
                                    </div>
                                ))
                            }
                        </div>
                        {
                            nonCODNeedsToPay &&
                            <button className={'button primary w-100 mt-3'}
                                    disabled={checkingOut}
                                    onClick={navigateToInvoiceViews}>
                                Pay Outstanding invoices
                            </button>
                        }
                    </div>
                </Col>
                <Col xs={12} lg={6} className={'mb-4 account-summary-card-box pl-1'}>
                    <div className={'account-summary-card'}>
                        <Row className={'d-flex flex-wrap'}>
                            <Col xs={6} className={'d-flex justify-content-end'}>
                                <p className={'payable'}>
                                    Payable
                                </p>
                            </Col>
                            <Col xs={6}/>
                            <Col xs={12} className={'d-flex justify-content-center'}>
                                <p className={'price'}>
                                    {formatMoney(payInfo?.minimumRequiredPayment ?? 0)}
                                </p>
                            </Col>
                        </Row>
                        <div className={classnames('text-center transition flex-grow-0', {
                            'flex-grow-1': nonCODNeedsToPay,
                            'mb-4': !nonCODNeedsToPay,
                        })}>
                            {
                                nonCODNeedsToPay &&
                                "Your Available Credit is not enough, you should either pay this order or pay your" +
                                " balance in order to submit the order"
                            }
                        </div>

                        <button className={classnames('button primary w-100', {
                            'px-1 text-sm': !payInfo?.isNeededToPay,
                        })}
                                disabled={checkingOut}
                                onClick={onButtonClick}>
                            {
                                payInfo?.isNeededToPay
                                    ? "Continue with Payment"
                                    : "Use available credit and place order"
                            }
                        </button>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default ShoppingCartViewAccountSummary;
