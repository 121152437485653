import React, {createContext, forwardRef} from 'react';
import _DataGrid from './ui';
import {
    DataGridEvents,
    DataGridInternalState,
    DataGridLayout,
    DataGridMisc,
    DataGridProps,
    DataGridSavedState,
    DefaultDataGridEvents,
    DefaultDataGridLayout,
    DefaultDataGridMisc,
    DefaultDataGridState
} from "./models";
import InternalDataGridController from "./core/models/in-app/controller/internal";
import DataGridController from "./core/models/in-app/controller";

export const DataGridStateContext = createContext<DataGridInternalState>(DefaultDataGridState);
export const DataGridControllerContext = createContext<InternalDataGridController>(new InternalDataGridController());
export const DataGridLayoutContext = createContext<DataGridLayout>(DefaultDataGridLayout);
export const DataGridEventHandlersContext = createContext<DataGridEvents>(DefaultDataGridEvents);
export const DataGridMiscContext = createContext<DataGridMisc>(DefaultDataGridMisc);
export const DataGridSavedStateContext = createContext<DataGridSavedState>([]);
export const DataGridApplicationNameContext = createContext<string>('');

// @ts-ignore
const DataGrid = forwardRef(_DataGrid as React.ForwardRefRenderFunction<DataGridController, DataGridProps>);


// UseDataGridSavedState(name?: string, version?: number) -> [columns, sortBy, pageSize];
// DataGridProvider ({authorizationToken, storageKey})

export {default as DataGridProvider} from './ui/components/provider';
export {default as useDataGridSavedState} from './ui/hooks/use-data-grid-saved-state';

export * from './models';
export default DataGrid;
