import React, {useContext, useEffect} from "react";
import {DynamicContentEditorDataContext} from "../../../packages/dynamic-content-editor/ui/contexts";
import {useDispatch} from "react-redux";
import {setDCEValues} from "../../../redux/entities/dynamic-content-editor/actions";
import {DCEEntryNames} from "../../routes";

const DCEManager = () => {
    const data = useContext(DynamicContentEditorDataContext);
    const dispatch = useDispatch();

    /**
     * With each change in the fixed slice of the DCE data store:
     * - sets the description of the about us values in the redux store to be used by the app-manager middleware.
     */
    useEffect(() => {
        const aboutUsGeneralInfo = data?.fixed?.find(e => e.name === DCEEntryNames.aboutUsGeneralInfo);
        if (!aboutUsGeneralInfo?.data?.length)
            return;
        dispatch(setDCEValues({
            description: aboutUsGeneralInfo.data.find(e => e.key === 'description')?.value,
        }));
    }, [data, dispatch]);

    return (<></>);
}

export default DCEManager;
