import React, {useMemo} from 'react';
import {Route} from 'react-router-dom';
import {Provider as ReduxProvider} from 'react-redux'
import store from './redux/store'
import Views from "./ui/views";
import routes, {DCERoutes} from "./ui/routes";
import {toast, ToastContainer, Zoom} from "react-toastify";
import ApplicationMiddlewares from "./ui/middlewares";
import theme from "./assets/js/panel-theme";
import {MuiThemeProvider} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {DynamicContentEditorRouter as DCERouter} from "./packages/dynamic-content-editor/index";
import {DCEApis} from "./core/constants/endpoints/dce-endpoints";
import EnvService from "./core/services/env-service";

const App = () => {
    const dceRouteToPageMap = useMemo(() => ({
        [routes.auth.base]: DCERoutes.auth,
        [routes.auth.resetPassword]: DCERoutes.auth,
        [routes.auth.deleteAccount]: DCERoutes.auth,
        [routes.public.about]: DCERoutes.about,
        [routes.public.catalogue]: DCERoutes.catalogues,
        [routes.main.categories]: DCERoutes.categories,
    }), [])
    /**@type {Partial<DynamicContentEditorApiConfiguration>}*/
    const dceApiConfiguration = useMemo(() => ({
        getPageDataUrl: DCEApis.getPageData,
    }), [])
    /**@type {Partial<DynamicContentEditorApiInterceptors>}*/
    const dceApiInterceptors = useMemo(() => ({
        request: [[
            (config) => {
                config.headers = {
                    PartnerId: EnvService.DemoPartnerId,
                    ...(config.headers ?? {}),
                }
                return config;
            }
        ]],
    }), [])

    return (
        <>
            <MuiThemeProvider theme={theme}>
                <ReduxProvider store={store}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DCERouter
                            routeToPageMap={dceRouteToPageMap}
                            apiConfiguration={dceApiConfiguration}
                            apiInterceptors={dceApiInterceptors}
                            route={routes.dynamicContentEditor}
                        >
                            <Route path={routes.landing}>
                                <ApplicationMiddlewares/>
                                <Views/>
                            </Route>
                        </DCERouter>
                    </MuiPickersUtilsProvider>
                </ReduxProvider>
            </MuiThemeProvider>
            <ToastContainer
                position={toast.POSITION.BOTTOM_RIGHT}
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                pauseOnFocusLoss
                draggable
                pauseOnHover
                transition={Zoom}
            />
        </>

    )
};

export default App;
