import React, {useMemo} from "react";
import LazyImage from "../../lazy-lmage";
import useRouter from "../../../../hooks/use-router";
import routes from "../../../../routes";
import {ProductsSearchTypes} from "../../../../../core/constants/enums";
import ProductSearchUtils from "../../../../../core/services/utils/product-search-utils";
import useWindowViewportWidth from "../../../../hooks/use-window/viewport-width";
import classnames from "classnames";

const HomeViewBanner = ({data, isUnClickable = false, url = null}) => {
    const {history, stringifyUrl} = useRouter();
    const banner = data?.content?.banners?.length ? data?.content?.banners[0] : {};
    const viewportWidth = useWindowViewportWidth();

    const bannerHeight = useMemo(() => {
        switch (viewportWidth) {
            case 'xs':
                return () => window.innerWidth - 40;
            case 'sm':
                return 221;
            case 'md':
                return 300;
            case 'lg':
                return 404;
            case 'xl':
            default:
                return 482;
        }
    }, [viewportWidth])

    /**
     * Navigates to the products search result view having the given banners tagIds as the search filters.
     */
    const onBannerClicked = () => {
        // Sets the selected banner for search
        history.push(stringifyUrl({
                url: routes.main.products,
                query: {
                    type: ProductsSearchTypes.tags,
                    data: JSON.stringify({
                        [ProductsSearchTypes.tags]: {
                            [ProductSearchUtils.basicSearchQueryKeys.tags]: banner?.tags?.map(e => ({
                                id: e.id,
                                name: e.name
                            })) ?? [],
                        },
                    }),
                    banner: encodeURIComponent(banner?.imageName ?? null),
                },
            })
        )
    }

    return (
        <>
            {
                !isUnClickable &&
                <div className={'home-view-banner'} onClick={onBannerClicked}>
                    <div key={banner.id} className={'image'}>
                        <LazyImage
                            height={bannerHeight}
                            src={banner?.imageName}
                            alt={banner?.imageName ?? ''}
                        />
                    </div>
                </div>
            }
            {
                isUnClickable &&
                <div className={'home-view-banner'}>
                    <div key={1} className={classnames('image', {'un-clickable': isUnClickable})}>
                        <LazyImage
                            height={bannerHeight}
                            src={url}
                            alt={url ?? ''}
                        />
                    </div>
                </div>
            }
        </>
    )
}

export default HomeViewBanner;
