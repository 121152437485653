import React, {useEffect, useState} from "react";
import * as Yup from 'yup';
import {makeRequired, makeValidate} from "mui-rff";
import {deepCopy, deepEqual, getAddressHTML} from "../../../../core/services/utils/utils";
import {Col, Collapse, Row} from "reactstrap";
import MuiInput from "../../base/input/mui-input";
import {ReactComponent as MoreIcon} from "../../../../assets/images/more.svg";
import {ReactComponent as CheckIcon} from "../../../../assets/images/check.svg";
import {ReactComponent as ExpandIcon} from "../../../../assets/images/blogs/expand.svg";
import classnames from "classnames";
import Form from "../../base/form";
import {Fade, Popover} from "@material-ui/core";
import {AddressTypes} from "../../../../core/constants/enums";
import {useDispatch} from "react-redux";
import {confirmationDialog} from "../../../../redux/entities/dialogs/actions";
import {toast} from "react-toastify";


const identifier = (id) => `exempt-number-${id}`

/**
 * Creates the schema and initialValues for the taxes of an address
 * @param address {any}
 * @return {*}
 */
const createSchemaAndInitialValues = (address) => {
    const schema = {};
    const initialValues = {};
    address.addressTaxes?.forEach((addressTax) => {
        const id = identifier(addressTax.taxByProvince?.id);
        schema[id] = Yup.string().nullable();
        if (addressTax.exemptNumber?.length) {
            initialValues[id] = addressTax.exemptNumber;
        }

    });
    return {
        schema: Yup.object().shape(schema),
        initialValues: initialValues,
    };
}

const AddressBox = ({address, setDefault, remove, edit, onEdit, onDuplicate, settingDefault}) => {
    const [seeTaxInfo, setSeeTaxInfo] = useState(false);
    const [schema, setSchema] = useState(() => Yup.object().shape({}));
    const [initialValues, setInitialValues] = useState({});
    const [popover, setPopover] = useState()
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const dispatch = useDispatch();

    const canShowTaxInfo = address?.type?.id === AddressTypes.shipping.id;

    /**
     * Listens for the changes in the address, and with each change, sets a new schema and new initial values for
     * the tax section.
     */
    useEffect(() => {
        const {schema, initialValues} = createSchemaAndInitialValues(address);
        setSchema(schema);
        setInitialValues(initialValues);
    }, [address])

    /**
     * Creates a copy of the values, edits the exempt numbers of the copy and sends it through the update address api.
     * @param values
     * @param {formApi} form
     * @return {Promise<void>}
     */
    const updateAddressTaxExemptNumbers = async (values, form) => {
        const forApi = deepCopy(address);
        const entries = Object.entries(values ?? {});
        for (const addressTax of (address?.addressTaxes ?? [])) {
            const addressIdentifier = identifier(addressTax.taxByProvince?.id);
            const entry = entries?.find(e => e[0] === addressIdentifier);
            forApi.addressTaxes.find(e => e.id === addressTax.id).exemptNumber = entry?.length ? entry[1] ?? null : null;
        }
        await edit(forApi, true);
    }

    /**
     * Opens the remove confirmation dialog to check for user confirmation before removing this address.
     */
    const getRemoveConfirmation = () => {
        if (address?.isDefault) {
            toast.warn(
                "You may not remove your default address. please change your default address" +
                " prior to this operation."
            );
            return;
        }
        dispatch(confirmationDialog({
            open: true,
            title: "Remove Address",
            description: "Do you want to remove the selected address?",
            callback: (confirmed) => {
                if (confirmed) remove(address);
                return true;
            },
            mandatory: false,
            cancelText: "cancel",
            proceedText: "delete",
        }))
    }

    /**
     * Renders theAddress tax information table.
     * @return {JSXElement[]}
     */
    const renderAddressTaxes = () => {
        return [
            <Row key={'header'} className={'w-100 head'}>
                <Col className={'tax'} xs={4}>
                    Tax
                </Col>
                <Col className={'rate'} xs={4}>
                    Rate
                </Col>
                <Col className={'input'} xs={4}>
                    Exempt No
                </Col>
            </Row>,
            ...address?.addressTaxes?.map((addressTax) => {
                const id = identifier(addressTax.taxByProvince?.id);
                return {
                    key: addressTax.id,
                    cells: {
                        tax: {
                            xs: 4,
                            className: 'tax',
                            cell: addressTax.taxByProvince?.title,
                        },
                        rate: {
                            xs: 4,
                            className: 'rate',
                            cell: `${addressTax.taxByProvince?.percentage ?? '0'} %`,
                        },
                        exempt: {
                            xs: 4,
                            className: 'input',
                            cell: (
                                <div className='d-flex w-100 align-items-center justify-content-center'>
                                    <MuiInput
                                        form
                                        name={id}
                                        disabled={address?.updating}
                                        required={!!required[id]}
                                        className={'bg-white'}
                                    />
                                </div>
                            ),
                        },
                    },
                }
            })?.map((data) => (
                <Row key={data.key} className={'w-100'}>
                    {
                        Object.entries(data.cells)?.map(([key, e]) => (
                            <Col key={key} className={e.className} xs={e.xs}>
                                {e.cell}
                            </Col>
                        ))
                    }
                </Row>
            ))
        ];
    }

    return (
        <div className={'address-box'}>
            <div className={'address-app-header'}>
                <p className={'title'}>
                    {address?.title ?? 'n/a'}
                </p>
                <button className={'button text more-icon'}
                        onClick={(e) => setPopover(e.currentTarget)}>
                    <MoreIcon className={'more'}/>
                </button>
                <Popover
                    id={!!popover ? 'options' : undefined}
                    classes={{paper: 'address-options-popover'}}
                    open={!!popover}
                    onClose={() => setPopover(null)}
                    anchorReference={'anchorEl'}
                    anchorEl={popover}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}>
                    <div className={'content'}>
                        <div className={'d-flex flex-wrap mt-2'} onClick={() => setPopover(null)}>
                            <div
                                className={classnames('option')}
                                onClick={() => onEdit(address)}>
                                Edit
                            </div>
                            {
                                (!address?.isDefault) &&
                                <div
                                    className={classnames('option')}
                                    onClick={() => setDefault(address)}>
                                    {address?.settingDefault
                                        ? "Processing..."
                                        : "Set as Default"
                                    }
                                </div>}
                            <div
                                className={classnames('option')}
                                onClick={() => onDuplicate(address)}>
                                Duplicate
                                as {Object.values(AddressTypes).find(e => e.id === (3 - (address?.type?.id ?? 0)))?.title}
                            </div>
                            <div
                                className={classnames('option remove-option')}
                                onClick={getRemoveConfirmation}>
                                Remove
                            </div>
                        </div>
                    </div>
                </Popover>
            </div>
            <div
                className={'body'}
                dangerouslySetInnerHTML={{__html: getAddressHTML(address)}}
            >
            </div>
            <div className={'actions'}>
                {
                    address?.isDefault
                        ? <button
                            className={classnames(
                                'button text default-address',
                                {'default-address': address?.isDefault}
                            )}
                            onClick={() => setDefault(address)}
                            disabled={settingDefault || address?.isDefault}>
                            <CheckIcon/>
                            Default {address?.type?.title} Address
                        </button>
                        : <div/>
                }
                {
                    canShowTaxInfo &&
                    <button className={'button text expand-button'}
                            onClick={() => setSeeTaxInfo(prevState => !prevState)}>
                        Tax info
                        <ExpandIcon
                            className={classnames('expand', {'active': seeTaxInfo})}
                        />
                    </button>
                }
            </div>
            <Collapse isOpen={seeTaxInfo && canShowTaxInfo}>
                <div className={'footer'}>
                    <Form
                        onSubmit={updateAddressTaxExemptNumbers}
                        initialValues={initialValues}
                        validate={validate}
                        render={({values}) => {
                            return (
                                <>
                                    {renderAddressTaxes()}
                                    <Row>
                                        <Col xs={12} className={'d-flex justify-content-center mt-2'}>
                                            <Fade in={!deepEqual(initialValues, values)}>
                                                <button
                                                    className={'button primary'}
                                                    disabled={deepEqual(initialValues, values) || address?.updating}
                                                    type={'submit'}>
                                                    {
                                                        address?.updating
                                                            ? "saving..."
                                                            : "Save changes"
                                                    }
                                                </button>
                                            </Fade>
                                        </Col>
                                    </Row>
                                </>
                            );
                        }}
                    />
                </div>
            </Collapse>
        </div>
    );
}

export default AddressBox;
