import React, {useState} from "react";
import ReactCreditCards from "react-credit-cards";
import {CreditCardTypeNames} from "../../../../core/constants/enums";
import {getCardMonth, getCardNumber} from "../../../../core/services/utils/utils";
import {useDispatch} from "react-redux";
import {confirmationDialog} from "../../../../redux/entities/dialogs/actions";
import classnames from "classnames";
import {Collapse} from "reactstrap";
import {ReactComponent as SeparatorLine} from "../../../../assets/images/card-more-option-separator.svg";
import {ReactComponent as Chevron} from "../../../../assets/images/toggle-arrow.svg";
import {routeFunctions} from "../../../routes";
import useRouter from "../../../hooks/use-router";


const CreditCard = ({
                        card,
                        focused = "name",
                        onSelect,
                        onRemove,
                        onSetDefault,
                        settingDefault,
                        withWrapper = false,
                        removable = false,
                        selectable = false,
                    }) => {
    const {history} = useRouter();
    const [isExtended, setIsExtended] = useState(false);
    const dispatch = useDispatch();


    /**
     * Opens the remove confirmation dialog to check for user confirmation before removing this credit card.
     */
    const onRemoveClicked = () => {
        dispatch(confirmationDialog({
            open: true,
            title: "Remove Credit Card",
            description: "Do you want to remove the selected card?",
            callback: (confirmed) => {
                if (confirmed) onRemove();
                return true;
            },
            mandatory: false,
        }))
    }

    /**
     * Navigates user to selected card's transaction summary
     */
    const viewCardTransactions = () => {
        history.push(routeFunctions.paymentMethods.transactionsSummary(card.id))
    }

    const cardContent = <>
        <div
            className={classnames(
                "credit-card",
                {'default-card': card?.isDefault},
                {'selectable': selectable},
            )}
            onClick={() => selectable && onSelect()}
        >
            <ReactCreditCards
                preview
                cvc={""}
                focused={focused}
                expiry={getCardMonth(card?.month)}
                number={getCardNumber(card?.lastFourDigits)}
                issuer={CreditCardTypeNames[card?.type] ?? 'UNKNOWN'}
                name={card.isDefault ? "DEFAULT CARD" : " "}
            />
        </div>
    </>


    return (
        <>
            {
                !withWrapper &&
                cardContent
            }
            {
                withWrapper &&
                <div className={classnames('credit-card-wrapper')}>
                    {
                        cardContent
                    }
                    <div className={'options-section'}>
                        <div/>
                        <div
                            className={classnames('card-more-options', {'extended': isExtended})}
                            onClick={() => setIsExtended(prevState => !prevState)}
                        >
                            <p>More Options</p>
                            <Chevron className={'mt-1'}/>
                        </div>
                    </div>
                    <Collapse isOpen={isExtended}>
                        <div className={'credit-card-options'}>
                            <div className={'expanded-section-separator'}>
                                <SeparatorLine/>
                            </div>
                            {/*<button*/}
                            {/*    className={"button primary outlined"}*/}
                            {/*    onClick={viewCardTransactions}*/}
                            {/*    disabled={settingDefault}>*/}
                            {/*    Usage Summary*/}
                            {/*</button>*/}
                            {
                                !card.isDefault && removable &&
                                <button
                                    className={"button primary outlined"}
                                    onClick={onSetDefault}
                                    disabled={settingDefault}>
                                    {
                                        card?.settingDefault
                                            ? 'Processing...'
                                            : 'Set Default'
                                    }
                                </button>
                            }
                            {
                                removable &&
                                <button
                                    className={"button text primary mb-2"}
                                    onClick={onRemoveClicked}
                                    disabled={settingDefault}>
                                    {'Delete'}
                                </button>
                            }
                        </div>
                    </Collapse>
                </div>
            }
        </>
    );
}

export default CreditCard;
