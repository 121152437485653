import React, {useEffect, useMemo, useRef, useState} from "react";
import useRouter from "../../../../../hooks/use-router";
import Form from "../../../../base/form";
import ProductSearchUtils from "../../../../../../core/services/utils/product-search-utils";
import * as Yup from "yup";
import {makeRequired, makeValidate} from "mui-rff";
import routes from "../../../../../routes";
import {ProductsSearchTypes} from "../../../../../../core/constants/enums";
import Select from "../../../../base/select";
import {ReactComponent as OrderByIcon} from "../../../../../../assets/images/input-icons/orderby.svg";
import {ReactComponent as ToggleArrowIcon} from "../../../../../../assets/images/toggle-arrow.svg";
import {Col} from "reactstrap";
import classnames from "classnames";
import MuiRadio from "../../../../base/radio";
import {deepCopy} from "../../../../../../core/services/utils/utils";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import ObserversService from "../../../../../../core/services/observers-service";


const formKeys = ProductSearchUtils.elasticTechnicalSearchFormKeys;
const queryKeys = ProductSearchUtils.elasticTechnicalSearchQueryKeys;

/**
 * Debounce the calls of form submission to increase the performance of the saerch sectin.
 * @param form
 * @param timeout
 * @return {(function(): void)|*}
 */
const submitFormDebounced = (form, timeout = 1000) => {
    return async () => {
        await new Promise(r => setTimeout(r, 1));
        form?.submit();
    };
}

const initialOrderBy = {
    id: -1,
    value: null,
    name: "order by",
    disabled: true,
}

const ElasticTechnicalSearchFiltersSection = ({formData, data, fetchingData}) => {
    const {history, stringifyUrl} = useRouter();
    const [formRef, _setFormRef] = useState();
    const schema = useMemo(() => {
        const shape = {
            [formKeys.orderBy]: Yup.object().nullable(),
        }
        const facets = Object.entries(formData.facets ?? {});
        for (const [facetName] of facets) {
            shape[facetName] = Yup.string().nullable().trim();
        }
        return Yup.object().shape(shape)
    }, [formData])
    const isMounted = useIsMounted();
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    const initialValues = useMemo(() => {
        const res = {
            [formKeys.orderBy]: data[queryKeys.orderBy],
        }
        if (data.facets) {
            const facets = Object.entries(formData.facets ?? {});
            for (const [facetName] of facets) {
                res[facetName] = data.facets[facetName];
            }
        }
        return res;
    }, [formData, data])
    const orderBys = useMemo(() => [
        initialOrderBy,
        ...(formData?.orderByList?.map(e => ({
            id: e.id,
            value: e.value,
            name: e.name,
        })) ?? [])
    ], [formData]);
    const facets = useMemo(() =>
            Object.entries(formData.facets ?? {})
                ?.filter(([, e]) => !!e?.length)
            ?? [],
        [formData])

    /**
     * Changes the query of the url with the given search values.
     * @param {any} values the form values.
     */
    const search = (values) => {
        const facets = Object.fromEntries(Object.entries(values).filter(([e, v]) =>
            ![formKeys.orderBy].includes(e) && typeof v !== 'undefined',
        ))
        const url = stringifyUrl({
            url: routes.main.products,
            query: {
                type: ProductsSearchTypes.elasticTechnical,
                data: JSON.stringify({
                    [ProductsSearchTypes.elasticTechnical]: {
                        [queryKeys.orderBy]: values[queryKeys.orderBy] === -1 ? undefined : values[queryKeys.orderBy],
                        [queryKeys.categoryId]: data[queryKeys.categoryId],
                        [queryKeys.keyword]: data[queryKeys.keyword],
                        [queryKeys.facets]: facets,
                    }
                })
            },
        });
        history.push(url);
    };

    /**
     * Submits the form but with a debounce functionality for better performance.
     */
    const submitForm = useMemo(() => submitFormDebounced(formRef), [formRef])

    /**
     * Sets the form ref with a delay to enable the rendering of the parent component
     * @param form
     * @return {number}
     */
    const setFormRef = (form) => setTimeout(() => {
        if (!isMounted()) return;
        _setFormRef(form);
    }, 300)

    const checkboxes = useMemo(
        () => facets.map((facet) => (
            <FacetCheckboxGroup
                key={facet[0]}
                facet={facet}
                form={formRef}
                submitForm={submitForm}
                initialValue={initialValues[facet[0]]}
                disabled={fetchingData}
            />
        )).sort((a, b) =>
            (formData?.properties?.find(e => e.title === a.key)?.orderIndex ?? 100) - (formData?.properties?.find(e => e.title === b.key)?.orderIndex ?? 100)
        ),
        [facets, formRef, submitForm, initialValues, fetchingData]
    )

    return (
        <>
            <Form
                className={'d-flex flex-wrap w-100'}
                initialValues={initialValues}
                validate={validate}
                onSubmit={search}
                render={({values, form}) => {
                    if (!formRef) setFormRef(form);
                    return (
                        <>
                            <Col xs={12} className={'mb-3 px-0'}>
                                <p className={'text-xl font-weight-600 mb-1'}>
                                    ORDER BY
                                </p>
                                <Select
                                    form
                                    name={formKeys.orderBy}
                                    required={!!required[formKeys.orderBy]}
                                    value={values[formKeys.orderBy] ?? -1}
                                    disabled={fetchingData}
                                    renderValue={e => e?.property ?? orderBys[0].name}
                                    startAdornment={<OrderByIcon className={'select-icon'}/>}
                                    onChange={submitForm}
                                    data={orderBys?.map(e => ({
                                        label: e.name,
                                        value: e.value,
                                        disabled: e.disabled,
                                        id: e.id,
                                    }))}
                                />
                            </Col>
                            {checkboxes}
                        </>
                    );
                }}
            />
        </>
    );
}

const collapseLimit = 5;

const FacetCheckboxGroup = ({facet, form, submitForm, initialValue, disabled}) => {
    const [collapsed, setCollapsed] = useState(true);
    const [name, values] = useMemo(() => facet, [facet]);
    const [currentValue, setCurrentValue] = useState(undefined)
    const showCollapse = useMemo(() => values?.length > 10, [values]);
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const containerRef = useRef();

    /**
     * With each change in the initial value:
     * - sets the current value of this group.
     */
    useEffect(() => {
        setCurrentValue(initialValue)
    }, [initialValue])

    /**
     * With each change in the facet:
     * - attaches an observer that would set up the ui for the collapse functionality.
     */
    useEffect(() => {
        if (!containerRef.current)
            return;
        const observer = ObserversService.newResizeObserver(determineContainerHeight);
        ObserversService.observeResizeObserver(observer, containerRef.current);
        return () => ObserversService.disconnectResizeObserver(observer);
    }, [facet, containerRef])

    /**
     * With each change in the collapsed state or the current value:
     * - determines the height of the container to fit the checkboxes having considered the visibility limit.
     */
    useEffect(() => {
        determineContainerHeight();
    }, [collapsed, currentValue])

    /**
     * Determines the height of the container to fit the checkboxes having considered the visibility limit.
     */
    const determineContainerHeight = () => {
        const label = containerRef.current.querySelector('.MuiFormLabel-root');
        const checkboxes = containerRef.current.querySelectorAll('.MuiFormControlLabel-root');
        const limit = collapsed ? collapseLimit : checkboxes.length;
        let i = 0;
        let height = label?.getBoundingClientRect()?.height ?? 0;
        while (i < limit && checkboxes.length > i) {
            height += checkboxes.item(i++).getBoundingClientRect().height;
        }
        containerRef.current.parentElement.style.maxHeight = `${height}px`;
    }

    /**
     * Selects the given value in the form and keeps a reference in the local state to bring its element to the top
     * of the group.
     * @param {Event} e
     */
    const onValueSelected = (e) => {
        if (disabled)
            return;
        if (currentValue === e.target.value) {
            setCurrentValue(undefined);
            form.change(name, undefined);
        } else {
            setCurrentValue(e.target.value);
            form.change(name, e.target.value);
        }
        submitForm();
    }

    /**
     * Renders the checkboxes of this group.
     */
    const checkboxData = useMemo(() => (
        deepCopy(values)
            ?.sort((a, b) => {
                if (currentValue === a.value) {
                    return -1;
                }
                if (currentValue === b.value) {
                    return 1;
                }
                return 0;
            })
            ?.map(facetEntry => ({
                label: (
                    <div
                        className={'checkbox-label'}>
                        <p>
                            {facetEntry?.value ?? ''}
                        </p>
                        <p className={'text-right'}>
                            {facetEntry?.count ?? 0}
                        </p>
                    </div>
                ),
                value: facetEntry.value,
            }))
    ), [values, currentValue])

    return (
        <div className={classnames('facet-checkbox', {collapsed})}>
            <div>
                <div ref={containerRef}>
                    <MuiRadio
                        form
                        name={name}
                        label={name}
                        onClick={onValueSelected}
                        disableRipple
                        data={checkboxData}
                        disabled={disabled}
                    />
                </div>

            </div>
            {
                showCollapse &&
                <button
                    className={classnames('button text toggle-button')}
                    type={'button'}
                    onClick={() => setCollapsed(prevState => !prevState)}
                >
                    <ToggleArrowIcon/>
                </button>
            }
            <div className={'separator'}/>
        </div>
    );
}

export default ElasticTechnicalSearchFiltersSection;
