import React, {useMemo} from "react";
import {Col, Container, Row} from 'reactstrap';
import Logo from "../../../assets/images/logo/logo-white.svg";
import {ReactComponent as BizkeyTechLogo} from "../../../assets/images/logo/bizkeytech-white.svg";
import {Link} from "react-router-dom";
import routes, {DCEEntryNames, routeFunctions} from "../../routes";
import moment from "moment";
import {AboutUsTabs} from "../../views/public/about-us";
import {ReactComponent as InstagramIcon} from "../../../assets/images/insta-logo-icon.svg";
import {useSelector} from "react-redux";
import {
    DynamicContentEditorEntry,
    DynamicContentEditorEntryRecord,
    DynamicContentEditorListEntry,
    DynamicContentEditorModels
} from "../../../packages/dynamic-content-editor/index";
import {HeaderTypes} from "../../../core/constants/enums";

const Footer = () => {
    const headerType = useSelector(state => state?.header?.type);
    const loggedIn = useMemo(() => headerType === HeaderTypes.loggedIn, [headerType]);

    return (
        <footer className={'app-footer'}>
            <Container>
                <Row className={'position-relative'}>
                    <Col lg={3} md={4} xs={12} className={'mb-5 mb-md-0 order-2 order-lg-5'}>
                        <div className="brand">
                            <img src={Logo} alt="" height={40}/>
                            <DynamicContentEditorEntry
                                iconPosition={DynamicContentEditorModels.DynamicContentEditorEntryIconPositions.outward}
                                dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.fixed}
                                entryName={DCEEntryNames.footerSocials}
                                _title={'Footer Social Links'}
                                tag={'div'}
                                className="footer-share-button"
                            >
                                <div className={'d-flex flex-wrap justify-content-center'}>
                                    <DynamicContentEditorEntryRecord
                                        recordKey={'instagram'}
                                        _title={'Instagram Link'}
                                        tag={'a'}
                                        target={'_blank'}
                                        valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.url}
                                    >
                                        <InstagramIcon/>
                                    </DynamicContentEditorEntryRecord>
                                    <DynamicContentEditorEntryRecord
                                        recordKey={'facebook'}
                                        _title={'Facebook Link'}
                                        tag={'a'}
                                        target={'_blank'}
                                        valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.url}
                                    >
                                        <i className="icon-facebook"/>
                                    </DynamicContentEditorEntryRecord>
                                    <DynamicContentEditorEntryRecord
                                        recordKey={'linkedin'}
                                        _title={'Linked-In Link'}
                                        tag={'a'}
                                        target={'_blank'}
                                        valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.url}
                                    >
                                        <i className="icon-linkedin"/>
                                    </DynamicContentEditorEntryRecord>
                                    <DynamicContentEditorEntryRecord
                                        recordKey={'amazon'}
                                        _title={'Amazon Link'}
                                        tag={'a'}
                                        target={'_blank'}
                                        valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.url}
                                    >
                                        <i className="icon-amazon"/>
                                    </DynamicContentEditorEntryRecord>
                                    <DynamicContentEditorEntryRecord
                                        recordKey={'ebay'}
                                        _title={'Ebay Link'}
                                        tag={'a'}
                                        target={'_blank'}
                                        valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.url}
                                    >
                                        <i className="icon-ebay"/>
                                    </DynamicContentEditorEntryRecord>
                                </div>
                            </DynamicContentEditorEntry>
                        </div>
                    </Col>
                    <DynamicContentEditorListEntry
                        _title={'Footer Addresses'}
                        entryName={DCEEntryNames.footerAddress}
                        dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.fixed}
                        tag={Col}
                        lg={9}
                        xs={12}
                        md={8}
                        className={'mb-5 mb-md-0 order-3 order-lg-2 d-flex flex-wrap'}
                        childTag={Col}
                        childProps={{
                            xs: 12,
                            md: 6,
                            lg: 4,
                        }}
                    >
                        <div className={'item'}>
                            <DynamicContentEditorEntryRecord
                                recordKey={'title'}
                                _title={'Address Title'}
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                tag={'div'}
                                className={'title'}
                                placeholder={"Address Title"}
                            />
                            <DynamicContentEditorEntryRecord
                                recordKey={'address'}
                                _title={'Address'}
                                tag={'address'}
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.multiline}
                                placeholder={"address street, city, state, zip code"}
                            />
                            <DynamicContentEditorEntryRecord
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.element}
                            >
                                {(item) => (
                                    <a
                                        data-dce-record-key={'tel'}
                                        data-dce-record-value-type={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.phone}
                                        data-dce-record-value={item["tel"]}
                                        data-dce-record-title={"Telephone"}
                                        href={`tel:${item["tel"]}`}
                                    >
                                            <span>
                                                Tel:
                                                <span className={'ml-1'}>
                                                    {item["tel"] ?? 'N/A'}
                                                </span>
                                            </span>
                                    </a>
                                )}
                            </DynamicContentEditorEntryRecord>
                            <DynamicContentEditorEntryRecord
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.element}
                            >
                                {(item) => (
                                    <a
                                        data-dce-record-key={'fax'}
                                        data-dce-record-value-type={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.phone}
                                        data-dce-record-value={item["fax"]}
                                        data-dce-record-title={"Fax"}
                                        href={`tel:${item["fax"]}`}
                                    >
                                            <span>
                                                Fax:
                                                <span className={'ml-1'}>
                                                    {item["fax"] ?? 'N/A'}
                                                </span>
                                            </span>
                                    </a>
                                )}
                            </DynamicContentEditorEntryRecord>
                            <DynamicContentEditorEntryRecord
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.element}
                            >
                                {(item) => (
                                    <a
                                        className={'mt-4'}
                                        data-dce-record-key={'toll-free'}
                                        data-dce-record-value-type={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.phone}
                                        data-dce-record-value={item["toll-free"]}
                                        data-dce-record-title={"Toll-Free"}
                                        href={`tel:${item["toll-free"]}`}
                                    >
                                            <span>
                                                Toll-Free:
                                                <span className={'ml-1'}>
                                                    {item["toll-free"] ?? 'N/A'}
                                                </span>
                                            </span>
                                    </a>
                                )}
                            </DynamicContentEditorEntryRecord>
                        </div>
                    </DynamicContentEditorListEntry>
                </Row>
            </Container>
            <div className={'footer-bottom'}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <DynamicContentEditorEntry
                                iconPosition={DynamicContentEditorModels.DynamicContentEditorEntryIconPositions.outward}
                                dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.fixed}
                                entryName={DCEEntryNames.footerNavbar}
                                _title={'Footer Navigation'}
                                tag={'div'}
                                className="menu"
                            >
                                <ul>
                                    {
                                        loggedIn &&
                                        <li>
                                            <DynamicContentEditorEntryRecord
                                                recordKey={'home'}
                                                _title={'Home Title'}
                                                tag={Link}
                                                to={routes.landing}
                                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                            />
                                        </li>
                                    }
                                    {
                                        !loggedIn &&
                                        <li>
                                            <DynamicContentEditorEntryRecord
                                                recordKey={'entry'}
                                                _title={'Entry Title'}
                                                tag={Link}
                                                to={routes.auth.login}
                                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                            />
                                        </li>
                                    }
                                    {
                                        loggedIn &&
                                        <li>
                                            <DynamicContentEditorEntryRecord
                                                recordKey={'categories'}
                                                _title={'Categories Title'}
                                                tag={Link}
                                                to={routeFunctions.main.categories()}
                                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                            />
                                        </li>
                                    }
                                    <li>
                                        <DynamicContentEditorEntryRecord
                                            recordKey={'about'}
                                            _title={'About Us Title'}
                                            tag={Link}
                                            to={routes.public.about}
                                            valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                        />
                                    </li>
                                    <li>
                                        <DynamicContentEditorEntryRecord
                                            recordKey={'catalogue'}
                                            _title={'Catalogues Title'}
                                            tag={Link}
                                            to={routes.public.catalogue}
                                            valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                        />
                                    </li>
                                    <li>
                                        <DynamicContentEditorEntryRecord
                                            recordKey={'our-story'}
                                            _title={'Out Story\'s Title'}
                                            tag={Link}
                                            to={routeFunctions.public.about(AboutUsTabs.ourStory.hash)}
                                            valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                        />
                                    </li>
                                    <li>
                                        <DynamicContentEditorEntryRecord
                                            recordKey={'send-message'}
                                            _title={'Contact-us Title'}
                                            tag={Link}
                                            to={routeFunctions.public.about(AboutUsTabs.contactUs.hash)}
                                            valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                        />
                                    </li>
                                    <li>
                                        <DynamicContentEditorEntryRecord
                                            recordKey={'reseller-locator'}
                                            _title={'Reseller Locator Title'}
                                            tag={Link}
                                            to={routeFunctions.public.about(AboutUsTabs.resellerLocator.hash)}
                                            valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                        />
                                    </li>
                                    <li>
                                        <DynamicContentEditorEntryRecord
                                            recordKey={'our-team'}
                                            _title={'Our Team Title'}
                                            tag={Link}
                                            to={routeFunctions.public.about(AboutUsTabs.ourTeam.hash)}
                                            valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                        />
                                    </li>
                                </ul>
                            </DynamicContentEditorEntry>
                        </Col>
                        <Col xs={12}>
                            <div className="copyright">
                                Copyright {moment().format('yyyy')} Bizkey Tech | All Rights Reserved | Designed and
                                Developed by
                                <a href="https://bizkeytech.com/">
                                    Bizkey Tech
                                    <BizkeyTechLogo className={'developer-logo'}/>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </footer>

    )
}

export default Footer;
