import React, {useEffect, useMemo, useState} from "react";
import useIsMounted from "../../../hooks/use-is-mounted";
import {apiMethods} from "../../../../core/constants/enums";
import api from "../../../../core/services/api/api";
import {shoppingCartApis} from "../../../../core/constants/endpoints/endpoints";
import {dateComparator, numComparator, stringComparator} from "../../../../core/services/utils/utils";
import classnames from "classnames";
import {
    DataGridColumnAlignments,
    DataGridColumnPinnedTypes,
    DataGridColumnTypes
} from "../../../../packages/data-grid/models";
import AppDataGrid from "../../../containers/data-grid";


export const AvailableCreditsDataGridColumnNames = {
    transactionNo: 'transactionNo',
    reference: 'reference',
    date: 'date',
    type: 'type',
    credit: 'credit',
    balance: 'balance',
    select: 'select',
}

const initialPaginationInfo = {
    pageSize: 5,
    currentPage: 1,
    length: 0,
    sizes: [5, 10, 20],
};

const AvailableCreditsTable = ({
                                   onSelect,
                                   updating,
                                   disabled,
                                   selected,
                                   className,
                                   excludes,
                               }) => {
    const [loading, setLoading] = useState(true);
    const [credits, setCredits] = useState([]);
    const [visibleCredits, setVisibleCredits] = useState([]);
    const [orderBy, setOrderBy] = useState(null);
    const [paginationInfo, setPaginationInfo] = useState(initialPaginationInfo);
    const [orderByMethod, setOrderByMethod] = useState(() => (a, b) => 0);
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts:
     * - fetches all the available credits of the user from the server.
     */
    useEffect(() => {
        getAvailableCredits();
    }, [])

    /**
     * With each change in the [credits] and [paginationInfo] values of the state:
     * - selects a slice of the credits to be set as the visible credits.
     */
    useEffect(() => {
        if (!credits?.length) {
            return setVisibleCredits([]);
        }
        const start = Math.min(Math.max((paginationInfo.currentPage - 1) * paginationInfo.pageSize, 0), credits.length);
        const end = Math.min((paginationInfo.currentPage) * paginationInfo.pageSize, credits.length);
        const chunk = Array.from(credits).sort(orderByMethod).slice(start, end)
        setVisibleCredits(chunk);
    }, [credits, paginationInfo.currentPage, paginationInfo.pageSize, orderByMethod])

    /**
     * Fetches all the available credits of the user from the server and sets the state list.
     * */
    const getAvailableCredits = () => {
        setLoading(true);
        api({
            url: shoppingCartApis.getAvailableCredits,
            method: apiMethods.get,
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setLoading(false);
                return;
            }
            if (response?.resultFlag) {
                setCredits(response?.data ?? []);
                setPaginationInfo(prevState => ({...prevState, length: response?.data?.length ?? 0}))
            }
            setLoading(false);
        });
    }

    /**
     * Sorts the entries of the credits in place.
     * @param {DataGridSortBy} tableOrderBy
     */
    const onSort = (tableOrderBy) => {
        setOrderBy(tableOrderBy);
        setPaginationInfo(prevState => ({...prevState, currentPage: 1}));
        switch (tableOrderBy?.field) {
            case AvailableCreditsDataGridColumnNames.transactionNo:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? stringComparator(a?.transactionNo ?? '', b?.transactionNo ?? '')
                    : stringComparator(b?.transactionNo ?? '', a?.transactionNo ?? '')
                )
            case AvailableCreditsDataGridColumnNames.reference:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? stringComparator(a?.referenceDisplay ?? '', b?.referenceDisplay ?? '')
                    : stringComparator(b?.referenceDisplay ?? '', a?.referenceDisplay ?? '')
                )
            case AvailableCreditsDataGridColumnNames.date:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? dateComparator(a?.submittedDate ?? '', b?.submittedDate ?? '')
                    : dateComparator(b?.submittedDate ?? '', a?.submittedDate ?? '')
                )
            case AvailableCreditsDataGridColumnNames.type:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? stringComparator(a?.transactionTypeDescription ?? '', b?.transactionTypeDescription ?? '')
                    : stringComparator(b?.transactionTypeDescription ?? '', a?.transactionTypeDescription ?? '')
                )
            case AvailableCreditsDataGridColumnNames.credit:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? numComparator(a?.amount ?? 0, b?.amount ?? 0)
                    : numComparator(b?.amount ?? 0, a?.amount ?? 0)
                )
            case AvailableCreditsDataGridColumnNames.balance:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? numComparator(a?.balance ?? 0, b?.balance ?? 0)
                    : numComparator(b?.balance ?? 0, a?.balance ?? 0)
                )

            default:
                return setOrderByMethod(() => (a, b) => 0)
        }
    }

    const dataGridColumns = useMemo(() =>
            /**@type {DataGridColumn[]}*/
            [
                {
                    title: 'Transaction No',
                    name: AvailableCreditsDataGridColumnNames.transactionNo,
                    alignment: DataGridColumnAlignments.left,
                    type: DataGridColumnTypes.string,
                    width: 110,
                },
                {
                    title: 'Reference',
                    name: AvailableCreditsDataGridColumnNames.reference,
                    alignment: DataGridColumnAlignments.right,
                    type: DataGridColumnTypes.string,
                },
                {
                    title: 'Date',
                    name: AvailableCreditsDataGridColumnNames.date,
                    alignment: DataGridColumnAlignments.right,
                    type: DataGridColumnTypes.date,
                },
                {
                    title: 'Type',
                    name: AvailableCreditsDataGridColumnNames.type,
                    alignment: DataGridColumnAlignments.left,
                    type: DataGridColumnTypes.string,
                },
                {
                    title: 'Credit',
                    name: AvailableCreditsDataGridColumnNames.credit,
                    alignment: DataGridColumnAlignments.right,
                    type: DataGridColumnTypes.money,
                },
                {
                    title: 'Balance',
                    name: AvailableCreditsDataGridColumnNames.balance,
                    alignment: DataGridColumnAlignments.right,
                    type: DataGridColumnTypes.money,
                },
                {
                    name: AvailableCreditsDataGridColumnNames.select,
                    alignment: DataGridColumnAlignments.center,
                    type: DataGridColumnTypes.element,
                    sortable: false,
                    pinned: true,
                    pinnedToggleable: false,
                    pinnedType: DataGridColumnPinnedTypes.right,
                },
            ].filter(e => !excludes?.includes(e.name))
        , [excludes])

    /**@type {DataGridRow[]}
     */
    const dataGridRows = useMemo(() => {
        return Array.from(visibleCredits)
                ?.map((credit) => {
                    const _updating = updating(credit);
                    const _selected = selected(credit);
                    const _disabled = disabled(credit);
                    return {
                        key: credit.id,
                        data: credit,
                        cells: {
                            [AvailableCreditsDataGridColumnNames.transactionNo]: credit?.transactionNo,
                            [AvailableCreditsDataGridColumnNames.reference]: credit?.referenceDisplay,
                            [AvailableCreditsDataGridColumnNames.date]: credit?.submittedDate,
                            [AvailableCreditsDataGridColumnNames.type]: credit?.transactionTypeDescription,
                            [AvailableCreditsDataGridColumnNames.credit]: credit?.amount,
                            [AvailableCreditsDataGridColumnNames.balance]: credit?.balance,
                            [AvailableCreditsDataGridColumnNames.select]:
                                <button
                                    disabled={_disabled}
                                    className={classnames(
                                        'button primary px-3 py-2',
                                        {"outlined": !_selected,}
                                    )}
                                    onClick={() => onSelect(credit, _selected)}
                                >
                                    {
                                        _updating
                                            ? 'processing...'
                                            : _selected
                                                ? "Remove"
                                                : "Apply"
                                    }
                                </button>,
                        }
                    }
                })
            ?? []
    }, [visibleCredits, selected, updating, disabled, onSelect])


    return (
        <>
            <AppDataGrid
                hideFooterActions
                hideExporting
                classNames={{
                    container: classnames(className, 'available-credits-table'),
                }}
                rows={dataGridRows}
                columns={dataGridColumns}
                state={{
                    loading: {state: loading},
                    sortBy: orderBy,
                    pagination: paginationInfo,
                }}
                onSortByChanged={(_, current) => onSort(current)}
                onCurrentPageChanged={(_, current) => setPaginationInfo({...current, sizes: [5, 10, 20],})}
                onPageSizeChanged={(_, current) => setPaginationInfo({...current, sizes: [5, 10, 20],})}
            />
        </>
    )
}

export default AvailableCreditsTable;
