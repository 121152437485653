import React, {useEffect, useState} from 'react';
import {Col, Row} from "reactstrap";
import DropZone from "../../../components/base/drop-zone"
import UserAvatarDropzone from "../../../components/app-specific/user-avatar-files";
import ValidateMessages from "../../../../core/constants/validate-messages";
import {useDispatch} from "react-redux";
import {updateReduxProfileInformation} from "../../../../redux/actions";
import api from "../../../../core/services/api/api";
import {userApis} from "../../../../core/constants/endpoints/endpoints";
import {Fade} from "@material-ui/core";
import {apiMethods, InputFileAcceptMimes} from "../../../../core/constants/enums";
import useIsMounted from "../../../hooks/use-is-mounted";
import * as Yup from "yup";
import {makeRequired, makeValidate} from "mui-rff";
import MuiInput from "../../../components/base/input/mui-input";
import Form from "../../../components/base/form";
import {deepEqual} from "../../../../core/services/utils/utils";
import MuiPhoneInput from "../../../components/base/input/mui-phone-input";
import YupExtensions from "../../../../core/services/yup-extensions";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";

YupExtensions.phoneNo();

const schema = Yup.object().shape({
    firstName: Yup.string().nullable().required(ValidateMessages.required),
    lastName: Yup.string().nullable().required(ValidateMessages.required),
    email: Yup.string().email("Invalid email format").nullable().required(ValidateMessages.required),
    phone: Yup.string().trim()
        .nullable()
        .required(ValidateMessages.required)
        .phoneNo(ValidateMessages.incorrectType("phone"), 10)
});

const ProfileView = () => {
    const [tempFile, setTempFile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [profileInformation, setProfileInformation] = useState({})
    const [updatingProfileImage, setUpdatingProfileImage] = useState(false);
    const [updatingProfileInfo, setUpdatingProfileInfo] = useState(false);
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * As soon as the component mounts:
     * - fetches the profile information from the server.
     */
    useEffect(() => {
        getProfileInformation()
    }, [])

    /**
     * Fetches users' profile information from the server
     * if the result of the api is successful, updates both the internal state and profile cookies in redux state.
     */
    const getProfileInformation = () => {
        setLoading(true);
        api({
            url: userApis.getProfile,
            method: apiMethods.get,
        }).then((response) => {
            if (!isMounted() || response?.isPreemptedDueToNotBeingLoggedIn || !response?.resultFlag) return;
            const profileInformation = {
                firstName: response.data.firstname,
                lastName: response.data.lastname,
                email: response.data.email,
                phone: response.data.phone,
                profileFileName: response.data.profilePicInfo?.url,
                isImagePlaceholder: response.data?.profilePicInfo?.isPlaceholder,
            }
            setLoading(false);
            setProfileInformation(profileInformation);
            dispatch(updateReduxProfileInformation(profileInformation))
        })
    }

    /**
     * Updates the profile information of the user in the server.
     *
     * if the result of the api is successful, saves the updated information in both the internal state and redux state.
     * @param {any} values
     * @return {Promise<void>}
     */
    const updateProfileInformation = async (values) => {
        setUpdatingProfileInfo(true);
        const response = await api({
            url: userApis.updateProfile,
            method: apiMethods.post,
            data: values,
            showSuccess: true,
        });
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setUpdatingProfileInfo(false);
            return
        }
        if (response.resultFlag) {
            setProfileInformation(prevState => ({...prevState, ...values}))
            dispatch(updateReduxProfileInformation(values))
        }
        setUpdatingProfileInfo(false);
    };

    /**
     * Updates the profile image of the user in the server.
     *
     * if the result of the api is successful, saves the updated information in both the internal state and redux state.
     * @param {any} data
     * @param {function} onUploadProgress
     * @return {Promise<boolean>}
     */
    const updateProfileImage = async (data, onUploadProgress = null) => {
        setUpdatingProfileImage(true);
        const response = await api({
            url: userApis.updateProfileImage,
            method: apiMethods.post,
            data: data ?? {fileName: null, content: null},
            showSuccess: true,
            extra: {
                onUploadProgress: onUploadProgress
            }
        })
        setUpdatingProfileImage(false);
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return false;
        if (response?.resultFlag) {
            setProfileInformation(prevState => ({
                ...prevState,
                profileFileName: response.data?.url,
                isImagePlaceholder: response.data?.isPlaceholder,
            }))
            dispatch(updateReduxProfileInformation({
                profileFileName: response.data?.url,
                isImagePlaceholder: response.data?.isPlaceholder,
            }))
        }
        setTempFile(null);
        return response?.resultFlag ?? false;
    }

    /**
     * Sets the tempFile from the first file of the selected files.
     * @param files
     */
    const onFileDropped = (files) => {
        if (!files.length) {
            return setTempFile(null);
        }
        setTempFile(files[files.length - 1])
    };

    return (
        <>
            <div className={'profile-view'}>
                <Row>
                    <Col xs={12}>
                        <div className="heading">
                            Profile Information
                        </div>
                    </Col>
                </Row>
                {
                    loading
                        ? <Row className='text-center justify-content-center'>
                            <LoadingIndicator/>
                        </Row>
                        : <Row>
                            <Col md={6} xs={12}>
                                <DropZone
                                    id={'profile-picture'}
                                    loading={loading}
                                    dropZoneClassName={'user-avatar-files-dropzone'}
                                    uploading={updatingProfileImage}
                                    openOnClick={true}
                                    multiple={false}
                                    setFiles={onFileDropped}
                                    accept={[InputFileAcceptMimes.images]}
                                    notAcceptedDurationInMs={2000}
                                >
                                    {(_, highlight, notAccepted) =>
                                        <UserAvatarDropzone
                                            highlight={highlight}
                                            notAccepted={notAccepted}
                                            url={profileInformation.profileFileName}
                                            file={tempFile}
                                            callback={updateProfileImage}
                                        />
                                    }
                                </DropZone>
                                <Fade
                                    in={profileInformation.profileFileName?.length && !profileInformation.isImagePlaceholder}>
                                    <div className={'d-flex w-100 justify-content-center'}>
                                        <button className=' primary button px-3 mt-3 mx-auto'
                                                onClick={() => updateProfileImage(null)}
                                                disabled={updatingProfileImage}>
                                            {
                                                updatingProfileImage
                                                    ? "Removing..."
                                                    : "Remove Image"
                                            }
                                        </button>
                                    </div>
                                </Fade>
                            </Col>
                            <Col md={6} xs={12} className="mt-5 mt-md-0">
                                <Form
                                    className='form'
                                    onSubmit={updateProfileInformation}
                                    validate={validate}
                                    initialValues={profileInformation}
                                    render={({values, initialValues, form}) => {
                                        return (
                                            <Row>
                                                <Col xs={12} className={'mb-4'}>
                                                    <MuiInput
                                                        form
                                                        label={"First Name"}
                                                        placeholder={'First name'}
                                                        name={'firstName'}
                                                        required={required.firstName}
                                                    />
                                                </Col>
                                                <Col xs={12} className={'mb-4'}>
                                                    <MuiInput
                                                        form
                                                        label={"Last Name"}
                                                        placeholder={'Last name'}
                                                        name={'lastName'}
                                                        required={required.lastName}
                                                    />
                                                </Col>
                                                <Col xs={12} className={'mb-4'}>
                                                    <MuiInput
                                                        form
                                                        label={"Email"}
                                                        type={'email'}
                                                        placeholder={'Email'}
                                                        name={'email'}
                                                        required={required.email}
                                                    />
                                                </Col>
                                                <Col xs={12}>
                                                    <MuiPhoneInput
                                                        type={'tel'}
                                                        placeholder={'Phone'}
                                                        label={"Phone"}
                                                        name={'phone'}
                                                        required={required.phone}
                                                        value={values.phone}
                                                        onChange={(e) => form.change(e.target.name, e.target.value)}
                                                        form={form}
                                                    />
                                                </Col>
                                                <Col xs={12}>
                                                    <div className="d-flex justify-content-center my-4">
                                                        <button className='primary button px-5'
                                                                type={'submit'}
                                                                disabled={updatingProfileInfo || deepEqual(values, initialValues)}>
                                                            {
                                                                updatingProfileInfo
                                                                    ? "Saving..."
                                                                    : "Save Changes"
                                                            }
                                                        </button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        );
                                    }}
                                />
                            </Col>
                        </Row>
                }
            </div>
        </>
    );
}

export default ProfileView;
