import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {accessDeniedDialog} from "../../../../redux/actions";
import useRouter from "../../../hooks/use-router";
import {ReactComponent as AlertIcon} from "../../../../assets/images/alert.svg";

const initAlert = {
    visible: false,
    msg: '',
};

const AccessDeniedDialog = () => {
    const {history} = useRouter();
    const [alert, setAlert] = useState(initAlert);
    const {message, open} = useSelector(state => state?.dialogs?.accessDenied ?? {message: '', open: false});
    const dispatch = useDispatch();

    /**
     * Listens to the changes in open and message state in the access denied redux slice and with each change:
     * if not open, then closes the alert too,
     * if open, then opens the alert along with setting its text.
     * The order of the text is as follows: message >> static text
     */
    useEffect(() => {
        if (!open) {
            return setAlert(initAlert);
        }
        setAlert({
            visible: true,
            msg: message,
        })
    }, [open, message])

    /**
     * Closes the access denied dialog along and navigates the user back.
     */
    const toggle = () => {
        dispatch(accessDeniedDialog(!open));
        history.goBack();
    }

    return (
        <Modal isOpen={open} centered backdrop={'static'} className={'access-denied-dialog'}>
            <ModalHeader>
                Access Denied
            </ModalHeader>
            <ModalBody>
                <div className="message-section">
                    <AlertIcon/>
                    <p>
                        {alert.msg ?? 'Access Denied, please Contact IT Support'}
                    </p>
                </div>
                <Row className={'no-gutters w-100 align-items-start justify-content-center'}>
                    <button
                        onClick={toggle}
                        type="button"
                        className="button primary m-auto px-5">
                        Back
                    </button>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default AccessDeniedDialog;
