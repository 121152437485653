import React, {useEffect, useMemo} from "react";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {
    apiMethods,
    ShoppingCartInjectedPropertyNames,
    ShoppingCartItemBoxTypes,
    ShoppingCartProcessSteps
} from "../../../../../core/constants/enums";
import api from "../../../../../core/services/api/api";
import {shoppingCartApis} from "../../../../../core/constants/endpoints/endpoints";
import ProductsAutoComplete from "../../../../components/base/auto-complete/products";
import ShoppingCartItemBox from "../../../../components/app-specific/shopping-cart-item-box";
import {toast} from "react-toastify";

const ShoppingCartViewItems = ({step, goToNextStep, cart, setCart, disabled}) => {
    const isMounted = useIsMounted();

    cart = cart ?? {};
    const count = [
        ...(cart?.parts?.map(e => e[ShoppingCartInjectedPropertyNames.item.newQuantity] ?? e.quantity ?? 0) ?? []),
        0,
        0,
    ].reduce((p, c) => p + c)
    const delayedItems = cart[ShoppingCartInjectedPropertyNames.delayedItems];
    const delayedItemsCount = [
        ...(delayedItems?.map(e => e?.parts ?? []) ?? []),
        [],
        []
    ].reduce((p, c) => p + c.length, 0)

    /**
     * Listens for the changes in steps and delayedItemsCount and with each change:
     * - if the step is delayed items, and their total count becomes zero, then navigates them to the next logical
     * step
     */
    useEffect(() => {
        if (step !== ShoppingCartProcessSteps.delayedItems) return;
        if (delayedItemsCount <= 0) {
            toast.success("No more delayed products, you can continue with your shopping cart.")
            goToNextStep();
        }
    }, [step, delayedItemsCount])

    /**
     * Adds a specific product to users' shopping cart items.
     *
     * - if the item being added already exists in the shopping cart, simply increases its quantity by 1.
     * @param {any} option the selected product
     * @return {Promise<void>}
     */
    const addToCart = async (option) => {
        const found = cart?.parts?.find(e => e.partNo === option.partNumber);
        if (found) {
            setLoadingForProduct(found, ShoppingCartInjectedPropertyNames.item.updating, true);
        }
        const response = await api({
            url: shoppingCartApis.updateItems,
            method: apiMethods.put,
            data: {
                partCountChangedList: [
                    {
                        partNo: option.partNumber,
                        finalCount: (
                            found
                                ? found[ShoppingCartInjectedPropertyNames.item.newQuantity] ?? found?.quantity ?? 0
                                : 0
                        ) + 1
                    }
                ]
            }
        })
        if (!isMounted()) return;
        if (found) {
            setLoadingForProduct(found, ShoppingCartInjectedPropertyNames.item.updating, false);
        }
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            return;
        }
        if (response?.resultFlag) {
            setCart(response?.data);
        }
    }

    /**
     * Sets the loading state of this item in the shopping cart.
     *
     * @param {any} item the item to change its loading state.
     * @param {string} name the loading state name of the item
     * @param {boolean} value the value of the loading
     */
    const setLoadingForProduct = (item, name, value = false) => {
        setCart(prevState => ({
            ...prevState,
            parts: prevState.parts?.map(e => e.partNo === item.partNo
                ? {...e, [name]: value}
                : e
            ) ?? [],
        }))
    }

    /**
     * Determines the shopping cart item type based on the current step of the shopping cart.
     * @return {string}
     */
    const itemType = useMemo(() => {
        switch (step) {
            case ShoppingCartProcessSteps.items:
            default:
                return ShoppingCartItemBoxTypes.normal;
            case ShoppingCartProcessSteps.delayedItems:
                return ShoppingCartItemBoxTypes.delayed;
        }
    }, [step])

    /**
     * Renders the content of this component based on the current step of the process.
     */
    const renderContent = () => {
        switch (step) {
            case ShoppingCartProcessSteps.items:
            default:
                return (
                    <>
                        <ProductsAutoComplete onSelect={addToCart}/>
                        <div className={'d-flex flex-column'}>
                            {
                                cart?.parts?.map(item => (
                                    <ShoppingCartItemBox
                                        key={item.partNo}
                                        cart={cart}
                                        disabled={disabled}
                                        item={item}
                                        setCart={setCart}
                                        type={itemType}
                                        setLoading={(n, v) => setLoadingForProduct(item, n, v)}
                                    />
                                ))
                            }
                        </div>
                    </>
                );
            case ShoppingCartProcessSteps.delayedItems:
                return delayedItems?.map((category, index) => (
                    <div key={index} className={'d-flex flex-column w-100'}>
                        <div className={'order-validation-text'}>
                            {category.message ?? ""}
                        </div>
                        <div className={'d-flex flex-column'}>
                            {
                                cart?.parts
                                    ?.filter(e => category?.parts?.includes(e?.partNo))
                                    ?.map(item => (
                                        <ShoppingCartItemBox
                                            key={item.partNo}
                                            cart={cart}
                                            disabled={disabled}
                                            item={item}
                                            setCart={setCart}
                                            type={itemType}
                                            setLoading={(n, v) => setLoadingForProduct(item, n, v)}
                                        />
                                    ))
                            }
                        </div>
                    </div>
                ));
        }
    }

    return (
        <>
            <div className={'d-flex flex-column w-100 items'}>
                <div className={'subtitle'}>
                    Quote Number: <span>{cart.quoteNumber}</span>
                </div>
                <div className={'item-counter'}>
                    There are <span>{count}</span> {count !== 1 ? "items" : "item"} in your cart.
                </div>
                {renderContent()}
            </div>
        </>
    );
}


export default ShoppingCartViewItems;
