import React, {useMemo} from "react";
import useRouter from "../../../../hooks/use-router";
import {
    HomeViewComponentSearchQueryTypes,
    HomeViewComponentTypes,
    ProductBoxTypes,
    ProductsSearchTypes,
    TechnicalPropertyPrefix
} from "../../../../../core/constants/enums";
import ProductSearchUtils from "../../../../../core/services/utils/product-search-utils";
import routes from "../../../../routes";
import Regexes from "../../../../../core/constants/regexes";
import ProductBox from "../../product-box";
import classnames from "classnames";
import ProductSlider from "../../product-slider";

const HomeViewProducts = ({data}) => {
    const {history, stringifyUrl} = useRouter();
    const type = useMemo(() => {
        switch (data?.type?.id) {
            case HomeViewComponentTypes?.smallProduct:
                return ProductBoxTypes.small;
            case HomeViewComponentTypes?.mediumProduct:
            default:
                return ProductBoxTypes.medium;
            case HomeViewComponentTypes?.largeProduct:
                return ProductBoxTypes.large;
        }
    }, [data])

    const products = data?.content?.parts ?? [];

    /**
     * Prepares the search query of the products based on their search type.
     * @return {[string,{year: string, orderBy: string, model: string, make: string},*,{}]|[string,{[p: string]: any, orderBy: string, categoryId: string},(*&{properties: undefined}),{[p: string]: any}]|*[]|[string,{tagIds: string, orderBy: string, keyword: string, categoryId: string},*,{}]}
     */
    const prepareSearchQuery = () => {
        let queryKeys;
        let formKeys;
        let values;
        switch (data.searchType) {
            case HomeViewComponentSearchQueryTypes.application:
                queryKeys = ProductSearchUtils.applicationSearchQueryKeys;
                formKeys = {
                    makeId: 'makeId',
                    modelId: 'modelId',
                    year: 'year',
                    orderBy: 'orderBy',
                }
                return [
                    ProductsSearchTypes.application,
                    formKeys,
                    data.searchQuery,
                    {
                        [queryKeys.make]: formKeys.makeId,
                        [queryKeys.model]: formKeys.modelId,
                        [queryKeys.year]: formKeys.year,
                        [queryKeys.orderBy]: formKeys.orderBy,
                    },
                ]
            case HomeViewComponentSearchQueryTypes.basic:
                queryKeys = ProductSearchUtils.basicSearchQueryKeys;
                formKeys = {
                    categoryId: 'categoryId',
                    keyword: 'keyword',
                    orderBy: 'orderBy',
                    tags: 'tags',
                }
                return [
                    ProductsSearchTypes.basic,
                    formKeys,
                    {
                        ...data.searchQuery,
                        tags: data.searchQuery?.tagIds?.map(e => ({id: e})),
                    },
                    {
                        [queryKeys.categoryId]: formKeys.categoryId,
                        [queryKeys.keyword]: formKeys.keyword,
                        [queryKeys.orderBy]: formKeys.orderBy,
                        [queryKeys.tags]: formKeys.tags,
                    }
                ]
            case HomeViewComponentSearchQueryTypes.technical:
                queryKeys = ProductSearchUtils.technicalSearchQueryKeys;
                const propertyKeys = Object.fromEntries(data.searchQuery?.properties?.map(e => [`${TechnicalPropertyPrefix}${e.id}`, `${TechnicalPropertyPrefix}${e.id}`])) ?? {}
                formKeys = {
                    categoryId: 'categoryId',
                    orderBy: 'orderBy',
                    ...propertyKeys
                }
                values = {
                    ...data.searchQuery,
                    properties: undefined,
                    ...(Object.fromEntries(data.searchQuery?.properties?.map(e => [`${TechnicalPropertyPrefix}${e.id}`, e.value])) ?? {})
                }
                return [
                    ProductsSearchTypes.technical,
                    formKeys,
                    values,
                    {
                        [queryKeys.categoryId]: formKeys.categoryId,
                        [queryKeys.orderBy]: formKeys.orderBy,
                        ...propertyKeys
                    }
                ]
            default:
                return [];
        }

    }

    /**
     * Navigates to the search view with the provided search query of the data.
     */
    const navigateToProducts = () => {
        const [searchType, formKeys, values, formToQueryMapping] = prepareSearchQuery()
        history.push(stringifyUrl({
                url: routes.main.products,
                query: {
                    type: searchType,
                    data: JSON.stringify({
                        [searchType]: ProductSearchUtils.transformFormValuesToSearchQuery(
                            formKeys,
                            values,
                            formToQueryMapping,
                            false,
                            false,
                            searchType === ProductsSearchTypes.technical
                                ? (key) => key.match(Regexes.technicalPropertyFinder)
                                : null
                        )
                    })
                },
            })
        )
    }

    /**
     * Renders the container of the product based on the type of the product.
     *
     * * if small: renders the slider.
     * @return {JSX.Element}
     */
    const renderContainer = () => {
        switch (type) {
            case ProductBoxTypes.small:
                return <ProductSlider type={type} products={products}/>
            default:
                return (
                    <div className={classnames('d-flex flex-wrap w-100 justify-content-center', {
                        'justify-content-md-start': type !== ProductBoxTypes.large && data?.type?.id !== HomeViewComponentTypes.timeBased,
                        'justify-content-md-center justify-content-lg-start': type === ProductBoxTypes.large,
                    })}>
                        {
                            products.map((part) => (
                                <ProductBox key={part?.id} type={type} data={part}/>
                            ))
                        }
                    </div>
                );

        }
    }

    /**
     * Renders the see more button
     * @return {JSX.Element}
     */
    const renderSeeMoreButton = () => {
        return (
            !!data?.searchQuery ?
                <div>
                    <button
                        onClick={navigateToProducts}
                        className={classnames('button primary py-2', {
                            'time-based-see-more': data?.type.id === HomeViewComponentTypes.timeBased,
                            'outlined': data?.type.id !== HomeViewComponentTypes.timeBased
                        })}>
                        See All
                    </button>
                </div> :
                <div className={'mt-5'}/>
        );
    }

    return (
        <>
            <div className={'home-view-products'}>
                {
                    data?.type.id !== HomeViewComponentTypes.timeBased ?
                        (
                            <div>
                                <div className={'sticky-header w-100 pb-3 mb-4'}>
                                    <div className={'heading small mb-0'}>
                                        {data?.title ?? ''}
                                    </div>
                                    {renderSeeMoreButton()}
                                </div>
                                {renderContainer()}
                            </div>
                        ) :
                        <div>
                            {renderSeeMoreButton()}
                            {renderContainer()}
                        </div>
                }
            </div>
        </>
    );
}

export default HomeViewProducts;
