import React, {useEffect, useState} from "react";
import {LazyLoadImage as Lazy} from "react-lazy-load-image-component";
import NoImage from "../../../../assets/images/no-image.png";
import EnvService from "../../../../core/services/env-service";

/**
 *
 * @param srcProp
 * @param alt
 * @param placeHolder
 * @param onError
 * @param discardNoImage
 * @param onLoad
 * @param {number | string | undefined | function} height
 * @param {import('react-lazy-load-image-component').LazyLoadImageProps} props
 * @return {JSX.Element}
 * @constructor
 */
const LazyImage = ({src: srcProp, alt, placeHolder, height, onError, onLoad, discardNoImage, ...props}) => {
    const [src, setSrc] = useState(srcProp);

    /**
     * Listens for the changes in srcProp and with each change:
     * sets the src of the state.
     */
    useEffect(() => {
        setSrc(srcProp);
    }, [srcProp])

    /**
     * Changes the src of the image to noImage if there is noImage available and the src is not already noImage
     */
    const useDefaultImage = (e) => {
        if (EnvService.isDevelopment)
            console.log("Image loading failed", src, (NoImage))

        if (!discardNoImage) {
            if (src !== (placeHolder ?? NoImage)) {
                setSrc((placeHolder ?? NoImage));
            }
        }
        if (onError) {
            onError(e);
        }
    }

    /**
     * Adds the loaded class name to the wrapper of the image as soon as the image loads.
     * @param {Event} e
     */
    const onImageLoaded = (e) => {
        const span = e.target?.parentElement;
        if (span) {
            span.classList.add('lazy-load-image-loaded');
        }
        if (onLoad) {
            onLoad(e);
        }
    }

    if (src || discardNoImage) {
        return (
            <Lazy src={src}
                  effect="opacity"
                  alt={alt}
                  onError={useDefaultImage}
                  {...(
                      onLoad
                          ? {onLoad: onImageLoaded,}
                          : {}
                  )}
                  height={typeof height === "function" ? height() : height}
                  {...props}
            />
        );
    } else {
        return (
            <Lazy src={NoImage}
                  effect="opacity"
                  alt="No"
                  onError={onError}
                  height={typeof height === "function" ? height() : height}
                  {...(
                      onLoad
                          ? {onLoad: onImageLoaded,}
                          : {}
                  )}
                  {...props}
            />
        );
    }
};

export default LazyImage;
