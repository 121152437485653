import {useContext, useMemo} from "react";
import {DynamicContentEditorStateContext} from "../../contexts";
import {DynamicContentEditorEntryDataTypes, DynamicContentEditorMode} from "../../../index.d";

/**
 * Fetches the Data of the given entry with the specified data type and the edit mode state of the DCE for the
 * parent component.
 * @param {string} entryName
 * @param {DynamicContentEditorEntryDataTypes} dataType
 * @return {[
 * (DynamicContentEditorPageEntryData<string> | undefined),
 * string,
 * boolean,
 * ]}
 */
const useDynamicContentEditorEntryDataFetcher = (entryName, dataType) => {
    const state = useContext(DynamicContentEditorStateContext);

    const inEditMode = useMemo(() => state.mode === DynamicContentEditorMode.edit, [state.mode])

    /**@type {DynamicContentEditorPageEntry<string>}*/
    const entry = useMemo(() => {
        switch (dataType) {
            case DynamicContentEditorEntryDataTypes.fixed:
                return state.data.fixed?.find(e => e.name === entryName);
            case DynamicContentEditorEntryDataTypes.pageBased:
            default:
                return state.data.pageBased?.[state.currentPage]?.find(e => e.name === entryName);
        }
    }, [entryName, dataType, state.currentPage, state.data])

    return useMemo(() => [
        entry?.data,
        state.currentPage,
        inEditMode
    ], [entry?.data, state?.currentPage, inEditMode])
}

export default useDynamicContentEditorEntryDataFetcher;
