import React, {useEffect, useState} from 'react';
import {Col, Container, Row} from "reactstrap";
import {controller} from "../../../../core/services/api/controller";
import useIsMounted from "../../../hooks/use-is-mounted";
import useRouter from "../../../hooks/use-router";
import routes from "../../../routes";
import FloatingContainer from "../../../components/app-specific/floating-container";
import {apiMethods} from "../../../../core/constants/enums";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";


const PageView = () => {
    const {params, history} = useRouter();
    const [state, setState] = useState({
        title: '',
        pageContent: '',
        loading: true
    })
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts:
     * - checks that the slug exists, and if not navigates the user to the error view
     * - fetches the page data to be displayed.
     */
    useEffect(() => {
        if (!params?.slug) {
            return history.replace(routes.error);
        }
        getPage();
    }, [])

    /**
     * Fetches the information of the requested page slug to be shown.
     */
    const getPage = () => {
        controller({
            "page": {
                action: apiMethods.post,
                url: `Page/Get`,
                params: {
                    "Data": params?.slug,
                },
                loginRequired: false,
            }
        }).then(({page}) => {
            if (!isMounted()) return;
            if (page?.isPreemptedDueToNotBeingLoggedIn)
                return;
            if (page?.Code === 100) {
                return setState({
                    title: page.List[0].Title,
                    pageContent: page.List[0].PageContent,
                    loading: false
                })
            }
            history.replace(routes.error);
        })
    };

    return (
        <>
            <main className={'page-view'}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <FloatingContainer setHeight>
                                {
                                    state.loading
                                        ? <Col xs={12} className={'text-center py-100'}>
                                            <LoadingIndicator/>

                                        </Col>
                                        : <div className="page-content">
                                            <h1>
                                                {state?.title ?? ''}
                                            </h1>
                                            <div className="content"
                                                 dangerouslySetInnerHTML={{__html: state?.pageContent ?? '<p></p>'}}/>
                                        </div>
                                }
                            </FloatingContainer>
                        </Col>
                    </Row>
                </Container>
            </main>

        </>
    );
}

export default PageView;
