import React, {forwardRef, useEffect, useMemo, useState} from "react";
import useRouter from "../../../hooks/use-router";
import {apiMethods, ProductsSearchTypes} from "../../../../core/constants/enums";
import useIsMounted from "../../../hooks/use-is-mounted";
import {TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import ProductsSearchBasicForm from "./types/basic";
import ProductsSearchApplicationForm from "./types/application";
import ProductsSearchTechnicalForm from "./types/technical";
import FloatingContainer from "../../app-specific/floating-container";
import useWindowViewportWidth from "../../../hooks/use-window/viewport-width";
import ProductsSearchElasticTechnicalForm from "./types/elastic-technical";
import {controller} from "../../../../core/services/api/controller";
import api from "../../../../core/services/api/api";
import {lookupApis} from "../../../../core/constants/endpoints/endpoints";

const Tabs = {
    basic: '1',
    application: '2',
    technical: '3',
    elasticTechnical: '4',
}

/**
 * Converts the given type of the searchSection to the appropriate tab.
 * @param type
 * @return {string}
 */
const convertTypeToTab = (type) => {
    switch (type) {
        case ProductsSearchTypes.technical:
            return Tabs.technical;
        case ProductsSearchTypes.elasticTechnical:
            return Tabs.elasticTechnical;
        case ProductsSearchTypes.application:
            return Tabs.application;
        case ProductsSearchTypes.basic:
        default:
            return Tabs.basic;
    }
}

const ProductsSearchSection = forwardRef(({inElasticTechnicalSearchResults, className}, ref) => {
    const {query} = useRouter();
    const [activeTab, setActiveTab] = useState(convertTypeToTab(query?.type ?? ProductsSearchTypes.basic));
    const [basicFormData, setBasicFormData] = useState({categories: [], orderByList: []});
    const [applicationSearchFormData, setApplicationSearchFormData] = useState({makeAndModels: [], years: []});
    const [technicalSearchFormData, setTechnicalSearchFormData] = useState([]);
    const isMounted = useIsMounted();
    const windowViewPortWidth = useWindowViewportWidth();

    const data = useMemo(() => query.data ? JSON.parse(query?.data ?? '{}') : {}, [query?.data]);
    const showSeparator = useMemo(() => !['xs', 'sm'].includes(windowViewPortWidth), [windowViewPortWidth]);

    const technicalDataProps = useMemo(() => {
        if (activeTab === Tabs.technical) {
            return data[ProductsSearchTypes.technical] ?? {}
        }
        return {};
    }, [data, activeTab])

    const elasticTechnicalDataProps = useMemo(() => {
        if (activeTab === Tabs.elasticTechnical) {
            return data[ProductsSearchTypes.elasticTechnical] ?? {}
        }
        return {};
    }, [data, activeTab])

    const applicationDataProps = useMemo(() => {
        if (activeTab === Tabs.application) {
            return data[ProductsSearchTypes.application] ?? {}
        }
        return {};
    }, [data, activeTab])

    const basicDataProps = useMemo(() => {
        if (activeTab === Tabs.basic) {
            return data[ProductsSearchTypes.basic] ?? {}
        }
        return {};
    }, [data, activeTab])

    /**
     * As soon as the component mounts:
     * -  fetches the application and technical form data from the server.
     * -  fetches the basic search form data from the server.
     */
    useEffect(() => {
        getAdvancedSearchFormData().then();
        getBasicFormRawData().then();
    }, []);

    /**
     * With each change in type property of search params:
     * - sets the active tab of the search section.
     */
    useEffect(() => {
        setActiveTab(convertTypeToTab(query?.type ?? ProductsSearchTypes.basic))
    }, [query?.type])

    /**
     * Fetches the basic search form data from the server and sets the state.
     */
    const getBasicFormRawData = async () => {
        const response = await controller({
            "Form": {
                url: 'Form/BasicSearch',
            }
        }).then(({Form}) => {
            let response = {};
            if (response?.isPreemptedDueToNotBeingLoggedIn)
                return response;
            if (Form?.Code === 100) {
                response = Array.isArray(Form.List) ? Form.List[0] : {};
            }
            return response;
        })
        if (!isMounted())
            return;
        setBasicFormData({
            categories: response?.CategoryList ?? [],
            orderByList: response?.PartOrderList ?? [],
        });
    }

    /**
     * Fetches the advanced search form data from the server and sets the state.
     */
    const getAdvancedSearchFormData = async () => {
        const applicationResponse = await api({
            url: lookupApis.applicationSearchForm,
            method: apiMethods.get,
            showError: false,
        })
        const technicalResponse = await api({
            url: lookupApis.technicalSearchForm,
            method: apiMethods.get,
            showError: false,
        })
        const res = {
            application: {},
            technical: {},
        }
        if (applicationResponse?.resultFlag && !applicationResponse?.isPreemptedDueToNotBeingLoggedIn) {
            res.application = {
                makeAndModels: applicationResponse?.data?.makesList?.map(make => ({
                    ...make,
                    models: applicationResponse?.data?.modelsList
                        ?.filter(model => model.makeId === make.id)
                        ?.map(model => ({
                            ...model,
                            years: generateYears(model.fromYear, model.toYear)
                        })) ?? []
                })) ?? [],
                orderByList: applicationResponse?.data?.partOrderByList ?? [],
            }
        }
        if (technicalResponse?.resultFlag && !technicalResponse?.isPreemptedDueToNotBeingLoggedIn) {
            res.technical = {
                categories: technicalResponse?.data?.categoriesList ?? [],
                orderByList: technicalResponse?.data?.partOrderByList ?? [],
            }
        }
        if (!isMounted())
            return;
        setApplicationSearchFormData(res.application);
        setTechnicalSearchFormData(res.technical);
    }

    /**
     * Generates an array of years for each the models using the fromYear to toYear
     * @param fromYear {number}
     * @param toYear {number}
     * @return {any[]}
     */
    const generateYears = (fromYear, toYear) => {
        const res = [];
        for (let i = fromYear; i <= toYear; ++i) {
            res.push(i);
        }
        return res;
    }

    return (
        <>
            <div ref={ref} className={classnames('products-search-section', className)}>
                <FloatingContainer marginTop={0}>
                    <div
                        className={'tab-buttons-section flex-md-row'}>
                        <button
                            onClick={() => setActiveTab(Tabs.basic)}
                            className={classnames({'active': activeTab === Tabs.basic}, 'mb-2 mb-md-0')}
                        >
                            Basic Search
                        </button>
                        <div
                            className={classnames({'tab-buttons-section-separator': showSeparator})}/>
                        <button
                            onClick={() => setActiveTab(Tabs.application)}
                            className={classnames({'active': activeTab === Tabs.application}, 'mb-2 mb-md-0')}
                        >
                            Application Search
                        </button>
                        <div
                            className={classnames({'tab-buttons-section-separator': showSeparator})}/>
                        <button
                            onClick={() => setActiveTab(Tabs.elasticTechnical)}
                            className={classnames({'active': activeTab === Tabs.technical || activeTab === Tabs.elasticTechnical})}
                        >
                            <div className={'d-flex justify-content-center align-items-center'}>
                                Technical Search <span className={'elastic-technical-search-indicator'}>(NEW)</span>
                            </div>
                        </button>
                    </div>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={Tabs.basic}>
                            <ProductsSearchBasicForm
                                formData={basicFormData}
                                data={basicDataProps}
                            />
                        </TabPane>
                        <TabPane tabId={Tabs.application}>
                            <ProductsSearchApplicationForm
                                formData={applicationSearchFormData}
                                data={applicationDataProps}
                            />
                        </TabPane>
                        {
                            activeTab === Tabs.technical &&
                            <TabPane tabId={Tabs.technical}>
                                <ProductsSearchTechnicalForm
                                    formData={technicalSearchFormData}
                                    categoriesFlat={basicFormData?.categories ?? []}
                                    data={technicalDataProps}
                                />
                            </TabPane>
                        }
                        {
                            activeTab === Tabs.elasticTechnical &&
                            <TabPane tabId={Tabs.elasticTechnical}>
                                <ProductsSearchElasticTechnicalForm
                                    categoriesFlat={basicFormData?.categories ?? []}
                                    data={elasticTechnicalDataProps}
                                    parsedQuery={data}
                                    inSearchResults={inElasticTechnicalSearchResults}
                                />
                            </TabPane>
                        }
                    </TabContent>
                </FloatingContainer>
            </div>
        </>
    )
})

export default ProductsSearchSection;
