import React, {useEffect} from "react";
import useRouter from "../../hooks/use-router";
import BroadcastService from "../../../core/services/broadcast-service";
import {Api} from "../../../core/services/api/api";

const BroadcastManager = () => {
    const {history, location} = useRouter();

    /**
     * With each change in the url location,
     */
    useEffect(() => {
        const cleanups = [
            resolveUserTokenExpiredEvents(),
        ]
        return () => {
            for (const cleanup of cleanups) {
                cleanup();
            }
        }
    }, [location]);

    /**
     * Adds an event listener for all the dispatched events of type [BroadcastService.channelNames.userTokenExpired].
     * @return {function(): void} the cleanup function
     */
    const resolveUserTokenExpiredEvents = () => {
        return BroadcastService.addEventListener(
            BroadcastService.channelNames.userTokenExpired,
            'message',
            onUserTokenExpiredEventReceived
        ).close
    }


    /**
     * Handles the Broadcasting events specific for the [BroadcastService.channelNames.userTokenExpired].
     *
     * * NOTE: this handler is almost always invokes by the api service of the application due to an unauthorized
     * request to the server that has been preempted in the fronted.
     * * Invokes a static method of Api service responsible for this event handling.
     * @param {any} messageEvent
     */
    const onUserTokenExpiredEventReceived = (messageEvent) => {
        const pathname = messageEvent?.pathname ?? location.pathname;

        if (pathname !== location.pathname)
            return;

        Api.onRequestPreemptedDueToAuthentication(history, messageEvent, true);
    }

    return (<></>);
}

export default BroadcastManager;
