import React, {useContext, useEffect, useLayoutEffect, useRef} from "react";
import {DataGridMiscContext} from "../../../index";
import {CircularProgress} from "@mui/material";
import ObserversService from "../../../../../core/services/observers-service";


const DataGridLoading = ({rowsLength}) => {
    const {LoadingComponent, visibleRows} = useContext(DataGridMiscContext);
    /**@type {React.MutableRefObject<HTMLTableElement>}*/
    const containerRef = useRef();

    /**
     * As soon as the component unmounts:
     * - removes the injected height of the [tbody] element of the table.
     */
    useLayoutEffect(() => {
        const containerElement = containerRef.current?.parentElement;
        const tBodyElement = containerElement?.querySelector('tbody');
        const tableElement = containerElement?.querySelector('table');
        const tableBodyHeight = tBodyElement.style.height;
        const tableMargin = tableElement.style.marginBottom;
        return () => {
            tableElement.style.marginBottom = tableMargin;
            // we do thing since if we have no rows, the empty Components shall do this for us as soon as it unmounts
            if (!rowsLength)
                return;
            tBodyElement.style.height = tableBodyHeight;
        }
    }, [])

    /**
     * With each change in the height of the container element:
     * - Sets the position properties of the loading container.
     */
    useLayoutEffect(() => {
        const containerElement = containerRef.current?.parentElement;
        const layoutElement = containerElement?.querySelector('.data-grid-layout');
        const toolbarElement = containerElement?.querySelector('div.data-grid-toolbar');
        const tBodyElement = containerElement.querySelector('tbody');
        const tHeaderElement = containerElement.querySelector('thead');

        if (!layoutElement || !tBodyElement || !tHeaderElement)
            return;

        const containerElementStyles = getComputedStyle(containerElement);
        const tableHeaderSize = tHeaderElement.getBoundingClientRect();

        const toolbarHeight = toolbarElement?.getBoundingClientRect()?.height ?? 0;

        containerRef.current.style.left = containerElementStyles.paddingLeft;
        containerRef.current.style.right = containerElementStyles.paddingRight;
        containerRef.current.style.top = `${tableHeaderSize.height + toolbarHeight}px`;

    }, [containerRef, rowsLength, visibleRows]);

    /**
     * With each change in the [containerRef], [rowsLength] value and [visibleRows] value:
     * - attaches an observer that would sync the size of the loading component's container.
     */
    useEffect(() => {
        const containerElement = containerRef.current?.parentElement;
        const layoutElement = containerElement?.querySelector('.data-grid-layout');
        if (!layoutElement)
            return;
        const observer = ObserversService.newResizeObserver(syncSize);
        ObserversService.observeResizeObserver(observer, layoutElement);
        syncSize([layoutElement])
        return () => ObserversService.unobserveResizeObserver(observer, layoutElement);
    }, [containerRef, rowsLength, visibleRows])

    /**
     * Syncs the size of the loading component's container so it can fit in the available space.
     * @param {(ResizeObserverEntry || HTMLDivElement)[]} entries
     */
    const syncSize = (entries) => {
        if (!entries?.length)
            return;
        const entry = entries[0];
        const rect = entry.contentRect ?? entry?.getBoundingClientRect();

        const containerElement = containerRef.current?.parentElement;
        const layoutElement = containerElement.querySelector('.data-grid-layout');
        const tableElement = containerElement?.querySelector('table');
        const tBodyElement = containerElement.querySelector('tbody');

        const tBodyHeight = tBodyElement.getBoundingClientRect().height ?? 0;
        const loadingHeight = containerRef.current.getBoundingClientRect().height ?? 0;
        const visibleRowsHeight = Array.from(tBodyElement.querySelectorAll('.data-grid-body-row') ?? [])
            .slice(0, visibleRows)
            .map(e => e.getBoundingClientRect().height ?? 0)
            .reduce((agg, c) => agg + c, 0);

        const height = Math.min(Math.max(tBodyHeight, loadingHeight), visibleRowsHeight);
        if (rowsLength > 0 && height > tBodyHeight) {
            tableElement.style.marginBottom = `${height - tBodyHeight}px`;
        }

        if (containerRef.current) {
            containerRef.current.style.width = (rect.width ?? 0) + 'px';
            containerRef.current.style.height = height + 'px';
            containerRef.current.style.marginTop = getComputedStyle(layoutElement).marginTop;
        }
    }

    return (
        <>
            <div ref={containerRef} className={'data-grid-loading-container'}>
                {
                    !!LoadingComponent && React.isValidElement(LoadingComponent)
                        ? React.cloneElement(LoadingComponent)
                        : <DefaultDataGridLoading/>
                }
            </div>
        </>
    );
}

const DefaultDataGridLoading = () => {

    return (
        <>
            <CircularProgress color={'secondary'}/>
        </>
    );
}

export default DataGridLoading;
