import React, {forwardRef} from "react";
import classnames from "classnames";

/**
 *
 * @param {string} className
 * @param {JSX.Element} children
 * @param {React.HTMLProps<HTMLButtonElement>} props
 * @return {JSX.Element}
 * @constructor
 */
const DataGridIconButton = forwardRef(({className, children, ...props}, ref) => {

    return (
        <>
            <button ref={ref} {...props} className={classnames('data-grid-icon-button', className)}>
                {children}
            </button>
        </>
    )
})

export default DataGridIconButton;
