import {deleteToken, getMessaging, getToken, isSupported, MessagePayload, Messaging, NextFn, Observer, onMessage, Unsubscribe,} from "firebase/messaging";
import {initializeApp} from "firebase/app";
import api from "./api/api";
import LocalStorageService from "./cache/local-storage-service";
import {toast} from "react-toastify";
import {bizliveApis} from "../constants/endpoints/endpoints";
import {apiMethods} from "../constants/enums";
import EnvService from "./env-service";
import CacheService from "./cache/cache-service";
import moment from "moment";


/**
 * The interface for handling firebase messaging operations.
 */
class FirebaseNotificationService {

    /**
     * Retrieve an instance of Firebase Messaging so that it can handle background messages.
     */
    static async getInstance(): Promise<Messaging | null> {
        return await isSupported()
            ? getMessaging(initializeApp({
                apiKey: "AIzaSyADCXefJozMZ-aBMPbzRSg7SGWRs3BbFU0",
                authDomain: "partner-1688b.firebaseapp.com",
                projectId: "partner-1688b",
                storageBucket: "partner-1688b.appspot.com",
                messagingSenderId: "631930930979",
                appId: "1:631930930979:web:37608eed7bec110aa02e96",
                measurementId: "G-H1VB0EMWET"
            }))
            : null;
    }

    /**
     * Subscribes the Messaging instance to push notifications. Returns an Firebase Cloud Messaging registration
     * token that can be used to send push messages to that Messaging instance.
     *
     * If a notification permission isn't already granted, this method asks the user for permission. The returned
     * promise rejects if the user does not allow the app to show notifications.
     *
     * @return {Promise<string>} the token of the firebase messaging used for activation. If this token is null, it
     * might be due to the client not having permissions.
     */
    static async activateDevice(): Promise<string | null> {
        const instance = await FirebaseNotificationService.getInstance();
        if (!instance) return null;
        const options = {vapidKey: EnvService.notificationKey}
        try {
            const token = await getToken(instance, options)
            if (token) {
                // @ts-ignore
                await api({
                    url: bizliveApis.activate,
                    method: apiMethods.post,
                    showError: false,
                    data: {
                        token: token,
                        expireDateTime: moment().add(CacheService.retrieveTokenAndExpiration()?.tokenExpiration ?? 0, "minutes").toDate(),
                    },
                });
            }
            // set or remove the notification token based on the state of the received token.
            if (token) LocalStorageService.set(LocalStorageService.keys.notificationToken, token)
            else LocalStorageService.remove(LocalStorageService.keys.notificationToken)
            // LocalStorageService.set(LocalStorageService.keys.notificationsBlocked, (!token).toString())
            return token;
        } catch (e) { // the error may arise in case of blocked notification permission
            toast.error("To receive notifications from the application, please grant notification permission.", {
                position: toast.POSITION.TOP_RIGHT,
            })
            return null
        }
    }

    /**
     * When a push message is received and the user is currently on a page for your origin, the message is passed to
     * the page and an onMessage() event is dispatched with the payload of the push message.
     *
     * Message payload that contains the notification payload that is represented with
     * firebase.messaging.NotificationPayload and the data payload that contains an arbitrary number of key-value
     * pairs sent by developers through the Send API
     *
     * @param {(MessagePayload) => Promise<void>} listener the listener function that will receive the MessagePayloads
     * @return {() => void} the method used for ub-subscribing the listener.
     */
    static async onMessageReceived(listener: NextFn<MessagePayload> | Observer<MessagePayload>): Promise<Unsubscribe | null> {
        const instance = await FirebaseNotificationService.getInstance();
        if (!instance) return null;
        return onMessage(instance, listener);
    }

    /**
     * Deletes the registration token associated with this Messaging instance and unsubscribes the Messaging instance
     * from the push subscription.
     *
     * @return {Promise<boolean>} a boolean to indicate whether the token as been successfully removed. If the token
     * did not exist, then true is returned and no api call is made.
     */
    static async deactivateDevice(): Promise<boolean> {
        const instance = await FirebaseNotificationService.getInstance();
        if (!instance) return false;
        const token = LocalStorageService.get(LocalStorageService.keys.notificationToken)
        if (token === null) {
            return token
        }
        try {
            const res = await deleteToken(instance)
            if (!res) {
                return res
            }
            LocalStorageService.remove(LocalStorageService.keys.notificationToken)
            // @ts-ignore
            const response = await api({
                url: bizliveApis.deactivate,
                method: apiMethods.post,
                showError: false,
                data: {
                    token: token
                },
            });
            return response?.resultFlag ?? false
        } catch (e) {
            return false
        }
    };

    /**
     * Determines if the browser support push notification.
     *
     * Note: We have to use this method before using the global Notification variable since Safari in IOS does not
     * support it.
     *
     * @return {boolean} a boolean to indicate if this browser supports the notification service.
     */
    static isSupported(): boolean {
        return 'Notification' in window && 'serviceWorker' in navigator && 'PushManager' in window
    }
}

export default FirebaseNotificationService
