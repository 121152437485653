import React, {useCallback, useMemo, useState} from "react";
import AvailableCreditsTable, {
    AvailableCreditsDataGridColumnNames
} from "../../../../components/app-specific/available-credits-table";
import useIsMounted from "../../../../hooks/use-is-mounted";

const MultiInvoicePaymentViewCredits = ({
                                            removeCredit,
                                            addCredit,
                                            selectedCredits,
                                        }) => {
    const [addingCredits, setAddingCredits] = useState([]);
    const isMounted = useIsMounted();

    const selectedCreditTransNos = useMemo(() =>
            selectedCredits?.map(e => e.transactionNo) ?? [],
        [selectedCredits]);

    /**
     * Determines whether to remove or add this credit.
     * @param credit
     * @param alreadySelected
     */
    const onCreditSelected = useCallback(async (credit, alreadySelected = false) => {
        if (alreadySelected)
            return await removeCredit(credit);
        setAddingCredits(prevState => [...prevState, credit]);
        await addCredit(credit)
        if (!isMounted())
            return;
        setAddingCredits(prevState => prevState.filter(e => e.transactionNo !== credit.transactionNo));
    }, [addCredit, removeCredit])

    /**
     * Determines if the credit's action button should be in a disabled state.
     * @param credit
     * @return {boolean} to determine if this credit should be disabled
     */
    const isCreditLoading = useCallback((credit) => {
        if (!isMounted())
            return false;
        const alreadySelected = selectedCredits?.find(e => e.transactionNo === credit.transactionNo);
        if (alreadySelected)
            return alreadySelected.loading ?? false;
        return addingCredits?.find(e => e.transactionNo === credit.transactionNo) ?? false;
    }, [selectedCredits, addingCredits])

    const content = useMemo(() => (
        <AvailableCreditsTable
            className={''}
            excludes={[AvailableCreditsDataGridColumnNames.date]}
            disabled={isCreditLoading}
            updating={isCreditLoading}
            selected={(credit) => selectedCreditTransNos.includes(credit.transactionNo)}
            onSelect={onCreditSelected}
        />
    ), [selectedCreditTransNos, isCreditLoading, onCreditSelected])

    return (
        <>
            {content}
        </>
    );
}
export default MultiInvoicePaymentViewCredits;
