import {DynamicContentEditorApiResponseDS} from "../../../index.d";
import DynamicContentEditorApiResponseCodes from "../../static/api-response-codes";
import axios, {AxiosError, AxiosResponse} from "axios";
import EnvService from "../env-service";

/**
 * The Api executor for the dynamic-content-editor.
 */
class DynamicContentEditorApiExecutor {

    /**
     * Executes the api call with the given args and then parses the response.
     *
     * @param {Function} api
     * @param {any[]} args
     * @return {Promise<DynamicContentEditorApiResponseDS>}
     */
    async execute(api: Function, ...args: any[]): Promise<DynamicContentEditorApiResponseDS> {
        try {
            const res = await api(...args);
            return DynamicContentEditorApiExecutor.onResponseReceived(res);
        } catch (e: any) {
            return DynamicContentEditorApiExecutor.onErrorReceived(e);
        }
    }


    /**
     * Creates a placeholder response data-structure.
     *
     * * this method is primarily used for creating the response object when an error happens in the api call.
     * @param {number} statusCode
     * @return {DynamicContentEditorApiResponseDS}
     * @private
     */
    private static createPlaceholderResponseDs(statusCode: number): DynamicContentEditorApiResponseDS {
        return {
            code: statusCode,
            resultFlag: false,
            data: null,
            message: undefined,
            aborted: statusCode === DynamicContentEditorApiResponseCodes.aborted,
        };
    }

    /**
     * Executes the logic for when an error is thrown while the api call is being made or after the response of the
     * api call is received.
     *
     * @param {AxiosError} error
     * @return {DynamicContentEditorApiResponseDS}
     * @private
     */
    private static onErrorReceived(error: AxiosError & {}): DynamicContentEditorApiResponseDS {
        if (EnvService.isDevelopment) {
            console.error('DCE Api call error:', error);
        }
        if (error.response) {
            // Request made and server responded
            return DynamicContentEditorApiExecutor.createPlaceholderResponseDs(error.response?.status ?? DynamicContentEditorApiResponseCodes.serverNotResponded);
        }
        if (error.request) {
            if (axios.isCancel(error)) {
                // cancelled / aborted
                return DynamicContentEditorApiExecutor.createPlaceholderResponseDs(DynamicContentEditorApiResponseCodes.aborted);
            }
            // The request was made but no response was received
            return DynamicContentEditorApiExecutor.createPlaceholderResponseDs(DynamicContentEditorApiResponseCodes.serverNotResponded);
        }

        // Something happened in setting up the request that triggered an Error
        return DynamicContentEditorApiExecutor.createPlaceholderResponseDs(DynamicContentEditorApiResponseCodes.requestFailed);
    }

    /**
     * Creates a response data-structure from the given response object and the statusCode.
     *
     * @param {DynamicContentEditorApiResponseDS} response
     * @param {number} statusCode
     * @return {DynamicContentEditorApiResponseDS}
     * @private
     */
    private static createResponseDs(response: DynamicContentEditorApiResponseDS, statusCode: number): DynamicContentEditorApiResponseDS {
        const code = response.code ?? statusCode;
        return {
            ...response,
            resultFlag: response.resultFlag ?? false,
            data: Array.isArray(response?.data) ? undefined : response?.data,
            code: code,
            aborted: code === DynamicContentEditorApiResponseCodes.aborted,
        };
    }

    /**
     * Executes the logic for when the response of the api call is received without any axios-specific or http
     * specific errors.
     *
     * @param {AxiosResponse} response
     * @return {DynamicContentEditorApiResponseDS}
     * @private
     */
    private static onResponseReceived(response: AxiosResponse): DynamicContentEditorApiResponseDS {
        const internalResponse: DynamicContentEditorApiResponseDS = response.data;
        return DynamicContentEditorApiExecutor.createResponseDs(
            internalResponse,
            internalResponse.code ?? response.status,
        );
    }
}


export default DynamicContentEditorApiExecutor;
