import React, {useEffect, useState} from 'react';
import useRouter from "../../../hooks/use-router";
import MuiInput from "../input/mui-input";
import classnames from "classnames";


const BlogSearchInput = ({
                             placeholder = 'Search',
                             label,
                             initialValue = '',
                             type = 'text',
                             search,
                             className,
                             ...props
                         }) => {
    const {location} = useRouter();
    const [focused, setFocused] = useState(false);
    const [res, setRes] = useState(initialValue);


    /**
     * Listens for the changes of the location and with each change:
     * Sets the result as the initial value
     */
    useEffect(() => {
        // if auto search, does nothing as the setting of value will trigger the search function
        if (initialValue === undefined) return;
        setRes(initialValue);
    }, [location])


    /**
     * Searches the result with the search callback if the result is a non-empty string
     * @param e {MouseEvent}
     */
    const onSearchPressed = (e) => {
        search(res);
    }

    /**
     * Listens for the key of enter, and if pressed, sets the res and also calls the search function
     * @param e
     */
    const onKeyPress = (e) => {
        // if enter is pressed
        if (e.charCode === 13) {
            setRes(e.target.value);
            if (search) search(res);
        }
    }

    return (
        <div className={classnames('d-flex flex-wrap align-items-center', className)}>
            <div className={'flex-grow-1 mr-3 w-68'}>
                <MuiInput
                    {...props}
                    type={type}
                    name={label}
                    value={res}
                    size={'small'}
                    placeholder={placeholder}
                    className={classnames({'--focused': focused}, 'blog-search-input mui-search-input')}
                    onChange={e => setRes(e.target.value)}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    onKeyPress={onKeyPress}
                />
            </div>
            <button
                className={'button primary m-auto flex-grow-1'}
                style={{padding: '10.5px 15px'}}
                onClick={onSearchPressed}
            >
                Search
            </button>

        </div>

    );
}

export default BlogSearchInput;
