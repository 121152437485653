import React, {useEffect, useRef} from "react";
import useRouter from "../../hooks/use-router";
import FirebaseNotificationService from "../../../core/services/firebase-notification-service";
import CacheService from "../../../core/services/cache/cache-service";
import {toast} from "react-toastify";
import routes, {routeFunctions} from "../../routes";
import {openLinkInNewTab} from "../../../core/services/utils/utils";
import {ProductsSearchTypes} from "../../../core/constants/enums";
import ProductSearchUtils from "../../../core/services/utils/product-search-utils";

const NotificationManager = () => {
    const {history, stringifyUrl} = useRouter()
    const toastIds = useRef([])

    /**
     * As soon as the component mounts:
     * - Attaches an event listeners for the storage and with each dispatch, determines if the user should be
     *      redirected to the login page.
     * - registers a listener for the new messages received from firebase that will update the internal list.
     *      as soon as the component un-mounts, the listener is un-subscribed.
     * - sets a global callback for opening the notifications button
     */
    useEffect(() => {
        listenForNotificationPermissionChanges()
        if (!hasNotificationPermission()) return
        let unsubscribe;
        FirebaseNotificationService.onMessageReceived((notification) => {
            onMessageReceived({
                data: {
                    data: {
                        data: JSON.parse(notification.data.data),
                        ...notification.notification
                    },
                    type: 'notification',
                }
            })
        }).then((_unsubscribe) => {
            unsubscribe = _unsubscribe;
        })
        if ('serviceWorker' in navigator) navigator.serviceWorker.addEventListener('message', onMessageReceived);
        return () => {
            if (unsubscribe) unsubscribe()
            if ('serviceWorker' in navigator) navigator.serviceWorker.removeEventListener('message', onMessageReceived);
        }
    }, [])

    /**
     * Listens to the messages from the service worker and with change message sent, determines if an action is
     * required.
     * @param {MessageEvent} message
     */
    const onMessageReceived = (message) => {
        if (!CacheService.isLoggedIn()) return
        const notification = message.data
        switch (notification?.type) {
            case "notification":
                const id = toastIds.current.push(toastIds.current.length)
                const options = {
                    type: "info",
                    position: toast.POSITION.TOP_RIGHT,
                    onClick: () => onNotificationClicked(notification.data, id - 1),
                    id: id - 1
                }
                toast.success(`${notification?.data?.title ?? ''} : ${notification?.data?.body ?? ''}`, options);
                break;
            default:
                break;
        }
    }

    /**
     * Registers the user into the notification platform using the FirebaseNotificationService.
     *
     * first deactivates the currently saved token of the user and registers them under a new notification token.
     */
    const listenForNotificationPermissionChanges = () => {
        if (!('permissions' in navigator)) return
        navigator.permissions.query({name: 'notifications'}).then((notificationPerm) => {
            notificationPerm.onchange = () => onNotificationPermissionChanged(notificationPerm);
        });

    }

    /**
     * Activates or deactivates the notification logic of the application based on the state of the given permission.
     * @param {PermissionStatus} notificationPermission the new notification permission.
     */
    const onNotificationPermissionChanged = (notificationPermission) => {
        switch (notificationPermission.state) {
            case "granted":
                FirebaseNotificationService.deactivateDevice().then((_) => {
                    FirebaseNotificationService.activateDevice().then(() => {
                        // reload the application to apply the effect of notification
                        window.location.reload()
                    })
                })
                break
            case "denied":
                FirebaseNotificationService.deactivateDevice().then((_) => {
                    // reload the application to apply the effect of notification
                    window.location.reload()
                })
                break
            default:
                return
        }
    }

    /**
     * Checks whether the user has given notification permission
     * @return {boolean}
     */
    const hasNotificationPermission = () => {
        if (!FirebaseNotificationService.isSupported()) return false;
        return Notification.permission !== "denied";
    }

    /**
     * Determines where to go when the notification is clicked.
     * @param notification
     * @param id
     */
    const onNotificationClicked = (notification, id) => {
        const data = notification.data;
        toast.dismiss(id)
        toastIds.current.splice(id, 1)
        if (data?.PartNo) {
            history.push(routeFunctions.main.single(data.PartNo))
            return
        }
        if (data?.Category) {
            history.push(stringifyUrl({
                url: routes.main.products,
                query: {
                    type: ProductsSearchTypes.categories,
                    data: JSON.stringify({
                        // Category3 is specifically used because in partner, we are only allowed to select a leaf
                        // category.
                        [ProductsSearchTypes.categories]: {
                            [ProductSearchUtils.basicSearchQueryKeys.categoryId]: data?.Category?.Id,
                        }
                    }),
                }
            }))
            return
        }
        if (data?.Url) {
            openLinkInNewTab(data?.Url)
            return
        }
        if (data?.Keyword) {
            history.push(stringifyUrl({
                url: routes.main.products,
                query: {
                    type: ProductsSearchTypes.basic,
                    data: JSON.stringify({
                        [ProductsSearchTypes.basic]: {
                            [ProductSearchUtils.basicSearchQueryKeys.keyword]: data?.Keyword,
                        }
                    }),
                }
            }))
        }
    }

    return (<></>)
}

export default NotificationManager;
