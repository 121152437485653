import React, {useContext, useLayoutEffect, useMemo, useRef, useState} from "react";
import {DataGridEventHandlersContext, DataGridMiscContext, DataGridStateContext} from "../../../../index";
import {DataGridActionTypes} from "../../../../models";
import {ReactComponent as TogglerIcon} from '../../../../assets/images/footer-actions/toggler.svg';
import classnames from "classnames";
import DataGridUtils from "../../../../core/services/utils";
import {Popover} from "@mui/material";
import DataGridButton from "../../../components/button";

/**
 * @param {DataGridFooterAction[]} actions
 * @param {DataGridRow[]} rows
 * @return {JSX.Element}
 * @constructor
 */
const DataGridFooterActions = ({actions, rows}) => {
    const {
        loading: {state: loading},
        allRowsSelected,
        selectedRows,
        excludedRows,
        pagination,
    } = useContext(DataGridStateContext);
    const {classNames, singleSelectionOnly, getSingleSelectionFooterText} = useContext(DataGridMiscContext);
    const {onActionInvoked} = useContext(DataGridEventHandlersContext);
    const [performingActions, setPerformingActions] = useState([]);
    const [popover, setPopover] = useState(null);
    const id = useRef(DataGridUtils.createUUId(true));

    const showSelection = useMemo(() => true, [])
    const actionsDisabled = useMemo(() => loading, [loading])

    /**@type {Array<DataGridFooterAction & {loading: boolean}>}*/
    const popoverActions = useMemo(() => {
        return actions
                ?.filter(action => {
                    if (!action.visibilityConstraints)
                        return true;

                    if (typeof action.visibilityConstraints === 'function') {
                        const checked = DataGridUtils.areAllRowsSelected(allRowsSelected, selectedRows.length, excludedRows.length, pagination.length);
                        return action.visibilityConstraints(selectedRows, excludedRows, checked, pagination);
                    }

                    let show = true;

                    let length = selectedRows.length
                    if (allRowsSelected)
                        length = pagination?.length ?? 0;

                    if (typeof action.visibilityConstraints.minSelectedRows !== 'undefined') {
                        show = show && action.visibilityConstraints.minSelectedRows <= length;
                    }
                    if (typeof action.visibilityConstraints.maxSelectedRows !== 'undefined') {
                        show = show && action.visibilityConstraints.maxSelectedRows >= length;
                    }
                    return show;
                })
                ?.map(e => ({
                    ...e,
                    loading: performingActions.includes(e.name)
                }))
            ?? []
    }, [selectedRows, excludedRows, actions, performingActions, pagination, allRowsSelected])

    const showActions = useMemo(() => !!popoverActions?.length, [popoverActions?.length])

    /**
     * With each change in the [actions] value of the props.
     * * removes the loading indicators of the actions that do not exist in the actions list.
     */
    useLayoutEffect(() => {
        const actionNames = actions?.map(e => e.name) ?? [];
        setPerformingActions(prevState => prevState.filter(e => !actionNames.includes(e)));
    }, [actions])

    /**
     * Closes the popover
     */
    const closePopover = () => setPopover(null);

    /**
     * Opens the popover
     * @param {Event} e
     */
    const openPopover = (e) => setPopover(e.currentTarget);


    /**
     * Invokes the given action of the data-grid footer.
     *
     * * sets the loading of the given action until the promise of the action invoker is resolved or failed.
     * @param {DataGridFooterAction & {loading: boolean}} action
     */
    const invokeAction = async (action) => {
        if (loading || action.loading)
            return;
        setPerformingActions(prevState => [...prevState, action.name]);
        await onActionInvoked(action, selectedRows, excludedRows, allRowsSelected, pagination);
        closePopover();
        setPerformingActions(prevState => prevState.filter(e => e !== action.name));
    }

    const selectionText = useMemo(() => {
        const areAllRowsSelected = DataGridUtils.areAllRowsSelected(allRowsSelected, selectedRows.length, excludedRows.length, pagination.length);

        if (singleSelectionOnly && getSingleSelectionFooterText) {
            const entry = areAllRowsSelected && rows?.length === 1 ? {
                key: rows[0].key,
                data: rows[0].data,
            } : selectedRows?.[0]
            return getSingleSelectionFooterText(entry);
        }

        if (areAllRowsSelected) {
            return "All items selected";
        }

        let selectionCount = 0;
        if (excludedRows.length && pagination.length) {
            selectionCount = pagination.length - excludedRows.length;
        }
        if (selectedRows.length) {
            selectionCount = selectedRows.length;
        }

        if (!selectionCount) {
            return '';
        }
        return `${selectionCount} ${selectionCount === 1 ? 'item' : 'items'} selected`;
    }, [
        allRowsSelected,
        selectedRows,
        rows,
        excludedRows?.length,
        pagination?.length,
        getSingleSelectionFooterText,
        singleSelectionOnly,
    ])

    return (
        <>
            <div className={classnames(
                'data-grid-footer-actions',
                classNames.footerActions,
            )}>
                {
                    showSelection &&
                    <p className={classnames(
                        'data-grid-footer-actions-selection-text',
                        classNames.footerActionsSelectionText,
                    )}>
                        {selectionText}
                    </p>
                }
                {
                    showActions &&
                    <DataGridButton
                        disabled={actionsDisabled}
                        className={classnames(
                            'data-grid-footer-actions-button',
                            classNames.footerActionsButton,
                        )}
                        onClick={openPopover}
                    >
                        <p>Actions</p>
                        <TogglerIcon/>
                    </DataGridButton>
                }
                <Popover
                    id={!!popover ? id.current : undefined}
                    elevation={2}
                    open={!!popover}
                    onClose={closePopover}
                    anchorReference={'anchorEl'}
                    className={classnames(
                        "data-grid-footer-actions-popover",
                        "data-grid-popover",
                        classNames.footerActionsPopover,
                    )}
                    classes={{
                        paper: classnames(
                            'data-grid-popover-paper',
                            'data-grid-footer-actions-popover-paper',
                            classNames.footerActionsPopoverPaper,
                        )
                    }}
                    anchorEl={popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}>
                    {
                        popoverActions.map(action => (
                            <div key={action.name}
                                 onClick={() => invokeAction(action)}
                                 className={classnames(
                                     'popover-item',
                                     {'default': action.type !== DataGridActionTypes.delete},
                                     {'delete': action.type === DataGridActionTypes.delete},
                                     {'disabled': action.loading},
                                 )}>
                                <p>
                                    {typeof action.title === 'function'
                                        ? action.title(selectedRows) ?? ''
                                        : action.title ?? ''}
                                </p>
                            </div>
                        ))
                    }
                </Popover>
            </div>
        </>
    )
}

export default DataGridFooterActions;
