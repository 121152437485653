import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import youTubePlayer from 'yt-player';
import classnames from "classnames";
import {ReactComponent as Play} from "../../../../../assets/images/video/play.svg";
import {ReactComponent as Pause} from "../../../../../assets/images/video/pause.svg";
import {ReactComponent as Mute} from "../../../../../assets/images/video/mute.svg";
import {ReactComponent as Unmute} from "../../../../../assets/images/video/unmute.svg";
import {ReactComponent as Fullscreen} from "../../../../../assets/images/video/full-screen.svg";
import {Fade} from "@material-ui/core";
import useIsMounted from "../../../../hooks/use-is-mounted";

/**
 * The Types of Videos
 * @type {{small: string, large: string}}
 */
export const VideoTypes = {
    small: 'small',
    large: 'large',
}

const YoutubeVideoPlayer = ({id, videoId, title = 'Video Player', type = VideoTypes.small, className}) => {
    const [state, setState] = useState({
        paused: true,
        duration: -1,
        currentTime: 0,
        muted: true,
        loaded: false,
        unplayable: false,
    });
    /**@type {React.MutableRefObject<YouTubePlayer & NodeJS.EventEmitter>}*/
    const player = useRef();
    const ref = useRef();
    const isMounted = useIsMounted();

    /**
     * As soon as the component um-mounts destroys the current player.
     */
    useLayoutEffect(() => {
        return () => {
            player.current?.destroy();
            player.current = null;
        }
    }, [])

    /**
     * Listens to the changes in the paused state and with each change:
     * if the state is playing, then creates an interval that will track the progression of the time and video's
     * duration.
     * Removes the interval before the next call of this listener.
     */
    useEffect(() => {
        let timer;
        if (!state.paused) {
            timer = setInterval(() => {
                if (!isMounted()) return;
                setState(prevState => ({
                    ...prevState,
                    currentTime: player.current.getCurrentTime(),
                    duration: player.current?.getDuration() ?? -1,
                }))
            }, 1000)
        } else {
            return () => timer && clearInterval(timer);
        }
    }, [state.paused])

    /**
     * Listens for the changes in the ref and with each change:
     * if the ref exists, instantiates the current player
     */
    useEffect(() => {
        if (!ref.current || player.current) return;
        // noinspection JSValidateTypes
        player.current = new youTubePlayer(`#${id}`, {
            autoplay: false,
            captions: undefined,
            controls: false,
            keyboard: true,
            fullscreen: true,
            annotations: false,
            modestBranding: true,
            related: false,
            timeupdateFrequency: 1000,
            playsInline: true,
            start: 0
        });
    }, [ref.current]);

    /**
     * Listens to the changes in videoId and playerRef and with each change:
     * if they both exist and the player is instantiated, then loads the video with the current videoId
     */
    useEffect(() => {
        if (!videoId || !player.current) return;
        player.current.load(videoId);
        setState({
            loaded: true,
            muted: player.current.isMuted(),
            paused: true,
            duration: -1,
            currentTime: 0,
        })
        player.current.addListener('paused', pause)
        player.current.addListener('playing', play)
        player.current.addListener('unplayable', onVideoUnPlayable)
        return () => {
        }
    }, [videoId, player.current])

    /**
     * Plays the current video of the player
     */
    const play = () => {
        player.current?.play();
        setState(prevState => ({
            ...prevState,
            progress: player.current?.getProgress() ?? 0,
            paused: false,
        }))
    }

    /**
     * Pauses the current video of the player
     */
    const pause = () => {
        setState(prevState => ({...prevState, paused: true}));
        player.current?.pause();
    }

    /**
     * Mutes the video of the player
     */
    const mute = () => {
        setState(prevState => ({...prevState, muted: true}));
        player.current?.mute();
    }

    /**
     * Un-Mutes the video of the player
     */
    const unmute = () => {
        setState(prevState => ({...prevState, muted: false}));
        player.current?.unMute();
    }

    /**
     * Makes the  the video of the player fullscreen
     */
    const fullscreen = () => {
        /**@type {Element}*/
        const iframe = player.current._player.getIframe();
        const requestFullScreen = iframe.requestFullscreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen;
        if (requestFullScreen) {
            requestFullScreen.bind(iframe)();
        }
    }

    /**
     * Makes the video unplayable so that the play button gets removed from the iframe.
     * @param {string | number} videoId the video id that could not be played
     */
    const onVideoUnPlayable = (videoId) => {
        setState(prevState => ({...prevState, unplayable: true}))
    }

    return (
        <>
            <div className={classnames('video-container-layout', className, {
                'active': state.duration !== -1,
                [type]: true
            })}>
                <div className={'video-container'}>
                    <Fade in={state.duration === -1 && !state.unplayable} unmountOnExit mountOnEnter>
                        <div className={'play-button-container'}>
                            <button className={'play-button'} onClick={play}>
                                <Play/>
                            </button>
                        </div>
                    </Fade>
                    <div
                        ref={ref}
                        id={id}
                        title={title}
                    />
                </div>
                <Fade in={state.duration !== -1} unmountOnExit mountOnEnter>
                    <div className={classnames('video-controls', {[type]: true})}>
                        <div className={classnames('inner')}>
                            {
                                state.paused
                                    ? <Play className={'video-icon'} onClick={play}/>
                                    : <Pause className={'video-icon'} onClick={pause}/>
                            }
                            <progress
                                className={'progress'}
                                value={state.currentTime.toString()}
                                max={state.duration}
                            />
                            {
                                state.muted
                                    ? <Unmute className={'video-icon'} onClick={unmute}/>
                                    : <Mute className={'video-icon'} onClick={mute}/>
                            }
                            <Fullscreen className={'video-icon'} onClick={fullscreen}/>
                        </div>

                    </div>
                </Fade>
            </div>
        </>
    )
}

export default YoutubeVideoPlayer;
