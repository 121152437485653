import headerReduxSlice from "./reducer";

const actions = headerReduxSlice.actions;

/**
 * Sets the header height of the application.
 *
 * The header height is actually the padding-bottom of the header element.
 * @param {number} height
 */
export const setReduxHeaderHeight = (height) => (dispatch) => dispatch(actions.headerHeight(height));

/**
 * Sets the header type of the application.
 *
 * @param {'logged-in' | 'logged-out' | 'authentication'} type
 */
export const setReduxHeaderType = (type) => (dispatch) => dispatch(actions.headerType(type));
