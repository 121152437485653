/**
 * Different values of api call methods.
 */
export enum ApiMethods {
    get = 'GET',
    post = 'POST',
    put = 'PUT',
    delete = 'DELETE',
    patch = 'PATCH',
}


/**
 * Different possible entries found in the [window.navigator.platform] value.
 */
export enum OperatingSystems {
    mac = 'Mac',
    ios = 'IOS',
    windows = 'Windows',
    android = 'Android',
    linux = 'Linux',
}
