import React, {useContext, useEffect, useLayoutEffect, useRef} from "react";
import {DataGridMiscContext} from "../../../index";
import useResizeObserver from "../../hooks/use-resize-observer";
import useCombinedRefs from "../../hooks/use-combined-refs";
import ObserversService from "../../../../../core/services/observers-service";

const DataGridEmptyContent = ({loading = false}) => {
    const {EmptyContentComponent} = useContext(DataGridMiscContext);
    const {rect, ref: resizeObserverRef} = useResizeObserver();
    /**@type {React.MutableRefObject<HTMLTableElement>}*/
    const containerRef = useRef();
    const ref = useCombinedRefs(resizeObserverRef, containerRef);

    /**
     * As soon as the component unmounts:
     * - removes the injected height of the [tbody] element of the table.
     */
    useLayoutEffect(() => {
        const containerElement = containerRef.current?.parentElement;
        const tBodyElement = containerElement?.querySelector('tbody');
        const tableBodyHeight = tBodyElement.style.height;
        return () => {
            tBodyElement.style.height = tableBodyHeight;
        }
    }, [])


    /**
     * With each change in the height of the container element:
     * - sets the height of the [tbody] element of the data-grid table so that the table can expand according to the
     * height of the given empty-content-component.
     */
    useLayoutEffect(() => {
        const containerElement = containerRef.current?.parentElement;
        const layoutElement = containerElement?.querySelector('.data-grid-layout');
        const tBodyElement = containerElement?.querySelector('tbody');
        const tHeaderElement = containerElement?.querySelector('thead');
        const toolbarElement = containerElement?.querySelector('div.data-grid-toolbar');

        if (!layoutElement || !tBodyElement || !tHeaderElement)
            return;

        const containerElementStyles = getComputedStyle(containerElement);
        const layoutSize = layoutElement.getBoundingClientRect();
        const tableHeaderSize = tHeaderElement.getBoundingClientRect();
        const toolbarHeight = toolbarElement?.getBoundingClientRect()?.height ?? 0;

        tBodyElement.style.height = `${rect?.height ?? 0}px`;

        containerRef.current.style.left = containerElementStyles.paddingLeft;
        containerRef.current.style.right = containerElementStyles.paddingRight;
        containerRef.current.style.top = `${tableHeaderSize.height + toolbarHeight}px`;
        containerRef.current.style.width = `${layoutSize?.width}px`;
        containerRef.current.style.marginTop = getComputedStyle(layoutElement).marginTop;

    }, [rect?.height, containerRef]);

    useEffect(() => {
        const containerElement = containerRef.current?.parentElement;
        const layoutElement = containerElement?.querySelector('.data-grid-layout');
        if (!layoutElement)
            return;
        const observer = ObserversService.newResizeObserver(syncSize);
        ObserversService.observeResizeObserver(observer, layoutElement);
        syncSize([layoutElement])
        return () => ObserversService.unobserveResizeObserver(observer, layoutElement);
    }, [containerRef])

    const syncSize = (entries) => {
        if (!entries?.length)
            return;
        const entry = entries[0];
        const rect = entry.contentRect ?? entry?.getBoundingClientRect();
        if (containerRef.current)
            containerRef.current.style.width = (rect.width ?? 0) + 'px';
    }

    return (
        <>
            <div ref={ref} className={'data-grid-empty-content-container'}>
                {
                    !!EmptyContentComponent && React.isValidElement(EmptyContentComponent)
                        ? React.cloneElement(EmptyContentComponent)
                        : <DefaultDataGridEmptyContent loading={loading}/>
                }
            </div>

        </>
    );
}

const DefaultDataGridEmptyContent = ({loading}) => {

    return (
        <>
            <div className={'default-data-grid-empty-content'}>
                {loading ? "" : "No results Found"}
            </div>
        </>
    )
}

export default DataGridEmptyContent;
