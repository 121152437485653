import React, {useEffect, useLayoutEffect, useRef} from "react";
import LazyImage from "../../../lazy-lmage";
import {formatMoney} from "../../../../../../core/services/utils/utils";
import classnames from "classnames";
import {SvgIcon, Tooltip} from "@material-ui/core";
import {ReactComponent as CompareIcon} from "../../../../../../assets/images/parts/compare.svg";
import {ReactComponent as CompareOutLinedIcon} from "../../../../../../assets/images/parts/compare-outlined.svg";
import {Bookmark, BookmarkBorderOutlined} from "@material-ui/icons";
import {Link} from 'react-router-dom'
import useWindowViewportWidth from "../../../../../hooks/use-window/viewport-width";
import TagsContainer from "../../../tags-container";
import ObserversService from "../../../../../../core/services/observers-service";

const SmallProductBox = ({
                             data,
                             toggleInCompareList,
                             toggleBookmarked,
                             linkPath,
                             onTagClicked,
                             preventAnimation
                         }) => {
    const containerRef = useRef();
    const viewportWidth = useWindowViewportWidth()

    const priceNotAvailable = data?.changedPrice <= 0;
    const hasNewPrice = data?.changedPrice < data?.originalPrice;

    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const bodyRef = useRef();
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const infoRef = useRef();

    /**
     * With each change in the data:
     * - resizes the product information to fit the whole body width
     */
    useEffect(() => {
        if (!bodyRef.current)
            return;

        const observer = ObserversService.newResizeObserver(syncInformationHeight);
        ObserversService.observeResizeObserver(observer, bodyRef.current);
        ObserversService.observeResizeObserver(observer, infoRef.current);
        syncInformationHeight();
        return () => ObserversService.disconnectResizeObserver(observer);
    }, [])

    /**
     * Resizes the product information to fit the whole body width
     */
    const syncInformationHeight = () => {
        if (infoRef.current && bodyRef.current)
            infoRef.current.style.minHeight = `${bodyRef.current.getBoundingClientRect()?.height}px`;
    }

    /**
     * With each change in viewport width:
     * - Calls the function for preventing the animation being run for action buttons
     */
    useLayoutEffect(() => {
        preventAnimation(containerRef.current);
    }, [viewportWidth])

    return (
        <>
            <div ref={containerRef} className={'product small'}>
                <Link to={linkPath} className={'w-100'}>
                    <div className="product-body">
                        <div className={'product-information'}>
                            <div ref={bodyRef} className={'product-info-column'}>
                                <p className={'part-no'}>
                                    {data?.partNumber ?? ''}
                                </p>
                                <div className="image">
                                    <LazyImage
                                        src={data?.imageUrl}
                                        alt={data?.partNumber}
                                        height={130}
                                    />
                                </div>
                            </div>
                            <div ref={infoRef} className={'product-info-column'}>
                                <p className={'category'}>
                                    {data?.category?.title ?? ''}
                                </p>
                                {
                                    priceNotAvailable
                                        ? <p className={'price not-available'}>
                                            {/*Price Not Available*/}
                                        </p>
                                        : hasNewPrice
                                            ?
                                            <div className={'d-flex justify-content-center align-items-center flex-column'}>
                                                <p className={'price new'}>
                                                    {formatMoney(data?.changedPrice)}
                                                </p>
                                                <p className={'price old'}>
                                                    {formatMoney(data?.originalPrice)}
                                                </p>
                                            </div>
                                            : <p className={'price'}>
                                                {formatMoney(data?.originalPrice)}
                                            </p>
                                }
                            </div>
                            <button
                                className={classnames('button animating-icon-button save',
                                    {
                                        'active': data?.saved || data?.isCompared,
                                        'selected': data?.saved,
                                    })}
                                onClick={toggleBookmarked}>
                                <Tooltip
                                    classes={{tooltip: 'product-tool-tip'}}
                                    placement={'top-end'}
                                    title={
                                        data?.saving
                                            ? 'Loading'
                                            : !data?.saved
                                                ? 'Add to save list'
                                                : 'Remove from save list'
                                    }
                                >
                                    {
                                        data?.saving
                                            ? <i className={'icon-loader'}/>
                                            : !data?.saved
                                                ? <BookmarkBorderOutlined className={'color'}/>
                                                : <Bookmark className={'color'}/>
                                    }
                                </Tooltip>
                            </button>
                            <button
                                className={classnames('button animating-icon-button compare',
                                    {
                                        'active': data?.isCompared || data?.saved,
                                        'selected': data?.isCompared,
                                    })}
                                onClick={toggleInCompareList}>
                                <Tooltip
                                    classes={{tooltip: 'product-tool-tip'}}
                                    placement={'top-end'}
                                    title={
                                        data?.comparing
                                            ? 'Loading'
                                            : data?.isCompared
                                                ? 'Remove from compare list'
                                                : 'Add to compare list'
                                    }
                                >
                                    {
                                        data?.comparing
                                            ? <i className={'icon-loader'}/>
                                            : data?.isCompared
                                                ? <SvgIcon component={CompareIcon}/>
                                                : <SvgIcon component={CompareOutLinedIcon}/>
                                    }
                                </Tooltip>
                            </button>
                        </div>
                        <TagsContainer
                            tags={data?.tags}
                            lines={2}
                            onTagClicked={onTagClicked}
                        />
                    </div>
                </Link>
            </div>

        </>
    )
}

export default SmallProductBox;
