import React from "react";
import classnames from "classnames";
import {TextField as FormTextField} from "mui-rff";
import InputMask from "react-input-mask";


const MuiPhoneInput = ({
                           value,
                           onChange,
                           fullWidth = true,
                           className,
                           type: typeProp,
                           name,
                           required,
                           placeholder,
                           label,
                           form,
                       }) => {

    /**
     * Custom error function that only shows error when form has been submitted and not changed
     * @param submitError
     * @param error
     * @return {boolean}
     */
    const customShowErrorFunction = ({meta: {error, submitFailed}}) => {
        return (submitFailed && error);
    }

    /**
     * Formats the phone number before its submission.
     */
    const formatValueBeforeSubmission = () => {
        const val = form.getFieldState(name)?.value;
        let value = val?.trim() ?? '';
        value = value
            .replace(/[()_-]/g, '')
            .replace(/\+1/g, '')
            .replaceAll(' ', '');
        form.change(name, value);
    }

    return (
        <>
            <InputMask mask={"+\\1 (999) 999-9999"} value={value} onChange={onChange} maskChar={'_'}>
                {(inputProps) =>
                    <FormTextField
                        name={name}
                        showError={customShowErrorFunction}
                        label={label}
                        className={classnames(className, 'mui-input')}
                        variant={'outlined'}
                        fullWidth={fullWidth}
                        type={typeProp}
                        required={required}
                        placeholder={placeholder}
                        fieldProps={{
                            beforeSubmit: formatValueBeforeSubmission
                        }}
                        {...inputProps}
                    />
                }
            </InputMask>
        </>
    );
}

export default MuiPhoneInput;
