import React, {useEffect, useRef} from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import mapboxgl from '!mapbox-gl';
import PinIcon from "../../../../../assets/images/logo/pin.png";
import useRouter from "../../../../hooks/use-router";
import EnvService from "../../../../../core/services/env-service";

mapboxgl.accessToken = EnvService.mapboxAccessToken;

const ResellerLocatorMap = ({flyTo, geoJson, height}) => {
    const {location} = useRouter();
    const mapContainer = useRef(null);
    const map = useRef(null);

    /**
     * Listens for the changes in the Map object and with each change:
     * - resizes the map object.
     */
    useEffect(() => {
        if (!map.current) return;
        setTimeout(() => {
            if (!map.current) return;
            map.current.resize();
        }, 200)
    }, [map.current, mapContainer.current, location.pathname, location?.hash])

    /**
     * Listens to the changes in map.current nd props.flyTo and with each change:
     * - if map is loaded and flyTo is provided  then flies to map to that specific location.
     */
    useEffect(() => {
        if (!map.current) return;
        if (!flyTo) return;
        map.current.flyTo({
            center: flyTo,
            essential: true,
            zoom: 15,
        });
    }, [flyTo, map.current]);

    /**
     * Listens to the changes in geoJson object and with each change:
     * - creates a new map object and then after it loads, creates the markers for it.
     *      - if only one marker is created, then flies the map to that location
     *      - if more than one marker is created, then fits the bounds of the map to contain all the markers.
     */
    useEffect(() => {
        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: 'mapbox://styles/mapbox/streets-v11', // stylesheet location
            center: [-53.29223632812499, -18.28151823530889],
            zoom: 2,
        });
        if (geoJson.features.length <= 0) return;
        map.current.on('load', () => {
            const markerAllBounds = renderMarkers();
            if (markerAllBounds.length < 1) return
            if (markerAllBounds.length === 1) {
                return map.current.flyTo({center: markerAllBounds[0], essential: true})
            }
            map.current.fitBounds(markerAllBounds, {padding: 50});
        });
    }, [geoJson]);

    /**
     * Renders the markers used in the map.
     * @return {*[]}
     */
    const renderMarkers = () => {
        const markerAllBounds = [];
        geoJson.features.forEach((marker) => {
            markerAllBounds.push(marker.geometry.coordinates);
            const element = document.createElement('div');
            element.className = 'mapbox-marker';
            element.style.backgroundImage = `url(${PinIcon})`;
            element.style.backgroundSize = "cover";
            element.style.width = marker.properties.iconSize[0] + 'px';
            element.style.height = marker.properties.iconSize[1] + 'px';
            const markerHeight = marker.properties.iconSize[1], linearOffset = 25;
            const popupOffsets = {
                'top': [0, 0], 'top-left': [0, 0], 'top-right': [0, 0], 'bottom': [0, -markerHeight],
                'bottom-left': [linearOffset, (markerHeight + linearOffset) * -1],
                'bottom-right': [-linearOffset, (markerHeight + linearOffset) * -1],
                'left': [0, (markerHeight) * -1], 'right': [0, (markerHeight) * -1]
            };
            const popup = new mapboxgl.Popup({offset: popupOffsets})
                .setLngLat(marker.geometry.coordinates).setHTML(
                    `<div class="title">
                                ${marker.properties.title}
                            </div>
                            <div class="desc">
                                ${marker.properties.message}
                            </div>`
                ).setMaxWidth("200px").addTo(map.current);
            new mapboxgl.Marker(element).setLngLat(marker.geometry.coordinates).setPopup(popup).addTo(map.current);
        });
        return markerAllBounds;
    }

    return (
        <div ref={mapContainer} className={'map'} style={{width: '100%', height: height}}/>
    )
}

export default ResellerLocatorMap;
