import React, {useMemo, useRef, useState} from "react";
import useRouter from "../../../../hooks/use-router";
import classnames from "classnames";
import {ReactComponent as Prev} from "../../../../../assets/images/home-view/slide-show-prev.svg";
import {ReactComponent as Next} from "../../../../../assets/images/home-view/slide-show-next.svg";
import LazyImage from "../../lazy-lmage";
import Slider from "react-slick";
import useWindowViewportWidth from "../../../../hooks/use-window/viewport-width";
import {ProductsSearchTypes} from "../../../../../core/constants/enums";
import ProductSearchUtils from "../../../../../core/services/utils/product-search-utils";
import routes from "../../../../routes";

const HomeViewSlideShow = ({data, first = false, loading, isClickable = true}) => {
    /**@type {React.MutableRefObject<import('react-slick').default>}*/
    const slickSliderRef = useRef();
    const {history, stringifyUrl} = useRouter();
    const [activeIndex, setActiveIndex] = useState(0);
    const viewportWidth = useWindowViewportWidth();
    const dragging = useRef(false);

    const bannerHeight = useMemo(() => {
        switch (viewportWidth) {
            case 'xs':
                return () => (window.innerWidth - 40) / 2.32;
            case 'sm':
                return 221;
            case 'md':
                return 300;
            case 'lg':
                return 404;
            case 'xl':
            default:
                return 482;
        }
    }, [viewportWidth])


    /**
     * Call back function being passed to the React slick component for handling slide change
     * sets the current active index in the state
     * @param index
     */
    const afterSlideChange = (index) => {
        dragging.current = false
        setActiveIndex(index);
    }


    /**
     * Navigates to the products search result view having the given banners tagIds as the search filters.
     * @param banner
     */
    const onBannerClicked = (banner) => {
        if (!isClickable)
            return;
        if (dragging.current)
            return;
        history.push(stringifyUrl({
                url: routes.main.products,
                query: {
                    banner: encodeURIComponent(banner?.imageName ?? null),
                    type: ProductsSearchTypes.tags,
                    data: JSON.stringify({
                        [ProductsSearchTypes.tags]: {
                            [ProductSearchUtils.basicSearchQueryKeys.tags]: banner?.tags?.map(e => ({
                                id: e.id,
                                name: e.name
                            })) ?? [],
                        }
                    })
                },
            })
        )
    }


    const beforeSlideChange = () => {
        dragging.current = true;
    }

    /**
     * Changes the carousel item based on the offset:
     *
     *  If the offset is greater than zero shifts it the right
     *  Otherwise, shifts it to the left
     *
     * @param {number} offset
     */
    const changeSlide = (offset) => {
        if (offset > 0) {
            slickSliderRef.current.slickNext();
            return;
        }
        slickSliderRef.current.slickPrev();
    }

    /**
     * Changes the carousel based on the selected index of the dots
     * if the difference of index is positive navigates slick carouse forward otherwise, navigates slick backwards
     * @param {number} index
     */
    const onDotsClicked = (index) => {
        if (index === activeIndex)
            return;
        slickSliderRef.current.slickGoTo(index);
    }

    const slickCarousel = useMemo(() => {
        return (
            <Slider
                slidesToShow={1}
                draggable
                infinite={true}
                speed={250}
                slidesToScroll={1}
                appendDots={false}
                arrows={false}
                ref={slickSliderRef}
                beforeChange={beforeSlideChange}
                afterChange={afterSlideChange}
            >
                {data?.map((banner, index) => (
                    <div
                        onClick={() => onBannerClicked(banner)}
                        key={banner.id * 2 + index}
                        className={classnames('image', {'un-clickable': !isClickable})}
                        // style={{width: `${containerWidth}px`}}
                    >
                        <LazyImage
                            src={banner?.imageName}
                            alt={banner?.imageName ?? ''}
                            height={bannerHeight}
                            width={'100%'}
                        />
                    </div>
                ))}
            </Slider>
        );
    }, [data, viewportWidth, bannerHeight])

    return (
        <>
            <div className={classnames('home-view-slide-show', {first})}>
                {
                    loading ?
                        <>
                            <div className={'slide-show-loading'} style={{height: bannerHeight}}/>
                        </>
                        : <div
                            // style={{width: containerWidth + 'px'}}
                        >
                            <button
                                disabled={false}
                                className={'icon-button prev'}
                                onClick={() => changeSlide(-1)}>
                                <Prev/>
                            </button>
                            {slickCarousel}
                            <div/>
                            <button
                                disabled={false}
                                className={'icon-button next'}
                                onClick={() => changeSlide(1)}>
                                <Next/>
                            </button>
                            <div className={'dots'}>
                                {data?.map((banner, index) => (
                                    <div
                                        key={banner.id}
                                        className={classnames('dot', {'active': index === activeIndex})}
                                        onClick={() => onDotsClicked(index)}
                                    />
                                ))}
                            </div>

                        </div>
                }
            </div>
        </>
    );
}

export default HomeViewSlideShow;
