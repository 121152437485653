import React from "react";
import useRouter from "../../../../../hooks/use-router";
import moment from "moment";

const PartFeedbacks = ({list}) => {
    const {location, history} = useRouter();

    /**
     * Adds the CommentForm hash to the location and attaches the user information that are are replying to the
     * location object so that the feedback form can access them.
     * @param {any} item
     */
    const replay = (item) => {
        history.replace({
            pathname: location.pathname,
            hash: '#FeedbackForm',
            state: {
                commentId: item.id,
                UserName: `${item.user?.firstName ?? ''} ${item.user?.lastName ?? ''}`,
            }
        })
    };

    /**
     * Renders the feedbacks of this part.
     * @param {any[]} feedbacks
     * @param {boolean} isReply whether these comments are replies of another comment.
     * @return {JSX.Element}
     */
    const renderFeedbacks = (feedbacks, isReply) => (
        <ul>
            {feedbacks?.map((item, index) => (
                <li key={index}>
                    <div className="box">
                        <div className="d-flex flex-wrap align-items-center mb-3">
                            <div className="name">
                                {`${item.user?.firstName ?? ''} ${item.user?.lastName ?? ''}`}
                            </div>
                            <time dateTime={item.SubmitDateTime}
                                  className="time ml-2">
                                {moment(item.submitDateTime).format('MMM Do YYYY h A')}
                            </time>
                        </div>
                        <p>
                            {item.msg}
                        </p>
                        {
                            isReply &&
                            <button className={'button primary text font-weight-600'}
                                    onClick={() => replay(item)}>
                                Reply
                            </button>
                        }
                    </div>
                    {Array.isArray(item?.children) && renderFeedbacks(item.children, false)}
                </li>
            ))}
        </ul>
    );

    return (
        <>
            <div className="heading small">
                Reviews
            </div>
            <div className="part-information-comments">
                {renderFeedbacks(list, true)}
            </div>
        </>
    )
}

export default PartFeedbacks
