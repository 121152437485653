import LocalStorageService from "./local-storage-service";
import moment from "moment";
import {matchPath} from "react-router";
import routes from "../../../ui/routes";

/**
 * Service that communicates with the local storage of the window
 */
export default class CacheService {
    static keys = LocalStorageService.keys;
    static setLocalStorage = LocalStorageService.set;
    static getLocalStorage = LocalStorageService.get;
    static localStorageRemove = LocalStorageService.remove;
    static localStorageRemoveAll = LocalStorageService.removeAll;

    /**
     * Sets the login information to the local storage of the window
     * @param token {string}
     * @param tokenLifeSpanMinutes {number} the number of minutes until the token expires
     * @param partnerId {string}
     * @param accessLevels {any[]} the access levels of the user
     * @param hasFullAccessLevel {boolean}
     */
    static setLoginInfo(token: string, tokenLifeSpanMinutes: number, partnerId: string, accessLevels: any[], hasFullAccessLevel: boolean) {
        this.setLocalStorage(this.keys.token, token);
        this.setLocalStorage(this.keys.tokenExpiration, moment(Date.now()).add(tokenLifeSpanMinutes, 'minutes').toISOString());
        this.setLocalStorage(this.keys.partnerId, partnerId);
        CacheService.syncUserAccessLevels(accessLevels, hasFullAccessLevel);
        window.dispatchEvent(new Event('storage'))
    }

    /**
     * Checks to see if the user has a token and their expiry time has not passed
     */
    static isLoggedIn(): boolean {
        const tokenAndExpiration = this.retrieveTokenAndExpiration();
        if (!tokenAndExpiration)
            return false;
        return tokenAndExpiration.tokenExpiration > 0;
    }

    /**
     * Determines if the was previously logged into the system.
     */
    static get hasTokenAndExpiration(): boolean {
        return !!this.retrieveTokenAndExpiration();
    }

    /**
     * Retrieves the token and expiry time in minutes form the local storage.
     */
    static retrieveTokenAndExpiration() {
        let token = this.getLocalStorage(this.keys.token);
        let expDateString = this.getLocalStorage(this.keys.tokenExpiration);
        if (!expDateString) {
            return undefined;
        }
        let expDate = expDateString && moment(expDateString);
        let currentDate = moment();
        if (!token || !expDate) {
            return undefined;
        }
        return {token, tokenExpiration: moment.duration(expDate.diff(currentDate)).asMinutes()};
    };

    /**
     * Logs the user out the system by removing the user from the local storage
     * @param {boolean} clearTokenAndExpirationDate
     */
    static removeUserInformation(clearTokenAndExpirationDate: boolean = true) {
        if (clearTokenAndExpirationDate) {
            this.localStorageRemove(this.keys.token);
            this.localStorageRemove(this.keys.tokenExpiration);
        }
        this.localStorageRemove(this.keys.partnerId);
        this.localStorageRemove(this.keys.Ip4);
        // TODO: use StorageEvent and for listeners use key to distinguish changes
        window.dispatchEvent(new Event('storage'))
    }

    /**
     * Syncs the user access levels with the values saved in the local storage if the list of accessLevels are not
     * empty. Otherwise remove the list of accessLevels.
     * @param accessLevels {any[]}
     * @param hasFullAccessLevel {boolean}
     */
    static syncUserAccessLevels(accessLevels: any[], hasFullAccessLevel: boolean = false) {
        this.setLocalStorage(this.keys.hasFullAccessLevel, `${hasFullAccessLevel}`);
        if (!accessLevels?.length) {
            this.setLocalStorage(this.keys.accessLevels, JSON.stringify([]));
            return;
        }
        this.setLocalStorage(this.keys.accessLevels, JSON.stringify(accessLevels?.map(e => e.token)));
    }

    /**
     * Fetches the list of Access levels of the user.
     */
    static getUserAccessLevels() {
        const accessLevels = this.getLocalStorage(this.keys.accessLevels);
        const parsed = !accessLevels ? [] : JSON.parse(accessLevels);
        return {
            accessLevels: parsed,
            hasFullAccessLevel: this.getLocalStorage(this.keys.hasFullAccessLevel ?? false),
        };
    }

    // ########################################     URL Caching   ########################################

    /**
     * Caches the location to the local storage only anf only if the route is not of error, or auth routes
     *
     * @param location {Location}
     */
    static cacheUrl(location?: Location | null) {
        if (!location || location.pathname.includes(routes.error)) {
            return;
        }
        const unCacheableRoutes = [
            routes.error,
            routes.auth.base,
            routes.auth.resetPassword,
            routes.auth.deleteAccount,
        ];
        if (matchPath(location.pathname, {path: unCacheableRoutes, exact: false})) {
            return;
        }
        this.setLocalStorage(this.keys.cachedUrl, JSON.stringify(location));
    }

    /**
     * Gets the cached url from the local storage and removes the cached route
     */
    static getCachedUrl(): Location | null {
        const cachedUrl = this.getLocalStorage(this.keys.cachedUrl);
        if (cachedUrl) {
            this.localStorageRemove(this.keys.cachedUrl);
            return JSON.parse(cachedUrl);
        }
        return null;
    }
}
