import {useCallback, useEffect, useState} from 'react';
import {lg, md, sm, xl, xxl} from "../../../../assets/js/sizes";

/**
 * Fetches the current viewport width of the window based on the bootstrap breakpoint measurements
 * @return {string}
 */
const getViewportWidth = () => {
    const width = Math.max(document.documentElement.clientWidth, (window.innerWidth || 0))
    if (width < sm) return 'xs'
    if (width < md) return 'sm'
    if (width < lg) return 'md'
    if (width < xl) return 'lg'
    if (width < xxl) return 'xl'
    return 'xxl'
}


/**
 * Determines the current viewport width of the window as a bootstrap breakpoint
 *
 * @return {'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl'}
 */
export default function useWindowViewportWidth() {
    const isClient = typeof window === 'object';
    const [windowViewportWidth, setWindowViewport] = useState(getViewportWidth);

    /**
     * Sets the current viewport width in the state to be returned from this hook.
     * @type {(function(): void)|*}
     */
    const onWindowResize = useCallback(() => {
        const newViewPortWidth = getViewportWidth();
        if (newViewPortWidth !== windowViewportWidth) setWindowViewport(newViewPortWidth);
    }, [windowViewportWidth])

    /**
     * Listens to the changes in onWindowResize and whether the window is instantiated and with each change:
     * Sets the event listener on the resizing of window for the hook to be effective.
     */
    useEffect(() => {
        if (!isClient) return;
        window.addEventListener('resize', onWindowResize);
        onWindowResize();
        return () => window.removeEventListener('resize', onWindowResize);
    }, [isClient, onWindowResize]);

    return windowViewportWidth;
}
