import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import OwlCarousel from "react-owl-carousel";
import PartFeedbacks from "../feedbacks";
import PartFeedbackForm from "../feedbacks/form";
import useRouter from "../../../../../hooks/use-router";
import ProductBox from "../../../../../components/app-specific/product-box";
import {ProductBoxTypes} from "../../../../../../core/constants/enums";
import classnames from "classnames";

const options = {
    autoplay: true,
    loop: false,
    margin: 20,
    stagePadding: 10,
    autoplayTimeout: 2000,
    autoplayHoverPause: true,
    responsive: {
        0: {items: 1},
        576: {items: 1},
        768: {items: 2},
        992: {items: 3},
        1200: {items: 4}
    }
};

const PartInformationViewExtrasSection = ({data, getComments}) => {
    const {params} = useRouter();
    const [loading, setLoading] = useState(false);

    /**
     * Listens for the changes in the loading state of the data and with each change:
     * - sets a timer that at the end of it, sets the loading state to false.
     *
     * this feature is only to remove the ui associated with OwlCarousel as it is buggy when changing the data entries.
     */
    useEffect(() => {
        if (!loading) return;
        const timer = setTimeout(() => {
            setLoading(false);
        }, 0)
        return () => {
            if (timer) clearTimeout(timer)
        }
    }, [loading])

    /**
     * Listens for the changes in data and with each change:
     * - sets the loading state to true and dispatches a resize event.
     */
    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
        setLoading(true);
    }, [data])


    return (
        <>
            {
                loading
                    ? <></>
                    : data?.relatedParts?.map((item, index) => (
                        !item.partsList?.length
                            ? <span key={index}/>
                            : <section
                                key={index}
                                className={classnames('related-parts-container')}>
                                <Container className={'pt-4'}>
                                    <Row>
                                        <Col xs={12}>
                                            <div className="heading small mb-0">
                                                {item?.relationTitle ?? ''}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12}>
                                            <OwlCarousel
                                                {...options}
                                                className={'owl-category'}>
                                                {item?.partsList?.map((item, index) => (
                                                        <div key={index}>
                                                            <ProductBox
                                                                type={ProductBoxTypes.medium}
                                                                data={item}
                                                                isChildOfCarousel
                                                                className={'medium-container'}
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            </OwlCarousel>
                                        </Col>
                                    </Row>
                                </Container>
                            </section>
                    ))
            }
            <Container>
                <Row className={'mb-5'}>
                    <Col lg={8} xs={12} className={'mt-5'}>
                        <div className="comments">
                            <PartFeedbacks list={data?.partFeedBacks ?? []}/>
                            <PartFeedbackForm fetchAgain={getComments}/>
                        </div>
                    </Col>
                    <Col xl={3} lg={4} xs={12} className={'ml-xl-auto mt-5 recent-search'}>
                        {
                            data?.recentSearched?.visits?.length > 0 &&
                            <>
                                <div className="heading small">
                                    Recent Search
                                </div>
                                {data.recentSearched?.visits
                                    ?.filter(e => e.clickedPart && e.clickedPart?.partNumber?.toLowerCase() !== params?.id?.toLowerCase())
                                    ?.slice(0, Math.min(data.recentSearched?.visits?.length, 4))
                                    ?.map((item, index) => (
                                        <div className={'medium-container'} key={index}>
                                            <ProductBox
                                                data={item.clickedPart}
                                                isChildOfCarousel
                                                type={ProductBoxTypes.medium}
                                            />
                                        </div>

                                    ))}
                            </>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default PartInformationViewExtrasSection;
