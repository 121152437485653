import React, {useMemo} from "react";
import {ReactComponent as CheckBoxIcon} from "../../../assets/images/checkbox/checkbox.svg";
import {ReactComponent as CheckBoxCheckedIcon} from "../../../assets/images/checkbox/checkbox-active.svg";
import {ReactComponent as RadioIcon} from "../../../assets/images/radio/radio.svg";
import {ReactComponent as RadioCheckedIcon} from "../../../assets/images/radio/radio-active.svg";
import classnames from "classnames";
import {Checkbox} from "@mui/material";

/**
 * @param {string} className
 * @param {boolean} radio
 * @param {import('@mui/material').CheckboxProps} props
 * @return {JSX.Element}
 * @constructor
 */
const DataGridCheckbox = ({className, radio = false, ...props}) => {

    const icons = useMemo(() => {
        if (!radio)
            return {
                icon: <CheckBoxIcon/>,
                checkedIcon: <CheckBoxCheckedIcon/>,
            }
        return {
            icon: <RadioIcon/>,
            checkedIcon: <RadioCheckedIcon/>,
        }
    }, [radio])

    return (
        <>
            <Checkbox
                {...props}
                {...icons}
                className={classnames('data-grid-checkbox', className)}
            />
        </>
    )
}

export default DataGridCheckbox;
