import React, {useEffect, useState} from "react";
import MuiInput from "./mui-input";
import ValidateMessages from "../../../../core/constants/validate-messages";
import RegexValidations from "../../../../core/services/regex-validations";
import {userApis} from "../../../../core/constants/endpoints/endpoints";
import {apiMethods} from "../../../../core/constants/enums";
import api from "../../../../core/services/api/api";
import useIsMounted from "../../../hooks/use-is-mounted";
import {CircularProgress} from "@material-ui/core";

/**
 *
 * @param {boolean} form whether this input is a form input or not.
 * @param {boolean} required
 * @param {function(boolean)} setValidating
 * @param {boolean} shouldValidateWithApiCall
 * @param {import("mui-rff").TextFieldProps & import("@material-ui/core").OutlinedTextFieldProps} props
 */
const EmailInput = ({form, required, setValidating, shouldValidateWithApiCall = true, ...props}) => {
    const [emailValidation, setEmailValidation] = useState({
        validating: false,
        message: null,
        error: false,
        email: null
    })
    const isMounted = useIsMounted();

    useEffect(() => {
        setValidating(emailValidation.validating);
    }, [emailValidation])

    /**
     * Validates the email of the user by checking its length, its validity through regex and then calling api for
     * duplication check
     * @param email {string}
     * @return {Promise<string | null>} The error message or null if valid.
     */
    const validateEmail = async (email) => {
        if (email !== emailValidation.email) {
            setEmailValidation(prevState => ({...prevState, email: email}));
        } else {
            if (!emailValidation.error) return null
            return emailValidation.message;
        }
        if (!email?.length) {
            if (!!required) {
                setEmailValidation(prevState => ({...prevState, error: true, message: ValidateMessages.required}));
                return ValidateMessages.required;
            }
            return null;
        }
        if (!RegexValidations.email.test(email?.toLowerCase())) {
            const message = ValidateMessages.incorrectType('Email');
            setEmailValidation(prevState => ({
                ...prevState,
                error: true,
                message: message
            }));
            return message;
        }
        if (shouldValidateWithApiCall) {
            setEmailValidation(prevState => ({...prevState, validating: true}));
            const response = await api({
                url: userApis.validateEmail(email),
                method: apiMethods.get,
                showError: false,
                loginRequired: false,
            });
            if (!isMounted()) return null;
            if (!response?.resultFlag) {
                const message = response?.message ?? '';
                setEmailValidation(prevState => ({
                    ...prevState,
                    validating: false,
                    error: true,
                    message: message,
                }));
                return message;
            }
            setEmailValidation(prevState => ({...prevState, validating: false, error: false, message: null}));
        }
        return null;
    }

    /**
     * Validates the Email input of the form for being non-empty, an email, and not a duplicate.
     * @param value {string}
     * @return {Promise<string>}
     */
    const validateEmailFormat = async (value) => {
        if (!value?.length) {
            return ValidateMessages.required;
        }
        if (!RegexValidations.email.test(value?.toLowerCase())) {
            return ValidateMessages.incorrectType('Email');
        }
        // return validateEmail(value);
        return null;
    }

    /**
     * Callback that is called when the input is blurred.
     * @param {FocusEvent<HTMLInputElement | HTMLTextAreaElement>} e
     */
    const onBlur = async (e) => {
        e.persist();
        if (props.onBlur)
            await props.onBlur(e);
        await validateEmail(e.target?.value)
    }

    /**
     * Custom error function that only shows error when form has been submitted and not changed
     * @param submitError
     * @param error
     * @return {boolean}
     */
    const customShowErrorFunction = ({meta: {error, submitFailed}}) => {
        return (submitFailed && error);
    }

    return (
        <>
            {
                form
                    ? <MuiInput
                        {...props}
                        form
                        showError={customShowErrorFunction}
                        required={!!required}
                        error={!!emailValidation.error}
                        helperText={!!emailValidation.error ? emailValidation.message : null}
                        onBlur={onBlur}
                        fieldProps={{
                            ...props?.fieldProps,
                            validate: validateEmailFormat
                        }}
                        InputProps={{
                            ...props?.InputProps,
                            endAdornment: (
                                <>
                                    {emailValidation.validating && <CircularProgress color={'secondary'} size={20}/>}
                                    {props?.InputProps?.endAdornment}
                                </>
                            ),
                        }}
                    />
                    : <MuiInput
                        {...props}
                        showError={customShowErrorFunction}
                        error={!!emailValidation.error}
                        helperText={!!emailValidation.error ? emailValidation.message : null}
                        onBlur={onBlur}
                        inputProps={{
                            ...props?.inputProps,
                            "aria-required": !!required
                        }}
                        InputProps={{
                            ...props?.InputProps,
                            endAdornment: (
                                <>
                                    {emailValidation.validating && <CircularProgress color={'secondary'} size={20}/>}
                                    {props?.InputProps?.endAdornment}
                                </>
                            ),
                        }}
                    />
            }
        </>
    )
}

export default EmailInput;
