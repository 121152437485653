import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {ReactComponent as PinnedIcon} from "../../../../../../assets/images/comparison/pinned.svg";
import {ReactComponent as PinIcon} from "../../../../../../assets/images/comparison/pin.svg";
import {CircularProgress, Fade, Tooltip} from "@material-ui/core";
import {
    ReactComponent as RemovePropertyIcon,
    ReactComponent as RemoveIcon
} from "../../../../../../assets/images/comparison/remove-property.svg";
import {ReactComponent as PreviewIcon} from "../../../../../../assets/images/comparison/preview.svg";
import Slider from "react-slick";
import LazyImage from "../../../../../components/app-specific/lazy-lmage";
import classnames from "classnames";
import {formatMoney} from "../../../../../../core/services/utils/utils";
import useRouter from "../../../../../hooks/use-router";
import {routeFunctions} from "../../../../../routes";
import {TechnicalPropertyTypes} from "../../../../../../core/constants/enums";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import ObserversService from "../../../../../../core/services/observers-service";


const CompareViewTableSection = ({
                                     selectedCategory,
                                     properties,
                                     parts,
                                     removePart
                                 }) => {
    const [pinnedPartIds, setPinnedPartIds] = useState([]);
    const [technicalProperties, setTechnicalProperties] = useState([]);
    const [infoMaxHeight, setInfoMaxHeight] = useState(200);

    const pinnedParts = useMemo(() => parts
                ?.filter(e => pinnedPartIds.includes(e.id))
                ?.map(e => ({
                    ...e,
                    pinned: true
                }))
            ?? [],
        [parts, pinnedPartIds])

    const unpinnedParts = useMemo(() => parts
                ?.filter(e => !pinnedPartIds.includes(e.id))
                ?.map(e => ({
                    ...e,
                    pinned: false
                }))
            ?? [],
        [parts, pinnedPartIds])

    /**
     * With each change in the selected category:
     * - sets the technical properties of the state.
     * - resets the max height of the information section
     */
    useLayoutEffect(() => {
        setTechnicalProperties(properties ?? []);
        setInfoMaxHeight(200);
    }, [selectedCategory?.id])

    /**
     * Toggles the pinned state of the given part.
     * @param part
     */
    const togglePinned = (part) => {
        setPinnedPartIds(prevState => {
            if (prevState.includes(part.id)) {
                return prevState.filter(e => e !== part.id);
            }
            return [...prevState, part.id]
        })
    }

    /**
     * Removes the given property from the list of technical properties of the state.
     * @param property
     */
    const removeProperty = (property) => {
        setTechnicalProperties(prevState => prevState?.filter(e => e.id !== property.id))
    }

    /**
     * Sets the max height of the information section.
     * @param {number} height
     */
    const setInformationMaxHeight = (height) => {
        setInfoMaxHeight(prevState => Math.max(height, prevState));
    }

    return (
        <>
            <div
                className={'compare-table-wrapper'}
                style={{
                    '--info-height': `${infoMaxHeight}px`,
                }}
            >
                <table className={'pinned-compare-table'}>
                    <thead>
                    <CompareViewTableHeaderRow
                        parts={pinnedParts}
                        togglePinned={togglePinned}
                        pinned
                    />
                    </thead>
                    <tbody>
                    {
                        [
                            null,
                            ...(technicalProperties ?? [])
                        ].map(property => (
                            <CompareViewTableBodyRow
                                key={`table-row-${property?.id ?? 'init'}`}
                                property={property}
                                parts={pinnedParts}
                                removeProperty={removeProperty}
                                selectedCategory={selectedCategory}
                                setInformationMaxHeight={setInformationMaxHeight}
                                removePart={removePart}
                                pinned
                            />
                        ))
                    }
                    </tbody>
                </table>
                <div className={'compare-table-container'}>
                    <table className={'compare-table'}>
                        <thead>
                        <CompareViewTableHeaderRow
                            parts={unpinnedParts}
                            togglePinned={togglePinned}
                        />
                        </thead>
                        <tbody>
                        {
                            [null, ...(technicalProperties ?? [])].map(property => (
                                <CompareViewTableBodyRow
                                    key={`table-row-${property?.id ?? 'init'}`}
                                    property={property}
                                    parts={unpinnedParts}
                                    selectedCategory={selectedCategory}
                                    removeProperty={removeProperty}
                                    setInformationMaxHeight={setInformationMaxHeight}
                                    removePart={removePart}
                                />
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

const CompareViewTableHeaderRow = ({parts, togglePinned, pinned}) => {

    return (
        <>
            <tr>
                {
                    pinned &&
                    <td>
                        <div className={'compare-table-header'}/>
                    </td>
                }
                {
                    parts?.map(part => (
                        <td key={`header-${part.id}`}>
                            <div className={'compare-table-header'} onClick={() => togglePinned(part)}>
                                {
                                    part?.pinned
                                        ? <PinnedIcon/>
                                        : <PinIcon/>
                                }
                                <Tooltip title={part?.partNo ?? ''}>
                                    <p>
                                        {part?.partNo ?? ''}
                                    </p>
                                </Tooltip>
                            </div>
                        </td>
                    ))
                }
            </tr>

        </>
    );
}

const CompareViewTableBodyRow = ({
                                     property,
                                     parts,
                                     pinned,
                                     removeProperty,
                                     selectedCategory,
                                     setInformationMaxHeight,
                                     removePart,
                                 }) => {

    return (
        <tr>
            {
                // Image section
                property === null &&
                <>
                    {
                        pinned &&
                        <td>
                            <div className={'compare-view-part-info-container'}/>
                        </td>
                    }
                    {
                        parts?.map(part => (
                            <td key={`part-image-${part.id}`}>
                                <CompareViewPartInformationSection
                                    part={part}
                                    selectedCategory={selectedCategory}
                                    setMaxHeight={setInformationMaxHeight}
                                    removePart={removePart}
                                />
                            </td>
                        ))
                    }
                </>
            }
            {
                // Properties section
                property !== null &&
                <>
                    {
                        pinned &&
                        <td>
                            <div className={'compare-table-cell'}>
                                <RemovePropertyIcon onClick={() => removeProperty(property)}/>
                                <Tooltip title={property?.title ?? ''} placement={'bottom-start'}>
                                    <p>
                                        {property?.title ?? ''}
                                    </p>
                                </Tooltip>
                            </div>
                        </td>
                    }
                    {
                        // Property values section
                        parts?.map(part => {
                            let value = part?.properties?.find(e => e.propertyId === property.id)?.value ?? '--'
                            if (property.propType === TechnicalPropertyTypes.switch) {
                                value = value === '1' ? 'Yes' : value === '0' ? 'No' : '--';
                            }
                            return (
                                <td key={`property-${property.id}-part-${part.id}`}>
                                    <div className={'compare-table-cell'}>
                                        <Tooltip title={value}>
                                            <p>
                                                {value}
                                            </p>
                                        </Tooltip>
                                    </div>
                                </td>
                            );
                        })
                    }
                </>
            }
        </tr>
    );
}

const CompareViewPartInformationSection = ({part, selectedCategory, setMaxHeight, removePart: removePartProps}) => {
    const {history} = useRouter();
    const [activeIndex, setActiveIndex] = useState(0);
    const [removing, setRemoving] = useState(false);
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const containerRef = useRef();
    /**@type {React.MutableRefObject<import('react-slick').default>}*/
    const sliderRef = useRef();
    const isMounted = useIsMounted();

    const images = useMemo(() => part?.images ?? [], [part])
    const priceNotAvailable = useMemo(() => (part?.price ?? 0) <= 0, [part]);
    const hasNewPrice = useMemo(() => part?.price < part?.originalPrice, [part]);

    /**
     * As soon as the component mounts:
     * - attaches an observer that determines the height of the content.
     */
    useEffect(() => {
        if (!containerRef.current)
            return;
        const observer = ObserversService.newResizeObserver(determineHeight);
        ObserversService.observeResizeObserver(observer, containerRef.current);
        determineHeight().then();
        return () => ObserversService.disconnectResizeObserver(observer);
    }, [containerRef, part?.id, selectedCategory?.id])

    /**
     * Calls the appropriate callback with the height of the content.
     */
    const determineHeight = async () => {
        await new Promise(r => setTimeout(r, 200));
        if (!isMounted())
            return;
        const height = containerRef.current?.getBoundingClientRect()?.height ?? 0;
        setMaxHeight(height);
    }

    /**
     * After each change in the slide, sets the [activeSlide] to the received number
     * @param index
     */
    const afterSlideChange = (index) => {
        setActiveIndex(index);
    }

    /**
     * Removes the given part from the list table parts.
     */
    const removePart = async () => {
        setRemoving(true);
        await removePartProps(part);
        setRemoving(false);
    }

    /**
     * Navigates to the current parts' information view.
     */
    const navigateToPart = () => {
        history.push(routeFunctions.main.single(part.partNo));
    }

    return (
        <>
            <div ref={containerRef} className={'compare-view-part-info-container'}>
                <div className={'compare-view-part-info'}>
                    <div className={'part-image'}>
                        <Slider
                            autoplay={false}
                            infinite={true}
                            initialSlide={activeIndex}
                            ref={sliderRef}
                            afterChange={afterSlideChange}
                            slidesToShow={1}
                        >
                            {
                                images.map((image) => (
                                    <div key={image.id} className="img">
                                        <LazyImage
                                            src={image.fileName}
                                            alt={image.fileName}
                                            height={150}
                                        />
                                    </div>
                                ))
                            }
                        </Slider>
                        <div className={'compare-view-part-dots'}>
                            {
                                images.map((item, index) => (
                                    <div
                                        key={item.id}
                                        onClick={() => sliderRef.current?.slickGoTo(index)}
                                        className={classnames(
                                            'dot',
                                            {
                                                'active': index === activeIndex,
                                                'active-minus-one': (index === activeIndex + 1) || (index === activeIndex - 1),
                                                'active-minus-two': (index === activeIndex + 2) || (index === activeIndex - 2),

                                            }
                                        )}
                                    />
                                ))
                            }
                        </div>
                        <button
                            onClick={removePart}
                            disabled={removing}
                            className={'button text remove p-0'}
                        >
                            {
                                removing
                                    ? <CircularProgress size={25} color={'secondary'}/>
                                    : <RemoveIcon/>
                            }
                        </button>
                        <Fade in={!removing}>
                            <button
                                onClick={() => navigateToPart()}
                                disabled={removing}
                                className={'button text preview p-0'}
                            >
                                <PreviewIcon/>
                            </button>
                        </Fade>

                    </div>
                    <div className={'information'}>
                        {
                            hasNewPrice &&
                            <Fade in={hasNewPrice}>
                                <p className={'price new'}>
                                    {formatMoney(part?.price)}
                                </p>
                            </Fade>
                        }
                        {
                            priceNotAvailable
                                ? <p className={'price not-available'}/>
                                : <p className={classnames('price', {'old': hasNewPrice})}>
                                    {formatMoney(hasNewPrice ? part?.originalPrice ?? part?.price : part?.price)}
                                </p>
                        }
                        <p className={'main-category'}>
                            {selectedCategory?.title ?? ''}
                        </p>
                        {
                            selectedCategory?.id !== part?.category?.id &&
                            <p className={'sub-category'}>
                                {part?.category?.title ?? ''}
                            </p>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}


export default CompareViewTableSection;
