import React, {useMemo} from "react";
import {Col, Container, Row} from "reactstrap";
import {HomeViewComponentTypes} from "../../../../core/constants/enums";
import HomeViewSlideShow from "./slide-show";
import HomeViewBanner from "./banner";
import HomeViewProducts from "./products";
import HomeViewCategories from "./categories";
import HomeViewTimeBased from "./time-based";

const HomePageComponent = ({component}) => {
    const withContainer = useMemo(() =>
            HomeViewComponentTypes.timeBased !== component.type?.id,
        [component?.type?.id])

    const content = useMemo(() => {
        switch (component?.type?.id) {
            case HomeViewComponentTypes.slideShow:
                return <HomeViewSlideShow data={component?.content?.banners ?? []}/>
            case HomeViewComponentTypes.banner:
                return <HomeViewBanner data={component}/>
            case HomeViewComponentTypes.smallProduct:
            case HomeViewComponentTypes.mediumProduct:
            case HomeViewComponentTypes.largeProduct:
                return <HomeViewProducts data={component}/>
            case HomeViewComponentTypes.category:
                return <HomeViewCategories data={component}/>
            case HomeViewComponentTypes.timeBased:
                return <HomeViewTimeBased data={component}/>
            default:
                return <></>
        }
    }, [component])

    const container = useMemo(() => (
        <Container>
            <Row>
                <Col xs={12} className={'mt-5 mb-2'}>
                    {content}
                </Col>
            </Row>
        </Container>
    ), [content])

    if (withContainer)
        return container;
    return content;
}

export default HomePageComponent;
