import React, {useRef, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import CreateCreditCardForm from "../../app-specific/forms/create-credit-card-form";
import {createUUId} from "../../../../core/services/utils/utils";

const CreateCreditCardDialog = ({open, toggle, onCreated}) => {
    const [adding, setAdding] = useState(false);
    const formIdRef = useRef(createUUId(true));

    return (
        <>
            <Modal
                isOpen={open}
                toggle={toggle}
                className={'add-credit-card-dialog'}
                centered
                size={'lg'}>
                <ModalHeader>
                    Add Credit Card
                </ModalHeader>
                <ModalBody className={'px-5 mt-4'}>
                    <CreateCreditCardForm
                        id={formIdRef.current}
                        setLoading={setAdding}
                        onCreated={onCreated}
                    />
                </ModalBody>
                <ModalFooter className={'d-flex align-items-center justify-content-end'}>
                    <button className={'button text primary '}
                            disabled={adding}
                            onClick={() => toggle(false)}>
                        Cancel
                    </button>
                    <button className={'button primary px-5'}
                            form={formIdRef.current}
                            disabled={adding}
                            type={'submit'}>
                        {
                            adding
                                ? "Adding..."
                                : "Add"
                        }
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
}


export default CreateCreditCardDialog;
