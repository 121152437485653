import React, {useLayoutEffect} from "react";
import useRouter from "../../hooks/use-router";
import routes from "../../routes";

const RedirectView = () => {
    const {history, stringifyUrl} = useRouter();

    /**
     * As soon as this view is to be painted:
     * checks to see if the state is provided:
     *      - if not, redirects the user to the error view.
     *      - else, redirects the user to the url that is given in the state.
     */
    useLayoutEffect(() => {
        if (!history.location.state) {
            return history.replace(routes.error);
        }
        const state = history.location.state;
        history.replace(stringifyUrl({
            url: state.location.pathname,
            query: state.query,
            fragmentIdentifier: state.hash,
        }))
    }, [])

    return <></>;
}

export default RedirectView;
