import React, {useEffect} from 'react';
import {Col, Container, Row} from 'reactstrap';
import {Route, Switch} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {setReduxHeaderHeight, setReduxHeaderType} from "../../../redux/entities/header/actions";
import Sidebar from "../../containers/sidebar";
import SavedItemsView from './saved-items';
import ProfileView from './profile';
import AddressView from './address-management';
import OrdersView from "./orders";
import PaymentMethodView from './payment-methods'
import routes from "../../routes";
import UserManagement from "./user-mangement";
import useRouter from "../../hooks/use-router";
import {HeaderTypes} from "../../../core/constants/enums";
import {matchPath} from "react-router";
import CompareView from "./compare";
import classnames from "classnames";
import {sidebarHeaderHeight} from "../../../assets/js/sizes";

const DashboardView = () => {
    const {history, location} = useRouter();
    const dispatch = useDispatch();
    // routes that will require the sidebar to be removed
    const removeSidebar = !!matchPath(location.pathname, {
        path: [
            routes.dashboard.compare,
        ],
        exact: true,
    });

    // routes that will require the full width of the window (no container limitations.)
    const maxWidth = !!matchPath(location.pathname, {
        path: [
            routes.dashboard.compare,
        ],
        exact: true,
    });

    /**
     * Listens to the changes in url pathname and with each change:
     * - sets the header height to 230 to comply with the height of the sidebar
     */
    useEffect(() => {
        if (matchPath(location.pathname, {path: routes.dashboard.base, exact: true})) {
            history.replace(routes.dashboard.savedItems)
        }
        dispatch(setReduxHeaderHeight(sidebarHeaderHeight))
        dispatch(setReduxHeaderType(HeaderTypes.loggedIn))
    }, [location?.pathname]);

    return (
        <>
            <section className={'py-5'}>
                <Container className={'transition'}
                           style={{...(maxWidth ? {maxWidth: window.innerWidth} : {})}}>
                    <Row>
                        <Col className={classnames('transition')}
                             style={{flex: removeSidebar ? '0 0 0' : 'auto'}}
                             lg={3} xs={12}>
                            <Sidebar remove={removeSidebar}/>
                        </Col>
                        <Col className={'transition'} lg={removeSidebar ? 12 : 9} xs={12}>
                            <main>
                                <Switch>
                                    <Route path={routes.dashboard.savedItems} exact>
                                        <SavedItemsView/>
                                    </Route>
                                    <Route path={routes.dashboard.compare} exact>
                                        <CompareView/>
                                    </Route>
                                    <Route path={routes.dashboard.profile} exact>
                                        <ProfileView/>
                                    </Route>
                                    <Route path={routes.dashboard.address} exact>
                                        <AddressView/>
                                    </Route>
                                    <Route path={routes.dashboard.orders} exact>
                                        <OrdersView/>
                                    </Route>
                                    <Route path={routes.dashboard.paymentMethods.base} exact>
                                        <PaymentMethodView/>
                                    </Route>
                                    {/*<Route path={routes.dashboard.paymentMethods.transactionsSummary} exact>*/}
                                    {/*    <CreditCardTransactionSummary/>*/}
                                    {/*</Route>*/}
                                    <Route path={routes.dashboard.userManagement} exact>
                                        <UserManagement/>
                                    </Route>
                                </Switch>
                            </main>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
};

export default DashboardView;
