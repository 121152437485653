import {HeaderTypes} from "../../../core/constants/enums";
import {createSlice} from "@reduxjs/toolkit";

/**
 * Reducer for handling header slice of the redux.
 *
 * this reducer is used throughout the application to change the type and size of the header.
 */
const headerReduxSlice = createSlice({
    name: 'header',
    initialState: {
        height: 0,
        type: HeaderTypes.loggedOut,
    },
    reducers: {
        headerHeight: (state, action) => {
            state.height = action.payload
        },
        headerType: (state, action) => {
            state.type = action.payload
        }
    }
})

export default headerReduxSlice;
