import React, {useEffect, useState} from "react";
import routes, {routeFunctions} from "../../../routes";
import useRouter from "../../../hooks/use-router";
import {ProductsSearchTypes} from "../../../../core/constants/enums";
import LazyImage from "../lazy-lmage";
import ProductSearchUtils from "../../../../core/services/utils/product-search-utils";

const CategoryBox = ({data: dataProp}) => {
    const {history, stringifyUrl} = useRouter();
    const [data, setData] = useState(dataProp)
    const [category, setCategory] = useState({});

    /**
     * Listens for the changes in dataProp and with each change:
     * - sets the inner state data and category.
     */
    useEffect(() => {
        setData(data ?? {})
        setCategory({
            Id: data?.Id == null ? 0 : data.Id,
            CoverImage: data?.CoverImage ?? '',
            ProductCount: data?.ProductCount ?? '',
            SubCategories: data?.SubCategories ?? [],
            Title: data?.Title ?? '',
        });
    }, [dataProp]);

    /**
     * If this category has children, navigates the user to see the subcategories of the selected category.
     * else: navigates the user to products view with that specific categoryId as its filter.
     *
     */
    const onCategoryClicked = () => {
        if (category.SubCategories.length > 0) {
            return history.push(routeFunctions.main.categories(category.Id))
        }
        const urlStringified = stringifyUrl({
            url: routes.main.products,
            query: {
                type: ProductsSearchTypes.categories,
                data: JSON.stringify({
                    [ProductsSearchTypes.categories]: {
                        [ProductSearchUtils.basicSearchQueryKeys.categoryId]: category?.Id,
                    }
                }),
            },
        });
        history.push(urlStringified);

    }

    return (
        <>
            <div className={'category-box-container'} onClick={onCategoryClicked}>
                <div className={'category-header-section'}>
                    <p className={'mb-0'}>{category.Title}</p>
                </div>
                <div className="category-box">
                    <div className={'inner'}>
                        <div className="img">
                            <LazyImage
                                src={category.CoverImage}
                                alt={category.Title}
                            />
                        </div>
                        <div className="content">
                            <div>
                                {
                                    data.SubCategories &&
                                    <ul>
                                        {data.SubCategories.map((cate, index) => index < 4
                                            ? <li key={index}>{cate.Title}</li>
                                            : null)}
                                    </ul>
                                }
                                {
                                    data.SubCategories?.length > 4 &&
                                    <p className={'more'}>
                                        + {data.SubCategories?.length - 4} more
                                    </p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CategoryBox;
