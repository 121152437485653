import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import OwlCarousel from "react-owl-carousel";
import ProductBox from "../product-box";
import {ReactComponent as Prev} from "../../../../assets/images/home-view/slide-show-prev.svg";
import {ReactComponent as Next} from "../../../../assets/images/home-view/slide-show-next.svg";
import {lg, md, sm, xl, xs} from "../../../../assets/js/sizes";
import classnames from "classnames";
import useWindowViewportWidth from "../../../hooks/use-window/viewport-width";
import {createUUId} from "../../../../core/services/utils/utils";
import ProductContext from '../../../contexts/product'

// Default values for the responsive viewport for the items inside the carousel based on the view size
const defaultResponsive = {
    [xs]: {items: 1},
    [sm]: {items: 1},
    [md]: {items: 2},
    [lg]: {items: 3},
    [xl]: {items: 3}
}

const ProductSlider = ({products, type, responsive = defaultResponsive, carouselSlideSpeed = 250}) => {
    const [hasNext, setHasNext] = useState(false);
    const [hasPrev, setHasPrev] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const viewportWidth = useWindowViewportWidth();
    const [childHeight, setChildHeight] = useState(0);
    const initialRender = useRef(true);
    /**@type {React.MutableRefObject<import('react-owl-carousel').default>}*/
    const carouselRef = useRef();
    const containerIdRef = useRef(createUUId());

    /**
     * For Each change in view port width and containerIdRef:
     * - Sets a timer for getting all the elements in the carousel
     * - Calculates the max height of the elements in the list and sets the value in the context
     * - Increases the max height only in init render to account for box shadow rendering
     */
    useEffect(() => {
        if (!products?.length)
            return;
        const timer = setTimeout(() => {
            const containerElement = document.getElementById(containerIdRef.current);

            if (!containerElement)
                return;

            const products = containerElement.getElementsByClassName('product-container');
            if (!products?.length)
                return;

            let height = [...products, {dummy: true}, {dummy: true}]?.reduce((p, c) => {
                if (c?.dummy) {
                    return p
                }
                return Math.max(p, c.getBoundingClientRect().height)
            }, 0);

            if (initialRender.current) {
                height += 10;
                initialRender.current = false;
            }
            setChildHeight(height);

        }, 500)
        return () => clearTimeout(timer);
    }, [products, containerIdRef, viewportWidth])

    /**
     * With every change in view port size:
     * - navigates the carousel to the current index
     */
    useLayoutEffect(() => {
        for (let i = 0; i < currentIndex; ++i) {
            carouselRef.current.next(0);
        }
    }, [viewportWidth, childHeight]);

    /**
     * Changes the carousel item based on the offset:
     *
     *  If the offset is greater than zero shifts it the right
     *  Otherwise, shifts it to the left
     *
     * @param {number} offset
     */
    const changeSlide = (offset) => {
        if (offset > 0) {
            carouselRef.current.next(carouselSlideSpeed);
            return;
        }
        carouselRef.current.prev(carouselSlideSpeed);
    }

    /**
     * Changes the values of [hasNext] and [hasPrev] depending on the current state of the owl
     * carousel.
     * @param {Event} e
     */
    const onOwlCarouselChanged = (e) => {
        if (e?.item?.index === null)
            return;

        const pageInfo = e?.page ?? {};
        const itemsInfo = e?.item ?? {};
        const hasNext = (itemsInfo.index + pageInfo.size) < itemsInfo.count;
        const hasPrev = itemsInfo.index > 0;

        setCurrentIndex(itemsInfo.index);
        setHasNext(hasNext);
        setHasPrev(hasPrev)
    }

    const owlCarousel = useMemo(() => {
        return (
            <OwlCarousel
                autoplay={false}
                loop={false}
                margin={0}
                responsive={responsive}
                ref={carouselRef}
                onChanged={onOwlCarouselChanged}
                id={containerIdRef.current}
                className={'owl-products'}>
                {products?.map((item) => (
                        <div
                            key={item?.id}
                            className={classnames('px-4')}>
                            <ProductBox
                                data={item}
                                type={type}
                                isChildOfCarousel
                            />
                        </div>
                    )
                )}
            </OwlCarousel>
        );
    }, [products, responsive, type, viewportWidth, childHeight])

    return (
        <>
            <ProductContext.Provider value={{enforcedHeight: childHeight}}>
                <div className={'product-slider'}>
                    {(hasPrev || hasNext) &&
                        <button
                            disabled={!hasPrev}
                            className={'icon-button prev'}
                            onClick={() => changeSlide(-1)}>
                            <Prev/>
                        </button>
                    }
                    {owlCarousel}
                    {(hasPrev || hasNext) &&
                        <button
                            disabled={!hasNext}
                            className={'icon-button next'}
                            onClick={() => changeSlide(1)}>
                            <Next/>
                        </button>
                    }
                </div>
            </ProductContext.Provider>
        </>
    )
}

export default ProductSlider;
