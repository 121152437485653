import React, {useMemo} from "react";
import {ReactComponent as RemoveIcon} from "../../../../../../assets/images/comparison/remove-property.svg";
import {Link} from "react-router-dom";
import {routeFunctions} from "../../../../../routes";
import {confirmationDialog} from "../../../../../../redux/entities/dialogs/actions";
import {useDispatch} from "react-redux";

const CompareViewProductNotes = ({
                                     product,
                                     removeProduct,
                                 }) => {
    const dispatch = useDispatch();
    const productLink = useMemo(() => routeFunctions.main.single(product?.partNo), [product?.partNo]);

    /**
     * Handles removing a product from the list of items in compare screen:
     * - Shows a confirmation dialog from for user
     * - after confirmation, calls the api regarding this action
     *
     * @param {MouseEvent<HTMLButtonElement>} e
     */
    const getRemoveProductConfirmation = (e) => {
        e.stopPropagation()
        e.preventDefault()
        dispatch(confirmationDialog({
            open: true,
            title: "Remove Product",
            description: "Do you want to remove the following product?",
            callback: (confirmed) => {
                if (confirmed) removeProduct(product).then();
                return true;
            },
            mandatory: false,
        }))
    }

    return (
        <>
            <div className={'compare-product-notes'}>
                <div className={'compare-product-notes-header'}>
                    <Link to={productLink} className={'part-no'}>
                        {product?.partNo ?? ''}
                    </Link>
                    {
                        removeProduct && !!product &&
                        <RemoveIcon
                            className={'ml-2 remove-button'}
                            onClick={getRemoveProductConfirmation}
                        />
                    }
                </div>
                <div className={'notes-container'}>
                    {
                        product?.notes?.map(note => (
                            <div className={'note'}>
                                <h6>{note.title}</h6>
                                <div dangerouslySetInnerHTML={{__html: note.msg}}/>
                            </div>
                        )) ?? <div className={'note not-available'}>
                            <p>There are no notes available</p>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default CompareViewProductNotes;
