import React, {useEffect, useState} from "react";
import useRouter from "../../../../hooks/use-router";
import routes, {routeFunctions} from "../../../../routes";
import LazyImage from "../../lazy-lmage";
import {Col} from "reactstrap";
import ProductSearchUtils from "../../../../../core/services/utils/product-search-utils";
import classnames from "classnames";
import {ProductsSearchTypes} from "../../../../../core/constants/enums";

const HomeViewCategories = ({data}) => {
    const {history, stringifyUrl} = useRouter();
    const [categories, setCategories] = useState([]);

    /**
     * With each change in data:
     * - sets the local categories state.
     */
    useEffect(() => {
        setCategories(data?.content?.categories?.map(e => ({...e, loading: false})) ?? [])
    }, [data])

    /**
     * Navigates to the categories view.
     */
    const navigateToCategories = () => {
        history.push(routeFunctions.main.categories())
    }

    /**
     * Navigates the user to products search view with the given category's id.
     * @param category
     */
    const navigateToProductSearch = (category) => {
        history.push(stringifyUrl({
                url: routes.main.products,
                query: {
                    type: ProductsSearchTypes.categories,
                    data: JSON.stringify({
                        [ProductsSearchTypes.categories]: {
                            [ProductSearchUtils.basicSearchQueryKeys.categoryId]: category?.id,
                        }
                    })
                },
            })
        )
    }

    /**
     * Navigates to the category views based on the current category's position.
     * @param category
     */
    const navigateToCategory = (category) => {
        // Adds loading cover to the ui element of category
        setCategories(prevState => prevState?.map(e => e.id === category?.id ? {...e, loading: true} : e));

        // There are no subcategories
        if (category.isLeaf) {
            navigateToProductSearch(category);
            setCategories(prevState => prevState?.map(e => e.id === category?.id ? {...e, loading: false} : e));
        } else {
            setCategories(prevState => prevState?.map(e => e.id === category?.id ? {...e, loading: false} : e));
            return history.push(routeFunctions.main.categories(category.id))

        }
    }

    return (
        <>
            <div className={'home-view-categories'}>
                <div className={'sticky-header w-100 pb-3'}>
                    <div className={'heading small mb-0'}>
                        {data?.title ?? ''}
                    </div>
                    <div>
                        <button
                            onClick={navigateToCategories}
                            className={'button primary outlined py-2'}>
                            See All
                        </button>
                    </div>
                </div>
                <div className={'d-flex flex-wrap w-100 justify-content-center justify-content-md-start'}>
                    {
                        categories.map((category) => (
                            <Col key={category?.id} xs={6} sm={6} md={4} lg={3}
                                 className={classnames('box-container', {'loading': category?.loading})}>
                                <div
                                    key={category.id}
                                    className={'box'}
                                    onClick={() => !category?.loading && navigateToCategory(category)}>
                                    <div className={'w-100 category-title-wrapper'}>
                                        <p className={'title'}>
                                            {category?.title ?? ''}
                                        </p>
                                    </div>
                                    <div className={'image'}>
                                        <LazyImage
                                            src={category?.coverFileName}
                                            alt={category?.coverFileName ?? ''}
                                            height={150}
                                        />
                                    </div>
                                </div>
                            </Col>
                        ))
                    }
                </div>
            </div>
        </>
    );
}


export default HomeViewCategories;
