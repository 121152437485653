import React, {useEffect} from "react";
import {
    apiMethods,
    ShoppingCartInjectedPropertyNames,
    ShoppingCartItemBoxTypes
} from "../../../../core/constants/enums";
import useIsMounted from "../../../hooks/use-is-mounted";
import {useDispatch} from "react-redux";
import axios from "axios";
import api from "../../../../core/services/api/api";
import {shoppingCartApis} from "../../../../core/constants/endpoints/endpoints";
import {confirmationDialog} from "../../../../redux/entities/dialogs/actions";
import {Col} from "reactstrap";
import {formatMoney} from "../../../../core/services/utils/utils";
import ShoppingCartInput from "../../base/input/cart-input";
import classnames from "classnames";
import {ReactComponent as RemoveIcon} from "../../../../assets/images/remove-part-icon.svg";
import LazyImage from "../lazy-lmage";
import {CircularProgress} from "@material-ui/core";
import useWindowViewportWidth from "../../../hooks/use-window/viewport-width";
import {routeFunctions} from "../../../routes";
import useRouter from "../../../hooks/use-router";

const ShoppingCartItemBox = ({
                                 item: itemProp,
                                 setLoading,
                                 setCart,
                                 cart,
                                 type = ShoppingCartItemBoxTypes.normal,
                                 disabled: disabledProps
                             }) => {
    const {history} = useRouter();
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    const viewportWidth = useWindowViewportWidth();

    const item = itemProp ?? {};
    const disabled =
        item[ShoppingCartInjectedPropertyNames.item.updating] ||
        item[ShoppingCartInjectedPropertyNames.item.removing] ||
        disabledProps;
    const lastItem = cart?.parts?.filter(e => !(e ?? {})[ShoppingCartInjectedPropertyNames.item.removing])?.length <= 1;
    const newQuantity = item[ShoppingCartInjectedPropertyNames.item.newQuantity] ?? item.quantity ?? 0;

    /**
     * Listens for the changes in newQuantity and with each change:
     * - sets a timer that at the end of which, calls the update product quantity api.
     */
    useEffect(() => {
        if (newQuantity === undefined || newQuantity === item.quantity) return;
        let axiosCancelToken;
        const timer = setTimeout(() => {
            if (!isMounted()) return;
            if (newQuantity === undefined || newQuantity === item.quantity || newQuantity === 0) return;
            axiosCancelToken = axios.CancelToken.source()
            updateProductQuantity(newQuantity, axiosCancelToken).then();
        }, 600)
        return () => {
            timer && clearTimeout(timer);
            axiosCancelToken && axiosCancelToken.cancel();
        };
    }, [newQuantity])

    /**
     * Updates the quantity of this particular product in the user shopping cart.
     * @param {number} newQuantity
     * @param {import("axios").CancelTokenSource} cancelToken
     * @return {Promise<void>}
     */
    const updateProductQuantity = async (newQuantity, cancelToken) => {
        setLoading(ShoppingCartInjectedPropertyNames.item.updating, true);
        const response = await api({
            url: shoppingCartApis.updateItems,
            method: apiMethods.put,
            data: {partCountChangedList: [{partNo: item.partNo, finalCount: newQuantity}]},
            extra: {cancelToken: cancelToken.token},
            showError: false,
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setLoading(ShoppingCartInjectedPropertyNames.item.updating, false);
            return;
        }
        if (response?.resultFlag) {
            setCart(response?.data);
        }
        setLoading(ShoppingCartInjectedPropertyNames.item.updating, false);
    }

    /**
     * Removes this item from the shopping cart of the user in the server.
     *
     * - if the api result was successful:
     *     * sets the shopping cart with its returned data.
     *     * removes the part from the list of delayed items as well.
     * @return {Promise<boolean>} True if the api was successful, false otherwise.
     */
    const removeProductFromShoppingCart = async () => {
        setLoading(ShoppingCartInjectedPropertyNames.item.removing, true);
        const response = await api({
            url: shoppingCartApis.updateItems,
            method: apiMethods.put,
            data: {partNumbersRemoveList: [item.partNo]}
        })
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setLoading(ShoppingCartInjectedPropertyNames.item.removing, false);
            return false;
        }
        if (response?.resultFlag) {
            // remove the part from the list of delayed items as well.
            setCart(prevState => ({
                ...prevState,
                [ShoppingCartInjectedPropertyNames.delayedItems]:
                    prevState[ShoppingCartInjectedPropertyNames.delayedItems]
                        ?.map(e => e.parts?.includes(item?.partNo)
                            ? {...e, parts: e.parts?.filter(e => e !== item?.partNo)}
                            : e
                        )
            }))
            setCart(response?.data);
        } else {
            setLoading(ShoppingCartInjectedPropertyNames.item.removing, false);
        }
        return response?.resultFlag ?? false;
    }

    /**
     * Changes the new quantity of this item.
     * @param {number} newQuantity
     */
    const changeNewQuantity = (newQuantity) => {
        if (!newQuantity) newQuantity = 0;
        setCart(prevState => ({
            ...prevState,
            parts: prevState.parts?.map(e => e.partNo === item.partNo
                ? {...e, [ShoppingCartInjectedPropertyNames.item.newQuantity]: newQuantity}
                : e
            ) ?? [],
        }))
    }

    /**
     * Calls the remove product method, but if the item is the last item left, then first gets the user
     * confirmation, since the removal of the last item will remove the entire cart.
     */
    const removeItem = () => {
        if (!lastItem) {
            return removeProductFromShoppingCart().then()
        }
        dispatch(confirmationDialog({
            open: true,
            title: "Remove Last Product",
            description: "This is the last item in your shopping cart. By removing this time, this cart will be" +
                " destroyed entirely. Do you want to proceed?",
            callback: (accepted) => {
                if (!accepted) return true;
                return removeProductFromShoppingCart();
            }
        }))
    }

    /**
     * Handles navigating user to part information from shopping cart items
     * @param {string} partNo
     */
    const onPartNumberClicked = (partNo) => {
        history.push(routeFunctions.main.single(partNo));
    }


    return (
        <>
            <div className={classnames('shopping-item-box', type, {
                'flex-wrap': ['md', 'xs'].includes(viewportWidth),
            })}>
                <div className={'d-flex align-items-start'}>
                    {
                        ((cart?.parts?.length ?? 0) > 1)
                        && <button className={'button text remove-button'}
                                   disabled={disabled}
                                   onClick={removeItem}>
                            {
                                !disabled
                                    ? <RemoveIcon/>
                                    : <CircularProgress size={20} color={'primary'}/>
                            }
                        </button>
                    }
                    <div className={'img'}>
                        <LazyImage
                            src={item.imageCover}
                            alt={item.partNo ?? '--'}
                            height={130}
                        />
                    </div>
                    <div className={'content'}>
                        <p className={'title'} onClick={() => onPartNumberClicked(item.partNo)}>
                            {item.partNo ?? '--'}
                        </p>
                        {
                            type === ShoppingCartItemBoxTypes.delayed &&
                            <p className={'delayed-item-quantity'}>
                                Ordered Quantity: {newQuantity ?? 0}
                            </p>
                        }
                        <div
                            className={'description'}
                            dangerouslySetInnerHTML={{__html: item.description ?? '<p></p>'}}
                        />
                    </div>
                </div>
                <div className={classnames('actions', {
                    'col-12 mt-3': ['md', 'xs'].includes(viewportWidth),
                })}>
                    {
                        type === ShoppingCartItemBoxTypes.normal &&
                        <Col xs={12}>
                            <div className={'price-container'}>
                                <p>
                                    {
                                        item.unitPrice !== undefined
                                            ? formatMoney(item.unitPrice)
                                            : '--'
                                    }
                                </p>
                            </div>
                            <ShoppingCartInput
                                disabled={disabled}
                                quantity={newQuantity === 0 ? null : newQuantity}
                                onChange={changeNewQuantity}
                                min={1}
                            />
                        </Col>
                    }
                </div>
            </div>
        </>
    );
}


export default ShoppingCartItemBox;
