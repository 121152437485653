import React, {useLayoutEffect, useMemo, useReducer, useRef} from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import DynamicContentEditor from "./components/base";
import DynamicContentEditorReducer from "../core/models/reducer";
import DynamicContentEditorController from "../core/models/controller";
import DynamicContentEditorControllerMiddleware from "./middlewares/controller";
import DynamicContentEditorCommunicatorMiddleware from "./middlewares/communicator";
import {
    DynamicContentEditorControllerContext,
    DynamicContentEditorDataContext,
    DynamicContentEditorStateContext
} from "./contexts";
import {DefaultDynamicContentEditorState} from "../index.d";
import DynamicContentEditorApi from "../core/services/api";
import {DynamicContentEditorModeContext} from "../index";

/**
 * @type {FC<DynamicContentEditorRouterProps>}
 */
const DynamicContentEditorRouter = ({
                                        children: routes,
                                        routeToPageMap,
                                        apiConfiguration,
                                        apiInterceptors,
                                        route,
                                    }) => {
    const reducer = useMemo(() => new DynamicContentEditorReducer(), []);
    const reducerFunc = useMemo(() => reducer.reducer, [])
    /**@type {Partial<DynamicContentEditorState>}*/
    const initializerArg = useMemo(() => {
        const apiConfig = {
            ...DefaultDynamicContentEditorState.apiConfiguration,
            ...(apiConfiguration ?? {}),
        };
        DynamicContentEditorApi.configuration = apiConfig;
        return ({
            ...DefaultDynamicContentEditorState,
            routeToPageMap: {
                ...DefaultDynamicContentEditorState.routeToPageMap,
                ...(routeToPageMap ?? {}),
            },
            apiConfiguration: apiConfig,
        })
    }, [])
    const [state, dispatch] = useReducer(reducerFunc, initializerArg, undefined);
    const controller = useMemo(() => new DynamicContentEditorController(state, dispatch), []);
    const preventEffect = useRef({
        routeToPageMapPropChanger: true,
    })

    /**
     * Determines if the current window object is the top level window, or it's a frame inside it.
     * @type {boolean}
     */
    const notInIframe = useMemo(() => window.top === window.self, []);

    /**
     * With each change in the routeToPageMap object of props:
     * - changes the routeToPageMap property of the state through the controller
     */
    useLayoutEffect(() => {
        if (preventEffect.current.routeToPageMapPropChanger) {
            return (preventEffect.current.routeToPageMapPropChanger = false) || void 0;
        }
        controller.setRouteToPageMap(routeToPageMap);
    }, [controller, routeToPageMap])

    /**
     * With each change in the [interceptors] value of the props:
     * - injects the interceptors to the api interface of this package.
     */
    useLayoutEffect(() => {
        DynamicContentEditorApi.injectInterceptors(apiInterceptors);
    }, [apiInterceptors])

    return (
        <>
            <BrowserRouter>
                <DynamicContentEditorControllerContext.Provider value={controller}>
                    <DynamicContentEditorStateContext.Provider value={state}>
                        <DynamicContentEditorDataContext.Provider value={state.data}>
                            <DynamicContentEditorModeContext.Provider value={state.mode}>
                                {/*Middlewares*/}
                                <DynamicContentEditorControllerMiddleware
                                    notInIframe={notInIframe}
                                    route={route}
                                />
                                <DynamicContentEditorCommunicatorMiddleware
                                    notInIframe={notInIframe}
                                />
                                {/*Routes*/}
                                <Switch>
                                    {
                                        notInIframe &&
                                        <Route path={route} exact>
                                            <DynamicContentEditor/>
                                        </Route>
                                    }
                                    {routes}
                                </Switch>
                            </DynamicContentEditorModeContext.Provider>
                        </DynamicContentEditorDataContext.Provider>
                    </DynamicContentEditorStateContext.Provider>
                </DynamicContentEditorControllerContext.Provider>
            </BrowserRouter>
        </>
    )
}


export default DynamicContentEditorRouter;
