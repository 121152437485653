import {FC, ForwardRefExoticComponent, PropsWithoutRef, RefAttributes} from "react";
import _DynamicContentEditorRouter from "./ui";
import _DynamicContentEditorEntry from "./ui/wrappers/entry";
import _DynamicContentEditorListEntry from "./ui/wrappers/list-entry";
import _DynamicContentEditorEntryRecord from "./ui/wrappers/entry-record";
import {DynamicContentEditorModeContext as _DynamicContentEditorModeContext} from "./ui/contexts";
import * as models from './index.d';

export * as DynamicContentEditorModels from './index.d';

export const DynamicContentEditorRouter = _DynamicContentEditorRouter as FC<models.DynamicContentEditorRouterProps>;
export const DynamicContentEditorEntry = _DynamicContentEditorEntry as FC<models.DynamicContentEditorEntryProps>;
export const DynamicContentEditorListEntry = _DynamicContentEditorListEntry as ForwardRefExoticComponent<PropsWithoutRef<models.DynamicContentEditorListEntryProps> & RefAttributes<unknown>>;
export const DynamicContentEditorEntryRecord = _DynamicContentEditorEntryRecord as ForwardRefExoticComponent<PropsWithoutRef<models.DynamicContentEditorEntryRecordProps> & RefAttributes<unknown>>;
export const DynamicContentEditorModeContext = _DynamicContentEditorModeContext;
