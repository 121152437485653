import React from "react";
import Form from "../../../base/form";
import {Col, Row} from "reactstrap";
import EmailInput from "../../../base/input/email-input";
import MuiCheckbox from "../../../base/check-box";
import * as Yup from "yup";
import ValidateMessages from "../../../../../core/constants/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {LandingPageFormTypes} from "../../../../../core/constants/enums";
import EnvService from "../../../../../core/services/env-service";

const formKeys = {
    email: 'email',
    partnerON: 'partnerON',
    partnerBC: 'partnerBC'
}

const schema = Yup.object().shape({
    [formKeys.email]: Yup.string().email().trim().nullable().required(ValidateMessages.required),
});


const ForgetPasswordLandingForm = ({onSubmit, changeLoginRegister}) => {
    const validate = makeValidate(schema);
    const required = makeRequired(schema);


    return (
        <Form
            className='register-form'
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{}}
            render={({values, form}) => {
                return (
                    <>
                        <Row>
                            <Col xs={12}>
                                <EmailInput
                                    setValidating={() => {
                                    }}
                                    form={true}
                                    label={"Email"}
                                    type={'email'}
                                    shouldValidateWithApiCall={false}
                                    name={formKeys.email}
                                    placeholder={'Email'}
                                    required={required[formKeys.email]}
                                />
                            </Col>
                            {
                                EnvService.ShowForgetPasswordCheckboxes &&
                                <Col xs={12} className={'form-check-label'}>
                                    <div className={'d-flx flex-column justify-content-start align-items-center'}>
                                        <MuiCheckbox
                                            form
                                            name={formKeys.partnerON}
                                            required={required[formKeys.partnerON]}
                                            onChange={(_, c) => {
                                                form.change(formKeys.partnerON, c);
                                                form.change(formKeys.partnerBC, !c);
                                            }}
                                            data={{
                                                value: values[formKeys.partnerON] ?? false,
                                                label: (
                                                    <p className={'label'}>
                                                        Cool ON
                                                    </p>
                                                )
                                            }}
                                        />
                                        <MuiCheckbox
                                            form
                                            name={formKeys.partnerBC}
                                            required={required[formKeys.partnerBC]}
                                            onChange={(_, c) => {
                                                form.change(formKeys.partnerBC, c);
                                                form.change(formKeys.partnerON, !c);
                                            }}
                                            data={{
                                                value: values[formKeys.partnerBC] ?? false,
                                                label: (
                                                    <p className={'label'}>
                                                        Cool BC
                                                    </p>
                                                )
                                            }}
                                        />
                                    </div>
                                </Col>
                            }
                            <Col xs={12}>
                                <div className="d-flex justify-content-center ">
                                    <button className={'button primary px-5 m-auto primary-action-button'}
                                            type={'submit'}>
                                        Reset password
                                    </button>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="d-flex justify-content-center mb-3">
                                    <button className={'button text primary px-5 m-auto primary-action-button'}
                                            onClick={() => changeLoginRegister(LandingPageFormTypes.login)}
                                    >
                                        back to login
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </>
                );
            }}
        />
    );
}

export default ForgetPasswordLandingForm;

