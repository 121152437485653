import React, {useEffect, useMemo, useState} from "react";
import routes from "../../../../../routes";
import Routes, {routeFunctions} from "../../../../../routes";
import {Col, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {formatMoney} from "../../../../../../core/services/utils/utils";
import {Tooltip} from "@material-ui/core";
import ShoppingCartInput from "../../../../../components/base/input/cart-input";
import {partApis, shoppingCartApis} from "../../../../../../core/constants/endpoints/endpoints";
import api from "../../../../../../core/services/api/api";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import {useDispatch} from "react-redux";
import {updateShoppingCartCount} from "../../../../../../redux/entities/shopping-cart/actions";
import {apiMethods, ProductsSearchTypes} from "../../../../../../core/constants/enums";
import useRouter from "../../../../../hooks/use-router";
import CacheService from "../../../../../../core/services/cache/cache-service";
import EnvService from "../../../../../../core/services/env-service";
import {ReactComponent as InformationIcon} from "../../../../../../assets/images/information-icon.svg";
import ToastService from "../../../../../../core/services/toast-service";
import ProductSearchUtils from "../../../../../../core/services/utils/product-search-utils";
import ApplicationIds from "../../../../../../core/constants/application-ids";
import TagsContainer from "../../../../../components/app-specific/tags-container";


const PartInformationViewContentSection = ({data, setData}) => {
    const {history, params, stringifyUrl} = useRouter();
    const [availabilityLoading, setAvailabilityLoading] = useState(false);
    const [quantity, setQuantity] = useState(0);
    const [addingToCart, setAddingToCart] = useState(false);
    const [availability, setAvailability] = useState({})
    const [isLoggedIn] = useState(CacheService.isLoggedIn())
    const isMounted = useIsMounted();
    const dispatch = useDispatch();

    const tags = useMemo(() => data?.tags?.map(e => ({
        id: e.id,
        name: e.name,
        iconFileName: e.iconInfo?.url,
    })), [])

    /**
     * Listens for the changes in id of the url params and isLoggedIn and with each change:
     * - only fetches the part availability of the users if the user is logged in or the id of params is changed.
     */
    useEffect(() => {
        if (!isLoggedIn || !params?.id) return;
        getPartAvailability().then();
    }, [params?.id, isLoggedIn])

    /**
     * Fetches the availability and pricing of this part from the server.
     * @return {Promise<void>}
     */
    const getPartAvailability = async () => {
        setAvailabilityLoading(true);
        const response = await api({
            url: partApis.getPartAvailability(params.id),
            method: apiMethods.get,
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setAvailabilityLoading(false);
            return;
        }
        setAvailability(response?.data ?? {})
        setAvailabilityLoading(false);
    }

    /**
     * Handles navigating user from part full information to shopping cart
     */
    const onViewShoppingCartClicked = () => {
        history.push(Routes.main.shoppingCart);
    }

    /**
     * Adds the specified quantity to the shopping cart of the user.
     * @return {Promise<void>}
     */
    const addToCart = async () => {
        if (!quantity)
            return;
        setAddingToCart(true);

        const response = await api({
            url: shoppingCartApis.addToCart,
            method: apiMethods.post,
            data: {
                partNo: data.partNo,
                addCount: quantity
            },
            showSuccess: false,
        })

        if (!isMounted())
            return;

        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setAddingToCart(false);
            return;
        }

        if (response?.resultFlag) {
            dispatch(updateShoppingCartCount(quantity))
            setQuantity(0);
            ToastService.success({
                title: "Products Added to Cart",
                message: `${quantity} product(s) added to shopping cart`,
                buttons: [
                    {
                        onClick: () => onViewShoppingCartClicked(),
                        children: "View Shopping Cart"
                    }
                ]
            })
        }

        setAddingToCart(false);
    }

    /**
     * Navigates the user to the registration view
     */
    const navigateToRegistrationView = () => {
        history.push(routes.auth.signUp);
    }

    /**
     * Focuses the username field in the header authentication form.
     * if the form is in reset password type, then navigates the user to login view.
     */
    const focusUsernameFieldInAuthForm = () => {
        const field = document.getElementById(ApplicationIds.headerLoginFormEmailInput);
        if (!field)
            return history.push(routes.auth.login);
        window.scrollTo(0, 0);
        field.focus();
    }

    /**
     * Handles clicking on the tag icon button:
     * Navigates user to the
     * @param tag
     */
    const onTagClicked = (tag) => {
        history.push(stringifyUrl({
                url: routes.main.products,
                query: {
                    type: ProductsSearchTypes.basic,
                    data: JSON.stringify({
                        [ProductsSearchTypes.basic]: {
                            [ProductSearchUtils.basicSearchQueryKeys.tags]: [{id: tag.id, name: tag.name}]
                        }
                    })
                },
            })
        )
    }

    return (
        <>
            <div className={'part-info-view-content-header'}>
                <div>
                    <h1>
                        {data.partNo}
                    </h1>
                    <div className="category">
                        <ul>
                            {data.firstChildCategory &&
                                <li>
                                    <Link to={routeFunctions.main.categories(data.firstChildCategory.id)}>
                                        {data.firstChildCategory?.title ?? ''}
                                    </Link>
                                </li>
                            }
                            {data.secondChildCategory &&
                                <li>
                                    <Link to={routeFunctions.main.categories(data.secondChildCategory.id)}>
                                        {data.secondChildCategory?.title ?? ''}
                                    </Link>
                                </li>
                            }
                        </ul>
                    </div>
                </div>
                {
                    isLoggedIn &&
                    <TagsContainer tags={tags} onTagClicked={onTagClicked}/>
                }
            </div>
            {
                isLoggedIn
                    ? (
                        <>
                            {
                                availabilityLoading &&
                                <></>
                            }
                            {
                                !availabilityLoading &&
                                <div className={'part-information-price'}>
                                    {
                                        availability?.userPrice != null
                                            ? parseFloat(availability?.userPrice) <= 0
                                                ? <h4 className='not-available'>
                                                    Call Office for Price
                                                </h4>
                                                : (availability?.userOriginalPrice)
                                                && parseFloat(availability?.userPrice ?? '0')
                                                < parseFloat(availability?.userOriginalPrice ?? '0')
                                                    ? <Col className={'d-flex flex-column align-items-start pl-0'}>
                                                        <h5 className='old-price'>
                                                            {formatMoney(availability?.userOriginalPrice ?? '100')}
                                                        </h5>
                                                        <h3 className='new-price'>
                                                            {formatMoney(availability?.userPrice ?? '0')}
                                                        </h3>
                                                    </Col>
                                                    : <h3 className=''>
                                                        {formatMoney(availability?.userPrice ?? '0')}
                                                    </h3>
                                            : <h4 className='not-available'>
                                                Price not Available
                                            </h4>
                                    }
                                </div>
                            }
                            {
                                EnvService.EnablePayments && (
                                    !!availability?.userPrice &&
                                    <div className={'cart-container'}>
                                        <Col xs={12} sm={6} md={6} className={'px-0'}>
                                            <div className={'d-flex flex-column align-items-center'}>
                                                <p className={'text-xl mb-2 black'}>
                                                    Order Qty
                                                </p>
                                                <ShoppingCartInput quantity={quantity} onChange={setQuantity}/>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} md={6}>
                                            <div className={'d-flex flex-column align-items-center mt-3 mt-sm-0'}>
                                                <p className={'text-xl mb-0 mb-sm-2 black'}>
                                                    Total
                                                </p>
                                                <p className={'total'}>
                                                    {formatMoney(availability?.userPrice * (quantity ?? 0))}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col xs={12} className={'d-flex justify-content-center mt-2 mt-sm-4'}>
                                            <button className={'button primary px-5 text-md'}
                                                    onClick={addToCart}
                                                    disabled={quantity < 1 || !!addingToCart}>
                                                {
                                                    addingToCart
                                                        ? 'submitting...'
                                                        : 'add to cart'
                                                }
                                            </button>
                                        </Col>
                                    </div>
                                )
                            }
                            <div className="warehouse">
                                {
                                    availabilityLoading &&
                                    <div className="loading extra-extra-small">
                                        <div/>
                                        <div/>
                                        <div/>
                                        <div/>
                                        <div/>
                                    </div>
                                }
                                <ul>
                                    {availability?.warehousesStatus?.map((item, index) => (
                                        <Tooltip
                                            arrow
                                            key={item?.warehouse ?? index}
                                            title={item.alt ?? ""}
                                            placement={'top'}>
                                            <li style={{color: item.color}}>
                                                <strong>
                                                    {item?.warehouse ?? ''}
                                                </strong>
                                                <div className={'availability'}>
                                                    {item?.availability ?? ''}
                                                    <InformationIcon style={{fill: item.color, height: 18, width: 18}}/>
                                                </div>
                                            </li>
                                        </Tooltip>
                                    ))}
                                </ul>

                            </div>
                        </>
                    )
                    : <>
                        <Row className='no-gutters align-items-start justify-content-center my-5'
                             style={{marginTop: '100px !important'}}>
                            <Col xs={12} className='mb-3 mt-5'>
                                <p className='text-center'>
                                    Please Login / Register to See This items' Availability and Pricing
                                </p>
                            </Col>
                            <Col xs={12}
                                 className='d-flex align-items-center justify-content-center'>
                                <button
                                    className='button primary px-5 mx-3'
                                    type={'button'}
                                    onClick={focusUsernameFieldInAuthForm}>
                                    Login
                                </button>
                                <button
                                    className='button primary outlined px-5 mx-3'
                                    type={'button'}
                                    onClick={navigateToRegistrationView}>
                                    Register
                                </button>
                            </Col>
                        </Row>
                    </>
            }
        </>
    );
}


export default PartInformationViewContentSection;
