import React, {useEffect, useState} from "react";
import api from "../../../../core/services/api/api";
import {userCardsApis} from "../../../../core/constants/endpoints/endpoints";
import {apiMethods, CreditCardTypes, UserPaymentsProcessSteps} from "../../../../core/constants/enums";
import useIsMounted from "../../../hooks/use-is-mounted";
import {Col, Row} from "reactstrap";
import UserPaymentsPaymentSection from "./sections/payment";
import UserPaymentsCardSelectionSection from "./sections/card-selection";
import UserPaymentsSuccessSection from "./sections/success";
import LoadingIndicator from "../loading-indicator";


const UserPayments = ({
                          pay,
                          payments,
                          payableAmount = 0,
                          disabled: disabledProp,
                          onStepChanged,
                          getCallToAction,
                          step: stepProp,
                          sendEmail,
                          navigateAway,
                          getSuccessTitle,
                          showPreAuthPercentageOnPayments,
                      }) => {
    const [step, setStep] = useState(stepProp);
    const [loading, setLoading] = useState(false);
    const [animating, setAnimating] = useState(false);
    const [fetchedOnce, setFetchedOnce] = useState(false);
    const [creditCards, setCreditCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const isMounted = useIsMounted();

    const disabled = disabledProp || animating

    /**
     * Listens for the changes in step and with each change:
     * - calls the onStepChange callback with the current step as its argument.
     * - fetches the list of user credit cards from the server if the step is not success or the data has not been
     * fetched before.
     */
    useEffect(() => {
        if (onStepChanged && stepProp !== UserPaymentsProcessSteps.success) onStepChanged(step);
        if (step === UserPaymentsProcessSteps.success) return;
        if (fetchedOnce) return;
        getCreditCards().then()
    }, [step])

    /**
     * Listens for the changes in stepProp and with each change:
     * - replaces the currentStep with the stepProp
     */
    useEffect(() => {
        if (stepProp) setStep(stepProp);
    }, [stepProp])

    /**
     * Fetches the list of user credit cards from the system.
     *
     * - if the result did not contain any default cards, then changes the step to card selection to make user
     * select their preferred card.
     * @return {Promise<void>}
     */
    const getCreditCards = async () => {
        setLoading(true);
        const response = await api({
            url: userCardsApis.getUserCreditCards,
            method: apiMethods.get,
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setLoading(false);
            return;
        }
        const cards = response?.data ?? []
        setCreditCards(cards)
        const defaultCard = cards?.find(e => e?.isDefault);
        if (!defaultCard) {
            setStep(UserPaymentsProcessSteps.cardSelection);
        } else {
            setSelectedCard(defaultCard);
        }
        setLoading(false);
        setFetchedOnce(true);
    }

    /**
     * Assigns the provided card as the selected card of the user.
     *
     * if the card is not among the list of credit cards, then it must have been created on the spot, so it adds the
     * cards.
     * @param {any} card selected credit card
     */
    const onCreditCardSelected = (card) => {
        if (step !== UserPaymentsProcessSteps.payment) {
            setStep(UserPaymentsProcessSteps.payment)
        }
        if (!!selectedCard?.id && (card?.id === selectedCard?.id))
            return;
        setSelectedCard({type: CreditCardTypes.unknown});
        setAnimating(true);
        setTimeout(() => {
            if (!isMounted()) return
            if (!creditCards?.find(e => e.id === card.id)) {
                setCreditCards(prevState => [...prevState, card])
            }
            setSelectedCard(card);
            setAnimating(false);
        }, 300)
    }

    /**
     * Renders the appropriate section based on the current step.
     * @return {JSX.Element}
     */
    const renderSection = () => {
        switch (step) {
            case UserPaymentsProcessSteps.payment:
            default:
                return <UserPaymentsPaymentSection
                    disabled={disabled}
                    amount={payableAmount}
                    payments={payments}
                    card={selectedCard}
                    setCard={onCreditCardSelected}
                    pay={pay}
                    setStep={setStep}
                    getCallToAction={getCallToAction}
                    showPreAuthPercentage={showPreAuthPercentageOnPayments}
                />;
            case UserPaymentsProcessSteps.cardSelection:
                return <UserPaymentsCardSelectionSection
                    cards={creditCards}
                    onSelect={onCreditCardSelected}
                    disabled={disabled}
                    getCards={getCreditCards}
                />;
            case UserPaymentsProcessSteps.success:
                return <UserPaymentsSuccessSection
                    getTitle={getSuccessTitle}
                    goBack={navigateAway}
                    sendEmail={sendEmail}
                />;
        }
    }

    return (
        <>
            <Row className={'user-payments'}>
                {
                    loading
                        ? <Col xs={12} className={'text-center py-100'}>
                            <LoadingIndicator/>
                        </Col>
                        : renderSection()
                }
            </Row>

        </>
    )
}

export default UserPayments;
