import React, {useContext, useMemo, useState} from "react";
import {DataGridEventHandlersContext, DataGridMiscContext} from "../../../../index";
import moment from "moment";
import DataGridUtils from "../../../../core/services/utils";
import classnames from "classnames";
import DataGridButton from "../../../components/button";
import {CircularProgress} from "@mui/material";
import {ReactComponent as CancelIcon} from "../../../../assets/images/body/cancel-editor.svg";
import {ReactComponent as SaveIcon} from "../../../../assets/images/body/save-editor.svg";

const DataGridCellEditor = ({initialValue, colName, rowKey, ContentComponent, close}) => {
    const [value, setValue] = useState(initialValue);
    const [canSubmitChanges, setCanSubmitChanges] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const {classNames} = useContext(DataGridMiscContext);
    const {onCellEdited} = useContext(DataGridEventHandlersContext);

    const valueChanged = useMemo(() => {
        if (moment.isMoment(initialValue) && moment.isMoment(value)) {
            return !initialValue.isSame(value);
        }
        return !DataGridUtils.deepEqual(initialValue, value)
    }, [initialValue, value]);

    /**
     * Submits the changes made in the cell editor.
     *
     * * if the value of the editor is not changed, just closes the popover
     * * if we can not submit the changes, we do nothing
     * * if the response from the invocation of [onCellEdited] callback is false, does nothing otherwise close the
     * popover.
     * @return {Promise<*>}
     */
    const submitChanges = async () => {
        if (!valueChanged || !onCellEdited) {
            return close();
        }
        if (!canSubmitChanges)
            return;

        setSubmitting(true);
        const resultFlag = await onCellEdited(initialValue, value, rowKey, colName);
        setSubmitting(false);

        if (resultFlag === false)
            return;
        close();
    }

    return (
        <>
            <div className={classnames(
                'data-grid-cell-editor',
                classNames.cellEditor,
            )}>
                {
                    React.cloneElement(
                        ContentComponent,
                        {
                            value: value,
                            setValue: setValue,
                            setCanSubmitChanges: setCanSubmitChanges,
                            submitChanges: submitChanges,
                        },
                        null
                    )
                }
                <div className={'data-grid-cell-editor-actions'}>
                    <DataGridButton
                        className={'cancel-action'}
                        disabled={submitting}
                        onClick={close}
                    >
                        {
                            submitting
                                ? <>
                                    <CircularProgress size={15} color={'secondary'}/>
                                    Cancel
                                </>
                                : <>
                                    <CancelIcon/>
                                    Cancel
                                </>
                        }
                    </DataGridButton>
                    <DataGridButton
                        className={'save-action'}
                        disabled={submitting}
                        onClick={submitChanges}
                    >
                        {
                            submitting
                                ? <>
                                    <CircularProgress size={15} color={'secondary'}/>
                                    Saving...
                                </>
                                : <>
                                    <SaveIcon/>
                                    Save
                                </>
                        }
                    </DataGridButton>
                </div>
            </div>
        </>
    );
}

export default DataGridCellEditor;
