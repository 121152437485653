import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import useRouter from "../../../../hooks/use-router";
import BlogSearchInput from "../../../../components/base/search-input/content-search-input";
import {ReactComponent as BackButton} from '../../../../../assets/images/blog-back-icon.svg'
import VideoCard from "../../../../components/app-specific/content-boxes/video-card";
import BlogCard from "../../../../components/app-specific/content-boxes/blog-card";
import useWindowViewportWidth from "../../../../hooks/use-window/viewport-width";
import InfiniteScroll from "react-infinite-scroller";
import {cmsBlogApis, cmsVideoApis} from "../../../../../core/constants/endpoints/cms_endpoints";
import api from "../../../../../core/services/api/api";
import {apiMethods} from "../../../../../core/constants/enums";
import useIsMounted from "../../../../hooks/use-is-mounted";
import FloatingContainer from "../../../../components/app-specific/floating-container";
import NoFoundSvg from "../../../../components/app-specific/no-results-found";

const initialPaginationInfo = {
    pageSize: 8,
    currentPage: 1,
    totalVideos: 9,
    totalBlogs: 9,
}

const ContentSearchResults = () => {
    const {query, history, stringifyUrl, location} = useRouter();
    const [loadingVideos, setLoadingVideos] = useState(true);
    const [loadingBlogs, setLoadingBlogs] = useState(true);
    const [videos, setVideos] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const windowViewPort = useWindowViewportWidth();
    const [paginationInfo, setPaginationInfo] = useState({...initialPaginationInfo, currentPage: 0});
    const isMounted = useIsMounted();

    /**
     * Listens for the changes in query and with each change:
     * - resets the blogs list and paginationInfo to trigger the search function
     */
    useEffect(() => {
        setPaginationInfo({...initialPaginationInfo, currentPage: 0});
        setLoadingBlogs(true);
        setLoadingVideos(true);
        setVideos([]);
        setBlogs([]);
    }, [query])


    /**
     * Listens for the changes for the currentPage of paginationInfo and with each change:
     * Loads the list of blogs and videos from the server.
     */
    useEffect(() => {
        if (paginationInfo.currentPage === 0) {
            return setPaginationInfo(initialPaginationInfo);
        }
        const timer = setTimeout(() => {
            search();
        }, 400);
        return () => clearTimeout(timer);
    }, [paginationInfo.currentPage])


    /**
     * Loads the search results based on the provided keywords.
     */
    const search = () => {
        if (paginationInfo.totalVideos > (paginationInfo.pageSize * (paginationInfo.currentPage - 1))) {
            searchVideos();
        }
        if (paginationInfo.totalBlogs > (paginationInfo.pageSize * (paginationInfo.currentPage - 1))) {
            searchBlogs();
        }
    }

    /**
     * Searches the videos of the cms based on the provided filters from the server and if the result of the api is
     * successful, sets the state.
     */
    const searchVideos = () => {
        setLoadingVideos(true);
        api({
            url: cmsVideoApis.search,
            method: apiMethods.post,
            loginRequired: false,
            data: {
                paginationInfo,
                filters: {
                    keyword: query?.keywords,
                    categoryIds: query?.categories,
                },
            },
        }).then((response) => {
            if (!isMounted()) return
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setLoadingVideos(false);
                return;
            }
            if (response?.resultFlag) {
                setVideos(prevState => {
                    const prevStateIds = prevState?.map(e => e.id) ?? [];
                    return [...prevState, ...response.data.resultList?.filter(e => !prevStateIds.includes(e.id))]
                });
                setPaginationInfo({
                    ...response.data.paginationInfo,
                    totalVideos: response.data.paginationInfo.totalCount
                });
            } else {
                setPaginationInfo(prevState => ({...prevState, totalVideos: 0}));
            }
            setLoadingVideos(false);
        });
    }

    /**
     * Searches the blogs of the cms based on the provided filters from the server and if the result of the api is
     * successful, sets the state.
     */
    const searchBlogs = () => {
        setLoadingBlogs(true);
        api({
            url: cmsBlogApis.search,
            method: apiMethods.post,
            loginRequired: false,
            data: {
                paginationInfo,
                filters: {
                    keyword: query?.keywords,
                    categoryIds: query?.categories,
                },
            },
        }).then((response) => {
            if (!isMounted()) return
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setLoadingBlogs(false);
                return;
            }
            if (response?.resultFlag) {
                setBlogs(prevState => {
                    const prevStateIds = prevState?.map(e => e.id) ?? [];
                    return [...prevState, ...response.data.resultList?.filter(e => !prevStateIds.includes(e.id))]
                });
                setPaginationInfo({
                    ...response.data.paginationInfo,
                    totalBlogs: response.data.paginationInfo.totalCount
                });
            } else {
                setPaginationInfo(prevState => ({...prevState, totalBlogs: 0}));
            }
            setLoadingBlogs(false);
        });
    }

    /**
     * Navigates the user to the search results view with the provided data.
     * @param data {string}
     */
    const onKeywordsChange = (data) => {
        history.push(stringifyUrl({url: location.pathname, query: {keywords: data}}));
    }

    /**
     * Increases the paginationInfo's current page to trigger the search function
     */
    const loadMore = () => {
        setPaginationInfo(prevState => ({...prevState, currentPage: prevState.currentPage + 1}));
    }

    return (
        <>
            <main className={'blogs-view'}>
                <Container>
                    <FloatingContainer setHeight>
                        <Row className={'mx-3 justify-content-around align-items-start'}>
                            <Col xs={12}
                                 className={'d-flex justify-content-start align-items-center'}>
                                <div onClick={history.goBack}>
                                    <BackButton className={'back-button'}/>
                                </div>
                                <h1 className={'header-title'}>
                                    Search for your Desired Content
                                </h1>
                            </Col>
                            <Col xs={12} className={'mt-3'}>
                                <BlogSearchInput
                                    initialValue={query?.keywords ?? ''}
                                    search={onKeywordsChange}
                                    className={'w-100'}
                                />
                            </Col>
                            <Col xs={12} className={'my-4 py-2'}/>
                            <Col xs={12} className={'d-flex justify-content-between align-items-center mb-3 px-3'}>
                                <h2 className={'section-title'}>
                                    Search Results
                                </h2>
                            </Col>
                            <Col xs={12} className={'my-3'}/>
                            {
                                !videos.length && !blogs.length && !loadingVideos && !loadingBlogs
                                    ? <NoFoundSvg/>
                                    : (
                                        <>
                                            <InfiniteScroll
                                                className='d-flex flex-wrap w-100 justify-content-around'
                                                pageStart={1}
                                                loadMore={loadMore}
                                                hasMore={!loadingVideos && !loadingBlogs
                                                    && (
                                                        (paginationInfo.totalBlogs > paginationInfo.pageSize * paginationInfo.currentPage)
                                                        || (paginationInfo.totalVideos > paginationInfo.pageSize * paginationInfo.currentPage)
                                                    )}
                                                useWindow
                                                threshhold={400}>
                                                {
                                                    !!videos?.length &&
                                                    <Col xs={12} lg={blogs.length ? 8 : 12}
                                                         className={'mb-5 d-flex flex-wrap h-100'}>
                                                        <div className={'mx-3 mb-3'}>
                                                            <h2 className={'section-title'}>
                                                                Videos
                                                            </h2>
                                                        </div>
                                                        <div className={'w-100 my-3 mt-4'}/>
                                                        {
                                                            videos.map(video => (
                                                                <VideoCard
                                                                    key={video.id}
                                                                    data={video}
                                                                    className={'mb-3'}
                                                                />
                                                            ))
                                                        }
                                                    </Col>
                                                }
                                                {
                                                    !!blogs?.length &&
                                                    <Col xs={12} lg={videos.length ? 4 : 12}
                                                         className={'mb-5 d-flex flex-wrap h-100'}>
                                                        <div className={'mx-3 mb-3'}>
                                                            <h2 className={'section-title'}>
                                                                Blogs
                                                            </h2>
                                                        </div>
                                                        <div className={'w-100 my-3 mt-4'}/>
                                                        {
                                                            blogs.map(blog => (
                                                                <Col key={blog.id} xs={12} sm={12}
                                                                     md={videos?.length ? 6 : 6}
                                                                     lg={videos?.length ? 12 : 4}
                                                                     className={'mb-3'}>
                                                                    <BlogCard
                                                                        secondVersion={(windowViewPort === 'lg' || windowViewPort === 'xl') && videos.length}
                                                                        blog={blog}
                                                                    />
                                                                </Col>
                                                            ))
                                                        }
                                                    </Col>
                                                }
                                            </InfiniteScroll>
                                            {
                                                (loadingVideos || loadingBlogs) && (
                                                    <>
                                                        <Col xs={12} lg={8} className={'mb-5 d-flex flex-wrap'}>
                                                            {loadingVideos && (
                                                                <>
                                                                    {
                                                                        !blogs.length && !videos.length &&
                                                                        <div className={'w-50 mx-3 mb-3'}>
                                                                            <div className={'loading extra-small'}>
                                                                                <div/>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                    <div className={'w-100'}/>
                                                                    {
                                                                        Array(6).fill(null).map((e, index) => (
                                                                            <Col xs={12} key={index} sm={6} md={6}
                                                                                 className={'mb-3'}>
                                                                                <div className={'loading video'}>
                                                                                    <div/>
                                                                                </div>
                                                                            </Col>

                                                                        ))
                                                                    }
                                                                </>
                                                            )}
                                                        </Col>
                                                        <Col xs={12} lg={4} className={'mb-5'}>
                                                            {
                                                                loadingBlogs && (
                                                                    <>
                                                                        {
                                                                            !blogs.length && !videos.length &&
                                                                            <div className={'w-50 mx-3 mb-3'}>
                                                                                <div className={'loading extra-small'}>
                                                                                    <div/>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            Array(3).fill(null).map((e, index) => (
                                                                                <div key={index}
                                                                                     className={'loading video mb-3 mx-3'}>
                                                                                    <div/>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </>
                                                                )
                                                            }

                                                        </Col>
                                                    </>
                                                )
                                            }
                                        </>
                                    )
                            }
                        </Row>
                    </FloatingContainer>
                </Container>
            </main>
        </>
    );
}


export default ContentSearchResults;
