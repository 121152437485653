import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane,} from "reactstrap";
import {useDispatch} from "react-redux";
import {setReduxHeaderHeight} from "../../../../redux/actions";
import StaticImage from '../../../../assets/images/static/compare.jpeg';
import useIsMounted from "../../../hooks/use-is-mounted";
import useRouter from "../../../hooks/use-router";
import {ReactComponent as BackButton} from "../../../../assets/images/blog-back-icon.svg";
import classnames from "classnames";
import CompareViewTableSection from "./content/table";
import {Fade} from "@material-ui/core";
import CompareViewPartsAutoComplete from "./components/autocomplete";
import api from "../../../../core/services/api/api";
import {partCompareApis} from "../../../../core/constants/endpoints/endpoints";
import {apiMethods} from "../../../../core/constants/enums";
import {numComparator} from "../../../../core/services/utils/utils";
import TryAgain from "../../../components/app-specific/try-again";
import CompareViewCategoryChips from "./components/category-chips";
import CompareViewImagesSection from "./content/images";
import CompareViewNotesSection from "./content/notes";

const tabs = {
    details: 'details',
    images: 'images',
    notes: 'notes',
};

const tabList = [
    {value: tabs.details, label: 'Detail Comparison'},
    {value: tabs.images, label: 'Image Comparison'},
    {value: tabs.notes, label: 'Notes Comparison'},
]

const CompareView = () => {
    const {history, query, location, stringifyUrl} = useRouter();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState([]);
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);
    const dispatch = useDispatch();
    const isMounted = useIsMounted();
    /**@type {React.MutableRefObject<HTMLElement>}*/
    const headerRef = useRef();

    const tab = useMemo(() => query?.tab ?? tabs.details, [query?.tab]);

    const selectedData = useMemo(() => data.find(e => e.category.id === selectedCategoryId), [selectedCategoryId, data])
    const properties = useMemo(() => selectedData?.properties ?? [], [selectedData])
    const parts = useMemo(() => selectedData?.parts ?? [], [selectedData])
    const allParts = useMemo(() => data?.map(e => e?.parts ?? [])?.reduce((p, c) => [...p, ...c], []), [data])

    /**
     * Before the paint of the component and with each change in headerRef.current:
     * - sets the height of the header and the marginTop of the banner to change their distance.
     */
    useLayoutEffect(() => {
        if (!headerRef.current) return;
        dispatch(setReduxHeaderHeight(headerRef.current.clientHeight - 50));
        const negativeTop = (headerRef.current.clientHeight - 100) * -1;
        headerRef.current.style.marginTop = negativeTop + 'px'
    }, [headerRef.current])

    /**
     * As soon as the component mounts:
     * - fetches the comparison lists items from the server.
     */
    useEffect(() => {
        getComparisonItems(true).then()
    }, [])

    /**
     * Changes the selected tab of the view in the query section of the url.
     * @param tab
     */
    const setTab = (tab) => {
        history.replace(stringifyUrl({
            url: location.pathname,
            query: {tab},
        }))
    }

    /**
     * Fetches the comparison items list from the server.
     */
    const getComparisonItems = async (initial = false) => {
        const response = await api({
            url: partCompareApis.get,
            method: apiMethods.get,
        })
        if (!isMounted())
            return;
        setLoading(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            const data = response?.data?.map(e => ({
                ...e,
                parts: e?.parts?.map(e => ({
                    ...e,
                    coverImageURL: response?.configuration?.PartImageURL?.concat(e?.coverImageURL ?? '') ?? '',
                    images: e?.images?.map(e => ({
                        ...e,
                        fileName: response?.configuration?.PartImageURL?.concat(e?.fileName ?? '') ?? ''
                    })),
                })) ?? [],
                properties: e?.properties?.sort((a, b) => numComparator(a.orderIndex, b.orderIndex)),
            })) ?? []
            setData(data);
            if (data?.length) {
                const notFound = !data?.find(e => e.category?.id === selectedCategoryId);
                if (initial || notFound) {
                    setSelectedCategoryId(data[0].category.id);
                }
            }
        }
    };

    /**
     * Removes the given category and all of its parts from the comparison list in the server.
     * @param {any} category
     * @return {Promise<void>}
     */
    const removeCategory = async (category) => {
        const response = await api({
            url: partCompareApis.removeCategory(category.id),
            method: apiMethods.delete,
        })
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            setData(prevState => {
                const newState = prevState.filter(e => e.category.id !== category.id)
                if (category.id === selectedCategoryId) {
                    setSelectedCategoryId(newState.length ? newState[0].category.id : null);
                }
                return newState;
            });
        }
    }

    /**
     * Removes the given part from the comparison list in the server.
     * @param {any} part
     * @return {Promise<void>}
     */
    const removePart = async (part) => {
        const response = await api({
            url: partCompareApis.removePart(part.id),
            method: apiMethods.delete,
        })
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response?.resultFlag) {
            setData(prevState => {
                const newData = prevState
                    ?.map(e => {
                        if (e.category.id !== selectedCategoryId)
                            return e;
                        const newParts = e.parts?.filter(e => e.id !== part.id) ?? [];
                        if (!newParts?.length) {
                            // remove the empty category
                            return null;
                        }
                        return {
                            ...e,
                            parts: newParts
                        }
                    })
                    ?.filter(e => e)
                const category = newData?.find(e => e.category.id === selectedCategoryId);
                if (!category && !!selectedCategoryId && newData?.length) {
                    setSelectedCategoryId(newData[0].category.id);
                }
                return newData;

            });

        }
    }

    /**
     * Handles selection of a category for showing the selected items:
     * - Sets the [selectedCategoryId] in the state with the received category
     *
     * @param {Object} category
     */
    const onCategorySelected = (category) => {
        if (selectedCategoryId === category.id)
            return;
        setSelectedCategoryId(category.id);
    }

    /**
     * Adds the given part to the appropriate category form list of categories of the state and also selects the
     * category that the part belongs to.
     */
    const onPartAdded = async (part) => {
        await getComparisonItems(false);
        setData(prevState => {
            const category = prevState?.find(e => e?.parts?.find(e => e.id === part.id))?.category;
            if (category)
                setSelectedCategoryId(category.id);
            return prevState;
        })
    }

    return (
        <>
            {/*Compare banner section*/}
            <section className={'view-image-header compare'} ref={headerRef}>
                <Container>
                    <Row>
                        <Col xs={12}>
                            <div className="box"
                                 style={{backgroundImage: `url(${StaticImage})`}}>
                                <h1>Compare</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/*Back button, categories, and search section*/}
            <main className={'compare-view'}>
                <Row className={'px-4'}>
                    <Col xs={12} className={'px-2'}>
                        <div
                            className={'compare-back-section'}
                            onClick={history.goBack}
                        >
                            <BackButton className={'back-button mb-1'}/>
                            <p>
                                Go Back
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} className={'px-4 mt-5'}>
                        <div className={'nav-tabs-container'}>
                            <Nav tabs className={'not-fixed-width align-items-center'}>
                                {
                                    tabList?.map(t => (
                                        <NavItem key={t.value}>
                                            <NavLink
                                                className={classnames({'active': t.value === tab})}
                                                onClick={() => setTab(t.value)}
                                            >
                                                {t.label}
                                            </NavLink>
                                        </NavItem>
                                    )) ?? []
                                }
                            </Nav>
                        </div>
                    </Col>
                    <Col xs={12} className={'px-5'}>
                        <CompareViewCategoryChips
                            categories={data}
                            removeCategory={removeCategory}
                            selectCategory={onCategorySelected}
                            selectedCategoryId={selectedCategoryId}
                        />
                    </Col>
                    <Col xs={12} className={'px-5'}>
                        <div className={'compare-part-addition-container'}>
                            <p className={'mb-2'}>
                                Add Part
                            </p>
                            <CompareViewPartsAutoComplete
                                onPartAdded={onPartAdded}
                                currentlySelectedParts={allParts}
                            />
                        </div>
                    </Col>
                </Row>
                {
                    loading
                        ? <>
                            {
                                <div className={'loading comparison-table'}>
                                    <div/>
                                </div>
                            }
                        </>
                        : !data?.length
                            ? <TryAgain
                                text={'Your comparison list is empty'}
                            />
                            :
                            <Fade in={!!selectedData}>
                                <span>
                                    <TabContent
                                        activeTab={tab}
                                        className={'mt-0'}
                                    >
                                    <TabPane tabId={tabs.details}>
                                        {
                                            tab === tabs.details &&
                                            <CompareViewTableSection
                                                selectedCategory={selectedData?.category}
                                                properties={properties}
                                                parts={parts}
                                                removePart={removePart}
                                            />
                                        }
                                    </TabPane>
                                    <TabPane tabId={tabs.images} className={'images-tab-pane'}>
                                        {
                                            tab === tabs.images &&
                                            <CompareViewImagesSection
                                                parts={parts}
                                                removePart={removePart}
                                            />
                                        }
                                    </TabPane>
                                    <TabPane tabId={tabs.notes}>
                                        {
                                            tab === tabs.notes &&
                                            <CompareViewNotesSection
                                                parts={parts}
                                                removePart={removePart}
                                            />
                                        }
                                    </TabPane>
                                </TabContent>
                                </span>
                            </Fade>
                }
            </main>
        </>

    );
}


export default CompareView;
