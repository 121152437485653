import EnvService from "../../services/env-service";

/**
 * The endpoints of the DCE package.
 */
class DynamicContentEditorApiEndpoints {
    static baseUrl = '';

    /**
     * the validate-token endpoint url.
     */
    static get validateToken() {
        return this.baseUrl + "/ValidateToken";
    }

    /**
     * the save-page-data endpoint url.
     */
    static get savePageData() {
        return this.baseUrl + "/Update";
    }

    /**
     * the upload-file endpoint url.
     */
    static get uploadFile() {
        return EnvService.uploadFileUrl;
    }
}

export default DynamicContentEditorApiEndpoints;
