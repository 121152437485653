import React, {useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {MultiInvoicePaymentProcessSteps, TransactionTypes} from "../../../../../core/constants/enums";
import {confirmationDialog} from "../../../../../redux/entities/dialogs/actions";
import {formatMoney} from "../../../../../core/services/utils/utils";
import classnames from "classnames";
import {ReactComponent as RemoveIcon} from "../../../../../assets/images/shopping-cart/remove.svg";
import MuiInput from "../../../../components/base/input/mui-input";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {Fade} from "@material-ui/core";

const MultiInvoicePaymentSummary = ({
                                        invoices,
                                        nextStep,
                                        editable,
                                        payments,
                                        credits,
                                        removeTransaction,
                                        sendEmail,
                                        step,
                                        total,
                                    }) => {
    const dispatch = useDispatch();


    /**
     * Shows the confirmation dialog to get user confirmation before removing the selected item.
     *
     * this method is also responsible for assigning the proper description and callback if the user confirmed the
     * operation.
     * @param {any} item the item to be removed
     * @param {string} type one of the properties of TransactionTypes
     */
    const onRemoveClicked = (item, type) => {
        const callback = (accepted) => {
            if (!accepted) return true;
            switch (type) {
                case TransactionTypes.creditMemo:
                case TransactionTypes.payment:
                    return removeTransaction(item, type)
                default:
                    return true;
            }
        };
        let description;
        switch (type) {
            case TransactionTypes.creditMemo:
            default:
                description = "Are you sure you want to remove this credit memo from your cart?"
                break;
            case TransactionTypes.payment:
                description = "Are you sure you want to refund this payment?"
                break;
        }
        dispatch(confirmationDialog({
            open: true,
            title: "Remove Confirmation",
            description: description,
            callback: callback,
            mandatory: false,
        }));
    }

    /**
     * Creates the entries of this bill info
     * it includes the subtotal, taxes and the list of transactions for this order.
     *
     * @return {JSX.Element[]}
     */
    const entries = useMemo(() => {
        const transactions = [
            ...credits?.map(e => ({
                ...e,
                transactionTypeName: TransactionTypes.creditMemo,
            })),
            ...payments
                ?.filter(e => e.extra?.success)
                ?.map(e => ({
                    ...e,
                    transactionTypeName: TransactionTypes.payment,
                })),
        ];
        const res = [];
        transactions?.forEach((transaction, index, array) => {
            const isCash = transaction.transactionTypeName === TransactionTypes.cashOrChequePayment;
            const typeName = isCash ? TransactionTypes.payment : transaction?.transactionTypeName;
            return res.push(
                <div className={classnames('transaction', typeName,
                    {
                        'py-2': isCash,
                        'first': index === 0 || typeName !== array[index - 1].transactionTypeName,
                        'last': (index === array.length - 1) || (typeName !== array[index + 1].transactionTypeName)
                    }
                )}
                     key={`transaction-${transaction?.id}`}>
                    <div className={'d-flex flex-grow-1 text-right'}>
                        <p>
                            {`${typeName ?? '--'}:`}
                        </p>
                    </div>
                    <div className={'d-flex flex-grow-1 align-items-center justify-content-between'}>
                        <p>
                            {`- ${formatMoney(transaction?.amount ?? 0)}`}
                        </p>
                        {
                            !isCash && editable &&
                            <button className={'button text icon red p-1'}
                                    disabled={transaction?.loading}
                                    onClick={() => onRemoveClicked(transaction, typeName)}>
                                <RemoveIcon/>
                            </button>
                        }
                    </div>
                </div>
            );
        });
        return res;
    }, [credits, payments, editable])

    return (
        <>
            <div className={'d-flex flex-column w-100 summary'}>
                <div className={'d-flex align-items-center justify-content-between'}>
                    <h5 className={'summary-title'}>
                        Summary
                    </h5>
                </div>
                <table className={'parts-table'}>
                    <thead>
                    <tr>
                        <td>
                            Invoice
                        </td>
                        <td>
                            Balance
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        invoices?.map((invoice, index) => (
                            <tr key={invoice.id} className={classnames({'white': index % 2 === 0})}>
                                <td>
                                    {invoice.orderNo ?? '--'}
                                </td>
                                <td>
                                    {
                                        invoice?.payInfo?.minimumRequiredPayment !== undefined
                                            ? formatMoney(invoice?.payInfo?.minimumRequiredPayment)
                                            : '--'
                                    }
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
                <div className={'summary-table'}>
                    {entries}
                </div>
                <div className={'total'}>
                    <p>
                        <span> Total: </span>
                        {formatMoney(total ?? 0)}
                    </p>
                </div>
                <MultiInvoicePaymentSummaryButton
                    nextStep={nextStep}
                    step={step}
                    sendEmail={sendEmail}
                />
            </div>
        </>
    )
}

const MultiInvoicePaymentSummaryButton = ({nextStep, sendEmail, step}) => {
    const userProfileEmail = useSelector(state => state?.profile?.email ?? 0);
    const [email, setEmail] = useState(userProfileEmail);
    const [goingToNextStep, setGoingToNextStep] = useState(false);
    const [expandMailInput, setExpandMailInput] = useState(false);
    const isMounted = useIsMounted();


    const shouldGoToNextStep = step === MultiInvoicePaymentProcessSteps.credits;
    const displayNothing = step === MultiInvoicePaymentProcessSteps.success;

    /**
     * Listens for the changes in the redux state email and with each change:
     * syncs the email state with it.
     */
    useEffect(() => {
        setEmail(userProfileEmail ?? '')
    }, [userProfileEmail])


    /**
     * Sends an email to the user containing the current information of this shopping cart.
     *
     * if the result of the api call is successful, collapses the email input.
     * @return {Promise<void>}
     */
    const sendEmailToUser = async () => {
        if (!email?.length) {
            return setExpandMailInput(false);
        }
        setGoingToNextStep(true);
        const resultFlag = await sendEmail(email);
        if (!isMounted()) return
        if (resultFlag) {
            setExpandMailInput(false);
        }
        setGoingToNextStep(false);
    }

    /**
     * Based on the current step of this cart:
     *
     * - if should-check-out, the proceeds to check out the cart.
     * - else if mail is not expanded, expands the mail input
     * - if mail is expanded, sends the mail to the recipient.
     * @return {Promise<void>}
     */
    const onClick = async () => {
        if (shouldGoToNextStep) {
            setGoingToNextStep(true);
            await nextStep();
            if (!isMounted()) return
            return setGoingToNextStep(false)
        }
        if (!expandMailInput) {
            return setExpandMailInput(true);
        }
        await sendEmailToUser()
    }

    return (
        <>
            <Fade in={!displayNothing} className={'w-100 d-flex flex-wrap'}>
                <div>
                    <div className={classnames('email', {"hidden": shouldGoToNextStep || !expandMailInput})}>
                        <p className={'title'}>
                            Email Address
                        </p>
                        <MuiInput
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder={'your email address'}
                            fullWidth
                            disabled={goingToNextStep}
                        />
                    </div>
                    <button onClick={onClick}
                            disabled={goingToNextStep}
                            className={classnames('mt-3 w-100 button primary', {
                                'outlined py-2': !shouldGoToNextStep,
                                'py-3': shouldGoToNextStep,
                            })}>
                        {
                            goingToNextStep
                                ? "submitting..."
                                : shouldGoToNextStep
                                    ? "Continue to payment"
                                    : "Receive email receipt"
                        }

                    </button>
                </div>
            </Fade>
        </>
    );
}

export default MultiInvoicePaymentSummary;
