/**
 * The Static Texts used in this package.
 */
class Texts {


    // ########################### HTTP SPECIFIC API RESPONSE ###################################

    /**
     * When the given request is incomplete or wrong in the context of the server call
     */
    static readonly badRequest: string = "There seems to be a problem with your request. please contact technical support";
    /**
     * When the user should log in to access the data from the server.
     */
    static readonly unauthorized: string = 'Please Login / Signup to use this feature.';
    /**
     * When the user does not have access to the requested data.
     */
    static readonly forbiddenAccess: string = 'You do not have access to the requested information. please contact technical support.';
    /**
     * When server was not found to communicate with
     */
    static readonly notFound: string = 'The requested endpoint does not exist. please contact technical support.';
    /**
     * When server instance for the api call crashed or was terminated before any response could be given
     */
    static readonly serverError: string = 'Can not communicate with the servers properly, please try again later.';

    // ########################### CUSTOM API RESPONSE ###################################

    /**
     * When the server call failed due to a problem in request preparation.
     */
    static readonly requestFailed: string = "There seems to be a problem with your request. please contact technical support.";
    /**
     * When the server call successfully was made but response was never returned.
     */
    static readonly serverNotResponded: string = 'Can not communicate with the servers properly, please try again later.';


}

export default Texts;
