import React from "react";
import {matchPath, NavLink as RRNavLink} from 'react-router-dom';
import {Nav, NavItem, NavLink} from "reactstrap";
import routes, {DCEEntryNames, routeFunctions} from "../../../routes";
import classnames from "classnames";
import {
    DynamicContentEditorEntry,
    DynamicContentEditorEntryRecord,
    DynamicContentEditorModels
} from "../../../../packages/dynamic-content-editor/index";

const initializeStableState = {
    home: "Home",
    about: "about Us",
    orders: "Order History",
    catalogue: "Catalogue",
    categories: "Categories",
    blogs: "Blogs",
    entry: "Entry",
    survey: "Survey"
};

const HeaderNavigationButtons = ({loggedIn = true, className = ''}) => {

    return (
        <>
            <DynamicContentEditorEntry
                isRelativePositioned
                iconPosition={DynamicContentEditorModels.DynamicContentEditorEntryIconPositions.outward}
                dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.fixed}
                _title={"Header Navigation"}
                entryName={DCEEntryNames.headerNavbar}
                tag={Nav}
                navbar
                className={classnames(className, 'header-navbar')}
            >
                {
                    loggedIn &&
                    <NavItem>
                        <NavLink
                            exact
                            tag={RRNavLink}
                            activeClassName="active"
                            to={routes.landing}
                        >
                            <DynamicContentEditorEntryRecord
                                _title={'Landing Route Name'}
                                recordKey={'home'}
                                tag={'span'}
                                placeholder={initializeStableState.home}
                            />
                        </NavLink>
                    </NavItem>
                }
                {
                    !loggedIn &&
                    <NavItem>
                        <NavLink
                            exact
                            tag={RRNavLink}
                            activeClassName="active"
                            isActive={(match, location) =>
                                matchPath(location.pathname, {
                                    path: routes.auth.base,
                                    exact: false,
                                })
                            }
                            to={routes.auth.login}
                        >
                            <DynamicContentEditorEntryRecord
                                recordKey={'entry'}
                                _title={'Login Route Name'}
                                tag={'span'}
                                placeholder={initializeStableState.entry}
                            />
                        </NavLink>
                    </NavItem>
                }
                {
                    loggedIn &&
                    <NavItem>
                        <NavLink
                            tag={RRNavLink}
                            activeClassName="active"
                            to={routeFunctions.main.categories()}
                        >
                            <DynamicContentEditorEntryRecord
                                recordKey={'categories'}
                                _title={'Categories Route Name'}
                                tag={'span'}
                                placeholder={initializeStableState.categories}
                            />
                        </NavLink>
                    </NavItem>
                }
                {
                    loggedIn &&
                    <NavItem>
                        <NavLink
                            tag={RRNavLink}
                            activeClassName="active"
                            to={routes.dashboard.orders}
                        >
                            <DynamicContentEditorEntryRecord
                                recordKey={'orders'}
                                _title={'Orders Route Name'}
                                tag={'span'}
                                placeholder={initializeStableState.orders}
                            />
                        </NavLink>
                    </NavItem>
                }
                <NavItem>
                    <NavLink
                        tag={RRNavLink}
                        activeClassName="active"
                        to={routes.public.catalogue}
                    >
                        <DynamicContentEditorEntryRecord
                            recordKey={'catalogue'}
                            _title={'Catalogue Route Name'}
                            tag={'span'}
                            placeholder={initializeStableState.catalogue}
                        />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        tag={RRNavLink}
                        activeClassName="active"
                        to={routes.public.about}
                    >
                        <DynamicContentEditorEntryRecord
                            recordKey={'about'}
                            _title={'About Us Route Name'}
                            tag={'span'}
                            placeholder={initializeStableState.about}
                        />
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        tag={RRNavLink}
                        activeClassName="active"
                        isActive={(match, location) =>
                            matchPath(location.pathname, {
                                path: routes.public.content.base,
                                exact: false,
                            })
                        }
                        to={routes.public.content.main}
                    >
                        <DynamicContentEditorEntryRecord
                            recordKey={'blogs'}
                            _title={'Blogs Route Name'}
                            tag={'span'}
                            placeholder={initializeStableState.blogs}
                        />
                    </NavLink>
                </NavItem>
                {
                    loggedIn &&
                    <NavItem>
                        <NavLink
                            tag={RRNavLink}
                            activeClassName="active"
                            to={routes.main.survey}
                        >
                            <DynamicContentEditorEntryRecord
                                recordKey={'survey'}
                                _title={'Survey Route Name'}
                                tag={'span'}
                                placeholder={initializeStableState.survey}
                            />
                        </NavLink>
                    </NavItem>
                }
            </DynamicContentEditorEntry>
        </>
    )
}

export default HeaderNavigationButtons;
