import profileReduxSlice from "./reducer";

const actions = profileReduxSlice.actions;

/**
 * Updates the profile information of the user in the redux store.
 *
 * @param data
 */
export const updateReduxProfileInformation = (data) => (dispatch) => {
    dispatch(actions.updateProfile(data))
};
