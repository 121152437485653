import React, {useState} from "react";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import EditShoppingCartAddressDialogEditAddressSection from "./sections/edit-address";
import EditShoppingCartAddressDialogSelectAddress from "./sections/select-address";
import {IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";

const EditShoppingCartAddressDialog = ({
                                           open,
                                           address,
                                           setOpen,
                                           type,
                                           onSuccess,
                                           countries,
                                           provinces,
                                           setProvinces
                                       }) => {
    const [tab, setTab] = useState(1);

    /**
     * Passes the newly updated selected address to the onSuccess callback and closes the dialog.
     * @param {any} cart the updated cart information after the update of the address.
     */
    const onAddressUpdated = (cart) => {
        onSuccess(cart);
        setOpen(false)
    }


    return (
        <>
            <Modal size={tab === 1 ? 'md' : 'lg'}
                   isOpen={open}
                   backdrop centered
                   className={'update-shopping-cart-address-dialog'}
                   toggle={() => setOpen(false)}>
                <ModalHeader>
                    <div className={'d-flex flex-row justify-content-between align-items-center'}>
                        Edit {type.title} Address
                        <div>
                            <IconButton aria-label={"cancel"} onClick={() => setOpen(false)}>
                                <Close/>
                            </IconButton>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <Nav tabs className={'mb-0 p-0 nav-dialog'}>
                        <NavItem>
                            <NavLink
                                className={classnames({'active': tab === 1})}
                                onClick={() => setTab(1)}>
                                Edit Address
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({'active': tab === 2})}
                                onClick={() => setTab(2)}>
                                Select Address
                            </NavLink>
                        </NavItem>
                        <TabContent activeTab={tab} className={''}>
                            <TabPane tabId={1}>
                                <EditShoppingCartAddressDialogEditAddressSection
                                    address={address}
                                    countries={countries}
                                    provinces={provinces}
                                    setProvinces={setProvinces}
                                    type={type}
                                    onSuccess={onAddressUpdated}
                                />
                            </TabPane>
                            <TabPane tabId={2}>
                                <EditShoppingCartAddressDialogSelectAddress
                                    type={type}
                                    onSuccess={onAddressUpdated}
                                />
                            </TabPane>
                        </TabContent>
                    </Nav>
                </ModalBody>
            </Modal>
        </>
    )
}


export default EditShoppingCartAddressDialog;
