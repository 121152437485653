import React from "react";
import {ReactComponent as CheckIcon} from "../../../../assets/images/check.svg";
import {ReactComponent as ExclamationIcon} from "../../../../assets/images/exclamation.svg";
import {formatMoney} from "../../../../core/services/utils/utils";
import moment from "moment";
import {CreditCardTypeNames} from "../../../../core/constants/enums";

const PaymentBox = ({payment, onSelect}) => {

    return (
        <>
            <div className={'payment-box'} onClick={() => onSelect(payment)}>
                <div>
                    {
                        payment?.extra?.success
                            ? <div className={'success'}>
                                <CheckIcon/> successful payment
                            </div>
                            : <div className={'un-success'}>
                                <ExclamationIcon/> unsuccessful payment
                            </div>
                    }
                    <p className={'amount'}>
                        {formatMoney(payment?.amount ?? 0)}
                    </p>
                </div>
                <div className={'mt-3'}>
                    <p>
                        <span className={'font-weight-600 text-capitalize ml-0'}>
                        {payment?.extra?.card?.type ? CreditCardTypeNames[payment.extra.card.type] ?? 'unknown' : 'unknown'}
                        </span>
                        ending in
                        <span>
                            {payment?.extra?.card?.lastFourDigits ?? '--'}
                        </span>
                    </p>
                    <p>
                        {payment?.submittedDate ? moment(payment?.submittedDate).format('dddd MMMM yyyy') : '--'}
                    </p>
                </div>
                <div>
                    <p>
                        Auth Code
                        <span>
                            {payment?.authorizationCode ?? '--'}
                        </span>
                    </p>
                    <p>
                        {payment?.submittedDate ? moment(payment?.submittedDate).format('hh:mm:ss A') : '--'}
                    </p>
                </div>
            </div>
        </>
    );
}

export default PaymentBox;
