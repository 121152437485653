import axios from 'axios';
import store from '../../../redux/store';
import {accessDeniedDialog, unAuthorizedDialog} from '../../../redux/actions';
import publicIp from 'react-public-ip';
import {apiMethods} from "../../constants/enums";
import LocalStorageService from "../cache/local-storage-service";
import ApiResponseMessages from "../../constants/api-response-messages";
import {getOS} from "../utils/utils";
import {Api} from "./api";
import EnvService from "../env-service";
import CacheService from "../cache/cache-service";
import ApiResponseCodes from "../../constants/api-response-codes";
import BroadcastService from "../broadcast-service";

/**
 * The axios instance that is used for api calls.
 * @type {import("axios").AxiosInstance}
 * @private
 */
const _axiosInstance = axios.create({
    baseURL: EnvService.ApiUrl + '/api/',
});

/**
 * Manages the api calls of the application.
 *
 * The functionality is similar to the functionality of the api.js with the difference that it is solely used for
 * old api structures.
 *
 * @param {any} requests the list of objects that each will be turned into a separate api call.
 * @return {Promise<any>}
 */
export const controller = async (requests) => {
    const output = {};
    let ipv4 = LocalStorageService.get(LocalStorageService.keys.Ip4);
    if (!ipv4) {
        ipv4 = await publicIp.v4() ?? "";
        LocalStorageService.set(LocalStorageService.keys.Ip4, ipv4)
    }
    for (const key in requests) {
        const request = _prepareRequestData(requests[key], key, ipv4);
        if (requests[key]?.loginRequired && !CacheService.isLoggedIn()) {
            const broadcastResultFlag = BroadcastService.postMessage(BroadcastService.channelNames.userTokenExpired, Api.location ?? {});
            if (!broadcastResultFlag) {
                Api.onRequestPreemptedDueToAuthentication(Api.history, Api.location);
            }
            output[key] = _responseErrorCallback(undefined, ApiResponseCodes.apiAbortedDueToAuth);
            continue;
        }
        if (!request)
            continue;
        try {
            const res = await _axiosInstance.request({
                url: request.url,
                method: _determineApiMethod(request.action),
                data: request.params,
                headers: request.headers,
            })
            output[key] = _responseCallback(res)
        } catch (e) {
            output[key] = _responseErrorCallback(e)
        }
    }
    return output
};

/**
 * Converts the string of apiMethod to the enum like property that can be used in axios request.
 * @param {string} method
 * @return {string}
 * @private
 */
const _determineApiMethod = (method) => {
    switch (method) {
        case "get":
        case apiMethods.get:
            return apiMethods.get;
        case "put":
        case apiMethods.put:
            return apiMethods.put;
        case "delete":
        case apiMethods.delete:
            return apiMethods.delete;
        case 'post':
        case apiMethods.post:
        default:
            return apiMethods.post;
    }
}

/**
 * Prepares the request properties that is required for the api call.
 * @param {any} request the request itself to be prepared.
 * @param {string} key the name of the request
 * @param {string} ipv4 ipv4 of the user
 * @return {null|any} the request itself or null when the request should not be proceeded.
 * @private
 */
const _prepareRequestData = (request, key, ipv4) => {
    request.params = {
        ...(request.params ?? {}),
        OS: getOS(),
        IPv4: ipv4,
    }
    request['loginRequired'] = request['loginRequired'] ?? true;
    request.action = request.action ?? apiMethods.post;
    const authToken = LocalStorageService.get(LocalStorageService.keys.token);
    request.headers = Api.getApiHeaders(request.headers ?? {})
    if (key === 'login') { // not used anymore
        request.headers = {
            ...request.headers,
            PartnerId: EnvService.DemoPartnerId,
        }
    }
    if (key === 'logout' && typeof authToken !== "string") {
        return null;
    }
    if (typeof authToken === "string") request.params.Token = authToken;
    return request
}

/**
 * Handles the api responses logic.
 *
 * The functionality is similar to the functionality of the api.js with the difference that it is solely used for
 * old api structures.
 *
 * @param {import("axios").AxiosResponse<any>} res the crud response
 * @return {any} the response object that is given to it.
 * @private
 */
const _responseCallback = (res) => {
    if (res.data.Code === -8 || res.data?.errorCode === -50) {
        // TODO: fix this
        store.dispatch(unAuthorizedDialog({
            open: true,
            message: res.data.message ?? res.data?.Message
        }));
        LocalStorageService.remove(LocalStorageService.keys.Ip4)
    } else if (res.data?.errorCode === -40) {
        store.dispatch(accessDeniedDialog({
            open: true,
            message: res.data?.message ?? res.data?.Message
        }));
    }
    const response = res.data;
    if (typeof res.data.Code === "undefined") response.Code = -10;
    if (typeof res.data.Message === "undefined") response.Message = "";
    if (typeof res.data.List === "undefined") response.List = [];
    return response
}

/**
 * Returns a default error response if the api fails.
 * @param {any} error the error received.
 * @param {any} code the error received.
 * @return {{Message: string, List: *[], Code: number}}
 * @private
 */
const _responseErrorCallback = (error, code = -10) => {
    return {
        isPreemptedDueToNotBeingLoggedIn: code === ApiResponseCodes.apiAbortedDueToAuth,
        Code: code,
        Message: ApiResponseMessages.serverErrorMessage,
        List: []
    };
}
