import React from "react";
import {Col} from "reactstrap";


const TryAgain = ({text = 'no search results found!'}) => {
    return (
        <Col className=' pt-5 pb-5 justify-content-center align-items-center'>
            <p className='text-center mb-2'>{text}</p>
        </Col>
    );
}

export default TryAgain;
