import React from "react";
import {Checkboxes} from "mui-rff";
import {ReactComponent as CheckedIcon} from "../../../../assets/images/red-checkbox/red-check-box-filled.svg";
import {ReactComponent as Icon} from "../../../../assets/images/red-checkbox/red-check-box-empty.svg";
import {
    ReactComponent as WhiteCheckedFilled
} from "../../../../assets/images/checkbox/white-checkbox-outlined-filled.svg";
import {ReactComponent as WhiteCheckedEmpty} from "../../../../assets/images/checkbox/white-checkbox-outlined.svg";
import classnames from "classnames";
import {Checkbox, FormControlLabel} from "@material-ui/core";

/**
 * @param {boolean} form
 * @param {import("mui-rff").CheckboxData} data
 * @param {string} className
 * @param {string} labelClassName only for non-form checkboxes
 * @param {import("mui-rff").CheckboxesProps & import("@material-ui/core").CheckboxProps} props
 * @param {boolean} isInHeaderLogin
 */
const MuiCheckbox = ({form = false, data, className, isInHeaderLogin, labelClassName, ...props}) => {

    return (
        <>
            {
                form
                    ? <Checkboxes
                        className={classnames(`checkbox`, className)}
                        icon={
                            isInHeaderLogin
                                ? <WhiteCheckedEmpty/>
                                : <Icon/>
                        }
                        checkedIcon={
                            isInHeaderLogin
                                ? <WhiteCheckedFilled/>
                                : <CheckedIcon/>
                        }
                        data={data}
                        {...props}
                    />
                    : <FormControlLabel
                        label={data.label}
                        disabled={data.disabled}
                        checked={data.value ?? false}
                        className={labelClassName}
                        control={
                            <Checkbox
                                icon={<Icon/>}
                                checkedIcon={<CheckedIcon/>}
                                className={classnames('checkbox', className)}
                                disabled={data.disabled}
                                checked={data.value ?? false}
                                {...props}
                            />
                        }
                    />
            }
        </>
    );
}

export default MuiCheckbox;
