import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import classnames from "classnames";
import HomeViewProducts from "../products";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {Container} from "reactstrap";


const HomeViewTimeBased = ({data}) => {
    return (
        <>
            <div className={'home-view-time-based'}>
                <div className={classnames('time-based-back-ground-container-upper')}>
                    <Container>
                        <Timer endDate={data?.content?.finishDateTime}/>
                        <HomeViewProducts data={data}/>
                    </Container>
                </div>
            </div>
        </>
    );
}

const Timer = ({endDate}) => {
    const [timeLeft, setTimeLeft] = useState(
        {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        });
    const isMounted = useIsMounted();

    /**
     * With each change in the end-date:
     * - sets the value of the time left with the newly given end-date.
     */
    useLayoutEffect(() => {
        setTimeLeft(calculateTimeLeft());
    }, [endDate])

    /**
     * With each render of the component, sets the remaining time in the state after calculation
     */
    useEffect(() => {
        const timer = setTimeout(() => {
            if (!isMounted())
                return;
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    }, [timeLeft]);

    /**
     * Calculates the remaining time of the deal based on the given end time from the props
     * @return {{days:number, hours:number, minutes:number,seconds:number}}
     */
    const calculateTimeLeft = () => {
        let timeDifference = (new Date(endDate).getTime()) - (new Date().getTime());

        const days = Math.max(Math.floor(timeDifference / (1000 * 60 * 60 * 24)), 0);
        timeDifference -= (days * 1000 * 60 * 60 * 24);
        const hours = Math.max(Math.floor((timeDifference / (1000 * 60 * 60))), 0);
        timeDifference -= (hours * 1000 * 60 * 60);
        const minutes = Math.max(Math.floor((timeDifference / (1000 * 60))), 0);
        timeDifference -= (minutes * 1000 * 60);
        const seconds = Math.max(Math.floor((timeDifference / 1000)), 0);

        return {
            days: Number.isNaN(Number(days)) ? 0 : days,
            hours: Number.isNaN(Number(hours)) ? 0 : hours,
            minutes: Number.isNaN(Number(minutes)) ? 0 : minutes,
            seconds: Number.isNaN(Number(seconds)) ? 0 : seconds,
        };
    }


    const renderedTimeLeft = useMemo(() => {
        const days = Math.max(timeLeft.days, 0);
        const hours = Math.max(timeLeft.hours, 0);
        const minutes = Math.max(timeLeft.minutes, 0);
        const seconds = Math.max(timeLeft.seconds, 0);
        return {
            days: days > 9 ? days : `0${days}`,
            hours: hours > 9 ? hours : `0${hours}`,
            minutes: minutes > 9 ? minutes : `0${minutes}`,
            seconds: seconds > 9 ? seconds : `0${seconds}`,
        }
    }, [timeLeft])

    return (
        <>
            <p className={'time-based-title'}>
                Deal Ends In
            </p>
            <div className={'time-based-timer'}>
                <div className={'timer-unit'}>
                    <p className={'mb-0'}>
                        {renderedTimeLeft.days}
                    </p>
                    <p className={'timer-unit-text'}>
                        {
                            renderedTimeLeft.days === 1
                                ? "Day"
                                : "Days"
                        }
                    </p>
                </div>
                <SeparatorIcon/>
                <div className={'timer-unit'}>
                    <p className={'mb-0'}>
                        {renderedTimeLeft.hours}
                    </p>
                    <p className={'timer-unit-text'}>
                        {
                            renderedTimeLeft.hours === 1
                                ? "Hour"
                                : "Hours"
                        }
                    </p>
                </div>
                <SeparatorIcon/>
                <div className={'timer-unit'}>
                    <p className={'mb-0'}>
                        {renderedTimeLeft.minutes}
                    </p>
                    <p className={'timer-unit-text'}>
                        {
                            renderedTimeLeft.minutes === 1
                                ? "Minute"
                                : "Minutes"
                        }
                    </p>
                </div>
                <SeparatorIcon/>
                <div className={'timer-unit'}>
                    <p className={'mb-0'}>
                        {renderedTimeLeft.seconds}
                    </p>
                    <p className={'timer-unit-text'}>
                        {
                            timeLeft.seconds === 1
                                ? "Second"
                                : "Seconds"
                        }
                    </p>
                </div>
            </div>
        </>
    );
}

const SeparatorIcon = () => {
    return (
        <div className={'timer-separator'}>
            <div/>
            <div/>
        </div>
    );

}

export default HomeViewTimeBased
