import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {
    DynamicContentEditorEntryDataTypes,
    DynamicContentEditorEntryIconPositions,
    DynamicContentEditorEntryRecordTypes
} from "../../../index.d";
import DynamicContentEditorUtils from "../../../core/services/utils";
import {DynamicContentEditorControllerContext, DynamicContentEditorEntryWrapperContext} from "../../contexts";
import useDynamicContentEditorEntryDataFetcher from "../../hooks/use-entry-fetcher";
import {ReactComponent as EditIcon} from "../../../assets/images/edit.svg";
import DynamicContentEditorApi from "../../../core/services/api";
import useIsMounted from "../../hooks/use-is-mounted";
import DynamicContentEditorUpsertEntryDialog from "../../components/dialogs/upsert-entry-dialog";
import classnames from "classnames";

const initUpsertEntryDialog = {open: false, records: []};

/**
 * @type {FC<DynamicContentEditorEntryProps>}
 */
const DynamicContentEditorEntry = ({
                                       children,
                                       dataType = DynamicContentEditorEntryDataTypes.pageBased,
                                       tag = 'div',
                                       isAbsolutePositioned = false,
                                       isRelativePositioned = false,
                                       entryName,
                                       id,
                                       _title: __title,
                                       getData,
                                       iconPosition = DynamicContentEditorEntryIconPositions.inward,
                                       ...props
                                   }) => {
    const controller = useContext(DynamicContentEditorControllerContext);
    const [data, page, inEditMode] = useDynamicContentEditorEntryDataFetcher(entryName, dataType);
    const currentPage = useRef(page);
    const [upsertEntryDialog, setUpsertEntryDialog] = useState(initUpsertEntryDialog);
    const idRef = useRef(id ?? DynamicContentEditorUtils.createUUId(true))
    const isMounted = useIsMounted();

    const contentProps = useMemo(() => ({
        ...(props ?? {}),
        ...(isAbsolutePositioned
                ? {
                    style: {
                        ...props?.style ?? {},
                        position: 'absolute',
                    },
                }
                : {}
        )
    }), [isAbsolutePositioned, props])

    const upsertDialogTitle = useMemo(() => {
        let _title;
        if (!__title)
            _title = ''
        else
            _title = __title.endsWith('s') ? `${__title}'` : `${__title}'s`
        return `Update ${_title} Section`;
    }, [__title])

    /**
     * With each change in the data of this entry:
     * - if [getData] callback does not exist, does nothing
     * - else, creates an object of the entries and calls the callback so the using application can access the data
     * used in this component.
     */
    useEffect(() => {
        if (!getData)
            return;
        const objectifiedData = Object.fromEntries(data?.map(e => [e.key, e.value]));
        getData(objectifiedData)
    }, [data])

    /**
     * With each change in the [inEditMode] flag and [page] value:
     * - if not in edit mode and the upsert entry dialog is open, closes the dialog.
     * - if page is different from the current page, then closes the dialog.
     */
    useEffect(() => {
        if (!inEditMode && upsertEntryDialog.open) {
            setUpsertEntryDialog(initUpsertEntryDialog);
        }
        if (currentPage.current !== page && upsertEntryDialog.open) {
            setUpsertEntryDialog(initUpsertEntryDialog);
        }
    }, [inEditMode, page])

    /**
     * Updates the records of the current entry in the server.
     *
     * * if the response of the api is successful, then updates the entry in the state of the DCE as well.
     * @param {DynamicContentEditorPageEntryData} records
     * @return {Promise<boolean>}
     */
    const updateEntry = async (records) => {
        const recordsCopy = Array.from(records);
        const res = [];
        for (const entry of (data ?? [])) {
            const recordEntryIndex = recordsCopy.findIndex(r => entry.key === r.key);
            if (recordEntryIndex === -1) {
                res.push(entry);
                continue;
            }
            const [record] = recordsCopy.splice(recordEntryIndex, 1);
            res.push({
                ...entry,
                ...record,
            })
        }
        for (const record of recordsCopy) {
            res.push(record);
        }
        const forApi = {
            page: dataType === DynamicContentEditorEntryDataTypes.fixed ? undefined : page,
            entry: {
                name: entryName,
                isFixed: dataType === DynamicContentEditorEntryDataTypes.fixed,
                data: res,
            }
        };
        const response = await DynamicContentEditorApi.savePageEntry(forApi);
        if (!isMounted())
            return false;
        if (response?.resultFlag) {
            controller.setPageEntryInfo(forApi.entry, forApi.page);
        }
        return response?.resultFlag ?? false;
    }

    /**
     * Opens the edit records dialog of this entry.
     *
     * * first fetches all the records o this entry then opens the dialog with these records so the dialog content
     * can be created based on these records.
     */
    const openUpsertEntryDialog = () => {
        const recordElements = document.getElementById(idRef.current)?.querySelectorAll('[data-dce-record-key]');
        const records = [];
        for (const recordElement of recordElements) {
            if (recordElement.dataset.dceRecordType === DynamicContentEditorEntryRecordTypes.unEditable)
                continue;
            records.push({
                key: recordElement.dataset.dceRecordKey,
                value: recordElement.dataset.dceRecordValue,
                title: recordElement.dataset.dceRecordTitle,
                type: recordElement.dataset.dceRecordValueType,
            })
        }
        setUpsertEntryDialog({open: true, records: records})
    }

    /**@type {JSX.Element}*/
    const contentInner = useMemo(() => {
        const _children = typeof children === "function"
            ? children(inEditMode)
            : children;
        let content = (
            <>
                {
                    inEditMode &&
                    <button
                        className={classnames('dynamic-content-editor-icon-button edit', iconPosition)}
                        onClick={openUpsertEntryDialog}
                    >
                        <EditIcon/>
                    </button>
                }
                {_children}
            </>
        )
        if (!isRelativePositioned) {
            content = (
                <div className={'dynamic-content-editor-entry-container'}>
                    {content}
                </div>
            )
        }
        return content;
    }, [children, isRelativePositioned, iconPosition, inEditMode]);

    /**@type {JSX.Element}*/
    const content = useMemo(() => {
        const Tag = tag;
        return (
            <Tag
                {...contentProps}
                id={idRef.current}
            >
                {contentInner}
            </Tag>
        );
    }, [contentInner, idRef, tag, contentProps])

    return (
        <>
            <DynamicContentEditorEntryWrapperContext.Provider value={data}>
                {content}
            </DynamicContentEditorEntryWrapperContext.Provider>
            <DynamicContentEditorUpsertEntryDialog
                open={upsertEntryDialog.open}
                records={upsertEntryDialog.records}
                close={() => setUpsertEntryDialog(initUpsertEntryDialog)}
                onSuccess={updateEntry}
                title={upsertDialogTitle}
            />
        </>
    );
}

export default DynamicContentEditorEntry;
