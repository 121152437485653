import {useCallback, useEffect, useRef} from 'react'


type UseIsMountedCallback = () => boolean;

/**
 * Hook for the mounted status of a component.
 *
 * @param {string?}     debugLabel      the label used for debugging
 * @param {boolean?}    log             whether this hook should log any changes in the mounted state.
 * @return {UseIsMountedCallback}       a function that its return value determines if the component is mounted or not.
 */
const useIsMounted = (debugLabel?: string, log?: boolean): UseIsMountedCallback => {
    const isMounted = useRef(false)

    /**
     * As soon as the component mounts, sets the isMounted to true
     * As soon as the component un-mounts, sets the isMounted to false
     */
    useEffect(() => {
        if (log)
            console.log('mounting', debugLabel)
        isMounted.current = true
        return () => {
            if (log)
                console.log('un-mounting', debugLabel)
            isMounted.current = false
        }
    }, [])

    /**
     * A function that its return value determines if the component is mounted or not.
     */
    return useCallback(() => isMounted.current, []);
}

export default useIsMounted;
