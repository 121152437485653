import {BroadcastChannel, EventContext, OnMessageHandler} from 'broadcast-channel';

/**
 * The service that handles all the static broadcast channel functionalities.
 */
class BroadcastService {
    /**
     * The names of the available channels of this service.
     */
    static readonly channelNames = {
        userTokenExpired: "user-token-expired",
    }

    /**
     * Posts a singular message to the given channel name with the given args.
     *
     * @param {keyof BroadcastService.channelNames} channelName
     * @param {any} args
     */
    static postMessage(channelName: string, args: any): boolean {
        try {
            const bc = new BroadcastChannel(channelName);
            bc.postMessage(args).then(() => {
                bc.close().then();
            });
            return true;
        } catch (e) {
            return false;
        }
    }


    /**
     * Adds an event listener for the given channel name and returns its cleanup function.
     *
     * @param {string} channelName
     * @param {EventContext} type
     * @param {OnMessageHandler<any>} listener
     */
    static addEventListener(channelName: string, type: EventContext, listener: OnMessageHandler<any>): { resultFlag: boolean, close: Function } {
        try {
            const bc = new BroadcastChannel(channelName);
            bc.addEventListener(type, listener);
            return {
                resultFlag: true,
                close: () => bc.close()
            };
        } catch (e) {
            return {
                resultFlag: false,
                close: () => false,
            };
        }
    }
}

export default BroadcastService;

