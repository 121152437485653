import React, {forwardRef, useContext, useEffect, useMemo} from "react";
import {
    DynamicContentEditorEntryDataTypes,
    DynamicContentEditorEntryIconPositions,
    DynamicContentEditorListEntryItemIdentifierKey
} from "../../../index.d";
import DynamicContentEditorUtils from "../../../core/services/utils";
import useDynamicContentEditorEntryDataFetcher from "../../hooks/use-entry-fetcher";
import DynamicContentEditorListEntryItem from "../list-entry-item";
import DynamicContentEditorApi from "../../../core/services/api";
import useIsMounted from "../../hooks/use-is-mounted";
import {DynamicContentEditorControllerContext} from "../../contexts";

const templateKey = 'template';

/**
 * @type {ForwardRefExoticComponent<PropsWithoutRef<DynamicContentEditorListEntryProps> & RefAttributes<unknown>>}
 */
const DynamicContentEditorListEntry = forwardRef(({
                                                      children,
                                                      childTag,
                                                      childProps,
                                                      dataType = DynamicContentEditorEntryDataTypes.pageBased,
                                                      tag = 'div',
                                                      isAbsolutePositioned = false,
                                                      isChildRelativePositioned = false,
                                                      includeIdentifier = false,
                                                      entryName,
                                                      getData,
                                                      _title,
                                                      iconPosition = DynamicContentEditorEntryIconPositions.inward,
                                                      ...props
                                                  }, ref) => {
    const controller = useContext(DynamicContentEditorControllerContext);
    const [_data, page, inEditMode] = useDynamicContentEditorEntryDataFetcher(entryName, dataType);
    const isMounted = useIsMounted();

    /**
     * Creates the item id's data record for the provided item value.
     *
     * @param {DynamicContentEditorPageEntryData} values
     * @return {[{value: string, key: string}]}
     */
    const imputeItemIdDataRecord = (values) => {
        const itemIdValue = values?.find(e => e.key === DynamicContentEditorListEntryItemIdentifierKey)?.value ?? DynamicContentEditorUtils.createUUId();
        return includeIdentifier
            ? [{key: DynamicContentEditorListEntryItemIdentifierKey, value: itemIdValue}]
            : []
    }

    /**@type {DynamicContentEditorPageEntryData}*/
    const data = useMemo(() =>
            _data
                ?.sort((a, b) => DynamicContentEditorUtils.numComparator(Number(a.key), Number(b.key)))
                ?.map(dataRecord => ({
                    ...dataRecord,
                    value: [
                        ...(dataRecord.value?.filter(e => e.key !== DynamicContentEditorListEntryItemIdentifierKey) ?? []),
                        ...(imputeItemIdDataRecord(dataRecord.value)),
                    ]
                }))
            ?? [],
        [_data, includeIdentifier])

    /**@type {Array<Record<string, any>>}*/
    const objectifiedDataList = useMemo(() => {
        return data?.map(dataRecord => Object.fromEntries(dataRecord.value?.map(e => [e.key, e.value]) ?? [])) ?? [];
    }, [data])

    /**
     * With each change in the data of this entry:
     * - if [getData] callback does not exist, does nothing
     * - else, creates an objectified list of all data item entries and calls the callback with this list so the
     * using application can access the data used in this component.
     */
    useEffect(() => {
        if (!getData)
            return;
        getData(objectifiedDataList)
    }, [objectifiedDataList])

    const contentProps = useMemo(() => ({
        ...(props ?? {}),
        ...(isAbsolutePositioned
                ? {
                    style: {
                        ...props?.style ?? {},
                        position: 'absolute',
                    },
                }
                : {}
        )
    }), [isAbsolutePositioned, props])

    /**
     * Adds the new item to the items of the current list entry in the server.
     *
     * * if the response of the api is successful, then adds the item in the state of the DCE as well.
     * @param {DynamicContentEditorPageEntryData} records
     * @return {Promise<boolean>}
     */
    const addListEntryItem = async (records) => {
        const forApi = {
            page: dataType === DynamicContentEditorEntryDataTypes.fixed ? undefined : page,
            entry: {
                name: entryName,
                isFixed: dataType === DynamicContentEditorEntryDataTypes.fixed,
                data: [
                    {
                        key: '0',
                        value: [
                            ...records,
                            ...imputeItemIdDataRecord(records),
                        ],
                    },
                    ...(data?.map((e, i) => ({...e, key: `${i + 1}`})) ?? []),
                ],
            }
        };
        const response = await DynamicContentEditorApi.savePageEntry(forApi);
        if (!isMounted())
            return false;
        if (response?.resultFlag) {
            controller.setPageEntryInfo(forApi.entry, forApi.page);
        }
        return response?.resultFlag ?? false;
    }

    /**
     * Updates the records of item in the items of the current list entry in the server.
     *
     * * if the response of the api is successful, then updates the item in the state of the DCE as well.
     * @param {string} key
     * @param {DynamicContentEditorPageEntryData} records
     * @return {Promise<boolean>}
     */
    const updateListEntryItem = async (key, records) => {
        const recordsCopy = Array.from(records);
        const res = [];
        for (const item of (data ?? [])) {
            if (item.key !== key) {
                res.push(item);
                continue;
            }
            const values = [];
            for (const entry of (item.value ?? [])) {
                const recordEntryIndex = recordsCopy.findIndex(r => entry.key === r.key);
                if (recordEntryIndex === -1) {
                    values.push(entry);
                    continue;
                }
                const [record] = recordsCopy.splice(recordEntryIndex, 1);
                values.push({
                    ...entry,
                    ...record,
                })
            }
            for (const record of recordsCopy) {
                values.push(record);
            }
            res.push({
                ...item,
                value: values,
            })
        }
        const forApi = {
            page: dataType === DynamicContentEditorEntryDataTypes.fixed ? undefined : page,
            entry: {
                name: entryName,
                isFixed: dataType === DynamicContentEditorEntryDataTypes.fixed,
                data: res,
            }
        };
        const response = await DynamicContentEditorApi.savePageEntry(forApi);
        if (!isMounted())
            return false;
        if (response?.resultFlag) {
            controller.setPageEntryInfo(forApi.entry, forApi.page);
        }
        return response?.resultFlag;
    }

    /**
     * Removes the item associated with the given key in the items of the current list entry in the server.
     *
     * * if the response of the api is successful, then remove the item in the state of the DCE as well.
     * @param {string} key
     * @return {Promise<boolean>}
     */
    const removeListEntryItem = async (key) => {
        const forApi = {
            page: dataType === DynamicContentEditorEntryDataTypes.fixed ? undefined : page,
            entry: {
                name: entryName,
                isFixed: dataType === DynamicContentEditorEntryDataTypes.fixed,
                data: data
                        ?.filter(e => e.key !== key)
                        ?.map((e, i) => ({...e, key: `${i}`}))
                    ?? [],
            }
        };
        const response = await DynamicContentEditorApi.savePageEntry(forApi);
        if (!isMounted())
            return false;
        if (response?.resultFlag) {
            controller.setPageEntryInfo(forApi.entry, forApi.page);
        }
        return response?.resultFlag;
    }

    /**
     * Upserts the records of the key associated with the given entry item.
     *
     * * if key is [templateKey] then adds a new item to the list.
     * * else updates the item in the list.
     * @param {string} key
     * @param {DynamicContentEditorPageEntryData} records
     * @return {Promise<boolean>}
     */
    const upsertListEntryItem = async (key, records) => {
        if (key === templateKey) {
            return await addListEntryItem(records);
        }
        return await updateListEntryItem(key, records);
    }

    /**@type {Array<JSX.Element>}*/
    const items = useMemo(() => {
        const items = data
                ?.map((dataRecord, i) => {
                    const itemChildProps = (typeof childProps === "function" ? childProps(objectifiedDataList[i], i, objectifiedDataList) : childProps) ?? {};
                    return <DynamicContentEditorListEntryItem
                        key={dataRecord.key}
                        tag={childTag}
                        data={dataRecord}
                        page={page}
                        inEditMode={inEditMode}
                        isRelativePositioned={isChildRelativePositioned}
                        upsert={(records) => upsertListEntryItem(dataRecord.key, records)}
                        remove={() => removeListEntryItem(dataRecord.key)}
                        parentTitle={_title}
                        iconPosition={iconPosition}
                        {...itemChildProps}
                    >
                        {children}
                    </DynamicContentEditorListEntryItem>
                })
            ?? []
        if (inEditMode) {
            const tempChildProps = (typeof childProps === "function" ? childProps() : childProps) ?? {}
            items.unshift(
                <DynamicContentEditorListEntryItem
                    template
                    key={templateKey}
                    tag={childTag}
                    inEditMode={inEditMode}
                    page={page}
                    isRelativePositioned={isChildRelativePositioned}
                    upsert={(records) => upsertListEntryItem(templateKey, records)}
                    parentTitle={_title}
                    iconPosition={iconPosition}
                    {...tempChildProps}
                >
                    {children}
                </DynamicContentEditorListEntryItem>
            )
        }
        return items;
    }, [children, data, childTag, childProps, inEditMode, page, isChildRelativePositioned, _title, objectifiedDataList, iconPosition])

    /**@type {JSX.Element}*/
    const content = useMemo(() => {
        const Tag = tag;
        return (
            <Tag ref={ref} {...(contentProps ?? {})}>
                {items}
            </Tag>
        );
    }, [contentProps, tag, items, ref])

    return (
        <>
            {content}
        </>
    );
});


export default DynamicContentEditorListEntry;
