import React, {useLayoutEffect, useRef} from "react";
import * as d3 from "d3";
import {createUUId} from "../../../../core/services/utils/utils";
import Colors from "../../../../assets/js/colors";


const enterClockwise = {
    startAngle: 0,
    endAngle: 0
};

const enterAntiClockwise = {
    startAngle: Math.PI * 2,
    endAngle: Math.PI * 2,
};


const initData = {arcs: []};

const DonutChart = ({
                        id,
                        data: dataset,
                        size = 300,
                        duration = 900,
                        children,
                    }) => {
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const ref = useRef();
    const idRef = useRef(id ?? `_${createUUId()}`);
    const svgIdRef = useRef(`_${createUUId()}`);
    const data = useRef(initData);

    const radius = size / 2;
    const inner = radius / 1.7;
    const outerStart = radius / 1.5;
    const increment = (radius - outerStart) / ((dataset.numbers?.length ?? 0) + 1);
    const colors = d3.scaleOrdinal().range(dataset?.colors ?? []);

    /**
     * Listens for the changes in dataset, size and duration with each change:
     * - removes the old chart
     * - renders the new chart.
     */
    useLayoutEffect(() => {
        removeChart();
        if (!dataset?.numbers?.length) return
        createChart();
    }, [dataset, size, duration]);

    /**
     * Removes the currently displaying chart if it exists.
     */
    const removeChart = () => {
        if (!ref.current) return;
        ref.current.innerHTML = '';
    }

    /**
     * Creates the tween animation for the arcs of this chart.
     * @param {any} angle
     * @param {number} index
     * @return {function(*): *}
     */
    function arcTween(angle, index) {
        const interpolate = d3.interpolate(this._current, angle);
        this._current = interpolate(0);
        return (t) => data.current.arcs[index](interpolate(t));
    }

    /**
     * Creates the tween animation for the arcs of this chart.
     * @param {any} angle
     * @param {number} index
     * @return {function(*): *}
     */
    function arcTweenOut(angle, index) {
        const interpolate = d3.interpolate(this._current, {...angle, ...enterAntiClockwise, value: 0});
        this._current = interpolate(0);
        return (t) => data.current.arcs[index](interpolate(t));
    }


    /**
     * Creates the Donut chart.
     *
     * recreates the pie, and svg elements and assigns them their attributes. Then fetches all the paths in the svg
     * and for each of them sets their attributes, and finally invokes paths' removing then entering animation to
     * make them appear.
     */
    const createChart = () => {
        let onlyOne = false;
        if (!dataset?.numbers?.filter(e => e)?.length) {
            data.current.arcs = [
                d3.arc().innerRadius(inner).outerRadius(outerStart + increment)
            ]
            onlyOne = true;
        } else {
            data.current.arcs = dataset.numbers?.map((_, index) =>
                d3.arc().innerRadius(inner).outerRadius(outerStart + ((index + 1) * increment))
            )
        }
        const pie = d3.pie().sort(null);
        const svg = d3.select(`#${idRef.current}`)
            .append('svg')
            .attr('id', svgIdRef.current)
            .attr("width", size)
            .attr("height", size)
            .attr('viewBox', (-size / 2) + ' ' + (-size / 2) + ' ' + size + ' ' + size)
            .attr('preserveAspectRatio', 'xMinYMin')
        const paths = svg.selectAll("path")
            .data(pie(onlyOne ? [1] : dataset.numbers))
            .enter()
            .append("path")
            .attr("fill", (d, i) => onlyOne ? Colors.grey1 : colors(i))
            .attr("d", data.current.arcs[data.current.arcs.length - 1](enterClockwise))
            .each(function (d) {
                this._current = {
                    data: d.data,
                    value: d.value,
                    startAngle: enterClockwise.startAngle,
                    endAngle: enterClockwise.endAngle
                };
            });
        paths.exit().transition().duration(duration).attrTween('d', arcTweenOut).remove()
        paths.transition().duration(duration).attrTween("d", arcTween); // redraw the arcs
    }

    return (
        <>
            <div className={'donut-chart'}
                 style={{
                     '--size': `${size}px`,
                     '--inner-size': `${radius * 0.85}px`,
                 }}>
                <div
                    ref={ref}
                    id={idRef.current}
                />
                <div className={'center'}>
                    {children}
                </div>
            </div>
        </>
    );
}

export default DonutChart;
