import React from "react";
import classnames from "classnames";
import {ReactComponent as RemoveIcon} from "../../../../../../assets/images/comparison/remove-property.svg";
import {confirmationDialog} from "../../../../../../redux/entities/dialogs/actions";
import {useDispatch} from "react-redux";

const CompareViewCategoryChips = ({
                                      categories,
                                      selectedCategoryId,
                                      selectCategory,
                                      removeCategory,
                                  }) => {
    const dispatch = useDispatch();

    /**
     * Handles removing a category from the list of items in compare screen:
     * - Shows a confirmation dialog from for user
     * - after confirmation, calls the api regarding this action
     *
     * @param {MouseEvent<HTMLButtonElement>} e
     * @param {Object}  category
     */
    const getRemoveCategoryConfirmation = (e, category) => {
        e.stopPropagation()
        e.preventDefault()
        dispatch(confirmationDialog({
            open: true,
            title: "Remove Category",
            description: "By removing this category, all of its parts will be removed from the comparison list. Do" +
                " you want to proceed?",
            callback: (confirmed) => {
                if (confirmed) removeCategory(category).then();
                return true;
            },
            mandatory: false,
        }))
    }

    return (
        <>
            <div className={'category-chips-list'}>
                {
                    categories.map(({category}) => (
                        <div
                            key={category?.id}
                            className={classnames(
                                'category-chips',
                                {'selected': category.id === selectedCategoryId}
                            )}
                            onClick={() => selectCategory(category)}
                        >
                            <p className={'mb-0'}>{category?.title ?? ""}</p>
                            <button
                                className={'remove-icon'}
                                onClick={(e) => getRemoveCategoryConfirmation(e, category)}
                            >
                                <RemoveIcon/>
                            </button>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

export default CompareViewCategoryChips;
