import React, {useLayoutEffect, useMemo, useRef, useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DynamicContentEditorUtils from "../../../../core/services/utils";
import {DynamicContentEditorEntryRecordValueTypes} from "../../../../index.d";
import DynamicContentEditorImageDropzone from "../../dropzone/image-dropzone";
import DynamicContentEditorCkEditor from "../../ck-editor";
import useIsMounted from "../../../hooks/use-is-mounted";
import classnames from "classnames";
import moment from "moment";
import DynamicContentEditorPdfDropzone from "../../dropzone/pdf-dropzone";

const DynamicContentEditorUpsertEntryDialog = ({
                                                   open,
                                                   close,
                                                   records,
                                                   onSuccess,
                                                   title,
                                               }) => {
    const formId = useRef(DynamicContentEditorUtils.createUUId(true));
    const [submitting, setSubmitting] = useState(false);
    const [uploading, setUploading] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [data, setData] = useState([]);
    const isMounted = useIsMounted();

    const disabled = useMemo(() => uploading.some(e => !!e), [uploading])

    /**
     * With each change in the open state of the dialog:
     * - if the dialog is closed, resets the [initialData] and [data] properties of the state.
     * - if the dialog is opened, sets the [initialData] and [data] properties of the state with the provided [records] prop
     */
    useLayoutEffect(() => {
        if (!open) {
            setInitialData([]);
            setData([]);
            return;
        }
        const initialData = [];
        for (const record of records) {
            initialData.push(record.value ?? '')
        }
        setInitialData(initialData);
        setData(initialData);
        setUploading(Array(initialData.length).fill(false))
    }, [open])

    /**
     * Changes the data of a single form entry with the given value.
     * @param {number} index
     * @param {string} value
     */
    const changeData = (index, value) => {
        setData(prevState => prevState.map((e, i) => i === index ? value ?? '' : e))
    }

    /**
     * Submits the form for upserting an entity in the DCE.
     *
     * @param {FormDataEvent} event
     * @return {Promise<void>}
     */
    const submitForm = async (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (DynamicContentEditorUtils.deepEqual(initialData, data)) {
            return close();
        }
        setSubmitting(true);
        /**@type {DynamicContentEditorPageEntryData}*/
        const recordsData = records.map((record, i) => ({
            key: record.key,
            value: data[i],
        }))
        const resultFlag = await onSuccess(recordsData);
        if (!isMounted())
            return;
        setSubmitting(false);
        if (resultFlag)
            close();
    }

    const formContent = useMemo(() => {
        return records.map((record, i) => {
            let content;
            let width;
            let noPadding = false;
            switch (record.type) {
                case DynamicContentEditorEntryRecordValueTypes.multiline:
                    content = <DynamicContentEditorCkEditor
                        disabled={submitting}
                        value={data[i]}
                        onChange={(value) => changeData(i, value)}
                    />;
                    width = 1;
                    break;
                case DynamicContentEditorEntryRecordValueTypes.currency:
                case DynamicContentEditorEntryRecordValueTypes.number:
                    content = <input
                        disabled={submitting}
                        type={"number"}
                        value={data[i]}
                        name={record.key}
                        min={0}
                        onChange={(e) => changeData(i, e.target.value)}
                    />
                    width = 0.5;
                    break;
                case DynamicContentEditorEntryRecordValueTypes.video:
                case DynamicContentEditorEntryRecordValueTypes.url:
                    content = <input
                        disabled={submitting}
                        type={"url"}
                        value={data[i]}
                        name={record.key}
                        onChange={(e) => changeData(i, e.target.value)}
                    />
                    width = 0.5;
                    break;
                case DynamicContentEditorEntryRecordValueTypes.image:
                    content = <DynamicContentEditorImageDropzone
                        disabled={submitting}
                        setLoading={(val) => setUploading(prevState => prevState.map((e, j) => i === j ? val : e))}
                        value={data[i]}
                        onChange={(url) => changeData(i, url)}
                    />
                    width = 1;
                    noPadding = true;
                    break;
                case DynamicContentEditorEntryRecordValueTypes.pdf:
                    content = <DynamicContentEditorPdfDropzone
                        disabled={submitting}
                        setLoading={(val) => setUploading(prevState => prevState.map((e, j) => i === j ? val : e))}
                        value={data[i]}
                        onChange={(url) => changeData(i, url)}
                    />
                    width = 1;
                    noPadding = true;
                    break;
                case DynamicContentEditorEntryRecordValueTypes.date:
                    content = <input
                        type={"date"}
                        value={data[i]}
                        name={record.key}
                        onChange={(e) => {
                            if (!e.target.value || !moment.isMoment(e.target.value)) {
                                return changeData(i, e.target.value)
                            }
                            return changeData(i, moment(e.target.value).format('YYYY-MM-DD'))
                        }}
                    />
                    width = 0.5;
                    break;
                case DynamicContentEditorEntryRecordValueTypes.text:
                default:
                    content = <input
                        type={"text"}
                        value={data[i]}
                        name={record.key}
                        onChange={(e) => changeData(i, e.target.value)}
                    />
                    width = 0.5;
                    break;
            }
            return (
                <div
                    key={record.key}
                    className={classnames(
                        'dynamic-content-editor-form-entry',
                        {'half-width': width === 0.5},
                        {'no-padding': noPadding},
                    )}
                >
                    <p className={'dynamic-content-editor-form-entry-title'}>
                        {record.title ?? ''}
                    </p>
                    {content}
                </div>
            );
        });
    }, [data, submitting, records])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => close()}
                maxWidth={'md'}
                fullWidth
                classes={{
                    root: 'dynamic-content-editor-dialog-root',
                    paper: "dynamic-content-editor-dialog-paper dynamic-content-editor-upsert-entry-dialog-paper",
                }}
            >
                <DialogTitle className={'dynamic-content-editor-dialog-title'}>
                    {title ?? ""}
                </DialogTitle>
                <DialogContent className={'dynamic-content-editor-dialog-content'}>
                    <form
                        onSubmit={submitForm}
                        noValidate
                        id={formId.current}
                    >
                        {formContent}
                    </form>
                </DialogContent>
                <DialogActions>
                    <button
                        className={'dynamic-content-editor-dialog-action-button'}
                        disabled={submitting}
                        type={'button'}
                        onClick={() => close()}
                    >
                        Close
                    </button>
                    <button
                        disabled={submitting || disabled}
                        className={'dynamic-content-editor-dialog-action-button'}
                        form={formId.current}
                        type={'submit'}
                    >
                        {
                            submitting
                                ? "Saving..."
                                : "Save"
                        }
                    </button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DynamicContentEditorUpsertEntryDialog;
