// CSS IMPORTS
import "./assets/css/index.scss";
import './assets/css/safari-only.css'
import "./assets/icon-sets/icomoon/style.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-credit-cards/es/styles-compiled.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Imports
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import reportWebVitals from "./report-web-vitals";
import Utils from "./core/services/utils/utils";
import YupExtensions from "./core/services/yup-extensions";


Utils.setAppInfo();
YupExtensions.injectExtensions();

// noinspection JSCheckFunctionSignatures
ReactDOM.render(<App/>, document.getElementById('root'));

reportWebVitals();
