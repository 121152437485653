import React, {useEffect, useState} from "react";
import {Col} from "reactstrap";
import useRouter from "../../../../../hooks/use-router";
import api from "../../../../../../core/services/api/api";
import {cmsBlogApis, cmsCategoryApis, cmsVideoApis} from "../../../../../../core/constants/endpoints/cms_endpoints";
import VideoCard from "../../../../../components/app-specific/content-boxes/video-card";
import InfiniteScroll from "react-infinite-scroller";
import classnames from "classnames";
import {
    apiMethods,
    ContentMainEitherViewTypes,
    QueryStringDefaultParseOptions
} from "../../../../../../core/constants/enums";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import BlogCard from "../../../../../components/app-specific/content-boxes/blog-card";
import NoFoundSvg from "../../../../../components/app-specific/no-results-found";

const initialPaginationInfo = {
    pageSize: 8,
    currentPage: 1,
    totalCount: 0,
}

const ContentMainEitherView = ({type}) => {
    const {query, stringifyUrl, location, history} = useRouter();
    const [content, setContent] = useState([]);
    const [paginationInfo, setPaginationInfo] = useState({...initialPaginationInfo, currentPage: 0});
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const [categoriesLoading, setCategoriesLoading] = useState(true);
    const [categoriesExpanded, setCategoriesExpanded] = useState(false);
    const isMounted = useIsMounted();

    const selectedCategories = query?.categories?.map(e => parseInt(e)) ?? [];

    /**
     * Listens for the changes in query and with each change:
     * Resets the blogs list and paginationInfo to trigger the search function
     */
    useEffect(() => {
        setPaginationInfo({...initialPaginationInfo, currentPage: 0});
        setContent([]);
        if (selectedCategories.length > 0 && !categoriesExpanded) {
            setCategoriesExpanded(true);
        }
        if (selectedCategories.length > 0) {
            const section = document.getElementById('__blog-categories-result');
            window.scrollTo(section.offsetLeft, section.offsetTop + 400);
        }
    }, [query, type])

    /**
     * Listens for the changes for the currentPage of paginationInfo and with each change:
     * - loads the list of content from the server.
     */
    useEffect(() => {
        if (paginationInfo.currentPage === 0) {
            return setPaginationInfo(initialPaginationInfo);
        }
        setLoading(true);
        const timer = setTimeout(() => {
            searchContent();
        }, 400);
        return () => clearTimeout(timer);
    }, [paginationInfo.currentPage])

    /**
     * As soon as this component mounts:
     *  - loads the list of all categories from the server.
     */
    useEffect(() => {
        getAllCategories();
    }, [])


    /**
     * Searches for the specified content based on the provided filters and the content type.
     */
    const searchContent = () => {
        api({
            url: type === ContentMainEitherViewTypes.blog ? cmsBlogApis.search : cmsVideoApis.search,
            method: apiMethods.post,
            loginRequired: false,
            data: {
                paginationInfo,
                filters: {
                    tagsId: [],
                    categoryIds: query?.categories,
                    keyword: "",
                },
            },
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setLoading(false);
                return;
            }
            if (response?.resultFlag) {

                setContent(prevState => {
                    const data = [
                        ...prevState,
                        ...(response.data.resultList ?? [])
                    ];
                    return Array.from(new Set(data.map(e => e.id)).values()).map(e => data.find(d => d.id === e));
                });
                setPaginationInfo(response.data.paginationInfo);
            } else {
                setContent([]);
                setPaginationInfo(prevState => ({...prevState, totalCount: 0}));
            }
            setLoading(false);
        });
    }

    /**
     * Loads all of CMS's categories form the server.
     */
    const getAllCategories = () => {
        setCategoriesLoading(true);
        api({
            url: cmsCategoryApis.getAllVideoCategories,
            method: apiMethods.get,
            loginRequired: false,
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setCategoriesLoading(false);
                return;
            }
            setCategories(response?.data ?? []);
            setCategoriesLoading(false);
        });
    }

    /**
     * Increases the paginationInfo's current page to trigger the search function
     */
    const loadMore = () => {
        setPaginationInfo(prevState => ({...prevState, currentPage: prevState.currentPage + 1}));
    }

    /**
     * Adds or removes the selected category id depending on their current selected status.
     * @param {any} category the category to be removed.
     */
    const onCategoryClick = (category) => {
        const categories = selectedCategories;
        const foundIndex = selectedCategories.indexOf(category.id);
        if (foundIndex === -1) {
            categories.push(category.id);
        } else {
            categories.splice(foundIndex, 1)
        }
        history.push(stringifyUrl({
            url: location.pathname,
            query: {categories: categories},
        }, QueryStringDefaultParseOptions))
    }

    return (
        <>
            <Col className={'pt-3 content-categories-selection'}>
                <div>
                    <div className={'content-categories-selection-header'}>
                        <p>Categories</p>
                    </div>
                    <div className={'section-body'}>
                        {
                            categoriesLoading
                                ? Array(12).fill(null).map((e, index) => (
                                    <div key={index} className={'loading extra-small w-45 mx-1 mb-2'}>
                                        <div/>
                                    </div>
                                ))
                                : categories?.map(category => {
                                    return (
                                        <button
                                            className={classnames(
                                                'button primary grouped',
                                                {
                                                    'active': selectedCategories.includes(category.id),
                                                }
                                            )}
                                            disabled={loading}
                                            key={`category-${category.id}`}
                                            onClick={() => onCategoryClick(category)}>
                                            {category.title}
                                        </button>
                                    )
                                })
                        }
                    </div>
                </div>
            </Col>
            <Col className={'pt-3'}>
                <div className={' w-100'} id={'__blog-categories-result'}/>
                <InfiniteScroll
                    className='d-flex flex-wrap w-100'
                    useWindow
                    pageStart={1}
                    loadMore={loadMore}
                    hasMore={!loading && paginationInfo.totalCount > paginationInfo.pageSize * paginationInfo.currentPage}
                    threshhold={400}>
                    {
                        !content?.length && !loading
                            ? <NoFoundSvg/>
                            : type === ContentMainEitherViewTypes.video
                                ? content.map(video => (
                                    <VideoCard key={video.id} data={video}/>
                                ))
                                : content.map(blog => (
                                    <Col key={blog.id} xs={12} lg={6} xl={4}
                                         className={'order-1 order-md-2 mb-4 px-2'}>
                                        <BlogCard blog={blog}/>
                                    </Col>
                                ))
                    }
                </InfiniteScroll>
                {
                    loading &&
                    <div className={'d-flex flex-wrap w-100'}>
                        {
                            type === ContentMainEitherViewTypes.video
                                ? Array(8).fill(null).map((e, index) => (
                                    <Col key={index} xs={12} sm={6} xl={4}
                                         className={'order-1 order-md-2 mb-4'}>
                                        <div className={'loading video'}>
                                            <div/>
                                        </div>
                                    </Col>
                                ))
                                : Array(8).fill(null).map((e, index) => (
                                    <Col key={index} xs={12} sm={6} xl={4}
                                         className={'order-1 mb-4 order-md-2 h-100'}>
                                        <div className={'loading blog'}>
                                            <div/>
                                        </div>
                                    </Col>
                                ))
                        }
                    </div>
                }
            </Col>
        </>
    );
}

export default ContentMainEitherView;




