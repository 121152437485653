class DynamicContentEditorTexts {

    static validationErrorMessage: string = "You do not have access to the Dynamic content editor of this" +
        " application. Please contact our technical support team.";

    static getPageDataErrorMessage: string = "Failed to fetch the data for the dynamic content editor for the" +
        " current page.";

    static savePageEntryErrorMessage: string = "Failed to save the changes made to this section. Please contact our" +
        " technical support.";

    static uploadFileErrorMessage: string = "Failed to upload the file to our servers. Please contact our technical" +
        " support.";
}

export default DynamicContentEditorTexts;
