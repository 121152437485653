import React, {useState} from "react";
import {Alert, Button, Col, Row} from "reactstrap";
import {controller} from "../../../../../core/services/api/controller";
import useIsMounted from "../../../../hooks/use-is-mounted";
import useAlert from "../../../../hooks/use-alert";
import {AlertTypes} from "../../../../../core/constants/enums";
import * as Yup from "yup";
import ValidateMessages from "../../../../../core/constants/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import MuiInput from "../../../../components/base/input/mui-input";
import Form from "../../../../components/base/form";
import MuiPhoneInput from "../../../../components/base/input/mui-phone-input";
import YupExtensions from "../../../../../core/services/yup-extensions";

const contactUsForm = {
    name: 'name',
    phone: 'phone',
    email: 'email',
    subject: 'subject',
    message: 'message'
}

YupExtensions.phoneNo();

const schema = Yup.object().shape({
    [contactUsForm.name]: Yup.string().trim().nullable().required(ValidateMessages.required),
    [contactUsForm.phone]: Yup.string().trim().nullable()
        .required(ValidateMessages.required)
        .phoneNo(ValidateMessages.incorrectType("phone"), 10),
    [contactUsForm.email]: Yup.string().trim().email("Enter valid email").nullable().required(ValidateMessages.required),
    [contactUsForm.subject]: Yup.string().trim().nullable().required(ValidateMessages.required),
    [contactUsForm.message]: Yup.string().trim().nullable().required(ValidateMessages.required),
});

const ContactUs = () => {
    const [alert, setAlert, resetAlert] = useAlert(5000);
    const [loading, setLoading] = useState(false);
    const isMounted = useIsMounted();
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * Sends a message to the support team with the given inforamtion.
     * @param {any} values
     */
    const contactUs = (values) => {
        setLoading(true);
        resetAlert()
        controller({
            Contact: {
                url: `ContactMessage/Add`,
                params: values,
                loginRequired: false,
            }
        }).then(({Contact}) => {
            if (!isMounted()) return;
            setLoading(false);
            setAlert({
                isOpen: true,
                message: Contact?.Message,
                color: Contact.Code === 100 ? AlertTypes.success : AlertTypes.error
            })
        })
    };

    return (
        <>
            <div className="sent-message">
                <div className="head mb-3 mt-2">
                    <h6>Send Message</h6>
                </div>
                <Alert color={alert.color} isOpen={alert.isOpen}>
                    {alert.message}
                </Alert>
                <Form
                    className='contact-us-form'
                    onSubmit={contactUs}
                    validate={validate}
                    render={({values, form}) => {
                        return (
                            <>
                                <div className={'form-container'}>
                                    <Row>
                                        <Col xs={12} md={4} className={'mb-3'}>
                                            <MuiInput
                                                form
                                                label={"Name"}
                                                placeholder={'Name'}
                                                name={contactUsForm.name}
                                                required={required.name}
                                            />
                                        </Col>
                                        <Col xs={12} md={4} className={'mb-3'}>
                                            <MuiPhoneInput
                                                type={'tel'}
                                                label={"Phone"}
                                                placeholder={'Phone'}
                                                name={contactUsForm.phone}
                                                required={required.phone}
                                                value={values[contactUsForm.phone]}
                                                onChange={(e) => form.change(e.target.name, e.target.value)}
                                                form={form}
                                            />
                                        </Col>
                                        <Col xs={12} md={4} className={'mb-3'}>
                                            <MuiInput
                                                form
                                                label={"Email"}
                                                placeholder={'Email'}
                                                name={contactUsForm.email}
                                                required={required.email}
                                            />
                                        </Col>
                                        <Col xs={12} className={'mb-3'}>
                                            <MuiInput
                                                form
                                                label={"Subject"}
                                                placeholder={'Subject'}
                                                name={contactUsForm.subject}
                                                required={required.subject}
                                            />
                                        </Col>
                                        <Col xs={12}>
                                            <MuiInput
                                                form
                                                label={"Message"}
                                                placeholder={'Write your message...'}
                                                name={contactUsForm.message}
                                                required={required.message}
                                                multiline={true}
                                                minRows={5}
                                            />
                                        </Col>
                                        <Col xs={12} className={'mt-3 d-flex justify-content-end'}>
                                            <Button className={'button primary px-5'} type={'submit'}
                                                    disabled={loading}>
                                                {loading ? 'Loading...' : 'Send your comment'}
                                            </Button>
                                        </Col>

                                    </Row>
                                </div>
                            </>
                        );
                    }}
                />
            </div>
        </>
    )
}

export default ContactUs;
