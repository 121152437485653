import moment from "moment";
import {ReadingFileAs} from "../../constants/enums";
import {v4 as UUIDv4} from 'uuid';
import EnvService from "../env-service";
import $ from 'jquery';
import {format} from 'phone-formatter';
import Regexes from "../../constants/regexes";


/**
 * Determines if two objects are equal
 * @param object1 {any}
 * @param object2 {any}
 * @return {boolean}
 */
export const deepEqual = (object1, object2) => {
    // check if the first one is an array
    if (Array.isArray(object1)) {
        if (!Array.isArray(object2) || object1.length !== object2.length) return false;
        for (let i = 0; i < object1.length; i++) {
            if (!deepEqual(object1[i], object2[i])) return false;
        }
        return true;
    }
    // check if the first one is an object
    if (typeof object1 === 'object' && object1 !== null && object2 !== null) {
        if (!(typeof object2 === 'object')) return false;
        const keys = Object.keys(object1);
        if (keys.length !== Object.keys(object2).length) return false;
        for (const key in object1) {
            if (!deepEqual(object1[key], object2[key])) return false;
        }
        return true;
    }
    // not array and not object, therefore must be primitive
    return object1 === object2;
}

/**
 *  Deep copy an acyclic *basic* Javascript object.  This only handles basic
 * scalars (strings, numbers, booleans) and arbitrarily deep arrays and objects
 * containing these.  This does *not* handle instances of other classes.
 * @param obj {any}
 */
export const deepCopy = (obj) => {
    let ret, key;
    let marker = '__deepCopy';

    if (obj && obj[marker])
        throw (new Error('attempted deep copy of cyclic object'));

    if (obj && obj.constructor == Object) {
        ret = {};
        obj[marker] = true;

        for (key in obj) {
            if (key == marker)
                continue;

            // @ts-ignore
            ret[key] = deepCopy(obj[key]);
        }

        delete (obj[marker]);
        return (ret);
    }

    if (obj && obj.constructor == Array) {
        ret = [];
        // @ts-ignore
        obj[marker] = true;

        for (key = 0; key < obj.length; key++)
            ret.push(deepCopy(obj[key]));

        // @ts-ignore
        delete (obj[marker]);
        return (ret);
    }
    // It must be a primitive type -- just return it.
    return (obj);
}

/**
 * Given a html text, removes all the tags and only shows the texts between them.
 * @param text {string}
 * @param limit {number}
 * @return {string}
 */
export const htmlToString = (text, limit = 100) => {
    if (!text)
        return '';
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = text;
    let cleanText = tempDivElement.textContent ?? tempDivElement.innerText ?? "";
    if (cleanText.length > limit) {
        cleanText = cleanText.substring(0, limit).concat('...');
    }
    return cleanText;
}

/**
 * Transforms a string that is parsable to a number into a formatted money string.
 * @param amount {string | number}
 * @param decimalCount {number}
 * @param decimal {string} the identifier used for decimal separation
 * @param thousands {string} the identifier used for thousands separation
 */
export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";
        amount = Math.abs(Number(amount) || 0).toFixed(decimalCount);
        let i = parseInt(amount).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + "$" + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

/**
 * fills the card number empty spaces with *s
 * @param lastFour
 */
export const getCardNumber = (lastFour) => {
    return `**** **** **** ${lastFour}`;
}

/**
 * fills the card month empty spaces with *s
 * @param month
 */
export const getCardMonth = (month) => {
    if (!month) return '**/**';
    if (month.length === 1) {
        return `0${month}/**`;
    }
    return `${month}/**`;
}

/**
 * fills the card number empty spaces with *s
 * @param {string} number
 */
export const getGiftCardNumber = (number) => {
    let difference = 16 - number.length;
    if (difference <= 0) return number;
    while (difference > 0) {
        number += "#";
        difference -= 1;
    }
    return number;
}

/**
 * Creates the full address property of the addresses to be used in the table.
 * @param {any} address
 * @return {string}
 */
export const getAddressString = (address) => {
    let res = '';
    if (!address) return res;
    if (address.aptNo?.length) {
        res = res.concat(address.aptNo)
        res = res.concat('-')
    }
    if (address.streetAddress?.length) {
        res = res.concat(address.streetAddress)
        res = res.concat(', ')
    }
    if (address.city?.length) {
        res = res.concat(address.city)
        res = res.concat(', ')
    }
    if (address.province?.name?.length) {
        res = res.concat(address.province?.name)
        res = res.concat(', ')
    }
    if (address?.postalCode?.length) {
        res = res.concat(address.postalCode.toUpperCase())
        res = res.concat(', ')
    }
    if (address?.country?.name?.length ?? address?.province?.country?.name?.length) {
        res = res.concat(address.country?.name ?? address?.province?.country?.name)
    }
    return res;
}

/**
 * Creates the full address property of the address for displaying in address cards.
 * @param {any} address
 * @return {string}
 */
export const getAddressHTML = (address) => {
    let res = '';
    if (!address)
        return res;
    if (address.aptNo?.length) {
        res = res.concat(address.aptNo)
        res = res.concat('-')
    }
    if (address.streetAddress?.length ?? address.fullAddress?.length) {
        res = res.concat(address.streetAddress ?? address.fullAddress)
    }
    res = res.concat('<br/>');
    if (address.city?.length) {
        res = res.concat(address.city)
        res = res.concat(', ')
    }
    if (address.province?.name?.length) {
        res = res.concat(address.province?.name)
    }
    res = res.concat('<br/>');
    if (address?.postalCode?.length) {
        res = res.concat(address.postalCode.toUpperCase())
        res = res.concat(', ')
    }
    if (address?.country?.name?.length ?? address?.province?.country?.name?.length) {
        res = res.concat(address.country?.name ?? address?.province?.country?.name)
    }
    return res;
}

/**
 * Flattens an object. if the parent key exists, then prepends the parent key with the key as it constructs the obejct
 * @param object {any}
 * @param parentKey {string | null}
 */
const _flatten = (object, parentKey = null) => {
    return [].concat(...Object.keys(object)
        .map((key) => typeof object[key] === 'object'
            ? _flatten(object[key], parentKey ? `${parentKey}-${key}` : key)
            : ((parentKey) ? {[`${parentKey}-${key}`]: object[key]} : {[key]: object[key]})
        )
    );
}

/**
 * Creates a new flattened object off of the given object
 * @param object {any}
 */
export const flattenObject = (object) => {
    return Object.assign({}, ..._flatten(object))
}

/**
 * Given an object, will flatten it and return all of its values as a single.
 *
 * if append is given, then for each of the values of the object, appends it to their values as a string
 * @param object {any}
 * @param append {string | null}
 */
export const flattenObjectAndReturnAsAList = (object, append = null) => {
    const all = flattenObject(object);
    const res = [];
    for (const [key, value] of Object.entries(all)) {
        if (key) res.push(value);
    }
    if (append && append.length) return res.map(e => `${e}${append}`);
    return res;
};

/**
 * Opens the provided link into a new tab of the browser
 * @param link {string} the link to be opened in a new tab
 */
export const openLinkInNewTab = (link) => {
    if (!link?.length) return;
    const newTab = document.createElement('a');
    newTab.href = link;
    newTab.target = '_blank';
    newTab.rel = 'noopenner';
    document.body.appendChild(newTab);
    newTab.click();
    document.body.removeChild(newTab);
}

/**
 * Downloads the given blob for the user.
 * @param blob {Blob} the file to be downloaded
 * @param name {string} the file to be downloaded
 */
export const downloadFile = (blob, name) => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

/**
 * Reads the given file based on the provided method of reading.
 * @param {File | Blob} file
 * @param {string} as
 * @return {PromiseLike<string | ArrayBuffer | null>}
 */
export const readFile = (file, as) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        switch (as) {
            case ReadingFileAs.text:
                reader.readAsText(file);
                break;
            case ReadingFileAs.dataUrl:
                reader.readAsDataURL(file);
                break;
            case ReadingFileAs.arrayBuffer:
                reader.readAsArrayBuffer(file);
                break;
            case ReadingFileAs.binaryString:
                reader.readAsBinaryString(file);
                break;
            default:
                break;
        }
        reader.onload = () => {
            resolve(reader.result)
        };
        reader.onerror = error => reject(error);
    });
}

/**
 * Parses the response DS of the theme editor from a list type to an object type.
 * @param {any[]} obj
 * @return {any}
 */
export const parseThemeEditorResponse = (obj) => {
    if (typeof obj === 'undefined') return ''
    let result = {};
    for (let i = 0; i < obj.length; i++) {
        result = {
            ...result,
            [obj[i].Name]: obj[i]['parts'].map((item) => {
                let res = {};
                for (let i = 0; i < item['part'].length; i++) {
                    res = {
                        ...res,
                        [item['part'][i].Name]: item['part'][i].Value
                    };
                }
                return res
            })
        };
    }
    return result;
};

/**
 * Fetches the os of the user browser.
 * @return {string | "Mobile" | "Web"}
 */
export const getOS = () => (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))
    ? "Mobile"
    : "Web"

/**
 * Corrects the url of the video by forcing it to start with https
 * @param {string} url
 * @return {string|*}
 */
export const correctUrl = (url) => {
    if (!url?.length) return '';
    if (url.startsWith('http://') || url.startsWith('https://')) return url;
    return `https://${url}`;
}

/**
 * Creates a Unique Identifier in form of a string
 */
export const createUUId = (reactKey = false) => {
    const uuid = UUIDv4();
    if (!reactKey) return uuid;
    return `_${uuid}`
}

/**
 * Strips the units from the given value.
 * @param {string} withUnit
 * @return {number} the stripped number.
 */
export const stripUnits = (withUnit) => {
    return parseFloat(withUnit.replace(/px|rem|em/, ''))
}

/**
 * Fetches the first scrolling ancestor of the given element.
 *
 * @param {HTMLElement} element
 * @param {boolean} includeHidden whether to also include elements with "overflow: hidden" or not. defaults to false.
 */
export const getFirstScrollingAncestor = (element, includeHidden = false) => {
    let style = getComputedStyle(element);
    let excludeStaticParent = style.position === "absolute";
    let overflowRegex = includeHidden ? /(auto|scroll|hidden)/ : /(auto|scroll)/;

    if (style.position === "fixed") return document.body;
    for (let parent = element; (parent = parent.parentElement);) {
        if (!parent)
            return document.body;

        style = getComputedStyle(parent);
        if (excludeStaticParent && style.position === "static")
            continue;

        if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX))
            return parent;
    }

    return document.body;
}

//              ########################### COMPARATORS ###################################

/**
 * Compares two numbers
 * @param a {number}
 * @param b {number}
 */
export const numComparator = (a, b) => {
    if (a === b) return 0;
    if (a < b) return -1;
    return 1
}

/**
 * Compares two dates by converting them to moment objects and then comparing them
 * @param a {Date}
 * @param b {Date}
 */
export const dateComparator = (a, b) => {
    const _momentComparator = (a, b) => {
        if (a.isSame(b, 'ms')) return 0;
        if (a.isAfter(b, 'ms')) return 1;
        return -1;
    }
    return _momentComparator(moment(a), moment(b));
}

/**
 * Compares two strings.
 * @param a {string}
 * @param b {string}
 */
export const stringComparator = (a, b) => {
    return a?.localeCompare(b);
}

class Utils {

    // TODO: enclose other utilities functions into the Utils class


    //              ########################### COMPARATORS ###################################

    /**
     * Compares two Booleans
     * @param a {boolean}
     * @param b {boolean}
     */
    static booleanComparator(a, b) {
        if (a === b) return 0;
        if (a < b) return -1;
        return 1;
    }

    //              ########################### UTILS ###################################


    /**
     * Determines if the element has scrolled into the view with respect to the window object.
     * @param {Element} el
     * @return {boolean}
     */
    static isScrolledIntoView(el) {
        if (!el) return false;
        const rect = el.getBoundingClientRect();
        const elemTop = rect.top;
        const elemBottom = rect.bottom;
        // Only completely visible elements return true:
        // Partially visible elements return true:
        // isVisible = elemTop < window.innerHeight && elemBottom >= 0;
        return (elemTop >= 0) && (elemBottom <= window.innerHeight);
    }


    /**
     * Awaits for the specified time in milliseconds.
     * @param {number} milliseconds
     * @return { Promise<void>}
     */
    static async wait(milliseconds) {
        await new Promise(r => setTimeout(r, milliseconds));
    }

    /**
     * Sets the application's title and description from given values.
     *
     * @param {string?} title
     * @param {string?} description
     */
    static setAppInfo({
                          title,
                          description
                      } = {title: EnvService.websiteName, description: EnvService.websiteDescription}) {
        if (title) {
            document.title = title;
        }
        if (description) {
            $('meta[name="description"]').attr("content", description);
        }
    }


    /**
     * Excludes null or undefined from the values of the object.
     * @param {Object} obj
     * @param {boolean} excludeNull
     * @param {boolean} excludeUndefined
     * @return {Record<string, any>}
     */
    static excludeNullOrUndefined(obj, excludeNull = true, excludeUndefined = true) {
        return Object.fromEntries(Object.entries(obj).filter(([_, val]) =>
            ((excludeNull && val !== null) || !excludeNull) &&
            ((excludeUndefined && typeof val !== 'undefined') || !excludeUndefined)
        ))
    }

    /**
     * Formats the given phone number.
     * @param {string} number the phone number
     * @param {string} pattern the pattern of the phone number
     * @param {number} areaCode the area code of the number as its suffix.
     * @param {import("phone-formatter").FormatOptions} options the area code of the number as its suffix.
     */
    static formatPhoneNumber(number, pattern = '+N (NNN) NNN-NNNN', areaCode = 1, options = {normalize: true}) {
        if (!number) return '--';
        number = number.replaceAll(Regexes.phoneFormatterCleaner, '')
        return format(`${areaCode}` + number, pattern, options);
    }
}

export default Utils;
