import {TechnicalPropertyPrefix, TechnicalPropertyTypes} from "../../constants/enums";
import Regexes from "../../constants/regexes";

/**
 * Responsible for parsing and creating the query search parameters associated with product searches of the application.
 */
class ProductSearchUtils {

    // ########################################     Keys   ########################################

    static basicSearchFormKeys = {
        category1: 'category1',
        category2: 'category2',
        category3: 'category3',
        orderBy: 'orderBy',
        keyword: 'keyword',
        tags: 'tags',
    }
    static basicSearchQueryKeys = {
        categoryId: 'categoryId',
        keyword: 'keyword',
        orderBy: 'orderBy',
        tags: 'tags',
    }
    static applicationSearchFormKeys = {
        make: 'make',
        model: 'model',
        year: 'year',
        orderBy: 'orderBy',
    }
    static applicationSearchQueryKeys = {
        make: 'make',
        model: 'model',
        year: 'year',
        orderBy: 'orderBy',
    }
    static technicalSearchFormKeys = {
        category1: 'category1',
        category2: 'category2',
        category3: 'category3',
        orderBy: 'orderBy',
        properties: 'properties',
    }
    static technicalSearchQueryKeys = {
        categoryId: 'categoryId',
        orderBy: 'orderBy',
        properties: 'properties',
    }

    static elasticTechnicalSearchFormKeys = {
        categoryId: 'categoryId',
        keyword: 'keyword',
        orderBy: 'orderBy',
    }
    static elasticTechnicalSearchQueryKeys = {
        categoryId: 'categoryId',
        keyword: 'keyword',
        orderBy: 'orderBy',
        facets: 'facets',
    }

    // ########################################     Helpers   ########################################

    /**
     * Determines if the given value should be included in the valid entries or not.
     *
     * @param {string} key
     * @param {any} value
     * @param {boolean} allowEmptyString
     * @param {boolean} allowNull
     * @return {{}}
     * @private
     */
    static _includeValue(key,
                         value,
                         allowEmptyString,
                         allowNull) {
        if (value === null || value === undefined) {
            if (!allowNull) {
                return {}
            }
        }
        if (typeof value === 'string') {
            if (!value.length && !allowEmptyString) {
                return {}
            }
        }
        return {
            [key]: value
        }
    }

    /**
     * Fetches the entries of the values that:
     * 1. can be found in keys
     * 2. are not empty string if not [allowEmptyString]
     * 3. are not null or undefined if not [allowNull]
     * 4. are not validated though validator if it exists.
     *
     * @param {Record<string, string>} keys
     * @param {Record<string, any>} values
     * @param {boolean} allowEmptyString
     * @param {boolean} allowNull
     * @param {function} validator
     * @return {{}}
     * @private
     */
    static _getValidEntries(keys,
                            values,
                            allowEmptyString,
                            allowNull,
                            validator = null) {
        const keysList = Object.keys(keys)
        return Object
            .entries(values ?? {})
            .filter(([key]) => keysList.includes(key) || (validator && validator(key)))
            .reduce((prev, [key, value]) => ({
                ...prev,
                ...this._includeValue(key, value, allowEmptyString, allowNull)
            }), {})
    }

    /**
     * Maps the given values with keys of formKeys to the query keys provided formToQueryKeyMapping.
     *
     * @param {Record<string, string | function>} formKeys
     * @param {Record<string, string>} values
     * @param {Record<string, string>} formToQueryKeyMapping
     * @param {any} rest
     * @return {{}}
     * @private
     */
    static _mapFormToQueryValues(formKeys,
                                 values,
                                 formToQueryKeyMapping,
                                 ...rest) {
        const keys = Object.values(formKeys)
        const mapped = {};
        for (const [queryKey, formKey] of Object.entries(formToQueryKeyMapping)) {
            if (typeof formKey === 'function') {
                mapped[queryKey] = formKey(values)
                continue;
            }
            if (keys.includes(formKey)) {
                mapped[queryKey] = values[formKey]
            }
        }
        return this._getValidEntries(formToQueryKeyMapping, mapped, ...rest)
    }

    /**
     * Finds the category hierarchy of the given categoryId from the list of categories.
     * @param {number} categoryId
     * @param {any[]} categories
     * @param {number} length
     * @param {string[]} result
     * @return {string[]}
     * @private
     */
    static _findCategoryHierarchy(categoryId,
                                  categories,
                                  length,
                                  result = [categoryId?.toString()]) {
        const found = categories?.find(e => (e.Id ?? e.id) === categoryId)
        const id = found?.parentId ?? found?.ParentId;
        if (!found || !id) {
            if (result.length !== length)
                result.push(...Array(length - result.length).fill(null))
            return result
        }
        return this._findCategoryHierarchy(id, categories, length, [id?.toString(), ...result])
    }

    // ########################################     Form -> Query   ########################################

    /**
     * Transforms the form values of the given values to query parameters.
     *
     * @param {Record<string, string>} formKeys
     * @param {Record<string, any>} values
     * @param {Record<string, string>} formToQueryKeyMapping
     * @param {boolean} allowEmptyString
     * @param {boolean} allowNull
     * @param {function | null} validator
     * @return {{}}
     */
    static transformFormValuesToSearchQuery(formKeys,
                                            values,
                                            formToQueryKeyMapping,
                                            allowEmptyString = false,
                                            allowNull = false,
                                            validator = null) {
        const validValues = this._getValidEntries(formKeys, values, allowEmptyString, allowNull, validator)
        return this._mapFormToQueryValues(formKeys, validValues, formToQueryKeyMapping, allowEmptyString, allowNull, validator)
    }

    // ########################################     Query -> Form   ########################################

    /**
     * Transforms the query parameters of the basic search to form values.
     *
     * @param {Record<string, string>} query
     * @param {Record<string, any>} formData
     * @param {boolean} allowEmptyString
     * @param {boolean} allowNull
     * @return {[Record<string, any>,[]]} the validated form values and the list of invalid properties of the given
     * query.
     */
    static parseBasicSearchQuery(query, formData, allowEmptyString = false, allowNull = false) {
        const queryKeys = this.basicSearchQueryKeys;
        const formKeys = this.basicSearchFormKeys;
        const values = this._getValidEntries(queryKeys, query, allowEmptyString, allowNull);

        const result = {};

        const valueKeys = Object.keys(values);
        const removed = Object.keys(query)?.filter(e => !valueKeys.includes(e));

        // Keyword check
        const keywords = values[queryKeys.keyword]
        if (keywords) {
            result[formKeys.keyword] = keywords;
        } else {
            removed.push(queryKeys.keyword)
        }

        // OrderBy check
        const foundOrderBy = formData.orderByList.find(e => (e.Name ?? e.name) === values[queryKeys.orderBy])
        if (foundOrderBy) {
            result[formKeys.orderBy] = foundOrderBy.Name ?? foundOrderBy.name;
        } else {
            removed.push(queryKeys.orderBy)
        }

        // Tagss check
        if (Array.isArray(values[queryKeys.tags])) {
            result[formKeys.tags] = values[queryKeys.tags];
        } else {
            removed.push(queryKeys.tags)
        }

        // CategoryId check
        if (Number.isNaN(parseInt(values[queryKeys.categoryId]))) {
            removed.push(queryKeys.categoryId);
        } else {
            const categories = this._findCategoryHierarchy(parseInt(values[queryKeys.categoryId]), formData.categories, 3)
            if (categories[0] && !Number.isNaN(categories[0])) {
                result[formKeys.category1] = categories[0]
            } else {
                removed.push(queryKeys.categoryId);
            }

            result[formKeys.category1] = Number(categories[0])

            if (categories[1] && !Number.isNaN(categories[1])) {
                result[formKeys.category2] = Number(categories[1])
            }

            if (categories[2] && !Number.isNaN(categories[2])) {
                result[formKeys.category3] = Number(categories[2])
            }
        }

        const requiredKeys = [queryKeys.categoryId, queryKeys.keyword, queryKeys.tags];
        if (removed.filter(e => requiredKeys.includes(e)).length === requiredKeys.length) {
            // if no category and no keywords, then return nothing since search can not be done.
            return [{}, [...removed, ...Object.values(queryKeys)]];
        }

        return [result, removed];
    }

    /**
     * Transforms the query parameters of the application search to form values.
     *
     * @param {Record<string, string>} query
     * @param {Record<string, any>} formData
     * @param {boolean} allowEmptyString
     * @param {boolean} allowNull
     * @return {[Record<string, any>,[]]} the validated form values and the list of invalid properties of the given
     * query.
     */
    static parseApplicationSearchQuery(query,
                                       formData,
                                       allowEmptyString = false,
                                       allowNull = false) {
        const queryKeys = this.applicationSearchQueryKeys;
        const formKeys = this.applicationSearchFormKeys;
        const values = this._getValidEntries(queryKeys, query, allowEmptyString, allowNull);
        const result = {};
        const valueKeys = Object.keys(values);
        const removed = Object.keys(query)?.filter(e => !valueKeys.includes(e));

        // Make check
        const foundMake = formData.makeAndModels.find(e => e.id === Number(values[queryKeys.make]))
        if (!foundMake) {
            // if no make, then return nothing since search can not be done.
            removed.push(queryKeys.make)
            return [result, [...removed, ...Object.values(queryKeys)]]
        }
        result[formKeys.make] = Number(foundMake.id);

        // Model check
        const foundModel = foundMake?.models.find(e => e.id === Number(values[queryKeys.model]))
        if (foundModel) {
            result[formKeys.model] = Number(foundModel.id);
        } else {
            removed.push(queryKeys.model)
        }

        // order by check
        const foundOrderBy = formData.orderByList.find(e => e.name === values[queryKeys.orderBy])
        if (foundOrderBy) {
            result[formKeys.orderBy] = foundOrderBy.name;
        } else {
            removed.push(queryKeys.orderBy)
        }

        // Year check
        const foundYear = foundModel?.years.find(e => e === Number(values[queryKeys.year]))
        if (foundYear) {
            result[formKeys.year] = Number(foundYear);
        } else {
            removed.push(queryKeys.year)
        }

        return [result, removed];
    }

    /**
     * Transforms the query parameters of the technical search to form values.
     *
     * @param {Record<string, string>} query
     * @param {Record<string, any>} formData
     * @param {boolean} allowEmptyString
     * @param {boolean} allowNull
     * @return {[Record<string, any>,[]]} the validated form values and the list of invalid properties of the given
     * query.
     */
    static parseTechnicalSearchQuery(query,
                                     formData,
                                     allowEmptyString = false,
                                     allowNull = false) {
        const queryKeys = this.technicalSearchQueryKeys;
        const formKeys = this.technicalSearchFormKeys;

        const values = this._getValidEntries(queryKeys, query, allowEmptyString, allowNull,
            (key) => key.match(Regexes.technicalPropertyFinder));

        const result = {};

        const valueKeys = Object.keys(values);
        const removed = Object.keys(query)?.filter(e => !valueKeys.includes(e));

        const categories = this._findCategoryHierarchy(parseInt(values[queryKeys.categoryId]), formData.categoriesFlat, 3)

        if (!categories[0] || Number.isNaN(categories[0])) {
            // if no category, then return nothing since search can not be done.
            return [{}, [...removed, ...Object.values(queryKeys)]];
        }

        result[formKeys.category1] = categories[0]

        if (categories[1] && !Number.isNaN(categories[1])) {
            result[formKeys.category2] = categories[1]
        }

        if (categories[2] && !Number.isNaN(categories[2])) {
            result[formKeys.category3] = categories[2]
        }

        const foundOrderBy = formData.orderByList.find(e => e.name === values[queryKeys.orderBy])
        if (foundOrderBy) {
            result[formKeys.orderBy] = foundOrderBy.name;
        } else {
            removed.push(queryKeys.orderBy)
        }

        const availableProperties = formData.categories?.find(e => e.id === Number(result[formKeys.category1]))?.properties ?? [];
        const currentProperties = Object.entries(values)?.filter(([key]) => key.match(Regexes.technicalPropertyFinder))

        // extra layer of validation for properties based on the category
        const properties = currentProperties
                ?.filter(([key, value]) => {
                    const id = parseInt(key.replace(TechnicalPropertyPrefix, ''));
                    const foundProperty = availableProperties.find(e => e.id === id);
                    if (!foundProperty) {
                        return false;
                    }
                    switch (foundProperty.type) {
                        case TechnicalPropertyTypes.input:
                            if (!value?.length) {
                                return false;
                            }
                            break
                        case TechnicalPropertyTypes.switch:
                            if (!['1', '0'].includes(value)) {
                                return false;
                            }
                            break;
                        case TechnicalPropertyTypes.dropdown:
                            if (!foundProperty.defaultValue?.find(e => e.id === Number(value?.id ?? value))) {
                                return false;
                            }
                            break;
                        default:
                            break;
                    }
                    return true;
                })
            ?? [];

        const propertyKeys = properties.map(e => e[0]);
        removed.push(...(
            currentProperties
                ?.map(e => e[0])
                ?.filter(e => !propertyKeys.includes(e))
            ?? []
        ));

        result[formKeys.properties] = propertyKeys?.map(key => parseInt(key.replace(TechnicalPropertyPrefix, ''))) ?? [];
        Object.assign(result, Object.fromEntries(properties.map(([key, value]) => [key, value?.id ?? value])))

        if (!result[formKeys.properties]?.length) {
            // if no properties, then return nothing since search can not be done.
            return [{}, [...removed, ...Object.values(queryKeys)]];
        }

        return [result, removed];
    }

}

export default ProductSearchUtils
