import React, {useEffect, useState} from "react";
import {Alert, Col, Row} from "reactstrap";
import useRouter from "../../../../../hooks/use-router";
import {AlertTypes, apiMethods} from "../../../../../../core/constants/enums";
import {partApis} from "../../../../../../core/constants/endpoints/endpoints";
import api from "../../../../../../core/services/api/api";
import useIsMounted from "../../../../../hooks/use-is-mounted";
import useAlert from "../../../../../hooks/use-alert";
import LocalStorageService from "../../../../../../core/services/cache/local-storage-service";
import {getOS} from "../../../../../../core/services/utils/utils";
import * as Yup from "yup";
import ValidateMessages from "../../../../../../core/constants/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import MuiInput from "../../../../../components/base/input/mui-input";
import Form from "../../../../../components/base/form";


const schema = Yup.object().shape({
    message: Yup.string().nullable().trim().required(ValidateMessages.required),
});

const validate = makeValidate(schema);
const required = makeRequired(schema);

const PartFeedbackForm = ({fetchAgain}) => {
    const {location, params, history} = useRouter();
    const [alert, setAlert] = useAlert(4000);
    const [submitting, setSubmitting] = useState(false);
    const [replyComment, setReplyComment] = useState({Id: null, Username: ''});
    const isMounted = useIsMounted();

    /**
     * Listens for the changes in location.state and with each change:
     * - if a hash has been added to the location, then scrolls the view to that element and then sets the
     * replyComment with the received information from location.state.
     */
    useEffect(() => {
        const id = location.hash?.replace('#', '')
        if (!id) return;
        window.scrollTo(0, getOffsetTop(document.getElementById(id)));
        setReplyComment({
            Id: location?.state?.commentId ?? null,
            Username: location?.state?.UserName ?? '',
        })
    }, [location.state]);

    /**
     * Fetches the offset top of an element with respect to the window itself.
     * @param {HTMLElement} element
     * @return {number}
     */
    const getOffsetTop = (element) => {
        let offsetTop = 0;
        while (element) {
            offsetTop += element.offsetTop;
            element = element.offsetParent;
        }
        return offsetTop;
    }

    /**
     * Removes the [location.state] so that the ui can be triggered to both scroll the user to feedbacks view and also
     * to remove the replying feature.
     */
    const cancelReplying = () => {
        history.replace({pathname: location.pathname, hash: '#FeedbackForm', state: null})
    }

    /**
     * Leaves a feedback for this specific part with the given information.
     * @param {any} values the form values.
     * @param {formApi} form the form api.
     */
    const leaveFeedback = async (values, form) => {
        setSubmitting(true);
        const response = await api({
            url: partApis.addComment,
            method: apiMethods.post,
            showError: false,
            data: {
                Data: {
                    PartNo: params.id,
                    Text: values.message,
                    ReplyCommentID: replyComment.Id
                },
                Token: LocalStorageService.get(LocalStorageService.keys.token),
                Os: getOS(),
            }
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setSubmitting(false);
            return;
        }
        setAlert({
            message: response?.Message ?? '',
            isOpen: true,
            color: response?.Code === 100 ? AlertTypes.success : AlertTypes.error,
        })
        if (response?.Code === 100) {
            cancelReplying();
            await fetchAgain();
            form.change('message', undefined);
            form.resetFieldState('message');
        }
        setSubmitting(false);
    };

    return (
        <>
            <div id={'FeedbackForm'}>
                <div className="heading smaller pt-3 mb-2">
                    Leave a Comment
                    <p>Your email address will not be published.</p>
                </div>
                <div className="comment-form">
                    <Alert color={alert.color} isOpen={alert.isOpen}>
                        {alert.message}
                    </Alert>
                    {
                        replyComment.Username &&
                        <div className="d-flex flex wrap justify-content-between">
                            <p className={'reply-comment'}>
                                You are replying
                                <span>{replyComment.Username}</span>
                                's comment
                            </p>
                            <button type={'button'} className={'button white'} onClick={cancelReplying}>
                                cancel
                            </button>
                        </div>
                    }
                    <Form
                        className='register-form'
                        onSubmit={leaveFeedback}
                        validate={validate}
                        initialValues={{}}
                        render={() => {
                            return (
                                <>
                                    <Row form>
                                        <Col xs={12}>
                                            <MuiInput
                                                form
                                                label={"Comment"}
                                                type={'text'}
                                                placeholder={'your message...'}
                                                name={'message'}
                                                required={required.message}
                                                minRows={5}
                                                multiline
                                            />
                                        </Col>
                                        <Col xs={12} className={'mt-3 text-right'}>
                                            <button
                                                className={'button primary px-5'}
                                                disabled={submitting}
                                                type={'submit'}>
                                                {
                                                    !submitting
                                                        ? "Submit Feedback"
                                                        : "submitting..."
                                                }
                                            </button>
                                        </Col>
                                    </Row>
                                </>
                            );
                        }}
                    />
                </div>
            </div>
        </>
    )
}

export default PartFeedbackForm;
