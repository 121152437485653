import React, {useEffect, useState} from "react";
import {Col, Fade} from "reactstrap";
import {useSelector} from "react-redux";
import classnames from "classnames";
import MuiInput from "../../../base/input/mui-input";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {ReactComponent as SuccessIcon} from "../../../../../assets/images/art-likes/payment-success.svg";

const UserPaymentsSuccessSection = ({sendEmail, goBack, getTitle}) => {
    const userProfileEmail = useSelector(state => state?.profile?.email ?? 0);
    const [email, setEmail] = useState(userProfileEmail);
    const [sendingEmail, setSendingEmail] = useState(false);
    const isMounted = useIsMounted();

    /**
     * Listens for the changes in the redux state email and with each change:
     * syncs the email state with it.
     */
    useEffect(() => {
        setEmail(userProfileEmail ?? '')
    }, [userProfileEmail])

    /**
     * Sends an email to the user containing the current information of this shopping cart.
     *
     * if the result of the api call is successful, collapses the email input.
     * @return {Promise<void>}
     */
    const sendEmailToUser = async () => {
        if (!email?.length) return;
        setSendingEmail(true);
        await sendEmail(email);
        if (!isMounted()) return
        setSendingEmail(false);
    }

    return (
        <>
            <Col xs={12}>
                <div className={'payment-card d-flex flex-column align-items-center'}>
                    <Col xs={12} md={8}>
                        <p className={'title success'}>
                            {getTitle()}
                        </p>
                        <p className={'sub-title'}>
                            Thank you for shopping from us
                        </p>
                        <SuccessIcon className={'svg-success'}/>
                        <div className={classnames('email')}>
                            <p className={'title'}>
                                Email Address
                            </p>
                            <MuiInput
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                placeholder={'your email address'}
                                fullWidth
                            />
                        </div>
                        <button onClick={sendEmailToUser}
                                disabled={sendingEmail}
                                className={classnames('mt-3 button primary py-3 w-100')}>
                            {
                                sendingEmail
                                    ? "submitting..."
                                    : "Receive Email Receipt"
                            }
                        </button>
                        <Fade in={!sendingEmail}>
                            <button className={'button text primary mt-2'}
                                    disabled={sendingEmail}
                                    onClick={goBack}>
                                Back
                            </button>
                        </Fade>
                    </Col>
                </div>
            </Col>
        </>
    )
}

export default UserPaymentsSuccessSection;
