import LocalStorageService from "../../../core/services/cache/local-storage-service";
import {createSlice} from "@reduxjs/toolkit";

/**
 * Reducer for handling auth slice of the redux.
 * this reducer is used to determine user auth status in the application and provide state for the ui component.
 */
const authReduxSlice = createSlice({
    name: 'auth',
    initialState: {
        token: LocalStorageService.get(LocalStorageService.keys.token),
        loading: false,
        message: ''
    },
    reducers: {
        /**
         * Starts the process of logging in the user into the system
         * @param state the previous state of the reducer.
         * @param action the action
         */
        loginUser: (state, action) => {
            state.loading = true;
            state.message = null;
        },
        /**
         * Saves the authentication token of the user.
         * @param state the previous state of the reducer.
         * @param action the action
         */
        loginUserSuccess: (state, action) => {
            state.loading = false;
            state.message = null;
            state.token = action.payload;
        },
        /**
         * Sets the error message of failed login attempt.
         * @param state the previous state of the reducer.
         * @param action the action
         */
        loginUserFailed: (state, action) => {
            state.loading = false;
            state.message = action.payload;
        },
        /**
         * Removes the token and message of the state.
         * @param state the previous state of the reducer.
         * @param action the action
         */
        logoutUser: (state, action) => {
            state.token = null;
            state.message = null;
        },
    }
})

export default authReduxSlice;
