import React from "react";
import {useLocation} from "react-router-dom";
import {parse as parseQuery, ParsedQuery, ParseOptions} from 'query-string';

/**
 * default parse options of this hook.
 */
const defaultParseOptions: ParseOptions = {arrayFormat: 'bracket', parseNumbers: true, parseBooleans: true};


/**
 * Hook for parsing the query of the url.
 *
 * @param {ParseOptions} options the parse options of the query string.
 * @return {ParsedQuery<string>}
 */
const useQuery = (options: ParseOptions = defaultParseOptions): ParsedQuery<string | number | boolean | Array<any>> => {
    const location = useLocation();

    return React.useMemo(() => parseQuery(location.search, options), [location.search, options]);
}

export default useQuery;
