import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from 'react';
import {Col, Container, Row,} from "reactstrap";
import {useDispatch} from "react-redux";
import {setReduxHeaderHeight} from "../../../../redux/actions";
import CategoryBox from "../../../components/app-specific/category-box";
import {controller} from "../../../../core/services/api/controller";
import classnames from "classnames";
import {partCategoryApis} from "../../../../core/constants/endpoints/endpoints";
import Utils from "../../../../core/services/utils/utils";
import useRouter from "../../../hooks/use-router";
import {ReactComponent as BackButton} from "../../../../assets/images/blog-back-icon.svg";
import useIsMounted from "../../../hooks/use-is-mounted";
import {apiMethods} from "../../../../core/constants/enums";
import EnvService from "../../../../core/services/env-service";
import {
    DynamicContentEditorEntry,
    DynamicContentEditorEntryRecord,
    DynamicContentEditorModels
} from "../../../../packages/dynamic-content-editor/index";
import {DCEEntryNames} from "../../../routes";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";


const CategoriesView = () => {
    /**@type {React.MutableRefObject<HTMLElement>}*/
    const headerRef = useRef();
    const {params, history} = useRouter();
    const [categories, setCategories] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    const parent = useMemo(() => params.id ? allCategories?.find(e => e.Id === parseInt(params.id)) : null, [params.id, allCategories]);

    /**
     * With each change in the parent category:
     * - sets the title of the application to reflect the selected category or the root categories
     */
    useEffect(() => {
        const appName = EnvService.websiteName + ' - ';
        if (!parent) {
            return Utils.setAppInfo({
                title: appName + 'Categories'
            });
        }
        Utils.setAppInfo({
            title: appName + `Sub Categories of ${parent?.Title ?? ''}`
        });
    }, [parent])

    /**
     * As soon as the component mounts:
     * - loads the categories from the server
     */
    useEffect(() => {
        getAllCategories()
    }, [])

    /**
     * Listens for the changes in the params of the url and with each change:
     * - fetches the categories from the server.
     */
    useEffect(() => {
        getCategories();
    }, [params?.id])

    /**
     * Before the paint of the component and with each change in _refBannerHead.current:
     * - sets the height of the header and the marginTop of the banner to change their distance.
     */
    useLayoutEffect(() => {
        if (!headerRef.current) return;
        dispatch(setReduxHeaderHeight(headerRef.current.clientHeight - 50));
        const negativeTop = (headerRef.current.clientHeight - 100) * -1;
        headerRef.current.style.marginTop = negativeTop + 'px'
    }, [headerRef.current])

    /**
     * Loads the categories from the server and sets the loading state to false.
     */
    const getAllCategories = () => {
        controller({
            categoriesResponse: {
                url: partCategoryApis.getAll,
                action: apiMethods.post,
            },
        }).then(({categoriesResponse}) => {
            if (!isMounted()) return;
            if (categoriesResponse?.isPreemptedDueToNotBeingLoggedIn)
                return;
            if (categoriesResponse.Code === 100) {
                setAllCategories(categoriesResponse?.List ?? [])
            }
        });
    }

    /**
     * Loads the categories from the server and sets the loading state to false.
     */
    const getCategories = () => {
        controller({
            categoriesResponse: {
                url: partCategoryApis.getByParentId,
                action: apiMethods.post,
                params: {
                    Data: params.id,
                }
            },
        }).then(({categoriesResponse}) => {
            if (!isMounted()) return;
            setLoading(false)
            if (categoriesResponse?.isPreemptedDueToNotBeingLoggedIn)
                return;
            if (categoriesResponse.Code === 100) {
                setCategories(categoriesResponse?.List ?? [])
            }
        });
    }

    return (
        <>
            <section className={'view-image-header'} ref={headerRef}>
                <Container>
                    <Row>
                        <DynamicContentEditorEntry
                            isRelativePositioned
                            dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.pageBased}
                            entryName={DCEEntryNames.categoriesBanner}
                            _title={'Categories\' Banner'}
                            tag={Col}
                            xs={12}
                            className={'position-relative'}
                        >
                            <DynamicContentEditorEntryRecord
                                recordKey={'image'}
                                _title={'Image'}
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.image}
                                tag={'div'}
                                className={'d-none'}
                            />
                            <DynamicContentEditorEntryRecord
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.element}
                            >
                                {(item) => (
                                    <div className="box" style={{backgroundImage: `url(${item?.image ?? ''})`}}>
                                        <h1>
                                            Categories
                                        </h1>
                                        <p>
                                            {parent?.Title ?? " All Categories"}
                                        </p>
                                    </div>
                                )}
                            </DynamicContentEditorEntryRecord>
                        </DynamicContentEditorEntry>
                    </Row>
                </Container>
            </section>
            <main className={classnames('categories-view', {'back': !!parent})}>
                <Container>
                    {
                        !!parent &&
                        <div
                            className={'d-flex align-items-center mb-3 cursor-pointer-hover'}
                            onClick={history.goBack}>
                            <div>
                                <BackButton className={'back-button mb-1'}/>
                            </div>
                            <p className={'black text-title-xxs mb-0 font-weight-bold'}>
                                Back
                            </p>
                        </div>
                    }
                    {
                        loading
                            ? <Row>
                                <Col xs={12} className={'text-center py-100'}>
                                    <LoadingIndicator/>

                                </Col>
                            </Row>
                            : <Row>
                                {
                                    categories?.map((category) => (
                                        <Col lg={4} md={6} xs={12} key={category?.Id}>
                                            <CategoryBox data={category}/>
                                        </Col>
                                    ))}
                            </Row>
                    }
                </Container>
            </main>
        </>
    );
}

export default CategoriesView;
