import React, {useEffect, useState} from 'react';
import {Alert, Col, Row} from "reactstrap";
import {controller} from "../../../../core/services/api/controller";
import useIsMounted from "../../../hooks/use-is-mounted";
import {userApis} from "../../../../core/constants/endpoints/endpoints";
import TryAgain from "../../../components/app-specific/try-again";
import useAlert from "../../../hooks/use-alert";
import {AlertTypes, ProductBoxTypes} from "../../../../core/constants/enums";
import ProductBox from "../../../components/app-specific/product-box";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";


const SavedItemsView = () => {
    const [parts, setParts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [alert, setAlert] = useAlert()
    const isMounted = useIsMounted();

    /**
     * As soon as the component is mounted:
     * - fetches the bookmarked (saved) products of the application from the server.
     */
    useEffect(() => {
        getBookmarkedProducts();
    }, [])

    /**
     * Fetches the bookmarked (saved) products of the application from the server.
     */
    const getBookmarkedProducts = () => {
        setLoading(true);
        controller({
            response: {
                url: userApis.getBookmarkedParts,
                params: {Object: null}
            }
        }).then(({response}) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setLoading(false);
                return;
            }
            if (response?.Code === 100) {
                setParts(response.List ?? []);
            } else {
                setAlert({
                    color: AlertTypes.error,
                    message: response.Message ?? '',
                    isOpen: true
                })
            }
            setLoading(false);
        })
    }


    return (
        <>
            <Row>
                <Col xs={12} className={'mb-3'}>
                    <div className="heading">
                        Saved Products
                    </div>
                </Col>
                <Alert color={alert.color} isOpen={alert.isOpen}>
                    {alert.message}
                </Alert>
            </Row>
            {
                loading
                    ? <Row>
                        <Col xs={12} className={'text-center py-100'}>
                            <LoadingIndicator/>
                        </Col>
                    </Row>
                    : parts?.length < 1
                        ? <Row>
                            <Col xs={12} className={'text-center py-100'}>
                                <TryAgain
                                    text={"Your saved items list seems to be empty. You may try again."}
                                    buttonText={"Retry"}
                                    onClick={getBookmarkedProducts}
                                />
                            </Col>
                        </Row>
                        : <Row>
                            {parts?.map((item) => (
                                <Col key={item.PartId} xs={12} sm={6} lg={4}>
                                    <div className={'medium-container'}>
                                        <ProductBox
                                            data={item}
                                            type={ProductBoxTypes.list}
                                            fullWidth
                                        />
                                    </div>
                                </Col>
                            ))}
                        </Row>
            }
        </>
    );
}

export default SavedItemsView;
