import React, {useMemo} from "react";
import {
    DataGridActionTypes,
    DataGridColumnAlignments,
    DataGridColumnPinnedTypes,
    DataGridColumnTypes,
    DataGridColumnWidthTypes,
    DataGridExoticColumnFields
} from "../../../packages/data-grid/models";
import moment from "moment";
import Utils from "../../../core/services/utils/utils";
import {Col} from "reactstrap";
import AppDataGrid from "../../containers/data-grid";

const TestView = () => {

    const columns = useMemo(() =>
        /** @type {DataGridColumn[]}*/
        [
            {
                name: DataGridExoticColumnFields.selection,
                alignment: DataGridColumnAlignments.center,
                type: DataGridColumnTypes.element,
                width: {
                    size: 1,
                    minWidth: 100,
                    type: DataGridColumnWidthTypes.flex
                },
                order: 2,
                resizable: false,
                visibilityToggleable: false,
                pinnedToggleable: false,
                reorderable: false,
                sortable: false,
                pinned: true,
            },
            {
                title: "TEST",
                name: 'groupCol',
                alignment: DataGridColumnAlignments.center,
                type: DataGridColumnTypes.string,
                pinned: true,
                pinnedToggleable: false,
                resizable: false,
                reorderable: false,
                sortable: false,
                visible: false,
                visibilityToggleable: false,
            },
            {
                name: DataGridExoticColumnFields.detailedPanelToggler,
                alignment: DataGridColumnAlignments.center,
                type: DataGridColumnTypes.element,
                width: 100,
                resizable: false,
                visibilityToggleable: false,
                pinnedToggleable: false,
                reorderable: false,
                sortable: false,
                pinned: true,
            },
            {
                title: "Column 1",
                name: 'column1',
                alignment: DataGridColumnAlignments.left,
                type: DataGridColumnTypes.dateTime,
                width: 140,
                pinned: false,
            },
            {
                title: "Column 2",
                name: 'column2',
                alignment: DataGridColumnAlignments.right,
                type: DataGridColumnTypes.dateTime,
                width: 100,
                pinned: false,
            },
            {
                title: "Column 3",
                name: 'column3',
                alignment: DataGridColumnAlignments.center,
                type: DataGridColumnTypes.money,
                width: 100,
                pinnedToggleable: {
                    left: false,
                    right: true
                },
            },
            {
                title: "column 4",
                name: 'column4',
                alignment: DataGridColumnAlignments.left,
                type: DataGridColumnTypes.string,
                width: 100,
                pinned: false,
                pinnedToggleable: {
                    left: true,
                    right: false
                },
            },
            {
                title: "column 5",
                name: 'column5',
                alignment: DataGridColumnAlignments.center,
                type: DataGridColumnTypes.string,
                width: 200,
                pinned: true,
                pinnedType: DataGridColumnPinnedTypes.right,
                pinnedToggleable: false,
            },
            {
                title: "column 6",
                name: 'column6',
                alignment: DataGridColumnAlignments.center,
                type: DataGridColumnTypes.string,
                width: 150,
                pinned: true,
                pinnedType: DataGridColumnPinnedTypes.right,
                pinnedToggleable: false,

            },
            {
                name: DataGridExoticColumnFields.detailedPanel,
                visibilityToggleable: false,
            }
        ], [])


    const footerActions = useMemo(() =>
        /** @type {DataGridFooterAction[]}*/
        [
            {
                name: 'action1',
                title: 'Action 1',
                visibilityConstraints: {
                    minSelectedRows: 1,
                    maxSelectedRows: 5,
                },
            },
            {
                name: 'action2',
                title: 'Action 2',
                visibilityConstraints: (selectedRows, excludedRows, allRowsSelected) => {
                    return allRowsSelected || selectedRows.length > 2;
                },
            },
            {
                name: 'action3',
                title: 'Delete',
                type: DataGridActionTypes.delete,
            }
        ], [])

    /**@type {DataGridRow[]}*/
    const rows = useMemo(() => {
        return Array(10).fill(null).map((_, e) => ({
            key: e,
            data: e,
            cells: {
                [DataGridExoticColumnFields.selection]: null,
                'groupCol': {
                    groupValue: e % 2 === 0 ? 1 : 2,
                },
                'column1': {
                    value: moment().toISOString(),
                    editable: true,
                    editableValue: moment(),
                    EditableContentComponent: <></>,
                    groupValue: e % 2 === 0 ? 2 : 1,
                },
                'column2': moment().toISOString(),
                'column3': e * 100,
                'column4': `Row value 4 ${e}`,
                'column5': `Row value 5 ${e}`,
                'column6': `Row value 6 ${e}`,
                [DataGridExoticColumnFields.detailedPanel]: undefined,
            },
        }));
    }, [])

    const onActionInvoked = async (action, selectedRows, excludedRows, allRowsSelected, pagination) => {
        console.log(action, selectedRows, excludedRows, allRowsSelected, pagination)
    }

    const onCellEdited = async (prevValue, newValue, rowKey, colName) => {
        const cell = rows?.find(e => e.key === rowKey)?.cells?.[colName];
        await Utils.wait(1000);
        console.log(cell);
    }

    /**
     * @param {DataGridSelectionEntry | undefined} entry
     */
    const getSingleSelectionFooterText = (entry) => {
        if (!entry)
            return '';
        return `Transaction ${entry.data + 1} selected`;
    }


    return (
        <>
            <Col xs={12} className={'my-5 py-5'}>
                <AppDataGrid
                    rows={rows}
                    columns={columns}
                    footerActions={footerActions}
                    onActionInvoked={onActionInvoked}
                    hidePagination
                    onCellEdited={onCellEdited}
                    singleSelectionOnly
                    getExportInfo={async () => {
                        await Utils.wait(5000);
                    }}
                    getSingleSelectionFooterText={getSingleSelectionFooterText}
                    state={{
                        group: {
                            columnName: "column1",
                            showOnColumn: 'column2',
                        }
                    }}
                />
            </Col>
        </>
    );
}
export default TestView;
