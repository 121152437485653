import React, {useEffect, useState} from "react";
import {AlertTypes} from "../../../core/constants/enums";


const _initAlert = {color: AlertTypes.success, message: '', isOpen: false}

/**
 * Use this hook when you would like the alert closing to be automatically handled.
 * @param {number | undefined} timer
 * @param {{isOpen: boolean | undefined, message: string | undefined, color: string | undefined} | undefined} props
 * @return {[{isOpen: boolean, color: string, message: ''},((value: (((prevState: {isOpen: boolean, color: string, message: ''}) => {isOpen: boolean, color: string, message: ''}) | {isOpen: boolean, color: string, message: ''})) => void),(function(): void)]}
 */
const useAlert = (timer = 2000, props = {}) => {
    const initAlert = {..._initAlert, ...props};
    const [alert, setAlert] = useState(initAlert);

    /**
     * Resets the state of this alert.
     */
    const resetAlert = () => setAlert(initAlert)

    /**
     * Listens for the changes in the alert open state and with each change:
     * - if the alert is open, then sets a timer for the end of which, the alert will be closed.
     */
    useEffect(() => {
        if (!alert?.isOpen) return;
        const timerId = setTimeout(() => {
            setAlert(initAlert)
        }, timer)
        return () => {
            if (timerId) clearTimeout(timerId)
        }
    }, [alert?.isOpen])

    return [alert, setAlert, resetAlert]
}

export default useAlert;
