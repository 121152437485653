import {toast, ToastOptions} from "react-toastify";
import {v4 as UUIDv4} from 'uuid';
import {
    DynamicContentEditorPage,
    DynamicContentEditorPageEntry,
    DynamicContentEditorPageEntryData,
    DynamicContentEditorPageEntryDataRecordValue,
    DynamicContentEditorReadingFileAs
} from "../../index.d";

/**
 * The utility functions in the dynamic-content-editor package.
 */
class DynamicContentEditorUtils {

    //              ########################### COMPARATORS ###################################

    /**
     * Compares two numbers
     * @param a {number}
     * @param b {number}
     */
    static numComparator(a: number, b: number): number {
        if (a === b) return 0;
        if (a < b) return -1;
        return 1
    }

    //              ########################### DCE SPECIFIC ###################################

    /**
     * Parses the entries of the given page data.
     *
     * * injects the isFixed property to each page entries based on the provided [isFixed] argument.
     * * parses the value of each record data if they can be parsed (in case of series type, they definitely will)
     * @param {Array<Omit<DynamicContentEditorPageEntry, 'data'> & {values: DynamicContentEditorPageEntryData}>} list
     * @param {boolean} isFixed
     * @return {DynamicContentEditorPage}
     */
    static parsePageData(
        list: Array<Omit<DynamicContentEditorPageEntry, 'data'> & { values: DynamicContentEditorPageEntryData }>,
        isFixed: boolean
    ): DynamicContentEditorPage {
        if (!list?.length)
            return [];
        return list.map(e => ({
            name: e.name,
            isFixed: isFixed,
            data: e.values?.map(dataRecord => ({
                ...dataRecord,
                value: this.parsePageRecordDataValue(dataRecord?.value),
            })) ?? [],
        }))
    }

    /**
     * Parses the record data value of a page entry.
     *
     * * if the value of the data object is of type array, then parses each entry of the array
     * * if the value of the data object is of type string, the parses the string itself.
     * * if the value is not a parsable string or its array of un-parsable strings, then returns the value itself.
     * @param {DynamicContentEditorPageEntryDataRecordValue} data
     * @return {DynamicContentEditorPageEntryData}
     * @private
     */
    private static parsePageRecordDataValue(data?: DynamicContentEditorPageEntryDataRecordValue)
        : DynamicContentEditorPageEntryData<string> {
        let res: any = data;
        if (Array.isArray(res)) {
            res = res.map(e => {
                try {
                    return JSON.parse(e);
                } catch {
                    return e;
                }
            })
        }
        if (typeof res === 'string') {
            try {
                return JSON.parse(res);
            } catch {
            }
        }
        return res;
    }

    //              ########################### UTILITIES ###################################

    /**
     * Determines if two objects are equal
     * @param object1 {any}
     * @param object2 {any}
     * @return {boolean}
     */
    static deepEqual(object1: any, object2: any): boolean {
        // check if the first one is an array
        if (Array.isArray(object1)) {
            if (!Array.isArray(object2) || object1.length !== object2.length) return false;
            for (let i = 0; i < object1.length; i++) {
                if (!this.deepEqual(object1[i], object2[i])) return false;
            }
            return true;
        }
        // check if the first one is an object
        if (typeof object1 === 'object' && object1 !== null && object2 !== null) {
            if (!(typeof object2 === 'object')) return false;
            const keys = Object.keys(object1);
            if (keys.length !== Object.keys(object2).length) return false;
            for (const key in object1) {
                if (!this.deepEqual(object1[key], object2[key])) return false;
            }
            return true;
        }
        // not array and not object, therefore must be primitive
        return object1 === object2;
    }

    /**
     *  Deep copy an acyclic *basic* Javascript object.  This only handles basic
     * scalars (strings, numbers, booleans) and arbitrarily deep arrays and objects
     * containing these.  This does *not* handle instances of other classes.
     * @param obj {any}
     */
    static deepCopy<T = any>(obj: T): T {
        let ret: any, key;
        let marker = '__deepCopy';

        // @ts-ignore
        if (obj && obj[marker])
            throw (new Error('attempted deep copy of cyclic object'));

        // @ts-ignore
        if (obj && obj.constructor === Object) {
            ret = {};
            // @ts-ignore
            obj[marker] = true;

            for (key in obj) {
                if (key === marker)
                    continue;

                // @ts-ignore
                ret[key] = this.deepCopy(obj[key]);
            }

            // @ts-ignore
            delete (obj[marker]);
            return (ret);
        }

        // @ts-ignore
        if (obj && obj.constructor === Array) {
            ret = [];
            // @ts-ignore
            obj[marker] = true;

            // @ts-ignore
            for (key = 0; key < obj.length; key++)
                ret.push(this.deepCopy(obj[key]));

            // @ts-ignore
            delete (obj[marker]);
            return (ret);
        }
        // It must be a primitive type -- just return it.
        return (obj);
    }


    /**
     * Shows a toast with the given options.
     * @param {string} message
     * @param {ToastOptions} options
     */
    static showToast(message: string, options: ToastOptions) {
        return toast(
            message,
            {
                position: toast.POSITION.TOP_RIGHT,
                ...(options ?? {})
            }
        );
    }

    /**
     * Creates a Unique Identifier in form of a string.
     * @param {boolean} reactKey
     * @return {string}
     */
    static createUUId(reactKey = false): string {
        const uuid = UUIDv4();
        if (!reactKey)
            return uuid;
        return `_${uuid}`
    }

    /**
     * Reads the given file based on the provided method of reading.
     * @param {File | Blob} file
     * @param {DynamicContentEditorReadingFileAs} as
     */
    static readFile(file: File | Blob | undefined, as: DynamicContentEditorReadingFileAs): PromiseLike<string | ArrayBuffer | null> {
        if (!file)
            return Promise.resolve(null);
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            switch (as) {
                case DynamicContentEditorReadingFileAs.text:
                    reader.readAsText(file);
                    break;
                case DynamicContentEditorReadingFileAs.dataUrl:
                    reader.readAsDataURL(file);
                    break;
                case DynamicContentEditorReadingFileAs.arrayBuffer:
                    reader.readAsArrayBuffer(file);
                    break;
                case DynamicContentEditorReadingFileAs.binaryString:
                    reader.readAsBinaryString(file);
                    break;
            }
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

}

export default DynamicContentEditorUtils
