/**
 * The Service that is used to keep the variables used in the .env file.
 */
export default class EnvService {
    /**
     * The Public Url of the application.
     */
    static PublicUrl: string = process.env.PUBLIC_URL ?? "";

    /**
     * Demo's Partner Id
     */
    static DemoPartnerId: string = process.env.REACT_APP_DEMO_PARTNER_ID ?? '';

    /**
     * The base url of our api
     */
    static ApiUrl: string = process.env.REACT_APP_URL_API ?? '';

    /**
     * The Firebase's VAPID key used for notification authentication
     */
    static notificationKey: string = process.env.REACT_APP_NOTIFICATION_VAPID_KEY ?? '';

    /**
     *  The Mapbox access token used for showing maps in the app
     **/
    static mapboxAccessToken: string = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? '';

    /**
     * Whether to allow used to See any payments in their views
     */
    static EnablePayments: boolean = process.env.REACT_APP_ENABLE_PAYMENTS === 'true';

    /**
     * Whether the browser autofill of the inputs in the login form needs to have their own color
     */
    static EnableAutofillDistinction: boolean = process.env.REACT_APP_ENABLE_AUTOFILL_DISTICTION === 'true';

    /**
     * Whether to allow used to See any Customer brands in their auth views
     */
    static ShowCustomerBrands: boolean = process.env.REACT_APP_SHOW_CUSTOMER_BRANDS === 'true';

    /**
     * Whether to allow The forget-password checkboxes to be shown. Used when using the CHTP app for BC and Ontario
     * Simultaneously.
     */
    static ShowForgetPasswordCheckboxes: boolean = process.env.REACT_APP_SHOW_FORGET_PASSWORD_CHECKBOXES === 'true';

    /**
     * Whether to use the new basic search api in the application.
     */
    static useNewBasicSearch: boolean = process.env.REACT_APP_USE_NEW_BASIC_SEARCH === 'true';

    /**
     * Whether to use bizkey tech's pattern style in the homepage view of the application.
     */
    static useBizkeyTechHomepagePattern: boolean = process.env.REACT_APP_HOME_PAGE_PATTERN === 'bizkey-tech';

    /**
     * Whether to use chtp's pattern style in the homepage view of the application.
     */
    static useChtpHomepagePattern: boolean = process.env.REACT_APP_HOME_PAGE_PATTERN === 'chtp';

    /**
     * The name of the page that is used for the privacy policy.
     */
    static policyPageName: string = process.env.REACT_APP_POLICY_PAGE_NAME ?? 'Policy';

    /**
     * Default Title of the application
     */
    static websiteName: string = process.env.REACT_APP_WEBSITE_NAME ?? ''

    /**
     * Default Description of the application
     */
    static websiteDescription: string = process.env.REACT_APP_WEBSITE_DESCRIPTION ?? ''

    /**
     * Determines if the current environment is development.
     */
    static isDevelopment: boolean = !!process.env.NODE_ENV && process.env.NODE_ENV === 'development'


}
