import React, {useMemo, useRef, useState} from "react";
import DynamicContentEditorDropzone from "../index";
import DynamicContentEditorUtils from "../../../../core/services/utils";
import classnames from "classnames";
import {DynamicContentEditorInputFileAcceptMimes} from "../../../../index.d";
import DynamicContentEditorApi from "../../../../core/services/api";
import useIsMounted from "../../../hooks/use-is-mounted";
import {ReactComponent as PlaceholderImage} from '../../../../assets/images/image-placeholder.svg';

const DynamicContentEditorImageDropzone = ({onChange, value, disabled, setLoading}) => {
    const idRef = useRef(DynamicContentEditorUtils.createUUId(true));
    const [tempImage, setTempImage] = useState(undefined);
    const [uploading, setUploading] = useState(false);
    const isMounted = useIsMounted();

    const hasImage = useMemo(() => !!(tempImage ?? value), [tempImage, value]);

    /**
     * Sets the temporary image for the preview purposes.
     * @param {any} parsedImage
     */
    const setParsedImage = ([parsedImage]) => {
        setTempImage(parsedImage);
    }

    /**
     * Fetches the file for uploading purposes.
     * @param {FileList} files
     */
    const uploadImage = async (files) => {
        const file = files.item(0);
        setUploading(true);
        if (setLoading) setLoading(true);
        const response = await DynamicContentEditorApi.uploadFile(file);
        setUploading(false);
        if (setLoading) setLoading(false);
        if (!isMounted())
            return;
        setTempImage(undefined);
        if (response?.resultFlag) {
            onChange(response.configuration?.FileBaseURL?.concat(response.data?.fileName) ?? '')
        }
    }

    /**
     * Removes the image of the dropzone.
     */
    const removeImage = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onChange(undefined)
        setTempImage(undefined);
    }

    return (
        <>
            <DynamicContentEditorDropzone
                openOnClick
                noParse
                setParsedImages={setParsedImage}
                setFiles={uploadImage}
                disabled={disabled}
                loading={uploading}
                uploading={uploading}
                accept={[DynamicContentEditorInputFileAcceptMimes.images]}
                dropZoneClassName={classnames(
                    'dynamic-content-editor-image-dropzone',
                    {'has-image': hasImage}
                )}
                multiple={false}
                id={idRef.current}
                notAcceptedDurationInMs={2000}
            >
                {(_, highlight, notAccepted) => (
                    <>
                        <div className='d-flex justify-content-center'>
                            <div className={classnames('img-container')}>
                                <div className={'background'}>
                                    {
                                        hasImage
                                            ? <img
                                                src={tempImage ?? value ?? ''}
                                                alt=""
                                            />
                                            : <PlaceholderImage/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='dynamic-content-editor-drop-zone-call-to-action'>
                            {
                                !(highlight || notAccepted)
                                    ? 'Click here or Drag and Drop your image'
                                    : notAccepted
                                        ? "This format is not supported"
                                        : "Drop you image here"
                            }
                        </div>
                        {
                            !!value &&
                            <button
                                type={'button'}
                                className={'dynamic-content-editor-dropzone-remove-button'}
                                disabled={disabled || uploading}
                                onClick={removeImage}
                            >
                                Remove this image
                            </button>
                        }
                    </>
                )}
            </DynamicContentEditorDropzone>
        </>
    )
}

export default DynamicContentEditorImageDropzone;
