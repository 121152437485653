import React, {useState} from "react";
import {Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import classnames from "classnames";
import AddGiftCardOrCreditToShoppingCartDialogGiftCardsSection from "./sections/gift-cards";
import AddGiftCardOrCreditToShoppingCartDialogAvailableCreditsSection from "./sections/available-credits";
import {IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";

const AddGiftCardOrCreditToShoppingCartDialog = ({
                                                     open,
                                                     setOpen,
                                                     onSuccess,
                                                     seeCredits = true,
                                                 }) => {
    const [tab, setTab] = useState(1);

    /**
     * Passes the newly updated shopping cart to the onSuccess callback.
     * @param {any} cart the updated cart information.
     * */
    const onGiftOrCreditAdded = (cart) => {
        onSuccess(cart);
        setOpen(false)
    }

    return (
        <>
            <Modal size={tab === 1 ? 'md' : 'lg'}
                   isOpen={open}
                   backdrop centered
                   className={'add-gift-card-or-credit-to-shopping-cart-dialog'}
                   toggle={() => setOpen(false)}>
                <ModalHeader>
                    <div className={'d-flex flex-row justify-content-between align-items-center'}>
                        <div>
                            {
                                seeCredits
                                    ? "Add Gift Card or Credit to Cart"
                                    : "Add Gift Card"
                            }
                        </div>
                        <div>
                            <IconButton aria-label={"cancel"} onClick={() => setOpen(false)}>
                                <Close/>
                            </IconButton>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {
                        seeCredits
                            ? <Nav tabs className={'mb-0 p-0  nav-dialog'}>
                                <NavItem>
                                    <NavLink
                                        className={classnames({'active': tab === 1})}
                                        onClick={() => setTab(1)}>
                                        Gift Cards
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({'active': tab === 2})}
                                        onClick={() => setTab(2)}>
                                        Available Credits
                                    </NavLink>
                                </NavItem>
                                <TabContent activeTab={tab} className={''}>
                                    <TabPane tabId={1}>
                                        <AddGiftCardOrCreditToShoppingCartDialogGiftCardsSection
                                            onSuccess={onGiftOrCreditAdded}
                                        />
                                    </TabPane>
                                    <TabPane tabId={2}>
                                        <AddGiftCardOrCreditToShoppingCartDialogAvailableCreditsSection
                                            onSuccess={onGiftOrCreditAdded}
                                        />
                                    </TabPane>
                                </TabContent>
                            </Nav>
                            : <AddGiftCardOrCreditToShoppingCartDialogGiftCardsSection
                                onSuccess={onGiftOrCreditAdded}
                            />
                    }
                </ModalBody>
            </Modal>
        </>
    )
}


export default AddGiftCardOrCreditToShoppingCartDialog;
