import React, {useLayoutEffect, useMemo, useRef} from "react";
import useWindowViewportWidth from "../../../../../hooks/use-window/viewport-width";
import classnames from "classnames";
import {Link} from "react-router-dom";
import {Fade, SvgIcon, Tooltip} from "@material-ui/core";
import {formatMoney} from "../../../../../../core/services/utils/utils";
import LazyImage from "../../../lazy-lmage";
import {BookmarkBorderOutlined} from "@material-ui/icons";
import {ReactComponent as CompareOutLinedIcon} from "../../../../../../assets/images/parts/compare-outlined.svg";
import TagsContainer from "../../../tags-container";

const ListProductBox = ({
                            data,
                            toggleInCompareList,
                            toggleBookmarked,
                            linkPath,
                            onTagClicked,
                            preventAnimation,
                            preventActionButtons = false,
                        }) => {
    const containerRef = useRef();
    const viewportWidth = useWindowViewportWidth();

    const codeList = useMemo(() => {
        let list = (data?.codeList) ?? []
        const codes = list.map(e => e.code);
        return [...new Set(codes)].map(e => list.find(c => c.code === e));
    }, [data?.codeList])

    const priceNotAvailable = data?.changedPrice <= 0;
    const hasNewPrice = data?.changedPrice < data?.originalPrice;

    /**
     * With each change in viewport width:
     * - Calls the function for preventing the animation being run for action buttons
     */
    useLayoutEffect(() => {
        preventAnimation(containerRef.current);
    }, [viewportWidth])

    return (
        <>
            <div ref={containerRef} className={classnames('product list')}>
                <Link to={linkPath}>
                    <div className={'product-header'}>
                        <div className={'product-header-line'}>
                            <p className={'part-no'}>
                                {data?.partNumber ?? ''}
                            </p>
                            <p className={'category'}>
                                {data?.category?.title ?? data?.category?.Title ?? data?.categoryTitle ?? ''}
                            </p>
                        </div>
                        <div className={'product-header-line'}>
                            {
                                hasNewPrice &&
                                <Fade in={hasNewPrice}>
                                    <p className={'price new'}>
                                        {formatMoney(data?.changedPrice)}
                                    </p>
                                </Fade>
                            }
                            {
                                priceNotAvailable
                                    ? <p className={'price not-available'}>
                                        {/*Price Not Available*/}
                                    </p>
                                    : <p className={classnames('price', {'old': hasNewPrice})}>
                                        {formatMoney(data?.originalPrice)}
                                    </p>
                            }
                        </div>
                    </div>
                    <div className={'product-body'}>
                        <div className={'image'}>
                            <LazyImage
                                src={data?.imageUrl}
                                alt={data?.partNumber}
                                height={170}
                            />
                        </div>
                        <TagsContainer
                            tags={data?.tags}
                            lines={2}
                            onTagClicked={onTagClicked}
                            containerAlwaysVisible
                        />
                        {
                            codeList.length > 0 &&
                            <div className={'product-codes'}>
                                {
                                    codeList.map(code => (
                                        <p key={code?.code}>
                                            {code?.code ?? ''}
                                        </p>
                                    ))
                                }
                            </div>
                        }
                    </div>
                    {
                        !preventActionButtons &&
                        <button
                            className={classnames('button animating-icon-button save',
                                {
                                    'active': data?.saved || data?.isCompared,
                                    'selected': data?.saved
                                })
                            }
                            onClick={toggleBookmarked}>
                            <Tooltip
                                classes={{tooltip: 'product-tool-tip'}}
                                placement={'top-end'}
                                title={
                                    data?.saving
                                        ? 'Loading'
                                        : !data?.saved
                                            ? 'Add to save list'
                                            : 'Remove from save list'
                                }
                            >
                                {
                                    data?.saving
                                        ? <i className={'icon-loader'}/>
                                        : <BookmarkBorderOutlined className={'color'}/>
                                }
                            </Tooltip>
                        </button>
                    }
                    {
                        !preventActionButtons &&
                        <button
                            className={classnames('button animating-icon-button compare', {
                                'active': data?.isCompared || data?.saved,
                                'selected': data?.isCompared
                            })}
                            onClick={toggleInCompareList}>
                            <Tooltip
                                classes={{tooltip: 'product-tool-tip'}}
                                placement={'top-end'}
                                title={
                                    data?.comparing
                                        ? 'Loading'
                                        : data?.isCompared
                                            ? 'Remove from compare list'
                                            : 'Add to compare list'
                                }
                            >
                                {
                                    data?.comparing
                                        ? <i className={'icon-loader'}/>
                                        : <SvgIcon component={CompareOutLinedIcon}/>
                                }
                            </Tooltip>
                        </button>
                    }

                </Link>
            </div>
        </>
    );
}

export default ListProductBox;
