import React, {useEffect, useMemo, useState} from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {loginUser} from "../../../../redux/entities/auth/actions";
import {unAuthorizedDialog} from "../../../../redux/actions";
import routes from "../../../routes";
import useRouter from "../../../hooks/use-router";
import * as Yup from "yup";
import ValidateMessages from "../../../../core/constants/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import EmailInput from "../../base/input/email-input";
import MuiInput from "../../base/input/mui-input";
import Form from "../../base/form";
import {ReactComponent as AlertIcon} from "../../../../assets/images/alert.svg";
import useIsMounted from "../../../hooks/use-is-mounted";

const initAlert = {
    visible: false,
    msg: '',
};
const initAuth = {
    loading: false, authMessage: '', token: null
}

const initDialog = {
    open: false, loginPopupMessage: '',
}

const formKeys = {
    username: 'account',
    password: 'password',
}

const schema = Yup.object().shape({
    [formKeys.username]: Yup.string().trim().nullable().required(ValidateMessages.required),
    [formKeys.password]: Yup.string().trim().nullable().required(ValidateMessages.required),
})

const validate = makeValidate(schema);
const required = makeRequired(schema);


const UnauthorizedDialog = () => {
    const {history, location, query} = useRouter();
    const [alert, setAlert] = useState(initAlert);
    const {loading, authMessage} = useSelector(state => state?.auth ?? initAuth);
    const {open, loginPopupMessage} = useSelector(state => state?.dialogs?.unauthorized ?? initDialog);
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    const initialFormValues = useMemo(() => ({}), []);

    /**
     * Listens to the changes in open, authMessage and loginPopupMessage and with each change:
     * - if not open, the closes the alert too
     * - if open, then sets the of the alert with the following precedence: authMessage >> loginPopuprMessage ??
     * static text.
     */
    useEffect(() => {
        if (!open) {
            return setAlert(initAlert);
        }
        setAlert({
            visible: true,
            msg: authMessage ?? loginPopupMessage,
        })
    }, [open, authMessage, loginPopupMessage])

    /**
     * Navigates the user back to the registration view.
     */
    const navigateToRegistration = () => {
        dispatch(unAuthorizedDialog({open: false}))
        history.replace(routes.auth.signUp);
    }

    /**
     * Dispatches the event associated with logging in the user into the system.
     * @param {any} values the form values.
     */
    const onLoginFormSubmitted = (values) => {
        dispatch(loginUser(values, () => {
            if (!isMounted())
                return;
            history.replace(routes.redirect, {
                location: location,
                query: query,
                hash: location.hash,
            })
        }));
    };

    return (
        <Modal isOpen={open} centered backdrop={'static'} className={'unauthorized-dialog'}>
            <ModalHeader>
                Login Required
            </ModalHeader>
            <ModalBody className={'p-3'}>
                <div className="message-section">
                    <AlertIcon/>
                    <p>
                        {alert.msg ?? 'Please Enter your credentials to continue working with the site.'}
                    </p>
                </div>
                <Form
                    className={'d-flex flex-column w-100'}
                    onSubmit={onLoginFormSubmitted}
                    validate={validate}
                    initialValues={initialFormValues}
                    render={() => {
                        return (
                            <>
                                <p className={'label-title'}>
                                    Email or user name
                                </p>
                                <EmailInput
                                    className={'mb-3'}
                                    setValidating={() => {
                                    }}
                                    form={true}
                                    type={'email'}
                                    shouldValidateWithApiCall={false}
                                    name={formKeys.username}
                                    placeholder={'Email or username '}
                                    required={required.username}
                                />
                                <p className={'label-title'}>
                                    Password
                                </p>
                                <MuiInput
                                    className={'mb-4'}
                                    form
                                    type={'password'}
                                    placeholder={'Password'}
                                    name={formKeys.password}
                                    required={required.password}
                                />
                                <div className="d-flex w-100 justify-content-center">
                                    <button
                                        className={'button primary login-button'}
                                        type={'submit'}
                                    >
                                        {!loading ? "Login" : "Loading..."}
                                    </button>
                                </div>
                            </>
                        )
                    }}
                />
                <div className={'d-flex w-100 justify-content-center'}>
                    <button
                        onClick={navigateToRegistration}
                        className={'button text primary mt-2 create-account-button'}>
                        {
                            loading
                                ? 'Loading...'
                                : 'Create a new account'
                        }
                    </button>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default UnauthorizedDialog;
