import React, {useEffect, useState} from "react";
import {Autocomplete as MuiAutoComplete} from "@material-ui/lab";
import {Autocomplete as FormAutoComplete} from "mui-rff";
import {TextField} from "@material-ui/core";

/**
 * @param {formApi} form
 * @param {boolean} clearOnSelect
 * @param {boolean} doNotSubmitFormChanges
 * @param textFieldProps
 * @param {React.ReactNode} endAdornment
 * @param {boolean} fullWidth
 * @param placeholder
 * @param {import("@material-ui/lab").AutocompleteProps & import("mui-rff").AutocompleteProps} props
 * @param ref
 * @return {JSX.Element}
 */
const AutoComplete = ({
                          clearOnSelect = false,
                          doNotSubmitFormChanges = false,
                          form = undefined,
                          textFieldProps,
                          endAdornment,
                          fullWidth = true,
                          placeholder = '',
                          ...props
                      }, ref) => {
    const [value, setValue] = useState(props.value ?? null);

    /**
     * With each change in the value:
     * - if clearOnSelect, then removes the state value (if Form, uses the form api for it)
     */
    useEffect(() => {
        if (value && clearOnSelect) {
            setValue(null);
            form?.resetFieldState(props.name);
        }
    }, [value])

    /**
     * Changes the value of the autocomplete (form or non-form) and then calls the onChange property of the props if
     * it exists.
     * @param {React.ChangeEvent} e
     * @param {any} value
     * @param {import('@material-ui/lab').AutocompleteChangeReason} reason
     */
    const onChanged = (e, value, reason) => {
        if (props.onChange) {
            props.onChange(e, value, reason);
        }
        if (!doNotSubmitFormChanges)
            form?.change(props.name, value);
        setValue(value);
    }


    return (
        <>
            {
                !form
                    ? <MuiAutoComplete
                        ref={ref}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant={'outlined'}
                                {...textFieldProps}
                                placeholder={placeholder}
                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <>
                                            {params.InputProps.endAdornment}
                                            {endAdornment}
                                        </>
                                    ),
                                }}
                            />
                        )}
                        {...props}
                        fullWidth={fullWidth}
                        onChange={onChanged}
                        value={value}
                    />
                    : <FormAutoComplete
                        ref={ref}
                        placeholder={placeholder}
                        {...props}
                        textFieldProps={{
                            variant: 'outlined',
                            ...textFieldProps,
                            InputProps: {
                                endAdornment: endAdornment,
                                ...(textFieldProps?.InputProps ?? {}),
                            },
                        }}
                        fullWidth={fullWidth}
                        onChange={onChanged}
                    />

            }
        </>
    )
}

export default React.forwardRef(AutoComplete);
