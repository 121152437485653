import React, {useEffect, useMemo, useRef, useState} from 'react';
import classnames from "classnames";
import useIsMounted from "../../../hooks/use-is-mounted";
import {getFirstScrollingAncestor} from "../../../../core/services/utils/utils";
import ObserversService from "../../../../core/services/observers-service";

const LazySvg = ({
                     src,
                     noSvg,
                     height: heightProps,
                     width: widthProps = heightProps,
                     onError,
                     onLoad,
                     ...props
                 }) => {
    const [svg, setSvg] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [error, setError] = useState(false);
    const isMounted = useIsMounted();
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const ancestorFinderRef = useRef();
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const elementRef = useRef();

    const height = useMemo(() => typeof heightProps === 'function' ? heightProps() : heightProps, [heightProps])
    const width = useMemo(() => typeof widthProps === 'function' ? widthProps() : widthProps, [widthProps])

    /**
     * With each change in src:
     * - attaches an intersection observer that would load the svg only when visible.
     */
    useEffect(() => {
        /**@type {IntersectionObserverInit}*/
        const config = {
            root: getFirstScrollingAncestor(ancestorFinderRef.current),
            rootMargin: '20%',
            threshold: 0,
        }
        const observer = ObserversService.newIntersectionObserver(observeIntersections, config);
        ObserversService.observeIntersectionObserver(observer, elementRef.current);
        return () => ObserversService.disconnectIntersectionObserver(observer);
    }, [src, ancestorFinderRef, noSvg]);

    /**
     * Observes the intersection of the current svg with the viewport and loads the svg as soon as the element is
     * inside the viewport.
     *
     * @param {IntersectionObserverEntry[]} entries
     * @param {IntersectionObserver} observer
     */
    const observeIntersections = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                fetchSvg().then();
                observer.unobserve(entry.target);
            }
        });
    }

    /**
     * Loads the svg from the given src and sets the state.
     * @return {Promise<void>}
     */
    const fetchSvg = async () => {
        setLoaded(false);
        setError(false);
        try {
            const response = await fetch(src);
            if (response.status !== 200) {
                throw '';
            }
            const svg = await response.text();
            if (!isMounted())
                return
            setSvg(svg)
            if (onLoad) onLoad(elementRef.current);
        } catch (e) {
            if (onError) onError();
            if (isMounted()) setError(true);
        } finally {
            if (isMounted()) setLoaded(true);
        }
    }

    return (
        <>
            <div ref={ancestorFinderRef} style={{display: "none"}}/>
            <div ref={elementRef}  {...props}>
                {
                    error &&
                    (
                        React.isValidElement(noSvg)
                            ? noSvg
                            : <React.Fragment/>
                    )
                }
                {
                    !error &&
                    <span className={'lazy-svg-background'} style={{height: height, width: width}}>
                    {
                        <div
                            className={classnames('lazy-svg', {'lazy-svg--loaded': loaded},)}
                            style={{height: height, width: width}}
                            dangerouslySetInnerHTML={{__html: svg}}
                        />
                    }
                </span>
                }
            </div>
        </>
    );
}

export default LazySvg;
