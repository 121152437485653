import {generatePath} from "react-router";
import {flattenObjectAndReturnAsAList} from "../../core/services/utils/utils";

/**
 * The map of all the available routes in the system
 */
const routes = {
    landing: '/',
    test: '/test',
    error: '/error',
    redirect: '/redirect',
    dynamicContentEditor: '/dynamic-content-editor',
    publicLinks: {
        partInformation: '/Parts/:id/:publicToken', // TODO: prepend /public-link/ to this routes but needs partner to change too
        technicalFile: '/:id/technical-files/:token', // TODO: prepend /public-link/ to this routes but needs partner to change too
        partSupportExtraInfo: '/part-support-extra-info/:token',
    },
    auth: {
        base: '/entry',
        login: '/entry/login',
        forgetPassword: '/entry/forget-password',
        signUp: '/entry/sign-up',
        resetPassword: '/reset-password/:token',
        deleteAccount: '/delete-account',
    },
    main: {
        products: '/Products',
        partInformation: '/Parts/:id',
        technicalFile: '/Parts/:id/technical-files/:id',
        shoppingCart: '/shopping-cart',
        multiInvoicePayment: '/multi-invoice-payment',
        categories: '/categories/:id?',
        survey: '/survey',
    },
    print: {
        invoice: '/print/invoice/:id',
        credit: '/print/credit/:id',
    },
    public: {
        about: '/about-us',
        catalogue: '/catalogue',
        page: '/page/:slug',
        content: {
            base: '/content',
            main: '/content/main',
            allVideos: '/content/main/videos',
            allBlogs: '/content/main/blogs',
            searchResults: '/content/search',
            blog: '/content/blog/:slug',
            video: '/content/video/:slug',
        },
        products: '/public/products',
        partInformation: '/public/products/:id',
    },
    dashboard: {
        base: '/dashboard',
        savedItems: '/dashboard/saved-items',
        paymentMethods: {
            base: '/dashboard/payment-methods',
            transactionsSummary: '/dashboard/payment-methods/transactions/:cardId'
        },
        userManagement: '/dashboard/user-management',
        profile: '/dashboard/profile',
        address: '/dashboard/address',
        orders: '/dashboard/orders',
        compare: '/dashboard/compare',
    },

};

/**
 * The map of all the available routes in the system that include route parameters.
 * This map has been created using generatePath method belonging to react-router.
 */
export const routeFunctions = {
    main: {
        // none has to be here since if there is no value, generatePath throws fatal error
        single: (partNumber) => generatePath(routes.main.partInformation, {id: partNumber ?? 'none'}),
        technicalFile: (partNumber, id) => generatePath(routes.main.technicalFile, {id: partNumber, token: id}),
        categories: (id) => generatePath(routes.main.categories, {id: id})
    },
    paymentMethods: {
        transactionsSummary: (cardId) => generatePath(routes.dashboard.paymentMethods.transactionsSummary, {cardId: cardId})
    },
    public: {
        about: (fragmentIdentifier) => `${routes.public.about}#${fragmentIdentifier ?? ''}`,
        blogs: {
            blog: (slug) => generatePath(routes.public.content.blog, {slug: slug}),
            video: (slug) => generatePath(routes.public.content.video, {slug: slug}),
        },
        partInformation: (partNumber) => generatePath(routes.public.partInformation, {id: partNumber}),
    },
    print: {
        invoice: (id) => generatePath(routes.print.invoice, {id: id}),
        credit: (id) => generatePath(routes.print.credit, {id: id}),
    },
}

/**
 * Aggregates all the available routes in the application into specific route lists.
 *
 * These properties are useful for determining the path of the <Route /> component or determining a route group when
 * applying logic.
 */
export const routeLists = {
    blogs: flattenObjectAndReturnAsAList(routes.public.content),
    public: flattenObjectAndReturnAsAList(routes.public),
    publicLinks: flattenObjectAndReturnAsAList(routes.publicLinks),
    auth: flattenObjectAndReturnAsAList(routes.auth),
    main: flattenObjectAndReturnAsAList(routes.main),
    dashboard: flattenObjectAndReturnAsAList(routes.dashboard),
    print: flattenObjectAndReturnAsAList(routes.print),
}

/**
 * Routes specific for the theme editor functionality of the application. These routes have been altered and in case
 * of any breaking, their original values must be used
 */
export const DCERoutes = {
    about: 'about',
    categories: 'categories',
    catalogues: 'catalogues',
    auth: 'auth',
}

/**
 * The Entry Names used in the DCE sections of the application.
 */
export const DCEEntryNames = {
    headerNavbar: 'header-navbar',
    footerSocials: 'footer-socials',
    footerAddress: 'footer-address',
    footerCertification: 'footer-certification',
    footerNavbar: 'footer-navbar',
    aboutUsGeneralInfo: "about-us-general-info",

    entryVideo: 'entry-video',
    entryBrandsDisclaimer: 'entry-brands-disclaimer',
    entryBrands: 'entry-brands',

    aboutUsPoints: 'about-us-points',
    aboutUsUurStory: 'about-us-our-story',
    aboutUsResellerLocator: 'about-us-reseller-locator',
    aboutUsTeam: 'about-us-team',
    aboutUsCatalogues: 'about-us-catalogues',

    categoriesBanner: "categories-banner",

    catalogues: "catalogues",
}

export default routes;

