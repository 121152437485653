import {createContext} from "react";

/**
 * @typedef {{enforcedHeight: number}} ProductContextInitialValues
 */

/**
 * Initial values for the product context.
 *
 * - stores the height of the products that they must use for their layout.
 * @type {ProductContextInitialValues}
 */
export const ProductContextInitialValues = {enforcedHeight: 0};

/**
 * The context used for product boxes.
 * @type {React.Context<ProductContextInitialValues>}
 */
const ProductContext = createContext(ProductContextInitialValues);


export default ProductContext;

