import shoppingCartReduxSlice from './reducer';

const actions = shoppingCartReduxSlice.actions;

/**
 * Sets the shopping cart count.
 *
 * this method is used in the header of the application or in the shopping cart view to sync the count of the
 * shopping cart with the server.
 *
 * @param {number} count the new count of the shopping cart.
 */
export const setShoppingCartCount = (count) => (dispatch) => dispatch(actions.setCount(count));

/**
 * Updates the shopping cart count.
 *
 * this method is used in the header of the application or in the shopping cart view to sync the count of the
 * shopping cart with the server.
 *
 * @param {number} change the modifier of shopping cart count.
 */
export const updateShoppingCartCount = (change) => (dispatch) => dispatch(actions.updateCount(change));
