import {Col} from "reactstrap";
import LazyImage from "../../lazy-lmage";
import BlogDateIcon from "../../../../../assets/images/blogs/date-icon.svg";
import moment from "moment";
import React, {useLayoutEffect} from "react";
import routes, {routeFunctions} from "../../../../routes";
import useRouter from "../../../../hooks/use-router";
import {htmlToString} from "../../../../../core/services/utils/utils";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {ReactComponent as ReadMoreIcon} from "../../../../../assets/images/read-more-icon.svg";
import ContentShareButtons from "../../content-share-buttons";


const RandomBlog = ({data, loading}) => {
    const {history} = useRouter();
    const isMounted = useIsMounted();

    const randomBlogLink = data
        ? window.location.origin + routeFunctions.public.blogs.blog(data?.slug)
        : window.location.origin + routes.public.content.main;

    /**
     * Listens for the changes in random blog and with each change just before the layout is pained:
     * - inserts the readMore button into the description of the blog as soon as the randomBlog appears on the screen.
     */
    useLayoutEffect(() => {
        setTimeout(() => {
            if (!isMounted()) return;
            const p = document.getElementById('desc');
            if (!p) return;
            p.innerHTML = htmlToString(data?.bodyContent ?? '', 400);
            if (p.classList.contains('w-100')) return;
            p.classList.add('w-100')
        }, 50)
    }, [data])

    /**
     * Navigates to the specified blog using their slug.
     * @param blog {any}
     */
    const navigateToBlog = (blog) => {
        history.push(routeFunctions.public.blogs.blog(blog?.slug));
    }

    return (
        <>
            {
                loading
                    ? <>
                        <Col xs={12} md={7} className={'order-1 order-md-2 h-100'}>
                            <div className={'loading image'}>
                                <div/>
                            </div>
                        </Col>
                        <Col xs={12} md={5} className={'order-2 order-md-1'}>
                            <div className={'loading extra-small mb-4'}>
                                <div/>
                            </div>
                            <div className={'loading extra-extra-small'}>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                                <div/>
                            </div>
                        </Col>
                    </>
                    : data &&
                    <div className={'w-100 d-flex flex-wrap random-blog'}>
                        <Col xs={12} md={7} className={'order-1 order-md-2 h-100'}>
                            <div className={'image'}>
                                <LazyImage src={data?.coverImage?.srcUrl} alt="blog's image"/>
                            </div>
                        </Col>
                        <Col xs={12} md={5} className={'order-2 order-md-1'}>
                            <div className={'mt-3'}>
                                <h1 className={'title'}>
                                    {data?.title ?? ''}
                                </h1>
                            </div>
                            <div className={'d-flex align-items-center'}>
                                <img className={'mr-1'} style={{marginLeft: -2}} src={BlogDateIcon} alt="date icon"/>
                                <p className={'date'}>
                                    {data?.lastModifyDateTime ? moment(data?.lastModifyDateTime).format('MMM DD, YYYY') : ''}
                                </p>
                            </div>

                            <div className={'text-wrap d-flex flex-wrap mt-2 cursor-pointer-hover'}>
                                <div
                                    id={'desc'}
                                    className={'description'}
                                    onClick={() => navigateToBlog(data)}
                                />
                            </div>
                            <div className={'d-flex flex-wrap justify-content-between align-items-center'}>
                                <ContentShareButtons
                                    tag={'div'}
                                    className={'mt-2 d-flex align-items-center ml-4'}
                                    title={data?.title}
                                    slug={data?.slug}
                                    link={randomBlogLink}
                                />
                                <div onClick={() => navigateToBlog(data)}
                                     className={'cursor-pointer-hover read-more mt-2'}>
                                    Read more
                                    <ReadMoreIcon className={'ml-2'}/>
                                </div>
                            </div>
                        </Col>
                    </div>
            }
        </>

    );
}

export default RandomBlog;
