import React, {useLayoutEffect} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import HomeView from './home';
import CategoriesView from './categories';
import PartInformationView from './part-information';
import ProductsView from './products';
import routes from "../../routes";
import ShoppingCartView from "./shopping-cart";
import {setReduxHeaderType} from "../../../redux/entities/header/actions";
import {HeaderTypes} from "../../../core/constants/enums";
import {useDispatch} from "react-redux";
import useRouter from "../../hooks/use-router";
import MultiInvoicePaymentView from "./multi-invoice-payment";
import EnvService from "../../../core/services/env-service";
import SurveyView from "./survey";


const MainViews = () => {
    const {location} = useRouter();
    const dispatch = useDispatch();

    /**
     * Listens to the changes in url pathname and with each change:
     * - sets the header type to logged-in as these routes are private
     */
    useLayoutEffect(() => {
        dispatch(setReduxHeaderType(HeaderTypes.loggedIn));
    }, [location?.pathname]);

    return (
        <Switch>
            <Route path={routes.landing} exact>
                <HomeView/>
            </Route>
            <Route path={routes.main.categories} exact>
                <CategoriesView/>
            </Route>
            <Route path={routes.main.survey} exact>
                <SurveyView/>
            </Route>
            <Route path={routes.main.partInformation} exact>
                <PartInformationView/>
            </Route>
            <Route path={routes.main.products} exact>
                <ProductsView/>
            </Route>
            {
                EnvService.EnablePayments
                    ? <>
                        <Route path={routes.main.shoppingCart} exact>
                            <ShoppingCartView/>
                        </Route>
                        <Route path={routes.main.multiInvoicePayment} exact>
                            <MultiInvoicePaymentView/>
                        </Route>
                    </>
                    : <Redirect to={routes.error}/>
            }
        </Switch>
    );
}

export default MainViews;

