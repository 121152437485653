import React, {useEffect} from "react";
import {Route, Switch} from "react-router-dom";
import routes, {routeLists} from "../../routes";
import AboutUsView from "./about-us";
import CatalogueView from "./catalogue";
import PageView from "./page";
import ContentViews from "./content";
import {setReduxHeaderType} from "../../../redux/entities/header/actions";
import CacheService from "../../../core/services/cache/cache-service";
import {HeaderTypes} from "../../../core/constants/enums";
import useRouter from "../../hooks/use-router";
import {useDispatch} from "react-redux";
import PublicProductsView from "./products";
import PartInformationView from "../main/part-information";

const PublicViews = () => {
    const {location} = useRouter();
    const dispatch = useDispatch();

    /**
     * listens for the changes in the location urls and with each change:
     * - determines the header type by examining the authentication state of the user.
     */
    useEffect(() => {
        dispatch(setReduxHeaderType(CacheService.isLoggedIn()
            ? HeaderTypes.loggedIn
            : HeaderTypes.loggedOut
        ))
    }, [location?.pathname])

    return (
        <>
            <Switch>
                <Route path={routes.public.about} exact>
                    <AboutUsView/>
                </Route>
                <Route path={routes.public.catalogue} exact>
                    <CatalogueView/>
                </Route>
                <Route path={routes.public.page} exact>
                    <PageView/>
                </Route>
                <Route path={routeLists.blogs} exact>
                    <ContentViews/>
                </Route>
                <Route path={routes.public.products} exact>
                    <PublicProductsView/>
                </Route>
                <Route path={routes.public.partInformation} exact>
                    <PartInformationView/>
                </Route>
            </Switch>
        </>
    )
}

export default PublicViews;
