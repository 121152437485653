import React from "react";
import {matchPath, NavLink} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../../redux/entities/auth/actions";
import routes from "../../routes";
import LocalStorageService from "../../../core/services/cache/local-storage-service";
import classNames from "classnames";
import {ReactComponent as EditIcon} from "../../../assets/images/edit.svg";
import {ReactComponent as ComparisonIcon} from "../../../assets/images/parts/compare.svg";
import {profileInfoInitialState} from "../../../redux/entities/profile/reducer";
import useRouter from "../../hooks/use-router";
import {Bookmark} from "@material-ui/icons";
import LazyImage from "../../components/app-specific/lazy-lmage";


const Sidebar = ({remove}) => {
    const {location, history} = useRouter();
    const infoUser = useSelector(state => state?.profile ?? profileInfoInitialState);
    const dispatch = useDispatch();

    const isAdmin = LocalStorageService.get(LocalStorageService.keys.hasFullAccessLevel) === 'true';
    const isProfilePage = !!matchPath(location.pathname, {
        path: routes.dashboard.profile,
        exact: true
    });

    /**
     * Logs the user out of this system.
     */
    const logout = () => {
        dispatch(logoutUser(history));
    }

    return (
        <>
            <aside className={classNames(`dashboard-sidebar`, {'remove': !!remove})}>
                <div className={classNames("profile", {
                    'default': infoUser.isImagePlaceholder,
                })}>
                    <div className={classNames("avatar")}>
                        <div className={'img'}>
                            <LazyImage
                                src={infoUser.profileFileName}
                                alt={infoUser.lastName}
                                height={'100%'}
                            />
                        </div>
                        <button
                            className={classNames(`button primary edit`, {'active': isProfilePage})}
                            onClick={() => history.push(routes.dashboard.profile)}>
                            <EditIcon/>
                        </button>
                    </div>
                    <div className="text">
                        <p>
                            {(infoUser.firstName ?? 'Dear') + ' ' + (infoUser.lastName ?? 'user')}
                        </p>
                    </div>

                </div>
                <div className="menu">
                    <ul>
                        <li>
                            <NavLink
                                to={routes.dashboard.savedItems}
                                activeclassname="active"
                                exact>
                                <div className={'d-flex flex-row justify-content-start align-items-center'}>
                                    <Bookmark className={'sidebar-icon'}/>
                                    <p className={'ml-2 mb-0'}>Saved Items</p>
                                </div>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={routes.dashboard.address}
                                activeclassname="active"
                                exact>
                                <i className={'icon-pin_fill'}/>
                                Addresses
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={routes.dashboard.compare}
                                activeclassname="active"
                                exact>
                                <ComparisonIcon className={'compare'}/>
                                Comparison list
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={routes.dashboard.orders}
                                activeclassname="active"
                                exact>
                                <i className={'fa fa-list'}/>
                                Orders
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={routes.dashboard.paymentMethods.base}
                                activeclassname="active"
                            >
                                <i className='fa fa-credit-card'/>
                                Payment Methods
                            </NavLink>
                        </li>
                        {
                            !!isAdmin &&
                            <li>
                                <NavLink
                                    to={routes.dashboard.userManagement}
                                    activeClassName="active"
                                    exact>
                                    <i className='fa fa-users'/>
                                    User Management
                                </NavLink>
                            </li>
                        }
                    </ul>
                </div>
                <button
                    className={'mt-auto button primary outlined py-2 px-5'}
                    onClick={logout}>
                    Logout
                </button>
            </aside>
        </>
    );
}


export default Sidebar;
