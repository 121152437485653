import React, {useEffect, useState} from "react";
import Form from "../../../base/form";
import {Col, Row} from "reactstrap";
import MuiInput from "../../../base/input/mui-input";
import MuiPhoneInput from "../../../base/input/mui-phone-input";
import MuiSelect from "../../../base/select";
import MuiCheckbox from "../../../base/check-box";
import * as Yup from "yup";
import ValidateMessages from "../../../../../core/constants/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import {apiMethods, LandingPageFormTypes} from "../../../../../core/constants/enums";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {controller} from "../../../../../core/services/api/controller";
import YupExtensions from "../../../../../core/services/yup-extensions";

YupExtensions.phoneNo();

const schema = Yup.object().shape({
    firstName: Yup.string().nullable().required(ValidateMessages.required),
    lastName: Yup.string().nullable().required(ValidateMessages.required),
    email: Yup.string().email("Invalid Email Format").nullable().required(ValidateMessages.required),
    phone: Yup.string().trim().nullable()
        .required(ValidateMessages.required)
        .phoneNo(ValidateMessages.incorrectType("phone"), 10),
    password: Yup.string().nullable().required(ValidateMessages.required),
    confirmPassword: Yup.string().nullable().required(ValidateMessages.required),
    company: Yup.string().nullable().required(ValidateMessages.required),
    companyPhone: Yup.string().trim().nullable()
        .required(ValidateMessages.required)
        .phoneNo(ValidateMessages.incorrectType("phone"), 10),
    country: Yup.object().nullable().required(ValidateMessages.required),
    province: Yup.object().nullable().required(ValidateMessages.required),
    city: Yup.string().nullable().required(ValidateMessages.required),
    address: Yup.string().nullable().required(ValidateMessages.required).max(100, "Address is too long."),
    postalCode: Yup.string().nullable().required(ValidateMessages.required),
    checkbox: Yup.boolean().nullable().default(false).required(ValidateMessages.acceptPrivacyPolicy),
});

/**
 *
 * @param onSubmit
 * @param validating
 * @param setValidating
 * @param registering
 * @param changeLoginRegister
 * @param onCheckBoxChanged
 * @returns {JSX.Element}
 * @constructor
 */
const RegisterNewUserForm = ({
                                 onSubmit,
                                 validating,
                                 setValidating,
                                 registering,
                                 changeLoginRegister,
                                 onCheckBoxChanged
                             }) => {
    const [countryAndState, setCountryAndState] = useState({Countries: [], Provinces: []})
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const isMounted = useIsMounted();


    /**
     * As soon as the component mounts: fetches the country and states of the application.
     */
    useEffect(() => {
        getCountriesAndProvinces().then();
    }, [])

    /**
     * Fetches the countries and provinces from the server.
     * @return {Promise<void>}
     */
    const getCountriesAndProvinces = async () => {
        const response = await controller({
            "CountryAndState": {
                url: 'Form/CountryAndState',
                action: apiMethods.get,
                loginRequired: false,
            }
        }).then(({CountryAndState}) => {
            let response;
            if (CountryAndState.Code === 100) {
                response = Array.isArray(CountryAndState.List) ? CountryAndState.List[0] : {};
            } else {
                response = {}
            }
            return response;
        });
        if (!isMounted())
            return;
        setCountryAndState(response)
    }


    return (
        <Form
            className='register-form'
            onSubmit={onSubmit}
            validate={validate}
            initialValues={{}}
            render={({values, form}) => {
                return (
                    <>
                        <Row>
                            <Col xs={6} className={'mb-3'}>
                                <MuiInput
                                    form
                                    label={"Firstname"}
                                    placeholder={'Firstname'}
                                    name={'firstName'}
                                    required={required.firstName}
                                />
                            </Col>
                            <Col xs={6} className={'mb-3'}>
                                <MuiInput
                                    form
                                    label={"Lastname"}
                                    placeholder={'Lastname'}
                                    name={'lastName'}
                                    required={required.lastName}
                                />
                            </Col>
                            <Col xs={12} className={'mb-3'}>
                                <MuiInput
                                    form
                                    label={"Email"}
                                    type={'email'}
                                    placeholder={'Email'}
                                    name={'email'}
                                    required={required.email}
                                />
                            </Col>
                            <Col xs={12} className={'mb-3'}>
                                <MuiPhoneInput
                                    type={'tel'}
                                    label={"Phone"}
                                    placeholder={'Phone'}
                                    name={'phone'}
                                    required={required.phone}
                                    value={values['phone']}
                                    onChange={(e) => form.change(e.target.name, e.target.value)}
                                    form={form}
                                />
                            </Col>
                            <Col xs={12} className={'mb-3'}>
                                <MuiInput
                                    form
                                    label={"Company"}
                                    placeholder={'Company'}
                                    name={'company'}
                                    required={required.company}
                                />
                            </Col>
                            <Col xs={12} className={'mb-3'}>
                                <MuiPhoneInput
                                    type={'tel'}
                                    label={"Company Phone"}
                                    placeholder={'Company Phone'}
                                    name={'companyPhone'}
                                    required={required.companyPhone}
                                    value={values['companyPhone']}
                                    onChange={(e) => form.change(e.target.name, e.target.value)}
                                    form={form}
                                />
                            </Col>
                            <Col xs={12} className={'mb-3'}>
                                <MuiInput
                                    form
                                    label={"Address"}
                                    placeholder={'Address'}
                                    name={'address'}
                                    required={required.address}
                                />
                            </Col>
                            <Col xs={6} className={'mb-3'}>
                                <MuiSelect
                                    form
                                    label={"Country"}
                                    name={'country'}
                                    required={!!required.country}
                                    data={countryAndState?.Conutries?.map(e => ({
                                        label: e.Title,
                                        id: e.Id,
                                        value: e,
                                    })) ?? []}
                                />
                            </Col>
                            <Col xs={6} className={'mb-3'}>
                                <MuiSelect
                                    form
                                    label={"Province"}
                                    name={'province'}
                                    required={!!required.province}
                                    data={countryAndState
                                        ?.Provinces
                                        ?.filter(e => e.CountryId === values?.country?.Id)
                                        ?.map(e => ({
                                            label: e.Title,
                                            id: e.Id,
                                            value: e,
                                        })) ?? []}
                                />
                            </Col>
                            <Col xs={6} className={'mb-3'}>
                                <MuiInput
                                    form
                                    placeholder={'City'}
                                    label={"City"}
                                    name={'city'}
                                    required={required.city}
                                />
                            </Col>
                            <Col xs={6} className={'mb-3'}>
                                <MuiInput
                                    form
                                    label={"Postal Code"}
                                    placeholder={'Postal Code'}
                                    name={'postalCode'}
                                    required={required.postalCode}
                                />
                            </Col>
                            <Col xs={6} className={'mb-3'}>
                                <MuiInput
                                    form
                                    label={"Password"}
                                    type={'password'}
                                    placeholder={'Password'}
                                    name={'password'}
                                    required={required.password}
                                />
                            </Col>
                            <Col xs={6} className={'mb-3'}>
                                <MuiInput
                                    form
                                    label={"Re-password"}
                                    type={'password'}
                                    placeholder={'re-password'}
                                    name={'confirmPassword'}
                                    required={required.confirmPassword}
                                />
                            </Col>
                            <Col xs={12} className={'form-check-label'}>
                                <MuiCheckbox
                                    form
                                    name={'checkbox'}
                                    required={required.checkbox}
                                    onChange={(_, c) => onCheckBoxChanged(c, form)}
                                    data={{
                                        value: values?.checkbox ?? false,
                                        label: (
                                            <p className={'label'}>
                                                privacy <span>policy</span>
                                            </p>
                                        )
                                    }}
                                />
                            </Col>
                            <Col xs={12}>
                                <div className="d-flex justify-content-center ">
                                    <button className={'button primary px-5 m-auto'}
                                            disabled={!!validating || registering}
                                            type={'submit'}>
                                        {!!validating
                                            ? 'Validating...'
                                            : registering
                                                ? "submitting..."
                                                : "Register"
                                        }
                                    </button>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className={'d-flex justify-content-center'}>
                                    <p className={'alternate-action'}>
                                        Already have an account?
                                        <span onClick={() => changeLoginRegister(LandingPageFormTypes.login)}>
                                            Log In
                                        </span>
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </>
                );
            }}
        />
    );
}

export default RegisterNewUserForm;
