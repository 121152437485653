import authReduxSlice from './reducer';

const actions = authReduxSlice.actions;

/**
 * Logs the user into the system with the provided user information and success callback.
 * @param {any} user                        the user info
 * @param {function(): void} onLoginSuccess the callback to be called when the login is successful.
 * @param {function(): void} onLoginFailed  the callback to be called when the login is failed.
 */
export const loginUser = (user, onLoginSuccess = null, onLoginFailed = null) => (dispatch) => dispatch(actions.loginUser({
    user,
    onLoginSuccess,
    onLoginFailed,
}));

/**
 * Saves the auth state of the logged-in user in the redux state.
 * @param {string} token users' authentication token.
 */
export const loginUserSuccess = (token) => (dispatch) => dispatch(actions.loginUserSuccess(token));

/**
 * Saves the reason of login failed api states in the redux state.
 * @param {string} message the reason.
 */
export const loginUserFailed = (message) => (dispatch) => dispatch(actions.loginUserFailed(message));

/**
 * Removes the user auth information from the redux state.
 * @param {History} history the history object.
 * @param {boolean} clearCache whether the user needs to clear the cache.
 */
export const logoutUser = (history, clearCache = true) => (dispatch) => dispatch(actions.logoutUser({
    history,
    clearCache
}));
