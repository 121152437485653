import React, {useEffect, useState} from 'react';
import {Prompt} from 'react-router-dom';
import useRouter from "../../../hooks/use-router";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";

const RouteLeavingGuard = ({
                               when = true,
                               navigate,
                               shouldBlockNavigation,
                               confirmationTitle,
                               confirmationMessage,
                           }) => {
    const {history} = useRouter();
    const [openPrompt, setOpenPrompt] = useState(false);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const [pendingRoute, setPendingRoute] = useState();
    const title = confirmationTitle ?? "Leave the Page?";
    const message = confirmationMessage ?? "Changes you made may not be saved. Are your sure you want to proceed?";

    /**
     * With each change in values of [pendingRoute] and [confirmedNavigation]:
     * - if the dialog is open, and we have a pending route, we either do the routing or use the provided [navigate]
     * callback.
     */
    useEffect(() => {
        if (!confirmedNavigation || !pendingRoute)
            return;
        if (navigate) {
            navigate(pendingRoute.location, pendingRoute.action);
        } else {
            switch (pendingRoute.action) {
                case "PUSH":
                    history.push(pendingRoute.location);
                    break;
                case "POP":
                    history.goBack();
                    break;
                case "REPLACE":
                    history.replace(pendingRoute.location);
                    break
                default:
                    break;
            }
        }
        setPendingRoute(undefined);
        setConfirmedNavigation(false);
    }, [confirmedNavigation, pendingRoute]);

    /**
     * Adds the confirmation trigger for default browser behaviour on reload or back-button click confirmation
     */
    useEffect(() => {
        if (!when)
            return clearWindowEventListeners;
        window.addEventListener('beforeunload', triggerConfirmationForBeforeunload, {capture: true});
        return clearWindowEventListeners;
    }, [when])

    /**
     * Clears the attached event listeners of the window.
     */
    const clearWindowEventListeners = () => {
        window.removeEventListener('beforeunload', triggerConfirmationForBeforeunload, {capture: true});
    }

    /**
     * Triggers browsers' confirmation popup in case of leaving the page or reloading the page.
     * @param e
     * @return {string}
     */
    const triggerConfirmationForBeforeunload = (e) => {
        e.preventDefault();
        return (e.returnValue = message);
    }

    /**
     * Blocks the navigation of the route by checking whether the route is to be blocked or not. If blocked, shows
     * the confirmation prompt.
     * @param {History.Location} nextLocation
     * @param {History.action} action
     * @return {boolean} whether to allow navigation
     */
    const blockNavigation = (nextLocation, action) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation, action)) {
            setOpenPrompt(true);
            setPendingRoute({location: nextLocation, action});
            return false;
        }
        return true;
    };

    /**
     * Closes the modal and sets the confirmedNavigation to trigger the location change
     */
    const onConfirmNavigation = () => {
        setOpenPrompt(false);
        setConfirmedNavigation(true);
    };

    /**
     * Closes the dialog
     */
    const closeDialog = () => {
        setOpenPrompt(false);
        setPendingRoute(undefined);
    }

    return (
        <>
            <Prompt when={when} message={blockNavigation}/>
            <Modal size={'md'}
                   isOpen={openPrompt}
                   backdrop={'static'}
                   centered={true}
                   toggle={closeDialog}>
                <ModalHeader>
                    {title}
                </ModalHeader>
                <ModalBody className={'mx-3 mt-3'}>
                    <p className={'font-weight-600'}>{message}</p>
                </ModalBody>
                <ModalFooter className={'d-flex align-items-center justify-content-end'}>
                    <button className={'button text primary'}
                            type={'button'}
                            onClick={closeDialog}>
                        Cancel
                    </button>
                    <button className={'button primary px-5'}
                            type={'button'}
                            onClick={onConfirmNavigation}>
                        Proceed
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );
};


export default RouteLeavingGuard;
