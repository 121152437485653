import {
    DataGridColumn,
    DataGridColumnPinnedTypes,
    DataGridColumnWidth,
    DataGridConfiguration,
    DataGridDensities,
    DataGridDispatcherAction,
    DataGridInternalState,
    DataGridLayout,
    DataGridPaginationState,
    DataGridRow,
    DataGridRowSelectionInfo,
    DataGridSelectionEntry,
    DataGridSortBy,
    DataGridStateActions,
    GetExportInfoFunc
} from "../../../../models";
import {Dispatch} from "react";
import {EventEmitter} from 'eventemitter3';
import DataGridUtils from "../../../services/utils";

/**
 * The interface that manipulates the table state and layout that it belongs to.
 *
 * * This api is only used for external manipulation:
 * within the data grid entities (for internal manipulation)
 */
class DataGridController extends EventEmitter {
    protected _state!: DataGridInternalState;
    protected _rows!: DataGridRow[];
    protected _getExportInfo!: GetExportInfoFunc;
    protected _layout!: DataGridLayout;
    protected readonly dispatch!: Dispatch<DataGridDispatcherAction>;


    /**
     * Initializes the state of this api with the given arguments.
     *
     * NOTE: the reason that the args for the constructor are optional is to allow the context to be typed.
     * @param {DataGridInternalState} state
     * @param {Dispatch<DataGridDispatcherAction>} dispatch
     * @param {DataGridRow[]} rows
     * @param {DataGridLayout} layout
     * @param {GetExportInfoFunc} getExportInfo
     */
    constructor(state?: DataGridInternalState,
                dispatch?: Dispatch<DataGridDispatcherAction>,
                rows?: DataGridRow[],
                layout?: DataGridLayout,
                getExportInfo?: GetExportInfoFunc) {
        super();

        if (state)
            this._state = state;
        if (dispatch)
            this.dispatch = dispatch;
        if (rows)
            this._rows = rows;
        if (layout)
            this._layout = layout;
        if (getExportInfo)
            this._getExportInfo = getExportInfo;
    }

    /**
     * The configuration of this data grid.
     * @return {DataGridConfiguration}
     */
    get configuration(): DataGridConfiguration {
        const config: Partial<DataGridInternalState> = DataGridUtils.deepCopy(this._state);
        delete config.selectedRows;
        delete config.excludedRows;
        delete config.allRowsSelected;
        return config as DataGridConfiguration;
    }

    /**
     * The pagination information of this data grid.
     * @return {DataGridPaginationState}
     */
    get pagination(): DataGridPaginationState {
        return {
            currentPage: this._state.pagination.currentPage,
            pageSize: this._state.pagination.pageSize,
            length: this._state.pagination.length,
        } as DataGridPaginationState;
    }

    /**
     * The sort by column info of this data grid.
     * @return {DataGridSortBy | undefined}
     */
    get sortBy(): DataGridSortBy | undefined {
        return this._state.sortBy;
    }

    /**
     * The density of this data grid.
     * @return {DataGridSortBy | undefined}
     */
    get density() {
        return this._state.density;
    }

    /**
     * The object that describes each column's visibility of this data grid.
     * @return {Record<string, boolean>}
     */
    get columnsVisibilityState(): Record<string, boolean> {
        return Object.fromEntries(this._state.columns
                .map((column) => [column.name, column.visible])
            ?? []);
    }

    /**
     * The object that describes each column's pinned state of this data grid.
     * @return {Record<string, boolean>}
     */
    get columnsPinnedState(): Record<string, boolean> {
        return Object.fromEntries((DataGridUtils.deepCopy(this._state.columns) as DataGridColumn[])
                .map((column) => [column.name, column.pinned])
            ?? []);
    }

    /**
     * The object that describes each column's order of this data grid.
     * @return {Record<string, number>}
     */
    get columnOrders(): Record<string, number> {
        return Object.fromEntries((DataGridUtils.deepCopy(this._state.columns) as DataGridColumn[])
                .map((column) => [column.name, column.order])
                .sort(([, orderA], [, orderB]) =>
                    DataGridUtils.numComparator(orderA as number, orderB as number)
                )
            ?? []);
    }

    /**
     * The list of selected rows of this data grid.
     * @return {DataGridSelectionEntry[]}
     */
    get selectedRows(): DataGridSelectionEntry[] {
        return this._state.selectedRows;
    }

    /**
     * The list of excluded rows from the selection of this data grid.
     * @return {DataGridSelectionEntry[]}
     */
    get excludedRows(): DataGridSelectionEntry[] {
        return this._state.excludedRows;
    }

    /**
     * Whether all the rows of this data grid is selected or not.
     * @return {boolean}
     */
    get allRowsSelected(): boolean {
        return this._state.allRowsSelected;
    }

    /**
     * The full information of the selection status of this data grid.
     *
     * By design:
     * - if the allRowsSelected is true, then excludedRows is filled to indicate any exclusions
     * - if the allRowsSelected is false, then selectedRows is filled to indicate any selections
     * @return {DataGridRowSelectionInfo}
     */
    get rowsSelectionInfo(): DataGridRowSelectionInfo {
        return {
            selectedRows: this._state.selectedRows,
            excludedRows: this._state.excludedRows,
            allRowsSelected: this._state.allRowsSelected,
        };
    }

    /**
     * Loads the given configuration into this data grid.
     *
     * * this method will result in an ui layout re-rendering.
     * @param {DataGridConfiguration} configuration
     * @param {boolean} emitEvent
     */
    loadConfiguration(configuration: DataGridConfiguration, emitEvent: boolean = false) {
        this.dispatch({
            type: DataGridStateActions.loadConfiguration,
            payload: configuration,
            emitEvent: emitEvent,
        })
    }

    /**
     * Sets the pagination's' page size of this data grids.
     * @param {number} pageSize
     * @param {boolean} emitEvent
     */
    setPageSize(pageSize: number, emitEvent: boolean = false): void {
        this.dispatch({
            type: DataGridStateActions.setPageSize,
            payload: pageSize,
            emitEvent: emitEvent,
        });
    }

    /**
     * Sets the pagination's' current page of this data grids.
     * @param {number} currentPage
     * @param {boolean} emitEvent
     */
    setCurrentPage(currentPage: number, emitEvent: boolean = false): void {
        this.dispatch({
            type: DataGridStateActions.setCurrentPage,
            payload: currentPage,
            emitEvent: emitEvent,
        });
    }

    /**
     * Sets the sort by column info of this data grid.
     * @param {DataGridSortBy | undefined} sortBy
     * @param {boolean} emitEvent
     */
    setSortBy(sortBy: DataGridSortBy | undefined = undefined, emitEvent: boolean = false): void {
        this.dispatch({
            type: DataGridStateActions.setSortBy,
            payload: sortBy,
            emitEvent: emitEvent,
        })
    }

    /**
     * Sets the density this data grid.
     * @param {DataGridDensities} density
     * @param {boolean} emitEvent
     */
    setDensity(density: DataGridDensities, emitEvent: boolean = false): void {
        this.dispatch({
            type: DataGridStateActions.setDensity,
            payload: density,
            emitEvent: emitEvent,
        })
    }

    /**
     * Toggles the visibilities of the columns of this data grid.
     *
     * * if we have disabled visibility toggling in the data grid component, this method will have no effect.
     * * only the column names included in the given payload shall have an effect on the visibilities of the
     * data grid columns
     * * if the column with which the data-grid is ordered by is not visible anymore, remove the sortBy
     *      property as well.
     * @param {Record<string, boolean>} visibilities
     * @param {boolean} emitEvent
     */
    toggleColumnsVisibility(visibilities: Record<string, boolean>, emitEvent: boolean = false) {
        if (this._layout.disableTogglingVisibility)
            return;
        this.dispatch({
            type: DataGridStateActions.toggleColumnsVisibility,
            payload: visibilities,
            emitEvent: emitEvent,

        })
    }

    /**
     * Toggles the pinned state of the columns of this data grid.
     *
     * * if we have disabled pin toggling in the data grid component, this method will have no effect.
     * * only the column names included in the given payload shall have an effect on the pinned state of the
     * data grid columns
     * * in case of adding a new pinned column, they will receive the highest pinnedOrder and in case of
     * removing an existing one, their pinnedColumn shall be undefined.
     * @param {Record<string, DataGridColumnPinnedTypes | undefined>} pinnedColumns
     * @param {boolean} emitEvent
     */
    togglePinnedColumns(pinnedColumns: Record<string, DataGridColumnPinnedTypes | undefined>, emitEvent: boolean = false) {
        if (this._layout.disableTogglingPins)
            return;
        this.dispatch({
            type: DataGridStateActions.togglePinnedColumns,
            payload: pinnedColumns,
            emitEvent: emitEvent,

        })
    }

    /**
     * Refreshes the layout of this data grid.
     *
     * * this method will result in an ui layout re-rendering.
     * @param {boolean} emitEvent
     */
    refreshLayout(emitEvent: boolean = false) {
        this.dispatch({
            type: DataGridStateActions.refreshLayout,
            payload: null,
            emitEvent: emitEvent,

        });
    }

    /**
     * Changes the order of the columns of this data grid.
     *
     * * if we have disabled reordering in the data grid component, this method will have no effect.
     * * only the order of the un-pinned columns may be changed
     * @param {Record<string, number>} orders
     * @param {boolean} emitEvent
     */
    reorderColumns(orders: Record<string, number>, emitEvent: boolean = false) {
        if (this._layout.disableReordering)
            return;
        this.dispatch({
            type: DataGridStateActions.reorderColumns,
            payload: orders,
            emitEvent: emitEvent,
        })
    }

    /**
     * Resets the selection state of the data-grid.
     */
    resetSelection() {
        this.dispatch({
            type: DataGridStateActions.resetSelection,
            payload: null,
            emitEvent: false,
        })
    }

    /**
     * Toggles the selected rows of this data grid.
     *
     * * the key selections values determine if the rows should be added or removed
     * * only the rows that are visible to the user  (current rows of the DataGrid component) shall be selected. if
     * a rowKey of a non-rendered row is given, it is ignored.
     * @param {Record<string, boolean>} keySelection
     * @param {boolean} emitEvent
     */
    toggleSelectedRows(keySelection: Record<string, boolean>, emitEvent: boolean = false) {
        this.dispatch({
            type: DataGridStateActions.toggleSelectedRows,
            payload: {
                keySelection: keySelection,
                rows: this._rows?.map(e => ({key: e.key, data: e.data})),
            },
            emitEvent: emitEvent,
        })
    }

    /**
     * Toggles the all rows selected state of this data grid.
     *
     * * the list of selectedRows and excludedRows of this data grid get emptied as well.
     * @param {boolean} selectAll
     * @param {boolean} emitEvent
     */
    toggleAllRowsSelection(selectAll: boolean, emitEvent: boolean = false) {
        this.dispatch({
            type: DataGridStateActions.toggleAllRowsSelection,
            payload: selectAll,
            emitEvent: emitEvent,

        })
    }

    /**
     * Fetches the width of the column identified with its name from the data grid.
     *
     * @param {string} names
     * @return {DataGridColumnWidth | undefined}
     */
    getColumnWidths(names: string): DataGridColumnWidth | undefined
    /**
     * Fetches the width of the columns identified with the given names from the data grid.
     *
     * @param {string[]} names
     * @return {DataGridColumnWidth[] | undefined}
     */
    getColumnWidths(names: string[]): DataGridColumnWidth[] | undefined
    /**
     * Fetches the width of the columns identified with the given names from the data grid.
     *
     * @param {string |string[]} names
     * @return {DataGridColumnWidth | DataGridColumnWidth[] | undefined}
     */
    getColumnWidths(names: string | string[]): DataGridColumnWidth | DataGridColumnWidth[] | undefined {
        if (typeof names === 'string') {
            return this._state.columns.find(e => e.name === names)?.width as DataGridColumnWidth;
        }
        return this._state.columns
            .filter(e => names.includes(e.name))
            .map(e => e.width as DataGridColumnWidth)
    }

    /**
     * Resizes the columns of this data grid with the given widths object.
     *
     * * if we have disabled resizing in the data grid component, this method will have no effect.
     * @param {Record<string, number | DataGridColumnWidth>} widths
     * @param {boolean} emitEvent
     */
    resizeColumns(widths: Record<string, number | DataGridColumnWidth>, emitEvent: boolean = false) {
        if (this._layout.disableResizing)
            return;
        this.dispatch({
            type: DataGridStateActions.resizeColumns,
            payload: widths,
            emitEvent: emitEvent,
        })
    }

    /**
     * Resizes the columns of this data grid with the given widths object.
     *
     * * if we have disabled resizing in the data grid component, this method will have no effect.
     * @param {Record<string, number | DataGridColumnWidth>} widths
     * @param {boolean} emitEvent
     */
    resizeColumnsBy(widths: Record<string, number | DataGridColumnWidth>, emitEvent: boolean = false) {
        if (this._layout.disableResizing)
            return;
        this.dispatch({
            type: DataGridStateActions.resizeColumnsBy,
            payload: widths,
            emitEvent: emitEvent,
        })
    }

    /**
     * Exports the data grid rows and columns.
     *
     * * The exact exported content will depend on getExportedInfo callback (another callback passed to the data grid).
     * @return {Promise<boolean>} success indicator.
     */
    async export(): Promise<boolean> {
        try {
            if (!this._getExportInfo) {
                // we are throwing to return false.
                // noinspection ExceptionCaughtLocallyJS
                throw new Error('');
            }
            const exportInfo = await this._getExportInfo();
            if (!exportInfo?.body?.length)
                return false;
            DataGridUtils.exportCSVFile(exportInfo.body, exportInfo.title);
            return true;
        } catch (e) {
            return false;
        }
    }
}

export default DataGridController;
