import {TechnicalPropertyPrefix} from "./enums";

/**
 * The regexes of the application.
 */
class Regexes {
    /**
     * Used for finding the properties of a product technical search.
     */
    static technicalPropertyFinder: RegExp = new RegExp(`^${TechnicalPropertyPrefix}`);

    static CamelCaseFinder: RegExp = new RegExp(`[A-Z]`, 'g');
    /**
     * Used for finding any parts of the phone number that needs to be removed prior to formatting the phone number.
     */
    static phoneFormatterCleaner: RegExp = new RegExp(`[\\s()+_-]+`, 'g')
}

export default Regexes;
