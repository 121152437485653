import React, {useRef, useState} from "react";
import moment from "moment";
import * as Yup from "yup";
import ValidateMessages from "../../../../../core/constants/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {Col, Row} from "reactstrap";
import ReactCreditCards from "react-credit-cards";
import MuiInput from "../../../base/input/mui-input";
import MuiDatePicker from "../../../base/date-picker";
import Form from "../../../base/form";
import api from "../../../../../core/services/api/api";
import {userCardsApis} from "../../../../../core/constants/endpoints/endpoints";
import {apiMethods} from "../../../../../core/constants/enums";
import {createUUId} from "../../../../../core/services/utils/utils";

const minDate = moment().subtract(1, 'month').toDate();

const schema = Yup.object().shape({
    cvc: Yup.string().nullable().required(ValidateMessages.required),
    holderName: Yup.string().nullable().required(ValidateMessages.required),
    expiry: Yup.date().nullable()
        .required(ValidateMessages.required)
        .typeError(ValidateMessages.incorrectType("Date"))
        .min(minDate, ValidateMessages.min("today", "later than")),
    number: Yup.string().nullable().required(ValidateMessages.required),
})

const CreateCreditCardForm = ({onCreated, setLoading, colProps, id}) => {
    const [focused, _setFocused] = useState();
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const isMounted = useIsMounted();
    const idRef = useRef(id ?? createUUId(true));

    /**
     * Sets the focused ref value to the given event's target name
     * @param {MouseEvent} e
     */
    const setFocused = (e) => {
        _setFocused(e.target.name);
    }

    /**
     * Creates a new credit card for this user based on the provided information and if the result of the api is
     * successful, adds the card to the state list.
     * @param {any} values form values.
     * @return {Promise<void>}
     */
    const createCreditCard = async (values) => {
        if (setLoading) setLoading(true);
        const _date = moment(values.expiry);
        const card = {
            cardNumber: values.number,
            cvv: values.cvc,
            holderName: values.holderName,
            year: `${_date.year() - 2000}`,
            month: `${_date.format('MM')}`,
            isDefault: false,
        }
        // TODO: change url to new version when ready
        const response = await api({
            url: userCardsApis.addUserCreditCard,
            method: apiMethods.post,
            data: card,
            showSuccess: true,
        });
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            if (setLoading) setLoading(false);
            return;
        }
        if (onCreated) onCreated(response?.data);
        if (setLoading) setLoading(false);
        return response?.resultFlag;
    }

    return (
        <>
            <Form
                className='create-credit-card-form'
                onSubmit={createCreditCard}
                validate={validate}
                initialValues={{}}
                id={idRef.current}
                render={({values}) => {
                    return (
                        <Row>
                            <Col xs={12} md={6} {...colProps}>
                                <ReactCreditCards
                                    cvc={values?.cvc ?? '***'}
                                    expiry={
                                        values.expiry && values.expiry !== 'Invalid Date'
                                            ? moment(values.expiry).format('MM/yy')
                                            : '**/**'
                                    }
                                    name={values.holderName ?? 'Your name'}
                                    number={values.number ?? ''}
                                    focused={focused}
                                />
                            </Col>
                            <Col xs={12} md={6} {...colProps}>
                                <Row>
                                    <Col xs={12}>
                                        <MuiInput
                                            form
                                            onFocus={setFocused}
                                            label={"Card Number"}
                                            placeholder={'Card number'}
                                            name={'number'}
                                            required={required.number}
                                            inputProps={{maxLength: 19}}
                                        />
                                    </Col>
                                    <Col xs={12}>
                                        <MuiInput
                                            form
                                            label={"Card Holder"}
                                            onFocus={setFocused}
                                            placeholder={'Card holder name'}
                                            name={'holderName'}
                                            required={required.holderName}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <MuiDatePicker
                                            form
                                            label={"Expiry Date"}
                                            onFocus={setFocused}
                                            views={['month', 'year']}
                                            placeholder={'MM-yy'}
                                            name={'expiry'}
                                            format={"MM-yy"}
                                            required={required.expiry}
                                            minDate={minDate}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <MuiInput
                                            form
                                            label={"Security Code"}
                                            onFocus={setFocused}
                                            placeholder={'CVV'}
                                            name={'cvc'}
                                            required={required.cvc}
                                            inputProps={{maxLength: 4}}
                                        />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    );
                }}
            />
        </>
    )
}

export default CreateCreditCardForm;
