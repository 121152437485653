import React from "react";

/**
 *
 * @param {string} title
 * @param {string} message
 * @param {import('react-toastify').ToastContentProps} toastProps
 * @param {boolean} hideTicketSubmission
 * @param {any[]} buttons
 * @return {JSX.Element}
 * @constructor
 */
const ToastContent = ({title, message, toastProps, hideTicketSubmission, buttons}) => {
    // const [openTicketDialog, setOpenTicketDialog] = useState(false);
    // const ticketData = useMemo(() => ({
    //     curl: toastProps.data?.curl,
    //     capturedScreen: toastProps.data?.capturedScreen,
    //     url: window.location.origin + (toastProps.data?.location?.pathname ?? ''),
    //     apiResponse: toastProps.data?.apiResponse,
    //     apiRequest: toastProps.data?.apiRequest,
    // }), [toastProps])

    // /**
    //  * Closes the current toast that this component belongs to as soon as the ticket submission dialog is closed.
    //  */
    // const onDialogClosed = () => {
    //     setOpenTicketDialog(false);
    //     toastProps.closeToast();
    // }

    return (
        <>
            <div className={'toast-content'}>
                {
                    title &&
                    <p className={'toast-title mb-0'}>
                        {title ?? ''}
                    </p>
                }
                {
                    message &&
                    <p className={'toast-message mb-0'}>
                        {message ?? ''}
                    </p>
                }
                <div className={'toast-actions'}>
                    {
                        buttons?.map((e, index, array) => (
                            <button
                                {...e}
                                className={'view-shopping-cart-button px-3 py-2 mt-5'}
                            />
                            // <Button
                            //     {...e}
                            //     onClick={() => e.onClick(toastProps)}
                            //     className={classnames(
                            //         'text-xs font-weight-600 py-1 px-3',
                            //         {
                            //             'mr-3': (index < array.length && !hideTicketSubmission) || (index < array.length - 1)
                            //         },
                            //         e.className,
                            //     )}
                            //     variant={'outlined'}
                            //     borderRadius={5}
                            //     backgroundColor={transparent}
                            //     backgroundHoverColor={primaryColor1}
                            //     labelColor={primaryColor1}
                            //     labelHoverColor={white}
                            //     borderColor={primaryColor1}
                            //     borderWidth={1}
                            // />
                        ))
                    }
                    {/*{*/}
                    {/*    !hideTicketSubmission &&*/}
                    {/*    <Button*/}
                    {/*        className={classnames('text-xs font-weight-600 py-1 px-3',)}*/}
                    {/*        variant={'outlined'}*/}
                    {/*        borderRadius={5}*/}
                    {/*        backgroundColor={transparent}*/}
                    {/*        backgroundHoverColor={primaryColor1}*/}
                    {/*        labelColor={primaryColor1}*/}
                    {/*        labelHoverColor={white}*/}
                    {/*        borderColor={primaryColor1}*/}
                    {/*        borderWidth={1}*/}
                    {/*        onClick={() => setOpenTicketDialog(true)}*/}
                    {/*    >*/}
                    {/*        Submit Ticket*/}
                    {/*    </Button>*/}
                    {/*}*/}
                </div>
            </div>
            {/*<SubmitTicketDialog*/}
            {/*    open={openTicketDialog}*/}
            {/*    close={onDialogClosed}*/}
            {/*    data={ticketData}*/}
            {/*/>*/}
        </>
    )
}

export default ToastContent;
