import {createSlice} from "@reduxjs/toolkit";


const initialState = {
    unauthorized: false,
    policy: {
        open: false,
        callback: null,
        mandatory: false,
    },
    exporting: false,
    accessDenied: false,
    confirmation: {
        open: false,
        callback: null,
        mandatory: false,
        title: 'Confirmation',
        description: '',
        cancelText: "cancel",
        proceedText: "proceed",
    }
}

/**
 * Reducer that is responsible for handling shared dialogs' states of the entire app.
 */
const dialogsReduxSlice = createSlice({
    name: "dialogs",
    initialState: initialState,
    reducers: {
        policyDialog: (state, action) => {
            state.policy = {
                ...action.payload,
                ...(!action.payload.open
                        ? {
                            callback: null,
                            mandatory: false,
                        }
                        : {}
                ),
            }
        },
        unAuthorizedDialog: (state, action) => {
            state.unauthorized = action.payload;
        },
        exportingDialog: (state, action) => {
            state.exporting = action.payload;
        },
        accessDeniedDialog: (state, action) => {
            state.accessDenied = action.payload
        },
        confirmationDialog: (state, action) => {
            state.confirmation = {
                ...initialState.confirmation,
                ...action.payload,
            }
        }
    }
});

export default dialogsReduxSlice;
