import {
    DefaultDynamicContentEditorState,
    DynamicContentEditorDispatcherAction,
    DynamicContentEditorPageEntry,
    DynamicContentEditorPageEntryDataRecord,
    DynamicContentEditorState,
    DynamicContentEditorStateActions
} from "../../../index.d";
import EnvService from "../../services/env-service";
import DynamicContentEditorApi from "../../services/api";


/**
 * The Reducer container for the dynamic content editor.
 *
 * * This wrapper is responsible for the logic of manipulating and managing the state of a dynamic content editor.
 */
class DynamicContentEditorReducer {

    /**
     * The logic that is to be passed into the React reducer.
     */
    get reducer() {
        return DynamicContentEditorReducer._reducer.bind(this);
    }


    /**
     * Performs the logic of this reducer, and returns the new state.
     *
     * @param {DynamicContentEditorState}               state
     * @param {DynamicContentEditorDispatcherAction}    action
     * @return {DynamicContentEditorState}
     * @private
     */
    private static _reducer(state: DynamicContentEditorState, action?: DynamicContentEditorDispatcherAction): DynamicContentEditorState {
        action = (
            action ?? {
                type: DynamicContentEditorStateActions.none,
                payload: null,
            }
        ) as DynamicContentEditorDispatcherAction;

        switch (action.type) {
            case DynamicContentEditorStateActions.setMode:
                return {
                    ...state,
                    mode: action.payload ?? DefaultDynamicContentEditorState.mode,
                }
            case DynamicContentEditorStateActions.setCurrentPage:
                return {
                    ...state,
                    currentPage: action.payload ?? DefaultDynamicContentEditorState.currentPage,
                }
            case DynamicContentEditorStateActions.setPageInfo: {
                const fixed = state?.data?.fixed ?? [];
                const pageBased = [];
                for (const entry of action.payload.data) {
                    if (entry.isFixed) {
                        const index = fixed.findIndex(e => e.name === entry.name)
                        if (index !== -1) {
                            fixed.splice(index, 1, entry);
                        } else {
                            fixed.push(entry);
                        }
                    } else {
                        pageBased.push(entry);
                    }
                }
                // if no page is provided, then we only set the fixed data.
                if (!action.payload.page) {
                    return {
                        ...state,
                        data: {
                            ...(state?.data ?? {}),
                            fixed: fixed,
                        },
                    }
                }
                return {
                    ...state,
                    data: {
                        ...(state?.data ?? {}),
                        fixed: fixed,
                        pageBased: {
                            ...(state?.data?.pageBased ?? {}),
                            [action.payload.page]: pageBased,
                        },
                    },
                }
            }
            case DynamicContentEditorStateActions.setPageEntryInfo: {
                if (action.payload.entry?.isFixed) {
                    // fixed page entry info.
                    const fixed = state?.data?.fixed ?? [];
                    const index = fixed.findIndex(e => e.name === action?.payload.entry.name)
                    if (index !== -1) {
                        fixed.splice(index, 1, action.payload.entry);
                    } else {
                        fixed.push(action.payload.entry);
                    }
                    return {
                        ...state,
                        data: {
                            ...(state?.data ?? {}),
                            fixed: fixed,
                        },
                    }
                }
                const page = state?.data?.pageBased?.[action.payload.page] ?? [];
                const entry = action?.payload.entry as DynamicContentEditorPageEntry;
                const shouldAdd = !page.find(e => e.name === entry?.name);
                return {
                    ...state,
                    data: {
                        ...(state?.data ?? {}),
                        pageBased: {
                            ...(state?.data?.pageBased ?? {}),
                            [action.payload.page]: shouldAdd
                                ? [...page, entry]
                                : page.map(e => e.name === entry.name
                                    ? {...e, ...entry}
                                    : e
                                ),
                        },
                    },
                }
            }
            case DynamicContentEditorStateActions.setPageEntryDataRecordInfo: {
                if (!action.payload.page) {
                    const entry = state?.data?.fixed?.find(e => e.name === action?.payload?.entryName);
                    if (!entry) {
                        if (EnvService.isDevelopment) {
                            console.log(
                                `Trying to set a data record of a page entry, but the entry does not exist.` +
                                `given-entry-name: ${action.payload?.entryName} entries: ${state?.data?.fixed?.map(e => e.name).join(', ')}`
                            )
                        }
                        return state;
                    }

                    const record = action?.payload?.record as DynamicContentEditorPageEntryDataRecord;
                    const shouldAdd = !entry.data?.find(e => e.key === record.key);

                    return {
                        ...state,
                        data: {
                            ...(state?.data ?? {}),
                            fixed: state?.data?.fixed?.map(e => e.name === entry.name
                                ? {
                                    ...e,
                                    data: shouldAdd
                                        ? [...e.data, record]
                                        : e.data.map(d =>
                                            d.key === record.key
                                                ? {...d, ...record}
                                                : d
                                        )
                                        ?? []
                                }
                                : e
                            ) ?? []
                        },
                    }
                }

                const page = state?.data?.pageBased?.[action.payload.page] ?? [];
                const entry = page.find(e => e.name === action?.payload?.entryName);
                if (!entry) {
                    if (EnvService.isDevelopment) {
                        console.log(
                            `Trying to set a data record of a page entry, but the entry does not exist.` +
                            `given-entry-name: ${action.payload?.entryName} entries: ${page.map(e => e.name).join(', ')}`
                        )
                    }
                    return state;
                }

                const record = action?.payload?.record as DynamicContentEditorPageEntryDataRecord;
                const shouldAdd = !entry.data?.find(e => e.key === record.key);

                return {
                    ...state,
                    data: {
                        ...(state?.data ?? {}),
                        pageBased: {
                            ...(state?.data?.pageBased ?? {}),
                            [action.payload.page]: page.map(e => e.name === entry.name
                                ? {
                                    ...e,
                                    data: shouldAdd
                                        ? [...e.data, record]
                                        : e.data.map(d =>
                                            d.key === record.key
                                                ? {...d, ...record}
                                                : d
                                        )
                                        ?? []
                                }
                                : e
                            )
                        }
                    },
                }
            }
            case DynamicContentEditorStateActions.setLoading:
                return {
                    ...state,
                    loading: {
                        ...(state?.loading ?? {}),
                        [action.payload.page]: action.payload.loading,
                    },
                }
            case DynamicContentEditorStateActions.setApiConfiguration:
                const newApiConfig = {
                    ...(state?.apiConfiguration ?? {}),
                    ...(action.payload ?? {}),
                }
                DynamicContentEditorApi.configuration = newApiConfig;
                return {
                    ...state,
                    apiConfiguration: newApiConfig,
                }
            case DynamicContentEditorStateActions.setRouteToPageMap:
                return {
                    ...state,
                    routeToPageMap: action.payload ?? {},
                }
            case DynamicContentEditorStateActions.none:
            default:
                return state;
        }
    }
}

export default DynamicContentEditorReducer;
