import React, {useContext, useEffect, useMemo, useState} from "react";
import {useHistory} from "react-router-dom";
import useIsMounted from "../../hooks/use-is-mounted";
import DynamicContentEditorLoading from "../loading";
import DynamicContentEditorIframe from "../iframe";
import DynamicContentEditorApi from "../../../core/services/api";
import {DynamicContentEditorControllerContext, DynamicContentEditorStateContext} from "../../contexts";
import useQuery from "../../hooks/use-query";
import DynamicContentEditorApiEndpoints from "../../../core/static/api-endpoints";


const DynamicContentEditor = () => {
    const state = useContext(DynamicContentEditorStateContext)
    const controller = useContext(DynamicContentEditorControllerContext);
    const [loading, setLoading] = useState(true);
    const [valid, setValid] = useState(false);
    const history = useHistory();
    const query = useQuery();
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts:
     * - checks if the token and baseUrl exist in the query of the [location] and if so, sets them in the state.
     * - if query params do not exist, navigates out of this page.
     */
    useEffect(() => {
        const token = query?.validationToken;
        /**@type {string}*/
        const baseUrl = query?.baseUrl;
        if (!token || !baseUrl) {
            return history.goBack();
        }
        DynamicContentEditorApiEndpoints.baseUrl = baseUrl;
        controller.setApiConfiguration({
            token: token,
            validateTokenUrl: DynamicContentEditorApiEndpoints.validateToken,
            savePageEntryUrl: DynamicContentEditorApiEndpoints.savePageData,
            uploadFileUrl: DynamicContentEditorApiEndpoints.uploadFile,
        });
    }, [])

    /**
     * As soon as the [validationTokenUrl] of the DCE state is set:
     * - validates the token for editing the DCE components of the application using this package.
     */
    useEffect(() => {
        if (!state.apiConfiguration?.validateTokenUrl)
            return;
        validateToken(state.apiConfiguration?.token).then();
    }, [state.apiConfiguration?.validateTokenUrl])

    /**
     * Validates the token specific for enabling the edit mode of this package.
     *
     * @param {string} token
     * @return {Promise<void>}
     */
    const validateToken = async (token) => {
        setLoading(true);
        setValid(false);
        const response = await DynamicContentEditorApi.validateToken(token);
        if (!isMounted())
            return;
        const valid = response?.resultFlag ?? false;
        setValid(valid);
        if (valid) {
            return controller.setApiConfiguration({
                token: token,
            });
        }
        setTimeout(() => isMounted() && history.goBack(), 4500);
    }

    const content = useMemo(() => (
        <div className={'dynamic-content-editor'}>
            {
                loading &&
                <DynamicContentEditorLoading withOverlay/>
            }
            {
                valid &&
                <DynamicContentEditorIframe
                    setLoading={setLoading}
                />
            }
        </div>
    ), [loading, valid])

    return (
        <>
            {content}
        </>
    )
}

export default DynamicContentEditor;
