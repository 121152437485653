import React, {useEffect, useState} from "react";
import useIsMounted from "../../../hooks/use-is-mounted";
import useRouter from "../../../hooks/use-router";
import routes from "../../../routes";
import {userApis} from "../../../../core/constants/endpoints/endpoints";
import api from "../../../../core/services/api/api";
import {Alert, Col, Container, Row} from "reactstrap";
import ValidateMessages from "../../../../core/constants/validate-messages";
import {AlertTypes, apiMethods} from "../../../../core/constants/enums";
import classnames from "classnames";
import * as Yup from "yup";
import Form from "../../../components/base/form";
import {makeRequired, makeValidate} from "mui-rff";
import MuiInput from "../../../components/base/input/mui-input";
import {ReactComponent as ExpiredIcon} from "../../../../assets/images/art-likes/forgot-password-expired.svg";
import {Fade} from "@material-ui/core";
import useAlert from "../../../hooks/use-alert";

const schema = Yup.object().shape({
    password: Yup.string().nullable().required(ValidateMessages.required),
    confirmPassword: Yup.string().nullable().required(ValidateMessages.required),
});


const ResetPasswordView = () => {
    const {params, history} = useRouter();
    const [resetting, setResetting] = useState(false);
    const [verifying, setVerifying] = useState(true);
    const [allowReset, setAllowReset] = useState(true);
    const [alert, setAlert, resetAlert] = useAlert(8000);
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const isMounted = useIsMounted();


    /**
     * As soon as the component mounts:
     * - checks if the params contain a token, if not, redirects the user back to the sign-in view
     * then validates the reset-password token obtained in the link that the user clicked to open this view.
     */
    useEffect(() => {
        if (!params.token) {
            history.replace(routes.auth.base);
            return;
        }
        validateResetPasswordToken(params.token).then();
    }, [])

    /**
     * Validates the reset password token with the server to see if the user is allowed to chenage their password.
     * @param {string} token
     */
    const validateResetPasswordToken = async (token) => {
        setVerifying(true);
        const response = await api({
            url: userApis.validateResetPasswordToken(token),
            method: apiMethods.get,
            loginRequired: false,
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setVerifying(false);
            return;
        }
        setAllowReset(response?.resultFlag ?? false);
        setVerifying(false);
    }

    /**
     * Resets the user's password in the server.
     * @param {any} values form values.
     */
    const resetPassword = async (values) => {
        if (values.password !== values.confirmPassword) {
            return setAlert({isOpen: true, message: ValidateMessages.passwordsMatch, color: AlertTypes.warning})
        }
        setResetting(true);
        resetAlert();
        const response = await api({
            url: userApis.resetPassword,
            method: apiMethods.post,
            showError: false,
            loginRequired: false,
            data: {
                newPassword: values.password,
                token: params.token,
            },
        })
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setResetting(false);
            return;
        }
        setAlert({
            isOpen: true,
            message: response.message ?? '',
            color: response?.resultFlag ? AlertTypes.success : AlertTypes.error,
        });
        setResetting(false);
        if (response?.resultFlag) {
            history.replace(routes.auth.base);
        }
    }

    return (
        <>
            <Container className={'reset-password-view'}>
                <Row>
                    <Col lg={7} xs={12}>
                        <div className="d-flex flex-column">
                            <h1>
                                {
                                    verifying
                                        ? <>
                                            Verifying your
                                            <span>Account</span>
                                        </>
                                        : resetting ?
                                            <>
                                                Resetting your <span>password</span>
                                            </>
                                            : <>
                                                Reset your account's <span>password</span>
                                            </>
                                }
                            </h1>
                            <div className={''}>

                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            {
                                !allowReset &&
                                <div
                                    className={'heading-01 w-100'}>
                                    <p className={'p-reset-password'}>
                                        This link may have been expired Please contact our technical support
                                    </p>

                                </div>
                            }

                        </div>
                    </Col>
                    <Col lg={5} xs={12}>
                        {
                            !allowReset && !verifying &&
                            <Fade in={(!allowReset && !verifying)} className={'bg-icon svg-art-like-constrained'}>
                                <div>
                                    <ExpiredIcon/>
                                </div>
                            </Fade>
                        }
                        {
                            allowReset && !verifying &&
                            <div className={classnames("auth-form-box", {
                                'hide': (verifying || !allowReset),
                                'not-allowed': !allowReset,
                            })}>
                                <div className="box-title">
                                    New Password
                                </div>
                                <Alert color={alert.color} isOpen={alert.isOpen}>
                                    {alert.message ?? ''}
                                </Alert>
                                <Form
                                    className='register-form'
                                    onSubmit={resetPassword}
                                    validate={validate}
                                    initialValues={{}}
                                    render={() => {
                                        return (
                                            <>
                                                <Row>
                                                    <Col xs={12}>
                                                        <MuiInput
                                                            form
                                                            type={'password'}
                                                            placeholder={'Password'}
                                                            name={'password'}
                                                            required={required.password}
                                                        />
                                                    </Col>
                                                    <Col xs={12}>
                                                        <MuiInput
                                                            form
                                                            type={'password'}
                                                            placeholder={'re-password'}
                                                            name={'confirmPassword'}
                                                            required={required.confirmPassword}
                                                        />
                                                    </Col>
                                                    <div className="d-flex w-100 justify-content-center">
                                                        <button
                                                            className="button primary mt-3 px-5"
                                                            disabled={!!verifying && resetting}
                                                            type={'submit'}>
                                                            {
                                                                resetting
                                                                    ? 'submitting...'
                                                                    : "Submit"
                                                            }
                                                        </button>
                                                    </div>
                                                </Row>
                                            </>
                                        );
                                    }}
                                />
                            </div>
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default ResetPasswordView;
