import React, {useEffect, useState} from "react";
import {ReadingFileAs, UploadStates} from "../../../../core/constants/enums";
import {readFile} from "../../../../core/services/utils/utils";
import useIsMounted from "../../../hooks/use-is-mounted";
import LazyImage from "../lazy-lmage";


const UserAvatarDropzone = ({url, file, callback, highlight, notAccepted}) => {
    const [state, setState] = useState({
        uploading: false,
        uploadProgress: {},
        successFullUploaded: false,
        urlImg: ''
    });
    const isMounted = useIsMounted();

    /**
     * Only if the file is non-null, uploads the file to the server.
     */
    useEffect(() => {
        if (!file) return
        setState(prevState => ({...prevState, uploadProgress: {}, uploading: true,}));
        sendRequest(file).then()
    }, [file])

    /**
     * Sets the uplaod progress of the state as the file is being uploaded to the server.
     * @param progressEvent
     * @param fileName
     */
    const onUploadProgress = (progressEvent, fileName) => {
        setState(prevState => ({
            ...prevState,
            uploadProgress: {
                ...prevState.uploadProgress,
                [fileName]: {
                    state: "pending",
                    percentage: Math.round((progressEvent.loaded * 100) / progressEvent.total),
                }
            }
        }));
    }

    /**
     * Sends the selected image as the avatar image of the user to the server.
     * @param {File | Blob} file
     * @return {Promise<void>}
     */
    const sendRequest = async (file) => {
        const urlImg = await readFile(file, ReadingFileAs.dataUrl)
        setState(prevState => ({...prevState, urlImg: urlImg}))
        const uploadProgress = {
            ...state.uploadProgress
        };
        const data = {
            fileName: file.name,
            content: btoa(await readFile(file, ReadingFileAs.binaryString))
        }
        const resultFlag = await callback(data, (e) => onUploadProgress(e, file.name))
        if (!isMounted()) return;
        uploadProgress[file.name] = {
            state: resultFlag ? UploadStates.done : UploadStates.error,
            percentage: resultFlag ? 100 : 0
        };
        setState(prevState => ({...prevState, uploadProgress}));
    }

    /**
     * Renders the progress bar of the upload state.
     * @param {File} file
     * @return {JSX.Element|null}
     */
    const renderProgress = (file) => {
        const uploadProgress = state.uploadProgress[file.name];
        if (!uploadProgress) return null
        return (
            <>
                <div className={`progress-wrapper ${uploadProgress?.state}`}>
                    <span>{(uploadProgress?.percentage ?? 0) + '%'}</span>
                </div>
                <img
                    alt={'avatar'}
                    src={state.urlImg}
                    style={{
                        opacity: uploadProgress?.state === UploadStates.done ? 1 : .8
                    }}
                />
            </>
        );
    }

    return (
        <div className="d-flex flex-wrap justify-content-center">
            {
                !!file
                    ? <div className="img" key={file.name}>
                        {renderProgress(file)}
                    </div>
                    : <div className="img" key={0}>
                        {
                            url?.length &&
                            <LazyImage src={url} alt="avatar"/>
                        }
                    </div>
            }

            <div className={'w-100 d-flex flex-column text-center'}>
                <p>
                    <strong>
                        {
                            notAccepted
                                ? "Invalid format"
                                : "Choose Photo"
                        }
                    </strong>
                </p>
                <p>
                    {
                        notAccepted
                            ? "The selected file's format is invalid. you can only use images."
                            : " Drop files here or click to upload."
                    }
                </p>
            </div>
        </div>
    )
}


export default UserAvatarDropzone;

