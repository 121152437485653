// **************** APPLICATION BREAKPOINTS ****************

export const xs = 0;
export const sm = 576;
export const md = 768;
export const lg = 992;
export const xl = 1200;
export const xxl = 1400;


export const defaultHeaderHeight = 10;
export const errorHeaderHeight = 40;
export const floatingContainerHeaderHeight = 360;
export const floatingContainerMarginTop = -325;
export const sidebarHeaderHeight = 10;
