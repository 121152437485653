import React, {useCallback, useEffect, useMemo, useState} from "react";
import useRouter from "../../../hooks/use-router";
import {makeRequired, makeValidate} from "mui-rff";
import {Col, Container, Row} from "reactstrap";
import {ReactComponent as BackButton} from "../../../../assets/images/blog-back-icon.svg";
import {ReactComponent as ExpiredIcon} from "../../../../assets/images/art-likes/public-link-expired.svg";
import FloatingContainer from "../../../components/app-specific/floating-container";
import useIsMounted from "../../../hooks/use-is-mounted";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";
import * as Yup from "yup";
import moment from "moment";
import ValidateMessages from "../../../../core/constants/validate-messages";
import api from "../../../../core/services/api/api";
import {newPartSupportRequestApi} from "../../../../core/constants/endpoints/endpoints";
import {apiMethods} from "../../../../core/constants/enums";
import Form from "../../../components/base/form";
import AppDataGrid from "../../../containers/data-grid";
import {DataGridColumnTypes, DataGridColumnWidthTypes} from "../../../../packages/data-grid";
import Select from "../../../components/base/select";
import MuiInput from "../../../components/base/input/mui-input";
import MuiDatePicker from "../../../components/base/date-picker";

const formKeys = {
    make: 'make',
    model: 'model',
    year: 'year',
    vin: 'vin',
    installedDate: 'installedDate',
    failedDate: "failedDate",
    installedMileage: 'installedMileage',
    failedMileage: 'failedMileage',
    remarks: 'remarks',
}

const dataGridColumnNames = {
    rga: 'rga',
    date: 'date',
    customer: 'customer',
    address: 'address',

    partNo: 'partNo',
    serialNo: 'serialNo',
    invoiceNo: 'invoiceNo',
    reason: 'reason',
}

const PartSupportExtraInfoView = () => {
    const {params, history} = useRouter();
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [partSupport, setPartSupport] = useState({});
    const [models, setModels] = useState([]);
    const [makes, setMakes] = useState([]);
    const [expired, setExpired] = useState(false);
    const isMounted = useIsMounted();

    const schema = useMemo(() => Yup.object().shape({
        [formKeys.make]: Yup.object().nullable(),
        [formKeys.model]: Yup.object().nullable(),
        [formKeys.year]: Yup.string().test('year', 'Invalid Year ',
            (val) => {
                let _val = val?.trim();
                if (!val || val?.length === 0)
                    return true;
                if (val?.length > 0 && val?.trim()?.length === 0)
                    return false;
                if (!Number.isInteger(Number(_val)))
                    return false;
                if (1800 > Number(_val) || Number(_val) > moment().year())
                    return false;
                return _val?.length === 4;
            }
        ).nullable(),
        [formKeys.vin]: Yup.string().test('vin', 'VIN number should be less than 18 characters ',
            (val) => {
                if (!val || val?.length === 0)
                    return true;
                if (val?.length > 0 && val?.trim()?.length === 0)
                    return false;
                return val?.length <= 17;
            }
        ).nullable(),
        [formKeys.installedDate]: Yup.date().when(formKeys.failedDate, {
            is: value => value && moment(value).isValid(),
            then: Yup.date().nullable().typeError(ValidateMessages.incorrectType('Date'))
                .max(Yup.ref(formKeys.failedDate), ValidateMessages.lessThan('Failed Date')),
            otherwise: Yup.date().nullable().typeError(ValidateMessages.incorrectType('Date')),
        }),
        [formKeys.failedDate]: Yup.date().when(formKeys.installedDate, {
            is: value => value && moment(value).isValid(),
            then: Yup.date().nullable().typeError(ValidateMessages.incorrectType('Date'))
                .min(Yup.ref(formKeys.installedDate), ValidateMessages.greaterThan('Installed Date')),
            otherwise: Yup.date().nullable().typeError(ValidateMessages.incorrectType('Date')),
        }),
        [formKeys.installedMileage]: Yup.number().nullable().typeError(ValidateMessages.incorrectType('number')),
        [formKeys.failedMileage]: Yup.number().nullable().typeError(ValidateMessages.incorrectType('number')),
        [formKeys.remarks]: Yup.string().nullable(),
    }, [[formKeys.installedDate, formKeys.failedDate]]), []);

    const validate = useMemo(() => makeValidate(schema), [schema]);
    const required = useMemo(() => makeRequired(schema), [schema]);

    const initialValues = useMemo(() => ({
        [formKeys.make]: !!partSupport?.makeId ? makes?.find(e => e.id === partSupport.makeId) : undefined,
        [formKeys.model]: !!partSupport?.modelId ? models?.find(e => e.id === partSupport?.modelId) : undefined,
        [formKeys.year]: partSupport?.year ?? undefined,
        [formKeys.vin]: partSupport?.vin ?? undefined,
        [formKeys.installedDate]: partSupport?.installedDate ?? undefined,
        [formKeys.failedDate]: partSupport?.failedDate ?? undefined,
        [formKeys.installedMileage]: partSupport?.installedMileage ?? undefined,
        [formKeys.failedMileage]: partSupport?.failedMileage ?? undefined,
        [formKeys.remarks]: partSupport?.remarks,
    }), [makes, models, partSupport?.remarks, partSupport?.failedDate, partSupport?.failedMileage, partSupport?.installedDate, partSupport?.installedMileage, partSupport.makeId, partSupport?.modelId, partSupport?.vin, partSupport?.year]);

    /**
     * Fetches part support information from the server.
     * @type {(function(): Promise<void>)|*}
     */
    const getPartSupport = useCallback(async () => {
        setExpired(false);
        setLoading(true);
        const response = await api({
            url: newPartSupportRequestApi.getInfo(params.token),
            method: apiMethods.get,
            loginRequired: false,
        });
        if (!isMounted())
            return;
        setLoading(false);
        if (response?.resultFlag) {
            setPartSupport(response?.data ?? {});
            setMakes(response?.data?.makes ?? []);
            setModels(response?.data?.makes.map(e => e.models.map(m => ({...m, makeId: e.id}))).flat() ?? []);
        } else {
            setExpired(true)
        }
    }, [isMounted, params.token]);

    /**
     * As soon as the page is mounted:
     * - Fetches part support information from the server.
     */
    useEffect(() => {
        getPartSupport().then();
    }, [getPartSupport]);

    /**
     * Submits the edited part support extra info to the server.
     * @type {(function(*): Promise<void>)|*}
     */
    const editPartSupportExtraInfo = useCallback(async (values) => {
        setSubmitting(true);
        const forApi = {
            shareLinkToken: params.token,
            makeId: !!values.make ? values.make.id : null,
            modelId: !!values.model ? values.model.id : null,
            year: !!values.year ? Number(values.year) : null,
            vin: !!values.vin ? values.vin : null,
            installedDate: !!values.installedDate ? moment(values.installedDate).toISOString() : null,
            failedDate: !!values.failedDate ? moment(values.failedDate).toISOString() : null,
            installedMileage: !!values.installedMileage ? parseFloat(values.installedMileage) : null,
            failedMileage: !!values.failedMileage ? parseFloat(values.failedMileage) : null,
            remarks: values.remarks,
            updatedProperties: [
                "makeId",
                "modelId",
                "year",
                "vin",
                "installedDate",
                "failedDate",
                "installedMileage",
                "failedMileage",
                "Remarks",
            ]
        };
        await api({
            url: newPartSupportRequestApi.updateExtraInfo,
            method: apiMethods.put,
            data: forApi,
            showErrorToast: true,
            loginRequired: false,
        });
        if (!isMounted())
            return;
        setSubmitting(false);
    }, [isMounted, params.token]);

    /**
     * Changes the model select options based on the selected make.
     * @type {(function(*, *): void)|*}
     */
    const onMakeChanged = useCallback((e, form) => {
        form.change(e.target.name, e.target.value);
        form.change(formKeys.model, null);
    }, [])


    const firstRowColumns = useMemo(() =>
        /**@type {DataGridColumn[]}*/
        [
            {
                name: dataGridColumnNames.rga,
                title: 'RGA',
                type: DataGridColumnTypes.string,
                width: {
                    type: DataGridColumnWidthTypes.flex,
                    size: 1,
                },
                sortable: false,
                resizable: false,
                visibilityToggleable: false,
                reorderable: false,
                pinnedToggleable: false,
            },
            {
                name: dataGridColumnNames.date,
                title: 'Date',
                type: DataGridColumnTypes.date,
                width: {
                    type: DataGridColumnWidthTypes.flex,
                    size: 1,
                },
                sortable: false,
                resizable: false,
                visibilityToggleable: false,
                reorderable: false,
                pinnedToggleable: false,
            },
            {
                name: dataGridColumnNames.customer,
                title: 'Customer',
                type: DataGridColumnTypes.string,
                width: {
                    type: DataGridColumnWidthTypes.flex,
                    size: 1,
                },
                sortable: false,
                resizable: false,
                visibilityToggleable: false,
                reorderable: false,
                pinnedToggleable: false,
            },
            {
                name: dataGridColumnNames.address,
                title: 'Address',
                type: DataGridColumnTypes.string,
                width: {
                    type: DataGridColumnWidthTypes.flex,
                    size: 1,
                },
                sortable: false,
                resizable: false,
                visibilityToggleable: false,
                reorderable: false,
                pinnedToggleable: false,
            }
        ], []);

    const secondRowColumns = useMemo(() =>
        /**@type {DataGridColumn[]}*/
        [
            {
                name: dataGridColumnNames.partNo,
                title: 'Part#',
                type: DataGridColumnTypes.string,
                width: {
                    type: DataGridColumnWidthTypes.flex,
                    size: 1,
                },
                sortable: false,
                resizable: false,
                visibilityToggleable: false,
                reorderable: false,
                pinnedToggleable: false,
            },
            {
                name: dataGridColumnNames.serialNo,
                title: 'Serial#',
                type: DataGridColumnTypes.string,
                width: {
                    type: DataGridColumnWidthTypes.flex,
                    size: 1,
                },
                sortable: false,
                resizable: false,
                visibilityToggleable: false,
                reorderable: false,
                pinnedToggleable: false,
            },
            {
                name: dataGridColumnNames.invoiceNo,
                title: 'Invoice#',
                type: DataGridColumnTypes.string,
                width: {
                    type: DataGridColumnWidthTypes.flex,
                    size: 1,
                },
                sortable: false,
                resizable: false,
                visibilityToggleable: false,
                reorderable: false,
                pinnedToggleable: false,
            },
            {
                name: dataGridColumnNames.reason,
                title: 'Reason',
                type: DataGridColumnTypes.string,
                width: {
                    type: DataGridColumnWidthTypes.flex,
                    size: 1,
                },
                sortable: false,
                resizable: false,
                visibilityToggleable: false,
                reorderable: false,
                pinnedToggleable: false,
            },
        ], []);

    /**
     * @type {DataGridRow[]}
     */
    const dataGridRows = useMemo(() => {
        return [partSupport].map((partSupport) => {
            return {
                data: partSupport,
                key: partSupport?.id ?? -1,
                cells: {
                    [dataGridColumnNames.rga]:
                        <p className={'font-weight-bold'}>
                            {partSupport.rga}
                        </p>,
                    [dataGridColumnNames.date]: partSupport.submitDateTime,
                    [dataGridColumnNames.customer]: partSupport.customer,
                    [dataGridColumnNames.address]: partSupport?.address,
                    [dataGridColumnNames.partNo]: partSupport.partNumber,
                    [dataGridColumnNames.serialNo]: partSupport.serialNo,
                    [dataGridColumnNames.invoiceNo]: partSupport.invoiceNo,
                    [dataGridColumnNames.reason]: partSupport?.type?.name ?? partSupport?.type ?? '',
                }
            }
        });
    }, [partSupport]);

    return (
        <>
            <Container className={'part-support-extra-info-view'}>
                <Row>
                    <Col xs={12}>
                        <FloatingContainer setHeight>
                            {
                                loading
                                    ? <>
                                        <div className={'my-5 py-5 d-flex justify-content-center'}>
                                            <LoadingIndicator/>
                                        </div>
                                    </>
                                    : !!expired ?
                                        <Container className={'public-link-expired'}>
                                            <Row className='justify-content-center w-100'>
                                                <ExpiredIcon/>
                                            </Row>
                                        </Container>
                                        : <Row>
                                            <Col xs={12}>
                                                <div className={'d-flex justify-content-start align-items-center'}>
                                                    <div onClick={() => history.goBack()}>
                                                        <BackButton className={'back-button'}/>
                                                    </div>
                                                    <h2 className={'title m-0'}>
                                                        Part Support Extra Info
                                                    </h2>
                                                </div>
                                            </Col>
                                            <Col xs={12} className={'mt-4 d-flex flex-column'}>
                                                {/*first half*/}
                                                <AppDataGrid
                                                    hideFooter
                                                    hideToolbar
                                                    rows={dataGridRows}
                                                    columns={firstRowColumns}
                                                    state={{loading: {state: loading}}}
                                                />
                                                {/*second half*/}
                                                <AppDataGrid
                                                    hideFooter
                                                    hideToolbar
                                                    rows={dataGridRows}
                                                    columns={secondRowColumns}
                                                    state={{loading: {state: loading}}}
                                                />
                                            </Col>
                                            <Col xs={12} className={'mt-5'}>
                                                <Form
                                                    className='d-flex flex-wrap w-100 justify-content-center w-100'
                                                    onSubmit={editPartSupportExtraInfo}
                                                    validate={validate}
                                                    initialValues={initialValues}
                                                    render={({values, form}) => {
                                                        return (
                                                            <>
                                                                <Col xs={12} lg={6} className={'d-flex flex-wrap mt-3'}>
                                                                    <Col xs={12} className={'mb-3 p-0'}>
                                                                        <p className={'section-title'}>
                                                                            Truck Information
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs={12} className={'p-0 mb-3'}>
                                                                        <Select
                                                                            form
                                                                            name={formKeys.make}
                                                                            required={false}
                                                                            label={'Make'}
                                                                            renderValue={(value) => value?.title ?? ''}
                                                                            onChange={(e) => onMakeChanged(e, form)}
                                                                            data={[
                                                                                {id: -1, label: 'None', value: null},
                                                                                ...makes?.map(e => ({
                                                                                    value: e,
                                                                                    label: e.title,
                                                                                    id: e.id,
                                                                                })),
                                                                            ]}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={12} className={'p-0 mb-3'}>
                                                                        <Select
                                                                            form
                                                                            name={formKeys.model}
                                                                            required={false}
                                                                            label={'Model'}
                                                                            renderValue={(value) => value?.title ?? ''}
                                                                            data={[
                                                                                {id: -1, label: 'None', value: null},
                                                                                ...models?.filter(t => t.makeId === values[formKeys.make]?.id)?.map(e => ({
                                                                                    value: e,
                                                                                    label: e.title,
                                                                                    id: e.id,
                                                                                })),
                                                                            ]}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={6} className={'p-0 mb-3 pr-1'}>
                                                                        <MuiInput
                                                                            form
                                                                            label={'Year'}
                                                                            name={formKeys.year}
                                                                            required={required[formKeys.year]}
                                                                            maxLength={4}
                                                                            inputProps={{
                                                                                maxLength: 4,
                                                                                minLength: 4,
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={6} className={'p-0 mb-3 pl-1'}>
                                                                        <MuiInput
                                                                            form
                                                                            label={'VIN'}
                                                                            name={formKeys.vin}
                                                                            required={required[formKeys.vin]}
                                                                            maxLength={17}
                                                                            inputProps={{
                                                                                maxLength: 17,
                                                                                minLength: 1,
                                                                            }}
                                                                        />
                                                                    </Col>
                                                                </Col>
                                                                <Col xs={12} lg={6} className={'d-flex flex-wrap mt-3'}>
                                                                    <Col xs={12} className={'mb-3 p-0'}>
                                                                        <p className={'section-title'}>
                                                                            Usage Information
                                                                        </p>
                                                                    </Col>
                                                                    <Col xs={12} md={6} className={'p-0 mb-3 pr-1'}>
                                                                        <MuiDatePicker
                                                                            form
                                                                            label={"Installed Date"}
                                                                            format={'dd/MM/yyyy'}
                                                                            placeholder={'dd/mm/yyyy'}
                                                                            name={formKeys.installedDate}
                                                                            required={required[formKeys.installedDate]}
                                                                            maxDate={values[formKeys.failedDate] ?? Date.now()}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={12} md={6} className={'p-0 mb-3 pl-1'}>
                                                                        <MuiDatePicker
                                                                            form
                                                                            label={"Failed Date"}
                                                                            format={'dd/MM/yyyy'}
                                                                            placeholder={'dd/mm/yyyy'}
                                                                            name={formKeys.failedDate}
                                                                            required={required[formKeys.failedDate]}
                                                                            minDate={values[formKeys.installedDate]}
                                                                            maxDate={Date.now()}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={12} className={'p-0 mb-3'}>
                                                                        <MuiInput
                                                                            form
                                                                            label={"Installed Mileage"}
                                                                            name={formKeys.installedMileage}
                                                                            required={required[formKeys.installedMileage]}
                                                                            type={'number'}
                                                                            className={'mr-2'}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={12} className={'p-0 mb-4'}>
                                                                        <MuiInput
                                                                            form
                                                                            label={"Failed Mileage"}
                                                                            name={formKeys.failedMileage}
                                                                            required={required[formKeys.failedMileage]}
                                                                            type={'number'}
                                                                            className={'mr-2'}
                                                                        />
                                                                    </Col>
                                                                    <Col xs={12} className={'p-0 mb-4'}>
                                                                        <MuiInput
                                                                            form
                                                                            label={"Remarks"}
                                                                            name={formKeys.remarks}
                                                                            required={required[formKeys.remarks]}
                                                                            className={'mr-2'}
                                                                        />
                                                                    </Col>
                                                                </Col>
                                                                <Col xs={12} className={'p-0 mb-4 d-flex justify-content-center'}>
                                                                    <button className={'button primary px-5'}
                                                                            type={'submit'}
                                                                            disabled={submitting || loading}>
                                                                        {
                                                                            submitting
                                                                                ? "Submitting..."
                                                                                : "Submit"
                                                                        }
                                                                    </button>
                                                                </Col>
                                                            </>
                                                        )
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                            }
                        </FloatingContainer>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default PartSupportExtraInfoView;
