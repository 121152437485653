import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import {Tooltip} from "@material-ui/core";
import classnames from "classnames";
import LazySvg from "../lazy-svg";
import LazyImage from "../lazy-lmage";
import useIsMounted from "../../../hooks/use-is-mounted";

const TagsContainer = ({tags, lines = 2, onTagClicked: onTagClickedProps, containerAlwaysVisible = false}) => {
    const [state, setState] = useState({tags: [], count: 0});
    const [hoveredTag, setHoveredTag] = useState();
    const isMounted = useIsMounted();
    /**@type {React.MutableRefObject<HTMLDivElement | null>}*/
    const containerRef = useRef(null);

    const hoverScale = useMemo(() => 1.5, []);
    const iconSize = useMemo(() => 24, [])
    const singleTagHeight = useMemo(() => 42 * (hoveredTag ? hoverScale : 1), [hoveredTag, hoverScale]);
    const tagsHeight = useMemo(() => {
        if (!hoveredTag) {
            return singleTagHeight * lines;
        }
        return ((singleTagHeight * lines) / hoverScale) + 6.5;
    }, [singleTagHeight, lines, hoveredTag, hoverScale]);
    const show = useMemo(() => state.count === state.tags.length, [state])

    /**
     * With each change in teh show state of the tags-container:
     * - injects the clas of static svg to the parents of these svg elements.
     */
    useEffect(() => {
        if (!show || !containerRef.current)
            return;
        const SVGs = containerRef.current.querySelectorAll('svg');
        for (const svg of SVGs) {
            svg.parentElement.parentElement.parentElement.parentElement.classList.remove('static-svg');
            if (svg.classList.contains('static-svg')) {
                svg.parentElement.parentElement.parentElement.parentElement.classList.add('static-svg');
            }
        }
        const PNGs = containerRef.current.querySelectorAll('img');
        for (const png of PNGs) {
            png.parentElement.parentElement.classList.remove('static-svg');
            png.parentElement.parentElement.classList.add('static-svg');
        }
    }, [show, containerRef])

    /**
     * With each change in the tags:
     * - sets the shown tags.
     */
    useLayoutEffect(() => {
        let _tags = tags?.filter(e => !!e.iconFileName) ?? [];
        _tags = [...(new Set(_tags?.map(e => e.id) ?? []).values())].map(e => _tags.find(t => t.id === e));
        setState({
            tags: _tags,
            count: 0,
        })
    }, [tags])

    /**
     * Removes the given tag from the list of shown tags
     * @param {any} tag
     */
    const removeShownTag = (tag) => {
        if (!isMounted())
            return;
        setState(prevState => ({
            ...prevState,
            tags: prevState.tags?.filter(e => e.id !== tag.id) ?? [],
        }));
    }

    /**
     * Adds one to the count of shown tags to indicate the loading of the tags.
     */
    const addShownTag = () => {
        if (!isMounted())
            return;
        setState(prevState => ({
            ...prevState,
            count: prevState.count + 1,
        }));
    }


    /**
     * Prevents the default behaviour of the event for clicking of the tag and then calls the appropriate callback.
     * @param e
     * @param tag
     */
    const onTagClicked = (e, tag) => {
        if (!isMounted())
            return;
        e.preventDefault();
        e.stopPropagation();
        onTagClickedProps(tag);
    }

    return (
        <>
            <div
                className={classnames('tags-container-wrapper', {show})}
                style={{
                    '--height': `${tagsHeight}px`,
                    '--line-height': `${singleTagHeight}px`,
                    '--hover-scale': `${hoverScale}`,
                }}
            >
                <div ref={containerRef} className={'tags-container'}>
                    {
                        state?.tags?.map(tag => (
                            <Tooltip
                                key={tag?.id}
                                title={tag?.name ?? ''}
                                placement={'bottom'}
                                arrow
                            >
                                <div
                                    className={'chips'}
                                    onMouseEnter={(e) => setHoveredTag(e.target)}
                                    onMouseLeave={() => setHoveredTag(undefined)}
                                    onClick={(e) => onTagClicked(e, tag)}
                                >
                                    {
                                        tag?.iconFileName?.toLowerCase()?.endsWith('.png')
                                            ? <LazyImage
                                                src={tag.iconFileName}
                                                height={iconSize}
                                                width={iconSize}
                                                onError={() => removeShownTag(tag)}
                                                onLoad={() => addShownTag(tag)}
                                            />
                                            : <LazySvg
                                                src={tag.iconFileName}
                                                height={iconSize}
                                                width={iconSize}
                                                onError={() => removeShownTag(tag)}
                                                onLoad={() => addShownTag(tag)}
                                            />
                                    }

                                </div>
                            </Tooltip>
                        ))
                    }
                </div>
            </div>

        </>
    )
}

export default TagsContainer;
