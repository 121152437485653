import * as Yup from "yup";
import ValidateMessages from "../../../../core/constants/validate-messages";
import useIsMounted from "../../../hooks/use-is-mounted";
import React, {useState} from "react";
import {makeRequired, makeValidate} from "mui-rff";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Row} from "reactstrap";
import Form from "../../base/form";
import api from "../../../../core/services/api/api";
import {userApis} from "../../../../core/constants/endpoints/endpoints";
import {apiMethods} from "../../../../core/constants/enums";
import MuiInput from "../../base/input/mui-input";
import {IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";
import MuiPhoneInput from "../../base/input/mui-phone-input";
import YupExtensions from "../../../../core/services/yup-extensions";

YupExtensions.phoneNo();

const schema = Yup.object().shape({
    firstName: Yup.string().nullable().required(ValidateMessages.required),
    lastName: Yup.string().nullable().required(ValidateMessages.required),
    email: Yup.string().email('Enter valid email').nullable().required(ValidateMessages.required),
    phone: Yup.string().trim().nullable()
        .required(ValidateMessages.required)
        .phoneNo(ValidateMessages.incorrectType("phone"), 10),
});


const AddUserDialog = ({open, setOpen, onSuccess, onBack}) => {
    const [registering, setRegistering] = useState(false);
    // const [validating, setValidating] = useState(false);
    const isMounted = useIsMounted();
    const validate = makeValidate(schema);
    const required = makeRequired(schema);

    /**
     * Registers a new user into the system. The user can select from the already existing companies or register a
     * new company as well.
     *
     * if registering a new company:
     *  * if not yet in taxes pages => show the taxes pages.
     *  * else => register the user with their new company and the taxes exemption numbers for the company
     *
     * else => registers the user to an existing company.
     * @param values
     */
    const addUser = async (values) => {
        setRegistering(true);
        const response = await api({
            url: userApis.registerNewUser,
            method: apiMethods.post,
            data: {
                firstName: values.firstName,
                lastName: values.lastName,
                email: values.email,
                phone: values.phone,
            },
        });
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setRegistering(false);
            return;
        }
        if (response?.resultFlag) {
            setOpen(false);
            onSuccess(response.data);
        }
        setRegistering(false);
    }

    /**
     * Navigates back from the modal.
     */
    const goBack = () => {
        setOpen(false);
        if (onBack) onBack();
    }

    const colClass = 'mb-3 px-2';
    return (
        <Modal
            isOpen={open}
            toggle={goBack}
            size={'md'}
            centered>
            <ModalHeader>
                <div className={'d-flex flex-row justify-content-between align-items-center'}>
                    Add New User
                    <div>
                        <IconButton aria-label={"cancel"} onClick={() => setOpen(false)}>
                            <Close/>
                        </IconButton>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody>
                <div className='my-3 w-100'/>
                <Form
                    className='w-100'
                    onSubmit={addUser}
                    validate={validate}
                    initialValues={{}}
                    id={'add-user-form'}
                    render={({form, values}) => {
                        return (
                            <Row className='no-gutters justify-content-around w-100'>
                                <Col xs={12} md={6} className={colClass}>
                                    <MuiInput
                                        form
                                        placeholder={'First name'}
                                        name={'firstName'}
                                        required={required.firstName}
                                    />
                                </Col>
                                <Col xs={12} md={6} className={colClass}>
                                    <MuiInput
                                        form
                                        placeholder={'Last name'}
                                        name={'lastName'}
                                        required={required.lastName}
                                    />
                                </Col>
                                <Col xs={12} md={7} className={colClass}>
                                    <MuiInput
                                        form
                                        type={'email'}
                                        name={'email'}
                                        placeholder={'Email'}
                                        required={required.email}
                                    />
                                </Col>
                                <Col xs={12} md={5} className={colClass}>
                                    <MuiPhoneInput
                                        type={'tel'}
                                        label={"Phone"}
                                        placeholder={'Phone'}
                                        name={'phone'}
                                        required={required.phone}
                                        value={values['phone']}
                                        onChange={(e) => form.change(e.target.name, e.target.value)}
                                        form={form}
                                    />
                                    {/*<MuiInput*/}
                                    {/*    form*/}
                                    {/*    type={'tel'}*/}
                                    {/*    placeholder={'Phone number'}*/}
                                    {/*    name={'phone'}*/}
                                    {/*    required={required.phone}*/}
                                    {/*/>*/}
                                </Col>
                            </Row>
                        );
                    }}
                />
            </ModalBody>
            <ModalFooter className={'d-flex align-items-center justify-content-end'}>
                <button className={'button primary text'} onClick={goBack}>
                    Cancel
                </button>
                <button
                    disabled={!open || registering}
                    className={'button primary px-5'}
                    form={'add-user-form'}
                    type={'submit'}>
                    {registering
                        ? "Adding..."
                        : "Add User"
                    }
                </button>
            </ModalFooter>
        </Modal>
    );
}

export default AddUserDialog;
