import React, {useEffect} from "react";
import useRouter from "../../../../hooks/use-router";
import routes from "../../../../routes";
import {useDispatch, useSelector} from "react-redux";
import {profileInfoInitialState} from "../../../../../redux/entities/profile/reducer";
import useIsMounted from "../../../../hooks/use-is-mounted";
import EnvService from "../../../../../core/services/env-service";
import api from "../../../../../core/services/api/api";
import {shoppingCartApis, userApis} from "../../../../../core/constants/endpoints/endpoints";
import {apiMethods} from "../../../../../core/constants/enums";
import {setShoppingCartCount} from "../../../../../redux/entities/shopping-cart/actions";
import {Col} from "reactstrap";
import classnames from "classnames";
import HeaderSearchInput from "../../../../components/base/search-input/header-search-input";
import {Zoom} from "@material-ui/core";
import {ReactComponent as CartIcon} from "../../../../../assets/images/shopping-cart/shopping-cart.svg";
import {ReactComponent as LogoutIcon} from "../../../../../assets/images/logout.svg";
import {logoutUser} from "../../../../../redux/entities/auth/actions";
import LoadingIndicator from "../../../../components/app-specific/loading-indicator";
import {updateReduxProfileInformation} from "../../../../../redux/entities/profile/actions";


const LoggedInHeaderContent = () => {
    const {history, match} = useRouter({path: routes.main.shoppingCart, exact: true});
    const profile = useSelector(state => state?.profile ?? profileInfoInitialState);
    const cart = useSelector(state => state?.shoppingCart);
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    const shoppingCartCount = cart?.count ?? 0;

    /**
     * With initial render of the logged in top-bar:
     * Calls the function for getting user profile
     */
    useEffect(() => {
        getUserInformation().then()
    }, [])

    /**
     * Gets user information from server by calling the api regarding this action
     * Dispatches the redux action to update the profile information
     * @return {Promise<void>}
     */
    const getUserInformation = async () => {
        let userInfo = {};

        const response = await api({
            url: userApis.getProfile,
            method: apiMethods.get,
        })
        if (!isMounted())
            return;
        if (response.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (response.resultFlag) {
            userInfo = {
                firstName: response.data.firstname,
                lastName: response.data.lastname,
                email: response.data.email,
                phone: response.data.phone,
                profileFileName: response.data.profilePicInfo?.url,
                isImagePlaceholder: response.data?.profilePicInfo?.isPlaceholder,
            }
            dispatch(updateReduxProfileInformation(userInfo))
        }
    }

    /**
     * Listens for the changes in the url and with each change:
     * if the url is not shoppingCart then fetches the shopping cart of the user to sync the values.
     */
    useEffect(() => {
        if (match || !EnvService.EnablePayments) return
        getShoppingCart().then();
    }, [match]);

    /**
     * Fetches the user shopping cart from the server and sets the shopping cart count.
     * @return {Promise<void>}
     */
    const getShoppingCart = async () => {
        const response = await api({
            url: shoppingCartApis.getShoppingCart,
            method: apiMethods.get,
            showError: false,
        });
        if (!isMounted())
            return;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        let count = 0
        if (response?.resultFlag) {
            count = [...(response.data?.parts?.map(e => e.quantity) ?? []), 0, 0].reduce((p, c) => p + c)
        }
        dispatch(setShoppingCartCount(count))
    }

    /**
     * Logs user out of the application:
     * - dispatches action for removing user information from the system and logs out user from the application
     */
    const onLogout = () => {
        dispatch(logoutUser(history));
    }

    return (
        <>
            <Col lg={4} xs={12} className={'d-flex flex-column mt-3 mt-lg-0'}>
                <div className={'d-flex justify-content-between'}>
                    {
                        profile
                            ? <button
                                onClick={() => history.push(routes.dashboard.savedItems)}
                                className="profile invis">
                                <div className="avatar">
                                    <img
                                        src={profile.profileFileName}
                                        alt={profile?.lastName ?? 'User Profile Picture'}
                                    />
                                </div>
                                <div className={classnames("text header-profile-info")}>
                                    <p>
                                        {(profile?.firstName ?? 'Dear') + ' ' + (profile?.lastName ?? 'User')}
                                    </p>
                                    <p className={'small'}>
                                        {profile.email ?? ''}
                                    </p>
                                </div>
                            </button>
                            : <LoadingIndicator size={100}/>
                    }
                    <div className={classnames('d-flex align-items-center')}>
                        {
                            EnvService.EnablePayments &&
                            <div
                                className={classnames('cart-icon-container', {'with-counts': shoppingCartCount > 0})}
                                onClick={() => history.push(routes.main.shoppingCart)}>
                                <Zoom in={shoppingCartCount > 0} className={'count-container'}>
                                    <div>
                                        <p className={'count'}>
                                            {shoppingCartCount > 99 ? '+99' : shoppingCartCount}
                                        </p>
                                    </div>
                                </Zoom>
                                <div className={'header-button-container'}>
                                    <button className={'invis'}>
                                        <CartIcon className={'shopping-cart'}/>
                                    </button>
                                </div>
                            </div>
                        }
                        <div className={'header-button-container'}>
                            <button className={'invis'} onClick={onLogout}>
                                <LogoutIcon className={'logout'}/>
                            </button>
                        </div>
                    </div>
                </div>
                <Col xs={12} className={'py-2'}/>
                <div className={'w-100'}>
                    <HeaderSearchInput/>
                </div>
            </Col>
        </>
    );
}

export default LoggedInHeaderContent
