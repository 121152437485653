import React, {useLayoutEffect, useMemo, useRef} from "react";
import useWindowViewportWidth from "../../../../../hooks/use-window/viewport-width";
import ObserversService from "../../../../../../core/services/observers-service";

const HomeViewChtpPattern = ({contentRef}) => {
    const viewportWidth = useWindowViewportWidth();
    /**@type{React.MutableRefObject<HTMLDivElement>}*/
    const patternRef = useRef();

    /**
     * As soon as the component mounts:
     * - attaches an observer that is responsible for syncing the height of the pattern with the content.
     */
    useLayoutEffect(() => {
        if (!contentRef || !patternRef.current)
            return;
        const observer = ObserversService.newResizeObserver(syncPatternHeight);
        ObserversService.observeResizeObserver(observer, contentRef);
        syncPatternHeight();
        return () => ObserversService.disconnectResizeObserver(observer);
    }, [contentRef, patternRef])

    /**
     * Syncs the height of the pattern with the content
     */
    const syncPatternHeight = () => {
        if (!contentRef)
            return;

        const contentHeight = contentRef?.getBoundingClientRect()?.height ?? 0;
        if (!patternRef.current)
            return;

        patternRef.current.style.height = `${contentHeight}px`;
    }


    /**
     * Sets the style of pattern div with each change in the height of main page pattern
     */
    const patternStyle = useMemo(() => {
        switch (viewportWidth) {
            case 'xxl':
                return {width: '600px'};
            case 'xl':
                return {width: '500px'};
            case 'lg':
                return {width: '400px'};
            default:
                return {width: '200px'};

        }
    }, [viewportWidth]);

    return (
        <>
            <div
                className={'home-view-chtp-pattern-container'}
                style={patternStyle}
                ref={patternRef}
            />
        </>
    )
}

export default HomeViewChtpPattern;
