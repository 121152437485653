import React, {RefCallback, RefObject, useCallback} from "react";

export type UserRef<E> = RefObject<E> | RefCallback<E>;

/**
 * Combines the given list of refs so they can all be attached to a single element.
 * @param { UserRef<E>[]} refs
 */
const useCombinedRefs = <E, >(...refs: UserRef<E>[]): RefCallback<E> => {

    /**
     * Assigned the element to all the given refs of this hook.
     */
    return useCallback((elm) => {
        refs.forEach((ref, i) => {
            if (typeof ref === 'function') {
                ref(elm);
            } else {
                (ref as React.MutableRefObject<E | null>).current = elm;
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, refs);
}

export default useCombinedRefs;
