import React, {useContext, useEffect, useLayoutEffect, useMemo} from "react";
import {matchPath, useLocation, useRouteMatch} from "react-router-dom";
import {DynamicContentEditorMode} from "../../../index.d";
import DynamicContentEditorApi from "../../../core/services/api";
import useIsMounted from "../../hooks/use-is-mounted";
import {DynamicContentEditorControllerContext, DynamicContentEditorStateContext} from "../../contexts";

const DynamicContentEditorControllerMiddleware = ({notInIframe, route}) => {
    const location = useLocation();
    const controller = useContext(DynamicContentEditorControllerContext);
    const state = useContext(DynamicContentEditorStateContext);
    const initiationRoute = useRouteMatch({path: route, exact: true});
    const isMounted = useIsMounted();

    /**
     * Whether the DCE should be in edit mode or not.
     * if the current window is an iframe, and we are not in the initial route of the DCE, then we are in edit mode.
     * @type {boolean}
     */
    const inEditMode = useMemo(() => !notInIframe && !initiationRoute, [initiationRoute, notInIframe])

    /**
     * with each change in the [inEditMode] value:
     * - sets the mode of the DCE to enable all the subcomponents to add/remove their edit-specific gadgets.
     */
    useLayoutEffect(() => {
        controller.setMode(inEditMode ? DynamicContentEditorMode.edit : DynamicContentEditorMode.view)
    }, [inEditMode, controller])


    /**
     * With each change in the pathname of the [location] and the routeToPageMap of the [state]:
     * - sets the current page of the DCE.
     */
    useLayoutEffect(() => {
        const page = Object.entries(state.routeToPageMap)?.find(e => matchPath(
            location.pathname,
            {path: e[0], exact: false}
        ))?.[1];
        controller.setCurrentPage(page);
    }, [location.pathname, controller, state.routeToPageMap]);

    /**
     * As soon as the component mounts:
     * - fetches the fixed data entries of the DCE.
     */
    useEffect(() => {
        getPageData().then();
    }, [])

    /**
     * With each change in the currentPage of the state:
     * - fetches the page data of the current page if its not loading.
     */
    useEffect(() => {
        if (!state.currentPage)
            return;
        const loading = state.loading[state.currentPage];
        if (loading)
            return;
        getPageData(state.currentPage).then();
    }, [state.currentPage])

    /**
     * Fetches the data associated with the current page of the DCE from the server.
     *
     * * if no page is provided, then only fetches the fixed data entries.
     * * if the response of the api is successful, sets the age information in the state.
     *
     * @param {string | undefined} page
     * @return {Promise<void>}
     */
    const getPageData = async (page = undefined) => {
        if (page)
            controller.setLoading(page, true);
        const response = await DynamicContentEditorApi.getPageData(page);
        if (page)
            controller.setLoading(page, false);
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            controller.setPageInfo(response.data, page)
        }
    }

    return (<></>);
}


export default DynamicContentEditorControllerMiddleware;
