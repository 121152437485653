import React, {forwardRef, useState} from "react";
import {TextField as FormTextField} from "mui-rff";
import {TextField as MuiTextField} from "@material-ui/core";
import classnames from "classnames";
import {ReactComponent as EyeIcon} from '../../../../assets/images/password-input/eye.svg'
import {ReactComponent as EyeInvisibleIcon} from '../../../../assets/images/password-input/eye-invisible.svg'

/**
 *
 * @param {boolean} form whether this input is a form input or not.
 * @param {boolean} fullWidth
 * @param {string} className
 * @param {string} type
 * @param {import("mui-rff").TextFieldProps & import("@material-ui/core").OutlinedTextFieldProps} props
 */
const MuiInput = ({form = false, fullWidth = true, className, type: typeProp, ...props}, ref) => {
    const passwordInput = typeProp === 'password';
    const [show, setShow] = useState(false);

    const toggleShow = () => {
        setShow(prevState => !prevState);
    }

    /**
     * Custom error function that only shows error when form has been submitted and not changed
     * @param submitError
     * @param error
     * @return {boolean}
     */
    const customShowErrorFunction = ({meta: {error, submitFailed}}) => {
        return (submitFailed && error);
    }

    return (
        <>
            {
                form
                    ? <FormTextField
                        ref={ref}
                        showError={customShowErrorFunction}
                        className={classnames(className, 'mui-input')}
                        variant={'outlined'}
                        fullWidth={fullWidth}
                        type={!passwordInput ? typeProp : !show ? 'password' : 'text'}
                        {...(passwordInput
                                ? {
                                    InputProps: {
                                        endAdornment: (
                                            <>
                                                {
                                                    !show
                                                        ? <EyeIcon className={'cursor-pointer-hover mr-2'}
                                                                   onClick={toggleShow}/>
                                                        : <EyeInvisibleIcon className={'cursor-pointer-hover mr-2'}
                                                                            onClick={toggleShow}/>
                                                }
                                            </>
                                        )
                                    }
                                }
                                : {}
                        )}
                        {...props}
                    />
                    : <MuiTextField
                        ref={ref}
                        className={classnames(className, 'mui-input')}
                        variant={'outlined'}
                        fullWidth={fullWidth}
                        type={!passwordInput ? typeProp : show ? 'password' : 'text'}
                        {...(passwordInput
                                ? {
                                    InputProps: {
                                        endAdornment: (
                                            <>
                                                {
                                                    !show
                                                        ?
                                                        <EyeIcon className={classnames('cursor-pointer-hover mr-3')}
                                                                 onClick={toggleShow}/>
                                                        :
                                                        <EyeInvisibleIcon className={classnames('cursor-pointer-hover mr-2')}
                                                                          onClick={toggleShow}/>

                                                }
                                            </>
                                        )
                                    }
                                }
                                : {}
                        )}
                        {...props}
                    />
            }
        </>
    )
}

export default forwardRef(MuiInput);
