import * as Yup from "yup";
import React, {useEffect, useState} from "react";
import {makeRequired, makeValidate} from "mui-rff";
import {Col, Modal, ModalBody, ModalFooter, ModalHeader, Nav, NavItem, NavLink, Row} from "reactstrap";
import ValidateMessages from "../../../../core/constants/validate-messages";
import useIsMounted from "../../../hooks/use-is-mounted";
import classnames from "classnames";
import {AddressTypes} from "../../../../core/constants/enums";
import MuiInput from "../../base/input/mui-input";
import Form from "../../base/form";
import MuiSelect from "../../base/select";
import MuiCheckbox from "../../base/check-box";
import {IconButton} from "@material-ui/core";
import {Close} from "@material-ui/icons";


const schema = Yup.object().shape({
    title: Yup.string().nullable().required(ValidateMessages.required),
    country: Yup.object().nullable().required(ValidateMessages.required),
    province: Yup.object().nullable().required(ValidateMessages.required),
    city: Yup.string().nullable().required(ValidateMessages.required),
    postalCode: Yup.string().nullable().required(ValidateMessages.required),
    streetAddress: Yup.string().nullable().required(ValidateMessages.required),
    aptNo: Yup.string().nullable(),
    isDefault: Yup.boolean().nullable().default(false),
});

/**
 * Creates the initial values of the form.
 * @param  address
 * @param {any} countryAndState
 * @return {{country: unknown, isDefault: (*|boolean), province: unknown, city: *, streetAddress: *, postalCode: (string|string|*), title: *, aptNo: any}}
 */
const createInitialValues = (address, countryAndState) => {
    return {
        title: address?.title,
        country: countryAndState?.countries?.find(e => e.id === address?.province?.country?.id),
        province: countryAndState?.provinces?.find(e => e.id === address?.province?.id),
        city: address?.city,
        postalCode: address?.postalCode,
        aptNo: address?.aptNo,
        streetAddress: address?.streetAddress,
        isDefault: address?.isDefault ?? false,
    };
}

const UpsertAddressDialog = ({
                                 open,
                                 duplicate,
                                 setOpen,
                                 createAddress,
                                 updateAddress,
                                 countryAndState,
                                 address,
                                 addressType
                             }) => {
    const [loading, setLoading] = useState(false);
    const [initialValues, setInitialValues] = useState({});
    const [tab, setTab] = useState(addressType?.id);
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const isMounted = useIsMounted();


    /**
     * Listens for the changes in address and with each change:
     * - sets the values of initialValues related to the form
     */
    useEffect(() => {
        setInitialValues(createInitialValues(address, countryAndState))
    }, [address])

    /**
     * Listens for the changes in addressType and with each change:
     * - sets the tab to the id of the addressType.
     */
    useEffect(() => {
        setTab(addressType?.id);
    }, [addressType])

    /**
     * Creates / Edits the address when the user submits the form. Appends the user id and removes the country of
     * the form values.
     * @param values {any} the form values.
     */
    const onSubmit = async (values) => {
        // In case address is being duplicated
        if (duplicate) {
            const _address = {
                ...values,
                type: {
                    id: tab
                },
            };
            const _resultFlag = await createAddress(_address);
            if (!isMounted()) return;
            setLoading(false);
            if (_resultFlag) {
                setOpen(false);
            }
            return;
        }

        const _address = {
            ...values,
            id: address?.id,
            type: {
                id: tab
            },
        };

        let resultFlag;
        setLoading(true);
        if (address) {
            resultFlag = await updateAddress(_address);
        } else {
            resultFlag = await createAddress(_address);
        }
        if (!isMounted()) return;
        setLoading(false);
        if (resultFlag) {
            setOpen(false);
        }
    }

    /**
     * Removes the province of the form as soon as the country of the form changes.
     * @param {formApi} form
     */
    const onCountryChange = (form) => {
        form.change('province', null);
        form.resetFieldState('province');
    }


    return (
        <>
            <Modal size={'md'}
                   isOpen={open}
                   backdrop centered
                   className={'upsert-address-dialog'}
                   toggle={() => setOpen(false)}>
                <ModalHeader>
                    <div className={'d-flex flex-row justify-content-between align-items-center'}>
                        <div>
                            {
                                duplicate
                                    ? "Duplicate Address"
                                    : address
                                        ? address.isDefault
                                            ? `Edit Default ${addressType.title} Address`
                                            : "Edit Address"
                                        : "Create Address"
                            }
                        </div>
                        <div>
                            <IconButton aria-label={"cancel"} onClick={() => setOpen(false)}>
                                <Close/>
                            </IconButton>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {
                        !address?.isDefault &&
                        <Nav tabs className={'p-0 nav-dialog'}>
                            {
                                Object.values(AddressTypes).map((e) => (
                                    <NavItem key={e.id}>
                                        <NavLink
                                            className={classnames({'active': e.id === tab})}
                                            onClick={() => setTab(e.id)}>
                                            {e.title}
                                        </NavLink>
                                    </NavItem>
                                ))
                            }
                        </Nav>
                    }

                    <Form
                        className='w-100'
                        onSubmit={onSubmit}
                        validate={validate}
                        initialValues={initialValues}
                        id={'upsert-address-form'}
                        render={({values, form}) => {
                            return (
                                <Row>
                                    <Col xs={12} className={'mt-3'}>
                                        <MuiInput
                                            form
                                            label={"Title"}
                                            placeholder={'Title'}
                                            name={'title'}
                                            required={required.title}
                                        />
                                    </Col>
                                    <Col xs={12} sm={9}>
                                        <MuiInput
                                            form
                                            label={'Street Address'}
                                            placeholder={'Street Address'}
                                            name={'streetAddress'}
                                            required={required.streetAddress}
                                        />
                                    </Col>
                                    <Col xs={12} sm={3}>
                                        <MuiInput
                                            form
                                            label={'Apt number'}
                                            placeholder={'Apt, Suit, etc'}
                                            name={'aptNo'}
                                            required={required.aptNo}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <MuiInput
                                            form
                                            label={"City"}
                                            placeholder={'city'}
                                            name={'city'}
                                            required={required.city}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <MuiSelect
                                            form
                                            name={'country'}
                                            label={"Country"}
                                            onChange={() => onCountryChange(form)}
                                            required={!!required.country}
                                            data={countryAndState?.countries?.map(e => ({
                                                value: e,
                                                label: e.name,
                                                id: e.id,
                                            })) ?? []}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <MuiSelect
                                            form
                                            name={'province'}
                                            label={"Province"}
                                            required={!!required.province}
                                            data={
                                                countryAndState?.provinces
                                                    ?.filter(e => e.countryId === values?.country?.id)
                                                    ?.map(e => ({
                                                        value: e,
                                                        label: e.name,
                                                        id: e.id,
                                                    })) ?? []
                                            }
                                        />
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <MuiInput
                                            form
                                            label={"Postal Code"}
                                            placeholder={'postal code'}
                                            name={'postalCode'}
                                            required={required.postalCode}
                                        />
                                    </Col>
                                    {
                                        !address?.isDefault &&
                                        <Col xs={12} className={'mb-0 ml-1'}>
                                            <MuiCheckbox
                                                form
                                                name={'isDefault'}
                                                required={required.isDefault}
                                                disabled={address?.isDefault}
                                                data={{
                                                    value: values?.isDefault ?? false,
                                                    label: "Is Default"
                                                }}
                                            />
                                        </Col>
                                    }

                                </Row>
                            );
                        }}
                    />
                </ModalBody>
                <ModalFooter className={'d-flex align-items-center justify-content-end'}>
                    <button
                        disabled={!open || loading}
                        className={'button primary px-5'}
                        form={'upsert-address-form'}
                        type={'submit'}>
                        {
                            loading
                                ? "Submitting..."
                                : duplicate || !address
                                    ? "Create Address"
                                    : "Update Address"
                        }
                    </button>
                </ModalFooter>
            </Modal>
        </>
    );

}

export default UpsertAddressDialog;

