import React, {useContext, useEffect, useLayoutEffect, useMemo, useState} from 'react';
import {Alert, Col, Container, Row} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {loginUser, policyDialog} from "../../../../redux/actions";
import ValidateMessages from "../../../../core/constants/validate-messages";
import routes, {DCEEntryNames} from "../../../routes";
import api from "../../../../core/services/api/api";
import {authApis, userApis} from "../../../../core/constants/endpoints/endpoints";
import {AlertTypes, apiMethods, LandingPageFormTypes} from "../../../../core/constants/enums";
import useIsMounted from "../../../hooks/use-is-mounted";
import useAlert from "../../../hooks/use-alert";
import useRouter from "../../../hooks/use-router";
import EnvService from "../../../../core/services/env-service";
import RegisterNewUserForm from "../../../components/app-specific/forms/register-new-user-form";
import LoginForm from "../../../components/app-specific/forms/login-form";
import {matchPath, Route, Switch} from "react-router-dom";
import ForgetPasswordLandingForm from "../../../components/app-specific/forms/forget-password-landing-form";
import {ReactComponent as PartLookUpIcon} from "../.././../../assets/images/new-landing-icons/part-look-up.svg";
import {ReactComponent as PartAccessIcon} from "../.././../../assets/images/new-landing-icons/access-to-parts.svg";
import {ReactComponent as PartAvailability} from "../.././../../assets/images/new-landing-icons/real-time-availability.svg";
import {ReactComponent as LoyaltyIcon} from "../.././../../assets/images/new-landing-icons/acess-to-loyalty.svg";
import {Tooltip} from "@material-ui/core";
import {
    DynamicContentEditorEntry,
    DynamicContentEditorEntryRecord,
    DynamicContentEditorListEntry,
    DynamicContentEditorModeContext,
    DynamicContentEditorModels
} from "../../../../packages/dynamic-content-editor/index";
import classnames from "classnames";
import NoImage from "../../../../assets/images/no-image.png";
import CacheService from "../../../../core/services/cache/cache-service";


const EntryView = () => {
    const {history, location, query} = useRouter();
    const [registering, setRegistering] = useState(false);
    const [validating, setValidating] = useState(false);
    const [alert, setAlert, resetAlert] = useAlert(8000);
    const isMounted = useIsMounted();
    const dispatch = useDispatch();
    const [formType, setFormType] = useState(LandingPageFormTypes.login);
    const {message, loading} = useSelector(state => state?.auth ?? {message: null, loading: false})
    const [brands, setBrands] = useState([]);
    const dceMode = useContext(DynamicContentEditorModeContext);
    const isDceInEditMode = useMemo(() => dceMode === DynamicContentEditorModels.DynamicContentEditorMode.edit, [dceMode]);
    const [disclaimerTooltipOpen, setDisclaimerTooltipOpen] = useState(isDceInEditMode);

    /**
     * With each change in the [isDceInEditMode] value and the length of the [brands]:
     * - if isInEditMode, then opens the disclaimer tooltip to allow it to be editable
     * - if the brands list is empty, closes the tooltip as there is nothing to show
     */
    useLayoutEffect(() => {
        if (isDceInEditMode) {
            return setDisclaimerTooltipOpen(true);
        }
        if (!brands?.length) {
            return setDisclaimerTooltipOpen(false);
        }
    }, [isDceInEditMode, brands.length])

    /**
     * WIth each change in the location's pathname:
     * - if the user is in the base auth route, navigates the user to the login route.
     * - sets the correct form type based on the current url of the website.
     */
    useEffect(() => {
        if (!!matchPath(location.pathname, {path: routes.auth.base, exact: true})) {
            return history.replace(routes.auth.login);
        }
        if (matchPath(location.pathname, routes.auth.login)) {
            return setFormType(LandingPageFormTypes.login);
        }
        if (matchPath(location.pathname, routes.auth.signUp)) {
            return setFormType(LandingPageFormTypes.registration);
        }
        if (matchPath(location.pathname, routes.auth.forgetPassword)) {
            return setFormType(LandingPageFormTypes.forgetPassword);
        }
    }, [location.pathname])

    /**
     * Listens for the changes in the message state of the auth redux slice, and with each change:
     * - if there is no message, then closes the alert,
     * - else opens the alert since there is an error from the server that needs to be displayed.
     */
    useEffect(() => {
        // if message is gone, so should the alert
        if (!message?.length) {
            return resetAlert();
        }
        // message exists, so there is an error from the server.
        setAlert({isOpen: true, message: message, color: AlertTypes.error})
    }, [message])

    /**
     * Listens for the changes in the type of the form and with each change:
     * - closes the alert
     */
    useEffect(() => {
        resetAlert();
    }, [formType])


    /**
     * Un masks the phone number from the form input that is in standard US format (***)***-**** to pure string
     * @param  phoneNumber {string}
     */
    const unMaskPhoneNumber = (phoneNumber) => {
        return phoneNumber.substring(1, 4).concat(phoneNumber.substring(5, 8)).concat(phoneNumber.substring(9, 13))
    }

    /**
     * Changes the value of the checkbox.
     *
     * - if values is false, then opens the privacy policy dialog as the user clicks on the policy icon.
     * @param {boolean} value the new value of the checkbox
     * @param {formApi} form
     */
    const onCheckboxChanged = (value, form) => {
        if (value) {
            dispatch(policyDialog({
                open: true,
                mandatory: false,
                callback: (v) => {
                    form.change('checkbox', v)
                },
            }))
        }
        form.change('checkbox', false)
    }


    /**
     * Registers a new user into the system. This registration requires the user to enter all the necessary
     * information.
     *
     * An operator will then be able to assign a customer to the user based on their provided information.
     * @param {any} values form values
     */
    const registerNewUser = async (values) => {
        if (!values.checkbox) {
            return {checkbox: ValidateMessages.acceptPrivacyPolicy,}
        }
        if (values.password !== values.confirmPassword) {
            return setAlert({isOpen: true, message: ValidateMessages.passwordsMatch, color: AlertTypes.warning})
        }

        setRegistering(true);
        resetAlert()
        const response = await api({
            url: authApis.register,
            method: apiMethods.post,
            showError: false,
            loginRequired: false,
            headers: {
                PartnerId: EnvService.DemoPartnerId,
            },
            data: {
                firstname: values.firstName,
                lastname: values.lastName,
                email: values.email,
                password: values.password,
                phone: values.phone,
                provinceId: values.province?.Id,
                city: values.city,
                address: values.address,
                postalCode: values.postalCode,
                company: values.company,
                companyPhone: values.companyPhone,
            },

        });
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setRegistering(false);
            return;
        }
        setAlert({
            isOpen: true,
            message: response?.message ?? '',
            color: response?.resultFlag ? AlertTypes.success : AlertTypes.error
        })
        setRegistering(false);
    }

    /**
     * Dispatches the action for logging user into the system with values received from the login form.
     * @param values
     */
    const onUserLogin = (values) => {
        const _values = {
            account: values['email'],
            password: values['password'],
            remember: values['remember']
        }
        dispatch(loginUser(_values, () => {
            if (!isMounted())
                return;
            const cachedUrl = CacheService.getCachedUrl();
            if (cachedUrl)
                return history.replace(cachedUrl);
            return history.replace(routes.landing);
        }))
    }

    /**
     * Calls the api regarding the reset your password with the form values data.
     *
     * * if we must select a partner, and none is selected, shows an alert to the user.
     * * if the api call was successful, shows success alert to user otherwise, shows error alert
     * @param values
     * @returns {Promise<void>}
     */
    const onResetPassword = async (values) => {
        if ((values.partnerON || values.partnerBC) || !EnvService.ShowForgetPasswordCheckboxes) {
            const response = await api({
                url: userApis.forgetPassword(values.email),
                method: apiMethods.get,
                loginRequired: false,
                headers: {
                    PartnerId: EnvService.DemoPartnerId,
                },
            })
            if (!isMounted()) return
            if (response?.isPreemptedDueToNotBeingLoggedIn)
                return;
            return setAlert({
                isOpen: true,
                message: response?.message ?? '',
                color: response?.resultFlag ? AlertTypes.success : AlertTypes.error,
            })
        }
        setAlert({
            isOpen: true,
            message: "Ensure that you have selected a partner before proceeding",
            color: AlertTypes.error
        })
    };

    /**
     * Handles the changes between registration and login and forgetpassword
     * sets the [formType] to the received value
     * Changes the url path based on the option
     */
    const changeLoginRegister = (type) => {
        setFormType(type);
        switch (type) {
            case LandingPageFormTypes.login:
            default:
                history.replace(routes.auth.login);
                break;
            case LandingPageFormTypes.registration:
                history.replace(routes.auth.signUp);
                break;
            case LandingPageFormTypes.forgetPassword:
                history.replace(routes.auth.forgetPassword)
        }

    }


    return (
        <>
            <Container className={'auth-entry-view'}>
                <Row>
                    <Col lg={7} xs={12} className={'order-sm-2 order-lg-1'}>
                        <div className="d-flex flex-column">
                            <h1>Login to your <span>account </span> <br/>
                                to have access to
                            </h1>
                            {
                                <div className={'mt-4'}>
                                    <div className={'d-flex flex-row align-items-center mb-3'}>
                                        <PartLookUpIcon className={'mr-2'}/>
                                        <p className={'login-features'}>
                                            Easy and Accurate part lookup
                                        </p>
                                    </div>
                                    <div className={'d-flex flex-row align-items-center mb-3'}>
                                        <PartAccessIcon className={'mr-2'}/>
                                        <p className={'login-features'}>
                                            Access to numerous parts from West to East
                                        </p>
                                    </div>
                                    <div className={'d-flex flex-row align-items-center mb-3'}>
                                        <PartAvailability className={'mr-2'}/>
                                        <p className={'login-features'}>
                                            Real-time availability and pricing
                                        </p>
                                    </div>
                                    <div className={'d-flex flex-row align-items-center mb-3'}>
                                        <LoyaltyIcon className={'mr-2'}/>
                                        <p className={'login-features'}>
                                            Access to loyalty programs and more savings!
                                        </p>
                                    </div>
                                </div>

                            }
                            <div className="w-100 text-center">
                                <button
                                    className={'button learn-more-about-us-button'}
                                    onClick={() => history.push(routes.public.about)}
                                    type={'button'}>
                                    Learn More About Us
                                </button>
                            </div>
                            {
                                EnvService.ShowCustomerBrands &&
                                <Row>
                                    <Col xs={12}>
                                        <div>
                                            <Row className={'auth-view-customer-brands'}>
                                                <Tooltip
                                                    placement={'top'}
                                                    arrow={true}
                                                    classes={{
                                                        popper: 'registration-brand-disclaimer'
                                                    }}
                                                    open={disclaimerTooltipOpen}
                                                    {...(
                                                        isDceInEditMode
                                                            ? {interactive: true}
                                                            : {
                                                                onOpen: () => setDisclaimerTooltipOpen(true),
                                                                onClose: () => setDisclaimerTooltipOpen(false),
                                                            }
                                                    )}
                                                    title={
                                                        <DynamicContentEditorEntry
                                                            dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.pageBased}
                                                            iconPosition={DynamicContentEditorModels.DynamicContentEditorEntryIconPositions.outward}
                                                            entryName={DCEEntryNames.entryBrandsDisclaimer}
                                                            _title={'Customer Brands Disclaimer'}
                                                            tag={'div'}
                                                        >
                                                            <DynamicContentEditorEntryRecord
                                                                recordKey={'tooltip-title'}
                                                                _title={'Title'}
                                                                tag={'div'}
                                                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.multiline}
                                                            />
                                                        </DynamicContentEditorEntry>
                                                    }
                                                >
                                                    <DynamicContentEditorListEntry
                                                        _title={'Customer Brands'}
                                                        entryName={DCEEntryNames.entryBrands}
                                                        dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.pageBased}
                                                        iconPosition={DynamicContentEditorModels.DynamicContentEditorEntryIconPositions.outward}
                                                        tag={'div'}
                                                        className={'customer-brand'}
                                                        getData={setBrands}
                                                        childTag={Col}
                                                        childProps={{
                                                            xs: 4,
                                                            className: classnames("customer-brand-inner", {
                                                                'mb-5': isDceInEditMode,
                                                                'mb-4': !isDceInEditMode,
                                                            }),
                                                        }}
                                                    >
                                                        <div>
                                                            <DynamicContentEditorEntryRecord
                                                                recordKey={'alt'}
                                                                _title={'Title'}
                                                                tag={'p'}
                                                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                                                className={'d-none'}
                                                            />
                                                            <DynamicContentEditorEntryRecord
                                                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.element}
                                                            >
                                                                {
                                                                    (item) => (
                                                                        <img
                                                                            data-dce-record-key={'src'}
                                                                            data-dce-record-value-type={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.image}
                                                                            data-dce-record-value={item.src}
                                                                            data-dce-record-title={"Brand Image"}
                                                                            className={classnames({'temp': !item.src})}
                                                                            src={item.src ?? NoImage}
                                                                            alt={item.alt}
                                                                        />
                                                                    )
                                                                }
                                                            </DynamicContentEditorEntryRecord>
                                                        </div>
                                                    </DynamicContentEditorListEntry>
                                                </Tooltip>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            }
                        </div>
                    </Col>
                    <Col lg={5} xs={12} className={'order-sm-1 order-lg-2'}>
                        <div className={"auth-form-box"}>
                            <div className="box-title">
                                {
                                    formType === LandingPageFormTypes.login
                                        ? "Log In" :
                                        formType === LandingPageFormTypes.registration
                                            ? "Sing Up"
                                            : "Forget Password"
                                }
                            </div>
                            <Alert color={alert.color} isOpen={alert.isOpen}>
                                {alert.message}
                            </Alert>
                            <Switch>
                                <Route path={routes.auth.signUp} exact>
                                    <RegisterNewUserForm
                                        onSubmit={registerNewUser}
                                        validating={validating}
                                        setValidating={setValidating}
                                        registering={registering}
                                        onCheckBoxChanged={onCheckboxChanged}
                                        changeLoginRegister={changeLoginRegister}
                                    />
                                </Route>
                                <Route path={routes.auth.login}>
                                    <LoginForm
                                        onSubmit={onUserLogin}
                                        loading={loading}
                                        changeLoginRegister={changeLoginRegister}
                                    />
                                </Route>
                                <Route path={routes.auth.forgetPassword}>
                                    <ForgetPasswordLandingForm
                                        onSubmit={onResetPassword}
                                        changeLoginRegister={changeLoginRegister}
                                    />
                                </Route>
                            </Switch>
                        </div>
                    </Col>
                </Row>
            </Container>

        </>
    );

}


export default EntryView;


