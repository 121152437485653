import React from "react";
import {TextField} from "@mui/material";
import classnames from "classnames";

/**
 * @param {string} className
 * @param {import('@mui/material').TextFieldProps} props
 * @return {JSX.Element}
 * @constructor
 */
const DataGridInput = ({className, ...props}) => {

    return (
        <>
            <TextField
                className={classnames(className, 'data-grid-input')}
                {...props}
            />
        </>
    )
}

export default DataGridInput;
