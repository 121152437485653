import React, {useEffect, useMemo, useState} from "react";
import * as Yup from "yup";
import ValidateMessages from "../../../../../core/constants/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import useIsMounted from "../../../../hooks/use-is-mounted";
import api from "../../../../../core/services/api/api";
import {shoppingCartApis, userCardsApis} from "../../../../../core/constants/endpoints/endpoints";
import {numComparator, stringComparator} from "../../../../../core/services/utils/utils";
import {Col, Row} from "reactstrap";
import MuiInput from "../../../base/input/mui-input";
import Form from "../../../base/form";
import {apiMethods} from "../../../../../core/constants/enums";
import {
    DataGridColumnAlignments,
    DataGridColumnPinnedTypes,
    DataGridColumnTypes
} from "../../../../../packages/data-grid/models";
import classnames from "classnames";
import AppDataGrid from "../../../../containers/data-grid";

const schema = Yup.object().shape({
    cardNumber: Yup.string().nullable().required(ValidateMessages.required),
})

// the keys used for the table
const dataGridColumnNames = {
    cardNumber: 'cardNumber',
    amount: 'amount',
    category: 'category',
    select: 'select',
};

const AddGiftCardOrCreditToShoppingCartDialogGiftCardsSection = ({onSuccess}) => {
    const [redeeming, setRedeeming] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cards, setCards] = useState([]);
    const [orderBy, setOrderBy] = useState(null);
    const [orderByMethod, setOrderByMethod] = useState(() => (a, b) => 0);
    const validate = makeValidate(schema);
    const required = makeRequired(schema);
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts:
     * - loads user gift cards from the server and sets the state list.
     */
    useEffect(() => {
        getUserGiftCards();
    }, [])


    /**
     * Loads the valid user gift cards from the server and sets the state list.
     */
    const getUserGiftCards = () => {
        setLoading(true);
        api({
            url: userCardsApis.getListOfGiftCards(false),
            method: apiMethods.get,
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setLoading(false);
                return;
            }
            if (response?.resultFlag) {
                setCards(response?.data ?? []);
            }
            setLoading(false);
        });
    }

    /**
     * Adds the selected gift card to this shopping cart.
     * @param {any} card the selected gift card.
     * @return {Promise<void>}
     */
    const onCardSelected = async (card) => {
        setUpdating(true);
        setCards(prevState => prevState?.map(e => e.cardNumber === card.cardNumber ? ({...e, updating: true}) : e));
        const response = await api({
            url: shoppingCartApis.addGiftCard,
            method: apiMethods.put,
            data: {
                giftCardNumber: card.cardNumber,
            },
        })
        if (!isMounted()) return;
        setUpdating(false);
        setCards(prevState => prevState?.map(e => e.cardNumber === card.cardNumber ? ({...e, updating: false}) : e));
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        if (!response?.resultFlag) return
        onSuccess(response?.data ?? []);
    }

    /**
     * Redeems a new gift card for this user and then if the result is successful, then adds the gift card to this
     * shopping cart.
     * @param {any} values form values
     * @param {formApi} form form api
     * @return {Promise<void>}
     */
    const redeemGiftCard = async (values, form) => {
        setRedeeming(true);
        const response = await api({
            url: userCardsApis.redeemGiftCard + `/${values.cardNumber}`,
            method: apiMethods.post,
        });
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setRedeeming(false);
            return;
        }
        if (!response?.resultFlag) {
            setRedeeming(false);
            return;
        }
        setCards(prevState => [response.data, ...prevState]);
        form.resetFieldState('cardNumber')
        setRedeeming(false);
        await onCardSelected(response.data)
    }

    /**
     * Sorts the entries of the cards in place.
     * @param {DataGridSortBy} tableOrderBy
     */
    const onSort = (tableOrderBy) => {
        setOrderBy(tableOrderBy);
        switch (tableOrderBy?.field) {
            case dataGridColumnNames.cardNumber:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? stringComparator(a?.cardNumber ?? '', b?.cardNumber ?? '')
                    : stringComparator(b?.cardNumber ?? '', a?.cardNumber ?? '')
                )
            case dataGridColumnNames.category:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? stringComparator(a?.category?.name ?? '', b?.category?.name ?? '')
                    : stringComparator(b?.category?.name ?? '', a?.category?.name ?? '')
                )
            case dataGridColumnNames.amount:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? numComparator(a?.currentBalance ?? 0, b?.currentBalance ?? 0)
                    : numComparator(b?.currentBalance ?? 0, a?.currentBalance ?? 0)
                )
            default:
                return setOrderByMethod(() => (a, b) => 0)
        }
    }

    const dataGridColumns = useMemo(() =>
            /**@type {DataGridColumn[]}*/
            [
                {
                    title: 'Card Number',
                    name: dataGridColumnNames.cardNumber,
                    alignment: DataGridColumnAlignments.left,
                    type: DataGridColumnTypes.string,
                },
                {
                    title: 'Category',
                    name: dataGridColumnNames.category,
                    alignment: DataGridColumnAlignments.left,
                    type: DataGridColumnTypes.string,
                    width: 130,
                },
                {
                    title: 'Amount',
                    name: dataGridColumnNames.amount,
                    alignment: DataGridColumnAlignments.right,
                    type: DataGridColumnTypes.money,
                    width: 80,
                },
                {
                    name: dataGridColumnNames.select,
                    alignment: DataGridColumnAlignments.center,
                    type: DataGridColumnTypes.element,
                    sortable: false,
                    pinned: true,
                    pinnedToggleable: false,
                    width: 60,
                    pinnedType: DataGridColumnPinnedTypes.right,
                },
            ]
        , [])

    /**@type {DataGridRow[]}*/
    const dataGridRows = useMemo(() => {
        return Array.from(cards)
                ?.sort(orderByMethod)
                ?.map((card) => ({
                    key: card.id,
                    data: card,
                    cells: {
                        [dataGridColumnNames.cardNumber]: card?.cardNumber,
                        [dataGridColumnNames.category]: card?.category?.name,
                        [dataGridColumnNames.amount]: card?.currentBalance,
                        [dataGridColumnNames.select]:
                            <button
                                disabled={updating}
                                className='button primary outlined px-3'
                                onClick={() => onCardSelected(card)}>
                                {
                                    card?.updating
                                        ? 'processing...'
                                        : "Select"
                                }
                            </button>,
                    }
                }))
            ?? []
    }, [cards, orderByMethod, updating])

    return (
        <>
            <div className={'gift-cards'}>
                <Form
                    className={'mx-4'}
                    onSubmit={redeemGiftCard}
                    validate={validate}
                    initialValues={{}}
                    render={() => {
                        return (
                            <Row className={'mx-4'}>
                                <div className={'d-flex w-100'}>
                                    <p className={'title'}>
                                        Redeem a Gift Card
                                    </p>
                                </div>
                                <Col className={'flex-grow-1 pl-0'}>
                                    <MuiInput
                                        form
                                        placeholder={'input your gift card number here'}
                                        name={'cardNumber'}
                                        required={required.cardNumber}
                                    />
                                </Col>
                                <div className={'d-flex align-items-center'}>
                                    <button className={'button primary px-4'}
                                            disabled={redeeming}
                                            type={'submit'}>
                                        {
                                            redeeming
                                                ? "redeeming..."
                                                : "Redeem and Use"
                                        }
                                    </button>
                                </div>
                            </Row>
                        );
                    }}
                />
                <div className={'dots'}>
                    <div/>
                    <p className={'title'}>
                        Your available gift cards
                    </p>
                </div>
                <AppDataGrid
                    hideFooter
                    hideExporting
                    classNames={{
                        container: classnames('gift-card-selection-table mb-3'),
                    }}
                    rows={dataGridRows}
                    columns={dataGridColumns}
                    state={{
                        loading: {state: loading},
                        sortBy: orderBy,
                    }}
                    onSortByChanged={(_, current) => onSort(current)}
                />
            </div>
        </>
    )
}

export default AddGiftCardOrCreditToShoppingCartDialogGiftCardsSection;
