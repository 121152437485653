import React, {useEffect, useMemo, useState} from 'react'
import {Col, Row} from "reactstrap";
import {userCardsApis} from "../../../../core/constants/endpoints/endpoints";
import api from "../../../../core/services/api/api";
import CreateCreditCardDialog from "../../../components/dialogs/create-credit-card-dialog";
import {formatMoney, getGiftCardNumber, stringComparator} from "../../../../core/services/utils/utils";
import CreditCard from "../../../components/app-specific/credit-card";
import {apiMethods, GiftCardTypes} from "../../../../core/constants/enums";
import classnames from "classnames";
import moment from "moment";
import RedeemGiftCardDialog from "../../../components/dialogs/redeem-gitftcard-dialog";
import useIsMounted from "../../../hooks/use-is-mounted";
import {ReactComponent as InformationIcon} from "../../../../assets/images/information-icon.svg";
import ReactCreditCards from "react-credit-cards";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";


const PaymentMethodsView = () => {
    const [addGiftCardDialog, setAddGiftCardDialog] = useState(false);
    const [addCreditCardDialog, setAddCreditCardDialog] = useState(false);
    const [creditCards, setCreditCards] = useState([]);
    const [creditCardsLoading, setCreditCardsLoading] = useState(true);
    const [giftCards, setGiftCards] = useState([]);
    const [giftCardsLoading, setGiftCardsLoading] = useState(true);
    const isMounted = useIsMounted();

    const groupedGiftCards = useMemo(() => {
        let sections = {};
        const _giftCards = giftCards ?? [];
        _giftCards.forEach(e => {
            if (e.type?.id === GiftCardTypes.refillable.id) return;
            if (sections[e.category.name]) {
                sections[e.category.name] = [...sections[e.category.name], e];
            } else {
                sections[e.category.name] = [e];
            }
        });
        const refillableGiftCard = _giftCards?.find(e => e.type?.id === GiftCardTypes.refillable.id);
        if (refillableGiftCard) {
            sections = {'Refillable': [refillableGiftCard], ...sections}
        }
        return Object
            .entries(sections)
            .sort(([keyA, valA], [keyB, valB]) => {
                if (keyA === 'Refillable') return -1
                if (keyB === 'Refillable') return 1
                return stringComparator(keyA, keyB);
            })
    }, [giftCards])

    /**
     * As soon as this component mounts:
     * - fetches the list of user credit cards from the system.
     * - fetches the list of all gift-cards in the system.
     */
    useEffect(() => {
        getCreditCards().then();
        getGiftCards().then();
    }, [])

    /**
     * Fetches the list of user credit cards from the system.
     * @return {Promise<void>}
     */
    const getCreditCards = async () => {
        setCreditCardsLoading(true);
        const response = await api({
            url: userCardsApis.getUserCreditCards,
            method: apiMethods.get,
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setCreditCardsLoading(false);
            return;
        }
        setCreditCards(response?.data?.map(e => ({...e, settingDefault: false, removing: false})) ?? [])
        setCreditCardsLoading(false);
    }

    /**
     * Fetches the list of all gift-cards in the system.
     * @return {Promise<void>}
     */
    const getGiftCards = async () => {
        const response = await api({
            url: userCardsApis.getListOfGiftCards(true),
            method: apiMethods.get,
        });
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setGiftCardsLoading(false);
            return;
        }
        setGiftCards(response?.data ?? [])
        setGiftCardsLoading(false);
    }

    /**
     * Sets the given credit card as the default card of the user.
     * @param {any} card the card to be set as the default card.
     */
    const setCreditCardAsDefault = (card) => {
        setCreditCards(prevState => prevState?.map(e => e.id === card.id ? {...e, settingDefault: true} : e))
        api({
            url: userCardsApis.setUserCardAsDefault(card.id),
            method: apiMethods.put,
            showSuccess: true,
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setCreditCards(prevState => prevState?.map(e => e.id === card.id ? {...e, settingDefault: false} : e))
                return;
            }
            if (response?.resultFlag) {
                setCreditCards(prevState => prevState?.map(e =>
                    e.isDefault && e.id !== card.id
                        ? {...e, isDefault: false}
                        : e.id === card.id
                            ? {...e, isDefault: true}
                            : e
                ))
            }
            setCreditCards(prevState => prevState?.map(e => e.id === card.id ? {...e, settingDefault: false} : e))
        })
    }

    /**
     * Removes the given credit card from the server and if the result is successful, removes them in the state list
     * as well.
     * @param {any} card the card to be removed.
     */
    const removeCreditCard = (card) => {
        setCreditCards(prevState => prevState?.map(e => e.id === card.id ? {...e, removing: true} : e))
        api({
            url: userCardsApis.removeUserCreditCard(card.id),
            method: apiMethods.delete,
            showSuccess: true,
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setCreditCards(prevState => prevState?.map(e => e.id === card.id ? {...e, removing: false} : e))
                return;
            }
            if (response?.resultFlag) {
                setCreditCards(prevState => prevState?.filter(e => e.id !== card.id))
            } else {
                setCreditCards(prevState => prevState?.map(e => e.id === card.id ? {...e, removing: false} : e))
            }
        })
    }

    /**
     * Redeems a gift card for this user.
     * @param {string} number the gift card number that is to be redeemed.
     * @return {Promise<boolean|*>}
     */
    const redeemGiftCard = async (number) => {
        const response = await api({
            url: userCardsApis.redeemGiftCard + `/${number}`,
            method: apiMethods.post,
        });
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return false;
        if (response?.resultFlag) {
            _addGiftCard(response?.data)
        }
        return response?.resultFlag;
    }

    /**
     * Adds the newly created credit card to the list of users cards.
     * @param {any} card the created card
     */
    const addCreditCard = (card) => {
        setCreditCards(prevState => [
            ...prevState,
            {
                ...card,
                settingDefault: false,
                removing: false
            },
        ])
        setAddCreditCardDialog(false);
    }

    /**
     * Adds a newly added gift card to the list of gift cards.
     * @param giftCard {any}
     * @return {void}
     * @private
     */
    const _addGiftCard = (giftCard) => {
        if (!giftCard) return
        const index = giftCards?.findIndex(e => e.cardNumber === giftCard.cardNumber) ?? -1;
        if (index === -1) {
            return setGiftCards(prevState => [
                giftCard, ...prevState,
            ])
        }
        setGiftCards(prevState => {
            prevState.listOfGiftCards.splice(Math.max(index - 1, 0), 0, [giftCard]);
            return prevState;
        })
    }

    /**
     * Fetches the issuer type of the gift card
     * @return {string}
     */
    const getIssuer = (giftCard) => {
        switch (giftCard?.type?.id) {
            case GiftCardTypes.refillable.id:
                return 'gift-card';
            case GiftCardTypes.giftCard.id:
            default:
                return 'unknown';
        }
    };

    /**
     * Toggles the add credit card dialog
     */
    const toggleAddCreditCardDialog = () => {
        setAddCreditCardDialog(prevState => !prevState)
    }

    /**
     * Toggles the redeem gift card dialog
     */
    const toggleRedeemGiftCardDialog = () => {
        setAddGiftCardDialog(prevState => !prevState)
    }


    const gifCardContent = useMemo(() => {
        return groupedGiftCards.map(([categoryName, value]) => (
            <div className='d-flex flex-wrap px-3 w-100' key={categoryName}>
                <Col xs={12} className='title mb-2'>
                    <p className='heading smaller font-weight-600'>{categoryName}:</p>
                </Col>
                <Col xs={12} className={'d-flex flex-wrap align-items-center mb-4'}>
                    {
                        value?.map((giftCard) => (
                            <div className={classnames('mb-4 mx-3')}
                                 key={giftCard.cardNumber}>
                                <ReactCreditCards
                                    preview
                                    cvc={'***'}
                                    focused={'name'}
                                    number={getGiftCardNumber(giftCard.cardNumber ?? '')}
                                    issuer={getIssuer(giftCard)}
                                    name={`${formatMoney(giftCard.currentBalance ?? giftCard.amount ?? 0.0)}`}
                                    expiry={`${giftCard?.expireDate
                                        ? moment(giftCard?.expireDate).format('MM/YY')
                                        : '**/**'}`
                                    }
                                />
                            </div>
                        ))
                    }
                </Col>
            </div>
        ))
    }, [groupedGiftCards])


    return (
        <div className={'payment-methods-view'}>
            <div>
                <div className={'sticky-header mt-2 mb-4'}>
                    <div className='heading mb-3'>
                        Credit Cards
                    </div>
                    <button className='float-right primary button px-3 mb-3'
                            onClick={toggleAddCreditCardDialog}>
                        Add A New Card
                    </button>
                </div>
                <div className={'w-100 d-flex'}>
                    <div className={"credit-card-informative"}>
                        <InformationIcon/>
                        <p>
                            Our system uses vault technology to register and store customers'
                            credit card accounting information on Moneris' secure servers.
                        </p>
                    </div>
                </div>
                {
                    creditCardsLoading
                        ? <Row className='text-center justify-content-center'>
                            <LoadingIndicator/>
                        </Row>
                        : <div className={'d-flex flex-wrap px-3 pb-3'}>
                            {
                                creditCards?.map((card) => (
                                    <CreditCard
                                        key={card.id}
                                        card={card}
                                        withWrapper
                                        removable
                                        onRemove={() => removeCreditCard(card)}
                                        onSetDefault={() => setCreditCardAsDefault(card)}
                                        settingDefault={creditCards?.some(e => !!e.settingDefault) ?? false}
                                    />
                                ))
                            }
                        </div>
                }
            </div>
            <div>
                <div className={'sticky-header mt-4'}>
                    <div className='heading mb-3'>
                        Gift Cards
                    </div>
                    <button className='float-right primary button px-3 mb-3'
                            onClick={toggleRedeemGiftCardDialog}>
                        Redeem Gift-card
                    </button>
                </div>
                {
                    giftCardsLoading
                        ? <Row className='text-center justify-content-center mt-3'>
                            <LoadingIndicator/>
                        </Row>
                        : <Row className='mt-3'>
                            {gifCardContent}
                        </Row>
                }
            </div>
            <CreateCreditCardDialog
                open={addCreditCardDialog}
                toggle={toggleAddCreditCardDialog}
                onCreated={addCreditCard}
            />
            <RedeemGiftCardDialog
                open={addGiftCardDialog}
                toggle={toggleRedeemGiftCardDialog}
                onSubmit={redeemGiftCard}
            />
        </div>
    );
}

export default PaymentMethodsView
