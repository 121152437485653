import React, {useEffect, useMemo, useRef} from "react";
import * as $ from 'jquery';
import useIsMounted from "../../hooks/use-is-mounted";

/**
 * @param {string}  text                    The text at the center of the component
 * @param {boolean} withOverlay             whether to show an overlay on the view or not
 * @param {number}  numberOfDots            The number of dots used for the loading of the page.
 * @param {number}  maxNumberOfDots         The maximum number of dots used for the loading of the page.
 * @param {number}  dotsIncreaseSteps       The increase spike in the dots with each animation iteration
 * @param {number}  animationDelay          The Delay used for the animation of the dots (ms).
 * @param {number}  animationDelayOffset    The offset used for the delay animation of the dots (ms).
 * @return {JSX.Element}
 * @constructor
 */
const DynamicContentEditorLoading = ({
                                         text,
                                         withOverlay = false,
                                         numberOfDots = 21,
                                         maxNumberOfDots = 99,
                                         dotsIncreaseSteps = 3,
                                         animationDelay = 1001,
                                         animationDelayOffset = 1000,
                                     }) => {
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const container = useRef();
    /**@type {React.MutableRefObject<HTMLDivElement>}*/
    const armContainer = useRef();
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts, hides the loader, then creates its dots, shows the loader and starts the
     * loading animation.
     */
    useEffect(() => {
        stopLoader();
        createDotsAndArms();
        startLoader();
        return () => stopLoader();
    }, [])

    /**
     * Shows the loader and then starts the animation of dots.
     */
    const startLoader = () => {
        $(container.current).show();
        loop(true);
    }

    /**
     * Hides the loader.
     */
    const stopLoader = () => {
        $(container.current).hide();
    }

    /**
     * Creates the dots and arms needed for the loader depending on the value of [numberOfDots].
     */
    const createDotsAndArms = (extraDots = numberOfDots) => {
        const spinner = armContainer.current;
        for (let index = 0; index < extraDots; ++index) {
            const arm = document.createElement('div')
            const dot = document.createElement('div')
            const armLine = document.createElement('div')
            arm.className = 'arm';
            dot.className = 'dot';
            if (index % 2 === 0) {
                dot.classList.add('primary');
            } else {
                dot.classList.add('secondary');
            }
            dot.style.opacity = `${Math.random()}`;
            armLine.className = 'arm-line';
            arm.appendChild(dot);
            arm.appendChild(armLine);
            spinner.appendChild(arm);
        }
    }

    /**
     * If the component is still mounted, animates the dots and arms of the loader and sets the new timer to calls
     * itself.
     * @param shouldSkip {boolean} whether the time must be skipped or not
     */
    const loop = (shouldSkip = false) => {
        if (!isMounted()) return;
        if (shouldSkip) {
            performAnimation();
        }
        // Reset random number for setTimout
        const randomTimeout = Math.floor(Math.random() * animationDelay) + animationDelayOffset;
        increaseDots();
        setTimeout(() => {
            performAnimation();
            loop();
        }, randomTimeout);
    }

    /**
     * Increases the number of dots in the loader.
     */
    const increaseDots = () => {
        const currentCounts = document.getElementsByClassName('arm')?.length ?? 0;
        const newCount = Math.min(currentCounts + dotsIncreaseSteps, maxNumberOfDots - numberOfDots);
        if (newCount < (maxNumberOfDots - numberOfDots)) {
            createDotsAndArms(dotsIncreaseSteps);
        }
    }

    /**
     * Animates the loader elements (dots and arms).
     */
    const performAnimation = () => {
        const arms = document.getElementsByClassName('arm');
        for (let index = 0; index < arms.length; ++index) {
            const armRotation = Math.floor(Math.random() * 541) + 20;
            const armTransition = Math.floor(Math.random() * 6) + 3;
            arms[index].style.transform = 'rotate(' + armRotation + 'deg)';
            arms[index].style.transition = armTransition + 's ease-out';
        }
    }


    const loadingComponent = useMemo(() => (
        <div ref={container} className="loader">
            <div className={'text-container'}>
                <p className="text">
                    {text ?? "LOADING..."}
                </p>
            </div>
            <div ref={armContainer} className="loading-arms-container"/>
        </div>
    ), [container, armContainer, text]);

    return (
        <>
            {
                withOverlay
                    ? (
                        <div className={'loading-overlay show'}>
                            <div className={'overlay'}/>
                            <div className={'loader-container'}>
                                {loadingComponent}
                            </div>
                        </div>
                    )
                    : loadingComponent
            }
        </>
    );
}

export default DynamicContentEditorLoading;
