import {Dispatch} from "react";
import {
    DynamicContentEditorApiConfiguration,
    DynamicContentEditorDispatcherAction,
    DynamicContentEditorMode,
    DynamicContentEditorPage,
    DynamicContentEditorPageEntry,
    DynamicContentEditorPageEntryDataRecord,
    DynamicContentEditorState,
    DynamicContentEditorStateActions
} from "../../../index.d";

/**
 * The controller that manages the state of the dynamic content editor and its components.
 *
 */
class DynamicContentEditorController {
    private readonly dispatch!: Dispatch<DynamicContentEditorDispatcherAction>;

    /**
     * Constructs a new dynamic-content-editor controller with the provided values.
     *
     * @param {Dispatch<DynamicContentEditorDispatcherAction>} dispatch
     * @param {DynamicContentEditorState} state
     */
    constructor(state?: DynamicContentEditorState,
                dispatch?: Dispatch<DynamicContentEditorDispatcherAction>) {
        if (state) {
            this._state = state;
        }
        if (dispatch) {
            this.dispatch = dispatch;
        }
    }

    /**
     * State object of the editor for this controller.
     * @private
     */
    private _state!: DynamicContentEditorState;

    /**
     * Sets the state object of the editor for this controller.
     *
     * NOTE: this method is for internal usage only. If used by any subcomponents or external sources, the effects
     * are un-determinate.
     * @param {DynamicContentEditorState} value
     */
    set state(value: DynamicContentEditorState) {
        this._state = value;
    }

    /**
     * Sets the mode of the editor.
     *
     * * This method is crucial for this package since all the edit functionality of this page sub-components are
     * activated or deactivated by this method.
     * @param {DynamicContentEditorMode} mode
     */
    setMode(mode: DynamicContentEditorMode) {
        this.dispatch({
            type: DynamicContentEditorStateActions.setMode,
            payload: mode,
        })
    }

    /**
     * Sets the current page name of the editor.
     * @param {string} page
     */
    setCurrentPage(page: string) {
        this.dispatch({
            type: DynamicContentEditorStateActions.setCurrentPage,
            payload: page,
        })
    }

    /**
     * Sets the data for a specific page of the editor.
     *
     * @param {string?} page
     * @param {DynamicContentEditorPage} data
     */
    setPageInfo(data: DynamicContentEditorPage, page?: string) {
        this.dispatch({
            type: DynamicContentEditorStateActions.setPageInfo,
            payload: {
                page: page,
                data: data,
            },
        })
    }

    /**
     * Sets the data for a page entry belonging to the provided page name.
     *
     * * if the given entry does not exist in the page, then adds it, otherwise edits the data.
     * @param {string} page
     * @param {DynamicContentEditorPageEntry} entry
     */
    setPageEntryInfo(entry: DynamicContentEditorPageEntry, page?: string) {
        this.dispatch({
            type: DynamicContentEditorStateActions.setPageEntryInfo,
            payload: {
                page: page,
                entry: entry,
            },
        })
    }

    /**
     * Sets the data for a page entry data record belonging to the provided entry and page.
     *
     * * if the entry associated with the given entry name does not exist in the page, then it does nothing.
     * * if the record does not exist in the entry, adds it to the data list of the entry, otherwise, edits the
     * value of the record.
     * * if page is not given, the record data of fixed entries is updated or inserted.
     * @param {string} page
     * @param {string} entryName
     * @param {DynamicContentEditorPageEntryDataRecord} record
     */
    setPageEntryDataRecordInfo(entryName: string, record: DynamicContentEditorPageEntryDataRecord, page?: string) {
        this.dispatch({
            type: DynamicContentEditorStateActions.setPageEntryDataRecordInfo,
            payload: {
                page: page,
                entryName: entryName,
                record: record,
            },
        })
    }

    /**
     * Sets the loading state for a specific page.
     * @param {string} page
     * @param {boolean} loading
     */
    setLoading(page: string, loading: boolean) {
        this.dispatch({
            type: DynamicContentEditorStateActions.setLoading,
            payload: {
                page: page,
                loading: loading,
            },
        })
    }

    /**
     * Sets the configuration of the editor.
     *
     * This configuration is used for all the api calls related to this package.
     * @param {Record<string, string>} config
     */
    setApiConfiguration(config: Partial<DynamicContentEditorApiConfiguration>) {
        this.dispatch({
            type: DynamicContentEditorStateActions.setApiConfiguration,
            payload: config,
        })
    }

    /**
     * Set the route to page mapping of the editor.
     *
     * * be aware that by any change in the routeToPageMap value of the editor the current page may change.
     * @param {Record<string, string>} mapping
     */
    setRouteToPageMap(mapping: Record<string, string>) {
        this.dispatch({
            type: DynamicContentEditorStateActions.setRouteToPageMap,
            payload: mapping,
        })
    }

}

export default DynamicContentEditorController;
