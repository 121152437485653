import React, {useEffect, useMemo, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {setReduxHeaderHeight} from "../../../../redux/entities/header/actions";
import useWindowViewportWidth from "../../../hooks/use-window/viewport-width";
import {Container} from "reactstrap";
import classnames from "classnames";
import useIsMounted from "../../../hooks/use-is-mounted";
import {surveyApis} from "../../../../core/constants/endpoints/endpoints";
import {apiMethods} from "../../../../core/constants/enums";
import api from "../../../../core/services/api/api";
import ObserversService from "../../../../core/services/observers-service";
import NoFoundSvg from "../../../components/app-specific/no-results-found";
import {defaultHeaderHeight} from "../../../../assets/js/sizes";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";

const SurveyView = () => {
    const [surveys, setSurveys] = useState([]);
    const [selectedSurvey, setSelectedSurvey] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [iframeOriginalHeight, setIframeOriginalHeight] = useState(0);

    const windowViewportWidth = useWindowViewportWidth();
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    const headerRef = useRef();
    /** @type {React.MutableRefObject<HTMLDivElement>}*/
    const surveyNavRef = useRef();
    /** @type {React.MutableRefObject<HTMLDivElement>}*/
    const containerRef = useRef();

    const showSeparator = ['xs', 'sm', 'md'];

    const surveyAdditionalMargin = useMemo(() => {
        switch (windowViewportWidth) {
            case 'xs':
                return 850;
            case 'sm':
                return 500;
            case 'md':
                return 400;
            case 'lg':
                return 200;
            case 'xl':
            case 'xxl':
            default:
                return 200;
        }
    }, [windowViewportWidth])

    /**
     * With initial render of the component:
     * - sets the header height of the application
     * - checks if the user is allowed to view the survey, and based on their auth state:
     *      1. shows the unauthorized dialog
     *      2. redirects them to the login view
     * - if user was logged into the application, calls the function for getting the list of surveys
     */
    useEffect(() => {
        window.addEventListener('resize', setHeaderHeight);
        getListOfSurveys().then();
        return () => {
            window.removeEventListener('resize', setHeaderHeight);
        }
    }, []);

    /**
     * With each change in the fetching which is due to the api call for getting the list of surveys:
     * - Calls the function for setting the height of the header
     * - Adds a resize observer to the survey nav bar
     */
    useEffect(() => {
        if (fetching)
            return;

        setHeaderHeight();

        if (!surveyNavRef?.current)
            return;

        const observer = ObserversService.newResizeObserver(onHeightOfSurveyNavbarChanged);
        ObserversService.observeResizeObserver(observer, surveyNavRef.current);
        onHeightOfSurveyNavbarChanged();
        return () => ObserversService.disconnectResizeObserver(observer);
    }, [fetching])

    /**
     * With each change in the [selectedSurvey] in the state:
     * - Gets the iframe being rendered and adds the onLoad function for it
     * - Calls the function for setting the height of iframe as well
     */
    useEffect(() => {
        if (!selectedSurvey || fetching)
            return;
        syncIframeHeight();
        setHeaderHeight();
    }, [selectedSurvey, fetching]);

    /**
     * With each change in the viewport width of the window:
     * - Changes the height of iframe to fit the window
     */
    useEffect(() => {
        if (fetching || !iframeOriginalHeight)
            return;
        const iframe = containerRef.current?.querySelector('iframe');
        if (!iframe)
            return;
        iframe.setAttribute('height', +iframeOriginalHeight + surveyAdditionalMargin);
    }, [surveyAdditionalMargin, fetching, iframeOriginalHeight])

    /**
     * Sets the height of the application header based on the current height of the headerRef.
     */
    const setHeaderHeight = () => {
        if (!selectedSurvey) {
            dispatch(setReduxHeaderHeight(defaultHeaderHeight));
            if (headerRef.current) {
                headerRef.current.style.marginTop = 100 + 'px';
            }
            return
        }
        const height = headerRef.current?.scrollHeight ?? 0;
        const offset = (Math.max(Math.min(Math.abs((height / 9)), 300), 0));
        dispatch(setReduxHeaderHeight(offset + 100));
        const topMargin = (offset + 50) * -1;
        if (headerRef.current) {
            headerRef.current.style.marginTop = topMargin + 'px';
        }
    };

    /**
     * Determines if the separator lines should be visible or not based on the height of the survey nav bar
     */
    const onHeightOfSurveyNavbarChanged = () => {
        if (!surveyNavRef.current)
            return
        const surveyNavbarHeight = surveyNavRef?.current?.clientHeight ?? 0;
        if (surveyNavbarHeight > 60) {
            const separators = document.getElementsByClassName('tab-buttons-section-separator');
            if (!separators?.length)
                return;
            for (let separator of separators) {
                separator.style.display = 'none';
            }
        }
    }

    /**
     * Sets the height of iframe component based on the height attribute of the received iframe from server
     */
    const syncIframeHeight = () => {
        /**@type {HTMLIFrameElement}*/
        const iframe = containerRef.current?.querySelector('iframe');
        if (!iframe)
            return;
        iframe.onload = () => {
            const height = iframe.getAttribute('height');
            setIframeOriginalHeight(Number(height.replace('px', '').replace('em', '').replace('rem', '')));
            if (!isMounted())
                return;
            iframe.setAttribute('height', +height + surveyAdditionalMargin);
        }
    }

    /**
     * Gets the list of surveys for the application:
     * - Calls the api for getting the list of surveys,
     * - if the api call was successful, sets the [surveys] in the state to the received list
     */
    const getListOfSurveys = async () => {
        setFetching(true);
        const response = await api({
            url: surveyApis.getListOfSurveys,
            method: apiMethods.get,
            loginRequired: true,
            showError: true,
        });
        if (!isMounted())
            return;
        if (response.isPreemptedDueToNotBeingLoggedIn) {
            return setFetching(false);
        }
        if (response?.resultFlag) {
            const surveys = response?.data ?? [];
            setSurveys(surveys);
            setSelectedSurvey(surveys?.length ? surveys[0] : null);
        }
        setFetching(false);
    }

    /**
     * Sets the selectedSurvey in the state to the received values
     *
     * @param {object} survey
     */
    const onSurveySelected = (survey) => {
        setIframeOriginalHeight(0);
        setSelectedSurvey(survey)
    }


    return (
        <>
            <section ref={headerRef}>
                <Container>
                    {
                        fetching
                            ? <div className={'text-center py-100'}>
                                <LoadingIndicator/>
                            </div>
                            : (surveys?.length ?? 0) < 1
                                ? <NoFoundSvg useSurvey/>
                                : <div className={'survey-view'}>
                                    {
                                        (surveys?.length ?? 0) > 1 &&
                                        <div className={'tab-buttons-section survey-nav'} ref={surveyNavRef}>
                                            {
                                                surveys.map((e, index, array) => {
                                                        return (
                                                            <React.Fragment key={e?.id}>
                                                                <button
                                                                    onClick={() => onSurveySelected(e)}
                                                                    className={classnames({'active': e.id === selectedSurvey?.id}, 'mb-3')}
                                                                >
                                                                    {e.title}
                                                                </button>
                                                                {
                                                                    ((array.length > 1) && (index !== array.length - 1)) &&
                                                                    <div className={classnames(
                                                                        'mb-3',
                                                                        {'tab-buttons-section-separator': !showSeparator.includes(windowViewportWidth)})}
                                                                    />
                                                                }
                                                            </React.Fragment>
                                                        )
                                                    }
                                                )
                                            }
                                        </div>
                                    }
                                    <div ref={containerRef} className={'survey-iframe-container'}>
                                        <div dangerouslySetInnerHTML={{__html: selectedSurvey?.link ?? "<p />"}}/>
                                    </div>
                                </div>
                    }
                </Container>
            </section>
        </>
    );
}

export default SurveyView;
