import DataGridEnvService from "../../../services/env-service";

/**
 * This interface houses all the available endpoints of this package.
 */
class ApiEndpoints {

    /**
     * The base url of the api endpoints of this package.
     * @private
     */
    private static baseUrl: string = DataGridEnvService.ApiUrl;

    /**
     * The controllers of the api endpoints.
     * @private
     */
    private static controllers = {
        dataGrid: this.baseUrl.concat("UiComponent"),
    }

    /**
     * The endpoints specific for the data-grid controller of the server api.
     */
    static dataGrid = {
        baseUrl: this.controllers.dataGrid,
        getData: this.controllers.dataGrid.concat('/GetApplicationComponents'),
        saveData: this.controllers.dataGrid.concat('/Save'),
    }
}


export default ApiEndpoints;
