import React from "react";
import {MenuItem, Select} from "@material-ui/core";
import FieldSelect from "./_base";


/**
 * @param {boolean} form
 * @param {boolean} fullWidth
 * @param {any} children
 * @param {(import('mui-rff').SelectData & {id: any})[]} data
 * @param {import('mui-rff').SelectProps & import('@material-ui/core').SelectProps} props
 * @return {JSX.Element}
 * @constructor
 */
const MuiSelect = ({
                       form = false,
                       data,
                       children,
                       fullWidth = true,
                       ...props
                   }) => {

    /**
     * The Menu props for blurring the dropdown after its been opened and to bring the menu to the front to prevent
     * its edges being clipped.
     */
    const defaultMenuProps = () => ({
        TransitionProps: {
            onEnter: () => {
                // setTimeout(() => {
                //     if (document.activeElement) {
                //         document.activeElement.blur();
                //     }
                // }, 500);
            },
        },
        PaperProps: {
            elevation: 4,
            style: {
                transform: 'translate3d(0, 5px, 0)'
            }
        },
        MenuListProps: {
            classes: {
                root: ''
            },
        },
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        getContentAnchorEl: null
    })

    return (
        <>
            {
                form
                    ? <FieldSelect
                        variant={'outlined'}
                        fullWidth={fullWidth}
                        disabled={(data?.length ?? 0) < 1}
                        data={data}
                        children={children}
                        {...props}
                        MenuProps={{
                            ...defaultMenuProps(),
                            ...(props?.MenuProps ?? {}),
                        }}
                    />
                    : <Select
                        variant={'outlined'}
                        fullWidth={fullWidth}
                        value={props?.value ?? ''}
                        {...props}
                        MenuProps={{
                            ...defaultMenuProps(),
                            ...(props?.MenuProps ?? {}),
                        }}
                    >
                        {data
                            ? data.map(item => (
                                <MenuItem
                                    value={item.value}
                                    key={item.id}
                                    disabled={item.disabled}
                                >
                                    {item.label}
                                </MenuItem>
                            ))
                            : children
                        }
                    </Select>
            }
        </>
    )
}

export default MuiSelect;
