import React from "react";
import {KeyboardDatePicker as FieldKeyboardDatePicker} from "mui-rff";
import {KeyboardDatePicker as MuiKeyboardDatePicker} from "@material-ui/pickers";
import {ReactComponent as DatePickerIcon} from "../../../../assets/images/calendar.svg";
import classnames from "classnames";
import DateFnsUtils from "@date-io/date-fns";

/**
 *
 * @param {boolean} form
 * @param {string} className
 * @param {Partial<import("mui-rff").KeyboardDatePickerProps & import("@material-ui/pickers").KeyboardDatePickerProps>} props
 * @return {JSX.Element}
 */
const MuiDatePicker = ({
                           form = false,
                           className,
                           ...props
                       }) => {

    return (
        <>
            {
                form
                    ? <FieldKeyboardDatePicker
                        className={classnames(className)}
                        format={'dd-MM-yyyy'}
                        variant={'inline'}
                        inputVariant={'outlined'}
                        disableToolbar={false}
                        animateYearScrolling
                        dateFunsUtils={DateFnsUtils}
                        keyboardIcon={<DatePickerIcon className={'picker-icon'}/>}
                        {...props}
                    />
                    : <MuiKeyboardDatePicker
                        className={classnames(className)}
                        format={'dd-MM-yyyy'}
                        variant={'inline'}
                        inputVariant={'outlined'}
                        disableToolbar={false}
                        animateYearScrolling
                        dateFunsUtils={DateFnsUtils}
                        keyboardIcon={<DatePickerIcon className={'picker-icon'}/>}
                        {...props}
                    />
            }
        </>
    )
}

export default MuiDatePicker;
