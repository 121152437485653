import React, {useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import ResellerLocatorMap from "./map";
import MuiInput from "../../../../components/base/input/mui-input";
import {ReactComponent as SearchIcon} from "../../../../../assets/images/search-icon-filled.svg";
import {deepCopy} from "../../../../../core/services/utils/utils";
import classnames from "classnames";
import {
    DynamicContentEditorEntryRecord,
    DynamicContentEditorListEntry,
    DynamicContentEditorModels,
} from "../../../../../packages/dynamic-content-editor/index";
import {DCEEntryNames} from "../../../../routes";


const ResellerLocator = () => {
    const [keywords, setKeywords] = useState('');
    const [data, setData] = useState([]);
    const [flyToMap, setFlyToMap] = useState('');
    const [geoJson, setGeoJson] = useState({type: 'FeatureCollection', features: []});

    /**
     * Listens to the changes in the data and with each change:
     * - constructs the feature points to be used in the map component.
     */
    useEffect(() => {
        let features = [];
        for (const item of deepCopy(data)) {
            const long = !item.long ? 0 : parseFloat(item.long);
            const lat = !item.lat ? 0 : parseFloat(item.lat);
            features.push({
                type: 'Feature',
                properties: {
                    title: item.title,
                    message: item.description,
                    iconSize: [60, 60]
                },
                geometry: {
                    type: 'Point',
                    coordinates: [long, lat]
                }
            });
        }
        setGeoJson({type: 'FeatureCollection', features})
    }, [data])

    /**
     * Fetches the properties of a child-item for each reseller locator.
     *
     * - hides the entries that the keywords is not contained in them.
     * @param {any} item
     * @return {{sm: number, className: string, lg: number, xs: number}}
     */
    const getChildProps = (item) => {
        const res = {
            lg: 4,
            sm: 6,
            xs: 12,
            className: ["item"],
        };
        if (item && !(item.title?.toLowerCase().includes(keywords) || item.description?.toLowerCase().includes(keywords))) {
            res.className.push('d-none');
        }
        return {
            ...res,
            className: classnames(...res.className),
        };
    }

    return (
        <>
            <div className="reseller-locator">
                <Row>
                    <Col lg={5} xs={12}>
                        <h3 className={'font-weight-bolder'}>Locations</h3>
                        <MuiInput
                            onChange={(event) => setKeywords(event.target.value?.toLowerCase() ?? '')}
                            name={'address'}
                            placeholder={'Enter your address'}
                            InputProps={{
                                endAdornment: (
                                    <SearchIcon className={'mr-2'}/>
                                )
                            }}
                        />
                        <DynamicContentEditorListEntry
                            _title={'Reseller Locator'}
                            entryName={DCEEntryNames.aboutUsResellerLocator}
                            dataType={DynamicContentEditorModels.DynamicContentEditorEntryDataTypes.pageBased}
                            tag={'div'}
                            className={'list'}
                            childTag={'div'}
                            getData={setData}
                            childProps={getChildProps}
                        >
                            <DynamicContentEditorEntryRecord
                                recordKey={'title'}
                                _title={'Title'}
                                tag={'h6'}
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.text}
                                placeholder={'Title'}
                            />
                            <DynamicContentEditorEntryRecord
                                recordKey={'description'}
                                _title={'Description'}
                                tag={'p'}
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.multiline}
                                placeholder={'click on the + icon to add a new item.'}
                            />
                            <DynamicContentEditorEntryRecord
                                recordKey={'lat'}
                                _title={'Latitude'}
                                tag={'p'}
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.number}
                                className={'d-none'}
                            />
                            <DynamicContentEditorEntryRecord
                                recordKey={'long'}
                                _title={'Longitude'}
                                tag={'p'}
                                valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.number}
                                className={'d-none'}
                            />
                            <div className="text-right">
                                <DynamicContentEditorEntryRecord
                                    valueType={DynamicContentEditorModels.DynamicContentEditorEntryRecordValueTypes.element}
                                >
                                    {
                                        (item) => (
                                            !!Object.values(item).length &&
                                            <button
                                                type={'button'}
                                                className={'button primary text'}
                                                onClick={() => setFlyToMap([item.long, item.lat])}
                                            >
                                                View In Map
                                            </button>
                                        )
                                    }
                                </DynamicContentEditorEntryRecord>
                            </div>
                        </DynamicContentEditorListEntry>
                    </Col>
                    <Col lg={6} xs={12} className={'ml-lg-auto mt-5 mt-lg-0'}>
                        <ResellerLocatorMap
                            geoJson={geoJson}
                            height={500}
                            flyTo={flyToMap}
                        />
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ResellerLocator;
