import React, {useEffect, useMemo, useState} from 'react';
import {Col, Row} from "reactstrap";
import useIsMounted from "../../../hooks/use-is-mounted";
import {userAccessLevelApis, userApis} from "../../../../core/constants/endpoints/endpoints";
import api from "../../../../core/services/api/api";
import AddUserDialog from "../../../components/dialogs/add-user-dialog";
import LocalStorageService from "../../../../core/services/cache/local-storage-service";
import routes from "../../../routes";
import useRouter from "../../../hooks/use-router";
import {apiMethods} from "../../../../core/constants/enums";
import Utils, {stringComparator} from "../../../../core/services/utils/utils";
import {ReactComponent as RemoveIcon} from "../../../../assets/images/remove.svg";
import {confirmationDialog} from "../../../../redux/entities/dialogs/actions";
import {useDispatch} from "react-redux";
import Switch from "../../../components/base/switch";
import {DataGridColumnAlignments, DataGridColumnTypes} from "../../../../packages/data-grid/models";
import AppDataGrid from "../../../containers/data-grid";

const dataGridColumnNames = {
    name: 'name',
    email: 'email',
    phone: 'phone',
    admin: 'admin',
    remove: 'remove',
}

const UserManagement = () => {
    const {history} = useRouter();
    const [members, setMembers] = useState([]);
    const [accessLevels, setAccessLevels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [openAddUser, setOpenAddUser] = useState(false);
    const [orderBy, setOrderBy] = useState(null);
    const [orderByMethod, setOrderByMethod] = useState(() => (a, b) => 0);
    const isMounted = useIsMounted();
    const dispatch = useDispatch();

    /**
     * Fetches the members for a company as soon as the component mounts.
     */
    useEffect(() => {
        if (!LocalStorageService.get(LocalStorageService.keys.hasFullAccessLevel)) {
            return history.replace(routes.error);
        }
        getAccessLevelsAndMembers().then();
    }, [])

    /**
     * Fetches the access levels from the server and if successful, fetches the members of the company and then sets
     * the access levels and members.
     */
    const getAccessLevelsAndMembers = async () => {
        setLoading(true);
        const accessLevels = await getAccessLevels();
        if (!isMounted() || !accessLevels)
            return;
        await getMembers(accessLevels);
        setLoading(false);
    }

    const getAccessLevels = async () => {
        const accessLevelsResponse = await api({
            url: userAccessLevelApis.getAllAccessLevels,
            method: apiMethods.get,
        })
        if (!isMounted())
            return;
        if (accessLevelsResponse?.isPreemptedDueToNotBeingLoggedIn) {
            setLoading(false);
            return;
        }
        if (!accessLevelsResponse?.resultFlag) {
            setMembers([]);
            setLoading(false);
        }
        const accessLevels = accessLevelsResponse.data?.map((accessLevel) => ({
            ...accessLevel,
            partName: accessLevel.partName ?? `access-level-${accessLevel.id}`,
            title: accessLevel.title ?? `Access Level ${accessLevel.id}`,
        })) ?? [];
        setAccessLevels(accessLevels);
        return accessLevels;
    }

    const getMembers = async (accessLevels) => {
        const membersResponse = await api({
            url: userAccessLevelApis.getUsersAccessLevelsInCustomer,
            method: apiMethods.get,
        })
        if (!isMounted())
            return;
        if (membersResponse?.isPreemptedDueToNotBeingLoggedIn) {
            setLoading(false);
            return;
        }
        const accessLevelIds = accessLevels?.map(e => e.id) ?? [];
        setMembers(membersResponse?.data?.map(e => ({
            ...e,
            fullName: `${e.firstName ?? ''} ${e.lastName ?? ''}`,
            accessLevels: e.accessLevels
                    ?.filter(e => accessLevelIds.includes(e.id))
                    ?.map(a => ({
                        ...a,
                        loading: false,
                    }))
                ?? [],
            loadingAdmin: false,
            removing: false,
        })) ?? []);
    }

    /**
     * Sets the specific access level's loading state to the value for the specific member.
     * @param memberId {number} the id of the member
     * @param accessLevelId {number} the id of the access level
     * @param value {boolean} the loading state value
     */
    const setAccessLevelLoading = (memberId, accessLevelId, value) => {
        setMembers(prevState => prevState.map(m => m.id === memberId ? {
            ...m,
            accessLevels: m.accessLevels?.map(a => a.id === accessLevelId ? {...a, loading: value} : a)
        } : m));
    }

    /**
     * Sets the admin loading state to the value for the specific member.
     * @param memberId {number} the id of the member
     * @param value {boolean} the loading state value
     */
    const setAdminLoading = (memberId, value) => {
        setMembers(prevState => prevState.map(m => m.id === memberId ? {
            ...m,
            loadingAdmin: value,
        } : m));
    }

    /**
     * Sets the remove loading state to the value for the specific member.
     * @param memberId {number} the id of the member
     * @param value {boolean} the loading state value
     */
    const setRemoveLoading = (memberId, value) => {
        setMembers(prevState => prevState.map(m => m.id === memberId ? {
            ...m,
            removing: value,
        } : m));
    }

    /**
     * Adds the new member to the beginning of the member list.
     * @param member
     */
    const onAddSuccess = (member) => {
        const accessLevelIds = accessLevels?.map(e => e.id) ?? [];
        setMembers(prevState => [
            {
                ...member,
                fullName: `${member.firstName ?? ''} ${member.lastName ?? ''}`,
                accessLevels: member.accessLevels
                        ?.filter(e => accessLevelIds.includes(e.id))
                        ?.map(a => ({
                            ...a,
                            loading: false,
                        }))
                    ?? [],
                loadingAdmin: false,
                removing: false,
            },
            ...prevState
        ])
    }

    /**
     * Changes the access level of a specific member by setting the state of that access level to loading, then
     * calling the api and if the api result is successful, removing the loading state.
     * @param member {any} the id of the member
     * @param accessLevel {any|null}
     * @param checked {boolean}
     * @param revoke {function():void}
     */
    const changeMemberAccessLevel = (member, accessLevel, checked, revoke) => {
        if (accessLevel) setAccessLevelLoading(member.id, accessLevel.id, true);
        else setAdminLoading(member.id, true);
        const forApi = {
            id: member.id,
            hasFullAccessLevel: !accessLevel ? checked : member?.hasFullAccessLevel,
            accessLevels: !accessLevel
                ? member.accessLevels
                : checked
                    ? [...(member?.accessLevels ?? []), accessLevel]
                    : member.accessLevels?.filter(a => a.id !== accessLevel.id)
        }
        api({
            url: userAccessLevelApis.updateUserAccessLevelsInCustomer,
            method: apiMethods.put,
            data: forApi,
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn)
                return;
            if (response?.resultFlag) {
                setMembers(prevState => prevState.map(m => m.id === member.id
                    ? {
                        ...m,
                        accessLevels: forApi.accessLevels,
                        hasFullAccessLevel: forApi.hasFullAccessLevel,
                    }
                    : m))
            } else {
                if (revoke) revoke();
            }
            if (accessLevel) setAccessLevelLoading(member.id, accessLevel.id, false);
            else setAdminLoading(member.id, false);

        })
    }

    /**
     * Removes a member from the company by calling the server api and if the result is successful, then removes it
     * from the ui.
     * @param member {any} member to be removed
     */
    const removeMemberFromCompany = (member) => {
        const callback = (accepted) => {
            if (!accepted) return true;
            setRemoveLoading(member.id, true);
            api({
                url: userApis.removeUserFromCustomer(member.id),
                method: apiMethods.put,
            }).then((response) => {
                if (!isMounted()) return;
                if (response?.isPreemptedDueToNotBeingLoggedIn)
                    return;
                if (response?.resultFlag) {
                    setMembers(prevState => prevState.filter(e => e.id !== member.id));
                }
                setRemoveLoading(member.id, false);
            })
            return true;
        }
        dispatch(confirmationDialog({
            open: true,
            title: "Action Irreversible",
            description: "You would not be able to reverse this action once it is proceeded. Do you want to delete the member?",
            callback: callback,
            mandatory: false,
            cancelText: "cancel",
            proceedText: "delete",
        }))
    }

    /**
     * Shows the confirmation dialog so that the user can confirm the operation and only then, grants admin
     * privilages to the provided member.
     * @param {any} member
     * @param {function(): void} revoke switch value changer
     */
    const grantAdminPrivileges = (member, revoke) => {
        dispatch(confirmationDialog({
            open: true,
            title: "Action Irreversible",
            description: "You would not be able to reverse this action once it is proceeded. Do you want to continue?",
            callback: (accepted) => {
                if (accepted) changeMemberAccessLevel(member, null, true, revoke);
                return true;
            },
            mandatory: false,
        }))
        // revoke it since they have to first confirm.
        revoke()
    }

    /**
     * Sorts the entries of the users in place.
     * @param {DataGridSortBy} tableOrderBy
     */
    const onSort = (tableOrderBy) => {
        setOrderBy(tableOrderBy);
        switch (tableOrderBy?.field) {
            case dataGridColumnNames.name:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? stringComparator(a?.fullName ?? '', b?.fullName ?? '')
                    : stringComparator(b?.fullName ?? '', a?.fullName ?? '')
                )
            case dataGridColumnNames.email:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? stringComparator(a?.email ?? '', b?.email ?? '')
                    : stringComparator(b?.email ?? '', a?.email ?? '')
                )
            case dataGridColumnNames.phone:
                return setOrderByMethod(() => (a, b) => tableOrderBy.descending
                    ? stringComparator(a?.phone ?? '', b?.phone ?? '')
                    : stringComparator(b?.phone ?? '', a?.phone ?? '')
                )
            default:
                return setOrderByMethod(() => (a, b) => 0)
        }
    }

    const dataGridColumns = useMemo(() =>
            /**@type {DataGridColumn[]}*/
            [
                {
                    name: dataGridColumnNames.remove,
                    alignment: DataGridColumnAlignments.center,
                    type: DataGridColumnTypes.element,
                    width: 10,
                    pinned: true,
                    pinnedToggleable: false,
                    sortable: false,
                    visibilityToggleable: false,
                },
                {
                    title: 'Full name',
                    name: dataGridColumnNames.name,
                    alignment: DataGridColumnAlignments.left,
                    type: DataGridColumnTypes.string,
                    pinned: true,
                },
                {
                    title: 'Email',
                    name: dataGridColumnNames.email,
                    alignment: DataGridColumnAlignments.left,
                    type: DataGridColumnTypes.string,
                },
                {
                    title: 'Phone',
                    name: dataGridColumnNames.phone,
                    alignment: DataGridColumnAlignments.left,
                    type: DataGridColumnTypes.string,
                },
                ...accessLevels.map((accessLevel, index) => ({
                    title: accessLevel.partName,
                    name: accessLevel.partName,
                    alignment: DataGridColumnAlignments.center,
                    type: DataGridColumnTypes.element,
                    sortable: false,
                })),
                {
                    title: 'Admin',
                    name: dataGridColumnNames.admin,
                    alignment: DataGridColumnAlignments.center,
                    type: DataGridColumnTypes.element,
                    sortable: false,
                },
            ],
        [accessLevels])

    /**@type {DataGridRow[]}
     */
    const dataGridRows = useMemo(() => {
        return Array.from(members)
                ?.sort(orderByMethod)
                ?.map((member) => ({
                    key: member.id,
                    data: member,
                    cells: {
                        [dataGridColumnNames.remove]: member.hasFullAccessLevel
                            ? <></>
                            : <button
                                disabled={member.removing}
                                className='button text icon remove-icon'
                                onClick={() => removeMemberFromCompany(member)}>
                                <RemoveIcon/>
                            </button>,
                        [dataGridColumnNames.name]: member?.fullName,
                        [dataGridColumnNames.email]: member?.email,
                        [dataGridColumnNames.phone]: Utils.formatPhoneNumber(member?.phone),
                        ...accessLevels
                            .map((accessLevel) => {
                                const found = (member.accessLevels ?? [])?.find(e => e.id === accessLevel.id);
                                return {
                                    [accessLevel.partName]:
                                        <Switch
                                            disabled={!!(found?.loading || member.hasFullAccessLevel)}
                                            checked={!!found || member.hasFullAccessLevel}
                                            onChange={(checked, revoke) => changeMemberAccessLevel(member, accessLevel, checked, revoke)}
                                            color={'user-management-secondary'}
                                            size={4.5}
                                        />
                                }
                            })
                            .reduce((agg, mem) => ({...agg, ...mem,}), {}),
                        [dataGridColumnNames.admin]: () => (
                            <Switch
                                size={4.5}
                                disabled={!!(member.loadingAdmin || member.hasFullAccessLevel)}
                                checked={member.hasFullAccessLevel}
                                color={'user-management-primary'}
                                onChange={(checked, revoke) => grantAdminPrivileges(member, revoke)}
                            />
                        ),
                    }
                }))
            ?? []
    }, [members, accessLevels, orderByMethod])

    return (
        <>
            <Row className={'user-management-view'}>
                <Col xs={12} className={'sticky-header'}>
                    <div className="heading mb-0">
                        Users
                    </div>
                    <button
                        className={'button primary px-4'}
                        onClick={() => setOpenAddUser(true)}>
                        Add new User
                    </button>
                </Col>
                <Col xs={12} className='w-100 mt-4 px-0'>
                    <AppDataGrid
                        hideFooter
                        hideExporting
                        rows={dataGridRows}
                        columns={dataGridColumns}
                        state={{
                            loading: {state: loading},
                            sortBy: orderBy,
                        }}
                        onSortByChanged={(_, current) => onSort(current)}
                    />
                </Col>
            </Row>
            <AddUserDialog
                open={openAddUser}
                setOpen={setOpenAddUser}
                onSuccess={onAddSuccess}
            />
        </>
    );
}

export default UserManagement;
