import {createContext} from "react";
import {
    DefaultDynamicContentEditorState,
    DynamicContentEditorMode,
    DynamicContentEditorPageEntryData,
    DynamicContentEditorState,
    DynamicContentEditorStateData,
} from "../../index.d";
import DynamicContentEditorController from "../../core/models/controller";


export const DynamicContentEditorStateContext =
    createContext<DynamicContentEditorState>(DefaultDynamicContentEditorState);

export const DynamicContentEditorControllerContext =
    createContext<DynamicContentEditorController>(new DynamicContentEditorController());

export const DynamicContentEditorEntryWrapperContext =
    createContext<DynamicContentEditorPageEntryData<string> | undefined>(undefined);

export const DynamicContentEditorModeContext = createContext<DynamicContentEditorMode>(DynamicContentEditorMode.view);

export const DynamicContentEditorDataContext = createContext<DynamicContentEditorStateData>(DefaultDynamicContentEditorState.data);
