import React, {useLayoutEffect, useMemo, useState} from "react";
import {Col, Row} from "reactstrap";
import CompareViewProductChips from "../../components/product-chips";
import {ReactComponent as SwapIcon} from "../../../../../../assets/images/comparison/swap.svg";
import CompareViewProductNotes from "../../components/product-notes";

const CompareViewNotesSection = ({
                                     parts: partsProps,
                                     removePart,
                                 }) => {
    const [pinnedPartId, setPinnedPartId] = useState(null);
    const [selectedPartId, setSelectedPartId] = useState(null);

    const parts = useMemo(() => [...partsProps].reverse(), [partsProps]);

    const pinnedProduct = useMemo(() => parts?.find(e => e.id === pinnedPartId), [parts, pinnedPartId])
    const selectedProduct = useMemo(() => parts?.find(e => e.id === selectedPartId), [parts, selectedPartId])

    /**
     * With each change in the parts:
     * - sets the pinnedPart and selectedPart in case they have not been selected.
     */
    useLayoutEffect(() => {
        const partIds = parts?.map(e => e.id) ?? [];
        setPinnedPartId(prevState => {
            if (partIds.includes(prevState))
                return prevState
            return parts?.[0]?.id ?? null;
        })
        setSelectedPartId(prevState => {
            if (partIds.includes(prevState) && (parts?.length ?? 0 > 1) && parts?.[0]?.id !== prevState)
                return prevState
            return parts?.[1]?.id ?? null;
        })
    }, [parts])

    const selectableProducts = useMemo(() => parts?.filter(e => e.id !== pinnedPartId) ?? [], [parts, pinnedPartId])

    /**
     * Switches the selected part and pinned part with each other.
     */
    const switchParts = () => {
        setSelectedPartId(pinnedPartId);
        setPinnedPartId(selectedPartId);
    }


    return (
        <>
            <div className={'compare-notes-wrapper'}>
                <Row>
                    <Col/>
                    <div className={'compare-swapper-container'}/>
                    {/* PRODUCT CHIPS SECTION */}
                    <Col>
                        <CompareViewProductChips
                            products={selectableProducts}
                            removeProduct={removePart}
                            selectedProductId={selectedPartId}
                            selectProduct={(product) => setSelectedPartId(product.id)}
                        />
                    </Col>
                </Row>
                <Row className={'d-flex align-items-center justify-content-center mt-4'}>
                    {/* Pinned Section */}
                    <Col>
                        {
                            pinnedProduct &&
                            <CompareViewProductNotes
                                product={pinnedProduct}
                                removeProduct={removePart}
                            />
                        }
                    </Col>
                    {/* Swapper */}
                    <div className={'compare-swapper-container'}>
                        {
                            parts?.length > 1 &&
                            <SwapIcon
                                className={'back-button'}
                                onClick={switchParts}
                            />
                        }
                    </div>
                    {/* Unpinned Section */}
                    <Col>
                        {
                            selectedProduct &&
                            <CompareViewProductNotes
                                product={selectedProduct}
                            />
                        }
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CompareViewNotesSection;
