import React, {useContext, useMemo} from "react";
import {DataGridControllerContext, DataGridMiscContext} from "../../../../../index";
import DataGridButton from "../../../../components/button";
import classnames from "classnames";
import {ReactComponent as Icon} from "../../../../../assets/images/toolbar/refresh.svg";
import useWindowViewportWidth from "../../../../../../../ui/hooks/use-window/viewport-width";
import DataGridIconButton from "../../../../components/icon-button/inedx";

const DataGridToolbarRefreshLayoutAction = () => {
    const {classNames} = useContext(DataGridMiscContext);
    const dataGridApi = useContext(DataGridControllerContext);
    const viewportWidth = useWindowViewportWidth();
    const showIconButton = useMemo(() => ['xs', 'sm', 'md'].includes(viewportWidth), [viewportWidth])

    /**
     * Refreshes the layout of this data-grid back to its initial state.
     * @return {Promise<void>}
     */
    const refreshLayout = () => {
        dataGridApi.refreshLayout(true);
    }

    return (
        <>
            {
                showIconButton
                    ? <DataGridIconButton
                        className={classnames('data-grid-toolbar-action', classNames.toolbarAction, classNames.toolbarRefreshLayoutAction)}
                        onClick={refreshLayout}
                    >
                        <Icon/>
                    </DataGridIconButton>
                    : <DataGridButton
                        className={classnames('data-grid-toolbar-action', classNames.toolbarAction, classNames.toolbarRefreshLayoutAction)}
                        onClick={refreshLayout}
                    >
                        <Icon/>
                        <p>
                            Reset Layout
                        </p>
                    </DataGridButton>
            }
        </>
    )
}

export default DataGridToolbarRefreshLayoutAction;
