import React, {useLayoutEffect, useMemo, useRef, useState} from "react";
import {ReactComponent as PrevIcon} from "../../../../../../assets/images/product-images/prev.svg";
import Slider from "react-slick";
import classnames from "classnames";
import LazyImage from "../../../../../components/app-specific/lazy-lmage";
import {ReactComponent as NextIcon} from "../../../../../../assets/images/product-images/next.svg";
import {ReactComponent as RemoveIcon} from "../../../../../../assets/images/comparison/remove-property.svg";
import {Link} from "react-router-dom";
import {routeFunctions} from "../../../../../routes";
import {confirmationDialog} from "../../../../../../redux/entities/dialogs/actions";
import {useDispatch} from "react-redux";

const CompareViewProductGallery = ({
                                       product,
                                       removeProduct,
                                   }) => {
    const dispatch = useDispatch();
    const [activeImageIndex, setActiveImageIndex] = useState(-1);
    /**@type {React.MutableRefObject<import('react-slick').default>}*/
    const sliderRef = useRef();
    const productLink = useMemo(() => routeFunctions.main.single(product?.partNo), [product?.partNo]);

    /**
     * With each change in the images property of the product:
     * - Resets the active image index's state value.
     */
    useLayoutEffect(() => {
        setActiveImageIndex(product?.images?.length ? 0 : -1);
    }, [product?.images])

    /**
     * Changes the active index of the image based on the given offset.
     * @param {number} offset
     */
    const changeImage = (offset) => {
        const length = product?.images?.length ?? 0;
        let newActiveIndex = activeImageIndex + offset;
        if (newActiveIndex >= length) {
            newActiveIndex %= length;
        }
        if (newActiveIndex < 0) {
            newActiveIndex = length + newActiveIndex;
        }
        sliderRef.current.slickGoTo(newActiveIndex);
        setActiveImageIndex(newActiveIndex)
    }

    /**
     * Handles removing a product from the list of items in compare screen:
     * - Shows a confirmation dialog from for user
     * - after confirmation, calls the api regarding this action
     *
     * @param {MouseEvent<HTMLButtonElement>} e
     */
    const getRemoveProductConfirmation = (e) => {
        e.stopPropagation()
        e.preventDefault()
        dispatch(confirmationDialog({
            open: true,
            title: "Remove Product",
            description: "Do you want to remove the following product?",
            callback: (confirmed) => {
                if (confirmed) removeProduct(product).then();
                return true;
            },
            mandatory: false,
        }))
    }

    return (
        <>
            <div className={'compare-product-gallery'}>
                <div className={'compare-product-gallery-header'}>
                    <Link to={productLink} className={'part-no'}>
                        {product?.partNo ?? ''}
                    </Link>
                    {
                        removeProduct && !!product &&
                        <RemoveIcon
                            className={'ml-2 remove-button'}
                            onClick={getRemoveProductConfirmation}
                        />
                    }
                </div>
                <div className={'image-container'}>
                    <LazyImage
                        src={product?.images?.[activeImageIndex]?.fileName}
                    />
                </div>
                <div className={'slider-container-wrapper part-image-slider mt-3'}>
                    {
                        ((product?.images?.length ?? 0) > 4) &&
                        <div
                            className={'part-images-icon prev'}
                            onClick={() => changeImage(-1)}
                        >
                            <PrevIcon/>
                        </div>
                    }
                    <div className={'slider-container'}>
                        <Slider
                            swipeToSlide={false}
                            slidesToScroll={1}
                            focusOnSelect={true}
                            infinite={true}
                            arrows={false}
                            draggable={false}
                            slidesToShow={product?.images?.length > 4 ? 4 : product?.images?.length ?? 0}
                            ref={sliderRef}
                            className={'part-image-slider-item'}
                        >
                            {
                                product?.images?.map((item, index) => (
                                    <div
                                        key={item}
                                        className={'image-wrapper'}
                                        onClick={() => changeImage(index - activeImageIndex)}
                                    >
                                        <div className={classnames('img', {'active': index === activeImageIndex})}>
                                            <LazyImage src={item.fileName} alt=""/>
                                        </div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                    {
                        product?.images?.length > 4 &&
                        <div
                            className={'part-images-icon next'}
                            onClick={() => changeImage(1)}
                        >
                            <NextIcon/>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default CompareViewProductGallery;
