import React, {useEffect, useLayoutEffect, useState} from "react";
import useRouter from "../../../hooks/use-router";
import api from "../../../../core/services/api/api";
import {newLookupApis} from "../../../../core/constants/endpoints/endpoints";
import {apiMethods, ProductBoxTypes, ProductsSearchTypes} from "../../../../core/constants/enums";
import useIsMounted from "../../../hooks/use-is-mounted";
import {setReduxHeaderHeight as changeHeaderHeightRedux} from "../../../../redux/entities/header/actions";
import {useDispatch} from "react-redux";
import InfiniteScroll from "react-infinite-scroller";
import classnames from "classnames";
import {Col, Container} from "reactstrap";
import ProductBox from "../../../components/app-specific/product-box";
import NoFoundSvg from "../../../components/app-specific/no-results-found";
import HomeViewBanner from "../../../components/app-specific/home-components/banner";
import HomeViewSlideShow from "../../../components/app-specific/home-components/slide-show";
import LoadingIndicator from "../../../components/app-specific/loading-indicator";

const initialPaginationInfo = {
    currentPage: 1,
    pageSize: 24,
    length: 0,
}

const PublicProductsView = () => {
    const {query} = useRouter();
    const [paginationInfo, setPaginationInfo] = useState(initialPaginationInfo);
    const [fetching, setFetching] = useState(true);
    const [allParts, setAllParts] = useState([]);
    const [visibleParts, setVisibleParts] = useState([]);
    const [banners, setBanners] = useState([]);
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    /**
     * with initial render of this page:
     * - Sets the header height to zero
     */
    useLayoutEffect(() => {
        dispatch(changeHeaderHeightRedux(0));
    }, [])

    /**
     * With each change in the query:
     * Calls the function for getting the list of parts
     */
    useEffect(() => {
        getPublicParts().then();
    }, [query.tag])

    /**
     * Calls the api for getting the list of public parts:
     * - if the api call was not successful, or it was preempted, returns
     * - Otherwise, sets the [allParts], [banners], and [configuration] and [pagination] in the state
     */
    const getPublicParts = async () => {
        const response = await api({
            url: newLookupApis.getPublicPartsByTag(query.tag),
            method: apiMethods.get,
            loginRequired: false,
        });
        if (!isMounted)
            return;
        if (response?.resultFlag) {
            let parts = response?.data?.parts?.map(e => ({
                ...e,
                coverImageFileName: response?.configuration?.PartImageURL?.concat(e?.coverImageFileName ?? '') ?? ''
            })) ?? [];
            parts = Array(...new Set(parts?.map(e => e.partNo)))?.map(e => parts?.find(p => p.partNo === e));
            setAllParts(parts);
            setVisibleParts(parts?.slice(0, Math.min(paginationInfo.pageSize, parts?.length)));
            setBanners(response?.data?.banners?.map(e => ({
                ...e,
                imageName: response?.configuration?.BannerUrlBaseAddress?.concat(e?.imageName ?? '') ?? '',
            })));
            setPaginationInfo(prevState => ({
                ...prevState,
                length: response?.data?.parts?.length ?? 0,
            }));
        }
        setFetching(false);
    }

    /**
     * Adds more products from [allProducts] to [visibleParts]
     */
    const loadMore = () => {
        setVisibleParts(allParts?.slice(0, paginationInfo.currentPage * paginationInfo.pageSize) ?? []);
        setPaginationInfo(prevState => ({
            ...prevState,
            currentPage: prevState.currentPage + 1,
        }))
    }


    return (
        <>
            <div className={'product-search-result-view public-products-view'}>
                <Container>
                    {
                        ((banners?.length ?? 0) > 0)
                            ? banners.length === 1
                                ? <div className={'banner-wrapper'}>
                                    <HomeViewBanner
                                        isUnClickable={true}
                                        url={banners[0].imageName}
                                    />
                                </div>
                                : <div className={'mb-5'}>
                                    <HomeViewSlideShow
                                        isClickable={false}
                                        data={banners}
                                    />
                                </div>
                            : <></>

                    }
                    <div className={'w-100'}>
                        <div className='d-flex justify-content-between mb-4 px-2'>
                            {
                                ((fetching && paginationInfo?.currentPage !== 1) || (allParts?.length > 0)) &&
                                <>
                                    <div className={'product-search-result-indicator'}>
                                        <p>
                                            {paginationInfo?.length ?? 0}
                                            <span>
                                                 {
                                                     (paginationInfo?.length ?? 0) === 1
                                                         ? "Result"
                                                         : "Results"
                                                 }
                                            </span>
                                        </p>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    {
                        ((visibleParts?.length ?? 0) > 0) &&
                        <InfiniteScroll
                            className={classnames('products-wrapper', {[ProductsSearchTypes.basic]: true})}
                            pageStart={0}
                            useWindow
                            threshhold={3000}
                            loadMore={loadMore}
                            hasMore={
                                !fetching &&
                                paginationInfo.length > paginationInfo.pageSize * paginationInfo.currentPage
                            }>
                            {
                                visibleParts?.map((item) => (
                                    <Col
                                        key={item.partNo}
                                        xs={12} sm={6} md={4} lg={3}
                                        className={classnames('medium-container')}
                                    >
                                        <ProductBox
                                            type={ProductBoxTypes.list}
                                            fullWidth
                                            SearchToken={null}
                                            data={item}
                                            navigateToPublicProductInfo
                                            preventActionButton
                                        />
                                    </Col>
                                ))
                            }
                        </InfiniteScroll>
                    }
                    {
                        !!fetching &&
                        <div key={paginationInfo?.currentPage ?? -1}
                             className='w-100 d-flex justify-content-center align-items-center p-5 my-5'>
                            <LoadingIndicator/>
                        </div>
                    }
                    {
                        (!((visibleParts?.length ?? 0) > 0) && !fetching) &&
                        <NoFoundSvg usePublic/>
                    }
                </Container>
            </div>
        </>
    );
}

export default PublicProductsView;
