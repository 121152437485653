import React, {useState} from "react";
import useIsMounted from "../../../../hooks/use-is-mounted";
import api from "../../../../../core/services/api/api";
import {invoicesApis} from "../../../../../core/constants/endpoints/endpoints";
import {apiMethods} from "../../../../../core/constants/enums";
import classnames from "classnames";
import {Col, Collapse} from "reactstrap";
import moment from "moment";
import {formatMoney} from "../../../../../core/services/utils/utils";
import {ReactComponent as ExpandIcon} from "../../../../../assets/images/blogs/expand.svg";
import {ReactComponent as CheckIcon} from "../../../../../assets/images/check.svg";
import {ReactComponent as PrintIcon} from "../../../../../assets/images/print.svg";
import {Fade} from "@material-ui/core";
import EnvService from "../../../../../core/services/env-service";
import useRouter from "../../../../hooks/use-router";
import {routeFunctions} from "../../../../routes";
import LoadingIndicator from "../../loading-indicator";

const InvoiceBox = ({data, selected, toggleSelected}) => {
    const {history} = useRouter();
    const [expanded, setExpanded] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [fullInfo, setFullInfo] = useState({});
    const isMounted = useIsMounted();

    /**
     * Fetches the full information of this invoice from the server.
     *
     * if the result of the api is successful, then sets the state information.
     * @return {Promise<void>}
     */
    const getFullInformation = async () => {
        setLoadingInfo(true);
        const response = await api({
            url: invoicesApis.getFullDetails(data.id),
            method: apiMethods.get,
        })
        if (!isMounted()) return;
        setLoadingInfo(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn)
            return;
        setFullInfo(response?.data ?? {});
    }

    /**
     * Toggles the expanded state of this invoice.
     *
     * if the invoice gets expanded, then the api for fetching its full information is called.
     */
    const toggleExpanded = () => {
        setExpanded(prevState => {
            const val = !prevState;
            if (val) getFullInformation().then();
            return val;
        });
    }

    /**
     * Navigates to the print view.
     */
    const navigateToPrintView = () => {
        if (!data.id)
            return;
        window.open(window.origin + routeFunctions.print.invoice(data?.id), '_blank');
    }

    return (
        <>
            <div className={classnames('order-box', {expanded})}>
                <div className={'d-flex justify-content-between pt-3 mx-3 mb-3'}>
                    <button
                        className={'button icon'}
                        onClick={navigateToPrintView}
                    >
                        <PrintIcon className={'print'}/>
                    </button>
                    <Fade in={EnvService.EnablePayments}>
                        <div>
                            <button onClick={() => toggleSelected(data)}
                                    className={classnames('button pay-button d-flex align-items-center px-2', {
                                        'selected': selected && data?.payInfo?.isNeededToPay,
                                        'paid ': !data?.payInfo?.isNeededToPay,
                                    })}>
                                {
                                    !data?.payInfo?.isNeededToPay
                                        ? <>
                                            <CheckIcon/>
                                            Paid
                                        </>
                                        : <>
                                            <p className={'mb-0'}>Pay</p>
                                            <div className={'checkbox-container'}>
                                                {
                                                    selected &&
                                                    <CheckIcon className={'icon'}/>
                                                }
                                            </div>
                                        </>
                                }
                            </button>
                        </div>
                    </Fade>
                </div>
                <div className={'body'}>
                    <div className={'ml-3'}>
                        Number
                    </div>
                    <Col xs={2} className={'d-flex justify-content-end px-0'}>
                        PO-Number
                    </Col>
                    <Col xs={2} className={'d-flex justify-content-end px-0'}>
                        Date
                    </Col>
                    <Col xs={2} className={'d-flex justify-content-end px-0'}>
                        Subtotal
                    </Col>
                    <Col xs={2} className={'d-flex justify-content-end px-0'}>
                        Total
                    </Col>
                    <Col xs={2} className={'d-flex justify-content-end '}>
                        Balance
                    </Col>
                </div>
                <div className={'expanded-body'}>
                    <div className={'ml-2'}>
                        {data?.orderNo ?? "--"}
                    </div>
                    <Col xs={2} className={'d-flex justify-content-end align-items-center'}>
                        {data?.customerPO ?? "--"}
                    </Col>
                    <Col xs={2} className={'d-flex justify-content-end align-items-center'}>
                        {data?.invoiceDate ? moment(data?.invoiceDate).format('DD/MM/yyyy') : "--"}
                    </Col>
                    <Col xs={2} className={'d-flex justify-content-end align-items-center'}>
                        {data?.subTotal ? formatMoney(data?.subTotal) : '--'}
                    </Col>
                    <Col xs={2} className={'d-flex justify-content-end align-items-center'}>
                        {data?.total ? formatMoney(data?.total) : '--'}
                    </Col>
                    <Col xs={2} className={'d-flex justify-content-center align-items-center px-0'}>
                        {data?.payInfo?.minimumRequiredPayment !== undefined ? formatMoney(data?.payInfo?.minimumRequiredPayment) : '--'}
                    </Col>
                    <div className={'d-flex justify-content-end flex-grow-1 align-items-center'}>
                        <div className={'mr-3'}>
                            <button className={'button text icon bg-white primary'}
                                    onClick={toggleExpanded}>
                                <ExpandIcon className={classnames('expand')}/>
                            </button>
                        </div>
                    </div>
                </div>
                <Collapse isOpen={expanded}>
                    <div className={'footer'}>
                        {
                            loadingInfo
                                ? <Col xs={12} className={'text-center'}>
                                    <LoadingIndicator size={100}/>
                                </Col>
                                : <>
                                    <div className={'d-flex flex-wrap'}>
                                        <Col xs={3} md={2} lg={3} xl={2}
                                             className={'d-flex align-items-center pr-0'}>
                                            Product
                                        </Col>
                                        <Col xs={3} lg={2} xl={3}
                                             className={'d-flex justify-content-end align-items-center'}>
                                            Quantity
                                        </Col>
                                        <Col xs={3} lg={2} xl={3}
                                             className={'d-flex justify-content-end align-items-center'}>
                                            Unit Price
                                        </Col>
                                    </div>
                                    {
                                        fullInfo?.items?.map(data => (
                                            <div key={data?.partNo ?? ''}
                                                 className={'d-flex flex-wrap black font-weight-500 mt-2 pt-1'}>
                                                <Col xs={3} md={2} lg={3} xl={2}
                                                     className={'d-flex align-items-center pr-0'}>
                                                    {data?.partNo ?? "--"}
                                                </Col>
                                                <Col xs={3} lg={2} xl={3}
                                                     className={'d-flex justify-content-end align-items-center'}>
                                                    {data?.orderQty ?? "--"}
                                                </Col>
                                                <Col xs={3} lg={2} xl={3}
                                                     className={'d-flex justify-content-end align-items-center'}>
                                                    {data?.unitPrice ? formatMoney(data.unitPrice) : '--'}
                                                </Col>
                                            </div>
                                        ))
                                    }
                                </>
                        }
                    </div>
                </Collapse>
            </div>
        </>
    );
}


export default InvoiceBox;
