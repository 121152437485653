import React from "react";
import {Col, Row} from "reactstrap";
import CreditBox from "../../../../../components/app-specific/order-boxes/credit-box";

const CreditsListView = ({data}) => {


    return (
        <Row className={'pt-3'}>
            {
                data?.map((credit) => (
                    <Col xs={12} key={credit.id} className={'pt-3'}>
                        <CreditBox data={credit}/>
                    </Col>
                ))
            }
        </Row>
    );
}


export default CreditsListView;

