import React, {useRef, useState} from "react";
import {Col} from "reactstrap";
import CreateCreditCardForm from "../../forms/create-credit-card-form";
import CreditCard from "../../credit-card";
import TryAgain from "../../try-again";
import {createUUId} from "../../../../../core/services/utils/utils";

const UserPaymentsCardSelectionSection = ({
                                              cards: cardsProps,
                                              getCards,
                                              onSelect,
                                              disabled,
                                          }) => {
    const [creating, setCreating] = useState(false);
    const formIdRef = useRef(createUUId(true));

    const cards = cardsProps?.filter(e => !e.isDefault) ?? []

    return (
        <>
            <Col xs={12}>
                <div className={'payment-card'}>
                    <CreateCreditCardForm
                        id={formIdRef.current}
                        onCreated={onSelect}
                        setLoading={setCreating}
                        colProps={{md: 12, xl: 6}}
                    />
                    <Col xs={12} xl={6}/>
                    <Col xs={12} xl={6} className={'add-button'}>
                        <button className={'button primary w-100'}
                                form={formIdRef.current}
                                disabled={creating || disabled}>
                            {
                                creating
                                    ? "Adding..."
                                    : "Add and Select"
                            }
                        </button>
                    </Col>
                </div>
            </Col>
            <Col xs={12} className={'mt-4 d-flex align-items-center'}>
                <p className={'heading small pr-2'}>
                    Saved Cards
                </p>
                <div className={'horizontal-divider'}/>
            </Col>
            <Col xs={12} className={'d-flex flex-wrap mt-5'}>
                {
                    cards?.length < 1
                        ? <TryAgain
                            text={"You do not have any credit cards in your account."}
                            buttonText={'Fetch again'}
                            onClick={getCards}
                        />
                        : cards?.map(card => (
                            <div key={card.id} className={'mr-3 pr-3 mb-3 pb-3'}>
                                <CreditCard
                                    card={card}
                                    focused={'number'}
                                    selectable
                                    onSelect={() => onSelect(card)}
                                />
                            </div>

                        ))
                }
            </Col>
        </>
    )
}

export default UserPaymentsCardSelectionSection;
