import React, {forwardRef} from 'react';
import accepts from "attr-accept";
import classnames from "classnames";
import {DynamicContentEditorInputFileAcceptTypes} from "../../../index.d";


const DynamicContentEditorUploadButton = forwardRef(({
                                                         multiple,
                                                         id,
                                                         onFileSelect,
                                                         children,
                                                         className,
                                                         accept = Object.values(DynamicContentEditorInputFileAcceptTypes),
                                                         disabled,
                                                         labelClassName = '',
                                                         ...props
                                                     }, ref) => {


        /**
         * Calls the onFileSelect if it exists and there is a selected file with the returned array of fileListTOArray
         * function.
         * @param e {Event}
         */
        const onFileAdd = (e) => {
            e.target.files.length
            && onFileSelect
            && !disabled
            && onFileSelect(fileListToArray(e.target.files))
        }


        /**
         * For every selected file, if the file has the accepted types, returns the files array.
         * if no multiple, then only returns the first selected file.
         *
         * @param files {FileList}
         * @return {FileList}
         */
        const fileListToArray = (files) => {
            const dataTransfer = new DataTransfer();
            for (let index = 0; index < files.length; index++) {
                if (!accepts(files.item(index), accept)) continue;
                if (multiple) {
                    dataTransfer.items.add(files.item(index));
                } else {
                    dataTransfer.items.add(files.item(index));
                    break;
                }
            }
            return dataTransfer.files;
        }


        return (
            <div className={className}
                 onClick={e => e.stopPropagation()}
                 {...props}
            >
                <input
                    ref={ref}
                    accept={accept.join(', ')}
                    className={'dynamic-content-editor-upload-button'}
                    id={id}
                    multiple={multiple}
                    type="file"
                    onChange={onFileAdd}
                    onClick={(e) => e.target.value = ''}
                />
                <label htmlFor={id} className={classnames(labelClassName)}>
                    {children}
                </label>
            </div>
        );
    }
);

export default DynamicContentEditorUploadButton;
