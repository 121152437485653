import ApiExecutor from "./executor";
import ApiEndpoints from "../../models/static/api-endpoints";
import {ApiInterceptors, ApiResponse, SaveDataGridStateRequestDs} from "./type-declarations";
import {ApiMethods} from "../../models/constants/enums";
import {DataGridSavedState, DataGridSavedStateEntry, DataGridSavedStateEntryData, SaveDataGridSavedStateEntry} from "../../../models";

/**
 * The interface that houses all the available api calls related to this package.
 */
class DataGridApiService {

    /**
     * The executor of this api responsible for handling the api call themselves.
     */
    private static readonly executor = new ApiExecutor();

    /**
     * Injects api interceptors to the axios instance used in this interface.
     * @param interceptors
     */
    static injectInterceptors(interceptors: Partial<ApiInterceptors>): void {
        return this.executor.injectInterceptors(interceptors);
    }

    // ###################################       DATA GRID         ###################################

    /**
     * Loads the saved data of the data-grid from the server.
     */
    static async getAllSavedStates(applicationName: string): ApiResponse<DataGridSavedState> {
        const response = await this.executor.execute({
            method: ApiMethods.get,
            url: ApiEndpoints.dataGrid.getData,
            params: {
                applicationName: applicationName,
            },
        });
        if (response?.resultFlag) {
            response.data = (response.data as Array<DataGridSavedStateEntry<string>>)
                    ?.map<DataGridSavedStateEntry<DataGridSavedStateEntryData | undefined>>(e => {
                        const res = e as unknown as DataGridSavedStateEntry<DataGridSavedStateEntryData | undefined>;
                        res.version = Number(res.version);
                        try {
                            res.data = JSON.parse(e.data ?? '{}');
                        } catch {
                            res.data = undefined;
                        }
                        return res;
                    })
                    ?.filter(e => !!e.data)
                ?? [];
        }
        return response;
    }

    /**
     * Saves the data-grid the newest state in the server.
     *
     * @param state             the state that is to be saved in the server.
     * @param abortController   the controller that would abort the execution of the api
     */
    static async saveDataGridState(state: SaveDataGridSavedStateEntry, abortController?: AbortController): ApiResponse<void> {
        return await this.executor.execute({
            method: ApiMethods.post,
            url: ApiEndpoints.dataGrid.saveData,
            data: {
                ...state,
                data: JSON.stringify(state.data),
            },
            signal: abortController?.signal,
        } as SaveDataGridStateRequestDs);
    }
}


export * from './type-declarations';
export default DataGridApiService;
