import React, {useEffect, useLayoutEffect, useMemo, useState} from "react";
import {matchPath, Redirect, Route, Switch} from "react-router-dom";
import MainViews from "./main";
import DashboardViews from "./dashboard";
import Header from "../containers/header"
import Footer from "../containers/footer"
import ErrorView from "./error";
import routes, {routeFunctions, routeLists} from "../routes";
import useRouter from "../hooks/use-router";
import PublicLinkViews from "./public-link";
import PolicyDialog from "../components/dialogs/policy-dialog";
import UnauthorizedDialog from "../components/dialogs/unauthorized-dialog";
import AccessDeniedDialog from "../components/dialogs/access-denied-dialog";
import {useDispatch} from "react-redux";
import {logoutUser} from "../../redux/entities/auth/actions";
import CacheService from "../../core/services/cache/cache-service";
import {setReduxHeaderType} from "../../redux/entities/header/actions";
import {apiMethods, HeaderTypes} from "../../core/constants/enums";
import ConfirmationDialog from "../components/dialogs/confirmation-dialog";
import AuthViews from "./auth";
import RedirectView from "./redirect";
import PublicViews from "./public";
import api, {Api} from "../../core/services/api/api";
import PrintViews from "./print";
import EnvService from "../../core/services/env-service";
import TestView from "./test";
import useIsMounted from "../hooks/use-is-mounted";
import {productsApis} from "../../core/constants/endpoints/endpoints";
import classnames from "classnames";

const Views = () => {
    const {history, location} = useRouter()
    const [allParts, setAllParts] = useState([]);
    const dispatch = useDispatch()
    const isMounted = useIsMounted();

    const inAuthViews = useMemo(() => !!matchPath(location.pathname, {
        path: [
            routes.auth.base,
            routes.auth.resetPassword,
            routes.auth.deleteAccount,
        ], exact: false,
    }), [location.pathname])

    /**
     * As soon as the component mounts, sets the event listener for header type changer and history listener.
     */
    useLayoutEffect(() => {
        const isLoggedIn = logoutIfNeeded()
        if (isLoggedIn) {
            dispatch(setReduxHeaderType(HeaderTypes.loggedIn));
        }
        return history.listen(listenToRouteChanges)
    }, [])

    /**
     * With each change in the history and location of the url:
     * - syncs the change in the history and location of the routes with the getters of the Api.
     */
    useLayoutEffect(() => {
        Api.history = history;
        Api.location = location;
        const unregister = history.listen((location) => {
            Api.location = location;
        })
        return () => unregister();
    }, [history])

    /**
     * As soon as the component mounts:
     * - fetches the sr-only parts of the system to render the links for crawlers.
     */
    useEffect(() => {
        getScreenReaderOnlyParts().then();
    }, [])

    /**
     * Fetches all the parts of the system to be used for creating links visible only to screen readers.
     * @return {Promise<void>}
     */
    const getScreenReaderOnlyParts = async () => {
        const response = await api({
            url: productsApis.getAll,
            method: apiMethods.get,
            loginRequired: false,
            showError: false,
        })
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            setAllParts(response?.data?.map(e => ({
                ...e,
                link: routeFunctions.public.partInformation(e.partNo ?? " "),
            })) ?? []);
        }
    }

    /**
     * Applies changes to the state based on the new route.
     *
     * this method also determines whether the window should scroll to the top of the view or not.
     * @param {Location} location
     * @param {import('history').Action} action
     */
    const listenToRouteChanges = (location, action) => {
        scrollTheViewIfNeeded(location);
    }

    /**
     * Scrolls the window to the top of the view if needed be.
     *
     * @param {Location} location
     */
    const scrollTheViewIfNeeded = (location) => {
        const viewWillScrollItself = !!matchPath(location.pathname, {
            path: [
                routes.public.about,
                routes.main.partInformation,
                routes.main.products,
                routes.publicLinks.partInformation,
            ], exact: true
        });
        const shouldNotScroll = !!matchPath(location.pathname, {
            path: [
                routes.public.content.allBlogs,
                routes.dashboard.compare,
                routes.public.content.allVideos,
            ], exact: true
        })
        const shouldScrollToTop = !viewWillScrollItself && !shouldNotScroll;
        if (shouldScrollToTop) {
            window.scrollTo(0, 0);
        }
    }

    /**
     * Logs the user out of the system if needed.
     *
     * NOTE: since we are passing the history object in the logoutUser method, then we will be redirected to the
     * sign-in view.
     * @return {boolean} boolean to determine if the user is logged-in.
     */
    const logoutIfNeeded = () => {
        if (!!matchPath(location.pathname, {
            path: [
                ...routeLists.auth,
            ], exact: true,
        })) {
            return false
        }
        if (CacheService.isLoggedIn())
            return true;
        if (!!matchPath(location.pathname, {
            path: [
                ...routeLists.auth,
                ...routeLists.public,
                ...routeLists.publicLinks,
                ...routeLists.blogs,
                routes.dynamicContentEditor,
            ], exact: true
        })) {
            return false;
        }
        dispatch(logoutUser(history, false))
        return false;
    }


    console.log(routeLists.publicLinks, location.pathname);

    return (
        <Switch>
            {
                EnvService.isDevelopment &&
                <Route path={routes.test}>
                    <TestView/>
                </Route>
            }
            <Route path={routes.landing}>
                <div className={classnames(`app-container`,
                    {'with-gradient': inAuthViews},
                    {'overflow-x-hidden': inAuthViews},
                )}>
                    <Header/>
                    <div className={'main-body'}>
                        <Switch>
                            <Route path={routes.error} exact>
                                <ErrorView/>
                            </Route>
                            <Route path={routes.redirect} exact>
                                <RedirectView/>
                            </Route>
                            <Route path={routeLists.auth} exact>
                                <AuthViews/>
                            </Route>
                            <Route path={routeLists.public} exact>
                                <PublicViews/>
                            </Route>
                            <Route path={routeLists.publicLinks} exact>
                                <PublicLinkViews/>
                            </Route>
                            <Route path={routeLists.print} exact>
                                <PrintViews/>
                            </Route>
                            <Route path={routeLists.dashboard} exact>
                                <DashboardViews/>
                            </Route>
                            <Route path={[routes.landing, ...routeLists.main]} exact>
                                <MainViews/>
                            </Route>
                            <Redirect to={routes.error}/>
                        </Switch>
                    </div>
                    <Footer/>
                    <PolicyDialog/>
                    <ConfirmationDialog/>
                    <UnauthorizedDialog/>
                    <AccessDeniedDialog/>
                </div>
                <div className={'sr-only subtree'}>
                    {
                        allParts?.map(part => (
                            <a key={part.link} href={part.link}>
                                Part No: {part?.partNo ?? ''} <br/>
                                Description: <div dangerouslySetInnerHTML={{__html: part?.description ?? '<p />'}}/>
                            </a>
                        ))
                    }
                </div>
            </Route>
        </Switch>
    );
}

export default Views;
