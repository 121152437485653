import React, {useRef} from "react";
import {Player} from "@lottiefiles/react-lottie-player";
import src from '../../../../assets/animations/lottie/loading.json'
import classnames from "classnames";

const LoadingIndicator = ({className = '', size = 250}) => {
    const playerRef = useRef();
    const lottieRef = useRef();

    return (
        <>
            <Player
                autoplay
                loop
                src={src}
                ref={playerRef}
                style={{
                    '--size': `${size ?? 250}px`,
                }}
                lottieRef={(ref) => (lottieRef.current = ref)}
                className={classnames('loading-indicator', className)}
            />
        </>
    )
}

export default LoadingIndicator;
