/**
 * The Validation Messages in this application
 */
export default class ValidateMessages {
    static incorrect = 'value is incorrect.';
    static passwordsMatch = 'passwords must match.';
    static acceptPrivacyPolicy = "You must accept our privacy policy";
    static required = 'This field is required';
    static phoneNumber = 'Mobile number not entered correctly.';
    static email = 'Email not entered correctly.';
    static number = 'The value must be numeric.';
    static minLength = (value: string) => `value must be more than ${value} characters.`;
    static maxLength = (value: string) => `value must be less than ${value} characters.`;
    static min = (value: string, verb = 'greater than') => `value must be ${verb} ${value}`;
    static max = (value: string) => `value must be less than ${value}`;
    static failed = 'An error has occurred.';
    static incorrectType = (type: string | number) => `value must be a ${type}`;
    static greaterThan = (number: string) => `The value must be greater than ${number}`;

    static lessThan = (number: string) => `The value must be less than ${number}`;
};

