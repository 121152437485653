import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import routes from "../../../routes";
import classnames from "classnames";
import useRouter from "../../../hooks/use-router";
import {ReactComponent as SearchIcon} from "../../../../assets/images/search-icon.svg";
import {matchPath} from "react-router";
import {ProductsSearchTypes} from "../../../../core/constants/enums";
import {Tooltip} from "@material-ui/core";
import ValidateMessages from "../../../../core/constants/validate-messages";
import MuiInput from "../input/mui-input";
import ProductSearchUtils from "../../../../core/services/utils/product-search-utils";


const HeaderSearchInput = ({
                               loading = false,
                               placeholder = 'Search...',
                               label,
                               type = 'text',
                           }) => {
    const {history, query, location, stringifyUrl} = useRouter();
    const [focused, setFocused] = useState(false);
    const [error, setError] = useState(false);
    const [openTooltip, setOpenTooltip] = useState(false);
    const [res, setRes] = useState('');
    /**@type {React.MutableRefObject<HTMLButtonElement>}*/
    const buttonRef = useRef();
    /**@type {React.MutableRefObject<HTMLInputElement>}*/
    const inputRef = useRef();
    const expanded = focused || res?.length > 0;

    /**
     * Listens for changes in res and expanded state and with each change:
     *
     * - if the error is shown, but the input value is empty, has more than 2 characters, or the input is not expanded,
     * then sets error to false so the tooltip is not shown anymore.
     */
    useLayoutEffect(() => {
        if (!error) return;
        if (res?.length > 2 || !res?.length || !expanded) {
            setError(false);
        }
    }, [res, expanded])

    /**
     * Listens for the changes in the url and with each change:
     *
     * - if the user is in the products view and is coming from the basic search tab, then sets the keywords to
     * match what the user has searched.
     */
    useEffect(() => {
        if (!matchPath(location.pathname, routes.main.products))
            return;
        if (query?.type !== ProductsSearchTypes.basic) {
            setRes('');
            return;
        }
        const data = JSON.parse(query.data ?? '{}');
        if (!data) {
            setRes('');
            return;
        }
        setRes(data[ProductsSearchTypes.basic]?.keyword ?? '')
    }, [location?.pathname, query])

    /**
     * Searches the result with the search callback if the result is a non-empty string
     */
    const onSearchPressed = () => {
        const shouldSearch = [buttonRef?.current, inputRef.current].includes(document.activeElement);
        if (!shouldSearch) {
            setFocused(true);
            return inputRef.current?.focus();
        }
        search(res);
    }

    /**
     * Listens for the key of enter, and if pressed, sets the res and also calls the search function
     * @param e
     */
    const onKeyPress = (e) => {
        // if enter is pressed
        if (e.charCode === 13) {
            setRes(e.target.value);
            if (search) search(res);
        }
    }

    /**
     * Navigates the user to the products search result view and searches the given text using basic search.
     *
     * - if the given text is empty, closes the input.
     * - if the given text's length is less than 3 characters, shows the error tooltip
     * @param {string} text the text to be used as the keywords in basic search.
     */
    const search = (text) => {
        if (!text?.length) {
            return setFocused(false);
        }
        if (text?.length < 3) {
            return setError(true);
        }
        history.push(stringifyUrl({
                url: routes.main.products,
                query: {
                    type: ProductsSearchTypes.basic,
                    data: JSON.stringify({
                        [ProductsSearchTypes.basic]: {
                            [ProductSearchUtils.basicSearchQueryKeys.keyword]: text,
                        }
                    })
                },
            })
        )
    }

    return (
        <div className={'header-search-input'}>
            <div
                className={classnames({'--focused': expanded})}
                onKeyPress={onKeyPress}
                onBlur={() => expanded && setFocused(false)}
                onMouseEnter={() => setOpenTooltip(true)}
                onMouseLeave={() => setOpenTooltip(false)}>
                {/*{*/}
                {/*    expanded &&*/}
                {/*    <button disabled={loading}*/}
                {/*            ref={buttonRef}*/}
                {/*            onClick={onSearchPressed}*/}
                {/*            className={'button icon'}>*/}
                {/*        <SearchIcon className={'ml-2'}/>*/}
                {/*    </button>*/}
                {/*}*/}
                <Tooltip
                    title={ValidateMessages.minLength('3')}
                    placement={'bottom'}
                    open={error && expanded && openTooltip}
                    arrow>
                    <MuiInput
                        fullWidth
                        size={'small'}
                        ref={inputRef}
                        onFocus={() => setFocused(true)}
                        type={type}
                        name={label}
                        value={res}
                        onChange={e => setRes(e.target.value)}
                        placeholder={placeholder}
                        InputProps={{
                            endAdornment: (
                                <button
                                    ref={buttonRef}
                                    disabled={loading}
                                    onClickCapture={onSearchPressed}
                                    className={'button icon'}>
                                    <SearchIcon className={'icon small'}/>
                                </button>
                            )
                        }}
                    />
                    {/*<input*/}
                    {/*    ref={inputRef}*/}
                    {/*    onFocus={() => setFocused(true)}*/}
                    {/*    type={type}*/}
                    {/*    name={label}*/}
                    {/*    value={res}*/}
                    {/*    onChange={e => setRes(e.target.value)}*/}
                    {/*    placeholder={placeholder}*/}
                    {/*/>*/}
                </Tooltip>
                {/*{*/}
                {/*    !expanded &&*/}
                {/*    <button*/}
                {/*        disabled={loading}*/}
                {/*        onClickCapture={onSearchPressed}*/}
                {/*        className={'button icon'}>*/}
                {/*        <SearchIcon className={'icon small'}/>*/}
                {/*    </button>*/}
                {/*}*/}
            </div>
        </div>
    );
}


export default HeaderSearchInput;
