import React, {useEffect, useState} from "react";
import {Col} from "reactstrap";
import useRouter from "../../../../../hooks/use-router";
import routes from "../../../../../routes";
import api from "../../../../../../core/services/api/api";
import {cmsBlogApis, cmsVideoApis} from "../../../../../../core/constants/endpoints/cms_endpoints";
import VideoCard from "../../../../../components/app-specific/content-boxes/video-card";
import BlogCard from "../../../../../components/app-specific/content-boxes/blog-card";
import TryAgain from "../../../../../components/app-specific/try-again";
import {apiMethods} from "../../../../../../core/constants/enums";
import useIsMounted from "../../../../../hooks/use-is-mounted";

const ContentMainBothSections = () => {
    const {history} = useRouter();
    const [videos, setVideos] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [blogsLoading, setBlogsLoading] = useState(true);
    const [videosLoading, setVideosLoading] = useState(true);
    const isMounted = useIsMounted();

    /**
     * Loads the most recent videos and blogs as soon as the component mounts.
     */
    useEffect(() => {
        getMostRecentVideos().then();
        getMostRecentBlogs().then();
    }, [])


    /**
     * Loads the list of most recent videos from the server and if the result of the api is successful, sets the list
     */
    const getMostRecentVideos = async () => {
        setVideosLoading(true);
        const response = await api({
            url: cmsVideoApis.getAllMostViewed,
            method: apiMethods.get,
            loginRequired: false,
        });
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setVideosLoading(false);
            return;
        }
        setVideosLoading(false);
        setVideos(response?.data?.slice(0, Math.min(response?.data?.length ?? 0, 4)) ?? [])
    }

    /**
     * Loads the list of most recent blogs from the server and if the result of the api is successful, sets the list
     */
    const getMostRecentBlogs = async () => {
        setBlogsLoading(true);
        const response = await api({
            url: cmsBlogApis.getAllMostViewed,
            method: apiMethods.get,
            loginRequired: false,
        });
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setBlogsLoading(false);
            return;
        }
        setBlogsLoading(false);
        setBlogs(response?.data?.slice(0, Math.min(response?.data?.length ?? 0, 3)) ?? [])
    }

    /**
     * Navigates the user to all videos view in the main blogs view
     */
    const navigateToAllVideos = () => {
        history.push(routes.public.content.allVideos);
    }


    /**
     * Navigates the user to all blogs view in the main blogs view
     */
    const navigateToAllBlogs = () => {
        history.push(routes.public.content.allBlogs);
    }

    return (
        <>
            <div className={'w-100 d-flex justify-content-between align-items-center mb-3 px-3'}>
                <h2 className={'section-title'}>
                    Videos
                </h2>
                <button className={'button primary outlined py-2'} onClick={navigateToAllVideos}>
                    See all
                </button>
            </div>
            {
                videosLoading
                    ? (
                        <div className={'d-flex flex-wrap w-100'}>
                            {
                                Array(4).fill(null).map((e, index) => (
                                    <Col key={index} xs={12} sm={6} xl={4}
                                         className={'order-1 order-md-2 mb-4'}>
                                        <div className={'loading video'}>
                                            <div/>
                                        </div>
                                    </Col>
                                ))
                            }
                        </div>
                    )
                    : (
                        <div className={'d-flex flex-wrap w-100'}>
                            {
                                !videos?.length
                                    ? <TryAgain text={'There is currently no videos available'}
                                                buttonText={'Retry'}
                                                onClick={getMostRecentVideos}
                                    />
                                    : videos.map(video => (
                                        <VideoCard key={video.id} data={video}/>
                                    ))
                            }
                        </div>
                    )
            }
            <div className={'my-5 w-100'}/>
            <div className={'my-5 w-100'}/>
            <div className={'w-100 d-flex justify-content-between align-items-center mb-3 px-3'}>
                <h2 className={'section-title'}>
                    Blogs
                </h2>
                <button className={'button primary outlined py-2'} onClick={navigateToAllBlogs}>
                    See all
                </button>
            </div>
            {
                blogsLoading
                    ? (
                        <>
                            {
                                Array(3).fill(null).map((e, index) => (
                                    <Col key={index} xs={12} sm={6} xl={4}
                                         className={'order-1 mb-4 order-md-2 h-100'}>
                                        <div className={'loading blog'}>
                                            <div/>
                                        </div>
                                    </Col>
                                ))
                            }
                        </>
                    )
                    : (
                        <div className={'d-flex flex-wrap w-100'}>
                            {
                                !blogs?.length
                                    ? <TryAgain text={'There is currently no blogs available.'}
                                                buttonText={'Retry'}
                                                onClick={getMostRecentBlogs}/>
                                    : blogs.map(blog => (
                                        <Col key={blog.id} xs={12} lg={6} xl={4}
                                             className={'order-1 order-md-2 mb-4'}>
                                            <BlogCard blog={blog}/>
                                        </Col>
                                    ))
                            }
                        </div>
                    )
            }
        </>
    );
}


export default ContentMainBothSections;
