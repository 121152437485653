import React, {useMemo, useRef, useState} from "react";
import DynamicContentEditorDropzone from "../index";
import DynamicContentEditorUtils from "../../../../core/services/utils";
import classnames from "classnames";
import {DynamicContentEditorInputFileAcceptTypes} from "../../../../index.d";
import DynamicContentEditorApi from "../../../../core/services/api";
import useIsMounted from "../../../hooks/use-is-mounted";
import {ReactComponent as Placeholder} from '../../../../assets/images/pdf-placeholder.svg';
import {ReactComponent as File} from '../../../../assets/images/pdf-file.svg';

const DynamicContentEditorPdfDropzone = ({onChange, value, disabled, setLoading}) => {
    const idRef = useRef(DynamicContentEditorUtils.createUUId(true));
    const [uploading, setUploading] = useState(false);
    const isMounted = useIsMounted();

    const hasPdf = useMemo(() => !!(value), [value]);


    /**
     * Fetches the file for uploading purposes.
     * @param {FileList} files
     */
    const uploadPdf = async (files) => {
        const file = files.item(0);
        setUploading(true);
        if (setLoading) setLoading(true);
        const response = await DynamicContentEditorApi.uploadFile(file);
        setUploading(false);
        if (setLoading) setLoading(false);
        if (!isMounted())
            return;
        if (response?.resultFlag) {
            onChange(response.configuration?.FileBaseURL?.concat(response.data?.fileName) ?? '')
        }
    }

    /**
     * Removes the pdf of the dropzone.
     */
    const removePdf = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onChange(undefined)
    }

    return (
        <>
            <DynamicContentEditorDropzone
                openOnClick
                noParse
                setFiles={uploadPdf}
                disabled={disabled}
                loading={uploading}
                uploading={uploading}
                accept={[DynamicContentEditorInputFileAcceptTypes.pdf]}
                dropZoneClassName={classnames(
                    'dynamic-content-editor-pdf-dropzone',
                    {'has-image': hasPdf}
                )}
                multiple={false}
                id={idRef.current}
                notAcceptedDurationInMs={2000}
            >
                {(_, highlight, notAccepted) => (
                    <>
                        <div className='d-flex justify-content-center'>
                            <div className={classnames('img-container')}>
                                <div className={'background'}>
                                    {
                                        hasPdf
                                            ? <File/>
                                            : <Placeholder/>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='dynamic-content-editor-drop-zone-call-to-action'>
                            {
                                !(highlight || notAccepted)
                                    ? 'Click here or Drag and Drop your PDF'
                                    : notAccepted
                                        ? "This format is not supported"
                                        : "Drop you PDF here"
                            }
                        </div>
                        {
                            !!value &&
                            <button
                                type={'button'}
                                className={'dynamic-content-editor-dropzone-remove-button'}
                                disabled={disabled || uploading}
                                onClick={removePdf}
                            >
                                Remove this PDF
                            </button>
                        }
                    </>
                )}
            </DynamicContentEditorDropzone>
        </>
    )
}

export default DynamicContentEditorPdfDropzone;
