import Cookies from "universal-cookie";
import {Cookie, CookieChangeListener, CookieGetOptions, CookieSetOptions} from "universal-cookie/cjs/types";
import moment from "moment";

/**
 * Handles all the operations related to the cookies in the browser.
 */
export default class CookiesService {
    /**
     * The list of keys that are used to store the cookies
     */
    static keys = {
        userInfo: {
            name: "infoUser",
        },
        login: {
            name: "login",
        },
    }

    /**
     * The Cookies that is used to perform the operations.
     */
    static cookies = new Cookies();

    /**
     * Gets the value stored in the cookies given the key and the options.
     * @param {string} name
     * @param {CookieGetOptions} options
     */
    static get(name: string, options?: CookieGetOptions): any {
        return this.cookies.get(name, options);
    }

    /**
     * Gets all the values stored in the cookies given the options.
     * @param {CookieGetOptions} options
     */
    static getAll(options?: CookieGetOptions): any {
        return this.cookies.getAll(options);
    }

    /**
     * Saves a value inside a cookie associated with a key and optional cookies options.
     * @param {string} name the key
     * @param {any} value the value to be saved
     * @param {CookieSetOptions} options the options for saving a cookie
     */
    static set(name: string, value: Cookie, options?: CookieSetOptions): void {
        return this.cookies.set(name, value, {...options, expires: moment().add(7, "days").toDate()});
    }

    /**
     * Removes the value associated with the given key and optional options
     * @param {string} name the key
     * @param {CookieSetOptions} options optional options
     */
    static remove(name: string, options?: CookieSetOptions): void {
        return this.cookies.remove(name, options);
    }

    /**
     * Attaches an event listener to the changes in cookies.
     * @param {CookieChangeListener} callback the listener to be attached.
     */
    static addChangeListener(callback: CookieChangeListener): void {
        return this.cookies.addChangeListener(callback);
    }

    /**
     * Removes an attached event listener from the changes in cookies.
     * @param {CookieChangeListener} callback the listener to be removed.
     */
    static removeChangeListener(callback: CookieChangeListener): void {
        return this.cookies.removeChangeListener(callback);
    }
}
