import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "reactstrap";
import useRouter from "../../../../hooks/use-router";
import {cmsBlogApis,} from "../../../../../core/constants/endpoints/cms_endpoints";
import api from "../../../../../core/services/api/api";
import {ReactComponent as BackButton} from '../../../../../assets/images/blog-back-icon.svg'
import BlogCard from "../../../../components/app-specific/content-boxes/blog-card";
import BlogDateIcon from "../../../../../assets/images/blogs/date-icon.svg";
import BlogViewsIcon from "../../../../../assets/images/blogs/views-icon.svg";
import moment from "moment";
import LazyImage from "../../../../components/app-specific/lazy-lmage";
import classnames from "classnames";
import {apiMethods} from "../../../../../core/constants/enums";
import useIsMounted from "../../../../hooks/use-is-mounted";
import FloatingContainer from "../../../../components/app-specific/floating-container";
import ContentShareButtons from "../../../../components/app-specific/content-share-buttons";

const ContentBlogInformationView = () => {
    const {location, params, history} = useRouter();
    const [loading, setLoading] = useState(true);
    const [loadingRelatedBlogs, setLoadingRelatedBlogs] = useState(true);
    const [blog, setBlog] = useState({});
    const [relatedBlogs, setRelatedBlogs] = useState([]);
    const isMounted = useIsMounted();

    const baseUrl = window.location.origin;

    /**
     * Loads the full information of the specified blog based on its slug and searches for the list of related blogs
     * (by tags) as soon as this component mounts.
     */
    useEffect(() => {
        getFullInformation();
    }, [params?.slug])


    /**
     * Loads the full information of the specified blog based on its slug and if the result of the api is
     * successful, sets the data
     */
    const getFullInformation = () => {
        setLoading(true);
        api({
            url: cmsBlogApis.getBySlug(params.slug),
            method: apiMethods.get,
            loginRequired: false,
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setLoading(false);
                return;
            }
            setBlog(response?.data ?? {});
            setLoading(false);
            if (response?.resultFlag) {
                getRelatedBlogs(response?.data);
            } else {
                setLoadingRelatedBlogs(false);
            }
        });
    }

    /**
     * Loads the related blogs of the current blog by searching with blogs' tags and if the result of the api is
     * successful, sets the data
     */
    const getRelatedBlogs = (blog) => {
        api({
            url: cmsBlogApis.search,
            method: apiMethods.post,
            loginRequired: false,
            data: {
                // here we are adding one more than the usual since the same blog is related to itself,
                paginationInfo: {currentPage: 1, pageSize: 4},
                filters: {tagsId: blog?.tags?.map(e => e.id)}
            }
        }).then((response) => {
            if (!isMounted()) return;
            if (response?.isPreemptedDueToNotBeingLoggedIn) {
                setLoadingRelatedBlogs(false);
                return;
            }
            setRelatedBlogs(response.data?.resultList
                    ?.filter(e => e.id !== blog?.id)
                    ?.slice(0, 3)
                ?? []);
            setLoadingRelatedBlogs(false);
        });
    }


    return (
        <>
            <main className={'blogs-view'}>
                <Container>
                    <FloatingContainer setHeight>
                        <Row className={'justify-content-around align-items-start mx-4'}>
                            {
                                loading
                                    ? (
                                        <>
                                            <div className={'d-flex w-100 justify-content-between align-items-start'}>
                                                <div className={'d-flex flex-column w-50 w-lg-25'}>
                                                    <div className={'loading extra-small mb-2'}>
                                                        <div/>
                                                    </div>
                                                    <div className={'loading extra-extra-small'}>
                                                        <div/>
                                                    </div>
                                                </div>
                                                <div className={'w-10'}>
                                                    <div className={'loading extra-extra-small'}>
                                                        <div/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className={'w-100 my-4 mt-5'}/>
                                            <div className={'w-100 loading blog-main-image mb-4'}>
                                                <div/>
                                            </div>
                                            <div className={'d-flex w-100'}>
                                                <div className={'w-50 loading extra-small mb-5'}>
                                                    <div/>
                                                </div>
                                            </div>

                                            <div className={'w-100 loading extra-extra-small'}>
                                                {
                                                    Array(20).fill(null).map((e, index) => (
                                                        <div key={index}/>
                                                    ))
                                                }
                                            </div>
                                        </>
                                    )
                                    : (
                                        <>
                                            <div className={'d-flex w-100 justify-content-between align-items-start'}>
                                                <div className={'d-flex flex-column w-75'}>
                                                    <div className={'d-flex w-100 justify-content-start' +
                                                        ' align-items-center'}>
                                                        <div onClick={history.goBack}>
                                                            <BackButton className={'back-button'}/>
                                                        </div>
                                                        <h2 className={'header-title m-0'}>
                                                            {blog.title ?? ''}
                                                        </h2>
                                                    </div>
                                                    <div className={'d-flex w-100 justify-content-start' +
                                                        ' align-items-center mt-2 ml-4'}>
                                                        <p className={'date mr-4 ml-3'}>
                                                            <img className={'mr-2'} src={BlogDateIcon} alt="date icon"/>
                                                            {blog?.lastModifyDateTime ? moment(blog?.lastModifyDateTime).format('MMM DD, YYYY') : ''}
                                                        </p>
                                                        <p className={'date'}>
                                                            <img className={'mr-2 ml-2'} src={BlogViewsIcon}
                                                                 alt="views icon"/>
                                                            {`${blog.viewCount ?? 0} viewed`}
                                                        </p>
                                                    </div>

                                                </div>
                                                <ContentShareButtons
                                                    tag={'div'}
                                                    className={'mt-2 d-flex align-items-center'}
                                                    title={blog?.title}
                                                    slug={blog?.slug}
                                                    link={baseUrl?.concat(location.pathname)}
                                                />
                                            </div>
                                            <div className={'w-100 my-4 mt-5'}/>
                                            <div className={'blog-image mb-4 mx-3'}>
                                                <LazyImage className={'image'}
                                                           src={blog?.coverImage?.srcUrl}
                                                           alt={blog?.slug}
                                                />
                                            </div>
                                            <div className={'w-100 my-3'}/>
                                            <div className={'w-100 mx-3'}>
                                                {
                                                    blog?.otherPropValues?.length > 0 &&
                                                    <>
                                                        <div className={'w-100 mt-3'}/>
                                                        <p className={'w-100 content-info-features'}>
                                                            Blog's features:
                                                        </p>
                                                        <div className={'w-100 mb-5'}/>
                                                    </>
                                                }
                                            </div>
                                            <div className={'d-flex flex-wrap w-100 mx-3'}>
                                                {
                                                    blog?.otherPropValues?.map((e, index) => (
                                                        <div
                                                            key={e.property?.name ?? index}
                                                            className={classnames('blog-property-box', {'no-border': index === blog?.otherPropValues?.length - 1})}>
                                                            <div className={classnames('ball')}/>

                                                            <div className={'d-flex flex-column flex-wrap'}>
                                                                <h4 className={'title mb-1'}>
                                                                    {e?.property?.name ?? ''}
                                                                </h4>
                                                                <p className={'value'}>
                                                                    {e?.value ?? ''}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className={'w-100 my-2'}/>
                                            <div className={'w-100 blog-body mx-3'}
                                                 dangerouslySetInnerHTML={{__html: blog?.bodyContent ?? ''}}/>
                                        </>
                                    )
                            }
                            <div className={'w-100 my-4'}/>
                            {
                                loadingRelatedBlogs
                                    ? (
                                        <Col xs={12} className={'mt-5 d-flex flex-wrap p-0'}>
                                            {
                                                <div className={'w-50 mb-3'}>
                                                    <div className={'loading extra-small'}>
                                                        <div/>
                                                    </div>
                                                </div>
                                            }
                                            <div className={'w-100'}/>
                                            {
                                                Array(3).fill(null).map((e, index) => (
                                                    <Col xs={12} key={index} sm={6} md={4} className={'mb-3'}>
                                                        <div className={'loading blog'}>
                                                            <div/>
                                                        </div>
                                                    </Col>

                                                ))
                                            }
                                        </Col>
                                    )
                                    : (
                                        <Col xs={12} className={'mt-5 d-flex flex-wrap p-0'}>
                                            {
                                                <div className={'w-100 mx-3 mb-3'}>
                                                    <h2 className={'header-title'}>
                                                        Related Topics
                                                    </h2>
                                                </div>
                                            }
                                            <div className={'w-100'}/>
                                            {
                                                relatedBlogs.map((blog) => (
                                                    <Col key={blog.id} xs={12} sm={12} md={6} lg={4} className={'mb-3'}>
                                                        <BlogCard secondVersion blog={blog}/>
                                                    </Col>

                                                ))
                                            }
                                        </Col>
                                    )
                            }
                        </Row>
                    </FloatingContainer>
                </Container>
            </main>
        </>
    );
}


export default ContentBlogInformationView;
