class DynamicContentEditorApiResponseCodes {

    // ########################### HTTP SPECIFIC ###################################

    /**When the user should log in to access the data from the server.*/
    static readonly unauthorized: number = 401;
    /**When the user does not have access to the requested data.*/
    static readonly forbiddenAccess: number = 403;

    // ########################### CUSTOM ###################################

    /**When the server call successfully was made but response was never returned.*/
    static readonly aborted: number = 597;
    /**When the server call failed due to a problem in request preparation.*/
    static readonly requestFailed: number = 599;
    /**When the server call successfully was made but response was never returned.*/
    static readonly serverNotResponded: number = 598;
}

export default DynamicContentEditorApiResponseCodes;
