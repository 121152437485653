import React, {useEffect} from "react";
import {matchPath, Route, Switch} from "react-router-dom";
import routes from "../../../routes";
import ContentMainPage from "./main";
import useRouter from "../../../hooks/use-router";
import ContentSearchResults from "./search_results";
import ContentBlogInformationView from "./blog-information";
import ContentVideoInformationView from "./video-information";


const ContentViews = () => {
    const {location, history} = useRouter();

    /**
     * Listens for the changes in location and with each change
     * - sets the height of the header to 50pxs as soon as the user opens the blogs pages
     */
    useEffect(() => {
        if (matchPath(location.pathname, {path: routes.public.content.base, exact: true})) {
            return history.replace(routes.public.content.main);
        }
    }, [location]);

    return (
        <>
            <Switch>
                <Route path={[
                    routes.public.content.main,
                    routes.public.content.allBlogs,
                    routes.public.content.allVideos
                ]} exact>
                    <ContentMainPage/>
                </Route>
                <Route path={[routes.public.content.searchResults]} exact>
                    <ContentSearchResults/>
                </Route>
                <Route path={[routes.public.content.blog]} exact>
                    <ContentBlogInformationView/>
                </Route>
                <Route path={[routes.public.content.video]} exact>
                    <ContentVideoInformationView/>
                </Route>
            </Switch>
        </>

    );
};

export default ContentViews;
