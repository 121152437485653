import React from "react";
import {Route, Switch} from "react-router-dom";
import routes from "../../routes";
import PrintInvoiceView from "./invoice";

const PrintViews = () => {

    return (
        <>
            <Switch>
                <Route path={routes.print.invoice} exact>
                    <PrintInvoiceView/>
                </Route>
                <Route path={routes.print.credit} exact>
                    <PrintInvoiceView credit/>
                </Route>
            </Switch>
        </>
    )
}

export default PrintViews;
