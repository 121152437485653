import React, {useEffect, useState} from "react";
import useRouter from "../../../../hooks/use-router";
import {useDispatch, useSelector} from "react-redux";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {
    AddressTypes,
    apiMethods,
    PaymentTerms,
    ShoppingCartInjectedPropertyNames,
    ShoppingCartProcessSteps,
    TransactionTypes
} from "../../../../../core/constants/enums";
import api from "../../../../../core/services/api/api";
import {addressesApis, shoppingCartApis} from "../../../../../core/constants/endpoints/endpoints";
import routes from "../../../../routes";
import {confirmationDialog} from "../../../../../redux/entities/dialogs/actions";
import {formatMoney, getAddressHTML} from "../../../../../core/services/utils/utils";
import classnames from "classnames";
import {ReactComponent as RemoveIcon} from "../../../../../assets/images/shopping-cart/remove.svg";
import {ReactComponent as CloseIcon} from "../../../../../assets/images/shopping-cart/close-cart.svg";
import {ReactComponent as EditIcon} from "../../../../../assets/images/shopping-cart/edit.svg";
import MuiInput from "../../../../components/base/input/mui-input";
import EditShoppingCartAddressDialog from "../../../../components/dialogs/edit-shopping-cart-address-dialog";
import AddGiftCardOrCreditToShoppingCartDialog
    from "../../../../components/dialogs/add-giftcard-or-credit-to-shopping-card-dialog";
import {Fade} from "@material-ui/core";

const ShoppingCartSummary = ({cart, setCart, editable, step, checkoutOrder, checkingOut, sendEmail}) => {
    const {history} = useRouter();
    const [addCreditOrGiftCard, setAddCreditOrGiftCard] = useState(false);
    const [loadingCountries, setLoadingCountries] = useState(false)
    const [closing, setClosing] = useState(false);
    const [countries, setCountries] = useState([])
    const [provinces, setProvinces] = useState({});
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    const partsChanging = cart?.parts?.some(e => !!
            (e ?? {})[ShoppingCartInjectedPropertyNames.item.updating] ||
        (e ?? {})[ShoppingCartInjectedPropertyNames.item.removing]
    ) ?? false;
    const transactionsChanging = cart?.appliedTransactions
        ?.some(e => !!(e ?? {})[ShoppingCartInjectedPropertyNames.transactions.loading]) ?? false;
    const transactions = cart?.appliedTransactions?.filter(e =>
            e.reference !== `${cart.quoteId}`
            && [
                TransactionTypes.creditMemo,
                TransactionTypes.payment,
                TransactionTypes.cashOrChequePayment,
            ].includes(e.transactionTypeName)
    ) ?? [];
    const creditMemos = transactions?.filter(e => e.transactionTypeName === TransactionTypes.creditMemo) ?? []
    const giftCard = cart?.appliedGiftCardInfo;
    const summaryButtonProcessing = partsChanging || transactionsChanging || checkingOut;
    const summaryButtonDisabled = !cart?.parts?.length;
    const canSeeCredits = cart?.paymentTerm === PaymentTerms.COD;
    const transactionButtonsDisabled = (transaction) =>
        checkingOut ||
        step === ShoppingCartProcessSteps.success ||
        transaction[ShoppingCartInjectedPropertyNames.transactions.loading];


    /**
     * As soon as the component mounts:
     * fetches all the countries from the server.
     */
    useEffect(() => {
        getCountries().then()
    }, [])


    /**
     * Fetches all the countries from the server.
     * @return {Promise<void>}
     */
    const getCountries = async () => {
        setLoadingCountries(true)
        const response = await api({
            url: addressesApis.getAllCountries,
            method: apiMethods.get,
        })
        if (!isMounted()) return;
        setLoadingCountries(false);
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            return;
        }
        if (response?.resultFlag) {
            setCountries(response?.data ?? [])
        }
    }

    /**
     * Closes the shopping cart of the user and then navigates them to their orders view.
     * @return {Promise<void>}
     */
    const closeShoppingCart = async () => {
        setClosing(true)
        const response = await api({
            url: shoppingCartApis.saveForLater,
            method: apiMethods.put,
        })
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setClosing(false);
            return;
        }
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setClosing(false);
            return;
        }
        if (response?.resultFlag) {
            history.push(routes.dashboard.orders)
        }
    }

    /**
     * Removes a gift card from this shopping card.
     * @return {Promise<boolean>}
     */
    const removeGiftCard = async () => {
        setCart(prevState => ({
            ...prevState,
            appliedGiftCardInfo: {
                ...prevState?.appliedGiftCardInfo,
                [ShoppingCartInjectedPropertyNames.transactions.loading]: true,
            }
        }))
        const response = await api({
            url: shoppingCartApis.removeGiftCard,
            method: apiMethods.put,
        })
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setCart(prevState => ({
                ...prevState,
                appliedGiftCardInfo: {
                    ...prevState?.appliedGiftCardInfo,
                    [ShoppingCartInjectedPropertyNames.transactions.loading]: false,
                }
            }))
            return false;
        }
        if (response?.resultFlag) {
            setCart(response?.data);
        } else {
            setCart(prevState => ({
                ...prevState,
                appliedGiftCardInfo: {
                    ...prevState?.appliedGiftCardInfo,
                    [ShoppingCartInjectedPropertyNames.transactions.loading]: false,
                }
            }))
        }
        return response?.resultFlag ?? false;
    }

    /**
     * Removes a transaction from this shopping cart, a transaction in this case can be a payment, credit memo, or a
     * gift card (a refillable gift card only).
     *
     * if the result of the api is successful, then first removes that transaction from the local state and then
     * merges the server changes because of the local payments
     * @param transaction
     * @return {Promise<boolean>}
     */
    const removeTransaction = async (transaction) => {
        setCart(prevState => ({
            ...prevState,
            appliedTransactions: prevState?.appliedTransactions?.map(e => e.id === transaction.id
                ? {...e, [ShoppingCartInjectedPropertyNames.transactions.loading]: true}
                : e
            ) ?? []
        }))
        const response = await api({
            url: shoppingCartApis.removeTransaction,
            method: apiMethods.put,
            data: {
                transactionId: transaction.id,
            }
        })
        if (!isMounted()) return false;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setCart(prevState => ({
                ...prevState,
                appliedTransactions: prevState?.appliedTransactions?.map(e => e.id === transaction.id
                    ? {...e, [ShoppingCartInjectedPropertyNames.transactions.loading]: false}
                    : e
                ) ?? []
            }))
            return false;
        }
        if (response?.resultFlag) {
            // do not remove any of these calls, we need both for successful ui update!
            setCart(prevState => ({
                ...prevState,
                appliedTransactions: prevState?.appliedTransactions?.map(e => e.id === transaction.id
                    ? {...e, [ShoppingCartInjectedPropertyNames.transactions.extra]: undefined}
                    : e
                ) ?? []
            }))
            setCart(response?.data);
        } else {
            setCart(prevState => ({
                ...prevState,
                appliedTransactions: prevState?.appliedTransactions?.map(e => e.id === transaction.id
                    ? {...e, [ShoppingCartInjectedPropertyNames.transactions.loading]: false}
                    : e
                ) ?? []
            }))
        }
        return response?.resultFlag ?? false;
    }

    /**
     * Shows the confirmation dialog to get user confirmation before removing the selected item.
     *
     * this method is also responsible for assigning the proper description and callback if the user confirmed the
     * operation.
     * @param {any} item the item to be removed
     * @param {string} type one of the properties of TransactionTypes
     */
    const onRemoveClicked = (item, type) => {
        const callback = (accepted) => {
            if (!accepted) return true;
            switch (type) {
                case TransactionTypes.giftCard:
                    return removeGiftCard();
                case TransactionTypes.creditMemo:
                case TransactionTypes.payment:
                    return removeTransaction(item);
                default:
                    return true;
            }
        };
        let description;
        switch (type) {
            case TransactionTypes.giftCard:
                description = "Are you sure you want to remove the applied gift card from your cart?"
                break;
            case TransactionTypes.creditMemo:
                description = "Are you sure you want to remove this credit memo from your cart?"
                break;
            case TransactionTypes.payment:
                description = "Are you sure you want to refund this payment?"
                break;
            default:
                return;
        }
        dispatch(confirmationDialog({
            open: true,
            title: "Remove Confirmation",
            description: description,
            callback: callback,
            mandatory: false,
        }));
    }

    /**
     * Renders the product table of this summary.
     * @return {JSXElement[]}
     */
    const renderProductEntries = () => {
        return cart?.parts?.map((item, index) => {
            item = item ?? {};
            const quantity = item[ShoppingCartInjectedPropertyNames.item.newQuantity] ?? item?.quantity ?? 1;
            return (
                <tr key={item.partNo} className={classnames({'white': index % 2 === 0})}>
                    <td>
                        {item.partNo ?? '--'}
                    </td>
                    <td>
                        {quantity ?? '--'}
                    </td>
                    <td>
                        {
                            item?.unitPrice !== undefined
                                ? formatMoney(item.unitPrice * quantity)
                                : '--'
                        }
                    </td>
                </tr>
            )
        })
    }

    /**
     * Creates the entries of this bill info
     * it includes the subtotal, taxes and the list of transactions for this order.
     *
     * @return {JSX.Element[]}
     */
    const renderSummaryEntries = () => {
        const res = [
            <div className={'transaction subtotal'} key={'subtotal'}>
                <div className={'d-flex flex-grow-1 text-right'}>
                    <p>Subtotal:</p>
                </div>
                <div className={'d-flex flex-grow-1 text-left'}>
                    <p>
                        {formatMoney(cart?.subtotalAmount ?? 0)}
                    </p>
                </div>
            </div>,
        ];
        if ((cart?.totalItemsDiscountAmount ?? 0) > 0) {
            res.push(
                <div className={'transaction subtotal'} key={'discount'}>
                    <div className={'d-flex flex-grow-1 text-right'}>
                        <p>Discount:</p>
                    </div>
                    <div className={'d-flex flex-grow-1 text-left'}>
                        <p>
                            {`-${formatMoney(cart?.totalItemsDiscountAmount ?? 0)}`}
                        </p>
                    </div>
                </div>
            )
        }

        cart?.taxes?.forEach((tax, index) => {
            res.push(
                <div
                    className={classnames(
                        'transaction tax',
                        {'last': (transactions?.filter(e => [TransactionTypes.creditMemo, TransactionTypes.giftCard].includes(e.transactionTypeName))?.length ?? 0) < 1,}
                    )}
                    key={`tax-${index}`}
                >
                    <div className={'d-flex flex-grow-1 text-right'}>
                        <p className={classnames({'crossed-over': !!(tax?.exemptNo)})}>
                            {`${tax.shortname ?? '--'}${tax?.rate ? `(${tax?.rate}%)` : ''}:`}
                        </p>
                    </div>
                    <div className={'d-flex flex-grow-1 text-left'}>
                        <p>
                            {!!(tax.exemptNo) ? `${tax.exemptNo}` : formatMoney(tax.total ?? 0)}
                        </p>
                    </div>
                </div>,
            );
        });
        res.push(
            <div className={'transaction subtotal'} key={'transaction-subtotal'}>
                <div className={'d-flex flex-grow-1 text-right'}>
                    <p>Total:</p>
                </div>
                <div className={'d-flex flex-grow-1 text-left'}>
                    <p>
                        {formatMoney(cart?.newTotalAmount ?? 0)}
                    </p>
                </div>
            </div>,
        )
        if (!!giftCard) {
            const giftCardDisabled = transactionButtonsDisabled(giftCard);
            res.push(
                <div
                    className={classnames(
                        'transaction',
                        TransactionTypes.giftCard,
                        {'last': (transactions?.filter(e => e.transactionTypeName === TransactionTypes.creditMemo)?.length ?? 0) < 1}
                    )}
                    key={'gift-card'}
                >
                    <div className={'d-flex flex-grow-1 text-right'}>
                        <p>
                            Gift Card:
                        </p>
                    </div>
                    <div className={'d-flex flex-grow-1 align-items-center justify-content-between'}>
                        <p>
                            {`- ${formatMoney(giftCard?.applicableAmount ?? 0)}`}
                        </p>
                        <Fade in={!giftCardDisabled}>
                            <button className={'button text icon red p-1'}
                                    disabled={giftCardDisabled}
                                    onClick={() => onRemoveClicked(giftCard, TransactionTypes.giftCard)}>
                                <RemoveIcon/>
                            </button>
                        </Fade>
                    </div>
                </div>
            );
        }
        transactions?.forEach((transaction, index, array) => {
            transaction = transaction ?? {};
            const isCash = transaction.transactionTypeName === TransactionTypes.cashOrChequePayment;
            const typeName = isCash ? TransactionTypes.payment : transaction?.transactionTypeName;
            const transactionDisabled = transactionButtonsDisabled(transaction);
            return res.push(
                <div className={classnames('transaction', typeName,
                    {
                        'py-2': isCash,
                        'first': index === 0 || typeName !== array[index - 1].transactionTypeName,
                        'last': (index === array.length - 1) || (typeName !== array[index + 1].transactionTypeName)
                    }
                )}
                     key={`transaction-${transaction?.id}`}>
                    <div className={'d-flex flex-grow-1 text-right'}>
                        <p>
                            {`${typeName ?? '--'}:`}
                        </p>
                    </div>
                    <div className={'d-flex flex-grow-1 align-items-center justify-content-between'}>
                        <p>
                            {`- ${formatMoney(transaction?.amount ?? 0)}`}
                        </p>
                        <Fade in={!transactionDisabled}>
                            <button className={'button text icon red p-1'}
                                    disabled={transactionDisabled}
                                    onClick={() => onRemoveClicked(transaction, typeName)}>
                                <RemoveIcon/>
                            </button>
                        </Fade>
                    </div>
                </div>
            );
        });
        return res;
    }

    return (
        <>
            <div className={'d-flex flex-column w-100 summary'}>
                <div className={'d-flex align-items-center justify-content-between'}>
                    <h5 className={'summary-title'}>
                        Summary
                    </h5>
                    {
                        <Fade in={step !== ShoppingCartProcessSteps.success}>
                            <div>
                                <button
                                    className={'button text close-icon d-flex align-items-center pr-0'}
                                    onClick={closeShoppingCart}
                                    disabled={closing}>
                                    <CloseIcon/>
                                    <p className={'close-text'}>
                                        {
                                            closing
                                                ? 'processing...'
                                                : ' Close and save quote for later'
                                        }
                                    </p>
                                </button>
                            </div>
                        </Fade>
                    }
                </div>
                <table className={'parts-table'}>
                    <thead>
                    <tr>
                        <td>
                            Product
                        </td>
                        <td>
                            Count
                        </td>
                        <td>
                            Price
                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {renderProductEntries()}
                    </tbody>
                </table>
                <div className={'summary-table'}>
                    {renderSummaryEntries()}
                    <button
                        onClick={() => setAddCreditOrGiftCard(true)}
                        disabled={(!editable || !!giftCard || creditMemos?.length >= 1)}
                        className={classnames('button primary outlined add-credit-button',
                            {'hidden': (!editable || !!giftCard || creditMemos?.length >= 1)}
                        )}>
                        {
                            canSeeCredits
                                ? "Add gift card or credit"
                                : "Add gift card"
                        }

                    </button>
                </div>
                <div className={'total'}>
                    <p>
                        <span> Balance: </span>
                        {formatMoney(cart?.balance ?? 0)}
                    </p>
                </div>
                <div className={'d-flex flex-column px-3 pt-3'}>
                    <ShoppingCartAddressCard
                        address={cart?.shippingAddress}
                        type={AddressTypes.shipping}
                        setCart={setCart}
                        editable={editable && !loadingCountries}
                        countries={countries}
                        provinces={provinces}
                        setProvinces={setProvinces}
                    />
                    <ShoppingCartAddressCard
                        address={cart?.billingAddress}
                        type={AddressTypes.billing}
                        setCart={setCart}
                        editable={editable && !loadingCountries}
                        countries={countries}
                        provinces={provinces}
                        setProvinces={setProvinces}
                    />
                </div>
                <ShoppingCartOrderNote
                    note={cart ? cart[ShoppingCartInjectedPropertyNames.note] : null}
                    setCart={setCart}
                    editable={editable}
                />
                <ShoppingCartSummaryButton
                    sendEmail={sendEmail}
                    checkoutOrder={checkoutOrder}
                    processing={summaryButtonProcessing}
                    disabled={summaryButtonDisabled}
                    step={step}
                />
                <AddGiftCardOrCreditToShoppingCartDialog
                    open={addCreditOrGiftCard}
                    setOpen={setAddCreditOrGiftCard}
                    onSuccess={setCart}
                    seeCredits={canSeeCredits}
                />
            </div>
        </>
    )
}

const ShoppingCartAddressCard = ({address, type, setCart, editable, countries, provinces, setProvinces}) => {
    const [editAddress, setEditAddress] = useState(false);

    return (
        <>
            <div className={'shopping-card-address'}>
                <div className={'d-flex align-items-center mb-1'}>
                    <p className={'title mb-0'}>
                        {type.title} Address
                    </p>
                    {
                        editable &&
                        <button
                            className={'button text icon'}
                            onClick={() => setEditAddress(true)}
                        >
                            <EditIcon/>
                        </button>
                    }
                </div>
                <div
                    dangerouslySetInnerHTML={{__html: getAddressHTML(address)}}
                />
            </div>
            <EditShoppingCartAddressDialog
                open={editAddress}
                setOpen={setEditAddress}
                type={type}
                address={address}
                onSuccess={setCart}
                countries={countries}
                provinces={provinces}
                setProvinces={setProvinces}
            />
        </>
    );
}

const ShoppingCartOrderNote = ({note, editable, setCart}) => {
    const [data, setData] = useState(note ?? '')

    /**
     * Updates the note of an order in the server.
     * @return {Promise<void>}
     */
    const updateOrderNote = async () => {
        if (note === data || !data?.length) return
        setCart(prevState => ({...prevState, [ShoppingCartInjectedPropertyNames.note]: data}))
    }

    return (
        <>
            {
                editable
                    ? <div className={'notes'}>
                        <p className={'title'}>
                            Your Notes
                        </p>
                        <MuiInput
                            value={data}
                            onChange={e => setData(e.target.value)}
                            placeholder={'add a note for your order (optional)'}
                            fullWidth
                            onBlur={updateOrderNote}
                            multiline
                            minRows={4}
                            maxRows={10}
                        />
                    </div>
                    : note?.length > 0
                        ? <div className={'notes'}>
                            <p className={'title'}>
                                Your Notes
                            </p>
                            <p>
                                {note ?? ''}
                            </p>
                        </div>
                        : <></>
            }
        </>
    );

}

const ShoppingCartSummaryButton = ({checkoutOrder, processing, disabled, sendEmail, step}) => {
    const userProfileEmail = useSelector(state => state?.profile?.email ?? 0);
    const [email, setEmail] = useState(userProfileEmail);
    const [goingToNextStep, setGoingToNextStep] = useState(false);
    const [expandMailInput, setExpandMailInput] = useState(false);

    const shouldCheckout = [
        ShoppingCartProcessSteps.items,
        ShoppingCartProcessSteps.delayedItems,
    ].includes(step);
    const displayNothing = [
        ShoppingCartProcessSteps.success
    ].includes(step);

    /**
     * Listens for the changes in the redux state email and with each change:
     * syncs the email state with it.
     */
    useEffect(() => {
        setEmail(userProfileEmail ?? '')
    }, [userProfileEmail])


    /**
     * Sends an email to the user containing the current information of this shopping cart.
     *
     * if the result of the api call is successful, collapses the email input.
     * @return {Promise<void>}
     */
    const sendEmailToUser = async () => {
        if (!email?.length) {
            return setExpandMailInput(false);
        }
        setGoingToNextStep(true);
        const resultFlag = await sendEmail(email);
        if (resultFlag) {
            setExpandMailInput(false);
        }
        setGoingToNextStep(false);
    }

    /**
     * Based on the current step of this cart:
     *
     * - if should-check-out, the proceeds to check out the cart.
     * - else if mail is not expanded, expands the mail input
     * - if mail is expanded, sends the mail to the recipient.
     * @return {Promise<void>}
     */
    const onClick = async () => {
        if (shouldCheckout) {
            setGoingToNextStep(true);
            await checkoutOrder();
            return setGoingToNextStep(false)
        }
        if (!expandMailInput) {
            return setExpandMailInput(true);
        }
        await sendEmailToUser()
    }

    return (
        <>
            <Fade in={!displayNothing} className={'w-100 d-flex flex-wrap'}>
                <div>
                    <div className={classnames('email', {"hidden": shouldCheckout || !expandMailInput})}>
                        <p className={'title'}>
                            Email Address
                        </p>
                        <MuiInput
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder={'your email address'}
                            fullWidth
                            disabled={goingToNextStep}
                        />
                    </div>
                    {
                        !disabled &&
                        <button
                            onClick={onClick}
                            disabled={goingToNextStep || disabled}
                            className={classnames('mt-3 w-100 button primary', {
                                'outlined py-2': !shouldCheckout,
                                'py-3': shouldCheckout,
                            })}>
                            {
                                processing
                                    ? "processing..."
                                    : goingToNextStep
                                        ? "submitting..."
                                        : shouldCheckout
                                            ? "Proceed to Checkout"
                                            : "Receive email receipt"
                            }

                        </button>
                    }
                </div>
            </Fade>
        </>
    );
}

export default ShoppingCartSummary;
