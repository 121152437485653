import {
    DataGridDispatcherAction,
    DataGridEvents,
    DataGridInternalState,
    DataGridReorderingEventArg,
    DataGridStateActions
} from "../../../../models";
import DataGridUtils from "../../../services/utils";

/**
 * The Event Emitter of the Data grid. This interface is responsible for emitting proper event as the state of a
 * data grid changes.
 *
 * * the emitted event is dispatched based on the type of the action that is received
 * * the emitting of an event is only possible if the action has its propagation set to true.
 */
class DataGridEventEmitter {
    /**
     * Constructs a new DataGridStateDispatcher with the provided arguments.
     *
     * @param {DataGridEvents} events
     */
    constructor(events: DataGridEvents) {
        this._events = events;
    }

    private _events: DataGridEvents;

    /**
     * Sets the events for this dispatcher to use.
     * @param {DataGridEvents} events
     */
    set events(events: DataGridEvents) {
        this._events = events;
    }


    /**
     * Determines if an event should be emitted that is associated with the type of the dispatched action.
     *
     * @param {DataGridDispatcherAction} action
     * @param {DataGridInternalState} prevState
     * @param {DataGridInternalState} newState
     * @return {Promise<void>}
     */
    async emitEvent(action: DataGridDispatcherAction, prevState: DataGridInternalState, newState: DataGridInternalState): Promise<void> {

        if (!action.emitEvent)
            // do nothing if now allowed to emit an event.
            return;

        switch (action.type) {
            case DataGridStateActions.columnsPropsChanged:
            case DataGridStateActions.preliminaryColumnsChanged:
            case DataGridStateActions.preliminaryDensityChanged:
            case DataGridStateActions.statePropsChanged:
            case DataGridStateActions.setPageLength:
            case DataGridStateActions.loadConfiguration:
            default:
                // internal actions or actions without defined event handlers.
                break;

            case DataGridStateActions.setPageSize:
                if (prevState.pagination.pageSize === newState.pagination.pageSize)
                    return;
                if (!!this._events.onPageSizeChanged) {
                    const prevPagination = DataGridUtils.deepCopy(prevState.pagination);
                    const newPagination = DataGridUtils.deepCopy(newState.pagination);
                    this._events.onPageSizeChanged(prevPagination, newPagination, newState.pagination.pageSize);
                }
                break;

            case DataGridStateActions.setCurrentPage:
                if (prevState.pagination.currentPage === newState.pagination.currentPage)
                    return;
                if (!!this._events.onCurrentPageChanged) {
                    const prevPagination = DataGridUtils.deepCopy(prevState.pagination);
                    const newPagination = DataGridUtils.deepCopy(newState.pagination);
                    this._events.onCurrentPageChanged(prevPagination, newPagination, newState.pagination.currentPage);
                }
                break;

            case DataGridStateActions.setSortBy:
                if (DataGridUtils.deepEqual(prevState.sortBy, newState.sortBy))
                    return;
                if (!!this._events.onSortByChanged)
                    this._events.onSortByChanged(prevState.sortBy, newState.sortBy);
                break;

            case DataGridStateActions.setDensity:
                if (DataGridUtils.deepEqual(prevState.density, newState.density))
                    return;
                if (!!this._events.onDensityChanged)
                    this._events.onDensityChanged(prevState.density, newState.density);
                break;

            case DataGridStateActions.toggleColumnsVisibility: {
                const prev = Object.fromEntries(prevState.columns.map(e => [e.name, e.visible]))
                const curr = Object.fromEntries(newState.columns.map(e => [e.name, e.visible]))
                const changes = Object.fromEntries(Object.entries(curr).filter(([n, v]) => prev[n] !== v))
                if (!Object.keys(changes).length)
                    return;
                if (!!this._events.onColumnVisibilitiesToggled)
                    this._events.onColumnVisibilitiesToggled(prev, curr, changes);
                break;
            }

            case DataGridStateActions.togglePinnedColumns: {
                const prev = Object.fromEntries(prevState.columns.map(e => [e.name, e.pinnedType]))
                const curr = Object.fromEntries(newState.columns.map(e => [e.name, e.pinnedType]))
                const changes = Object.fromEntries(Object.entries(curr).filter(([n, v]) => prev[n] !== v))
                if (!Object.keys(changes).length)
                    return;
                if (!!this._events.onColumnsPinnedToggled)
                    this._events.onColumnsPinnedToggled(prev, curr, changes);
                break;
            }

            case DataGridStateActions.refreshLayout:
                // layout is density, column ordering, pinned columns, column visibility,
                if (DataGridUtils.deepEqual(prevState.columns, newState.columns) && prevState.density === newState.density)
                    return;
                if (!!this._events.onLayoutRefreshed)
                    this._events.onLayoutRefreshed();
                break;

            case DataGridStateActions.reorderColumns:
            case DataGridStateActions.setAllColumnsOrder: {
                const prev: DataGridReorderingEventArg = {
                    pinned: Object.fromEntries(prevState.columns
                        .filter(e => e.pinned)
                        .map(e => ([e.name, e.pinnedOrder]))),
                    normal: Object.fromEntries(prevState.columns
                        .map(e => ([e.name, e.order]))),
                }
                const curr: DataGridReorderingEventArg = {
                    pinned: Object.fromEntries(newState.columns
                        .filter(e => e.pinned)
                        .map(e => ([e.name, e.pinnedOrder]))),
                    normal: Object.fromEntries(newState.columns
                        .map(e => ([e.name, e.order]))),
                }
                if (DataGridUtils.deepEqual(prev, curr))
                    return;
                if (!!this._events.onColumnsReordered)
                    this._events.onColumnsReordered(prev, curr);
                break;
            }

            case DataGridStateActions.toggleSelectedRows:
            case DataGridStateActions.resetSelection:
            case DataGridStateActions.toggleAllRowsSelection: {
                const prev = {
                    selectedRows: prevState.selectedRows,
                    excludedRows: prevState.excludedRows,
                    allRowsSelected: prevState.allRowsSelected,
                };
                const curr = {
                    selectedRows: newState.selectedRows,
                    excludedRows: newState.excludedRows,
                    allRowsSelected: newState.allRowsSelected,
                };
                if (DataGridUtils.deepEqual(prev, curr))
                    return;
                if (this._events.onRowSelectionInfoChanged)
                    this._events.onRowSelectionInfoChanged(prev, curr);
            }
                break;

            case DataGridStateActions.resizeColumnsBy:
            case DataGridStateActions.resizeColumns: {
                const prev = Object.fromEntries(prevState.columns.map(e => [e.name, e.width]));
                const curr = Object.fromEntries(newState.columns.map(e => [e.name, e.width]));
                const changes = Object.fromEntries(Object.entries(curr).filter(([n, v]) => !DataGridUtils.deepEqual(prev[n], v)))
                if (!Object.keys(changes).length)
                    return;
                if (!!this._events.onColumnsResized)
                    this._events.onColumnsResized(prev, curr, changes);
                break;
            }

        }
    }

}

export default DataGridEventEmitter;
