import React, {useEffect, useMemo, useRef} from "react";
import * as Yup from "yup";
import ValidateMessages from "../../../../../../core/constants/validate-messages";
import {makeRequired, makeValidate} from "mui-rff";
import Form from "../../../../../components/base/form";
import EmailInput from "../../../../../components/base/input/email-input";
import MuiInput from "../../../../../components/base/input/mui-input";
import MuiCheckbox from "../../../../../components/base/check-box";
import routes from "../../../../../routes";
import useRouter from "../../../../../hooks/use-router";
import CookiesService from "../../../../../../core/services/cache/cookies-service";
import EnvService from "../../../../../../core/services/env-service";
import ApplicationIds from "../../../../../../core/constants/application-ids";


const formKeys = {
    username: 'email',
    password: 'password',
    remember: "remember"
}

const schema = Yup.object().shape({
    [formKeys.username]: Yup.string().nullable().required(ValidateMessages.required),
    [formKeys.password]: Yup.string().nullable().required(ValidateMessages.required),
    [formKeys.remember]: Yup.boolean().nullable().default(false),
})

const validate = makeValidate(schema);
const required = makeRequired(schema);

const HeaderLoginForm = ({loading, onSubmit}) => {
    /*** @type {React.MutableRefObject<HTMLDivElement>}*/
    const containerRef = useRef();
    const {history} = useRouter();
    const preloadedUserInfo = useMemo(() => (
        CookiesService.get(CookiesService.keys.login.name)
        ?? {
            account: '',
            password: '',
            remember: false
        }
    ), []);

    const initialFormValues = useMemo(() => ({
        [formKeys.username]: preloadedUserInfo.account,
        [formKeys.password]: preloadedUserInfo.password,
        [formKeys.remember]: preloadedUserInfo.remember,
    }), [preloadedUserInfo]);


    /**
     * Handles navigating user to registration screen
     */
    const onSignUpClicked = () => {
        history.replace(routes.auth.signUp);
    }

    /**
     * As soon as the component mounts:
     * - changes the color of the autocomplete inputs.
     */
    useEffect(() => {
        setTimeout(changeAutofillColors, 100);
    }, [])

    /**
     * Changes the colors of autofilled inputs based on the parent component of mui inputs that are autofilled
     * @param color
     * @return {Promise<void>}
     */
    const changeAutofillColors = async (color = 'bg-autofill-input-color') => {
        if (!EnvService.EnableAutofillDistinction)
            return;
        if (!containerRef.current)
            return;
        await new Promise(r => setTimeout(r, 1));
        const inputs = document.querySelectorAll("input");
        const autofilled = Array.from(containerRef.current.querySelectorAll('input:-webkit-autofill'));
        for (const input of inputs) {
            const parent = input.parentElement;
            input.classList.remove(color);
            const isAutofilled = !!autofilled.find(e => e === input);
            if (isAutofilled) {
                input.classList.add(color);
            }
            if (parent.classList.contains('MuiInputBase-root')) {
                parent.classList.remove(color);
                if (isAutofilled) {
                    parent.classList.add(color);
                }
            }
        }
    }


    return (
        <div ref={containerRef}>
            <Form
                className={'header-login-form'}
                onSubmit={onSubmit}
                validate={validate}
                initialValues={initialFormValues}
                render={({values, form}) => {
                    return (
                        <>
                            <div className={'header-login-main-section'}>
                                <div className={'header-login-form-section'}>
                                    <p className={'header-login-form-title'}>
                                        Email or user name
                                    </p>
                                    <EmailInput
                                        id={ApplicationIds.headerLoginFormEmailInput}
                                        className={'mb-2'}
                                        setValidating={() => {
                                        }}
                                        form={true}
                                        type={'email'}
                                        shouldValidateWithApiCall={false}
                                        name={formKeys.username}
                                        placeholder={'Email or username '}
                                        required={required.username}
                                        onKeyUp={(e) => changeAutofillColors()}
                                        onFocus={() => changeAutofillColors()}
                                        onBlur={() => changeAutofillColors()}
                                    />
                                    <p className={'header-login-form-title'}>
                                        Password
                                    </p>
                                    <MuiInput
                                        className={'mb-3'}
                                        form
                                        type={'password'}
                                        placeholder={'Password'}
                                        name={formKeys.password}
                                        required={required.password}
                                        onKeyUp={(e) => changeAutofillColors()}
                                        onFocus={() => changeAutofillColors()}
                                        onBlur={() => changeAutofillColors()}
                                    />
                                    <p
                                        className={'header-login-form-title'}
                                        onClick={onSignUpClicked}
                                    >
                                        Don't have an account?
                                        <span className={'sign-up-text-button'}>
                                            Sign Up
                                        </span>
                                    </p>
                                </div>
                                <div className={'ml-0 ml-md-4 header-login-second-section'}>
                                    <p className={'dummy'}/>
                                    <button className={'button primary primary-action-button'}
                                            type={'submit'}>
                                        {!loading ? "Login" : "Loading..."}
                                    </button>
                                    <MuiCheckbox
                                        form
                                        className={'header-login-remember-me'}
                                        isInHeaderLogin={true}
                                        name={formKeys.remember}
                                        required={required[formKeys.remember]}
                                        onChange={(_, c) => form.change(formKeys.remember, c)}
                                        data={{
                                            value: values[formKeys.remember],
                                            label: (
                                                <p className={'header-login-form-title mb-0'}>
                                                    Remember Me
                                                </p>
                                            )
                                        }}
                                    />
                                </div>
                            </div>
                        </>
                    )
                }}
            />
        </div>
    );
}

export default HeaderLoginForm;
