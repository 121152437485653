import React, {useLayoutEffect, useRef} from "react";
import classnames from "classnames";
import {formatMoney} from "../../../../../../core/services/utils/utils";
import {Fade, SvgIcon, Tooltip} from "@material-ui/core";
import LazyImage from "../../../lazy-lmage";
import {ReactComponent as CompareIcon} from "../../../../../../assets/images/parts/compare.svg";
import {ReactComponent as CompareOutLinedIcon} from "../../../../../../assets/images/parts/compare-outlined.svg";
import {Bookmark, BookmarkBorderOutlined} from "@material-ui/icons";
import {Link} from 'react-router-dom'
import useWindowViewportWidth from "../../../../../hooks/use-window/viewport-width";
import TagsContainer from "../../../tags-container";

const LargeProductBox = ({
                             data,
                             toggleInCompareList,
                             toggleBookmarked,
                             linkPath,
                             onTagClicked,
                             preventAnimation
                         }) => {
    const containerRef = useRef();
    const viewportWidth = useWindowViewportWidth();

    const priceNotAvailable = data?.changedPrice <= 0;
    const hasNewPrice = data?.changedPrice < data?.originalPrice;

    /**
     * With each change in viewport width:
     * - Calls the function for preventing the animation being run for action buttons
     */
    useLayoutEffect(() => {
        preventAnimation(containerRef.current);
    }, [viewportWidth])


    return (
        <>
            <div ref={containerRef} className={classnames('product large')}>
                <Link to={linkPath}>
                    <div className={'product-header'}>
                        <div className={'product-header-line'}>
                            <p className={'part-no'}>
                                {data?.partNumber ?? ''}
                            </p>
                            <p className={'category mt-2'}>
                                {data?.category?.title ?? data?.category?.Title ?? ''}
                            </p>
                        </div>
                        <div className={'product-header-line'}>
                            {hasNewPrice &&
                                <Fade in={hasNewPrice}>
                                    <p className={'price new'}>
                                        {formatMoney(data?.changedPrice)}
                                    </p>
                                </Fade>
                            }
                            {
                                priceNotAvailable
                                    ? <p className={'price not-available'}>
                                        {/*Price Not Available*/}
                                    </p>
                                    : <p className={classnames('price', {'old': hasNewPrice})}>
                                        {formatMoney(data?.originalPrice)}
                                    </p>
                            }
                        </div>
                    </div>
                    <div className={'product-body'}>
                        <div className={'image'}>
                            <LazyImage
                                src={data?.imageUrl}
                                alt={data?.partNumber}
                                height={300}
                            />
                        </div>
                        <button
                            className={classnames('button animating-icon-button save',
                                {
                                    'active': data?.saved || data?.isCompared,
                                    'selected': data?.saved
                                })}
                            onClick={toggleBookmarked}>
                            <Tooltip
                                classes={{tooltip: 'product-tool-tip'}}
                                placement={'top-end'}
                                title={
                                    data?.saving
                                        ? 'Loading'
                                        : !data?.saved
                                            ? 'Add to save list'
                                            : 'Remove from save list'
                                }
                            >
                                {
                                    data?.saving
                                        ? <i className={'icon-loader'}/>
                                        : !data?.saved
                                            ? <BookmarkBorderOutlined className={'color'}/>
                                            : <Bookmark className={'color'}/>
                                }
                            </Tooltip>
                        </button>
                        <button
                            className={classnames(
                                'button animating-icon-button compare',
                                {
                                    'active': data?.isCompared || data?.saved,
                                    'selected': data?.isCompared
                                })}
                            onClick={toggleInCompareList}>
                            <Tooltip
                                classes={{tooltip: 'product-tool-tip'}}
                                placement={'top-end'}
                                title={
                                    data?.comparing
                                        ? 'Loading'
                                        : data?.isCompared
                                            ? 'Remove from compare list'
                                            : 'Add to compare list'
                                }
                            >
                                {
                                    data?.comparing
                                        ? <i className={'icon-loader'}/>
                                        : data?.isCompared
                                            ? <SvgIcon component={CompareIcon}/>
                                            : <SvgIcon component={CompareOutLinedIcon}/>
                                }
                            </Tooltip>
                        </button>
                    </div>
                    <TagsContainer
                        tags={data?.tags}
                        lines={2}
                        onTagClicked={onTagClicked}
                    />
                </Link>
            </div>
        </>
    );
}


export default LargeProductBox;
