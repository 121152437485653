import React, {useContext, useMemo, useRef} from "react";
import {DataGridColumnPinnedTypes, DataGridColumnWidthTypes, DataGridExoticColumnFields} from "../../../../models";
import {DataGridLayoutContext, DataGridMiscContext, DataGridStateContext} from "../../../../index";
import classnames from "classnames";
import DataGridColumnResizer from "../resizer";
import DataGridHeaderCellDefaultContent from "../cell-content/default";
import DataGridHeaderCellSelectionContent from "../cell-content/selection";

const exoticColumnNames = Object.values(DataGridExoticColumnFields)

/**
 * @param {DataGridInternalColumn} column
 * @param {function} resizeColumn
 * @param {function} onIsResizingChanged
 */
const DataGridHeaderCell = ({
                                column,
                                resizeColumn,
                                onIsResizingChanged,
                            }) => {
    const {classNames, singleSelectionOnly} = useContext(DataGridMiscContext);
    const {hideSelectAll} = useContext(DataGridLayoutContext);
    const {loading: {state: loading}} = useContext(DataGridStateContext);
    /**@type {React.MutableRefObject<HTMLTableHeaderCellElement>}*/
    const ref = useRef();

    const isExotic = useMemo(() => exoticColumnNames.includes(column.name), [column.name]);

    const width = useMemo(() =>
            `${Math.max(column.width.size, column.width.minWidth)}px`,
        [column.width])

    const pinOffset = useMemo(() =>
            !column.pinned
                ? undefined
                : `${column.leftPinOffset ?? column.rightPinOffset}px`,
        [column.pinned, column.leftPinOffset, column.rightPinOffset])

    const resizeable = useMemo(() =>
            column.resizable &&
            column.width.type !== DataGridColumnWidthTypes.flex &&
            !isExotic &&
            !loading,
        [column.resizable, column.width?.type, isExotic, loading]);

    const reorderable = useMemo(() =>
            !column.pinned &&
            column.reorderable &&
            !loading,
        [column.pinned, column.reorderable, loading])

    /**
     * Resizes the column width by the given offset value.
     * @param {number} offset
     */
    const resizeColumnByOffset = (offset) => {
        resizeColumn(column, offset)
    }

    const content = useMemo(() => {
        switch (column.name) {
            case DataGridExoticColumnFields.selection:
                if (singleSelectionOnly || hideSelectAll)
                    return <></>
                return <DataGridHeaderCellSelectionContent/>
            case DataGridExoticColumnFields.detailedPanelToggler:
            case DataGridExoticColumnFields.detailedPanel:
            case DataGridExoticColumnFields.spacer:
                // child column should never be rendered in the first place. this is just a
                // placeholder in case of any bugs.
                // child-toggler column does not need any content in the header
                return <></>
            default:
                return <DataGridHeaderCellDefaultContent column={column}/>
        }
    }, [
        column.name,
        column.alignment,
        column.sortable,
        column.pinnedToggleable,
        column.visibilityToggleable,
        column.pinned,
        column.pinnedType,
        column.title,
        singleSelectionOnly,
        hideSelectAll,
    ]);

    const resizer = useMemo(() =>
        <DataGridColumnResizer
            reverse={column.pinnedType === DataGridColumnPinnedTypes.right}
            onResize={resizeColumnByOffset}
            onIsResizingChanged={onIsResizingChanged}
        />, [column.pinnedType])

    return (
        <>
            <th
                ref={ref}
                data-id={column.name}
                className={classnames('data-grid-header-cell',
                    classNames.headerCell,
                    column.alignment,
                    {'reorderable': reorderable},
                    {'not-reorderable': !reorderable},
                    {
                        'pinned': column.pinned,
                        [column.pinnedType]: column.pinned,
                    },
                )}
                style={{
                    '--width': width,
                    '--offset': pinOffset,
                }}>
                <div
                    className={classnames('data-grid-header-inner-cell', classNames.headerInnerCell)}
                    onClick={e => e.preventDefault() || e.stopPropagation()}
                >
                    {
                        resizeable && column.pinnedType === DataGridColumnPinnedTypes.right &&
                        resizer
                    }
                    <div className={classnames(
                        'data-grid-header-inner-cell-content',
                        classNames.headerInnerCellContent
                    )}>
                        {content}
                    </div>
                    {
                        resizeable && column.pinnedType !== DataGridColumnPinnedTypes.right &&
                        resizer
                    }
                </div>
            </th>
        </>
    )
}

export default DataGridHeaderCell;
