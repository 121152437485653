import React, {forwardRef, useEffect, useState} from "react";
import {DataGridExportInfo, DataGridProps} from "../../../packages/data-grid/models";
import DataGrid from "../../../packages/data-grid";
import DataGridController from "../../../packages/data-grid/core/controller";
import useIsMounted from '../../hooks/use-is-mounted';
import LoadingIndicator from "../../components/app-specific/loading-indicator";
import {useDispatch} from "react-redux";
import {exportingDialog} from "../../../redux/entities/dialogs/actions";
import classnames from "classnames";


const _AppDataGrid: React.ForwardRefRenderFunction<DataGridController, DataGridProps>
    = ({
           LoadingComponent = <LoadingIndicator/>,
           getExportInfo: getExportInfoProps,
           ...props
       }, ref) => {
    const [exporting, setExporting] = useState(false);
    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    /**
     * With each change in the exporting value of the state:
     * - opens or closes the exporting dialog of the application.
     */
    useEffect(() => {
        dispatch(exportingDialog(exporting));
    }, [exporting])


    /**
     * Fetches the exported information of the data-grid.
     *
     * * sets the [exporting] state value to open or close the exporting dialog.
     */
    const getExportInfo = async (): Promise<DataGridExportInfo | undefined> => {
        if (!getExportInfoProps)
            return;
        setExporting(true);
        const data = await getExportInfoProps();
        if (!isMounted())
            return;
        setExporting(false);
        return data;
    }


    return (
        <DataGrid
            LoadingComponent={LoadingComponent}
            getExportInfo={!getExportInfoProps ? undefined : getExportInfo}
            // @ts-ignore
            ref={ref}
            {...props}
            classNames={{
                ...(props.classNames ?? {}),
                container: classnames('app-data-grid-container', props.classNames?.container),
            }}
        />
    );
};

const AppDataGrid = forwardRef(_AppDataGrid);

export default AppDataGrid;
