import React, {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import useIsMounted from "../../../hooks/use-is-mounted";

const DynamicContentEditorConfirmationDialog = ({
                                                    open,
                                                    close,
                                                    title,
                                                    description,
                                                    onConfirmed,
                                                }) => {
    const [submitting, setSubmitting] = useState(false);
    const isMounted = useIsMounted();

    /**
     * Confirms this confirmation dialog by invoking the [onConfirmed] callback.
     *
     * * awaits the resultFlag of the callback to determine whether the action was successful.
     * - if the resultFlag has a boolean value of [false] does nothing,
     * - else closes the dialog.
     * @return {Promise<void>}
     */
    const confirmAction = async () => {
        setSubmitting(true);
        const resultFlag = await onConfirmed();
        if (!isMounted())
            return;
        setSubmitting(false);
        if (typeof resultFlag === "boolean" && !resultFlag) {
            return;
        }
        close();
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={() => close()}
                maxWidth={'sm'}
                fullWidth
                classes={{
                    root: 'dynamic-content-editor-dialog-root',
                    paper: "dynamic-content-editor-dialog-paper dynamic-content-editor-confirmation-dialog-paper",
                }}
            >
                <DialogTitle className={'dynamic-content-editor-dialog-title'}>
                    {title ?? "Confirmation"}
                </DialogTitle>
                <DialogContent className={'dynamic-content-editor-dialog-content'}>
                    <div>
                        {description ?? ""}
                    </div>
                </DialogContent>
                <DialogActions>
                    <button
                        className={'dynamic-content-editor-dialog-action-button'}
                        disabled={submitting}
                        type={'button'}
                        onClick={() => close()}
                    >
                        No
                    </button>
                    <button
                        className={'dynamic-content-editor-dialog-action-button'}
                        type={'button'}
                        onClick={confirmAction}
                    >
                        {
                            submitting
                                ? "Processing..."
                                : "Yes"
                        }
                    </button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default DynamicContentEditorConfirmationDialog;
