import React from "react";
import useRefEffect from '../use-ref-effect';
import ObserversService from "../../../../../core/services/observers-service";

interface Rect {
    width: number;
    height: number;
}

interface UseResizeObserver<T> {
    ref: React.RefCallback<T>;
    rect?: Rect;
}

/**
 * Attaches a resize-observer to the element that is obtained from the attached ref of this hook.
 *
 * @return {UseResizeObserver<T>}
 */
const useResizeObserver = <T extends HTMLElement, >(): UseResizeObserver<T> => {
    const [rect, setRect] = React.useState<Rect>();
    const observerRef = React.useRef<ResizeObserver>();

    /**
     * Sets the dimensions of the rect state with each change in the dimensions of the ref element.
     * @param entries
     */
    const setRectDimensions = (entries: (ResizeObserverEntry | HTMLElement)[]) => {
        if (entries.length <= 0) {
            return;
        }
        let size;
        if (entries[0] instanceof HTMLElement) {
            size = entries[0]?.getBoundingClientRect();
        } else {
            size = entries[0]?.contentRect;
        }
        const {width, height} = size;
        setRect((prevState) =>
            prevState?.width === width && prevState?.height === height
                ? prevState
                : {width, height}
        );
    }

    /**
     * Attaches a resize-observer to the element of the ref as soon as it is assigned a value or changes its value.
     *
     * @param {T} element
     * @return {function(): void}
     */
    const refEffect = (element: T) => {
        if (!observerRef.current) {
            observerRef.current = ObserversService.newResizeObserver(setRectDimensions);
        }
        const observer = observerRef.current;
        ObserversService.observeResizeObserver(observer, element);
        setRectDimensions([element]);
        return () => ObserversService.unobserveResizeObserver(observer, element);
    }

    const ref = useRefEffect<T>(refEffect);

    return ({
        ref,
        rect,
    });
}

export default useResizeObserver;
