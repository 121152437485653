import React, {useEffect, useRef} from "react";
import {Col, Row} from "reactstrap";
import {formatMoney} from "../../../../../../core/services/utils/utils";
import InvoiceBox from "../../../../../components/app-specific/order-boxes/invoice-box";
import useRouter from "../../../../../hooks/use-router";
import routes from "../../../../../routes";


const InvoicesListView = ({data, selectedInvoices, setSelectedInvoices}) => {
    const {history, location, query} = useRouter();
    const lastPath = useRef(location.pathname);

    const selectedInvoiceIds = selectedInvoices?.map(e => e.id) ?? [];
    const total = [
        ...(selectedInvoices?.map(e => parseFloat(e.payInfo?.minimumRequiredPayment)) ?? []), 0, 0
    ].reduce((a, b) => a + b)

    /**
     * Listens to the changes in the pathname of the current url and with each change:
     *
     * - empties the selected invoices list. This is done since the only way the url changes is when the user comes
     * back from the multi payment view, or open this view, and hence the list should be emptied to start anew.
     */
    useEffect(() => {
        if (lastPath.current === location.pathname) return;
        lastPath.current = location.pathname;
        setSelectedInvoices([]);
    }, [location.pathname])

    /**
     * Adds or removes from the list of selected invoices for mui-inoice payments.
     *
     * if the invoice is already selected, removes it, otherwise adds it to the list.
     * @param invoice
     */
    const toggleSelected = (invoice) => {
        setSelectedInvoices(prevState => {
            if (prevState.map(e => e.id).includes(invoice.id)) {
                return prevState.filter(e => e.id !== invoice.id)
            }
            return [...prevState, invoice];
        })
    }

    /**
     * Navigates the user to multi invoice payment view.
     */
    const navigateToMultiInvoicePaymentView = () => {
        history.push(routes.main.multiInvoicePayment,
            {
                invoices: selectedInvoices,
                credits: [],
                payments: [],
                query,
            });
    }

    return (
        <Row className={'pt-3'}>
            {
                data?.map((invoice) => {
                    const selected = selectedInvoiceIds.includes(invoice.id);
                    return (
                        <Col xs={12} key={invoice.id} className={'pt-3'}>
                            <InvoiceBox toggleSelected={toggleSelected} selected={selected} data={invoice}/>
                        </Col>
                    );
                })
            }
            {!!selectedInvoices.length &&
                <div className={'sticky-footer w-100'}>
                    <button
                        className={'button primary px-5'}
                        onClick={navigateToMultiInvoicePaymentView}
                        disabled={!selectedInvoices.length}
                    >
                        pay
                        ({selectedInvoices.length} {selectedInvoiceIds.length === 1 ? 'invoice' : 'invoices'}): {formatMoney(total)}
                    </button>
                </div>
            }
        </Row>
    );
}

export default InvoicesListView;

