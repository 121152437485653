import React, {useContext, useLayoutEffect} from "react";
import {useHistory} from "react-router-dom";
import DynamicContentEditorCommunicator from "../../../core/models/communicator";
import {DynamicContentEditorControllerContext} from "../../contexts";

const DynamicContentEditorCommunicatorMiddleware = ({notInIframe}) => {
    const history = useHistory();
    const controller = useContext(DynamicContentEditorControllerContext);

    /**
     * As soon as the component mounts:
     *  we instantiate a Communicator object and inject it to the global window so the iframe and the top window can
     *  communicate with each-other through this interface.
     */
    useLayoutEffect(() => {
        window.DCECommunicator = new DynamicContentEditorCommunicator();
        return () => {
            window.DCECommunicator = null;
            delete window.DCECommunicator;
        }
    }, [])

    /**
     * With each change in the [notInIframe] prop value and [controller] context value:
     * - if we are in view-mode (in the parent window) we do nothing
     * - if we are in the iframe, then we prepare the api config syncer callback for the top window (view mode) to
     * use this syncer for injecting the view mode's api config to the iframe state.
     */
    useLayoutEffect(() => {
        if (notInIframe)
            return;
        window.DCECommunicator.prepareApiConfigurationSyncer(controller);
    }, [notInIframe, controller])

    /**
     * With each change in the [noInFrame] prop of this middleware:
     * - if we are in view-mode (in the parent window) we do nothing
     * - if we are in edit-mode (inside the iframe), then we listen to the changes of the history stack and store
     * the stack length in the communicator of the parent window (view mode).
     */
    useLayoutEffect(() => {
        if (notInIframe)
            return;
        return history.listen((_, action) => {
            switch (action) {
                case "POP":
                    if (window.top.DCECommunicator)
                        window.top.DCECommunicator.historyStackLength -= 1;
                    break;
                case "PUSH":
                    if (window.top.DCECommunicator)
                        window.top.DCECommunicator.historyStackLength += 1;
                    break;
                case "REPLACE":
                default:
                    break;
            }
        })
    }, [notInIframe])

    return (<></>)
}

export default DynamicContentEditorCommunicatorMiddleware;
