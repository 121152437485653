import React, {useEffect, useState} from "react";
import {Col, Container} from "reactstrap";
import useRouter from "../../../../hooks/use-router";
import routes from "../../../../routes";
import RandomBlog from "../../../../components/app-specific/content-boxes/random-blog";
import BlogSearchInput from "../../../../components/base/search-input/content-search-input";
import {Route, Switch} from "react-router-dom";
import ContentMainBothSections from "./both";
import {cmsBlogApis} from "../../../../../core/constants/endpoints/cms_endpoints";
import api from "../../../../../core/services/api/api";
import useIsMounted from "../../../../hooks/use-is-mounted";
import {apiMethods, ContentMainEitherViewTypes} from "../../../../../core/constants/enums";
import FloatingContainer from "../../../../components/app-specific/floating-container";
import ContentMainEitherView from "./either";


const BlogsMainPage = () => {
    const {history, stringifyUrl} = useRouter();
    const [loadingRandomBlog, setLoadingRandomBlog] = useState(true);
    const [randomBlog, setRandomBlog] = useState();
    const isMounted = useIsMounted();

    /**
     * As soon as the component mounts:
     * -loads a randomly selected blog from the server.
     */
    useEffect(() => {
        getRandomBlog().then()
    }, [])


    /**
     * Loads the random blog to be shown at the top of the page.
     */
    const getRandomBlog = async () => {
        const response = await api({
            url: cmsBlogApis.getARandom,
            method: apiMethods.get,
            loginRequired: false,
        });
        if (!isMounted()) return;
        if (response?.isPreemptedDueToNotBeingLoggedIn) {
            setLoadingRandomBlog(false);
            return;
        }
        setRandomBlog(response?.data ?? null);
        setLoadingRandomBlog(false)

    }

    /**
     * Navigates the user to the search results view with the provided data.
     * @param data {string}
     */
    const search = (data) => {
        if (!data?.length) return;
        history.push(stringifyUrl({
            url: routes.public.content.searchResults,
            query: {
                keywords: data
            }
        }));
    }

    return (
        <>
            <main className={'blogs-view'}>
                <Container>
                    <FloatingContainer setHeight>
                        <div className={'mx-4 d-flex justify-content-around align-items-start w-100'}>
                            <RandomBlog loading={loadingRandomBlog} data={randomBlog}/>
                        </div>
                        <div className={'my-4 w-100'}/>
                        <div className={'d-flex w-100 justify-content-center align-items-center'}>
                            <BlogSearchInput search={search} className={'w-88'}/>
                        </div>
                        <div className={'d-flex flex-wrap align-items-start'}>
                            <div className={'my-5 w-100'}/>
                            <Col xs={12} className={'d-flex flex-wrap px-0'}>
                                <Switch>
                                    <Route path={routes.public.content.main} exact>
                                        <ContentMainBothSections/>
                                    </Route>
                                </Switch>
                            </Col>
                        </div>
                    </FloatingContainer>
                </Container>
                <Container>
                    <div className={'d-flex flex-wrap align-items-start'}>
                        <Col xs={12} className={'d-flex flex-wrap px-0'}>
                            <Switch>
                                <Route path={routes.public.content.allVideos} exact>
                                    <ContentMainEitherView type={ContentMainEitherViewTypes.video}/>
                                </Route>
                                <Route path={routes.public.content.allBlogs} exact>
                                    <ContentMainEitherView type={ContentMainEitherViewTypes.blog}/>
                                </Route>
                            </Switch>
                        </Col>
                    </div>
                </Container>
            </main>
        </>
    );
}


export default BlogsMainPage;
